import React, { useMemo } from 'react'
import './clipboard-tooltip.scss'
import PropTypes from 'prop-types'

export const ClipboardTooltip = ({
  tooltipText,
  tooltipTextFocus,
  children,
  ignoreWidth,
  noFocus,
}) => {
  const classNameBtn = useMemo(() => {
    let cls = 'integration-checklist__copy-button'

    if (ignoreWidth) {
      cls += ' ignore-width'
    }
    if (noFocus) {
      cls += ' no-focus'
    }

    return cls
  }, [ignoreWidth, noFocus])
  return (
    <button
      type="button"
      aria-label={tooltipText}
      className={classNameBtn}
      data-text={tooltipText}
      data-text-focus={tooltipTextFocus}
    >
      {children}
    </button>
  )
}

ClipboardTooltip.propTypes = {
  tooltipText: PropTypes.string,
  tooltipTextFocus: PropTypes.string,
  children: PropTypes.node,
  ignoreWidth: PropTypes.bool,
  noFocus: PropTypes.bool,
}
