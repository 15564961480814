import React from 'react'
import PropTypes from 'prop-types'
import './levels-item.scss'
import { BaseTextBody, BaseTextHeading } from '../../../../../../components/BaseText/BaseText'

const LevelsItem = ({
  title,
  img,
  children,
  secondaryFeatures,
}) => (
  <div className="levels-item">
    <div className="levels-item__img" style={{ backgroundImage: `url(${img})` }} />

    <div className="levels-item__body">
      <div className="levels-item__wrapper levels-item__title">
        <BaseTextHeading>
          {title}
        </BaseTextHeading>
      </div>

      <div className="levels-item__wrapper">
        {children}
      </div>

      <div className="levels-item__wrapper">
        {secondaryFeatures.map((item, index) => (
          <div className="levels-item__second-feature" key={index}>
            <BaseTextBody secondary>{item.desc}</BaseTextBody>
            <BaseTextBody>{item.value}</BaseTextBody>
          </div>
        ))}
      </div>
    </div>
  </div>
)

LevelsItem.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  children: PropTypes.node,
  secondaryFeatures: PropTypes.arrayOf(PropTypes.shape({
    desc: PropTypes.string,
    value: PropTypes.string,
  })),
}

export default LevelsItem
