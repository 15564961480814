import React from 'react'
import { BaseTextBody, BaseTextHeading } from '../../BaseText/BaseText'
import { Status } from '../../Status/Status'

const ModalBodySomethingError = () => (
  <>
    <Status type="error" />

    <BaseTextHeading size="S">
      Something went wrong
    </BaseTextHeading>

    <BaseTextBody size="S">
      Try again. If the error occurs frequently, please contact our support team.
    </BaseTextBody>
  </>
)

export default ModalBodySomethingError
