import React from 'react'
import PropTypes from 'prop-types'
import './quests-item.scss'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'

const QuestsItem = ({
  icon,
  title,
  exp,
  onClick,
}) => (
  <div className="quests-item">
    <div className="quests-item__data">
      <div className="quests-item__icon">
        {icon}
      </div>

      <div className="quests-item__desc">
        <div className="quests-item__title">
          <BaseTextBody onClick={onClick}>
            {title}
          </BaseTextBody>
        </div>

        <BaseTextBody
          size="S"
          secondary
        >
          Reward:&nbsp;
          {exp}
          &nbsp;exp.
        </BaseTextBody>
      </div>
    </div>

    <div className="quests-item__action">
      <BaseButton
        size="s"
      >
        Claim
      </BaseButton>
    </div>
  </div>
)

QuestsItem.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  exp: PropTypes.string,
  onClick: PropTypes.func,
}

export default QuestsItem
