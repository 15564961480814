import React from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { ExternalLink } from '../../../../../../../components/ExternalLink/ExternalLink'
import { Chip } from '../../../../../../../components/Chip/Chip'
import SelectToken from './select-token/SelectToken'
import { Input } from '../../../../../../../components/Input/Input'

import './exchange-item.scss'

const ExchangeItem = ({
  allPossibleTokens,
  selectedToken,
  isMaxButtonEnabled,
  onMaxButtonClick,
  isInputDisabled,
  tokenValue,
  onChangeTokenValue,
  tokenPrice,
  onFocus,
  onFocusOut,
  onSelectToken,
}) => (
  <div className="exchange-item-container">
    <div className="exchange-item-container__header">
      <ExternalLink text={selectedToken.name} link={selectedToken.link} hideLink={selectedToken.isLinkHidden} />
      <BaseTextBody secondary>
        &#126;
        {` $${tokenPrice}`}
      </BaseTextBody>
    </div>

    <div className="exchange-item-container__body">
      <div className="select-token-container">
        <SelectToken
          allPossibleTokens={allPossibleTokens}
          selectedToken={selectedToken}
          onSelectToken={onSelectToken}
        />
      </div>

      {isMaxButtonEnabled && (
        <div className="exchange-item__body-chip">
          <Chip onClick={onMaxButtonClick}>MAX</Chip>
        </div>
      )}

      <Input
        className="ascetic-input"
        type="number"
        isInputDisabled={isInputDisabled}
        placeholder="0,00"
        value={tokenValue}
        onChange={(e) => onChangeTokenValue(e.target.value)}
        onFocus={onFocus}
        onFocusOut={onFocusOut}
      />
    </div>
  </div>
)

ExchangeItem.propTypes = {
  allPossibleTokens: PropTypes.array,
  selectedToken: PropTypes.object,
  isMaxButtonEnabled: PropTypes.bool,
  onMaxButtonClick: PropTypes.func,
  isInputDisabled: PropTypes.bool,
  tokenValue: PropTypes.string,
  onChangeTokenValue: PropTypes.func,
  tokenPrice: PropTypes.number,
  onFocus: PropTypes.func,
  onFocusOut: PropTypes.func,
  onSelectToken: PropTypes.func,
}

export default ExchangeItem
