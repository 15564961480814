import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import './stats-item.scss'
import { BaseTextBody, BaseTextSubheading } from '../BaseText/BaseText'

export const StatsItem = ({
  name,
  value,
  subValue,
  isError,
}) => {
  const clsStatsItem = useMemo(() => {
    let cls = 'stats-item'

    if (isError) {
      cls = `${cls} stats-item--error`
    }

    return cls
  }, [isError])

  return (
    <div className={clsStatsItem}>
      <div className="stats-item__name">
        <BaseTextBody secondary>
          {name}
        </BaseTextBody>
      </div>

      <div className="stats-item__value">
        <BaseTextSubheading>
          {value}
          {subValue ? (
            <span>
&nbsp;(
              {subValue}
              )
            </span>
          ) : ''}
        </BaseTextSubheading>
      </div>
    </div>
  )
}

StatsItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  subValue: PropTypes.string,
  isError: PropTypes.bool,
}
