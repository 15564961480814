import React from 'react'
import './gas-compensation.scss'
import { Chip } from '../../../../../components/Chip/Chip'
import { BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import Icon01 from './icon--01.png'
import Icon02 from './icon--02.png'
import Icon03 from './icon--03.png'
import Icon04 from './icon--04.png'
import Icon05 from './icon--05.png'
import { BaseHistory } from '../../../../../components/BaseHistory/BaseHistory'
import { historyData } from './data'

const GasCompensation = () => (
  <div className="gas-compensation">
    <div className="gas-compensation__header">
      <BaseTextHeading
        size="L"
      >
        Gas compensation
      </BaseTextHeading>

      <Chip
        onClick={() => { }}
        icon={<img src={Icon01} alt="" />}
        iconPosition="left"
        size="L"
        outline
      >
        0x00000...000000
      </Chip>
    </div>

    <div className="gas-compensation__networks">
      <Chip
        onClick={() => { }}
        icon={<img src={Icon02} alt="" />}
        iconPosition="left"
        size="L"
        outline
        isSelected
      >
        Ethereum
      </Chip>

      <Chip
        onClick={() => { }}
        icon={<img src={Icon03} alt="" />}
        iconPosition="left"
        size="L"
        outline
      >
        Polygon
      </Chip>

      <Chip
        onClick={() => { }}
        icon={<img src={Icon04} alt="" />}
        iconPosition="left"
        size="L"
        outline
      >
        Fantom
      </Chip>

      <Chip
        onClick={() => { }}
        icon={<img src={Icon05} alt="" />}
        iconPosition="left"
        size="L"
        outline
      >
        BSC
      </Chip>
    </div>

    <BaseHistory
      historyData={historyData}
      currentPage={1}
      rowCount={3}
    />
  </div>
)

export default GasCompensation
