import React from 'react'
import PropTypes from 'prop-types'

export const Grid = ({
  children,
  gap = 'initial',
  direction = 'row',
  justify = 'flex-start',
  align = 'flex-start',
}) => (
  <div
    className="grid"
    style={{
      display: 'flex',
      width: '100%',
      gap,
      flexDirection: direction,
      justifyContent: justify,
      alignItems: align,
    }}
  >
    {children}
  </div>
)

Grid.propTypes = {
  children: PropTypes.element,
  gap: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'column']),
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
}
