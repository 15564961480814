import React from 'react'
import PropTypes from 'prop-types'

import './stepper.scss'
import { StatusBadge } from '../StatusBadge/StatusBadge'
import { BaseTextBody } from '../BaseText/BaseText'
import IconStepperArrow from '../Icon/icon--stepper-arrow.svg'
import { Icon } from '../Icon/Icon'
import { generateUniqueKey } from '../../utils/helperFunctions'

const Step = ({
  active,
  badge,
  text,
  substate,
}) => (
  <div className={`stepper__step 
      ${active ? 'stepper__step--active' : ''}
      ${substate ? `stepper__step--${substate}` : ''}
    `}
  >
    <StatusBadge
      state={badge.state}
      text={badge.text}
    />

    <div className="stepper__step-text">
      <BaseTextBody>{text}</BaseTextBody>
    </div>
  </div>
)

Step.propTypes = {
  active: PropTypes.bool,
  badge: PropTypes.object,
  text: PropTypes.string,
  substate: PropTypes.string,
}

export const Stepper = ({
  className,
  steps,
}) => (
  <div className={`stepper ${className || ''}`}>
    {steps.map((item, index) => {
      if (index === steps.length - 1) {
        return (
          <Step
            active={item.active}
            badge={item.badge}
            text={item.text}
            substate={item.substate}
            key={generateUniqueKey()}
          />
        )
      }
      return (
        <React.Fragment key={generateUniqueKey()}>
          <Step
            active={item.active}
            badge={item.badge}
            text={item.text}
            substate={item.substate}
          />

          {className === 'stepper--arrow'
            && (
            <Icon
              name={IconStepperArrow}
              id="icon--stepper-arrow"
              mod="stepper__divider--arrow"
            />
            )}

          {!className
            && <div className="stepper__divider" />}
        </React.Fragment>
      )
    })}
  </div>
)

Stepper.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.array,
}
