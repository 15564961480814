import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from '@rehooks/local-storage'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import { StatusBadge } from '../../../../../../components/StatusBadge/StatusBadge'
import { getBrigdeHistory } from '../../../../../../service/gate-away'
import { getAllTransactions } from '../../../../../../service/zapperApi'
import Loading from '../../../../../../components/Loading/Loading'
import { Icon } from '../../../../../../components/Icon/Icon'
import IconNoData from '../../../../../../components/Icon/icon--no-data.svg'
import { formatDecimal } from '../../../../../../utils/helperFunctions'
import { Status } from '../../../../../../components/Status/Status'
import { parseBridgeTransactions } from '../../../../../../parser/bridge'
import { getAllPossibleTokensForAllNetworksConbinations } from '../../../../../../service/bridge'

import './order-list.scss'

export const OrderList = ({ type }) => {
  const now = new Date().getTime()
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [selectedChain] = useLocalStorage('selectedChain')
  const [orders, setOrders] = useState([])
  const [isOrdersRequestPending, setOrdersRequestIsPending] = useState(false)
  const [areOrdersLoaded, setOrdersAreLoaded] = useState(false)
  const [previousType, setPreviousType] = useState(type)
  const [isErrorExist, setErrorIsExist] = useState(false)

  useEffect(() => {
    if (previousType !== type) {
      setPreviousType(type)
      setOrdersAreLoaded(false)
    }
  }, [previousType, type])

  const loadBridgeData = useCallback(async () => {
    setOrdersRequestIsPending(true)
    try {
      const mockedBridgeData = await getBrigdeHistory({
        listType: type,
        address: internalSelectedAddress,
        nextPageToken: now.toString(),
      })
      console.log('mockedBridgeData = ', mockedBridgeData)

      const allPossibleTokens = await getAllPossibleTokensForAllNetworksConbinations()

      const bridgeData = parseBridgeTransactions({
        transactions: await getAllTransactions(internalSelectedAddress, selectedChain),
        allPossibleTokens,
        type,
      })
      console.log('bridgeData = ', bridgeData)

      setOrdersRequestIsPending(false)
      setOrdersAreLoaded(true)

      setErrorIsExist(mockedBridgeData.isExist)
      setOrders(mockedBridgeData.items)
    } catch (e) {
      setErrorIsExist(true)
      setOrders([])
      console.log('Could not load orders...')
    }
  }, [type, internalSelectedAddress, selectedChain, now])

  useEffect(() => {
    (async () => {
      if (!isOrdersRequestPending && !areOrdersLoaded) {
        loadBridgeData()
      }
    })()
  }, [isOrdersRequestPending, areOrdersLoaded, loadBridgeData])

  const getTokenDataTemplate = useCallback((token) => (
    <BaseTextBody>
      {`${formatDecimal(token.amount, token.decimals).split(',').join('')} ${token.symbol}`}
    </BaseTextBody>
  ), [])

  const getOrderItem = useCallback(({
    status, token, date, networks,
  }) => (
    <div className="order-item">
      <div className="order-item__top">
        <div className="order-item__status">
          <StatusBadge text={status.text} state={status.value} label />
        </div>
        <BaseTextBody secondary>{date}</BaseTextBody>
      </div>

      <div className="order-item__bottom">
        {/* need to leave it like this, until we are 100% that we don't need additional info for fees */}
        {/* <div className="order-item__asset-one">
          {getTokenDataTemplate(token.sent)}
        </div> */}

        <div className="order-item__asset-two">
          {/* need to leave it like this, until we are 100% that we don't need additional info for fees */}
          {/* <BaseTextBody>→</BaseTextBody> */}
          {getTokenDataTemplate(token.received)}
        </div>

        <div className="order-item__networks">
          <BaseTextBody>·</BaseTextBody>
          <BaseTextBody>{networks.from}</BaseTextBody>
          <BaseTextBody>→</BaseTextBody>
          <BaseTextBody>{networks.to}</BaseTextBody>
        </div>
      </div>
    </div>
  ), [getTokenDataTemplate])

  return (
    <div className="order-list">
      {
        isOrdersRequestPending && (
          <div className="order-list__loader">
            <Loading />
          </div>
        )
      }

      {
        isErrorExist && !isOrdersRequestPending && (
          <div className="order-list__icon-block">
            <Status type="error" />
            <div className="order-list__icon-block__message">
              <BaseTextBody secondary>Orders loading failed.</BaseTextBody>
              <BaseTextBody onClick={() => {
                loadBridgeData()
              }}
              >
                Retry
              </BaseTextBody>
            </div>
          </div>
        )
      }

      {
        !isErrorExist && !isOrdersRequestPending && orders.length === 0 && (
          <div className="order-list__icon-block">
            <Icon name={IconNoData} id="icon--no-data" />
            <BaseTextBody secondary>
              {
                type === 'orders'
                  ? 'You do not have any pending orders'
                  : 'You do not have any finished orders yet'
              }
            </BaseTextBody>
          </div>
        )
      }

      {
        !isErrorExist && !isOrdersRequestPending && orders.length > 0 && (
          orders.map((item, index) => (
            <div className="order-list__item" key={index}>
              {getOrderItem(item)}
            </div>
          ))
        )
      }
    </div>
  )
}

OrderList.propTypes = {
  type: PropTypes.string,
}
