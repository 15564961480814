import instance from '../axios-api'
import { parseGasData } from '../parser/api'

/**
 * Получение gas стратегий
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getGas = () => instance.get('https://ethgasstation.info/api/ethgasAPI.json')

export const loadGasData = async (cancelToken) => {
  const res = await instance.get('https://ethgasstation.info/api/ethgasAPI.json', { cancelToken })
  return parseGasData(res.data)
}
