import React from 'react'

import PropTypes from 'prop-types'
import './transaction-type-details.scss'
import { Icon } from '../../Icon/Icon'
import IconAdd from '../../Icon/icon--action-add.svg'
import IconDown from '../../Icon/icon--action-arrow-downward.svg'
import IconUp from '../../Icon/icon--action-arrow-upward.svg'
import IconOpen from '../../Icon/icon--action-lock-open.svg'
import IconRemove from '../../Icon/icon--action-remove.svg'
import IconSwap from '../../Icon/icon--action-swap-horiz.svg'
import { BaseTextBody } from '../../BaseText/BaseText'

export const TransactionTypeDetails = ({
  currency,
  icon,
  name,
  time,
}) => {
  let iconTransaction

  switch (icon) {
    case 'add':
      iconTransaction = <Icon name={IconAdd} id="icon--action-add" />
      break

    case 'down':
      iconTransaction = <Icon name={IconDown} id="icon--action-arrow-downward" />
      break

    case 'up':
      iconTransaction = <Icon name={IconUp} id="icon--action-arrow-upward" />
      break

    case 'open':
      iconTransaction = <Icon name={IconOpen} id="icon--action-lock-open" />
      break

    case 'remove':
      iconTransaction = <Icon name={IconRemove} id="icon--action-remove" />
      break

    case 'swap':
      iconTransaction = <Icon name={IconSwap} id="icon--action-swap-horiz" />
      break

    default:
  }

  return (
    <div className="transaction-type-details">
      <div className="transaction-type-details__left">
        <div className="transaction-type-details__currency">
          {currency}
        </div>

        <div className="transaction-type-details__icon">
          {iconTransaction}
        </div>
      </div>

      <div className="transaction-type-details__right">
        <div className="transaction-type-details__name">
          <BaseTextBody>{name}</BaseTextBody>
        </div>

        <div className="transaction-type-details__date">
          <BaseTextBody size="S" secondary>{time}</BaseTextBody>
        </div>
      </div>
    </div>
  )
}

TransactionTypeDetails.propTypes = {
  currency: PropTypes.element,
  icon: PropTypes.oneOf([
    'add',
    'down',
    'up',
    'open',
    'remove',
    'swap',
  ]),
  name: PropTypes.oneOf([
    'Send',
    'Receive',
    'Exchange',
    'Bridge Exchange',
    'Trade {type}',
    'Approval',
    'Add Liquidity',
    'Remove Liquidity',
  ]),
  time: PropTypes.string,
}
