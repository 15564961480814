import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { Text } from '../../../../../../../../components/Text/Text'
import { Btn } from '../../../../../../../../components/Btn/Btn'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconMoreHoriz from '../../../../../../../../components/Icon/icon--more-horiz.svg'
import IconRadioButtonUnchecked from '../../../../../../../../components/Icon/icon--radio-button-unchecked.svg'
import IconRadioButtonChecked from '../../../../../../../../components/Icon/icon--radio-button-checked.svg'
import { Menu } from '../../../../../../../../components/Menu/Menu'
import { MenuBlock } from '../../../../../../../../components/MenuBlock/MenuBlock'
import { MenuItem } from '../../../../../../../../components/MenuItem/MenuItem'
import IconMetamask from '../../../../../../../../components/Icon/icon--metamask.png'
import IconArrowRight from '../../../../../../../../components/Icon/icon--keyboard-arrow-right.svg'
import IconEtherscan from '../../../../../../../../components/Icon/icon--etherscan.svg'
import IconCopy from '../../../../../../../../components/Icon/icon--copy.svg'
import IconWallet from '../../../../../../../../components/Icon/icon--wallet.svg'
import IconOpenInNew from '../../../../../../../../components/Icon/icon--open-in-new.svg'
import IconList from '../../../../../../../../components/Icon/icon--list.svg'
import ClickOutside from '../../../../../../../../components/ClickOutside/ClickOutside'
import { getLinkBasedOnCurrentNetwork, getLinkTitleBasedOnCurrentNetwork } from '../../../../../../../../parser/data'

import './address-block.scss'

const AddressBlock = ({
  address,
  walletDetails,
  openedWallet,
  activeWallet,
  onOpenSubmenu,
  onSelectAddress,
  onDeposit,
}) => {
  const cutAddress = (addr) => `${(addr || '').substr(0, 6)}...${(addr || '').substr(-5)}`
  const [isFeatureHidden] = useState(true)

  const getMenu = useMemo(() => (
    <ClickOutside callback={() => onOpenSubmenu()}>
      <div className="submenu-wrapper">
        <Menu menuType="menu--wallet-submenu">
          <MenuBlock
            divider
            title={openedWallet}
            items={[
              <MenuItem
                text={`Open ${getLinkTitleBasedOnCurrentNetwork()}`}
                iconLeft={{
                  file: IconEtherscan,
                  name: 'icon--etherscan',
                }}
                iconRight={{
                  file: IconOpenInNew,
                  name: 'icon--open-in-new',
                }}
                onClick={() => {
                  window.open(`https://${getLinkBasedOnCurrentNetwork()}/address/${openedWallet}`, '_blank')
                  onOpenSubmenu()
                }}
              />,
              <MenuItem
                text="Copy Address"
                iconLeft={{
                  file: IconCopy,
                  name: 'icon--copy',
                }}
                isCopyToClipBoard
                copyToClipBoardText={openedWallet}
                onClick={() => onOpenSubmenu()}
              />,
            ]}
          />

          <MenuBlock
            items={[
              <MenuItem
                text="Deposit Assets"
                iconLeft={{
                  file: IconWallet,
                  name: 'icon--wallet',
                }}
                iconRight={{
                  file: IconArrowRight,
                  name: 'icon--keyboard-arrow-right',
                }}
                onClick={() => {
                  onOpenSubmenu()
                  onDeposit(openedWallet)
                }}
              />,
            ]}
          />
        </Menu>
      </div>
    </ClickOutside>
  ), [openedWallet, onDeposit, onOpenSubmenu])

  return (
    <div className="owner-wallet__address-list__address-block">
      <div className="address-block__header">
        <img className="owner-avatar" src={IconMetamask} alt="metamask" />
        <BaseTextBody>{cutAddress(address)}</BaseTextBody>
        <div className="controls">
          <Btn
            mod="btn--icon"
            onClick={() => onOpenSubmenu(address)}
            iconLeft={(
              <Icon
                name={IconMoreHoriz}
                id="icon--more-horiz"
                mod="icon--22"
              />
            )}
          />
          {
            activeWallet !== address ? (
              <Btn
                mod="btn--icon"
                onClick={() => onSelectAddress(address)}
                iconLeft={(
                  <Icon
                    name={IconRadioButtonUnchecked}
                    id="icon--radio-button-unchecked"
                    mod="icon--22"
                  />
                )}
              />
            ) : (
              <Icon
                name={IconRadioButtonChecked}
                id="icon--radio-button-checked"
                mod="icon--22"
              />
            )
          }
        </div>
      </div>
      {
        !isFeatureHidden && (
          <div className="address-block__body">
            <Text className="text--12" text={`Lvl. ${walletDetails.level}`} />
            <Text className="text--12" text={`${walletDetails.currentExperienceValue} / ${walletDetails.maxExperienceValue}`} />
            <div className="quests-block">
              <Icon
                name={IconList}
                id="icon--list"
              />
              <Text className="text--12" text={`${walletDetails.questsCount} quest${walletDetails.questsCount > 1 ? 's' : ''}`} />
            </div>
          </div>
        )
      }
      {openedWallet === address && getMenu}
    </div>
  )
}

AddressBlock.propTypes = {
  address: PropTypes.string,
  walletDetails: PropTypes.object,
  openedWallet: PropTypes.string,
  activeWallet: PropTypes.string,
  onOpenSubmenu: PropTypes.func,
  onSelectAddress: PropTypes.func,
  onDeposit: PropTypes.func,
}

export default AddressBlock
