import React, {
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { BaseModal } from '../../../../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../../components/Icon/icon--close.svg'
import IconWarning from '../../../../../../../../components/Icon/icon--warning.svg'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../../components/BaseText/BaseText'
import './extract-private-key-msg-step.scss'

const ExtractPrivateKeyMsgStep = ({
  onCloseModal,
  onNextStep,
}) => {
  const getModalHeader = () => (
    <BaseButton
      onClick={onCloseModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getModalBody = useMemo(() => (
    <>
      <div className="extract-private-key__base-modal__icon-block">
        <Icon
          name={IconWarning}
          id="icon--warning"
        />
      </div>
      <BaseTextHeading size="S">
        Are you sure you want to extract the private key?
      </BaseTextHeading>
      <BaseTextBody size="S">
        You will have full control over the wallet, but you will no longer be able to connect it back to the platform.
        We will delete all wallet data from our platform.
      </BaseTextBody>
    </>
  ), [])

  const getModalFooter = useMemo(() => (
    <div className="extract-private-key__base-modal__footer">
      <div className="extract-private-key__base-modal__footer__btn">
        <div className="extract-private-key__base-modal__footer__btn__box">
          <BaseButton
            onClick={onCloseModal}
            variant="secondary"
          >
            Cancel
          </BaseButton>
        </div>
        <div className="extract-private-key__base-modal__footer__btn__box">
          <BaseButton
            dataTestid="popup--extract-private-key--next-step"
            onClick={onNextStep}
          >
            Continue
          </BaseButton>
        </div>
      </div>
    </div>
  ), [onNextStep, onCloseModal])

  return (
    <BaseModal
      title="Extract Private Key"
      topAction={getModalHeader()}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

ExtractPrivateKeyMsgStep.propTypes = {
  onNextStep: PropTypes.func,
  onCloseModal: PropTypes.func,
}

export default ExtractPrivateKeyMsgStep
