import { deleteFromStorage } from '@rehooks/local-storage'
import { BigNumber } from 'bignumber.js'
import { NEW_ETH_ADDRESS, WRAPPED_ETH_ADDRESS, ZERO_ADDRESS } from '../const'
import { addLPToken } from '../store/slice/lpTokens'
import {
  factoryABI, UniswapABI, UniswapFactoryAddress, UniswapRouterAddress, minABI, ERC20ANI,
} from './contracts'
import { getExactTokenBalance } from '../service/etherscanApi'
import { getTokenData } from '../service/api'
import { parseNativeTokenData } from '../parser/data'

export const logout = () => {
  deleteFromStorage('handleSelected')
  deleteFromStorage('accessToken')
  deleteFromStorage('internalSelectedAddress')
  deleteFromStorage('selectedAddress')
  deleteFromStorage('selectedWallet')
  deleteFromStorage('isLoginMetamask')
}

/**
 * @param {string} wallet
 * @returns {string}
 */
export const croppedWallet = (wallet = '') => `${wallet.substr(0, 6)}...${wallet.substr(-5)}`

/**
 * @param {string} wallet
 * @returns {string}
 */
export const croppedWalletAddress = (wallet = '') => `${wallet.substr(0, 12)}...${wallet.substr(-5)}`

/**
 * @param {string} str
 * @param {number} firstStart
 * @param {number} firstEnd
 * @param {number} secondEnd
 * @returns {string}
 */
export const croppedString = (str = '', firstStart, firstEnd, secondEnd) => `${str.substr(firstStart, firstEnd)}...${str.substr(secondEnd)}`

/**
 * @param {number} balance
 * @param {number} decimal
 * @returns {number}
 */
export const calculateBalance = (balance, decimal) => Math.trunc(balance / (10 ** decimal))

/**
 * @param {function} fromWei
 * @param {string} wei
 */
export const weiToEth = (fromWei = () => {}, wei) => fromWei(wei, 'ether')

// Todo если баланс === 0, то не показывать
// Todo если баланс маленький, то вывести с пометкой маленький
/**
 * @param {number} firstNUmber
 * @param {number} secondNumber
 * @param {number} PRICE_PRECISION
 * @param BN
 * @returns {string}
 */
export const e = (firstNUmber, secondNumber, PRICE_PRECISION = 8, BN) => {
  if (firstNUmber.toString() === '0' || secondNumber.toString() === '0') {
    return '0'
  }
  let dm = firstNUmber.divmod(secondNumber)
  let r = dm.div.toString()
  let j = 0
  if (r !== '0') {
    j += r.length
  }
  if (PRICE_PRECISION > j) {
    r += '.'
  }

  while (j < PRICE_PRECISION) {
    dm = dm.mod.mul(new BN('10')).divmod(secondNumber)
    r += dm.div.toString()
    // start counting j after upper find smth != 0
    if (j > 0) {
      j += 1
    } else if (dm.div.toString() !== '0') {
      j += 1
    }
  }
  return r
}

const strtodec = (amount, dec) => {
  let i = 0
  if (amount.toString().indexOf('.') !== -1) {
    i = amount.toString().length - (amount.toString().indexOf('.') + 1)
  }
  let stringf = ''
  stringf = amount.toString().split('.').join('')
  if (dec < i) {
    console.warn('strtodec: amount was truncated')
    stringf = stringf.substring(0, stringf.length - (i - dec))
  } else {
    stringf += '0'.repeat(dec - i)
  }
  return stringf
}

export const floatToERC20 = (tokenDecimals, amount) => {
  return strtodec(amount, tokenDecimals)
}

/**
 * @param {string} tokenAddress
 * @param {number} amount
 * @param {object} web3React
 * @returns {Promise<string>}
 */
export const safeFloatToERC20 = async(tokenAddress, amount, web3React) => {
  const tokenDecimals = await getTokenExactData(tokenAddress, 'decimals', web3React)
  return strtodec(amount, tokenDecimals)
}

// Todo сделать hooks
/**
 * @param {number} balance
 * @param {number} tokenDecimals
 * @param BN
 * @param {number} precision
 * @returns {string}
 * @constructor
 */
export const ERC20ToFloat = (balance, tokenDecimals, BN, precision = 5) => {
  try {
    return e(balance, new BN(`1${'0'.repeat(tokenDecimals)}`), precision, BN)
  } catch (e) {
    console.error(`ERC20ToFloat: ${e}`)
  }
}

/**
 * @param {string|number} eth
 * @param {number} nums
 * @returns {number}
 */
export const formatEth = (eth, nums = 5) => {
  const ethF = parseFloat(eth)
  return Math.trunc(ethF * 10 ** nums) / 10 ** nums
}

/**
 * @param {string} tokenAddress
 * @param {string} data
 * @param {object} web3React
 * @returns {null|*}
 */
export const getTokenExactData = (tokenAddress, data, web3React = {}) => {
  if (tokenAddress === ZERO_ADDRESS){
    const ETH_DATA = {
      symbol: 'ETH',
      decimals: 18,
      name: 'Ethereum'
    }
    return ETH_DATA[data]
  }
  if (web3React.library) {
    const address = web3React.library.utils.toChecksumAddress(tokenAddress)
    const token = new web3React.library.eth.Contract(minABI, address)
    return token.methods[data]().call()
  }
  return null
}

export const getTokenDictData = async (tokenAddress, dataList, web3React = {}) => {
  let tokenObject
  if (web3React.library) {
    const token = new web3React.library.eth.Contract(minABI, tokenAddress)
    tokenObject = await dataList.map(async (data) => ( {...tokenObject, [data]: await token.methods[data]().call()} ))
  }
  return tokenObject
}

export const exchangeETHToToken = (tokenAddress, userAddress, web3React = {}) => {
  const path = []
  const uniSwapContract = new web3React.library.eth.Contract(UniswapABI, UniswapRouterAddress)
  path.push(WRAPPED_ETH_ADDRESS)
  path.push(tokenAddress)
  return uniSwapContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
    0,
    path,
    userAddress,
    '999999999999999999',
  ).encodeABI()
}

export const exchangeTokenToETH = (amountIn, tokenAddress, userAddress, web3React = {}) => {
  const path = []
  const uniSwapContract = new web3React.library.eth.Contract(UniswapABI, UniswapRouterAddress)
  path.push(tokenAddress)
  path.push(WRAPPED_ETH_ADDRESS)
  return uniSwapContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
    amountIn,
    0,
    path,
    userAddress,
    '999999999999999999',
  ).encodeABI()
}

export const swapTokensData = (amount, token0address, token1address, userAddress, web3React = {}) => {
  const path = []
  const uniSwapContract = new web3React.library.eth.Contract(UniswapABI, UniswapRouterAddress)
  path.push(token0address)
  path.push(token1address)

  return uniSwapContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
    amount,
    0,
    path,
    userAddress,
    '999999999999999999',
  ).encodeABI()
}

export const approveTokensData = ({
  amount,
  tokenAddress,
  web3React,
  allowanceTarget,
}) => {
  const ERC20Contract = new web3React.library.eth.Contract(ERC20ANI, tokenAddress)
  return ERC20Contract.methods.approve(allowanceTarget || UniswapRouterAddress, amount).encodeABI()
}

export const swapTokensGas = (amount, token0address, token1address, userAddress, web3React = {}) => {
  const path = []

  const uniSwapContract = new web3React.library.eth.Contract(UniswapABI, UniswapRouterAddress)
  path.push(token0address)
  path.push(token1address)

  return uniSwapContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(
    amount,
    0,
    path,
    userAddress,
    '999999999999999999',
  ).estimateGas({from: userAddress})
}

export const approveTokensGas = (amount, token_address, userAddress, web3React = {}) => {
  const ERC20Contract = new web3React.library.eth.Contract(ERC20ANI, token_address)
  return ERC20Contract.methods.approve(UniswapRouterAddress, amount).estimateGas({from: userAddress})
}

export const proccessLPToken = async (tokenAddress, web3React, dispatch) => {
  try {
    const pairPrice = await getLPTokenPrice(tokenAddress, web3React)
    const token0 = await getTokenExactData(tokenAddress, 'token0', web3React)
    const token1 = await getTokenExactData(tokenAddress, 'token1', web3React)
    const resultToken = {
      liquid: 'Liq: $226.492.492',
      className: 'item-staked--sm',
      title: `${await getTokenExactData(token0, 'symbol', web3React)}/${await getTokenExactData(token1, 'symbol', web3React)}`,
      currency: `$${formatEth(parseFloat(pairPrice))}`,
      token0,
      token1,
      address: tokenAddress,
      pairPrice: formatEth(parseFloat(pairPrice)),
    }
    dispatch(addLPToken(resultToken))
  } catch (e) {
    console.error('proccessLPToken', e.message)
    return `Some error:${e}`
  }
}

export const getLPPairForAsset = async (tokenAddress, web3React, dispatch) => {
  try {
    const uniSwapContract = new web3React.library.eth.Contract(UniswapABI, UniswapRouterAddress)
    const WETH_ADDRESS = await uniSwapContract.methods.WETH().call()
    const factoryContract = new web3React.library.eth.Contract(factoryABI, UniswapFactoryAddress)
    const lp_token = await factoryContract.methods.getPair(WETH_ADDRESS, tokenAddress).call()
    proccessLPToken(lp_token, web3React, dispatch)
    return lp_token
  } catch (e) {
    console.error('getLPPairForAsset', e.message)
    return `Some error:${e}`
  }
}

/**
 * @param {string} address
 * @param {object} web3React
 * @returns {Promise<string|number>}
 */
export const getEtherBalance = async (address, web3React) => {
  try {
    return new web3React.library.utils.BN(await web3React.library.eth.getBalance(address))
  } catch (error) {
    console.error('getEtherBalance', error.message)
    return 0
  }
}

/**
 * @param {string} tokenAddress
 * @param {string} userAddress
 * @param {object} web3React
 * @returns {Promise<string|null|*>}
 */
export const getAllowance = async ({
  tokenAddress,
  allowanceTarget,
  userAddress,
  web3React,
 }) => {
  if (web3React.library) {
    const contract = new web3React.library.eth.Contract(minABI, tokenAddress)
    try {
      return parseFloat(await contract.methods.allowance(userAddress, allowanceTarget).call())
    } catch (error) {
      console.log('getAllowance', error.message)
      return `Allowance error: ${error}`
    }
  }
  return null
}

/**
 * @param {string} tokenAddress
 * @param {string} _userAddress
 * @param {object} web3React
 * @returns {Promise<string|null|*>}
 */
export const getERC20tokenBalance = async (tokenAddress, _userAddress, web3React = {}) => {
  if (web3React.library) {
    const token = new web3React.library.eth.Contract(minABI, tokenAddress)
    // call contract
    try {
      return new web3React.library.utils.BN(await token.methods.balanceOf(web3React.library.utils.toChecksumAddress(_userAddress)).call())
    } catch (error) {
      console.error('getERC20tokenBalance', error.message)
      return `Some error:${error}`
    }
  }
  return null
}

/**
 * @param {string} poolAddress
 * @param {object} web3React
 * @param {number} precision
 * @returns {Promise<string|string|number>}
 */
export const getLPTokenPrice = async (poolAddress, web3React = {}, precision = 8) => {
  const minABI = [
    {
      inputs: [], payable: false, stateMutability: 'nonpayable', type: 'constructor',
    }, {
      anonymous: false,
      inputs: [{
        indexed: true, internalType: 'address', name: 'owner', type: 'address',
      }, {
        indexed: true, internalType: 'address', name: 'spender', type: 'address',
      }, {
        indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
      }],
      name: 'Approval',
      type: 'event',
    }, {
      anonymous: false,
      inputs: [{
        indexed: true, internalType: 'address', name: 'sender', type: 'address',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256',
      }, {
        indexed: true, internalType: 'address', name: 'to', type: 'address',
      }],
      name: 'Burn',
      type: 'event',
    }, {
      anonymous: false,
      inputs: [{
        indexed: true, internalType: 'address', name: 'sender', type: 'address',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount0', type: 'uint256',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount1', type: 'uint256',
      }],
      name: 'Mint',
      type: 'event',
    }, {
      anonymous: false,
      inputs: [{
        indexed: true, internalType: 'address', name: 'sender', type: 'address',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount0In', type: 'uint256',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount1In', type: 'uint256',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount0Out', type: 'uint256',
      }, {
        indexed: false, internalType: 'uint256', name: 'amount1Out', type: 'uint256',
      }, {
        indexed: true, internalType: 'address', name: 'to', type: 'address',
      }],
      name: 'Swap',
      type: 'event',
    }, {
      anonymous: false,
      inputs: [{
        indexed: false, internalType: 'uint112', name: 'reserve0', type: 'uint112',
      }, {
        indexed: false, internalType: 'uint112', name: 'reserve1', type: 'uint112',
      }],
      name: 'Sync',
      type: 'event',
    }, {
      anonymous: false,
      inputs: [{
        indexed: true, internalType: 'address', name: 'from', type: 'address',
      }, {
        indexed: true, internalType: 'address', name: 'to', type: 'address',
      }, {
        indexed: false, internalType: 'uint256', name: 'value', type: 'uint256',
      }],
      name: 'Transfer',
      type: 'event',
    }, {
      constant: true, inputs: [], name: 'DOMAIN_SEPARATOR', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'MINIMUM_LIQUIDITY', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'PERMIT_TYPEHASH', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [{ internalType: 'address', name: '', type: 'address' }, { internalType: 'address', name: '', type: 'address' }], name: 'allowance', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'value', type: 'uint256' }], name: 'approve', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'balanceOf', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'to', type: 'address' }], name: 'burn', outputs: [{ internalType: 'uint256', name: 'amount0', type: 'uint256' }, { internalType: 'uint256', name: 'amount1', type: 'uint256' }], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [], name: 'decimals', outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'factory', outputs: [{ internalType: 'address', name: '', type: 'address' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'getReserves', outputs: [{ internalType: 'uint112', name: '_reserve0', type: 'uint112' }, { internalType: 'uint112', name: '_reserve1', type: 'uint112' }, { internalType: 'uint32', name: '_blockTimestampLast', type: 'uint32' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: '_token0', type: 'address' }, { internalType: 'address', name: '_token1', type: 'address' }], name: 'initialize', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [], name: 'kLast', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'to', type: 'address' }], name: 'mint', outputs: [{ internalType: 'uint256', name: 'liquidity', type: 'uint256' }], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [], name: 'name', outputs: [{ internalType: 'string', name: '', type: 'string' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'nonces', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, { internalType: 'address', name: 'spender', type: 'address' }, { internalType: 'uint256', name: 'value', type: 'uint256' }, { internalType: 'uint256', name: 'deadline', type: 'uint256' }, { internalType: 'uint8', name: 'v', type: 'uint8' }, { internalType: 'bytes32', name: 'r', type: 'bytes32' }, { internalType: 'bytes32', name: 's', type: 'bytes32' }], name: 'permit', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [], name: 'price0CumulativeLast', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'price1CumulativeLast', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'to', type: 'address' }], name: 'skim', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'uint256', name: 'amount0Out', type: 'uint256' }, { internalType: 'uint256', name: 'amount1Out', type: 'uint256' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'bytes', name: 'data', type: 'bytes' }], name: 'swap', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [], name: 'symbol', outputs: [{ internalType: 'string', name: '', type: 'string' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [], name: 'sync', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: true, inputs: [], name: 'token0', outputs: [{ internalType: 'address', name: '', type: 'address' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'token1', outputs: [{ internalType: 'address', name: '', type: 'address' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: true, inputs: [], name: 'totalSupply', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'value', type: 'uint256' }], name: 'transfer', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function',
    }, {
      constant: false, inputs: [{ internalType: 'address', name: 'from', type: 'address' }, { internalType: 'address', name: 'to', type: 'address' }, { internalType: 'uint256', name: 'value', type: 'uint256' }], name: 'transferFrom', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function',
    }]

  if (web3React.library) {
    const pair = new web3React.library.eth.Contract(minABI, poolAddress)
    // get lp price
    const token1address = await pair.methods.token0().call()
    const token2address = await pair.methods.token1().call()

    let token1Amount = await getERC20tokenBalance(token1address, poolAddress, web3React)
    let token2Amount = await getERC20tokenBalance(token2address, poolAddress, web3React)

    // correction due to the difference in the point after the decimal point
    const token1Decimals = parseInt(await getTokenExactData(token1address, 'decimals', web3React), 10)
    const token2Decimals = parseInt(await getTokenExactData(token2address, 'decimals', web3React), 10)
    if (token1Decimals !== token2Decimals) {
      if (token1Decimals > token2Decimals) {
        token2Amount = token2Amount.mul(new web3React.library.utils.BN(`1${'0'.repeat(token1Decimals - token2Decimals)}`))
      } else {
        token1Amount = token1Amount.mul(new web3React.library.utils.BN(`1${'0'.repeat(token2Decimals - token1Decimals)}`))
      }
    }

    return (token1Amount === 0 || token2Amount === 0) ? '0' : e(token2Amount, token1Amount, precision, web3React.library.utils.BN)
  }
  return 0
}

/**
 * @param {string} to
 * @param {number} amount
 * @param {string} tokenAddress
 * @param {string} userAddress
 * @param {object} web3React
 * @returns {*}
 */
export const transferTokensGas = (to, amount, tokenAddress, userAddress, web3React = {}) => {
  const ERC20Contract = new web3React.library.eth.Contract(ERC20ANI, tokenAddress)
  return ERC20Contract.methods.transfer(to, amount).estimateGas({from: userAddress})
}

/**
 * Полученает имя браузера
 * @returns {string}
 */
export const getBrowserName = () => {
  const nAgt = navigator.userAgent;
  let browserName  = navigator.appName;
  let nameOffset,verOffset;

  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
    browserName = "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "IE";
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if ( (nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset=nAgt.lastIndexOf('/')) ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  return browserName
}

/**
 * Преобразует check во float
 * Если check не является NaN то вернет parseFloat(check)
 * Если является NaN, то возвращает переменную ifNaN, если она не передана, то вернет bool
 *
 * @param {string|float|number} check
 * @param {any} ifNaN
 * @param {number} fixed
 */
export const checkFloatNaN = (check, ifNaN, fixed) => {
  try {
    const toFloat = parseFloat(check)

    if (Number.isNaN(toFloat)) {
      if (typeof ifNaN !== 'undefined') {
        return ifNaN
      }

      return true
    }

    if (fixed) {
      return parseFloat(toFloat.toFixed(fixed))
    }

    return parseFloat(toFloat)
  } catch (e) {
    if (typeof ifNaN !== 'undefined') {
      return ifNaN
    }
    return true
  }
}

/**
 * Если check не является NaN то вернет false
 * Если является NaN, то возвращает переменную ifNaN, если она не передана, то вернет bool
 *
 * @param {string|float|number} check
 * @param {any} ifNaN
 */
export const checkNan = (check, ifNaN) => {
  try {
    if (Number.isNaN(check)) {
      if (typeof ifNaN !== 'undefined') {
        return ifNaN
      }

      return true
    }

    if (typeof ifNaN !== 'undefined') {
      return ifNaN
    }

    return false
  } catch (e) {
    if (typeof ifNaN !== 'undefined') {
      return ifNaN
    }
    return true
  }
}

/**
 * @param {number} rowCount
 * @param {number} rowsPerPage
 * @returns {number}
 */
export const getNumberOfPages = (rowCount, rowsPerPage) => Math.ceil(rowCount / rowsPerPage)

/**
 * Проверяем существует ли картинка
 * @param {string} imageUrl
 * @returns {Promise<boolean>}
 */
export const checkImageExists = (imageUrl) => {
  return new Promise((resolve, reject) => {
    let imageData = new Image();

    imageData.onload = function () {
      resolve(true);
    };
    imageData.onerror = function () {
      reject(false);
    };

    // not really sure why you have this here, but ok
    imageData.src = imageUrl;
  });
}

/**
 * @param {string|number} prefix
 * @returns {string}
 */
export const generateUniqueKey = (prefix = '_') => prefix + Math.random().toString(36).substr(2, 9)

export const balanceToERC20 = async (address, balance, web3React) => {
  return address === ZERO_ADDRESS
    ? strtodec(balance, 18)
    : await safeFloatToERC20(address, balance, web3React)
}

export const convertETHToWETH = (address) => ZERO_ADDRESS === address || NEW_ETH_ADDRESS === address
  ? WRAPPED_ETH_ADDRESS
  : address

/**
 * Проверяем является строка адресом.
 * Использовать в том случае, если невозможно использовать web3React.library.utils.isAddress
 * @param address
 * @returns {boolean}
 */
export const isTokenAddress = (address) => /^0x[a-fA-F0-9]{40}$/g.test(address)

// this method is not in the data parser, because of a dependency cycle
const getInitialTokenAddressesBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  if (selectedChain === 'BSC' || selectedChain === 'Polygon') {
    return [NEW_ETH_ADDRESS]
  }

  return [NEW_ETH_ADDRESS, ZERO_ADDRESS]
}

export const checkTokenBalance = async ({
  userTokens,
  BN,
  gas,
  gasPrice,
  web3React,
  firstTokenAddress,
  tokenValue,
}) => {
  let isEnough = false
  // here we have 18 hardcoded, because all theree network's tokens have such a value
  const decimals = 18
  const requiredTokenAmountBasedOnNetwork = ERC20ToFloat(new BN(`${parseFloat(gas) * parseFloat(gasPrice)}`), decimals, BN)
  const requiredTokenAmountBasedOnNetworkInWrapper = new BigNumber(requiredTokenAmountBasedOnNetwork)

  if (userTokens) {
    const initialTokenAddresses = getInitialTokenAddressesBasedOnNetwork()
    const foundNativeToken = userTokens.filter((token) => (
      initialTokenAddresses.includes(token.contract_address) || initialTokenAddresses.includes(token.address)
    ))[0]

    if (foundNativeToken) {
      let currentNativeTokenBalance = weiToEth(web3React.library.utils.fromWei, foundNativeToken.balance.toString())

      const wrappedCurrentNativeTokenBalance = new BigNumber(currentNativeTokenBalance)
      const koef = new BigNumber(initialTokenAddresses.includes(firstTokenAddress) ? tokenValue : 0)

      isEnough = wrappedCurrentNativeTokenBalance.isGreaterThanOrEqualTo(requiredTokenAmountBasedOnNetworkInWrapper.plus(koef))
    }
  }

  return {
    isEnough,
    requiredTokenAmountBasedOnNetworkInWrapper,
  }
}

export const getPairForAndSearch = (str) => {
  if (typeof str !== 'string') {
    return {
      search: '',
      pairFor: '',
    }
  }
  const splitStr = str.match(/\b(\w+)\b/g)

  return {
    filterValue: splitStr[0]?.trim() || '',
    pairFor: splitStr[1]?.trim() || '',
  }
}

export const loadTokenData = async ({
  token,
  cancelToken,
  web3React,
  internalSelectedAddress,
  Web3BN,
  tokens,
  latestNativeTokenPrice,
}) => {
  if (![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(token.address)) {
    const { address } = token
    const checkedAddress = web3React.library.utils.toChecksumAddress(address)
    const { decimals, usdtPrice } = await getTokenData(checkedAddress, cancelToken)
    const exactTokenBalance = await getExactTokenBalance({
      tokenAddress: checkedAddress,
      userAddress: internalSelectedAddress,
      decimals,
      Web3BN,
      cancelToken,
    })
    return {
      ...token,
      usdtPrice,
      exactTokenBalance,
      decimals,
    }
  }

  const foundToken = tokens.filter((userToken) => (
    userToken.address === token.address
    || userToken.contract_address === token.address
    || (token.address === ZERO_ADDRESS && userToken.contract_address === NEW_ETH_ADDRESS)
  ))[0]

  return {
    ...token,
    usdtPrice: latestNativeTokenPrice,
    exactTokenBalance: weiToEth(web3React?.library.utils.fromWei, foundToken.balance.toString()),
  }
}

const getMultiplier = (decimals) => {
  let zeros = '0';
  while (zeros.length < 256) { zeros += zeros }

  if (typeof(decimals) !== 'number') {
    decimals = BigNumber.from(decimals).toNumber();
  }

  if (typeof(decimals) === 'number' && decimals >= 0 && decimals <= 256 && !(decimals % 1)) {
    return (`1${zeros.substring(0, decimals)}`);
  }
}

const formatFixed = (value, decimals) => {
  if (decimals == null) { decimals = 0; }
  const multiplier = getMultiplier(decimals);

  value = new BigNumber(value)

  const negative = value.lt(new BigNumber(0));
  if (negative) { value = value.multipliedBy(NegativeOne); }

  let fraction = value.modulo(multiplier).toString();
  while (fraction.length < multiplier.length - 1) { fraction = '0' + fraction; }

  fraction = fraction.match(/^([0-9]*[1-9]|0)(0*)/)[1];

  const whole = value.dividedBy(multiplier).toString();
  value = multiplier.length === 1 ? whole : `${whole}.${fraction}`

  if (negative) { value = `-${value}` }

  return value;
}

const formatUnits = (value, unitName) => {
  if (typeof(unitName) === 'string') {
    const index = names.indexOf(unitName);
    if (index !== -1) { unitName = 3 * index; }
  }
  return formatFixed(value, (unitName != null) ? unitName: 18);
}

const formatBalance = (balance, sep = '', keepDecimals = 6) => {
  balance = balance.toString();
  const [integerPart, decimalPart] = balance.split('.');
  const str = integerPart.toString();
  const chunkSize = 3;
  const result = [];
  for (let i = 0; i < str.length; i += chunkSize) {
    const end = str.length - i;
    const start = end - chunkSize;
    result.unshift(str.substring(start, end));
  }

  let resultIntPart = result.join(sep);

  if (decimalPart) {
    let keptDecimals = decimalPart;
    if (decimalPart.length > keepDecimals) {
      const keptDecimalsArr = decimalPart.slice(0, keepDecimals + 1).split('');
      if (keptDecimalsArr[keptDecimals.length - 1] > '4') {
        keptDecimalsArr[keptDecimals.length - 2] += 1;
      }
      keptDecimals = keptDecimalsArr.slice(0, keepDecimals).join('');
    }
    if (!resultIntPart) {
      resultIntPart = '0';
    }
    if (keptDecimals) {
      return `${resultIntPart}.${keptDecimals}`;
    }
  }
  return resultIntPart;
}

export const formatDecimal = (amount, decimalCount = 18) => {
  const unitFormateed = formatUnits(amount || 0, decimalCount);
  const numstr = formatBalance(unitFormateed, ',');

  const dot = numstr.split('.')[1];
  if (dot === '0' || dot === '00') {
    return numstr.split('.')[0];
  }

  return numstr;
}