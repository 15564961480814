/* eslint-disable */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import pick from 'lodash/pick'
import {
  CREATE_CYBER_WALLET_FAILURE,
  CREATE_CYBER_WALLET_SUCCESS,
  SIGN_METAMASK_SUCCESS,
  SIGN_SIGNATURE_SUCCESS,
} from '../action/signAction'
import {
  userLogin,
  userConnectCreate,
  getDataSelectWallet,
  createTokenSwapOrder,
  apiGetDataUser,
  apiTransferOrder,
  confirmOrder,
  getEthPrice,
  getBnbPrice,
  getMaticPrice,
} from '../../service/api'
import { getAllERC20Transfers } from '../../service/etherscanApi'
import find from "lodash/find";

export const login = createAsyncThunk(
  'user/login',
  /**
   * @param {object} data
   * @param {string} data.sign
   * @param {string} data.message
   * @param rejectWithValue
   * @returns {Promise<AxiosResponse<any>|RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown>>}
   */
  async (data = {}, { rejectWithValue }) => {
    try {
      return await userConnectCreate({ signature: data.sign, message: data.message })
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const getMessage = createAsyncThunk(
  'user/getMessage',
  /**
   * @param {string} account
   * @param {object} web3React
   * @param rejectWithValue
   * @returns {Promise<AxiosResponse<any>|RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown>>}
   */
  async ({ account, web3React }, { rejectWithValue }) => {
    try {
      const { data: { message } } = await userLogin()
      const sign = await web3React.library.eth.personal.sign(message, account[0])
      return await userConnectCreate({ signature: sign, message })
    } catch (err) {
      console.error(err)
      return rejectWithValue(err.response.data)
    }
  }
)

export const getDataWallet = createAsyncThunk(
  'user/getDataWallet',
  /**
   * @param {string} address
   * @param {object} cancelTokenSource
   * @param rejectWithValue
   * @returns {Promise<RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown>|AxiosResponse<*>>}
   */
  async ({ address, cancelTokenSource }, { rejectWithValue }) => {
    if (address) {
      try {
        const walletData = await getDataSelectWallet(address, cancelTokenSource?.token)
        const selectedChain = localStorage.getItem('selectedChain')

        let chosenMethod = getEthPrice
        if (selectedChain === 'BSC') {
          chosenMethod = getBnbPrice
        } else if (selectedChain === 'Polygon') {
          chosenMethod = getMaticPrice
        }

        const latestNativeTokenPrice = (await chosenMethod()).data.price
        return {
          data: {
            ...walletData,
            latestNativeTokenPrice
          }
        }
      } catch (e) {
        console.error(e)
        return rejectWithValue(e)
      }
    }
    return rejectWithValue({ error: `address doesn't not exist` })
  }
)

export const getDataExternalWallet = createAsyncThunk(
  'user/getDataExternalWallet',
  /**
   * @param {string} address
   * @param {object} cancelTokenSource
   * @param rejectWithValue
   * @returns {Promise<RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown>|AxiosResponse<*>>}
   */
  async ({ address, cancelTokenSource }, { rejectWithValue }) => {
    if (address) {
      try {
        const walletData = await getDataSelectWallet(address, cancelTokenSource?.token)

        return {
          data: {
            ...walletData,
          }
        }
      } catch (e) {
        console.error(e)
        return rejectWithValue(e)
      }
    }
    return rejectWithValue({ error: `address doesn't not exist` })
  }
)

export const getInitialDataUser = createAsyncThunk(
  'user/getInitialDataUser',
  /**
   * @param {string} address
   * @param rejectWithValue
   * @returns {Promise<AxiosResponse<any>|RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown>>}
   */
  async (address, { rejectWithValue }) => {
    try {
      return await apiGetDataUser()
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  }
)

export const getDataUser = createAsyncThunk(
  'user/getDataUser',
  /**
   * @param rejectWithValue
   * @returns {Promise<AxiosResponse<any>|RejectWithValue<{} extends {rejectValue: infer RejectValue} ? RejectValue : unknown>>}
   */
  async ({}, { rejectWithValue }) => {
    try {
      return await apiGetDataUser()
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  }
)

// TODO: remove once new trade is enabled
export const postTokenSwapOrder = createAsyncThunk(
  'user/tokenSwapOrder',
  async (data, { rejectWithValue }) => {
    try {
      return await createTokenSwapOrder(data)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  }
)

export const postConfirmOrder = createAsyncThunk(
  'user/confrimOrder',
  async (data, { rejectWithValue }) => {
    try {
      return await confirmOrder(data)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  }
)

export const transferOrder = createAsyncThunk(
  'user/transferOrder',
  (data, {rejectWithValue}) => {
    try {
      return apiTransferOrder(data)
    } catch (e) {
      console.log(e)
      return rejectWithValue(e)
    }
  }
)

export const getERC20Transfers = createAsyncThunk(
  'user/getERC20Transfers',
  async (data, { rejectWithValue }) => {
    try {
      return await getAllERC20Transfers(data)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  }
)

export const user = createSlice({
  name: 'user',
  initialState: {
    id: 0,
    username: '',
    loading: true,
    accessToken: '',
    signMessage: undefined,
    cyberWallet: undefined,
    signature: undefined,
    needConfirm: false,
    transactionsToConfirm: [],
    errors: {
      trade: undefined,
    },
    internalAddresses: [],
    externalAddresses: [],
    externalTokens: [],
    tokens: [],
    tokensAssets: [],
    balance: 0,
    newTokens: false,
    selectedAddress: undefined,
    internalSelectedAddress: undefined,
    connectCyberWallet: false,
    needReloadOrderbook: false,
    areInternalTokensLoading: false,
    areExternalTokensLoading: false,
    latestNativeTokenPrice: 0,
    isGodModeEnabled: false,
  },
  reducers: {
    updateData(state, action) {
      state.user = pick(action.payload, [
        'id',
        'username',
        'loading',
        'accessToken',
      ])
    },
    signReducer(state, action) {
      switch (action.type) {
        case SIGN_METAMASK_SUCCESS:
          return { ...state, signMessage: action.signMessage }
        case SIGN_SIGNATURE_SUCCESS:
          return { ...state, signature: action.signature }
        case CREATE_CYBER_WALLET_SUCCESS:
          return { ...state, cyberWallet: action.cyberWallet }
        case CREATE_CYBER_WALLET_FAILURE:
          return { ...state, errors: action.errors }
        default:
          return state
      }
    },
    updateConnectCyberWallet (state, action) {
      return { ...state, connectCyberWallet: action.payload }
    },
    allTransactionsConfirmed(state, action) {
      return {...state, needConfirm: false, transactionsToConfirm: []}
    },
    setNeedReloadOrderbook(state, action) {
      return { ...state, needReloadOrderbook: action.payload }
    },
    toggleGodModeStatus(state, action) {
      return {
        ...state,
        isGodModeEnabled: action.payload,
      }
    },
    updateNameCyberWallet(state, action) {
      state.internalAddresses = state.internalAddresses.map(obj => [action.payload].find(o => o.address === obj.address) || obj);
    },
  },
  extraReducers: {
    [login.pending.type]: (state) => {
      state.loading = true
    },
    [login.fulfilled.type]: (state, action) => {
      state.loading = false
      state.accessToken = action.payload.data.access_token
      state.internalAddresses = action.payload.data.user.internal_addresses
      state.externalAddresses = action.payload.data.user.external_addresses

      localStorage.setItem('accessToken', action.payload.data.access_token);
    },
    [login.rejected.type]: (state) => {
      state.loading = false
    },
    [getMessage.fulfilled.type]: (state, action) => {
      state.accessToken = action.payload.data.access_token
      state.internalAddresses = action.payload.data.user.internal_addresses
      state.externalAddresses = action.payload.data.user.external_addresses

      localStorage.setItem('accessToken', action.payload.data.access_token);
    },
    [getDataWallet.pending.type]: (state, action) => {
      state.areInternalTokensLoading = action.meta?.arg?.withLoader
    },
    [getDataWallet.fulfilled.type]: (state, action) => {
      state.areInternalTokensLoading = false
      state.tokens = action.payload.data.tokens
      state.tokensAssets = action.payload.data.tokens
      state.balance = action.payload.data.balance
      state.newTokens = action.payload.data.new_tokens
      state.latestNativeTokenPrice = action.payload.data.latestNativeTokenPrice
    },
    [getDataExternalWallet.fulfilled.type]: (state, action) => {
      state.areExternalTokensLoading = false
      state.externalTokens = action.payload.data.tokens
    },
    [getInitialDataUser.pending.type]: (state) => {
      state.loading = true
    },
    [getInitialDataUser.fulfilled.type]: (state, action) => {
      state.loading = false
      state.internalAddresses = action.payload.user.internal_addresses
      state.externalAddresses = action.payload.user.external_addresses
      const foundAddress = find(action.payload.user.internal_addresses, { address: localStorage.getItem('internalSelectedAddress') })
      state.isGodModeEnabled = new Date(foundAddress?.advanced_mode_end_time) > new Date()
    },
    [getInitialDataUser.rejected.type]: (state) => {
      state.loading = false
    },
    [getDataUser.fulfilled.type]: (state, action) => {
      state.internalAddresses = action.payload.user.internal_addresses
      state.externalAddresses = action.payload.user.external_addresses
      const foundAddress = find(action.payload.user.internal_addresses, { address:  localStorage.getItem('internalSelectedAddress') })
      state.isGodModeEnabled = new Date(foundAddress?.advanced_mode_end_time) > new Date()
    },
    [transferOrder.fulfilled.type]: (state, action) => {
      state.needConfirm = true
      state.transactionsToConfirm = action.payload.data.transactions?.map(el => {
        el['asset'] = action.payload.data.asset?.symbol
        el['decimals'] = action.payload.data.asset?.decimals
        el['tokenValue'] = action.payload.data.asset_value
        el['address'] = action.payload.data.asset.address
        return el
      })
    },
    [postTokenSwapOrder.fulfilled.type]: (state, action) => {
      state.transactionsToConfirm = action.payload.data.transactions?.map(el => {
        el['triggers'] = action.payload.data.triggers
        el['asset'] = action.payload.data.asset0?.symbol
        el['tokenValue'] = action.payload.data.asset0_value
        el['decimals'] = action.payload.data.asset0.decimals
        el['asset0'] = {
          decimals: action.payload.data.asset0.decimals,
          symbol: action.payload.data.asset0.symbol,
          name: action.payload.data.asset0.name,
          address: action.payload.data.asset0.address,
        }
        el['asset1'] = {
          decimals: action.payload.data.asset1.decimals,
          symbol: action.payload.data.asset1.symbol,
          name: action.payload.data.asset1.name,
          address: action.payload.data.asset1.address,
        }

        return el
      })
      state.needConfirm = true
      state.errors.trade = undefined
    },
    [postTokenSwapOrder.rejected.type]: (state, action) => {
      state.errors.trade = action.payload.response.data.detail
    },
    [postConfirmOrder.fulfilled.type]: (state, action) => {
      state.transactionsToConfirm = state.transactionsToConfirm.slice(1)
      state.needConfirm = state.transactionsToConfirm.length > 0

      if (state.transactionsToConfirm.length === 0) {
        state.needReloadOrderbook = true
      }
    },
    [getERC20Transfers.fulfilled.type]: (state, action) => {
      state.currentERC20Transactions = action.payload.data
    }
  },
})

export const { updateConnectCyberWallet, setNeedReloadOrderbook, toggleGodModeStatus, updateNameCyberWallet } = user.actions
