/* eslint-disable */
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles/testMagicLink.scss'

import { BtnForm } from '../components/BtnForm/BtnForm'
import { H1 } from '../components/H1/H1'
import { LIST_WEB3_PROVIDER } from '../const/web3'
import { useWeb3 } from '../context/web3Provider'

const STEPS = {
  selectLogin: 'selectLogin',
  login: 'login',
  sign: 'sign',
}

export const TestMagicLink = () => {
  const dispatch = useDispatch()
  const web3React = useWeb3()

  const [currentStep, setCurrentStep] = useState(STEPS.selectLogin)
  const [email, setEmail] = useState('')

  const renderLoginMagic = useMemo(() => {
    const login = async () => {
      try {
        await web3ProviderState.magic.auth.loginWithMagicLink({ email })

        setCurrentStep(STEPS.sign)
      } catch (e) {
        console.error('login Magic', e)
      }
    }

    return (
      <div>
        <H1
          className="h1--primary confirm-order__title"
          text="Please sign up or login"
        />

        <div style={{ marginTop: '16px' }}>
          <input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            value={email}
            className="select-currency-chart__input"
            type="text"
            placeholder="email"
          />
        </div>

        <div style={{ marginTop: '8px' }}>
          <BtnForm
            onClick={login}
            text="Login"
          />
        </div>
      </div>
    )
  }, [email, web3ProviderState.magic.auth])
  const renderSelectLogin = useMemo(() => (
    <div>
      <div className="TestMagicLink-selectLogin">
        <BtnForm
          onClick={() => {
            dispatch(updateSelectedProvider(LIST_WEB3_PROVIDER.magic))
            setCurrentStep(STEPS.login)
          }}
          text="Magic"
        />
      </div>

      <div className="TestMagicLink-selectLogin">
        <BtnForm
          onClick={() => {
            dispatch(updateSelectedProvider(LIST_WEB3_PROVIDER.walletConnect))
            setCurrentStep(STEPS.login)
          }}
          text="Wallet Connect"
        />
      </div>

      <div className="TestMagicLink-selectLogin">
        <BtnForm
          onClick={() => {
            dispatch(updateSelectedProvider(LIST_WEB3_PROVIDER.metamask))
            setCurrentStep(STEPS.login)
          }}
          text="Web3"
        />
      </div>
    </div>
  ), [dispatch])
  const renderSign = useMemo(() => {
    const callSign = async () => {
      const originalMessage = 'YOUR_MESSAGE'

      try {
        const fromAddress = (await web3React.library.eth.getAccounts())[0]

        const signedMessage = await web3React.library.eth.personal.sign(originalMessage, fromAddress)
        console.log(`Signed Message: ${signedMessage}`)
      } catch (e) {
        console.error('renderSign', e)
      }
    }

    return (
      <div>
        <BtnForm
          onClick={callSign}
          text="Sign"
        />
      </div>
    )
  }, [web3React])
  const renderLoginWalletConnect = useMemo(() => (
    <div>
      {renderSign}
    </div>
  ), [renderSign])
  const renderLogin = useMemo(() => {
    switch (web3ProviderState.selectedProvider) {
      case LIST_WEB3_PROVIDER.magic:
        return renderLoginMagic
      case LIST_WEB3_PROVIDER.walletConnect:
        return renderLoginWalletConnect
      default:
        return <div />
    }
  }, [renderLoginMagic, renderLoginWalletConnect, web3ProviderState.selectedProvider])

  const renderStep = useMemo(() => {
    switch (currentStep) {
      case STEPS.selectLogin:
        return renderSelectLogin
      case STEPS.login:
        return renderLogin
      case STEPS.sign:
        return renderSign
      default:
        return <div />
    }
  }, [currentStep, renderSelectLogin, renderLogin, renderSign])

  return (
    <div className="TestMagicLink">
      {renderStep}
    </div>
  )
}
