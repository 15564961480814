import React from 'react'
import PropTypes from 'prop-types'
import './level-features.scss'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'

const LevelFeatures = ({
  top,
  bottom,
}) => (
  <div className="level-features">
    <div className="level-features__row">
      {top.map((item, index) => (
        <div
          className={`level-features__col ${item.disabled ? 'disabled' : ''}`}
          key={index}
        >
          <BaseTextBody>{item.text}</BaseTextBody>

          {item.orders && (
            <div className="level-features__orders">
              {item.orders.map((order, orderIndex) => (
                <div className="level-features__order" key={orderIndex}>
                  <BaseTextBody
                    size="S"
                  >
                    {order}
                  </BaseTextBody>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>

    <div className="level-features__row">
      {bottom.map((item, index) => (
        <div
          className={`level-features__col ${item.disabled ? 'disabled' : ''}`}
          key={index}
        >
          <BaseTextBody>{item.text}</BaseTextBody>

          {item.orders && (
            <div className="level-features__orders">
              {item.orders.map((order, orderIndex) => (
                <div className="level-features__order" key={orderIndex}>
                  <BaseTextBody
                    size="S"
                  >
                    {order}
                  </BaseTextBody>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
)

const props = PropTypes.arrayOf(PropTypes.shape({
  text: PropTypes.string,
  disabled: PropTypes.bool,
  orders: PropTypes.arrayOf(PropTypes.string),
}))
LevelFeatures.propTypes = {
  top: props,
  bottom: props,
}

export default LevelFeatures
