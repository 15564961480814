import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import { Text } from '../Text/Text'

import './item-from-currency.scss'

export const ItemFromCurrency = ({
  className,
  title,
  iconName,
  iconId,
  keys,
}) => (
  <div className={`item-from-currency ${className || ''}`}>
    <Text
      className="text--12-16 item-from-currency__title"
      text={title}
    />
    <div className="item-from-currency__body">
      <Icon
        mod="icon--12"
        name={iconName}
        id={iconId}
      />

      <Text
        className="item-from-currency__keys"
        text={keys}
      />
    </div>
  </div>
)

ItemFromCurrency.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  iconName: PropTypes.string,
  iconId: PropTypes.string,
  keys: PropTypes.string,
}
