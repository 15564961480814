import React from 'react'
import PropTypes from 'prop-types'
import './first-screen-banner.scss'
import IconFire from '../Icon/icon--fire.svg'
import IconProfile from '../Icon/icon--profile.svg'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { Icon } from '../Icon/Icon'
import { BaseTextBody } from '../BaseText/BaseText'

const FirstScreenBanner = ({
  headerLeft,
  backgroundImg,
  bodyImg,
  children,
}) => (
  <div
    className="first-screen-banner"
    style={{ backgroundImage: `url(${backgroundImg})` }}
  >
    <div className="base-container">
      <div className="first-screen-banner__header">
        <div className="first-screen-banner__header-left">
          <BaseTextBody>{headerLeft}</BaseTextBody>
        </div>

        <div className="first-screen-banner__menu">
          <ExternalLink
            text="Loyalty"
            link="#"
            icon={<Icon name={IconFire} id="icon--fire" />}
          />

          <ExternalLink
            text="Profile"
            link="#"
            icon={<Icon name={IconProfile} id="icon--profile" />}
          />
        </div>
      </div>

      <div className="first-screen-banner__body">
        {children}

        {bodyImg && (
          <img src={bodyImg} alt="" className="first-screen-banner__body-img" />
        )}
      </div>
    </div>
  </div>
)

FirstScreenBanner.propTypes = {
  headerLeft: PropTypes.node,
  backgroundImg: PropTypes.string,
  bodyImg: PropTypes.string,
  children: PropTypes.node,
}

export default FirstScreenBanner
