import React from 'react'

import './page404.scss'
import { Social } from '../../../../components/Social/Social'
import { Logo } from '../../../../components/Logo/Logo'
import Message from '../../../../components/Message/Message'
// import { BaseSearch } from '../../../components/BaseSearch/BaseSearch'

const Page404 = () => (
  <div className="page404">
    <div className="page404__header">
      <Logo />
    </div>

    <div className="page404__body">
      <div className="page404__content">
        <Message
          status="warning"
          title="Sorry, that page cannot be found"
          size="L"
        />

        {/* TODO: Временно скрыто */}
        {/* <div className="page404__search"> */}
        {/*  <BaseSearch */}
        {/*    placeholder="Search for assets and pairs..." */}
        {/*  /> */}
        {/* </div> */}
      </div>
    </div>

    <div className="page404__footer">
      <Social
        twitter
        discord
        telegram
      />
    </div>
  </div>
)

export default Page404
