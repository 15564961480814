export const BREAKPOINT = {
  xs: 0,
  md: 768,
  lg: 1024,
  xl: 1440,
  fhd: 1920,
}

export const BREAKPOINT_NAME = {
  xs: 'xs',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  fhd: 'fhd',
}
