import instance from '../axios-api'

export const COINGEKO_SUPPORT_CHAIN = {
  ethereum: 'ethereum',
  polygon: 'polygon-pos',
  bsc: 'binance-smart-chain',
}

export const getTokenPrice = async (contract) => {
  const selectedChain = (localStorage.getItem('selectedChain')).toLowerCase()
  const getPriceUrl = `https://api.coingecko.com/api/v3/coins/${COINGEKO_SUPPORT_CHAIN[selectedChain]}/contract/${contract.toLowerCase()}`

  try {
    const response = await instance.get(getPriceUrl)
    return {
      symbol: response.data?.symbol,
      name: response.data?.name,
      price: response.data?.market_data?.current_price?.usd,
    }
  } catch (e) {
    console.error('getTokenPrice', e.response.data?.error)

    return {
      symbol: '',
      name: '',
      price: 0,
    }
  }
}
