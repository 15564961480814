import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector } from 'react-redux'
import { AssetTrandList } from './asset-trand-list/AssetTrandList'
import { getTopAsset } from '../../../../service/api'
import { BREAKPOINT_NAME } from '../../../../const/breakpoint'
import { formatEth } from '../../../../utils/helperFunctions'
import { getGas } from '../../../../service/gas'
import { useGetDownBreakpoint } from '../../../../hooks/useGetBreakpoint'
import IconEthereum from '../../../../components/Icon/icon--ethereum.svg'
import { IconText } from '../../../../components/IconText/IconText'
import IconGasStation from '../../../../components/Icon/icon--gas-station.svg'
import Loading from '../../../../components/Loading/Loading'
import IconBsc from '../../../../components/Icon/icon--bsc.png'
import IconPolygon from '../../../../components/Icon/icon--polygon.png'

import './top-menu.scss'

export const TopMenu = () => {
  const [selectedChain] = useLocalStorage('selectedChain')
  const userState = useSelector((store) => store.user)
  const [dataAssetTopTrading, setDataAssetTopTrading] = useState({ ethPrice: 0, list: [], listMore: [] })
  const [gasPrice, setGasPrice] = useState(0)
  const [, currentBreakpoint] = useGetDownBreakpoint()
  const [isLoadingEthPrice, setIsLoadingEthPrice] = useState(true)
  const [isLoadingGasPrice, setIsLoadingGasPrice] = useState(true)

  const formatTopAsset = useCallback((item, index) => {
    let direction

    switch (item.direction) {
      case 'up':
        direction = 'up'
        break
      case 'down':
        direction = 'down'
        break
      default:
        direction = 'flat'
        break
    }

    return {
      number: `#${index}`,
      text: item.symbol,
      value: `(${item.priceChange.toFixed(2)}%)`,
      valueType: direction,
      tokenPrice: formatEth(item.price),
      tokenAddress: item.address || item.id,
    }
  }, [])

  useEffect(() => {
    setIsLoadingEthPrice(userState.latestNativeTokenPrice === 0)
  }, [userState.latestNativeTokenPrice])

  useEffect(() => {
    setIsLoadingGasPrice(gasPrice === 0)
  }, [gasPrice])

  useEffect(() => {
    let isMounted = true
    const asyncSetDataTopTrading = async () => {
      try {
        const data = await getTopAsset()
        let indexSplice = 3

        switch (currentBreakpoint) {
          case BREAKPOINT_NAME.fhd:
            indexSplice = 7
            break
          case BREAKPOINT_NAME.xl:
            indexSplice = 4
            break
          default:
            indexSplice = 3
            break
        }

        const secondArr = data.investIndexData
        const firstArr = secondArr.splice(0, indexSplice)

        const newDataAssetTop = {
          ethPrice: formatEth(userState.latestNativeTokenPrice, 2),
          list: firstArr.map((item) => formatTopAsset(item, item.position + 1)),
          listMore: secondArr.map((item) => formatTopAsset(item, item.position + 1)),
        }

        if (isMounted) {
          setDataAssetTopTrading(newDataAssetTop)
        }
      } catch (e) {
        console.error('getTopAssets', e)
      }

      try {
        const { data: { fastest } } = await getGas()

        setGasPrice(fastest / 10)
      } catch (e) {
        console.error(e)
      }
    }

    asyncSetDataTopTrading()

    return () => { isMounted = false }
  }, [
    formatTopAsset,
    currentBreakpoint,
    userState.internalAddresses,
    userState.latestNativeTokenPrice,
  ])

  const getTokenIcon = useMemo(() => {
    let icon = IconEthereum

    if (selectedChain === 'BSC') {
      icon = IconBsc
    } else if (selectedChain === 'Polygon') {
      icon = IconPolygon
    }

    return (
      <IconText
        isImg
        imgSrc={icon}
        text={isLoadingEthPrice ? <Loading size="S" /> : `$${dataAssetTopTrading.ethPrice}`}
        className="icon-text--secondary icon-text--sm"
      />
    )
  }, [selectedChain, isLoadingEthPrice, dataAssetTopTrading.ethPrice])

  return (
    <div className="asset-top-tranding-menu">
      <div className="asset-top-tranding-menu__list">
        <AssetTrandList
          listMore={dataAssetTopTrading.listMore}
          list={dataAssetTopTrading.list}
        />
      </div>

      <div className="asset-top-tranding-menu__currencies">
        <div data-testid="header--top-trend-tokens--eth-price" className="asset-top-tranding-menu__currencies__box">
          {getTokenIcon}
        </div>

        <div data-testid="header--top-trend-tokens--gas-price" className="asset-top-tranding-menu__currencies__box">
          <IconText
            isImg
            imgSrc={IconGasStation}
            className="icon-text--secondary icon-text--sm"
            text={isLoadingGasPrice ? <Loading size="S" /> : `${gasPrice} GWEI`}
          />
        </div>
      </div>
    </div>
  )
}
