import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { SelectBase } from '../../../../../../../../components/SelectBase/SelectBase'
import IconSwitcher from '../../../../../../../../components/Icon/icon--switcher.svg'

import './god-mod-content.scss'
import { Input } from '../../../../../../../../components/Input/Input'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconRadioButtonUnchecked from '../../../../../../../../components/Icon/icon--radio-button-unchecked.svg'
import IconRadioButtonChecked from '../../../../../../../../components/Icon/icon--radio-button-checked.svg'

export const GodModContent = ({
  error,
  selectedExpire,
  onChangeInput,
  inputValue,
  onChangeSelectBase,
}) => {
  const selectBaseItems = useMemo(() => {
    const arrValue = [1, 6, 12, 24]

    return arrValue.map((item) => {
      let iconSelect = (
        <Icon
          name={IconRadioButtonUnchecked}
          id="icon--radio-button-unchecked"
          mod="icon--22"
        />
      )

      if (selectedExpire === item) {
        iconSelect = (
          <Icon
            name={IconRadioButtonChecked}
            id="icon--radio-button-checked"
            mod="icon--22"
          />
        )
      }

      return {
        name: `${item} hour`,
        value: item,
        icon: iconSelect,
      }
    })
  }, [selectedExpire])

  return (
    <div className="god-mod-content">
      <div className="god-mod-content__left">
        <div className="god-mod-content__title">
          <BaseTextBody secondary>
            Cyber Wallet 8 Digit PIN
          </BaseTextBody>
        </div>

        <Input
          dataTestid="popup--god-mode-input-password"
          type="password"
          name="input_default"
          value={inputValue}
          showPass
          onChange={onChangeInput}
        />

        {error
        && (
          <div className="god-mod-content__error">
            <BaseTextBody
              size="S"
            >
              {error}
            </BaseTextBody>
          </div>
        )}
      </div>

      <div className="god-mod-content__right">
        <div className="god-mod-content__title">
          <BaseTextBody secondary>
            Auto Disable After
          </BaseTextBody>
        </div>

        <SelectBase
          selected={`${selectedExpire} hour`}
          className="select-base--new"
          headerIcon={IconSwitcher}
          headerIconID="icon--switcher"
          items={selectBaseItems}
          onChange={onChangeSelectBase}
        />
      </div>
    </div>
  )
}

GodModContent.propTypes = {
  error: PropTypes.string,
  inputValue: PropTypes.string,
  selectedExpire: PropTypes.string,
  onChangeInput: PropTypes.func,
  onChangeSelectBase: PropTypes.func,
}
