import instance from '../axios-api'
import { parseBridgeHistory } from '../parser/api'

export const getBrigdeHistory = async ({
  listType,
  address,
  pageSize,
  nextPageToken,
}) => {
  try {
    let { data } = await instance.get('https://cbridge-v2-test.celer.network/v1/transferHistory', {
      params: {
        addr: address,
        page_size: pageSize || 5,
        next_page_token: nextPageToken,
      },
    })

    // this is for testing purposes, for test gate away network
    data = {
      err: null,
      history: [
        {
          transfer_id: '0x6689771bca58011c841a98908007f6117d542485dd9388ca98a7f39ed2f02626',
          src_send_info: {
            chain: {
              id: 1,
              name: 'Ethereum Mainnet',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/ETH.png',
              block_delay: 8,
              gas_token_symbol: 'ETH',
              explore_url: 'https://etherscan.io/',
              contract_addr: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
              drop_gas_amt: '0',
              drop_gas_cost_amt: '0',
              drop_gas_balance_alert: '0',
              suggested_gas_cost: '0',
            },
            token: {
              symbol: 'WETH',
              address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '6000000000000000',
          },
          dst_received_info: {
            chain: {
              id: 137,
              name: 'Polygon',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/Polygon.png',
              block_delay: 80,
              gas_token_symbol: 'MATIC',
              explore_url: 'https://polygonscan.com/',
              contract_addr: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
              drop_gas_amt: '30000000000000000',
              drop_gas_cost_amt: '650000000000000',
              drop_gas_balance_alert: '10000000000000000000',
              suggested_gas_cost: '152100',
            },
            token: {
              symbol: 'WETH',
              address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '5972450823794323',
          },
          ts: '1644313665986',
          src_block_tx_link: 'https://etherscan.io/tx/0x3af94cd6d1a4975769d5bdb7884af105cf80fa1a8bf93087d4446c8728ef5439',
          dst_block_tx_link: 'https://polygonscan.com/tx/0x84aa8fc54bf1ba2a6c662dca36ddb7619c3adedfaa254d78ca6c941bccd72758',
          status: 5,
          refund_reason: 2,
        },
        {
          transfer_id: '0x6689771bca58011c841a98908007f6117d542485dd9388ca98a7f39ed2f02626',
          src_send_info: {
            chain: {
              id: 1,
              name: 'Ethereum Mainnet',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/ETH.png',
              block_delay: 8,
              gas_token_symbol: 'ETH',
              explore_url: 'https://etherscan.io/',
              contract_addr: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
              drop_gas_amt: '0',
              drop_gas_cost_amt: '0',
              drop_gas_balance_alert: '0',
              suggested_gas_cost: '0',
            },
            token: {
              symbol: 'WETH',
              address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '6000000000000000',
          },
          dst_received_info: {
            chain: {
              id: 137,
              name: 'Polygon',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/Polygon.png',
              block_delay: 80,
              gas_token_symbol: 'MATIC',
              explore_url: 'https://polygonscan.com/',
              contract_addr: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
              drop_gas_amt: '30000000000000000',
              drop_gas_cost_amt: '650000000000000',
              drop_gas_balance_alert: '10000000000000000000',
              suggested_gas_cost: '152100',
            },
            token: {
              symbol: 'WETH',
              address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '5972450823794323',
          },
          ts: '1644313665986',
          src_block_tx_link: 'https://etherscan.io/tx/0x3af94cd6d1a4975769d5bdb7884af105cf80fa1a8bf93087d4446c8728ef5439',
          dst_block_tx_link: 'https://polygonscan.com/tx/0x84aa8fc54bf1ba2a6c662dca36ddb7619c3adedfaa254d78ca6c941bccd72758',
          status: 2,
          refund_reason: 2,
        },
        {
          transfer_id: '0x6689771bca58011c841a98908007f6117d542485dd9388ca98a7f39ed2f02626',
          src_send_info: {
            chain: {
              id: 1,
              name: 'Ethereum Mainnet',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/ETH.png',
              block_delay: 8,
              gas_token_symbol: 'ETH',
              explore_url: 'https://etherscan.io/',
              contract_addr: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
              drop_gas_amt: '0',
              drop_gas_cost_amt: '0',
              drop_gas_balance_alert: '0',
              suggested_gas_cost: '0',
            },
            token: {
              symbol: 'WETH',
              address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '6000000000000000',
          },
          dst_received_info: {
            chain: {
              id: 137,
              name: 'Polygon',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/Polygon.png',
              block_delay: 80,
              gas_token_symbol: 'MATIC',
              explore_url: 'https://polygonscan.com/',
              contract_addr: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
              drop_gas_amt: '30000000000000000',
              drop_gas_cost_amt: '650000000000000',
              drop_gas_balance_alert: '10000000000000000000',
              suggested_gas_cost: '152100',
            },
            token: {
              symbol: 'WETH',
              address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '5972450823794323',
          },
          ts: '1644313665986',
          src_block_tx_link: 'https://etherscan.io/tx/0x3af94cd6d1a4975769d5bdb7884af105cf80fa1a8bf93087d4446c8728ef5439',
          dst_block_tx_link: 'https://polygonscan.com/tx/0x84aa8fc54bf1ba2a6c662dca36ddb7619c3adedfaa254d78ca6c941bccd72758',
          status: 2,
          refund_reason: 2,
        },
        {
          transfer_id: '0x6689771bca58011c841a98908007f6117d542485dd9388ca98a7f39ed2f02626',
          src_send_info: {
            chain: {
              id: 1,
              name: 'Ethereum Mainnet',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/ETH.png',
              block_delay: 8,
              gas_token_symbol: 'ETH',
              explore_url: 'https://etherscan.io/',
              contract_addr: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
              drop_gas_amt: '0',
              drop_gas_cost_amt: '0',
              drop_gas_balance_alert: '0',
              suggested_gas_cost: '0',
            },
            token: {
              symbol: 'WETH',
              address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '6000000000000000',
          },
          dst_received_info: {
            chain: {
              id: 137,
              name: 'Polygon',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/Polygon.png',
              block_delay: 80,
              gas_token_symbol: 'MATIC',
              explore_url: 'https://polygonscan.com/',
              contract_addr: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
              drop_gas_amt: '30000000000000000',
              drop_gas_cost_amt: '650000000000000',
              drop_gas_balance_alert: '10000000000000000000',
              suggested_gas_cost: '152100',
            },
            token: {
              symbol: 'WETH',
              address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '5972450823794323',
          },
          ts: '1644313665986',
          src_block_tx_link: 'https://etherscan.io/tx/0x3af94cd6d1a4975769d5bdb7884af105cf80fa1a8bf93087d4446c8728ef5439',
          dst_block_tx_link: 'https://polygonscan.com/tx/0x84aa8fc54bf1ba2a6c662dca36ddb7619c3adedfaa254d78ca6c941bccd72758',
          status: 5,
          refund_reason: 2,
        },
        {
          transfer_id: '0x6689771bca58011c841a98908007f6117d542485dd9388ca98a7f39ed2f02626',
          src_send_info: {
            chain: {
              id: 1,
              name: 'Ethereum Mainnet',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/ETH.png',
              block_delay: 8,
              gas_token_symbol: 'ETH',
              explore_url: 'https://etherscan.io/',
              contract_addr: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
              drop_gas_amt: '0',
              drop_gas_cost_amt: '0',
              drop_gas_balance_alert: '0',
              suggested_gas_cost: '0',
            },
            token: {
              symbol: 'WETH',
              address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '6000000000000000',
          },
          dst_received_info: {
            chain: {
              id: 137,
              name: 'Polygon',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/Polygon.png',
              block_delay: 80,
              gas_token_symbol: 'MATIC',
              explore_url: 'https://polygonscan.com/',
              contract_addr: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
              drop_gas_amt: '30000000000000000',
              drop_gas_cost_amt: '650000000000000',
              drop_gas_balance_alert: '10000000000000000000',
              suggested_gas_cost: '152100',
            },
            token: {
              symbol: 'WETH',
              address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '5972450823794323',
          },
          ts: '1644313665986',
          src_block_tx_link: 'https://etherscan.io/tx/0x3af94cd6d1a4975769d5bdb7884af105cf80fa1a8bf93087d4446c8728ef5439',
          dst_block_tx_link: 'https://polygonscan.com/tx/0x84aa8fc54bf1ba2a6c662dca36ddb7619c3adedfaa254d78ca6c941bccd72758',
          status: 5,
          refund_reason: 2,
        },
        {
          transfer_id: '0x6689771bca58011c841a98908007f6117d542485dd9388ca98a7f39ed2f02626',
          src_send_info: {
            chain: {
              id: 1,
              name: 'Ethereum Mainnet',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/ETH.png',
              block_delay: 8,
              gas_token_symbol: 'ETH',
              explore_url: 'https://etherscan.io/',
              contract_addr: '0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820',
              drop_gas_amt: '0',
              drop_gas_cost_amt: '0',
              drop_gas_balance_alert: '0',
              suggested_gas_cost: '0',
            },
            token: {
              symbol: 'WETH',
              address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '6000000000000000',
          },
          dst_received_info: {
            chain: {
              id: 137,
              name: 'Polygon',
              icon: 'https://get.celer.app/cbridge-icons/chain-icon/Polygon.png',
              block_delay: 80,
              gas_token_symbol: 'MATIC',
              explore_url: 'https://polygonscan.com/',
              contract_addr: '0x88DCDC47D2f83a99CF0000FDF667A468bB958a78',
              drop_gas_amt: '30000000000000000',
              drop_gas_cost_amt: '650000000000000',
              drop_gas_balance_alert: '10000000000000000000',
              suggested_gas_cost: '152100',
            },
            token: {
              symbol: 'WETH',
              address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
              decimal: 18,
              xfer_disabled: false,
            },
            amount: '5972450823794323',
          },
          ts: '1644313665986',
          src_block_tx_link: 'https://etherscan.io/tx/0x3af94cd6d1a4975769d5bdb7884af105cf80fa1a8bf93087d4446c8728ef5439',
          dst_block_tx_link: 'https://polygonscan.com/tx/0x84aa8fc54bf1ba2a6c662dca36ddb7619c3adedfaa254d78ca6c941bccd72758',
          status: 2,
          refund_reason: 2,
        },
      ],
      next_page_token: '1644313665986',
      current_size: '1',
    }

    return {
      items: parseBridgeHistory(listType, data),
      error: {
        isExist: false,
      },
    }
  } catch (e) {
    console.error(e)
    return {
      items: [],
      error: {
        isExist: true,
        message: 'Orders loading failed...',
      },
    }
  }
}
