import React from 'react'
import ChartBlock from './chart-block/ChartBlock'
import SidePanelBlock from './side-panel-block/SidePanelBlock'

import './chart-and-side-panel.scss'

const ChartAndSidePanel = () => (
  <div className="chart-and-side-panel-container">
    <ChartBlock />
    <SidePanelBlock />
  </div>
)

export default ChartAndSidePanel
