import React, {
  useEffect, useMemo, useCallback, useState, useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import find from 'lodash/find'
import { useLocalStorage } from '@rehooks/local-storage'
import { populateTokenData } from '../../store/slice/tokens'
import { setNeedReloadOrderbook } from '../../store/slice/user'
import { OrderBook } from '../OrderBook/OrderBook'
import { CustomDataTable } from '../DataTable/DataTable'
import { BaseTabs } from '../BaseTabs/BaseTabs'
import {
  getTokenSwapOrder, getTokenSwapOrderHistory, getTokenPairs, deleteOrder, urgentlyOrder,
} from '../../service/api'
import './currency-chart-tabs.scss'
import { StatusBadge } from '../StatusBadge/StatusBadge'
import { IconTextPair } from '../IconText/IconText'
import { BaseTextBody } from '../BaseText/BaseText'
import IconSelectBase from '../Icon/icon--select-base.svg'
import { SelectBase } from '../SelectBase/SelectBase'
import { Icon } from '../Icon/Icon'
import IconSelectCircleActive from '../Icon/icon--select-circle-active.svg'
import IconSelectCircle from '../Icon/icon--select-circle.svg'
import iconOpenMenu from '../Icon/icon--open-menu.svg'
import iconUrgently from '../Icon/icon--urgently.svg'
import iconCanceledFilled from '../Icon/icon--canceled-filled.svg'
import { BlockImgPair } from '../BlockImgPair/BlockImgPair'
import { ModalRowOrderbook } from '../ModalRowOrderbook/ModalRowOrderbook'
import { toastSuccess } from '../Toast/Toast'
import { getImage } from '../../utils/images'
import { useWeb3 } from '../../context/web3Provider'

const CONST_TABS = {
  OrderBook: 'Orders',
  History: 'History',
}
const CONST_INIT_SELECTED_PAIRS = {
  name: (
    <div className="currency-chart-tabs__select-pairs">
      <BlockImgPair loadingIconFirst loadingIconSecond />
      {' '}
      <span className="currency-chart-tabs__current-pair">All pairs</span>
    </div>
  ),
  value: { asset0Symbol: 'All pairs' },
}
const DEFAULT_ORDERING = '-base_order__creation_time'

export const CurrencyChartTabs = () => {
  const web3React = useWeb3()
  const userState = useSelector((store) => store.user)
  const currentAsset = useSelector((store) => store.tradingView.currentAsset)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [selectedPairs, setSelectedPairs] = useState(CONST_INIT_SELECTED_PAIRS)
  const [selectedTab, setSelectedTab] = useState(CONST_TABS.OrderBook)
  const [ordering, setOrdering] = useState(DEFAULT_ORDERING)
  const [totalRowsHistory, setTotalRowsHistory] = useState(0)
  const [totalRowsOrderBook, setTotalRowsOrderbook] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [dataTable, setDataTable] = useState([])
  const [listTokenPairs, setListTokenPairs] = useState([])
  const [dataTableHistory, setDataTableHistory] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [needRefresh, setNeedRefresh] = useState(false)
  const [dataModal, setDataModal] = useState({})
  const dispatch = useDispatch()

  const tableDataPoller = useRef()
  const isTableDataRequestPending = useRef(false)

  const getDate = useCallback((date) => {
    const getDay = `${(`0${date.getDate()}`).slice(-2)}`
    const getMonth = `${(`0${date.getMonth() + 1}`).slice(-2)}`
    const getHours = `${(`0${date.getHours()}`).slice(-2)}`
    const getMinutes = `${(`0${date.getMinutes()}`).slice(-2)}`
    const getSeconds = `${(`0${date.getSeconds()}`).slice(-2)}`

    return `${getDay}.${getMonth}, ${getHours}:${getMinutes}:${getSeconds}`
  }, [])
  const handleCloseOrderDetails = useCallback(() => {
    setDataModal({})
  }, [])
  const formatData = useCallback(async (row) => {
    const dateCreated = new Date(row.base_order.creation_time)
    const firstTokenImg = await getImage(row.asset0.address)
    const secondTokenImg = await getImage(row.asset1.address)
    let status

    switch (row.status) {
      case 'Executed':
        status = 'loading'
        break
      case 'Confirmed':
        status = 'completed'
        break
      case 'Canceled':
        status = 'canceled'
        break
      case 'Failed':
        status = 'error'
        break
      default:
        status = 'pending'
        break
    }

    const newData = {
      id: row.id,
      status: <StatusBadge onClick={() => setDataModal(row)} state={status} text={row.status === 'Executed' ? 'Executing' : row.status} label />,
      pairs: <IconTextPair
        firstTokenImg={firstTokenImg.src}
        secondTokenImg={secondTokenImg.src}
        text={row.pair}
      />,
      action: <BaseTextBody>{row.action}</BaseTextBody>,
      type: <BaseTextBody>{row.type}</BaseTextBody>,
      triggerConditions: <BaseTextBody>{row.triggers[0]?.trigger_condition}</BaseTextBody>,
      base_order__creation_time: (
        <div className="CurrencyChartTabs--base_order__creation_time">
          <BaseTextBody secondary>{getDate(dateCreated)}</BaseTextBody>
        </div>
      ),
      allProps: row,
    }

    if (CONST_TABS.OrderBook === selectedTab) {
      newData.more = (
        <SelectBase
          selected={<Icon name={iconOpenMenu} id="icon--open-menu" />}
          onChange={() => {}}
          className="select-base--new"
          items={[
            {
              value: 'Urgently execute',
              hide: row.type === 'market',
              name: (
                <div onClick={async () => {
                  try {
                    await urgentlyOrder(row.id)
                    setNeedRefresh(true)
                    toastSuccess('Urgently execute command sent')
                  } catch (e) {
                    console.error(e)
                  }
                }}
                >
                  <BaseTextBody secondary>Urgently execute</BaseTextBody>
                </div>
              ),
              icon: <Icon name={iconUrgently} id="icon--urgently" />,
            },
            {
              value: 'Cancel order',
              name: (
                <div onClick={async () => {
                  try {
                    await deleteOrder(row.id)
                    setNeedRefresh(true)
                    toastSuccess('Order canceled successfully')
                  } catch (e) {
                    console.error(e)
                  }
                }}
                >
                  <BaseTextBody secondary>Cancel order</BaseTextBody>
                </div>
              ),
              icon: <Icon name={iconCanceledFilled} id="icon--canceled-filled" />,
            },
          ]}
        />
      )
    }

    return newData
  }, [getDate, selectedTab])
  const getDataTable = useCallback(async (
    asset0Address,
    asset1Address,
    tablePerPage,
    tableCurrentPage,
    tableOrdering,
    needLoad = true,
  ) => {
    needLoad && setLoadingTable(true)

    try {
      const { data } = await getTokenSwapOrder(
        internalSelectedAddress,
        tablePerPage,
        Number.isNaN(tableCurrentPage) ? 1 : tableCurrentPage,
        tableOrdering,
        asset0Address,
        asset1Address,
      )
      setTotalRowsOrderbook(data.count)
      // eslint-disable-next-line no-return-await
      const arrPromise = data.results.map(async (item) => await formatData(item))
      const arrResult = await Promise.all(arrPromise)

      setDataTable(arrResult.map((item) => item))
      needLoad && setLoadingTable(false)
      return true
    } catch (e) {
      console.error('CurrencyChartTabs dataTable', e)
      needLoad && setLoadingTable(false)
      return true
    }
  }, [formatData, internalSelectedAddress])
  /**
   * @type {(function({string}=, {string}=, {number}=, {number}=, {string}=, {bool}=): Promise<void>)|*}
   */
  const getDataTableHistory = useCallback(async (
    asset0Address,
    asset1Address,
    tablePerPage,
    tableCurrentPage,
    tableOrdering,
    needLoad = true,
  ) => {
    needLoad && setLoadingTable(true)

    try {
      const { data } = await getTokenSwapOrderHistory(
        internalSelectedAddress,
        tablePerPage,
        Number.isNaN(tableCurrentPage) ? 1 : tableCurrentPage,
        tableOrdering,
        asset0Address,
        asset1Address,
      )

      setTotalRowsHistory(data.count)
      // eslint-disable-next-line no-return-await
      const arrPromise = data.results.map(async (item) => await formatData(item))
      const arrResult = await Promise.all(arrPromise)

      setDataTableHistory(arrResult.map((item) => item))
      needLoad && setLoadingTable(false)
      return true
    } catch (e) {
      console.error('CurrencyChartTabs dataTable', e)
      needLoad && setLoadingTable(false)
      return true
    }
  }, [formatData, internalSelectedAddress])
  const onSort = useCallback(async (column, sortDirection) => {
    setLoadingTable(true)

    try {
      const newOrdering = `${sortDirection === 'desc' ? '-' : ''}${column.selector}`

      if (selectedTab === CONST_TABS.OrderBook) {
        await getDataTable(
          selectedPairs.asset0Address,
          selectedPairs.asset1Address,
          perPage,
          currentPage,
          newOrdering,
        )
      }
      if (selectedTab === CONST_TABS.History) {
        await getDataTableHistory(
          selectedPairs.asset0Address,
          selectedPairs.asset1Address,
          perPage,
          currentPage,
          newOrdering,
        )
      }

      setOrdering(newOrdering)
    } catch (e) {
      console.error('onSort dataTable', e)
    }

    setLoadingTable(false)
  }, [currentPage, getDataTable, getDataTableHistory, perPage, selectedPairs.asset0Address, selectedPairs.asset1Address, selectedTab])
  const onChangeRowsPerPage = useCallback(async (newPerPage) => {
    selectedTab === CONST_TABS.OrderBook && await getDataTable(
      selectedPairs.asset0Address,
      selectedPairs.asset1Address,
      newPerPage,
      currentPage,
      ordering,
    )
    selectedTab === CONST_TABS.History && await getDataTableHistory(
      selectedPairs.asset0Address,
      selectedPairs.asset1Address,
      newPerPage,
      currentPage,
      ordering,
    )

    setPerPage(newPerPage)
  }, [currentPage, getDataTable, getDataTableHistory, ordering, selectedPairs.asset0Address, selectedPairs.asset1Address, selectedTab])
  const onChangePage = useCallback(async (page) => {
    selectedTab === CONST_TABS.OrderBook && await getDataTable(
      selectedPairs.asset0Address,
      selectedPairs.asset1Address,
      perPage,
      page,
      ordering,
    )
    selectedTab === CONST_TABS.History && await getDataTableHistory(
      selectedPairs.asset0Address,
      selectedPairs.asset1Address,
      perPage,
      page,
      ordering,
    )
    setCurrentPage(page)
  }, [getDataTable, getDataTableHistory, ordering, perPage, selectedPairs.asset0Address, selectedPairs.asset1Address, selectedTab])

  const columns = useMemo(() => {
    const newColumns = [
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: <SelectBase
          selected={selectedPairs.name}
          onChange={async (item) => {
            const name = item.asset0Symbol === 'All pairs' ? item.asset0Symbol : `${item.asset0Symbol}/${item.asset1Symbol}`
            const firstImg = await getImage(item.asset0Address)
            const secondImg = await getImage(item.asset1Address)

            setCurrentPage(1)

            setSelectedPairs({
              name: (
                <div className="currency-chart-tabs__select-pairs">
                  <BlockImgPair
                    loadingIconFirst={!firstImg.isExist}
                    loadingIconSecond={!secondImg.isExist}
                    firstImg={firstImg.src}
                    secondImg={secondImg.src}
                  />
                  {' '}
                  <span className="currency-chart-tabs__current-pair">{name}</span>
                </div>
              ),
              asset0Address: item.asset0Address,
              asset1Address: item.asset1Address,
              asset0Symbol: item.asset0Symbol,
              asset1Symbol: item.asset1Symbol,
            })

            selectedTab === CONST_TABS.OrderBook && await getDataTable(
              item.asset0Address,
              item.asset1Address,
              perPage,
              1,
              ordering,
            )
            selectedTab === CONST_TABS.History && await getDataTableHistory(
              item.asset0Address,
              item.asset1Address,
              perPage,
              1,
              ordering,
            )
          }}
          className="select-base--new"
          headerIcon={IconSelectBase}
          headerIconID="icon--select-base"
          items={listTokenPairs}
        />,
        selector: 'pairs',
      },
      {
        name: 'Action',
        selector: 'action',
      },
      {
        name: 'Type',
        selector: 'type',
      },
      {
        name: 'Trigger Conditions',
        selector: 'triggerConditions',
      },
      {
        name: 'Created',
        selector: 'base_order__creation_time',
        sortable: true,
        right: true,
      },
    ]

    if (CONST_TABS.OrderBook === selectedTab) {
      newColumns.push({
        name: '',
        selector: 'more',
        right: true,
        width: '50px',
        style: {
          '.select-base__header': {
            paddingRight: '12px',
          },
          'div:first-child': {
            overflow: 'initial !important',
          },
        },
      })
    }

    return newColumns
  }, [getDataTable, getDataTableHistory, listTokenPairs, ordering, perPage, selectedPairs, selectedTab])
  const NewTabs = useMemo(() => [
    {
      text: 'Orders',
      renderContent: () => (
        <CustomDataTable
          defaultSortField="base_order__creation_time"
          defaultSortAsc={false}
          onRowClicked={(item) => {
            const executed = find(item?.allProps.transactions, { name: 'Swap' }).modified_at || ''
            setDataModal({
              ...item?.allProps,
              executed,
            })
          }}
          onSort={onSort}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRowsOrderBook}
          isDataLoading={loadingTable}
          data={dataTable}
          columns={columns}
        />
      ),
    },
    {
      text: 'History',
      renderContent: () => (
        <CustomDataTable
          defaultSortField="base_order__creation_time"
          defaultSortAsc={false}
          onRowClicked={(item) => setDataModal(item?.allProps)}
          onSort={onSort}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={totalRowsHistory}
          loadingTable={loadingTable}
          data={dataTableHistory}
          columns={columns}
        />
      ),
    },
  ], [
    columns,
    dataTable,
    dataTableHistory,
    loadingTable,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    totalRowsHistory,
    totalRowsOrderBook,
  ])

  // Принудительное обновление данных в таблице
  useEffect(() => {
    let isMounted = true
    const asyncRefreshDataTable = async () => {
      if (needRefresh && isMounted) {
        selectedTab === CONST_TABS.OrderBook && await getDataTable(perPage, currentPage, ordering, false)
        selectedTab === CONST_TABS.History && await getDataTableHistory(perPage, currentPage, ordering, false)

        setNeedRefresh(false)
      }
    }

    asyncRefreshDataTable()
    return () => { isMounted = false }
  }, [currentPage, getDataTable, getDataTableHistory, needRefresh, ordering, perPage, selectedTab])

  useEffect(() => {
    let isMounted = true
    const asyncSetListTokensPairs = async () => {
      try {
        const { data } = await getTokenPairs()

        const newList = [{
          name: (
            <div className="currency-chart-tabs__item-select-pairs">
              <BlockImgPair loadingIconFirst loadingIconSecond />
              {' '}
              <span className="currency-chart-tabs__current-pair">All pairs</span>
            </div>
          ),
          value: {
            asset0Symbol: 'All pairs',
          },
          icon: <Icon
            name={selectedPairs.asset0Symbol === 'All pairs' ? IconSelectCircleActive : IconSelectCircle}
            id={selectedPairs.asset0Symbol === 'All pairs' ? 'icon--select-circle-active' : 'icon--select-circle'}
          />,

        }]

        data.forEach(async (item) => {
          const firstImg = await getImage(item.asset0__address)
          const secondImg = await getImage(item.asset1__address)

          let selectIcon = IconSelectCircle
          let selectIconId = 'icon--select-circle'

          if (selectedPairs.asset0Address === item.asset0__address && selectedPairs.asset1Address === item.asset1__address) {
            selectIcon = IconSelectCircleActive
            selectIconId = 'icon--select-circle-active'
          }

          newList.push({
            name: (
              <div className="currency-chart-tabs__item-select-pairs">
                <BlockImgPair
                  isNotFoundFirstIcon={!firstImg.isExist}
                  isNotFoundSecondIcon={!secondImg.isExist}
                  firstImg={firstImg.src}
                  secondImg={secondImg.src}
                />
                {' '}
                <span className="currency-chart-tabs__current-pair">
                  {item.asset0__symbol}
                  /
                  {item.asset1__symbol}
                </span>
              </div>
            ),
            value: {
              asset0Symbol: item.asset0__symbol,
              asset1Symbol: item.asset1__symbol,
              asset0Address: item.asset0__address,
              asset1Address: item.asset1__address,
            },
            icon: <Icon name={selectIcon} id={selectIconId} />,
          })
        })

        if (isMounted) {
          setListTokenPairs(newList)
        }
      } catch (e) {
        console.error(e)
      }
    }

    asyncSetListTokensPairs()
    return () => { isMounted = false }
  }, [selectedPairs])

  // Получаем данные при инициализации стр
  useEffect(() => {
    let isMounted = true
    const asyncFirstGetDataTable = async () => {
      if (isMounted) {
        await getDataTable('', '', 10, 1, DEFAULT_ORDERING)
      }
    }

    asyncFirstGetDataTable()
    return () => { isMounted = false }
  }, [getDataTable])

  const stopPollingForTableData = useCallback(() => {
    if (tableDataPoller.current) {
      clearInterval(tableDataPoller.current)
      tableDataPoller.current = undefined
      isTableDataRequestPending.current = false
    }
  }, [])

  const startPollingForTableData = useCallback(() => {
    if (!tableDataPoller.current) {
      const intervalTime = 20000

      tableDataPoller.current = setInterval(async () => {
        if (!isTableDataRequestPending.current) {
          isTableDataRequestPending.current = true

          if (selectedTab === CONST_TABS.OrderBook) {
            const isOrderBookDataReceived = await getDataTable(
              selectedPairs.asset0Address,
              selectedPairs.asset1Address,
              perPage,
              currentPage,
              ordering,
              false,
            )

            isTableDataRequestPending.current = !isOrderBookDataReceived
          }

          if (selectedTab === CONST_TABS.History) {
            const isHistoryDataReceived = await getDataTableHistory(
              selectedPairs.asset0Address,
              selectedPairs.asset1Address,
              perPage,
              currentPage,
              ordering,
              false,
            )

            isTableDataRequestPending.current = !isHistoryDataReceived
          }
        }
      }, intervalTime)
    }
  }, [
    selectedTab,
    getDataTable,
    getDataTableHistory,
    selectedPairs.asset0Address,
    selectedPairs.asset1Address,
    perPage,
    currentPage,
    ordering,
  ])

  // Обновляем данные каждые 20 сек
  useEffect(() => {
    startPollingForTableData()
    return () => stopPollingForTableData()
  }, [startPollingForTableData, stopPollingForTableData])

  // Если создали ордер, то обновляем ордербук
  useEffect(() => {
    let isMounted = true
    const asyncGetDataTable = async () => {
      if (userState.needReloadOrderbook && isMounted) {
        await getDataTable(perPage, currentPage, ordering)

        dispatch(setNeedReloadOrderbook(false))
      }
    }

    asyncGetDataTable()
    return () => { isMounted = false }
  }, [currentPage, dispatch, getDataTable, ordering, perPage, userState.needReloadOrderbook])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    internalSelectedAddress
    && web3React.library
    && currentAsset
    && populateTokenData({
      address: currentAsset,
      user: web3React.library.utils.toChecksumAddress(internalSelectedAddress),
      web3React,
      dispatch,
      addToken: false,
    })
  }, [currentAsset, web3React, internalSelectedAddress, dispatch])

  return (
    <>
      {Object.keys(dataModal).length > 0 && (
      <ModalRowOrderbook
        closeModal={handleCloseOrderDetails}
        firstToken={{
          ...dataModal.asset0,
          price: dataModal.asset0_price,
          value: dataModal.asset0_value,
          hash: dataModal.transactions[0]?.hash || null,
        }}
        secondToken={{
          ...dataModal.asset1,
          price: dataModal.asset1_price,
          hash: dataModal.transactions[1]?.hash || null,
        }}
        status={dataModal.status}
        type={dataModal.type}
        triggerConditions={dataModal.triggers[0]?.trigger_condition || ''}
        created={getDate(new Date(dataModal.base_order.creation_time))}
        triggered={dataModal.base_order.activate_time ? getDate(new Date(dataModal.base_order.activate_time)) : '00.00, 00:00:00'}
        executed={dataModal.executed || '00.00, 00:00:00'}
        ownerWallet={internalSelectedAddress}
        transactions={dataModal.transactions}
      />
      )}
      <OrderBook
        tabs={(
          <BaseTabs
            tabs={NewTabs}
            onChange={async (newTab) => {
              setSelectedTab(newTab)
              setCurrentPage(1)
              setOrdering(DEFAULT_ORDERING)
              setTotalRowsHistory(0)
              setTotalRowsOrderbook(0)
              setSelectedPairs(CONST_INIT_SELECTED_PAIRS)
              if (newTab === CONST_TABS.OrderBook) {
                setDataTableHistory([])
                await getDataTable('', '', perPage, 1, DEFAULT_ORDERING)
              }
              if (newTab === CONST_TABS.History) {
                setDataTable([])
                await getDataTableHistory('', '', perPage, 1, DEFAULT_ORDERING)
              }
            }}
          />
        )}
      />
    </>
  )
}

CurrencyChartTabs.propTypes = {
}
