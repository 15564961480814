import React, {
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import ExtractPrivateKeyMsgStep from './extract-private-key-msg-step/ExtractPrivateKeyMsgStep'
import ExtractPrivateKeyGetKeyStep from './extract-private-key-get-key-step/ExtractPrivateKeyGetKeyStep'
import './extract-private-key-modal.scss'

const CONST_STEPS_MODAL = {
  msg: 'msg',
  pin: 'pin',
}

const ExtractPrivateKeyModal = ({
  chosenCyberWallet,
  onCloseModal,
  onAfterRemove,
}) => {
  const [currentStep, setCurrentStep] = useState(CONST_STEPS_MODAL.msg)

  const getDefaultStep = useMemo(() => (
    <ExtractPrivateKeyMsgStep
      onCloseModal={onCloseModal}
      onNextStep={() => setCurrentStep(CONST_STEPS_MODAL.pin)}
    />
  ), [onCloseModal])

  const renderStep = useMemo(() => {
    switch (currentStep) {
      case CONST_STEPS_MODAL.msg:
        return getDefaultStep
      case CONST_STEPS_MODAL.pin:
        return (
          <ExtractPrivateKeyGetKeyStep
            onAfterRemove={onAfterRemove}
            chosenAddress={chosenCyberWallet}
            onCloseModal={onCloseModal}
          />
        )
      default:
        return getDefaultStep
    }
  }, [chosenCyberWallet, currentStep, getDefaultStep, onAfterRemove, onCloseModal])

  return (
    <>
      {renderStep}
    </>
  )
}

ExtractPrivateKeyModal.propTypes = {
  chosenCyberWallet: PropTypes.string,
  onCloseModal: PropTypes.func,
  onAfterRemove: PropTypes.func,
}

export default ExtractPrivateKeyModal
