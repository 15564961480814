import React from 'react'

import PropTypes from 'prop-types'
import './exchange-currency.scss'
import { BaseTextBody } from '../../BaseText/BaseText'

export const ExchangeCurrency = ({
  title,
  value,
  icon,
  flipRight,
}) => (
  <div
    className={
        `exchange-currency 
        ${flipRight ? 'exchange-currency--right' : ''}`
      }
  >
    <div className="exchange-currency__icon">
      {icon}
    </div>

    <div className="exchange-currency__body">
      <BaseTextBody>{title}</BaseTextBody>

      <BaseTextBody
        size="S"
        secondary
      >
        {value}
      </BaseTextBody>
    </div>
  </div>
)

ExchangeCurrency.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.element,
  flipRight: PropTypes.bool,
}
