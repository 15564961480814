import React, {
  useCallback,
  useEffect, useState,
} from 'react'
import PropTypes from 'prop-types'
import IdentIcon from 'react-identicons'
import find from 'lodash/find'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { BigNumber } from 'bignumber.js'
import { useDispatch } from 'react-redux'
import { useWeb3Bn } from '../../../../../../hooks/web3'
import {
  formatEth,
} from '../../../../../../utils/helperFunctions'
import { TYPES_WALLET } from '../../../../../../const'
import { BaseTextBody, BaseTextHeading } from '../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../components/Icon/Icon'
import IconAccountBalanceWallet from '../../../../../../components/Icon/icon--account-balance-wallet.svg'
import IconSend from '../../../../../../components/Icon/icon--send.svg'
import IconFileCopy from '../../../../../../components/Icon/icon--file-copy.svg'
import IconOpenInNew from '../../../../../../components/Icon/icon--open-in-new.svg'
import IconAllInclusive from '../../../../../../components/Icon/icon--all-inclusive.svg'
import AddressesList from './addresses-list/AddressesList'
import { ClipboardTooltip } from '../../../../../../components/ClipboardTooltip/ClipboardTooltip'
import DepositAssetsModal from '../../deposit-assets-modal/DepositAssetsModal'
import TransferModal from './transfer-modal/TransferModal'
import RenameCyberWalletModal from './rename-cyber-wallet-modal/RenameCyberWalletModal'
import Loading from '../../../../../../components/Loading/Loading'
import ClickOutside from '../../../../../../components/ClickOutside/ClickOutside'
import { getUserTokensSum, getLinkBasedOnCurrentNetwork, getLinkTitleBasedOnCurrentNetwork } from '../../../../../../parser/data'
import { GodModeModal } from './god-mode-modal/GodModeModal'
import DeleteCyberWalletStepsModal from './delete-cyber-wallet-steps-modal/DeleteCyberWalletStepsModal'
import ExtractPrivateKeyModal from './extract-private-key-modal/ExtractPrivateKeyModal'
import { getInitialDataUser, updateNameCyberWallet } from '../../../../../../store/slice/user'
import { useWeb3 } from '../../../../../../context/web3Provider'

import './cyber-wallet-account.scss'

const CyberWalletAccount = ({
  userState,
  onCreateNewCyberWallet,
  isCyberWalletAdressesListOpened,
  onToggleAddessesListOpenedState,
}) => {
  const Web3BN = useWeb3Bn()
  const web3React = useWeb3()
  const dispatch = useDispatch()
  const [selectedWallet] = useLocalStorage('selectedWallet')
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [totalBalance, setTotalBalance] = useState(0)
  const [isTotalBalanceReady, setIsTotalBalanceReadyState] = useState(false)
  const [isDepositModalShown, setDepositModalShownState] = useState(false)
  const [isTransferModalShown, setTransferModalShownState] = useState(false)
  // stores cyberwallet data which was chosen by user from the submenu, interal is by default
  const [chosenCyberWallet, setChosenCyberWallet] = useState(internalSelectedAddress)
  const [isRenameCyberWalletModalShown, setRenameCyberWalletModalShownState] = useState(false)
  const [isDeleteCyberWalletModalShown, setDeleteCyberWalletModalShownState] = useState(false)
  const [isExtractPrivateKeyModalShown, setExtractPrivateKeyModalShownState] = useState(false)
  const [isOpenModalGodMode, setOpenModalGodMode] = useState(false)

  const getName = () => {
    if (selectedWallet === TYPES_WALLET.internal && internalSelectedAddress) {
      return (find(userState.internalAddresses, { address: internalSelectedAddress }) || {}).name
    }

    return userState.internalAddresses[0]?.name
  }

  const getAddress = () => (
    !userState
      ? `${(internalSelectedAddress || '').substr(0, 12)}...${(internalSelectedAddress || '').substr(-5)}`
      : `${(internalSelectedAddress || '').substr(0, 6)}...${(internalSelectedAddress || '').substr(-5)}`
  )

  const selectNewInternalAddressAfterRemove = useCallback(async (removeCyberWalletAddress) => {
    if (
      (removeCyberWalletAddress === internalSelectedAddress
      || removeCyberWalletAddress === internalSelectedAddress)
      && userState.internalAddresses.length > 1
    ) {
      const newArrInternalAddress = userState.internalAddresses.filter((item) => item.address !== removeCyberWalletAddress)

      writeStorage('internalSelectedAddress', newArrInternalAddress[0].address)
      writeStorage('selectedAddress', newArrInternalAddress[0].address)
    }

    dispatch(getInitialDataUser(internalSelectedAddress))
  }, [dispatch, userState.internalAddresses, internalSelectedAddress])

  const updateNameCyberWalletAfterRename = useCallback((cyberWallet) => {
    dispatch(updateNameCyberWallet(cyberWallet))
  }, [dispatch])

  useEffect(() => {
    if (!userState.areInternalTokensLoading) {
      let sum = 0

      if (userState.tokens && Array.isArray(userState.tokens) && BigNumber && web3React.library) {
        sum = (getUserTokensSum({
          tokens: userState.tokens,
          BN: Web3BN,
          web3React,
          latestNativeTokenPrice: userState.latestNativeTokenPrice,
        })).totalSum
        setIsTotalBalanceReadyState(true)
      }
      setTotalBalance(formatEth(sum, 2))
    }
  }, [
    Web3BN,
    userState.areInternalTokensLoading,
    setTotalBalance,
    userState.latestNativeTokenPrice,
    userState.tokens,
    web3React,
  ])

  return (
    <ClickOutside callback={() => {
      if (isCyberWalletAdressesListOpened) {
        onToggleAddessesListOpenedState()
      }
    }}
    >
      <div data-testid="sidebar--cyber-wallets" className="cyber-wallet__account-container">
        <div
          className="main-block"
          onClick={onToggleAddessesListOpenedState}
        >
          <div className="header-controls-block">
            <div className="cyber-wallet__address-block">
              {userState.isGodModeEnabled && (
                <div className="god-mod-icon">
                  <Icon
                    mod="icon--14"
                    name={IconAllInclusive}
                    id="icon--all-inclusive"
                  />
                </div>
              )}

              <IdentIcon
                className="account-icon"
                string={getName()}
                size="16"
              />
              <BaseTextBody upperCase>
                {getAddress()}
              </BaseTextBody>
            </div>

            <div className="controls-block" onClick={(e) => e.stopPropagation()}>
              <ClipboardTooltip tooltipText="Deposit">
                <span onClick={() => setDepositModalShownState(true)}>
                  <Icon
                    mod="icon--14"
                    name={IconAccountBalanceWallet}
                    id="icon--account-balance-wallet"
                  />
                </span>
              </ClipboardTooltip>
              <ClipboardTooltip tooltipText="Transfer">
                <span onClick={() => setTransferModalShownState(true)}>
                  <Icon
                    mod="icon--14"
                    name={IconSend}
                    id="icon--send"
                  />
                </span>
              </ClipboardTooltip>

              <ClipboardTooltip tooltipText="Copy address" tooltipTextFocus="Copied!">
                <CopyToClipboard text={internalSelectedAddress}>
                  <span>
                    <Icon
                      mod="icon--14"
                      name={IconFileCopy}
                      id="icon--file-copy"
                    />
                  </span>
                </CopyToClipboard>
              </ClipboardTooltip>

              <ClipboardTooltip tooltipText={`View in ${getLinkTitleBasedOnCurrentNetwork()}`}>
                <span onClick={() => window.open(`https://${getLinkBasedOnCurrentNetwork()}/address/${internalSelectedAddress}`, '_blank')}>
                  <Icon
                    mod="icon--14"
                    name={IconOpenInNew}
                    id="icon--open-in-new"
                  />
                </span>
              </ClipboardTooltip>
            </div>
          </div>

          <div className="balance-block">
            {!userState.areInternalTokensLoading && isTotalBalanceReady
              ? (
                <BaseTextHeading upperCase>
                  $
                  {totalBalance}
                </BaseTextHeading>
              )
              : <Loading />}
          </div>
        </div>

        {isCyberWalletAdressesListOpened && (
          <AddressesList
            addresses={userState.internalAddresses}
            onTransfer={(cyberWalletAddress) => {
              onToggleAddessesListOpenedState()
              setChosenCyberWallet(cyberWalletAddress)
              setTransferModalShownState(true)
            }}
            onDeposit={(cyberWalletAddress) => {
              onToggleAddessesListOpenedState()
              setChosenCyberWallet(cyberWalletAddress)
              setDepositModalShownState(true)
            }}
            onRename={(cyberWalletAddress) => {
              onToggleAddessesListOpenedState()
              setChosenCyberWallet(cyberWalletAddress)
              setRenameCyberWalletModalShownState(true)
            }}
            onCreateNewCyberWallet={() => {
              onToggleAddessesListOpenedState()
              onCreateNewCyberWallet()
            }}
            onDelete={(cyberWalletAddress) => {
              onToggleAddessesListOpenedState()
              setChosenCyberWallet(cyberWalletAddress)
              setDeleteCyberWalletModalShownState(true)
            }}
            onExtractPrivateKey={(cyberWalletAddress) => {
              onToggleAddessesListOpenedState()
              setChosenCyberWallet(cyberWalletAddress)
              setExtractPrivateKeyModalShownState(true)
            }}
            onOpenModalGodMode={(cyberWalletAddress) => {
              onToggleAddessesListOpenedState()
              setChosenCyberWallet(cyberWalletAddress)
              setOpenModalGodMode(true)
            }}
          />
        )}

        {isDepositModalShown && (
          <DepositAssetsModal
            chosenWallet={chosenCyberWallet}
            onCloseModal={() => {
              setChosenCyberWallet(internalSelectedAddress)
              setDepositModalShownState(false)
            }}
          />
        )}

        {isTransferModalShown && (
          <TransferModal
            chosenCyberWallet={chosenCyberWallet}
            onCloseModal={() => {
              setChosenCyberWallet(internalSelectedAddress)
              setTransferModalShownState(false)
            }}
          />
        )}

        {isRenameCyberWalletModalShown && (
          <RenameCyberWalletModal
            chosenCyberWallet={chosenCyberWallet}
            onAfterRename={(cyberWallet) => updateNameCyberWalletAfterRename(cyberWallet)}
            onCloseModal={() => {
              setChosenCyberWallet(internalSelectedAddress)
              setRenameCyberWalletModalShownState(false)
            }}
          />
        )}

        {isDeleteCyberWalletModalShown && (
          <DeleteCyberWalletStepsModal
            chosenCyberWallet={chosenCyberWallet}
            onAfterRemove={selectNewInternalAddressAfterRemove}
            onCloseModal={() => {
              setChosenCyberWallet(internalSelectedAddress)
              setDeleteCyberWalletModalShownState(false)
            }}
          />
        )}
        {isExtractPrivateKeyModalShown && (
          <ExtractPrivateKeyModal
            chosenCyberWallet={chosenCyberWallet}
            onAfterRemove={selectNewInternalAddressAfterRemove}
            onCloseModal={() => {
              setChosenCyberWallet(internalSelectedAddress)
              setExtractPrivateKeyModalShownState(false)
            }}
          />
        )}
        {isOpenModalGodMode && (
          <GodModeModal
            chosenCyberWallet={chosenCyberWallet}
            closeModalGodModal={() => {
              setChosenCyberWallet(internalSelectedAddress)
              setOpenModalGodMode(false)
            }}
          />
        )}
      </div>
    </ClickOutside>
  )
}

CyberWalletAccount.propTypes = {
  userState: PropTypes.object,
  onCreateNewCyberWallet: PropTypes.func,
  isCyberWalletAdressesListOpened: PropTypes.bool,
  onToggleAddessesListOpenedState: PropTypes.func,
}

export default CyberWalletAccount
