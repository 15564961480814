import React from 'react'
import { Route } from 'react-router'
import { useSelector } from 'react-redux'
import { CONST_NODE_ENV } from '../const'
import Page404 from "../pages/main-container/pages/page-404/Page404";

const ProtectedRoute = (props) => {
  const userState = useSelector((state) => state.user)

  // Тестовая страница с magic-link
  // Работает только в окружении development
  if ('test-magic-link' === props.computedMatch?.url && process.env.ENV === CONST_NODE_ENV.development) {
    return <Route {...props} />
  }
  if (!userState.internalAddresses || userState.internalAddresses?.length === 0 && !userState.loading) {
    return <Route component={Page404} />
  }

  return <Route {...props} />
}

export default ProtectedRoute
