import React from 'react'
import PropTypes from 'prop-types'
import './order-book.scss'
import { BaseTextHeading } from '../BaseText/BaseText'

export const OrderBook = ({
  tabs,
}) => (
  <div className="order-book">
    <div className="order-book__header">
      <BaseTextHeading
        size="S"
      >
        Orderbook
      </BaseTextHeading>
    </div>

    {tabs}
  </div>
)
OrderBook.propTypes = {
  tabs: PropTypes.element,
}
