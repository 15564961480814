import { EXCHANGES } from '../const/chart'

export const parseHistoryChartData = ({ historyData }) => {
  const res = historyData.map((data) => ({
    time: new Date(data[0]).toDateString(),
    open: data[1],
    high: data[2],
    low: data[3],
    close: data[4],
  }))
  return res
}

export const parseHistoryChartDataForLine = ({ historyDataForLine }) => {
  const res = historyDataForLine.map((data) => {
    const d = new Date(data[0])
    const time = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()) / 1000
    return {
      time,
      value: parseFloat(data[1].toFixed(2)),
    }
  })
  return res
}

const generateSymbol = ({ exchange, fromSymbol, toSymbol }) => {
  const short = `${fromSymbol}/${toSymbol}`
  return {
    short,
    full: `${exchange}:${short}`,
  }
}

export const parseExchanges = (exchangesData) => {
  let allSymbols = []

  Object.keys(EXCHANGES).forEach((exchangeKey) => {
    const exchange = EXCHANGES[exchangeKey]
    const { pairs } = exchangesData[exchange.name]

    Object.keys(pairs).forEach((leftPairPart) => {
      const symbols = pairs[leftPairPart].map((rightPairPart) => {
        const symbol = generateSymbol({
          exchange: exchange.name,
          fromSymbol: leftPairPart,
          toSymbol: rightPairPart,
        })
        return {
          symbol: symbol.short,
          full_name: symbol.full,
          description: symbol.short,
          exchange: exchange.name,
          type: 'crypto',
        }
      })
      allSymbols = [...allSymbols, ...symbols]
    })
  })

  return allSymbols
}

export const parseCryptoCompareHistoryData = ({ data, from, to }) => {
  if ((data.Response && data.Response === 'Error') || data.Data.length === 0) {
    return {
      isError: true,
      bars: [],
    }
  }

  let bars = []
  data.Data.forEach((bar) => {
    if (bar.time >= from && bar.time < to) {
      bars = [...bars, {
        time: bar.time * 1000,
        low: bar.low,
        high: bar.high,
        open: bar.open,
        close: bar.close,
      }]
    }
  })

  return {
    isError: false,
    bars,
  }
}

export const parseFullSymbol = (fullSymbol) => {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    return undefined
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] }
}

export const parseMoonToolsHistoryDate = (data) => (
  data.length > 0
    ? {
      isError: false,
      bars: data,
    }
    : {
      isError: true,
      bars: [],
    }
)

export const getStableCoinAddressBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  let stableCoinAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
  if (selectedChain === 'BSC') {
    stableCoinAddress = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
  } else if (selectedChain === 'Polygon') {
    stableCoinAddress = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
  }
  return stableCoinAddress
}

export const parseCoinIdBySymbolNetworkAndAddress = ({ data, network, address }) => {
  let networkForSearch = 'ethereum'
  if (network === 'BSC') {
    networkForSearch = 'binance-smart-chain'
  } else if (network === 'Polygon') {
    networkForSearch = 'polygon-pos'
  }

  const foundCoin = data.filter((item) => item.platforms[networkForSearch] === address.toLowerCase())[0]
  return foundCoin ? foundCoin.id : undefined
}

export const getNativeCoinIdBasedOnNetwork = () => {
  const network = localStorage.getItem('selectedChain')
  let nativeCoinId = 'ethereum'
  if (network === 'BSC') {
    nativeCoinId = 'binancecoin'
  } else if (network === 'Polygon') {
    nativeCoinId = 'matic-network'
  }
  return nativeCoinId
}
