import React, {
  useEffect,
  useState,
  useCallback, useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { useLocalStorage } from '@rehooks/local-storage'
import find from 'lodash/find'
import { addLpTokenToStore } from '../../store/slice/tradingView'
import {
  getTokenExactData, checkFloatNaN, ERC20ToFloat,
} from '../../utils/helperFunctions'
import { Block } from '../Block/Block'
import Img16Based from '../ItemCurrencySwitches/img-16-based.png'
import Img16Smartcredit from '../ItemCurrencySwitches/img-16-smartcredit.png'
import './body-exchange-filled.scss'
import {
  lpTokensFilter,
  getPoolLiquidity,
} from '../../service/api'
import { selectAllPriceTokens } from '../../store/slice/priceToken'
import { TradePairInfo } from '../TradePairInfo/TradePairInfo'
import EmojiThink from '../../assets/images/emojiThink.png'
import { getImage } from '../../utils/images'
import { BlockImgPair } from '../BlockImgPair/BlockImgPair'
import { CurrencyChartTabs } from '../CurrencyChartTabs/CurrencyChartTabs'
import { getLinkBasedOnCurrentNetwork } from '../../parser/data'
import { useWeb3Bn } from '../../hooks/web3'
import { useWeb3 } from '../../context/web3Provider'

const DEFAULT_DATA_TOKEN = {
  address: '',
  symbol: '',
  icon: EmojiThink,
}

export const BodyExchangeFilled = ({
  className,
}) => {
  const Web3BN = useWeb3Bn()
  const web3React = useWeb3()
  const dispatch = useDispatch()
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [selectedChain] = useLocalStorage('selectedChain')
  const priceTokensState = useSelector(selectAllPriceTokens)
  const tradingState = useSelector((store) => store.tradingView.reversed)
  const currentAsset = useSelector((store) => store.tradingView.currentAsset)
  const trading = useSelector((store) => store.tradingView)
  const [pair, setPair] = useState([{ value: '' }])
  const [selectedTokenPnl, setSelectedTokenPnl] = useState('')
  const [tradingViewPair, setTradingViewPair] = useState([
    {
      value: '',
      token0: DEFAULT_DATA_TOKEN,
      token1: DEFAULT_DATA_TOKEN,
    },
  ])
  const [total, setTotal] = useState(0)
  const [lpTokensList, setLpTokensList] = useState([])
  const [isLoadingDropDown, setIsLoadingDropDown] = useState(false)
  const [infinityScrollHasMore, setInfinityScrollHasMore] = useState(false)
  const [isFocusInputSelectCurrencyChart, setIsFocusInputSelectCurrencyChart] = useState(false)
  const [pageLpTokens, setPageLpTokens] = useState(1)
  const [searchLpTokens, setSearchLpTokens] = useState('')
  const [selectedValue, setSelectedValue] = useState(null)
  const [chartData, setChartData] = useState({
    lastDayReturnFloat: 'N/A',
    lastDayReturnPercents: 'N/A',
    averageCost: 'N/A',
    fees: 'N/A',
    symbol: 'N/A',
    tokenBalance: 'N/A',
    totalPnl: 'N/A',
    usdtBalance: 'N/A',
    equity: 'N/A',
    usdBalanceToken: 'N/A',
  })
  const [isLoadingChartData, setLoadingChartData] = useState(true)

  const assetPnlItems = useMemo(() => [
    {
      name: `Equity · ${chartData.equity}%`,
      value: `${checkFloatNaN(chartData.tokenBalance, 'N/A', 5)} ${chartData.symbol}`,
    },
    {
      name: 'Profit / Loss',
      value: chartData.totalPnl < 0 ? `-$${chartData.totalPnl}` : `+$${chartData.totalPnl}`,
      isError: chartData.totalPnl < 0,
    },
    {
      name: 'Average Cost',
      value: `$${chartData.averageCost}`,
      isError: chartData.averageCost < 0,
    },
    {
      name: '24-hour Return',
      value: `$${chartData.lastDayReturnFloat}`,
      subValue: `${chartData.lastDayReturnPercents}%`,
      isError: chartData.lastDayReturnFloat < 0,
    },
    {
      name: 'Paid Fees',
      value: `$${chartData.fees}`,
      isError: chartData.fees < 0,
    },
  ], [chartData])

  // Поиск lp пар по значению
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeValue = useCallback(debounce(async (newValue) => {
    setIsLoadingDropDown(true)
    setSearchLpTokens(newValue)

    const listOfLpTokens = await lpTokensFilter({
      filterValue: newValue,
      page: pageLpTokens,
      limit: 10,
    })
    setLpTokensList(listOfLpTokens.data.results.map((serverData) => (
      serverData && {
        address: serverData.address,
        link: `https://${getLinkBasedOnCurrentNetwork()}/token/${serverData.address}`,
        token0: {
          address: serverData.token1.address,
          symbol: serverData.token1.symbol,
        },
        token1: {
          address: serverData.token2.address,
          symbol: serverData.token2.symbol,
        },
      }
    )))

    setIsLoadingDropDown(false)
  }, 1000), [])

  /**
   * @type {(function(*): Promise<void>)|*}
   */
  const funcLoadItems = useCallback(async () => {
    setIsLoadingDropDown(true)
    setSearchLpTokens('')
    setPageLpTokens(1)

    const listOfLpTokens = await lpTokensFilter({
      filterValue: '',
      page: 1,
      limit: 10,
    })

    setLpTokensList(listOfLpTokens.data.results.map((serverData) => (
      serverData && {
        address: serverData.address,
        link: `https://${getLinkBasedOnCurrentNetwork()}/token/${serverData.address}`,
        token0: {
          address: serverData.token1.address,
          symbol: serverData.token1.symbol,
        },
        token1: {
          address: serverData.token2.address,
          symbol: serverData.token2.symbol,
        },
      }
    )))

    setInfinityScrollHasMore(!!listOfLpTokens.data.next)
    setIsLoadingDropDown(false)
  }, [])

  const funcInfinityScroll = useCallback(async () => {
    const listOfLpTokens = await lpTokensFilter({
      filterValue: searchLpTokens,
      page: pageLpTokens + 1,
      limit: 10,
    })

    setLpTokensList(lpTokensList.concat(listOfLpTokens.data.results.map((serverData) => (
      serverData && {
        address: serverData.address,
        link: `https://${getLinkBasedOnCurrentNetwork()}/token/${serverData.address}`,
        token0: {
          address: serverData.token1.address,
          symbol: serverData.token1.symbol,
        },
        token1: {
          address: serverData.token2.address,
          symbol: serverData.token2.symbol,
        },
      }
    ))))

    setPageLpTokens(pageLpTokens + 1)
    setInfinityScrollHasMore(!!listOfLpTokens.data.next)
  }, [lpTokensList, pageLpTokens, searchLpTokens])

  const asyncGetSrc = useCallback((src) => getImage(src), [])

  const getPnlChip = useCallback((dataChip) => ({
    icon: <BlockImgPair firstImg={dataChip?.icon} />,
    onClick: () => setSelectedTokenPnl(dataChip?.address),
    text: dataChip?.symbol,
    isSelected: selectedTokenPnl === dataChip?.address,
  }), [selectedTokenPnl])

  useEffect(() => {
    const tokens = pair[0].value.split('/')
    setTotal(1 / total)
    setPair([{ value: `${tokens[1]}/${tokens[0]}` }])
    // eslint-disable-next-line
  }, [tradingState])

  useEffect(() => {
    let isMounted = true
    const asyncSetChartData = async () => {
      if (!selectedTokenPnl || !internalSelectedAddress) {
        return
      }

      try {
        let usdtBalance = 0
        priceTokensState.forEach((item) => {
          usdtBalance += item.totalPrice
        })
        usdtBalance = checkFloatNaN(usdtBalance, 'N/A', 2)
        const findToken = find(priceTokensState, { address: selectedTokenPnl })
        const priceToken = findToken?.totalPrice
        const { data } = await getPoolLiquidity({
          address: selectedTokenPnl,
          internalAddress: internalSelectedAddress,
        })
        const lastDayReturnFloat = checkFloatNaN(data['24_hour_return_float'], 'N/A', 2)
        const lastDayReturnPercents = checkFloatNaN(data['24_hour_return_percents'], 'N/A', 2)
        const averageCost = checkFloatNaN(data.average_cost, 'N/A', 2)
        const fees = checkFloatNaN(data.fees, 'N/A', 2)
        const { symbol } = data
        const tokenBalance = findToken ? ERC20ToFloat(new Web3BN(`${findToken.balance}`), findToken.decimals, Web3BN) : 0
        const totalPnl = checkFloatNaN(data.total_pnl, 'N/A', 2)
        const equity = checkFloatNaN((priceToken / (usdtBalance)) * 100, 0, 2)

        if (isMounted) {
          setChartData({
            lastDayReturnFloat,
            lastDayReturnPercents,
            averageCost,
            fees,
            symbol,
            tokenBalance,
            totalPnl,
            usdtBalance,
            equity,
            usdBalanceToken: priceToken,
          })
        }
      } catch (e) {
        console.error(e)
      }

      if (isMounted) {
        setLoadingChartData(false)
      }
    }

    asyncSetChartData()
    return () => { isMounted = false }
  }, [Web3BN, selectedTokenPnl, internalSelectedAddress, priceTokensState, isLoadingChartData])

  useEffect(() => {
    let isMounted = true
    const asyncSetTradingView = async () => {
      if (web3React.library && trading.chartToken) {
        const token0Address = await getTokenExactData(trading.chartToken, 'token0', web3React)
        const token0Symbol = await getTokenExactData(token0Address, 'symbol', web3React)
        const token0Name = await getTokenExactData(token0Address, 'name', web3React)
        const token0Icon = (await asyncGetSrc(token0Address))?.src

        const token1Address = await getTokenExactData(trading.chartToken, 'token1', web3React)
        const token1Symbol = await getTokenExactData(token1Address, 'symbol', web3React)
        const token1Name = await getTokenExactData(token1Address, 'name', web3React)
        const token1Icon = (await asyncGetSrc(token1Address))?.src
        const lpPair = `${token0Symbol}/${token1Symbol}`

        if (isMounted) {
          if (!selectedValue && token0Symbol && token1Symbol && !isFocusInputSelectCurrencyChart) {
            setSelectedValue(`${token0Symbol} / ${token1Symbol}`)
          }

          setSelectedTokenPnl(token0Address)
          setTradingViewPair([
            {
              value: lpPair,
              token0: {
                address: token0Address,
                symbol: token0Symbol,
                name: token0Name,
                icon: token0Icon,
              },
              token1: {
                address: token1Address,
                symbol: token1Symbol,
                name: token1Name,
                icon: token1Icon,
              },
            },
          ])
        }
      }
    }

    asyncSetTradingView()
    return () => { isMounted = false }
  }, [asyncGetSrc, selectedValue, trading.chartToken, web3React, selectedChain, isFocusInputSelectCurrencyChart])

  // При первом заходе на стр сеттим адрес токена для PNL
  useEffect(() => {
    setSelectedTokenPnl(currentAsset)
    // eslint-disable-next-line
  }, [])
  // При выборе пары через top assets
  useEffect(() => {
    if (!isFocusInputSelectCurrencyChart) {
      setSelectedValue(`${tradingViewPair[0]?.token0?.symbol} / ${tradingViewPair[0]?.token1?.symbol}`)
    }
  }, [tradingViewPair, isFocusInputSelectCurrencyChart])

  return (
    <div className={`body-exchange-filled ${className || ''}`}>
      <Block>
        <TradePairInfo
          fullNamePair={`${tradingViewPair[0]?.token0?.name} / ${tradingViewPair[0]?.token1?.name}`}
          firstTokenAddress={tradingViewPair[0]?.token0?.address}
          secondTokenAddress={tradingViewPair[0]?.token1?.address}
          infinityScrollHasMore={infinityScrollHasMore}
          funcInfinityScroll={funcInfinityScroll}
          funcLoadItems={funcLoadItems}
          selectedValue={selectedValue === null ? 'CFI / ETH' : selectedValue}
          dropDownItems={lpTokensList}
          currencyItems={tradingViewPair}
          imgCurrencyOne={Img16Smartcredit}
          imgCurrencyOneAlt={pair[0]}
          imgCurrencyTu={Img16Based}
          imgCurrencyTuAlt={pair[1]}
          isLoadingDropDown={isLoadingDropDown}
          isLoadingComponent={isLoadingChartData}
          onBlurInputSelectCurrencyChart={() => setIsFocusInputSelectCurrencyChart(false)}
          onChange={(newValue) => {
            setIsFocusInputSelectCurrencyChart(true)
            setPageLpTokens(1)
            handleChangeValue(newValue)
            setSelectedValue(newValue)
          }}
          callback={async (lpToken) => {
            handleChangeValue('')
            await addLpTokenToStore(lpToken?.address, web3React, dispatch)
          }}
          tradingChartCurrencyItems={tradingViewPair}
          pairInfoDesc={selectedValue === null ? 'CFI / ETH' : selectedValue}
          pairInfoItems={[
            // {
            //   name: '24-hour Return',
            //   value: '−$1320.20',
            //   subValue: '-32%',
            //   type: 'error',
            // },
            // {
            //   name: 'Volume',
            //   value: '$3.120.240,23',
            // },
            // {
            //   name: 'Liquidiity',
            //   value: '$51.480,122',
            // },
          ]}
          assetPnlChips={[getPnlChip(tradingViewPair[0]?.token0), getPnlChip(tradingViewPair[0]?.token1)]}
          assetPnlBalance={`${chartData.tokenBalance} ${chartData.symbol}`}
          assetPnlPrice={`$${checkFloatNaN(chartData.usdBalanceToken, 'N/A', 2)}`}
          assetPnlItems={assetPnlItems}
        />
      </Block>

      <Block>
        <CurrencyChartTabs />
      </Block>
    </div>
  )
}

BodyExchangeFilled.propTypes = {
  className: PropTypes.string,
}
BodyExchangeFilled.defaultProps = {
  className: '',
}
