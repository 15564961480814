import React from 'react'
import PropTypes from 'prop-types'
import './list.scss'
import { BaseTextBody } from '../BaseText/BaseText'

export const List = ({
  items, noBorder,
}) => (
  <ul className="list">
    {items.map((item, key) => (
      <li className={noBorder ? 'list__item no-border' : 'list__item'} key={key}>
        <div className="list__name">
          <BaseTextBody>{item.name}</BaseTextBody>
        </div>

        <div className="list__value">
          <BaseTextBody {...item.valueState}>{item.value}</BaseTextBody>
          {item.valueIcon && item.valueIcon}
        </div>
      </li>
    ))}
  </ul>
)

List.propTypes = {
  items: PropTypes.array,
  noBorder: PropTypes.bool,
}
