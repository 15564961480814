import React from 'react'
import PropTypes from 'prop-types'
import './chart.scss'
import { PieChart } from '../../../../../../../components/PieChart/PieChart'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../components/BaseText/BaseText'
import { TotalExchangeRates } from './total-exchange-rates/TotalExchangeRates'
import Loading from '../../../../../../../components/Loading/Loading'

export const Chart = ({
  dataTestid,
  title,
  value,
  subvalue,
  subvaluePercent,
  isTotalBalanceReady,
  pieChartItems,
  ...props
}) => (
  <div data-testid={dataTestid} className="chart" {...props}>
    <div className="chart__info">
      <div className="chart__top">
        <div className="chart__title">
          <BaseTextBody secondary>{title}</BaseTextBody>
        </div>

        <div data-testid="dashboard--net-worth--value" className="chart__value">
          {!isTotalBalanceReady && (
            <div className="chart__value__loading">
              <Loading />
            </div>
          )}
          <BaseTextHeading size="L">{value}</BaseTextHeading>
        </div>

        <div className={`chart__subvalue ${(subvaluePercent < 0 || subvalue < 0) && 'chart__subvalue__negative'}`}>
          {!isTotalBalanceReady
            ? (
              <Loading />
            )
            : (
              <>
                <BaseTextBody>
                  {subvaluePercent > 0 ? `+${subvaluePercent}` : subvaluePercent}
                  %
                </BaseTextBody>

                <div className={`second-value ${subvalue < 0 && 'second-value__negative'}`}>
                  {subvalue && (
                  <BaseTextBody>{`($${subvalue})`}</BaseTextBody>
                  )}
                </div>
              </>
            )}
        </div>
      </div>

      <div className="chart__bottom">
        <PieChart
          dataTestid="dashboard--net-worth--percent"
          listShow
          items={pieChartItems}
        />
      </div>
    </div>

    <div className="chart__body">
      <TotalExchangeRates />
    </div>
  </div>
)

Chart.propTypes = {
  pieChartItems: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number,
    text: PropTypes.string,
    color: PropTypes.string,
  })),
  title: PropTypes.string,
  dataTestid: PropTypes.string,
  value: PropTypes.string,
  subvalue: PropTypes.number,
  subvaluePercent: PropTypes.number,
  isTotalBalanceReady: PropTypes.bool,
}
