import React from 'react'
import { BaseTextBody, BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import LevelsItem from './levels-item/LevelsItem'
import LevelFeatures from './levels-item/level-features/LevelFeatures'
import './levels-functionality.scss'
import { Icon } from '../../../../../components/Icon/Icon'
import IconArrowDown from '../../../../../components/Icon/icon--arrow-down.svg'
import { getLoyaltyLevelsFunctionality } from '../../../../../parser/data'

const LevelsFunctionality = () => (
  <div className="levels-functionality">
    <div className="levels-functionality__header">
      <BaseTextHeading size="XL">
        Levels Functionality
      </BaseTextHeading>
    </div>

    {getLoyaltyLevelsFunctionality().map((item, itemIndex) => (
      <LevelsItem
        key={itemIndex}
        title={item.title}
        img={item.img}
        secondaryFeatures={item.secondaryFeatures}
      >
        <LevelFeatures
          top={item.levelFeatures.top}
          bottom={item.levelFeatures.bottom}
        />
      </LevelsItem>
    ))}

    <button className="levels-functionality__more" type="button">
      <BaseTextBody secondary>See more</BaseTextBody>
      <Icon name={IconArrowDown} id="icon--arrow-down" />
    </button>
  </div>
)

export default LevelsFunctionality
