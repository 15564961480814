import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import './message.scss'
import { BaseTextBody, BaseTextHeading } from '../BaseText/BaseText'
import { Status } from '../Status/Status'

const Message = ({
  status,
  title,
  description,
  size,
  left,
  dataTestid,
}) => {
  const titleText = useMemo(() => {
    switch (size) {
      case 'S':
        return <BaseTextHeading size="S">{title}</BaseTextHeading>
      case 'L':
        return <BaseTextHeading size="L">{title}</BaseTextHeading>
      default:
        return <BaseTextHeading>{title}</BaseTextHeading>
    }
  }, [size, title])

  return (
    <div data-testid={dataTestid} className={`message ${left ? 'message--left' : ''}`}>
      {status
        && (
        <div className="message__icon">
          <Status
            type={status}
          />
        </div>
        )}

      {title
        && (
        <div className="message__title">
          {titleText}
        </div>
        )}

      {description
        && (
        <div className="message__description">
          <BaseTextBody secondary>{description}</BaseTextBody>
        </div>
        )}
    </div>
  )
}
Message.propTypes = {
  status: PropTypes.string,
  dataTestid: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.any,
  size: PropTypes.oneOf([
    'S',
    'L',
  ]),
  left: PropTypes.bool,
}

export default Message
