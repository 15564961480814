import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { BlockImgPair } from '../BlockImgPair/BlockImgPair'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import './item-drop-down.scss'
import { getImage } from '../../utils/images'

export const ItemDropDown = ({
  oneImg,
  link,
  address, // Address пары
  token0,
  symbol,
  token1,
  hideAddress,
}) => {
  const [firstImg, setFirstImg] = useState('')
  const [secondImg, setSecondImg] = useState('')

  useEffect(() => {
    let isMounted = true
    const asyncSetSrcImg = async () => {
      const srcFirstToken = await getImage(token0.address)
      const srcSecondToken = await getImage(token1.address)

      if (isMounted) {
        setFirstImg(srcFirstToken.src)
        setSecondImg(srcSecondToken.src)
      }
    }

    asyncSetSrcImg()
    return () => { isMounted = false }
  }, [token0.address, token1.address])

  const renderName = useMemo(() => {
    if (Object.keys(token0).length > 0 && Object.keys(token1).length) {
      return `${token0.symbol} / ${token1.symbol}`
    }
    if (Object.keys(token0).length > 0) {
      return token0.symbol
    }
    if (Object.keys(token1).length) {
      return token1.symbol
    }
    if (symbol) {
      return symbol
    }

    return ''
  }, [symbol, token0, token1])

  return (
    <div className="ItemDropDown-Container">
      <div className="ItemDropDown-Inner">
        <div className="ItemDropDown-Inner-LeftBlock">
          <div className="ItemDropDown-img">
            {oneImg
              ? (
                <BlockImgPair
                  loadingIconFirst={!firstImg}
                  loadingIconSecond={false}
                  firstImg={firstImg}
                />
              )
              : (
                <BlockImgPair
                  loadingIconFirst={!firstImg}
                  loadingIconSecond={!secondImg}
                  firstImg={firstImg}
                  secondImg={secondImg}
                />
              )}
          </div>

          <div className="ItemDropDown-NameLp">
            {renderName}
          </div>
        </div>

        {!hideAddress && (
        <div className="ItemDropDown-Inner-RightBlock">
          <ExternalLink link={link} text={address} />
        </div>
        )}
      </div>
    </div>
  )
}

export const ITEM_DROP_DOWN_TOKEN = PropTypes.shape({
  symbol: PropTypes.string,
  address: PropTypes.string,
})

ItemDropDown.propTypes = {
  address: PropTypes.string, // Address пары
  link: PropTypes.string,
  symbol: PropTypes.string,
  oneImg: PropTypes.bool,
  token0: ITEM_DROP_DOWN_TOKEN,
  token1: ITEM_DROP_DOWN_TOKEN,
  hideAddress: PropTypes.bool,
}
ItemDropDown.defaultProps = {
  token0: {},
  token1: {},
}
