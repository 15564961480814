import React from 'react'
import { BodyHistory } from '../components/BodyHistory/BodyHistory'
import { PageDashboard } from '../components/PageDashboard/PageDashboard'

export const History = () => (
  <div className="dashboard">
    <PageDashboard>
      <BodyHistory />
    </PageDashboard>
  </div>
)
