import React, {
  useMemo,
  useCallback,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { useLocalStorage } from '@rehooks/local-storage'
import { unwrapResult } from '@reduxjs/toolkit'
import { getMessage } from '../../../store/slice/user'
import { toastCritical } from '../../../components/Toast/Toast'
import IconClose from '../../../components/Icon/icon--close.svg'
import { BaseModal } from '../../../components/BaseModal/BaseModal'
import { BaseTextHeading, BaseTextBody } from '../../../components/BaseText/BaseText'
import { Icon } from '../../../components/Icon/Icon'
import { Status } from '../../../components/Status/Status'
import { BaseButton } from '../../../components/BaseButton/BaseButton'
import IconAccountBalanceWallet from '../../../components/Icon/icon--account-balance-wallet.svg'

import './connect-modal.scss'
import { useWeb3 } from '../../../context/web3Provider'
import { LIST_WEB3_PROVIDER } from '../../../const/web3'

const ConnectModal = ({
  onClose,
}) => {
  const dispatch = useDispatch()
  const web3React = useWeb3()
  const [selectedOwnerWallet] = useLocalStorage('selectedOwnerWallet')
  const [isConnectSignApprovePending, setConnectSignApprovePendingState] = useState(false)
  const [isConnectSignApproved, setConnectSignApprovedState] = useState(false)
  const [isUserOnSignStep, setUserOnSignStepState] = useState(false)

  const onStartCyberWalletConnection = useCallback(() => {
    (async () => {
      if (web3React.active) {
        setConnectSignApprovePendingState(true)
        try {
          unwrapResult(await dispatch(getMessage({ account: [selectedOwnerWallet], web3React })))
          setConnectSignApprovePendingState(false)
          setConnectSignApprovedState(true)
        } catch (e) {
          setConnectSignApprovePendingState(false)
          setConnectSignApprovedState(false)
        }
      } else {
        setConnectSignApprovedState(false)
        setConnectSignApprovePendingState(false)
        toastCritical('Your Metamask should be connected to Ethereum Mainnet, but it is connected to some other network')
      }
    })()
  }, [web3React, dispatch, selectedOwnerWallet])

  const getModalHeader = useMemo(() => (
    <BaseButton
      onClick={onClose}
      variant="secondary"
      onlyIcon
      iconLeft={(<Icon name={IconClose} id="icon--close" />)}
    />
  ), [onClose])

  const getModalBody = useMemo(() => {
    const selectedProviderFromLocalStorage = localStorage.getItem('selectedProvider')

    const getSignStep = () => {
      let bodyData = {
        type: 'metamask',
        title: 'Please, sign authentication',
        subTitle: 'Open your MetaMask and authentication.',
      }

      switch (selectedProviderFromLocalStorage) {
        case LIST_WEB3_PROVIDER.walletConnect:
          bodyData = {
            type: 'walletConnect',
            title: 'Please, sign authentication',
            subTitle: 'Open your app and authentication.',
          }
          break
        case LIST_WEB3_PROVIDER.magic:
          bodyData = {
            type: 'google',
            title: 'Signing...',
            subTitle: 'Signing is done automatically.',
          }
          break
        default:
          break
      }

      if (!isConnectSignApprovePending) {
        if (isConnectSignApproved) {
          bodyData = {
            type: 'completed',
            title: 'Authentication signed',
          }
        } else {
          bodyData = {
            type: 'canceled',
            title: 'Authentication canceled by you',
          }
        }
      }

      return (
        <>
          <Status type={bodyData.type} />
          <BaseTextHeading size="S">
            {bodyData.title}
          </BaseTextHeading>
          {
            bodyData.subTitle && (
              <BaseTextBody size="S">
                {bodyData.subTitle}
              </BaseTextBody>
            )
          }
        </>
      )
    }

    return !isUserOnSignStep ? (
      <>
        <div className="connect-modal__icon-block">
          <Icon name={IconAccountBalanceWallet} id="icon--account-balance-wallet" />
        </div>
        <BaseTextHeading size="S">
          Connect Cyber Wallet and discover the full functionality
        </BaseTextHeading>
        <BaseTextBody size="S">
          Cyber Wallet allows you to use all the platform&apos;s functions such as: trading,
          smart investing, regular and multi-chain (bridge) exchange.
        </BaseTextBody>
      </>
    ) : getSignStep()
  }, [isConnectSignApprovePending, isConnectSignApproved, isUserOnSignStep])

  const getModalFooter = useMemo(() => (
    <div className="connect-modal__footer">
      <div className="connect-modal__footer__btn">
        <BaseButton
          variant="secondary"
          onClick={onClose}
        >
          Skip for now
        </BaseButton>
        <BaseButton
          dataTestid="connect-cyber-wallet-button"
          disabled={isConnectSignApprovePending}
          onClick={() => {
            setUserOnSignStepState(true)
            setConnectSignApprovePendingState(true)
            onStartCyberWalletConnection()
          }}
        >
          Connect Cyber Wallet
        </BaseButton>
      </div>
    </div>
  ), [onClose, isConnectSignApprovePending, setUserOnSignStepState, onStartCyberWalletConnection])

  return (
    <BaseModal
      dataTestId="connect-cyber-wallet"
      title="Connect Cyber Wallet"
      topAction={getModalHeader}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

ConnectModal.propTypes = {
  onClose: PropTypes.func,
}

export default ConnectModal
