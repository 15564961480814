import React, {
  useMemo, useEffect, useCallback, useState, useRef,
} from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'

import { BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import SwapForm from './swap-form/SwapForm'
import SettingsForm from './settings-form/SettingsForm'
import { getTokenData } from '../../../../../service/api'
import { getExactTokenBalance } from '../../../../../service/etherscanApi'
import IconSettings from '../../../../../components/Icon/icon--settings.svg'
import { BaseButton } from '../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../components/Icon/Icon'
import IconArrowBack from '../../../../../components/Icon/icon--arrow-back.svg'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../../../../../const'
import { weiToEth } from '../../../../../utils/helperFunctions'
import { parseNativeTokenData } from '../../../../../parser/data'
import { useWeb3Bn } from '../../../../../hooks/web3'
import { useWeb3 } from '../../../../../context/web3Provider'
import './exchange-swap.scss'

const ExchangeSwap = () => {
  const web3React = useWeb3()
  const Web3BN = useWeb3Bn()

  const tokensDataPoller = useRef()
  const isPollerRequestPending = useRef()

  const [cancelTokenSource, setCancelTokenSource] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const selectedTokensPair = useSelector((store) => store.trade.selectedTokensPair)
  const { latestNativeTokenPrice, tokens } = useSelector((store) => store.user)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [tradeSettings] = useLocalStorage('tradeSettings')

  const [tokensPair, setTokensPair] = useState({})
  const [isSettingsFormOpened, setSettingsFormIsOpenedState] = useState(false)
  const [cachedSelectedTokensPair, setCachedSelectedTokensPairState] = useState({})
  const [cachedTokenValue, setCachedTokenValue] = useState()

  const [isUpdateTokensRequestPending, setUpdatedTokensRequestIsPendingState] = useState(false)

  const loadTokenData = useCallback(async (token, cancelToken) => {
    if (![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(token.address)) {
      const { address } = token
      const checkedAddress = web3React.library.utils.toChecksumAddress(address)
      const data = await getTokenData(checkedAddress, cancelToken)
      const exactTokenBalance = await getExactTokenBalance({
        tokenAddress: checkedAddress,
        userAddress: internalSelectedAddress,
        decimals: data.decimals,
        Web3BN,
        cancelToken,
      })
      return { ...data, exactTokenBalance }
    }

    const foundToken = tokens.filter((userToken) => (
      userToken.address === token.address
      || userToken.contract_address === token.address
      || (token.address === ZERO_ADDRESS && userToken.contract_address === NEW_ETH_ADDRESS)
    ))[0]
    return {
      ...parseNativeTokenData(token, latestNativeTokenPrice),
      exactTokenBalance: weiToEth(web3React?.library.utils.fromWei, foundToken.balance.toString()),
    }
  }, [web3React, internalSelectedAddress, latestNativeTokenPrice, tokens, Web3BN])

  const loadAndSetTokensPair = useCallback(async (cancelToken) => {
    try {
      const token1 = await loadTokenData(selectedTokensPair.token1, cancelToken)
      const token2 = await loadTokenData(selectedTokensPair.token2, cancelToken)
      setTokensPair({ token1, token2 })
      isPollerRequestPending.current = false
    } catch (e) {
      console.log('Tokens pair setting error = ', e)
      isPollerRequestPending.current = false
    }
  }, [selectedTokensPair, loadTokenData])

  const stopPollingForTokensData = useCallback(() => {
    if (tokensDataPoller.current) {
      clearInterval(tokensDataPoller.current)
      tokensDataPoller.current = undefined
      isPollerRequestPending.current = false
    }
  }, [])

  const startPollingForTokensData = useCallback(async (cancelToken) => {
    if (!tokensDataPoller.current) {
      const intervalTime = 10000
      tokensDataPoller.current = setInterval(async () => {
        if (!isPollerRequestPending.current) {
          isPollerRequestPending.current = true
          loadAndSetTokensPair(cancelToken)
        }
      }, intervalTime)
    }
  }, [loadAndSetTokensPair])

  useEffect(() => {
    (async () => {
      if (web3React.library && JSON.stringify(cachedSelectedTokensPair) !== JSON.stringify(selectedTokensPair)) {
        if (cancelTokenSource) {
          cancelTokenSource.cancel()
          stopPollingForTokensData()
        }

        const newCancelTokenSource = axios.CancelToken.source()

        setCancelTokenSource(newCancelTokenSource)
        setTokensPair({})
        setCachedSelectedTokensPairState(selectedTokensPair)
        loadAndSetTokensPair(newCancelTokenSource.token)
        startPollingForTokensData(newCancelTokenSource.token)
      }
    })()
  }, [
    selectedTokensPair,
    cachedSelectedTokensPair,
    web3React,
    loadAndSetTokensPair,
    startPollingForTokensData,
    stopPollingForTokensData,
    cancelTokenSource,
  ])

  const onSwapTokens = useCallback(async () => {
    setUpdatedTokensRequestIsPendingState(true)
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
        stopPollingForTokensData()
      }

      const newCancelTokenSource = await axios.CancelToken.source()
      setCancelTokenSource(newCancelTokenSource)

      const cachedFirstToken = tokensPair.token1
      const token1 = await loadTokenData(tokensPair.token2, newCancelTokenSource.token)
      const token2 = await loadTokenData(cachedFirstToken, newCancelTokenSource.token)

      setTokensPair({ token1, token2 })
      setUpdatedTokensRequestIsPendingState(false)
    } catch (e) {
      console.error('Swap Tokens', e)
      setUpdatedTokensRequestIsPendingState(false)
    }
  }, [cancelTokenSource, tokensPair, loadTokenData, stopPollingForTokensData])

  const onSetFirstToken = useCallback(async (firstToken) => {
    setUpdatedTokensRequestIsPendingState(true)
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
        stopPollingForTokensData()
      }

      const newCancelTokenSource = await axios.CancelToken.source()
      setCancelTokenSource(newCancelTokenSource)

      const token1 = await loadTokenData(firstToken, newCancelTokenSource.token)

      setTokensPair({ token1, token2: tokensPair.token2 })
      setUpdatedTokensRequestIsPendingState(false)
    } catch (e) {
      console.error('onSetFirstToken', e)
      setUpdatedTokensRequestIsPendingState(false)
    }
  }, [cancelTokenSource, loadTokenData, stopPollingForTokensData, tokensPair.token2])

  const onSetSecondToken = useCallback(async (secondToken) => {
    setUpdatedTokensRequestIsPendingState(true)
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel()
        stopPollingForTokensData()
      }

      const newCancelTokenSource = await axios.CancelToken.source()
      setCancelTokenSource(newCancelTokenSource)

      const token2 = await loadTokenData(secondToken, newCancelTokenSource.token)

      setTokensPair({ token1: tokensPair.token1, token2 })
      setUpdatedTokensRequestIsPendingState(false)
    } catch (e) {
      console.error('onSetFirstToken', e)
      setUpdatedTokensRequestIsPendingState(false)
    }
  }, [cancelTokenSource, loadTokenData, stopPollingForTokensData, tokensPair.token1])

  const getSettingsFormTemplate = useMemo(() => (
    <SettingsForm
      gas={tradeSettings ? tradeSettings.gas : 'fastest'}
      onChangeGas={(newGas) => {
        writeStorage('tradeSettings', {
          ...tradeSettings,
          gas: newGas,
        })
      }}
      slippage={tradeSettings ? tradeSettings.slippage : '0.1'}
      onChangeSlippage={(newSlippage) => {
        writeStorage('tradeSettings', {
          ...tradeSettings,
          slippage: newSlippage,
        })
      }}
    />
  ), [tradeSettings])

  const getHeader = useMemo(() => (
    !isSettingsFormOpened
      ? (
        <>
          <BaseTextHeading size="S">Swap</BaseTextHeading>

          {!isLoading && (
            <BaseButton
              onlyIcon
              variant="secondary"
              onClick={() => setSettingsFormIsOpenedState(true)}
              iconLeft={(<Icon name={IconSettings} id="icon--settings" />)}
            />
          )}
        </>
      )
      : (
        <>
          <BaseButton
            onlyIcon
            variant="secondary"
            onClick={() => setSettingsFormIsOpenedState(false)}
            iconLeft={(<Icon name={IconArrowBack} id="icon--arrow-back" />)}
          />
          <BaseTextHeading size="S">Settings</BaseTextHeading>
        </>
      )
  ), [isSettingsFormOpened, isLoading])

  useEffect(() => {
    setIsLoading(!(tokensPair.token1 && tokensPair.token2))
  }, [tokensPair.token1, tokensPair.token2])

  return (
    <div className="exchange-trade-container">
      <div className="exchange-trade-container__header">
        {getHeader}
      </div>

      <div className="exchange-trade-container__body">
        {isSettingsFormOpened
          ? getSettingsFormTemplate
          : (
            <SwapForm
              internalSelectedAddress={internalSelectedAddress}
              isUpdateTokensRequestPending={isUpdateTokensRequestPending}
              tokensPair={tokensPair}
              isLoading={isLoading}
              onSwapTokens={onSwapTokens}
              onSelectFirstToken={(token) => onSetFirstToken(token)}
              onSelectSecondToken={(token) => onSetSecondToken(token)}
              onUpdateTokenValue={(tokenValue) => setCachedTokenValue(tokenValue)}
              cachedTokenValue={cachedTokenValue}
            />
          )}
      </div>
    </div>
  )
}

export default ExchangeSwap
