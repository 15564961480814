import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import { BaseTabs } from '../../../../../components/BaseTabs/BaseTabs'
import OrdersTable from './orders-table/OrdersTable'
import HistoryTable from './history-table/HistoryTable'
import { getTokensPairsList } from '../../../../../service/api'
import IconSelectBase from '../../../../../components/Icon/icon--select-base.svg'
import { SelectBase } from '../../../../../components/SelectBase/SelectBase'
import ImgPairBlock from '../../../../../components/ImgPairBlock/ImgPairBlock'
import { Icon } from '../../../../../components/Icon/Icon'
import IconSelectCircleActive from '../../../../../components/Icon/icon--select-circle-active.svg'
import IconSelectCircle from '../../../../../components/Icon/icon--select-circle.svg'
import { getImage } from '../../../../../utils/images'
import OrderDetailsModal from './order-details-modal/OrderDetailsModal'

import './orderbook.scss'

const Orderbook = () => {
  const getInitialSelectedPair = (isItem) => ({
    name: (
      <div className={`orderbook__select-pairs ${isItem ? 'item' : ''}`}>
        <ImgPairBlock text="All pairs" isLoadingFirstIcon isLoadingSecondIcon />
      </div>
    ),
    value: 'all-pairs',
  })

  const [selectedTab, setSelectedTabState] = useState('orders')
  const [selectedPair, setSelectedPairState] = useState(getInitialSelectedPair())

  const [isTokensListLoaded, setTokensListIsLoadedState] = useState(false)
  const [isTokensListRequestPending, setTokensListRequestIsPendingState] = useState(false)
  const [tokensPairs, setTokensPairsState] = useState([{
    ...getInitialSelectedPair(true),
    icon: <Icon name={IconSelectCircleActive} id="icon--select-circle-active" />,
  }])

  const [isOrderDetailsModalShown, setOrderDetailsModalShownState] = useState(false)
  const [selectedOrder, setSelectedOrderState] = useState()

  const [shouldBeTableReloaded, setTableShouldBeReloaded] = useState()

  const loadTokensPairsList = useCallback(async () => {
    setTokensListRequestIsPendingState(true)

    try {
      const pairs = await getTokensPairsList()

      const newPairsList = [tokensPairs[0]]
      pairs.forEach(async (item) => {
        const firstTokenIcon = await getImage(item.token1.address)
        const secondTokenIcon = await getImage(item.token2.address)

        newPairsList.push({
          name: (
            <div className="orderbook__select-pairs item">
              <ImgPairBlock
                firstTokenIcon={firstTokenIcon.src}
                secondTokenIcon={secondTokenIcon.src}
                text={`${item.token1.symbol}/${item.token2.symbol}`}
              />
            </div>
          ),
          value: {
            token1: item.token1,
            token2: item.token2,
          },
          icon: <Icon name={IconSelectCircle} id="icon--select-circle" />,
        })
      })

      setTokensPairsState(newPairsList)
      setTokensListRequestIsPendingState(false)
      setTokensListIsLoadedState(true)
    } catch (e) {
      setTokensPairsState([tokensPairs[0]])
      setTokensListRequestIsPendingState(false)
      setTokensListIsLoadedState(true)
    }
  }, [tokensPairs])

  useEffect(() => {
    if (!isTokensListLoaded && !isTokensListRequestPending) {
      loadTokensPairsList()
    }
  }, [isTokensListLoaded, isTokensListRequestPending, loadTokensPairsList])

  const getLastColumnForHistoryTable = () => (
    {
      name: '',
      selector: 'more',
      right: true,
      width: '50px',
      style: {
        '.select-base__header': {
          paddingRight: '12px',
        },
        'div:first-child': {
          overflow: 'initial !important',
        },
      },
    }
  )

  const onSelectNewPair = useCallback(async (item) => {
    let text = 'All pairs'
    let firstTokenIcon
    let secondTokenIcon

    if (item !== 'all-pairs') {
      firstTokenIcon = await getImage(item.token1.address)
      secondTokenIcon = await getImage(item.token2.address)
      text = `${item.token1.symbol}/${item.token2.symbol}`
    }

    setTokensPairsState(tokensPairs.map((pair) => (
      {
        ...pair,
        icon: JSON.stringify(item) !== JSON.stringify(pair.value)
          ? <Icon name={IconSelectCircle} id="icon--select-circle" />
          : <Icon name={IconSelectCircleActive} id="icon--select-circle-active" />,
      }
    )))

    setSelectedPairState({
      name: (
        <div className="orderbook__select-pairs">
          <ImgPairBlock
            isLoadingFirstIcon={item === 'all-pairs' || !firstTokenIcon.isExist}
            isLoadingSecondIcon={item === 'all-pairs' || !secondTokenIcon.isExist}
            firstTokenIcon={firstTokenIcon ? firstTokenIcon.src : undefined}
            secondTokenIcon={secondTokenIcon ? secondTokenIcon.src : undefined}
            text={text}
          />
        </div>
      ),
      value: item,
    })
  }, [tokensPairs])

  const getColumns = useCallback(() => {
    const columns = [
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: <SelectBase
          selected={selectedPair.name}
          onChange={onSelectNewPair}
          className="select-base--new"
          headerIcon={IconSelectBase}
          headerIconID="icon--select-base"
          items={tokensPairs}
          dataTestid="orderbookSelektPair"
        />,
        selector: 'pairs',
      },
      {
        name: 'Transaction',
        selector: 'transaction',
      },
      {
        name: 'Type',
        selector: 'type',
      },
      {
        name: 'Trigger Conditions',
        selector: 'triggerConditions',
      },
      {
        name: 'Created',
        selector: 'creationTime',
        sortable: true,
        right: true,
      },
    ]

    if (selectedTab === 'orders') {
      columns.push(getLastColumnForHistoryTable())
    }

    return columns
  }, [selectedPair.name, onSelectNewPair, tokensPairs, selectedTab])

  const onShowOrderModal = useCallback((order) => {
    setSelectedOrderState(order)
    setOrderDetailsModalShownState(true)
  }, [])

  const getTabs = useMemo(() => [
    {
      text: 'Orders',
      renderContent: () => (
        <OrdersTable
          columns={getColumns()}
          selectedPair={selectedPair.value}
          onOrderClick={onShowOrderModal}
          shouldBeTableReloaded={shouldBeTableReloaded}
        />
      ),
      testId: 'ordersTab',
    },
    {
      text: 'History',
      renderContent: () => (
        <HistoryTable
          columns={getColumns()}
          selectedPair={selectedPair.value}
          onOrderClick={onShowOrderModal}
        />
      ),
      testId: 'historyTab',
    },
  ], [getColumns, selectedPair.value, onShowOrderModal, shouldBeTableReloaded])

  return (
    <div className="orderbook-container" data-testid="orderbookTable">
      <div className="orderbook__header">
        <BaseTextHeading size="S">Orderbook</BaseTextHeading>
      </div>

      <div className="orderbook__body">
        <BaseTabs
          tabs={getTabs}
          onChange={(newTab) => {
            setSelectedTabState(newTab.toLowerCase())
            setSelectedPairState(getInitialSelectedPair())
            setTokensPairsState(tokensPairs.map((pair) => {
              if (pair.value === 'all-pairs') {
                return { ...pair, icon: <Icon name={IconSelectCircleActive} id="icon--select-circle-active" /> }
              }
              return { ...pair, icon: <Icon name={IconSelectCircle} id="icon--select-circle" /> }
            }))
          }}
        />
      </div>

      {isOrderDetailsModalShown && (
        <OrderDetailsModal
          selectedOrder={selectedOrder}
          onClose={() => {
            setOrderDetailsModalShownState(false)
          }}
          onCancelOrder={() => {
            setOrderDetailsModalShownState(false)
            setTableShouldBeReloaded(true)
            setTimeout(() => setTableShouldBeReloaded(false))
          }}
          onUrgentlyExecuteOrder={() => {
            setOrderDetailsModalShownState(false)
            setTableShouldBeReloaded(true)
            setTimeout(() => setTableShouldBeReloaded(false))
          }}
        />
      )}
    </div>
  )
}

export default Orderbook
