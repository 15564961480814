import React from 'react'
import './loyalty.scss'
import { Social } from '../../../../components/Social/Social'
import FirstScreenBanner from '../../../../components/first-screen-banner/FirstScreenBanner'
import backgroundImg01 from '../../../../components/first-screen-banner/backgroundImg--01.png'
import { BaseTextBody, BaseTextHeading } from '../../../../components/BaseText/BaseText'
import { BaseButton } from '../../../../components/BaseButton/BaseButton'
import UserLevels from './user-levels/UserLevels'
import LevelsFunctionality from './levels-functionality/LevelsFunctionality'
import CompleteFunctionality from './complete-functionality/CompleteFunctionality'

const Loyalty = () => (
  <div className="loyalty">
    <FirstScreenBanner
      backgroundImg={backgroundImg01}
    >
      <BaseTextHeading size="XXL">
        <span>Warlock</span>
        Loyalty Program
      </BaseTextHeading>

      <BaseTextBody>Description - стейкерам CFI больше плюшек платформы, выгоднее условия и тд</BaseTextBody>

      <div className="first-screen-banner__actions">
        <BaseButton>Buy CFI</BaseButton>
        <BaseButton>Stake CFI</BaseButton>
      </div>
    </FirstScreenBanner>

    <UserLevels />

    <div className="base-container">
      <LevelsFunctionality />

      <CompleteFunctionality />
    </div>

    <div className="loyalty__footer">
      <Social
        twitter
        discord
        telegram
      />
    </div>
  </div>
)

export default Loyalty
