import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../components/Icon/icon--close.svg'
import { Status } from '../../../../../../../components/Status/Status'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../components/BaseText/BaseText'
import { Divider } from '../../../../../../../components/Divider/Divider'
import { GodModContent } from './god-mod-content/GodModContent'
import { BaseModal } from '../../../../../../../components/BaseModal/BaseModal'
import { enableGodMode } from '../../../../../../../service/api'
import { toggleGodModeStatus } from '../../../../../../../store/slice/user'

export const GodModeModal = ({
  chosenCyberWallet,
  closeModalGodModal,
}) => {
  const dispatch = useDispatch()
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')

  const [enterPinGodMode, setEnterPinGodMode] = useState('')
  const [errorGodMode, setErrorGodMode] = useState()
  const [selectedExpire, setSelectedExpire] = useState(1)

  const sendConfirmGodMode = useCallback(() => {
    (async () => {
      try {
        await enableGodMode({
          address: chosenCyberWallet,
          pin: enterPinGodMode,
          hours: selectedExpire,
        })
        if (internalSelectedAddress === chosenCyberWallet) {
          dispatch(toggleGodModeStatus(true))
        }
        setErrorGodMode()
        closeModalGodModal()
      } catch (e) {
        setErrorGodMode('Invalid pin')
        console.error(e)
      }
    })()
  }, [enterPinGodMode, chosenCyberWallet, selectedExpire, closeModalGodModal, dispatch, internalSelectedAddress])

  return (
    <BaseModal
      onClickOutside={closeModalGodModal}
      title="Enable God Mode"
      topAction={(
        <BaseButton
          onClick={closeModalGodModal}
          variant="secondary"
          onlyIcon
          iconLeft={(
            <Icon
              name={IconClose}
              id="icon--close"
            />
          )}
        />
      )}
      footer={(
        <>
          <BaseButton
            onClick={closeModalGodModal}
            variant="secondary"
          >
            Cancel
          </BaseButton>

          <BaseButton
            dataTestid="popup--god-mode-next-step"
            onClick={sendConfirmGodMode}
            disabled={!enterPinGodMode}
          >
            Enable God Mode
          </BaseButton>
        </>
      )}
    >
      <Status
        type="inclusive"
      />

      <BaseTextHeading
        size="S"
      >
        Enter Cyber Wallet PIN and set time period
      </BaseTextHeading>

      <BaseTextBody secondary>
        God Mode allows you to make transactions without a signature.
      </BaseTextBody>

      <Divider />

      <GodModContent
        onChangeSelectBase={(item) => setSelectedExpire(item)}
        selectedExpire={selectedExpire}
        error={errorGodMode}
        inputValue={enterPinGodMode}
        onChangeInput={(e) => setEnterPinGodMode(e.target.value)}
      />
    </BaseModal>
  )
}

GodModeModal.propTypes = {
  chosenCyberWallet: PropTypes.string,
  closeModalGodModal: PropTypes.func,
}
