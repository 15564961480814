import React, { useCallback } from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import TradingViewWidget, { Themes, BarStyles, RangeTypes } from 'react-tradingview-widget'
import PropTypes from 'prop-types'

export const TradingViewChart = ({
  currencyItems,
}) => {
  const [selectedChain] = useLocalStorage('selectedChain')

  const getSymbol = useCallback(() => {
    const network = selectedChain === 'BSC' ? 'BINANCE' : 'UNISWAP'
    const symbol = `${network}:${currencyItems[0]?.value?.replace('/', '')?.toUpperCase()}`
    // an old check from the old functionality (?)
    return symbol === 'UNISWAP:WETHUSDT' ? 'BINANCE:ETHUSDT' : symbol
  }, [selectedChain, currencyItems])

  return (
    <TradingViewWidget
      symbol={getSymbol()}
      theme={Themes.DARK}
      locale="en"
      range={RangeTypes.YTD}
      interval={30}
      hide_side_toolbar={0}
      autosize
      style={BarStyles.LINE}
      allow_symbol_change={false}
    />
  )
}

TradingViewChart.propTypes = {
  currencyItems: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
  })),
}
