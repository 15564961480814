import { useMemo } from 'react'
import { useWeb3 } from '../context/web3Provider'

/**
 * @returns {Object|null}
 */
export const useWeb3Bn = () => {
  const web3React = useWeb3()

  const BN = useMemo(() => {
    if (web3React && web3React.library) {
      return web3React.library.utils.BN
    }
    return null
  }, [web3React])

  return BN
}
