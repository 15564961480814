import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import './assets-table.scss'
// eslint-disable-next-line import/no-cycle
import { CustomDataTable, CustomDataTableProps } from '../../../../../../../components/DataTable/DataTable'
import { BaseTextHeading, BaseTextSubheading } from '../../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IocnSwitch from '../../../../../../../components/Icon/icon--switch.svg'

export const AssetsTable = ({
  name,
  value,
  pnl,
  down,
  table,
  openAssets,
  onRowClicked,
  areTokensLoading,
  isDeposits,
}) => {
  const [open, setOpen] = useState(openAssets)

  const getClsAssetTablePnl = useMemo(() => {
    let cls = 'assets-table__pnl'

    if (pnl.percent < 0 || pnl.currency < 0) {
      cls = `${cls} assets-table--down`
    }

    return cls
  }, [pnl])
  const getClsAssetTablePnlCurrency = useMemo(() => {
    let cls = 'assets-table__pnl-currency'

    if (pnl.currency < 0) {
      cls = `${cls} assets-table--down`
    }

    return cls
  }, [pnl])

  return (
    <div className={`assets-table ${down ? 'assets-table--down' : ''} ${open ? 'assets-table--open' : ''}`}>
      <div className="assets-table__header" onClick={() => setOpen(!open)}>
        <div className="assets-table__header-body">
          <div className="assets-table__name">
            <BaseTextHeading size="S">{name}</BaseTextHeading>
            <BaseTextHeading size="S">
              {' '}
              ·
              {value}
            </BaseTextHeading>
          </div>

          {!isDeposits && (
            <div className={getClsAssetTablePnl}>
              <div className={getClsAssetTablePnl}>
                <BaseTextSubheading>
                  {pnl.percent > 0 ? `+${pnl.percent}` : pnl.percent}
                  %
                </BaseTextSubheading>
              </div>

              <div className={getClsAssetTablePnlCurrency}>
                <BaseTextSubheading>
                  ($
                  {pnl.currency > 0 ? `+${pnl.currency}` : pnl.currency}
                  )
                </BaseTextSubheading>
              </div>
            </div>
          )}
        </div>

        <div className="assets-table__header-icon">
          <Icon name={IocnSwitch} id="icon--switch" />
        </div>
      </div>

      <div className="assets-table__body">
        <CustomDataTable
          paginationServer={false}
          columns={table.columns}
          data={table.data}
          onRowClicked={onRowClicked}
          sortServer={false}
          isDataLoading={areTokensLoading}
        />
      </div>
    </div>
  )
}

AssetsTable.propTypes = {
  name: PropTypes.string,
  onRowClicked: PropTypes.func,
  value: PropTypes.string,
  pnl: PropTypes.shape({
    percent: PropTypes.number,
    currency: PropTypes.number,
  }),
  down: PropTypes.bool,
  table: PropTypes.shape(CustomDataTableProps),
  openAssets: PropTypes.bool,
  areTokensLoading: PropTypes.bool,
  isDeposits: PropTypes.bool,
}
