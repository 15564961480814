import React, { useMemo } from 'react'

import './loading.scss'
import PropTypes from 'prop-types'

const Loading = ({ theme, size }) => {
  const clsLoader = useMemo(() => {
    let cls = 'loader'

    switch (theme) {
      case 'dark':
        cls = `${cls} loader--dark`
        break
      default:
    }

    if (size === 'S') {
      cls = `${cls} loader__size--s`
    }

    return cls
  }, [theme, size])

  return (
    <div className={clsLoader} />
  )
}

Loading.propTypes = {
  theme: PropTypes.oneOf([
    'dark',
  ]),
  size: PropTypes.oneOf([
    'S',
  ]),
}

export default Loading
