import React from 'react'

import './globalLoading.scss'

const GlobalLoading = () => (
  <div className="GlobalLoading-Block">
    <div className="GlobalLoading" />
  </div>

)

export default GlobalLoading
