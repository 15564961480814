export const LIST_WEB3_PROVIDER = {
  metamask: 'metamask',
  magic: 'magic',
  walletConnect: 'walletConnect',
  bsc: 'BSC',
  polygon: 'POLYGON',
}

export const CONST_TYPE_CONNECT = {
  metamask: 'metamask',
  walletConnect: 'walletConnect',
  google: 'google',
  twitter: 'twitter',
}

export const SUPPORTED_CHAINS = {
  ethereum: 'Ethereum',
  bsc: 'BSC',
  polygon: 'Polygon',
}
