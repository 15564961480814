import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react'

import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector } from 'react-redux'
import { Chip } from '../../../../../../../components/Chip/Chip'
import Loading from '../../../../../../../components/Loading/Loading'
import { BaseTextHeading } from '../../../../../../../components/BaseText/BaseText'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../../../../../../../const'
import { getTokenData, getPoolLiquidity } from '../../../../../../../service/api'
import { getExactTokenBalance } from '../../../../../../../service/etherscanApi'
import { useWeb3Bn } from '../../../../../../../hooks/web3'
import {
  formatEth,
  checkFloatNaN,
  weiToEth,
} from '../../../../../../../utils/helperFunctions'
import DetailItem from './detail-item/DetailItem'
import { getUserTokensSum, getNativeTokenDataBasedOnNetworkWithZeroBalance } from '../../../../../../../parser/data'

import './bottom-pair-control.scss'
import { useWeb3 } from '../../../../../../../context/web3Provider'

const BottomPairControl = () => {
  const web3React = useWeb3()
  const Web3BN = useWeb3Bn()

  const ethAddresses = [ZERO_ADDRESS, NEW_ETH_ADDRESS]
  const tokensPair = useSelector((store) => store.trade.selectedTokensPair)
  const [cachedTokensPair, setCachedTokensPairState] = useState(tokensPair)
  const { latestNativeTokenPrice, tokens } = useSelector((store) => store.user)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')

  const [selectedToken, setSelectedTokenState] = useState(tokensPair[Object.keys(tokensPair)[0]])
  const [totalBalance, setTotalBalanceState] = useState(0)
  const [totalBalanceInUsd, setTotalBalanceInUsdState] = useState(0)
  const [isDetailsRequestPending, setDetailsRequestPendingState] = useState(false)
  const [areDetailsLoaded, setDetailsLoadedState] = useState(false)
  const [additionalDetails, setAdditionalDetailsState] = useState([])

  const loadTokenBalanceForNativeToken = useCallback(async () => {
    const foundToken = tokens.length > 0
      ? tokens.filter((userToken) => (
        userToken.address === selectedToken.address || userToken.contract_address === selectedToken.address
      ))[0]
      : getNativeTokenDataBasedOnNetworkWithZeroBalance()
    const tokenValue = weiToEth(web3React.library.utils.fromWei, foundToken.balance.toString())
    setTotalBalanceState(`${tokenValue} ${selectedToken.symbol}`)

    const total = formatEth(parseFloat(tokenValue) * parseFloat(latestNativeTokenPrice))
    const totalInUsd = formatEth(checkFloatNaN(total, 0), 2).toString()
    setTotalBalanceInUsdState(totalInUsd)

    return { total: tokenValue, totalInUsd }
  }, [web3React, latestNativeTokenPrice, selectedToken, tokens])

  const loadTokenBalanceForOtherToken = useCallback(async () => {
    const data = await getTokenData(selectedToken.address)
    const exactTokenBalance = await getExactTokenBalance({
      tokenAddress: selectedToken.address,
      userAddress: internalSelectedAddress,
      decimals: data.decimals,
      Web3BN,
    })
    setTotalBalanceState(`${exactTokenBalance} ${selectedToken.symbol}`)

    const totalInUsd = formatEth(checkFloatNaN(formatEth(exactTokenBalance * data.usdtPrice), 0), 2).toString()
    setTotalBalanceInUsdState(totalInUsd)

    return { total: exactTokenBalance, totalInUsd }
  }, [
    selectedToken.address,
    internalSelectedAddress,
    selectedToken.symbol,
    Web3BN,
  ])

  const getLiquidityData = useCallback(async () => {
    const {
      parsedTotalPnl,
      totalPnl,
      averageCost,
      returnFloat,
      returnPercents,
      fees,
    } = await getPoolLiquidity({
      address: selectedToken.address === NEW_ETH_ADDRESS ? ZERO_ADDRESS : selectedToken.address,
      internalAddress: internalSelectedAddress,
      isShouldParse: true,
    })

    return [
      {
        title: 'Profit / Loss',
        value: parsedTotalPnl,
        isError: totalPnl < 0,
      },
      {
        title: 'Average Cost',
        value: `$${averageCost}`,
        isError: averageCost < 0,
      },
      {
        title: '24-hour Return',
        value: `$${returnFloat}`,
        subValue: `${returnPercents}%`,
        isError: returnFloat < 0,
      },
      {
        title: 'Paid Fees',
        value: `$${fees}`,
        isError: fees < 0,
      },
    ]
  }, [selectedToken.address, internalSelectedAddress])

  const loadAdditionalDetails = useCallback(async (balance) => {
    const details = []

    const usdTotalBalance = (getUserTokensSum({
      tokens,
      BN: Web3BN,
      web3React,
      latestNativeTokenPrice,
    })).totalSum

    const equity = checkFloatNaN((balance.totalInUsd / (usdTotalBalance)) * 100, 0, 2)
    details.push({
      title: `Equity · ${equity}%`,
      value: `${checkFloatNaN(balance.total, 'N/A', 5)} ${selectedToken.symbol}`,
    })

    details.push(...await getLiquidityData())
    setAdditionalDetailsState(details)
    setDetailsLoadedState(true)
  }, [selectedToken, Web3BN, web3React, latestNativeTokenPrice, tokens, getLiquidityData])

  const loadSelectedTokenDetails = useCallback(async () => {
    setDetailsRequestPendingState(true)

    try {
      const balance = !ethAddresses.includes(selectedToken.address)
        ? await loadTokenBalanceForOtherToken()
        : await loadTokenBalanceForNativeToken()
      await loadAdditionalDetails(balance)

      setDetailsRequestPendingState(false)
    } catch (e) {
      console.log('error = ', e)
      setDetailsRequestPendingState(false)
      setDetailsLoadedState(true)
      setAdditionalDetailsState([])
    }
  }, [
    ethAddresses,
    selectedToken,
    loadTokenBalanceForOtherToken,
    loadTokenBalanceForNativeToken,
    loadAdditionalDetails,
  ])

  useEffect(() => {
    if (!areDetailsLoaded && !isDetailsRequestPending && Web3BN && latestNativeTokenPrice) {
      loadSelectedTokenDetails()
    }
  }, [
    areDetailsLoaded,
    isDetailsRequestPending,
    selectedToken.address,
    loadSelectedTokenDetails,
    Web3BN,
    latestNativeTokenPrice,
    selectedToken,
  ])

  useEffect(() => {
    if (JSON.stringify(tokensPair) !== JSON.stringify(cachedTokensPair)) {
      setCachedTokensPairState(tokensPair)
      setDetailsLoadedState(false)
      setSelectedTokenState(tokensPair.token1)
    }
  }, [tokensPair, cachedTokensPair])

  const getDetailsTemplate = useMemo(() => (
    additionalDetails.length > 0
      ? additionalDetails.map((detail, key) => (
        <DetailItem {...detail} key={key} />
      ))
      : 'Details loading failed...'
  ), [additionalDetails])

  const getChips = useMemo(() => (
    <div data-testid="trade--chart-pnl--chips" className="chips">
      {Object.keys(tokensPair).map((key, index) => {
        const token = tokensPair[key]
        return (
          <Chip
            onClick={() => {
              if (selectedToken.symbol !== token.symbol) {
                setDetailsLoadedState(false)
                setSelectedTokenState(token)
              }
            }}
            icon={<img className="image-icon" src={token.icon} alt="img" />}
            iconPosition="left"
            size="L"
            outline
            isSelected={selectedToken.symbol === token.symbol}
            key={index}
            isDisabled={!areDetailsLoaded}
          >
            {token.symbol}
          </Chip>
        )
      })}
    </div>
  ), [tokensPair, selectedToken, areDetailsLoaded])

  return (
    <div className="bottom-pair-control-container">
      <div className="top-block">
        {getChips}

        <div data-testid="trade--chart-pnl--balance" className="details">
          {
            areDetailsLoaded && (
              additionalDetails.length > 0 ? (
                <>
                  <BaseTextHeading size="S">{totalBalance}</BaseTextHeading>
                  <BaseTextHeading size="S" secondary>
                    ($
                    {totalBalanceInUsd}
                    )
                  </BaseTextHeading>
                </>
              ) : (
                <BaseTextHeading size="S" isSecondary>Balance loading failed...</BaseTextHeading>
              )
            )
          }
          {!areDetailsLoaded && <Loading />}
        </div>
      </div>

      <div
        data-testid="trade--chart-pnl--info"
        className={
        `selected-chip-details-block
        ${!areDetailsLoaded ? 'loading' : ''}
        ${areDetailsLoaded && additionalDetails.length === 0 ? 'failed' : ''}
        `
        }
      >
        {areDetailsLoaded ? getDetailsTemplate : <Loading />}
      </div>
    </div>
  )
}

export default BottomPairControl
