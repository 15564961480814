import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../../../../../../../../../components/Icon/Icon'
import IconOpenInNew from '../../../../../../../../../components/Icon/icon--open-in-new.svg'
import { Text } from '../../../../../../../../../components/Text/Text'
import { getLinkBasedOnCurrentNetwork } from '../../../../../../../../../parser/data'

import './drop-down.scss'

export const DropDown = ({
  items,
  isOpen,
  onClick = () => {},
}) => {
  const classNameListContainer = useMemo(() => {
    let cls = 'dropdown-container'

    if (isOpen) {
      cls += ' dropdown-container__open'
    }

    return cls
  }, [isOpen])

  const getAddress = (address) => `${(address || '').substr(0, 6)}...${(address || '').substr(-5)}`

  const renderContent = useCallback(() => {
    if (!Array.isArray(items) || items.length === 0) {
      return (
        <div className="dropdown-container__no-result">
          <span>No result.</span>
        </div>
      )
    }

    return (
      <div className="dropdown-container-list">
        {items.map((item, key) => (
          <div
            onClick={() => onClick(item)}
            key={key}
            className="dropdown-container-list__item"
          >
            <div className="left-block">
              <img className="token-icon" src={item.icon} alt="selected-token-icon" />
              <Text text={item.symbol || item.contract_ticker_symbol} />
            </div>

            <div className="right-block">
              <span className="address">
                <Text text={getAddress(item.contract_address)} className="text--12" />
              </span>
              <span
                className="check-icon"
                onClick={() => window.open(`https://${getLinkBasedOnCurrentNetwork}/address/${item.contract_address}`, '_blank')}
              >
                <Icon
                  mod="icon--14"
                  name={IconOpenInNew}
                  id="icon--open-in-new"
                />
              </span>
            </div>
          </div>
        ))}
      </div>
    )
  }, [items, onClick])

  return (
    <div className={classNameListContainer}>
      {isOpen && renderContent()}
    </div>
  )
}

export const ITEMS_PROPTYPES = PropTypes.shape({
  contract: PropTypes.string,
  contract_address: PropTypes.string,
  symbol: PropTypes.string,
  logo_url: PropTypes.string,
})

DropDown.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(ITEMS_PROPTYPES),
}

DropDown.defaultProps = {
  isOpen: false,
  items: [],
}
