import React, {
  useCallback,
  useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from './img/background.svg'
import { BaseModal } from '../../../components/BaseModal/BaseModal'
import { BaseTextHeading } from '../../../components/BaseText/BaseText'
import { Icon } from '../../../components/Icon/Icon'
import IconExitToApp from '../../../components/Icon/icon--exit-to-app.svg'
import { BaseButton } from '../../../components/BaseButton/BaseButton'
import { CommonButton } from '../../../components/CommonButton/CommonButton'
import IconMetamask from '../../../components/Icon/icon--metamask.png'
import IconWalletConnect from '../../../components/Icon/icon--wallet-connect.png'
import IconGoogle from '../../../components/Icon/icon--google.png'
import IconTwitter from '../../../components/Icon/icon--twitter.png'

import './sign-in-modal.scss'
import ConnectMetamaskModal from './connect-metamask-modal/ConnectMetamaskModal'
import ConnectWalletConnectModal from './connect-wallet-connect-modal/ConnectWalletConnectModal'
import ConnectGoogleModal from './connect-google-modal/ConnectGoogleModal'
import { CONST_TYPE_CONNECT } from '../../../const/web3'

const SignInModal = ({
  onUserSuccessfullyConected,
}) => {
  const [chosenType, setChosenTypeState] = useState()
  const [connectModal, setConnectModal] = useState()

  const chooseType = useCallback((type) => {
    if (!chosenType || (chosenType && chosenType !== type)) {
      setChosenTypeState(type)
    } else {
      setChosenTypeState()
    }
  }, [chosenType])

  const getModalBody = useMemo(() => (
    <>
      <div className="sign-in-modal__icon-block">
        <Icon
          name={IconExitToApp}
          id="icon--exit-to-app"
        />
      </div>

      <BaseTextHeading size="S">
        Select an option to Sign in
      </BaseTextHeading>

      <div className="sign-in-modal__controls-block">
        <CommonButton
          text="Metamask"
          iconLeft={<img src={IconMetamask} alt="metamask" />}
          active={chosenType === CONST_TYPE_CONNECT.metamask}
          onClick={() => chooseType(CONST_TYPE_CONNECT.metamask)}
          dataTestid="login-metamask-button"
        />
        <div className="other-methods-block">
          <CommonButton
            text="WalletConnect"
            iconLeft={<img src={IconWalletConnect} alt="wallet-connect" />}
            active={chosenType === CONST_TYPE_CONNECT.walletConnect}
            onClick={() => chooseType(CONST_TYPE_CONNECT.walletConnect)}
          />
          <CommonButton
            text="Google"
            iconLeft={<img src={IconGoogle} alt="google" />}
            active={chosenType === CONST_TYPE_CONNECT.google}
            onClick={() => chooseType(CONST_TYPE_CONNECT.google)}
          />
          <CommonButton
            text="Twitter"
            iconLeft={<img src={IconTwitter} alt="twitter" />}
            disabled
            active={chosenType === CONST_TYPE_CONNECT.twitter}
            onClick={() => chooseType(CONST_TYPE_CONNECT.twitter)}
          />
        </div>
      </div>
    </>
  ), [chosenType, chooseType])

  const getModalFooter = useMemo(() => (
    <div className="sign-in-modal__footer">
      <div className="sign-in-modal__footer__btn">
        <BaseButton
          disabled={!chosenType}
          onClick={() => setConnectModal(chosenType)}
          dataTestid="login-continue-button"
        >
          Continue
        </BaseButton>
      </div>
    </div>
  ), [chosenType])

  const getSelectModal = useMemo(() => {
    if (connectModal) {
      switch (connectModal) {
        case CONST_TYPE_CONNECT.metamask:
          return <ConnectMetamaskModal onUserSuccessfullyConected={onUserSuccessfullyConected} />
        case CONST_TYPE_CONNECT.walletConnect:
          return <ConnectWalletConnectModal onUserSuccessfullyConected={onUserSuccessfullyConected} />
        case CONST_TYPE_CONNECT.google:
          return <ConnectGoogleModal onUserSuccessfullyConected={onUserSuccessfullyConected} />
        default:
          return <ConnectMetamaskModal onUserSuccessfullyConected={onUserSuccessfullyConected} />
      }
    }

    return (
      <BaseModal
        title="Sign in"
        footer={getModalFooter}
      >
        {getModalBody}
      </BaseModal>
    )
  }, [connectModal, getModalBody, getModalFooter, onUserSuccessfullyConected])

  return (
    <div data-testid="sign-in-modal" className="sign-in-modal__container">
      {connectModal !== CONST_TYPE_CONNECT.google && (<img src={BackgroundImage} alt="" className="sign-in-modal__container__backbround-image" />)}
      {getSelectModal}
    </div>
  )
}

SignInModal.propTypes = {
  onUserSuccessfullyConected: PropTypes.func,
}

export default SignInModal
