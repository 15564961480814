import React, { useCallback, useMemo } from 'react'
import './pagination.scss'
import chunk from 'lodash/chunk'
import indexOf from 'lodash/indexOf'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import IconLeft from '../Icon/icon--p-arrow-left.svg'
import IconRight from '../Icon/icon--p-arrow-right.svg'
import { BaseTextBody } from '../BaseText/BaseText'
import { getNumberOfPages } from '../../utils/helperFunctions'

export const Pagination = ({
  className,
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
}) => {
  const numPages = useMemo(() => getNumberOfPages(rowCount, rowsPerPage?.value ? rowsPerPage.value : rowsPerPage), [rowCount, rowsPerPage])
  const listPage = useMemo(() => Array.from(Array(numPages), (x, i) => i + 1), [numPages])
  const chunkListPage = useMemo(() => chunk(listPage, 5), [listPage])
  const newCurrentPage = useMemo(() => (Number.isNaN(currentPage) ? 1 : currentPage), [currentPage])
  const disabledNext = useMemo(() => currentPage >= numPages, [currentPage, numPages])
  const disabledPrev = useMemo(() => currentPage <= 1, [currentPage])
  const clsNext = useMemo(() => {
    let cls = 'pagination__item'

    if (disabledNext) {
      cls += ' pagination__item--disabled'
    }

    return cls
  }, [disabledNext])
  const clsPrev = useMemo(() => {
    let cls = 'pagination__item'

    if (disabledPrev) {
      cls += ' pagination__item--disabled'
    }

    return cls
  }, [disabledPrev])

  const clsPaginationItem = useCallback((page) => {
    let cls = 'pagination__item'

    if (newCurrentPage === page) {
      cls += ' pagination__item--active'
    }

    return cls
  }, [newCurrentPage])

  const standardRenderNumPages = useCallback(() => listPage.map((item, key) => (
    <div key={key} onClick={() => onChangePage(item)} className={clsPaginationItem(item)}>
      <BaseTextBody>{item}</BaseTextBody>
    </div>
  )), [listPage, clsPaginationItem, onChangePage])
  const ellipsisLeftRenderNumPages = useCallback(() => {
    const needNewArr = indexOf(chunkListPage[chunkListPage.length - 2], newCurrentPage) >= 0
      || chunkListPage[chunkListPage.length - 1].length < 5
    let renderArray = chunkListPage[chunkListPage.length - 1]

    // Если в последнем чанке недостаточно элементов
    if (needNewArr) {
      const lengthLastChunk = chunkListPage[chunkListPage.length - 1].length
      let newArray = chunkListPage[chunkListPage.length - 2].slice((lengthLastChunk))

      newArray = newArray.concat(chunkListPage[chunkListPage.length - 1])
      renderArray = newArray
    }

    return (
      <>
        <div onClick={() => onChangePage(1)} className={clsPaginationItem(1)}>
          <BaseTextBody>1</BaseTextBody>
        </div>
        <div className="pagination__item">
          <BaseTextBody>...</BaseTextBody>
        </div>
        {renderArray.map((item, key) => (
          <div key={key} onClick={() => onChangePage(item)} className={clsPaginationItem(item)}>
            <BaseTextBody>{item}</BaseTextBody>
          </div>
        ))}
      </>
    )
  }, [chunkListPage, clsPaginationItem, newCurrentPage, onChangePage])
  const ellipsisLeftRightRenderNumPages = useCallback(() => chunkListPage.map((chunkItem, indexChunkList) => {
    const render = (first, second, third) => (
      <>
        <div onClick={() => onChangePage(1)} className={clsPaginationItem(1)}>
          <BaseTextBody>{1}</BaseTextBody>
        </div>
        <div className="pagination__item">
          <BaseTextBody>...</BaseTextBody>
        </div>
        <div onClick={() => onChangePage(first)} className={clsPaginationItem(first)}>
          <BaseTextBody>{first}</BaseTextBody>
        </div>
        <div onClick={() => onChangePage(second)} className={clsPaginationItem(second)}>
          <BaseTextBody>{second}</BaseTextBody>
        </div>
        <div onClick={() => onChangePage(third)} className={clsPaginationItem(third)}>
          <BaseTextBody>{third}</BaseTextBody>
        </div>
        <div className="pagination__item">
          <BaseTextBody>...</BaseTextBody>
        </div>
        <div onClick={() => onChangePage(listPage.length)} className={clsPaginationItem(listPage.length)}>
          <BaseTextBody>{listPage.length}</BaseTextBody>
        </div>
      </>
    )

    const indexElement = indexOf(chunkItem, newCurrentPage)

    if (indexElement < 0) {
      return <></>
    }
    // Если первый элемент в чанке
    if (indexElement === 0) {
      const firstNumPage = chunkListPage[indexChunkList - 1][chunkListPage[indexChunkList - 1].length - 1]

      return render(firstNumPage, chunkItem[0], chunkItem[1])
    }
    // Если это не первый и не последний элемент в чанке
    if (indexElement > 0 && indexElement < chunkItem.length - 1) {
      const newChunk = chunkItem.slice(indexElement - 1)

      return render(newChunk[0], newChunk[1], newChunk[2])
    }
    // Если это последний  элемент в чанке
    if (indexElement === chunkItem.length - 1) {
      const lastNumPage = chunkListPage[indexChunkList + 1][0]

      return render(chunkItem[chunkItem.length - 2], chunkItem[chunkItem.length - 1], lastNumPage)
    }

    return <></>
  }), [chunkListPage, clsPaginationItem, newCurrentPage, listPage.length, onChangePage])
  const ellipsisRightRenderNumPages = useCallback(() => chunkListPage.map((chunkItem) => {
    if (indexOf(chunkItem, newCurrentPage) < 0) {
      return <></>
    }

    return chunkItem.map((item, index) => {
      if (index === 4) {
        return (
          <>
            <div onClick={() => onChangePage(item)} className={clsPaginationItem(item)}>
              <BaseTextBody>{item}</BaseTextBody>
            </div>
            <div className="pagination__item">
              <BaseTextBody>...</BaseTextBody>
            </div>
            <div onClick={() => onChangePage(listPage.length)} className={clsPaginationItem(listPage.length)}>
              <BaseTextBody>{listPage.length}</BaseTextBody>
            </div>
          </>
        )
      }

      return (
        <div key={index} onClick={() => onChangePage(item)} className={clsPaginationItem(item)}>
          <BaseTextBody>{item}</BaseTextBody>
        </div>
      )
    })
  }), [chunkListPage, clsPaginationItem, newCurrentPage, listPage.length, onChangePage])

  const renderNumPages = useMemo(() => {
    // Если массив состоит из одного чанка
    if (listPage.length <= 5) {
      return standardRenderNumPages()
    }
    // Если массив состоит из нескольких чанков, но мы находимся в первом
    if (listPage.length > 5 && newCurrentPage < 5) {
      return ellipsisRightRenderNumPages()
    }
    // Если массив состоит из нескольких чанков и мы находимся между первым и последним
    if (listPage.length > 5 && newCurrentPage > 4 && newCurrentPage < listPage.length - 3) {
      return ellipsisLeftRightRenderNumPages()
    }
    // Если мы находимся в последнем чанке
    if (listPage.length === newCurrentPage || newCurrentPage >= listPage.length - 4) {
      return ellipsisLeftRenderNumPages()
    }

    return <></>
  }, [
    newCurrentPage,
    ellipsisLeftRenderNumPages,
    ellipsisLeftRightRenderNumPages,
    ellipsisRightRenderNumPages,
    listPage.length,
    standardRenderNumPages,
  ])

  return (
    <div className={`pagination ${className || ''}`}>
      <div
        onClick={() => {
          if (!disabledPrev) {
            onChangePage(newCurrentPage - 1)
          }
        }}
        className={clsPrev}
      >
        <Icon
          name={IconLeft}
          id="icon--p-arrow-left"
        />
      </div>

      {renderNumPages}

      <div
        onClick={() => {
          if (!disabledNext) {
            onChangePage(newCurrentPage + 1)
          }
        }}
        className={clsNext}
      >
        <Icon
          name={IconRight}
          id="icon--p-arrow-right"
        />
      </div>
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  rowsPerPage: PropTypes.number,
  rowCount: PropTypes.number,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func,
}
