import React, {
  useState,
  useMemo,
  useCallback,
} from 'react'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../../../../../../../components/Loading/Loading'
import IconUnfold from '../../../../../../../../components/Icon/icon--unfold.svg'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import { ExternalLink } from '../../../../../../../../components/ExternalLink/ExternalLink'
import { lpTokensFilter } from '../../../../../../../../service/api'
import { parsePairsList } from '../../../../../../../../parser/api'
import ClickOutside from '../../../../../../../../components/ClickOutside/ClickOutside'
import iconBaseSearch from '../../../../../../../../components/Icon/icon--base-search.svg'

import ImgPairBlock from '../../../../../../../../components/ImgPairBlock/ImgPairBlock'

import './select-pair.scss'
import { getPairForAndSearch } from '../../../../../../../../utils/helperFunctions'

const SelectPair = ({
  tokensPair,
  onSelectPair,
}) => {
  const [isPairsListShown, setParisListsShownState] = useState(false)
  const [pairs, setPairs] = useState([])
  const [isThereMorePairs, setThereIsMorePairsState] = useState(false)
  const [searchPairValue, setSearchPairValue] = useState('')
  const [currentPairsPage, setCurrentPairsPage] = useState(1)
  const [arePairsLoading, setPairsAreLoadingState] = useState(true)

  const getImgPairBlock = useMemo(() => (
    <ImgPairBlock
      firstTokenIcon={tokensPair.token1.icon}
      secondTokenIcon={tokensPair.token2.icon}
    />
  ), [tokensPair.token1.icon, tokensPair.token2.icon])

  const getRightControl = useMemo(() => (
    <div className="select-pair__header-control">
      <Icon name={IconUnfold} id="icon--unfold" />
    </div>
  ), [])

  const closePairsList = useCallback(() => {
    setParisListsShownState(false)
    setPairs([])
    setPairsAreLoadingState(true)
    setThereIsMorePairsState(false)
    setSearchPairValue('')
    setCurrentPairsPage(1)
  }, [])

  const getPairItem = useCallback((item) => (
    <div
      className="pair-item-container"
      onClick={() => {
        onSelectPair({
          token1: item.token1,
          token2: item.token2,
        })
        closePairsList()
      }}
    >
      <div className="pair-item-left-block">
        <div className="pair-item-image-block">
          <ImgPairBlock
            firstTokenIcon={item.token1.icon}
            secondTokenIcon={item.token2.icon}
          />
        </div>

        <div className="pair-item-title">
          {`${item.token1.symbol} / ${item.token2.symbol}`}
        </div>
      </div>

      <div className="pair-item-right-block">
        <ExternalLink link={item.link} text={item.address} />
      </div>
    </div>
  ), [onSelectPair, closePairsList])

  const loadNextPairsInTheList = useCallback(async () => {
    try {
      const incrementedPage = currentPairsPage + 1
      const { filterValue, pairFor } = getPairForAndSearch(searchPairValue)
      const newLoadedPairs = await lpTokensFilter({
        filterValue,
        pairFor,
        page: incrementedPage,
      })
      setPairs(pairs.concat(parsePairsList(newLoadedPairs)))
      setCurrentPairsPage(incrementedPage)
      setThereIsMorePairsState(!!newLoadedPairs.data.next)
    } catch (e) {
      setCurrentPairsPage(currentPairsPage)
      setThereIsMorePairsState(false)
    }
  }, [currentPairsPage, pairs, searchPairValue])

  const getLoader = useMemo(() => (
    <div className="loading-block">
      <Loading />
    </div>
  ), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadNewPairsBasedOnSearchValue = useCallback(debounce(async (searchValue) => {
    setCurrentPairsPage(1)
    try {
      const { filterValue, pairFor } = getPairForAndSearch(searchValue)
      const loadedPairs = await lpTokensFilter({
        filterValue,
        pairFor,
        page: currentPairsPage,
      })
      setPairs(parsePairsList(loadedPairs))
      setPairsAreLoadingState(false)
    } catch (e) {
      setPairs([])
      setPairsAreLoadingState(false)
    }
  }, 1000), [])

  const getSearch = useMemo(() => (
    <div className="pairs-search-block">
      <Icon name={iconBaseSearch} id="icon--base-search" />
      <input
        onChange={(e) => {
          setSearchPairValue(e.target.value)
          setPairsAreLoadingState(true)
          onLoadNewPairsBasedOnSearchValue(e.target.value)
        }}
        value={searchPairValue}
        type="text"
        placeholder="Search for asset..."
      />
    </div>
  ), [searchPairValue, onLoadNewPairsBasedOnSearchValue])

  const getPairsList = useMemo(() => (
    <div data-testid="trade--chart-drop-down" className="pairs-list-container">
      {((arePairsLoading && searchPairValue.length > 0) || !arePairsLoading) && getSearch}

      {!arePairsLoading && pairs.length > 0 && (
        <InfiniteScroll
          dataLength={pairs.length}
          next={loadNextPairsInTheList}
          hasMore={isThereMorePairs}
          height={pairs.length >= 10 ? 280 : pairs.length * 42}
          loader={getLoader}
        >
          {pairs.map((item, key) => (
            <div key={key} className="item-block">
              {getPairItem(item)}
            </div>
          ))}
        </InfiniteScroll>
      )}

      {!arePairsLoading && pairs.length === 0 && (
        <div className="no-result-block">
          No results found
        </div>
      )}

      {arePairsLoading && getLoader}
    </div>
  ), [arePairsLoading, pairs, loadNextPairsInTheList, isThereMorePairs, getPairItem, getLoader, getSearch, searchPairValue])

  const openPairsList = useCallback(async () => {
    setParisListsShownState(true)
    try {
      const loadedPairs = await lpTokensFilter({ filterValue: '', page: 1 })
      setPairs(parsePairsList(loadedPairs))
      setThereIsMorePairsState(!!loadedPairs.data.next)
      setPairsAreLoadingState(false)
    } catch (e) {
      setPairs([])
      setThereIsMorePairsState(false)
      setPairsAreLoadingState(false)
    }
  }, [])

  return (
    <ClickOutside callback={closePairsList}>
      <div data-testid="trade--chart-select-pair" className="select-pair-container">
        <div className="select-pair__body">
          <div
            className="select-pair__header"
            onClick={() => {
              if (!isPairsListShown) {
                openPairsList()
              } else {
                closePairsList()
              }
            }}
          >
            <div className="select-pair__header-img">
              {getImgPairBlock}
            </div>

            <div className="select-pair__header-input">
              {`${tokensPair.token1.symbol} / ${tokensPair.token2.symbol}`}
            </div>

            {getRightControl}
          </div>

          {isPairsListShown && getPairsList}
        </div>
      </div>
    </ClickOutside>
  )
}

SelectPair.propTypes = {
  tokensPair: PropTypes.object,
  onSelectPair: PropTypes.func,
}

export default SelectPair
