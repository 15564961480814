import React, {
  useCallback, useMemo, useState, useEffect, useRef,
} from 'react'
import CancelIcon from '@material-ui/icons/Cancel'
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import ErrorIcon from '@material-ui/icons/Error'
import PropTypes from 'prop-types'
import { BaseDropDown, ITEMS_PROPTYPES } from './BaseDropDown'
import { BlockImgPair } from '../BlockImgPair/BlockImgPair'
import ClickOutside from '../ClickOutside/ClickOutside'

import './select-currency-chart.scss'
import { DropDownInput } from './DropDownInput'
import { BaseTextBody } from '../BaseText/BaseText'
import { getImage } from '../../utils/images'

export const SelectCurrencyChart = ({
  infinityScrollHasMore,
  funcInfinityScroll,
  placeholder,
  isExchangeItem,
  externalValue,
  oneImg,
  selectedValue,
  firstTokenAddress = '0x63b4f3e3fa4e438698CE330e365E831F7cCD1eF4',
  secondTokenAddress = '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  callback = () => {},
  onChange = () => {},
  isLoading,
  funcLoadItems = () => {},
  items = [],
  errorIcon,
  warningIcon,
  onBlurInput,
}) => {
  const [selectInput, setSelectInput] = useState(false)
  const [focusInput, setFocusInput] = useState(false)
  const [firstTokenImg, setFirstTokenImg] = useState(null)
  const [secondTokenImg, setSecondTokenImg] = useState(null)
  const [sizeInput, setSizeInput] = useState(5)
  const oldSelectedValue = useRef({})

  useEffect(() => {
    const isMac = typeof navigator !== 'undefined' ? /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) : false

    if (isMac) {
      setSizeInput(selectedValue.length < 5 ? 8 : selectedValue.length + 3)
    } else {
      setSizeInput(selectedValue.length < 5 ? 5 : selectedValue.length)
    }
  }, [setSizeInput, selectedValue])

  useEffect(() => {
    let isMounted = true
    const asyncSetSrc = async () => {
      const srcFirstToken = await getImage(firstTokenAddress)
      const srcSecondToken = await getImage(secondTokenAddress)

      if (isMounted) {
        setFirstTokenImg(srcFirstToken.src)
        setSecondTokenImg(srcSecondToken.src)
      }
    }

    asyncSetSrc()
    return () => { isMounted = false }
  }, [firstTokenAddress, secondTokenAddress])

  const rightIcon = useMemo(() => {
    if (selectedValue && selectInput && !isExchangeItem) {
      return (
        <CancelIcon
          onClick={() => {
            onChange('')
          }}
          style={{ color: '#FFFFFF1E', fontSize: '20px' }}
          fontSize="small"
        />
      )
    }
    if (selectInput) {
      return <UnfoldMoreRoundedIcon style={{ color: '#FFFFFFF5', fontSize: '20px' }} fontSize="small" />
    }

    return (
      <UnfoldMoreRoundedIcon
        style={{ color: '#FFFFFF7A', fontSize: '20px' }}
        fontSize="small"
      />
    )
  }, [selectedValue, selectInput, isExchangeItem, onChange])
  const classNameInput = useMemo(() => {
    let cls = 'select-currency-chart__input-inner'

    if (focusInput) {
      cls += ' select-currency-chart__input-inner__large '
    }
    if (!focusInput) {
      cls += ' select-currency-chart__input-inner__small'
    }

    return cls
  }, [focusInput])

  const classNameHeader = useMemo(() => {
    let cls = 'select-currency-chart__header'

    if (focusInput) {
      cls += ' select-currency-chart__header__selected'
    }

    return cls
  }, [focusInput])

  const handleSelected = useCallback((select) => {
    oldSelectedValue.current = {
      select,
      selectedValue,
    }

    onChange(`${select?.token0?.symbol} / ${select?.token1?.symbol}`)
    setFocusInput(false)
    setSelectInput(false)

    callback(select, selectedValue)
  }, [callback, onChange, selectedValue])
  const closeDropDown = useCallback(() => {
    setSelectInput(false)
    setFocusInput(false)

    if ((!Array.isArray(items) || (Array.isArray(items) && items.length <= 1)) && oldSelectedValue.current.select) {
      handleSelected(oldSelectedValue.current.select)
    }
  }, [handleSelected, items])

  const handleShowDropDown = useCallback((isInput) => {
    if (!isInput) {
      setSelectInput(!selectInput)
      setFocusInput(!focusInput)
    }
    if (isInput) {
      setSelectInput(true)
      setFocusInput(true)
    }
    if (focusInput === false && Array.isArray(items) && items.length <= 1 && oldSelectedValue.current.select) {
      handleSelected(oldSelectedValue.current.select)
    }
  }, [items, selectInput, focusInput, handleSelected])

  const renderImg = useMemo(() => {
    if (errorIcon) {
      return <ErrorIcon className="header-img_error" />
    }
    if (warningIcon) {
      return <WarningRoundedIcon className="header-img_warning" />
    }
    if (isExchangeItem) {
      return <BlockImgPair loadingIconFirst={!firstTokenImg} firstImg={firstTokenImg} />
    }

    return (
      <BlockImgPair
        loadingIconSecond={!secondTokenImg}
        loadingIconFirst={!firstTokenImg}
        firstImg={firstTokenImg}
        secondImg={secondTokenImg}
      />
    )
  }, [firstTokenImg, isExchangeItem, secondTokenImg, errorIcon, warningIcon])

  const renderInput = useMemo(() => {
    if (isExchangeItem) {
      return <BaseTextBody onClick={() => handleShowDropDown(false)}>{selectedValue}</BaseTextBody>
    }

    return (
      <input
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={selectedValue}
        onClick={() => handleShowDropDown(true)}
        className="select-currency-chart__input"
        type="text"
        placeholder={focusInput ? selectedValue : placeholder}
        size={sizeInput}
      />
    )
  }, [focusInput, handleShowDropDown, isExchangeItem, onChange, placeholder, selectedValue, sizeInput])

  const renderDropDown = useMemo(() => {
    if (isExchangeItem && focusInput) {
      return (
        <DropDownInput
          infinityScrollHasMore={infinityScrollHasMore}
          funcInfinityScroll={funcInfinityScroll}
          inputValue={externalValue}
          oneImg={oneImg}
          isLoading={isLoading}
          onClick={handleSelected}
          items={items}
          isOpen={focusInput}
          onChange={(e) => {
            onChange(e)
          }}
        />
      )
    }
    return focusInput
      ? (
        <BaseDropDown
          infinityScrollHasMore={infinityScrollHasMore}
          funcInfinityScroll={funcInfinityScroll}
          isLoading={isLoading}
          onClick={handleSelected}
          items={items}
          isOpen={focusInput}
        />
      )
      : <></>
  }, [
    isExchangeItem,
    isLoading,
    handleSelected,
    items,
    focusInput,
    infinityScrollHasMore,
    funcInfinityScroll,
    externalValue,
    oneImg,
    onChange,
  ])

  useEffect(() => {
    (async () => {
      if (focusInput && funcLoadItems) {
        funcLoadItems()
      }
    })()
  }, [focusInput, funcLoadItems])

  useEffect(() => {
    if (!focusInput) {
      onBlurInput()
    }
  }, [onBlurInput, focusInput])

  return (
    <ClickOutside callback={closeDropDown}>
      <div className="select-currency-chart">
        <div className="select-currency-chart__body">
          <div className={classNameHeader}>
            <div className="select-currency-chart__header-img">
              {renderImg}
            </div>

            <div className={classNameInput}>
              {renderInput}
            </div>

            <div onClick={() => handleShowDropDown(false)} className="select-currency-chart__header-clear">
              {rightIcon}
            </div>
          </div>

          {renderDropDown}
        </div>
      </div>
    </ClickOutside>
  )
}

SelectCurrencyChart.propTypes = {
  isExchangeItem: PropTypes.bool,
  oneImg: PropTypes.bool,
  infinityScrollHasMore: PropTypes.bool,
  firstTokenAddress: PropTypes.string,
  placeholder: PropTypes.string,
  secondTokenAddress: PropTypes.string,
  selectedValue: PropTypes.string,
  externalValue: PropTypes.string,
  isLoading: PropTypes.bool,
  callback: PropTypes.func,
  funcInfinityScroll: PropTypes.func,
  onChange: PropTypes.func,
  funcLoadItems: PropTypes.func,
  items: PropTypes.arrayOf(ITEMS_PROPTYPES),
  errorIcon: PropTypes.bool,
  warningIcon: PropTypes.bool,
  onBlurInput: PropTypes.func,
}
SelectCurrencyChart.defaultProps = {
  placeholder: 'CFI / ETH',
  onBlurInput: () => {},
}
