import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BaseTabsHeader } from '../BaseTabsHeader/BaseTabsHeader'
import { Icon } from '../Icon/Icon'
import IconInbox from '../Icon/icon--inbox.svg'
import { BaseTextBody } from '../BaseText/BaseText'
import Loading from '../Loading/Loading'

import './base-tabs.scss'

export function BaseTabs({
  className,
  tabs,
  onChange,
  withAdditionalBorder,
  selectedTabIndex,
}) {
  const [activeIndex, setActiveIndex] = useState(0)
  const isEmpty = !tabs[activeIndex].renderContent()

  useEffect(() => {
    if (selectedTabIndex >= 0) {
      setActiveIndex(selectedTabIndex)
    }
  }, [selectedTabIndex])

  const handleTabClick = (index, tab) => {
    if (index !== activeIndex) {
      setActiveIndex(index)

      if (typeof onChange === 'function') {
        onChange(tab.text)
      }
    }
  }

  const getContent = useMemo(() => {
    if (!tabs[activeIndex].isLoading) {
      return !isEmpty ? tabs[activeIndex].renderContent() : (
        <div className="base-tabs__content--no-data">
          <Icon name={IconInbox} id="icon--inbox" />

          <BaseTextBody
            size="S"
          >
            List is empty
          </BaseTextBody>
        </div>
      )
    }
    return <Loading />
  }, [tabs, activeIndex, isEmpty])

  return (
    <div className={`base-tabs ${className || ''}`}>
      <div className="base-tabs__header">
        <BaseTabsHeader
          activeIndex={activeIndex}
          tabs={tabs}
          onTabClick={handleTabClick}
          withAdditionalBorder={withAdditionalBorder}
        />
      </div>

      <div className={`base-tabs__content ${isEmpty || tabs[activeIndex].isLoading ? 'no-data' : ''}`} data-testid="wip-balance-block-tabs-content">
        {getContent}
      </div>
    </div>
  )
}

BaseTabs.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    renderContent: PropTypes.func,
    text: PropTypes.string,
    isLoading: PropTypes.bool,
    testId: PropTypes.string,
  })),
  withAdditionalBorder: PropTypes.bool,
  selectedTabIndex: PropTypes.number,
}
