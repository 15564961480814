import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../Loading/Loading'
import { ITEM_DROP_DOWN_TOKEN, ItemDropDown } from '../ItemDropDown/ItemDropDown'

import './drop-down-input.scss'
import { SearchBase } from '../SearchBase/SearchBase'
import { ZERO_ADDRESS } from '../../const'

export const DropDownInput = ({
  infinityScrollHasMore,
  oneImg,
  items,
  isOpen,
  isLoading,
  inputValue,
  funcInfinityScroll,
  onClick = () => {},
  onChange = () => {},
}) => {
  const classNameListContainer = useMemo(() => {
    let cls = 'BaseDropDown-Container'

    if (isOpen) {
      cls += ' BaseDropDown-Container__open'
    }

    return cls
  }, [isOpen])

  const renderContent = useCallback(() => {
    if (isLoading) {
      return (
        <div className="BaseDropDown-Container__noResult">
          <Loading />
        </div>
      )
    }
    if (!isLoading && (!Array.isArray(items) || items.length === 0)) {
      return (
        <div className="BaseDropDown-Container__noResult">
          <span>No result.</span>
        </div>
      )
    }

    return (
      <div className="BaseDropDown-Container-List">
        <div className="BaseDropDown-Container-List-Title">
          popular
        </div>

        <InfiniteScroll
          dataLength={items.length}
          next={funcInfinityScroll}
          hasMore={infinityScrollHasMore}
          height={200}
          loader={<div className="BaseDropDown-Container-List__loading"><Loading /></div>}
        >
          {items.map((item, key) => (
            <div
              onClick={() => onClick(item)}
              key={key}
              className="BaseDropDown-Container-List__item"
            >
              <ItemDropDown
                oneImg={oneImg}
                link={item.link}
                address={item.address}
                token0={{
                  address: item.address,
                  symbol: item.symbol,
                }}
                symbol={item.symbol}
                hideAddress={item.link.indexOf(ZERO_ADDRESS) !== -1}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    )
  }, [isLoading, items, funcInfinityScroll, infinityScrollHasMore, oneImg, onClick])

  return (
    <div className={classNameListContainer}>
      <SearchBase
        value={inputValue}
        onChange={onChange}
      />

      {renderContent()}
    </div>
  )
}

export const ITEMS_PROPTYPES = PropTypes.shape({
  address: PropTypes.string, // Address пары
  link: PropTypes.string,
  token0: ITEM_DROP_DOWN_TOKEN,
  token1: ITEM_DROP_DOWN_TOKEN,
})

DropDownInput.propTypes = {
  isOpen: PropTypes.bool,
  infinityScrollHasMore: PropTypes.bool,
  oneImg: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  funcInfinityScroll: PropTypes.func,
  onChange: PropTypes.func,
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  items: PropTypes.arrayOf(ITEMS_PROPTYPES),
}

DropDownInput.defaultProps = {
  isOpen: false,
  isLoading: false,
  infinityScrollHasMore: false,
  items: [],
}
