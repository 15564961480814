import React, {
  useEffect, useState, useRef, useCallback, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { createChart } from 'lightweight-charts'
import Loading from '../../../../../../../components/Loading/Loading'
import {
  getCoinHistoryCharDataForLine,
  getCoinIdBySymbolNetworkAndAddress,
} from '../../../../../../../service/chart'
import { getNativeCoinIdBasedOnNetwork } from '../../../../../../../parser/chart'
import { BaseTextHeading, BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { Chip } from '../../../../../../../components/Chip/Chip'
import { NEW_ETH_ADDRESS } from '../../../../../../../const'

import './chart.scss'

const RANGES = {
  '1D': '1',
  '1W': '7',
  '1M': '30',
  '1Y': '365',
  MAX: 'max',
}

const Chart = ({ selectedTokensPair }) => {
  const chart = useRef()
  const chartContainer = useRef()
  const lineSeries = useRef()
  const resizeObserver = useRef()

  const [chartSymbol, setChartSymbol] = useState()
  const [isDataLoaded, setDataIsLoaded] = useState(false)
  const [isChartExist, setChartIsExist] = useState(true)

  const [selectedRange, setSelectedRange] = useState('max')
  const [cachedRange, setCachedRange] = useState('max')

  const getDataFromCoinGecko = useCallback(async () => {
    const { symbol, address } = selectedTokensPair.token1

    let coinId
    if (address !== NEW_ETH_ADDRESS) {
      coinId = await getCoinIdBySymbolNetworkAndAddress({ symbol, address })
    } else {
      coinId = getNativeCoinIdBasedOnNetwork()
    }

    if (coinId) {
      return {
        isError: false,
        bars: await getCoinHistoryCharDataForLine({ coinId, days: selectedRange }),
      }
    }

    return {
      isError: true,
      bars: [],
    }
  }, [selectedTokensPair.token1, selectedRange])

  useEffect(() => (async () => {
    const newSymbol = `${selectedTokensPair.token1.symbol}/USD`
    if (chartSymbol !== newSymbol || selectedRange !== cachedRange) {
      setChartSymbol(newSymbol)
      setDataIsLoaded(false)
      setCachedRange(selectedRange)

      try {
        const coinGeckoData = await getDataFromCoinGecko()
        lineSeries.current.setData(coinGeckoData.bars)
        chart.current.applyOptions({
          timeScale: {
            timeVisible: selectedRange === '1',
          },
        })
        chart.current.timeScale().fitContent()
        setDataIsLoaded(!coinGeckoData.isError)
        setChartIsExist(!coinGeckoData.isError)
      } catch (error) {
        console.log('Couldnt load the data = ', error)
        setDataIsLoaded(false)
        setChartIsExist(false)
      }
    }
  })(), [selectedTokensPair, chartSymbol, getDataFromCoinGecko, selectedRange, cachedRange])

  const getChartOptions = useCallback(() => ({
    width: chartContainer.current.clientWidth,
    height: chartContainer.current.clientHeight,
    layout: {
      backgroundColor: '#0d0d0d',
      textColor: '#adb2bb',
    },
    grid: {
      vertLines: {
        color: '#2e323d',
      },
      horzLines: {
        color: '#2e323d',
      },
    },
    priceScale: {
      borderColor: '#464b55',
    },
    timeScale: {
      borderColor: '#464b55',
    },
  }), [])

  useEffect(() => {
    chart.current = createChart(chartContainer.current, getChartOptions())
    lineSeries.current = chart.current.addAreaSeries({
      topColor: 'rgba(234, 223, 20, 0.56)',
      bottomColor: 'rgba(234, 223, 20, 0.04)',
      lineColor: 'rgba(234, 223, 20, 1)',
      lineWidth: 2,
    })
  }, [getChartOptions])

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (!chart.current) return
      chart.current.remove()
      chart.current = undefined
    }
  }, [])

  useEffect(() => {
    resizeObserver.current = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect
      chart.current.applyOptions({ width, height })
      setTimeout(() => {
        if (chart.current) {
          chart.current.timeScale().fitContent()
        }
      })
    })

    resizeObserver.current.observe(chartContainer.current)

    return () => resizeObserver.current.disconnect()
  }, [])

  const getRangeControllerTemplate = useCallback(({
    index,
    callback,
    value,
    isSelected,
  }) => (
    <div className="range-control" key={index}>
      <Chip onClick={() => callback()} isSelected={isSelected}>
        {value}
      </Chip>
    </div>
  ), [])

  const getRangeControlsTemplate = useMemo(() => (
    <div className="ranges-block">
      {
        Object.keys(RANGES).map((range, index) => {
          const currentRange = RANGES[range]
          return getRangeControllerTemplate({
            index,
            callback: () => {
              setSelectedRange(currentRange)
            },
            value: range,
            isSelected: selectedRange === currentRange,
          })
        })
      }
    </div>
  ), [getRangeControllerTemplate, selectedRange])

  return (
    <div className="chart-container">
      {getRangeControlsTemplate}

      <div className="title-pair-block">
        <BaseTextBody secondary>
          {`${selectedTokensPair.token1.name} / USD`}
        </BaseTextBody>
      </div>

      <div className="chart-block" ref={chartContainer} />

      {!isDataLoaded && (
        <div className="chart-container__loading-overlay-block">
          <Loading />
        </div>
      )}

      {!isDataLoaded && !isChartExist && (
        <div className="chart-container__loading-overlay-block">
          <BaseTextHeading size="S" isSecondary>A chart for such a pair does not exist...</BaseTextHeading>
        </div>
      )}
    </div>
  )
}

Chart.propTypes = {
  selectedTokensPair: PropTypes.object,
}

export default Chart
