import React from 'react'
import PropTypes from 'prop-types'
import { BaseTab } from '../BaseTab/BaseTab'

import './base-tabs-header.scss'

export const BaseTabsHeader = ({
  tabs,
  activeIndex,
  onTabClick,
  withAdditionalBorder,
}) => {
  function handleClick(index, tab) {
    if (!tab.disabled) {
      onTabClick(index, tab)
    }
  }

  return (
    <div className={`base-tabs-header ${withAdditionalBorder ? 'with-additional-border' : ''}`}>
      {tabs.map((tab, index) => (
        <BaseTab
          key={index}
          text={tab.text}
          onClick={() => handleClick(index, tab)}
          active={index === activeIndex}
          disabled={tab.disabled}
          withAdditionalBorder={withAdditionalBorder}
          dataTestid={tab.testId}
        />
      ))}
    </div>
  )
}

BaseTabsHeader.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    testId: PropTypes.string,
  })),
  activeIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onTabClick: PropTypes.func,
  withAdditionalBorder: PropTypes.bool,
}
