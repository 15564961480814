import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { Text } from '../../../../../../../../components/Text/Text'
import { Btn } from '../../../../../../../../components/Btn/Btn'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconMoreHoriz from '../../../../../../../../components/Icon/icon--more-horiz.svg'
import IconRadioButtonUnchecked from '../../../../../../../../components/Icon/icon--radio-button-unchecked.svg'
import IconRadioButtonChecked from '../../../../../../../../components/Icon/icon--radio-button-checked.svg'
import { Menu } from '../../../../../../../../components/Menu/Menu'
import { MenuBlock } from '../../../../../../../../components/MenuBlock/MenuBlock'
import { MenuItem } from '../../../../../../../../components/MenuItem/MenuItem'

import IconEdit from '../../../../../../../../components/Icon/icon--edit.svg'
import IconArrowRight from '../../../../../../../../components/Icon/icon--keyboard-arrow-right.svg'
import IconEtherscan from '../../../../../../../../components/Icon/icon--etherscan.svg'
import IconCopy from '../../../../../../../../components/Icon/icon--copy.svg'
import IconSend from '../../../../../../../../components/Icon/icon--send.svg'
import IconWallet from '../../../../../../../../components/Icon/icon--wallet.svg'
import IconLinkOff from '../../../../../../../../components/Icon/icon--link-off.svg'
import IconDelete from '../../../../../../../../components/Icon/icon--delete.svg'
import IconOpenInNew from '../../../../../../../../components/Icon/icon--open-in-new.svg'
import IconAllInclusive from '../../../../../../../../components/Icon/icon--all-inclusive.svg'
import Identicon from '../../../../../../../../components/IdentIcon/Identicon'
import { getLinkBasedOnCurrentNetwork, getLinkTitleBasedOnCurrentNetwork } from '../../../../../../../../parser/data'
import { disableGodMode } from '../../../../../../../../service/api'
import { toggleGodModeStatus } from '../../../../../../../../store/slice/user'

import './address-block.scss'

const AddressBlock = ({
  cyberWallet,
  openedCyberWallet,
  activeCyberWallet,
  onOpenSubmenu,
  onSelectAddress,
  onTransfer,
  onDeposit,
  onRename,
  onDelete,
  onExtractPrivateKey,
  onOpenModalGodMode,
}) => {
  const dispatch = useDispatch()
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')

  const [isGodMode, setIsGodMode] = useState(false)
  const [isDisableGodModeRequestPending, setDisableGodModeRequestIsPendingState] = useState(false)

  const onDisableGodMode = useCallback(async (address) => {
    setDisableGodModeRequestIsPendingState(true)
    try {
      await disableGodMode(address)
      onOpenSubmenu({})
      if (internalSelectedAddress === address) {
        dispatch(toggleGodModeStatus(false))
      }
      setIsGodMode(false)
      setDisableGodModeRequestIsPendingState(false)
    } catch (e) {
      setDisableGodModeRequestIsPendingState(false)
    }
  }, [onOpenSubmenu, internalSelectedAddress, dispatch])

  const getMenu = useMemo(() => (
    <div className="submenu-wrapper">
      <Menu menuType="menu--wallet-submenu">
        <MenuBlock
          divider
          title={openedCyberWallet.address}
          items={[
            <MenuItem
              isStopPropagation
              text={`Open ${getLinkTitleBasedOnCurrentNetwork()}`}
              iconLeft={{
                file: IconEtherscan,
                name: 'icon--etherscan',
              }}
              iconRight={{
                file: IconOpenInNew,
                name: 'icon--open-in-new',
              }}
              onClick={() => {
                window.open(`https://${getLinkBasedOnCurrentNetwork()}/address/${openedCyberWallet.address}`, '_blank')
                onOpenSubmenu({})
              }}
            />,
            <MenuItem
              isStopPropagation
              text="Copy Address"
              iconLeft={{
                file: IconCopy,
                name: 'icon--copy',
              }}
              isCopyToClipBoard
              copyToClipBoardText={openedCyberWallet.address}
              onClick={() => onOpenSubmenu({})}
            />,
            <MenuItem
              dataTestid="popup--show-rename-wallet"
              isStopPropagation
              text="Rename Cyber Wallet"
              iconLeft={{
                file: IconEdit,
                name: 'icon--edit',
              }}
              iconRight={{
                file: IconArrowRight,
                name: 'icon--keyboard-arrow-right',
              }}
              onClick={() => {
                onOpenSubmenu({})
                onRename(openedCyberWallet)
              }}
            />,
          ]}
        />

        <MenuBlock
          divider
          items={[
            <MenuItem
              dataTestid="popup--show-transfer"
              isStopPropagation
              text="Transfer"
              iconLeft={{
                file: IconSend,
                name: 'icon--send',
              }}
              iconRight={{
                file: IconArrowRight,
                name: 'icon--keyboard-arrow-right',
              }}
              onClick={() => {
                onOpenSubmenu({})
                onTransfer(openedCyberWallet.address)
              }}
            />,
            <MenuItem
              dataTestid="popup--show-deposit-assets"
              isStopPropagation
              text="Deposit Assets"
              iconLeft={{
                file: IconWallet,
                name: 'icon--wallet',
              }}
              iconRight={{
                file: IconArrowRight,
                name: 'icon--keyboard-arrow-right',
              }}
              onClick={() => {
                onOpenSubmenu({})
                onDeposit(openedCyberWallet.address)
              }}
            />,
            <MenuItem
              dataTestid="popup--show-god-mode"
              isStopPropagation
              text="God Mode"
              iconLeft={{
                file: IconAllInclusive,
                name: 'icon--all-inclusive',
              }}
              onChangeToggle={() => {
                if (!isGodMode) {
                  onOpenSubmenu({})
                  onOpenModalGodMode(openedCyberWallet.address)
                } else {
                  onDisableGodMode(openedCyberWallet.address)
                }
              }}
              isToggle
              isToggleChecked={isGodMode}
              isLoading={isDisableGodModeRequestPending}
            />,
          ]}
        />

        <MenuBlock
          items={[
            <MenuItem
              dataTestid="popup--show-extract-private-key"
              isStopPropagation
              text="Extract Private Key"
              iconLeft={{
                file: IconLinkOff,
                name: 'icon--link-off',
              }}
              iconRight={{
                file: IconArrowRight,
                name: 'icon--keyboard-arrow-right',
              }}
              onClick={() => {
                onOpenSubmenu({})
                onExtractPrivateKey(openedCyberWallet.address)
              }}
            />,
            <MenuItem
              dataTestid="popup--show-delete-wallet"
              isStopPropagation
              text="Delete Cyber Wallet"
              iconLeft={{
                file: IconDelete,
                name: 'icon--delete',
              }}
              onClick={() => {
                onOpenSubmenu({})
                onDelete({
                  name: openedCyberWallet.name,
                  totalBalance: openedCyberWallet.totalBalance,
                  address: openedCyberWallet.address,
                })
              }}
            />,
          ]}
        />
      </Menu>
    </div>
  ), [
    isGodMode,
    onExtractPrivateKey,
    openedCyberWallet,
    onOpenSubmenu,
    onRename,
    onTransfer,
    onDeposit,
    onOpenModalGodMode,
    onDelete,
    isDisableGodModeRequestPending,
    onDisableGodMode,
  ])

  useEffect(() => (
    setIsGodMode(cyberWallet.in_advanced_mode || new Date(cyberWallet.advanced_mode_end_time) > new Date())
  ), [cyberWallet.in_advanced_mode, cyberWallet.advanced_mode_end_time])

  return (
    <div onClick={() => onSelectAddress(cyberWallet)} className="cyber-wallet__address-list__address-block">
      <Identicon string={cyberWallet.name} size="30" />
      <div className="details">
        <BaseTextBody>{cyberWallet.name}</BaseTextBody>
        <Text className="text--12" text={`$${cyberWallet.totalBalance}`} />
      </div>
      <div className="controls">
        {isGodMode && (
          <div className="cyber-wallet__address-block__god-mode">
            <Icon
              mod="icon--14"
              name={IconAllInclusive}
              id="icon--all-inclusive"
            />
          </div>
        )}
        <Btn
          dataTestid="popup--show-more-cyber-wallet"
          mod="btn--icon"
          onClick={() => onOpenSubmenu(openedCyberWallet.name ? {} : cyberWallet)}
          iconLeft={(
            <Icon
              name={IconMoreHoriz}
              id="icon--more-horiz"
              mod="icon--22"
            />
          )}
        />
        {
          activeCyberWallet !== cyberWallet.address ? (
            <Btn
              mod="btn--icon"
              onClick={() => onSelectAddress(cyberWallet)}
              iconLeft={(
                <Icon
                  name={IconRadioButtonUnchecked}
                  id="icon--radio-button-unchecked"
                  mod="icon--22"
                />
              )}
            />
          ) : (
            <Icon
              name={IconRadioButtonChecked}
              id="icon--radio-button-checked"
              mod="icon--22"
            />
          )
        }
      </div>
      {openedCyberWallet.name && openedCyberWallet.name === cyberWallet.name && getMenu}
    </div>
  )
}

AddressBlock.propTypes = {
  cyberWallet: PropTypes.object,
  openedCyberWallet: PropTypes.object,
  activeCyberWallet: PropTypes.string,
  onOpenSubmenu: PropTypes.func,
  onSelectAddress: PropTypes.func,
  onTransfer: PropTypes.func,
  onDeposit: PropTypes.func,
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
  onExtractPrivateKey: PropTypes.func,
  onOpenModalGodMode: PropTypes.func,
}

export default AddressBlock
