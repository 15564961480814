import React from 'react'
import { connect } from 'react-redux'
import { Router } from 'react-router'
import { ToastContainer } from 'react-toastify'
import MainContainer from './pages/main-container/MainContainer'
import Routes from './Routes'
import browserHistory from './history'
import { Web3Provider } from './context/web3Provider'

const App = () => (
  <Router history={browserHistory}>
    <Web3Provider>
      <MainContainer>
        <Routes />
        <ToastContainer />
      </MainContainer>
    </Web3Provider>
  </Router>
)

export default connect(null, null)(App)
