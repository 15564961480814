import React, { useMemo } from 'react'
import './alert-message.scss'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../BaseText/BaseText'

export const AlertMessage = ({
  size,
  children,
  error,
  warning,
  assistive,
  success,
}) => {
  const classNameAlert = useMemo(() => {
    let cls = 'alert-message'

    if (error) {
      cls = `${cls} alert-message--error`
    }
    if (warning) {
      cls = `${cls} alert-message--warning`
    }
    if (assistive) {
      cls = `${cls} alert-message--assistive`
    }
    if (success) {
      cls = `${cls} alert-message--success`
    }

    if (size) {
      cls = `${cls} alert-message--size-${size}`
    }

    return cls
  }, [error, warning, assistive, success, size])

  const message = useMemo(() => {
    let msg

    switch (size) {
      case 's':
        msg = <BaseTextBody size="S">{children}</BaseTextBody>
        break

      case 'm':
        msg = <BaseTextBody>{children}</BaseTextBody>
        break

      default:
        msg = <BaseTextBody>{children}</BaseTextBody>
    }

    return msg
  }, [children, size])

  return (
    <div className={classNameAlert}>
      {message}
    </div>
  )
}
AlertMessage.propTypes = {
  size: PropTypes.oneOf(['s', 'm']),
  children: PropTypes.node,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  assistive: PropTypes.bool,
  success: PropTypes.bool,
}
