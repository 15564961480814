import React from 'react'
import PropTypes from 'prop-types'
import { Btn } from '../Btn/Btn'
import { Icon } from '../Icon/Icon'
import IconSearch from '../Icon/icon--search.svg'
import IconClear from '../Icon/icon--clear.svg'

import './search-base.scss'

export const SearchBase = ({
  className,
  type,
  name,
  id,
  value,
  placeholder,
  onChange,
}) => (
  <div className={`search-base ${className || ''}`}>
    <div className="search-base__btn search-base__btn-search">
      <Btn
        mod="btn--icon"
        iconLeft={(
          <Icon
            name={IconSearch}
            id="icon--search"
          />
        )}
      />
    </div>

    <input
      className="search-base__input"
      onChange={(event) => onChange(event)}
      value={value}
      type={type}
      name={name}
      id={id}
      placeholder={placeholder}
    />

    <div className="search-base__btn search-base__btn-clear">
      <Btn
        onClick={() => {
          onChange({ target: { value: '' } })
        }}
        mod="btn--icon"
        iconLeft={(
          <Icon
            name={IconClear}
            id="icon--clear"
          />
        )}
      />
    </div>
  </div>
)

SearchBase.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf([
    'text',
  ]),
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}
