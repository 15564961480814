import React from 'react'
import PropTypes from 'prop-types'
import './common-button.scss'

export const CommonButton = ({
  text,
  iconLeft,
  onClick,
  disabled,
  active,
  className,
  dataTestid,
}) => {
  const handleOnClick = (e) => {
    e.stopPropagation()
    if (onClick && !disabled) {
      onClick()
    }
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className={`common-button ${disabled ? 'disabled' : ''} ${active ? 'active' : ''} ${className}`}
      onClick={handleOnClick}
      data-testid={dataTestid}
    >
      {iconLeft && (
        <div className="icon-block">
          {iconLeft}
        </div>
      )}
      {text}
    </button>
  )
}

CommonButton.propTypes = {
  text: PropTypes.string,
  iconLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  dataTestid: PropTypes.string,
}
CommonButton.defaultProps = {
  text: '',
  iconLeft: '',
  onClick: () => {},
  disabled: false,
  active: false,
  className: '',
}
