import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { user } from './slice/user'
import { lpTokens } from './slice/lpTokens'
import { tokens } from './slice/tokens'
import { wallets } from './slice/wallet'
import { priceToken } from './slice/priceToken'
import { tradingView } from './slice/tradingView'
import { trade } from './slice/trade'
import signReducer from './reducer/signReducer'

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
})

export const store = configureStore({
  reducer: {
    user: user.reducer,
    sign: signReducer,
    lpTokens: lpTokens.reducer,
    tokens: tokens.reducer,
    tradingView: tradingView.reducer,
    wallets: wallets.reducer,
    priceToken: priceToken.reducer,
    trade: trade.reducer,
  },
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})
