import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import IconRadioButtonUnchecked from '../Icon/icon--radio-button-unchecked.svg'
import IconRadioButtonChecked from '../Icon/icon--radio-button-checked.svg'
import { Btn } from '../Btn/Btn'
import ClickOutside from '../ClickOutside/ClickOutside'
import Asset from '../Asset/Asset'
import IconUnfold from '../Icon/icon--unfold.svg'

import './select-with-control.scss'

export const SelectWithControl = ({
  items,
  selectedItem,
  onSelectItem,
  dataTestid,
}) => {
  const selectBlock = useRef(null)
  const [isOpened, setIsOpenedState] = useState(false)
  const [openClass, setOpenClass] = useState('select-base--open')

  const openSelect = useCallback(() => {
    setIsOpenedState(!isOpened)

    if (selectBlock.current.offsetTop + 300 > document.documentElement.clientHeight) {
      setOpenClass('select-with-control--open-top')
    } else {
      setOpenClass('select-with-control--open')
    }
  }, [isOpened])

  const renderItems = useMemo(() => (
    items.map((item, index) => (
      <div
        onClick={() => {
          if (item.name !== selectedItem.name) {
            onSelectItem(index)
          }
        }}
        className="select-with-control__item"
        key={index}
      >
        <Asset
          icon={
            item.icon
              ? <Icon name={item.icon.name} id={item.icon.id} />
              : <img className="select-with-control__item-image" src={item.image} alt="img" />
          }
          assetName={item.title}
        />

        {
          item.name !== selectedItem.name ? (
            <Btn
              mod="btn--icon"
              iconLeft={(
                <Icon
                  name={IconRadioButtonUnchecked}
                  id="icon--radio-button-unchecked"
                  mod="icon--22"
                />
              )}
              onClick={() => {
                if (item.name !== selectedItem.name) {
                  onSelectItem(index)
                }
              }}
            />
          ) : (
            <Icon
              name={IconRadioButtonChecked}
              id="icon--radio-button-checked"
              mod="icon--22"
            />
          )
        }
      </div>
    ))
  ), [items, onSelectItem, selectedItem.name])

  return (
    <ClickOutside callback={() => setIsOpenedState(false)}>
      <div
        className={`select-with-control ${isOpened ? openClass : ''}`}
        ref={selectBlock}
        data-testid={dataTestid}
      >
        <div className="select-with-control__body">
          <div
            className="select-with-control__header"
            onClick={() => openSelect()}
          >
            <Asset
              icon={
                selectedItem.icon
                  ? <Icon name={selectedItem.icon.name} id={selectedItem.icon.id} />
                  : <img className="select-with-control__item-image" src={selectedItem.image} alt="img" />
              }
              assetName={selectedItem.title}
            />

            <div className="select-with-control__header-icon">
              <Icon name={IconUnfold} id="icon--unfold" />
            </div>
          </div>

          <div className="select-with-control__list-container">
            {renderItems}
          </div>
        </div>
      </div>
    </ClickOutside>
  )
}

export const SelectWithControlTypeItems = PropTypes.arrayOf(PropTypes.shape({
  value: PropTypes.node,
  name: PropTypes.string,
}))

SelectWithControl.propTypes = {
  items: SelectWithControlTypeItems,
  selectedItem: PropTypes.object,
  onSelectItem: PropTypes.func,
  dataTestid: PropTypes.string,
}
