import React, {
  useRef,
  useEffect,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import md5 from './md5.min'

const Identicon = React.memo(({
  string,
}) => {
  const canvas = useRef(null)

  const updateCanvas = useCallback(() => {
    const hash = md5(string)
    const block = Math.floor(30 / 5)
    const hashcolor = hash.slice(0, 6)

    canvas.current.width = block * 5
    canvas.current.height = block * 5
    // eslint-disable-next-line no-confusing-arrow
    const arr = hash.split('').map((el) => (parseInt(el, 16) < 8) ? 0 : 1)

    const map = []

    // eslint-disable-next-line no-multi-assign
    map[0] = map[4] = arr.slice(0, 5)
    // eslint-disable-next-line no-multi-assign
    map[1] = map[3] = arr.slice(5, 10)
    map[2] = arr.slice(10, 15)

    const ctx = canvas.current.getContext('2d')
    ctx.imageSmoothingEnabled = false
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height)

    map.forEach((row, i) => {
      row.forEach((el, j) => {
        if (el) {
          ctx.fillStyle = `#${hashcolor}`
          ctx.fillRect(
            block * i,
            block * j,
            block,
            block,
          )
        } else {
          ctx.fillStyle = 'transparent'
          ctx.fillRect(
            block * i,
            block * j,
            block,
            block,
          )
        }
      })
    })
  }, [string])

  useEffect(() => {
    updateCanvas(string)
  }, [updateCanvas, string])

  return (
    <canvas
      ref={canvas}
      className="identicon"
      style={{ width: 30, height: 30 }}
    />
  )
})

Identicon.defaultProps = {
  string: '',
}

Identicon.propTypes = {
  string: PropTypes.string,
}

export default Identicon
