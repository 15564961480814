import instance from '../axios-api'
import {
  parseHistoryChartData,
  parseExchanges,
  parseCryptoCompareHistoryData,
  parseMoonToolsHistoryDate,
  parseHistoryChartDataForLine,
  getStableCoinAddressBasedOnNetwork,
  parseCoinIdBySymbolNetworkAndAddress,
} from '../parser/chart'

export const getCoinIdBySymbolNetworkAndAddress = async ({ symbol, address }) => {
  const { data } = await instance.get(`/cgecko/coins/list?include_platform=true&symbol=${symbol.toLowerCase()}`)
  return parseCoinIdBySymbolNetworkAndAddress({ data, network: localStorage.getItem('selectedChain'), address })
}

export const getCoinHistoryChartData = async ({
  coinId,
  days,
}) => {
  const { data } = await instance.get(`/cgecko/raw/coins/${coinId}/ohlc?vs_currency=usd&days=${days || 'max'}`)
  return parseHistoryChartData({ historyData: data })
}

export const getCoinHistoryCharDataForLine = async ({
  coinId,
  days,
}) => {
  const { data } = await instance.get(`/cgecko/raw/coins/${coinId}/market_chart?vs_currency=usd&days=${days || 'max'}`)
  return parseHistoryChartDataForLine({ historyDataForLine: data.prices })
}

export const getAllPossibleExchanges = async () => {
  const { data } = await instance.get('https://min-api.cryptocompare.com/data/v3/all/exchanges')
  return parseExchanges(data.Data)
}

export const getCryptoCompareHistoryData = async ({ query, from, to }) => {
  const { data } = await instance.get(`https://min-api.cryptocompare.com/data/histoday?${query}`)
  return parseCryptoCompareHistoryData({ data, from, to })
}

export const getMoonToolsHistoryData = async ({ address, from, to }) => {
  const network = localStorage.getItem('selectedChain').toLowerCase()
  const stableCoinAddress = getStableCoinAddressBasedOnNetwork()
  const res = await instance.get(`https://api.moontools.io/ohlc?network=${network}&base_address=${address}&quote_address=${stableCoinAddress}&usd_mode=true&timestamp_start=${from}&timestamp_end=${to}&timestamp_format=milliseconds&timestamp_key=time&order=asc&interval=720`, {
    headers: {
      'X-API-Key': '6234e7d5-9e05-440d-81cd-704d0c11a9d8',
      'X-API-Secret': '6398efb6fabb8f852a1ae48ebe4e8495',
      'Access-Control-Allow-Origin': '*',
    },
  })

  return parseMoonToolsHistoryDate(res.data.result)
}
