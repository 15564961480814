import React, {
  useState,
  useMemo,
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../../../../../../../components/Loading/Loading'
import IconUnfold from '../../../../../../../../components/Icon/icon--unfold.svg'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import { ExternalLink } from '../../../../../../../../components/ExternalLink/ExternalLink'
import { getTokensList } from '../../../../../../../../service/api'
import {
  getDefaultTokenBasedOnNetwork,
  parseToken,
  sortArrayByAlphabet,
  getNativeTokenDataBasedOnNetwork,
  getLinkBasedOnCurrentNetwork,
} from '../../../../../../../../parser/data'
import ClickOutside from '../../../../../../../../components/ClickOutside/ClickOutside'
import iconBaseSearch from '../../../../../../../../components/Icon/icon--base-search.svg'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { NEW_ETH_ADDRESS, ZERO_ADDRESS } from '../../../../../../../../const'

const SelectToken = ({
  areUserTokensUsed,
  selectedToken,
  onSelectToken,
}) => {
  const userTokens = useSelector((store) => store.user.tokens)

  const [isTokensListShown, setTokensListIsShownState] = useState(false)
  const [tokens, setTokens] = useState([])
  const [areThereMoreTokens, setThereAreMoreTokensState] = useState(false)
  const [searchTokenValue, setSearchTokenValue] = useState('')
  const [currentTokensPage, setCurrentTokensPage] = useState(1)
  const [areTokensLoading, setTokensAreLoadingState] = useState(true)

  const getInitialTokensList = useCallback(() => {
    const newTokens = [getDefaultTokenBasedOnNetwork()]
    userTokens.forEach((token) => {
      if (![token.address, token.contract_address].some((el) => [NEW_ETH_ADDRESS, ZERO_ADDRESS].includes(el))) {
        const address = token.address || token.contract_address
        newTokens.push({
          ...parseToken(token),
          link: `https://${getLinkBasedOnCurrentNetwork()}/address/${address}`,
        })
      }
    })
    return newTokens
  }, [userTokens])

  const prefilterTokensBasedOnInitialTokens = useCallback((newTokens, initialTokens) => (
    newTokens.filter((newToken) => !initialTokens.map((token) => token.address).includes(newToken.address))
  ), [])

  const filterOurExistingTokens = useCallback((newTokens) => {
    if (areUserTokensUsed) {
      return tokens.length > 0
        ? newTokens.filter((newToken) => tokens.some((token) => newToken.address !== ZERO_ADDRESS && token.address !== newToken.address))
        : newTokens.filter((newToken) => newToken.address !== ZERO_ADDRESS)
    }
    return newTokens
  }, [areUserTokensUsed, tokens])

  const closeTokensList = useCallback(() => {
    setTokensListIsShownState(false)
    setTokens([])
    setTokensAreLoadingState(true)
    setThereAreMoreTokensState(false)
    setSearchTokenValue('')
    setCurrentTokensPage(1)
  }, [])

  const getTokenItemTemplate = useCallback((item) => (
    <div
      className="token-item-container"
      onClick={() => {
        onSelectToken(item)
        closeTokensList()
      }}
    >
      <div className="token-item-left-block">
        <img className="token-icon" src={item.icon} alt="token-icon" />
        <BaseTextBody>{item.symbol}</BaseTextBody>
      </div>

      {
        getNativeTokenDataBasedOnNetwork().tokenSymbol !== item.symbol && (
          <div className="token-item-right-block">
            <ExternalLink
              link={item.link}
              text={item.address}
              onClick={(e) => {
                window.open(item.link, '_blank')
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
              }}
            />
          </div>
        )
      }
    </div>
  ), [onSelectToken, closeTokensList])

  const loadNextTokensInTheList = useCallback(async () => {
    try {
      const incrementedPage = currentTokensPage + 1
      const data = await getTokensList({ filterValue: searchTokenValue, page: incrementedPage })

      const initialTokens = areUserTokensUsed ? getInitialTokensList() : []
      const preFileteredTokens = prefilterTokensBasedOnInitialTokens(data.tokens, initialTokens)

      setTokens(tokens.concat(filterOurExistingTokens(preFileteredTokens)))
      setThereAreMoreTokensState(data.areThereMoreTokens)
      setCurrentTokensPage(incrementedPage)
    } catch (e) {
      setCurrentTokensPage(currentTokensPage)
      setThereAreMoreTokensState(false)
    }
  }, [
    currentTokensPage,
    searchTokenValue,
    tokens,
    filterOurExistingTokens,
    areUserTokensUsed,
    getInitialTokensList,
    prefilterTokensBasedOnInitialTokens,
  ])

  const getLoader = useMemo(() => (
    <div className="loading-block">
      <Loading />
    </div>
  ), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadNewTokensBasedOnSearchValue = useCallback(debounce(async (searchValue) => {
    setCurrentTokensPage(1)
    try {
      const initialTokens = areUserTokensUsed ? getInitialTokensList() : []
      const data = await getTokensList({ filterValue: searchValue, page: 1 })

      const preFileteredTokens = prefilterTokensBasedOnInitialTokens(data.tokens, initialTokens)
      setTokens(initialTokens.concat(filterOurExistingTokens(preFileteredTokens)))

      setThereAreMoreTokensState(data.areThereMoreTokens)
      setTokensAreLoadingState(false)
    } catch (e) {
      setTokens([])
      setTokensAreLoadingState(false)
    }
  }, 1000), [])

  const getSearch = useMemo(() => (
    <div className="tokens-search-block">
      <Icon name={iconBaseSearch} id="icon--base-search" />
      <input
        onChange={(e) => {
          setSearchTokenValue(e.target.value)
          setTokensAreLoadingState(true)
          onLoadNewTokensBasedOnSearchValue(e.target.value)
        }}
        value={searchTokenValue}
        type="text"
        placeholder="Search for asset..."
      />
    </div>
  ), [searchTokenValue, onLoadNewTokensBasedOnSearchValue])

  const getTokensListTemplate = useMemo(() => (
    <div className="tokens-list-container">
      {getSearch}

      {!areTokensLoading && tokens.length > 0 && (
        <InfiniteScroll
          dataLength={tokens.length}
          next={loadNextTokensInTheList}
          hasMore={areThereMoreTokens}
          height={tokens.length >= 10 ? 280 : tokens.length * 42}
          loader={getLoader}
        >
          {tokens.map((item, key) => (
            <div key={key} className="item-block">
              {getTokenItemTemplate(item)}
            </div>
          ))}
        </InfiniteScroll>
      )}

      {!areTokensLoading && tokens.length === 0 && (
        <div className="no-result-block">
          Tokens list is empty
        </div>
      )}

      {areTokensLoading && getLoader}
    </div>
  ), [areTokensLoading, tokens, loadNextTokensInTheList, areThereMoreTokens, getTokenItemTemplate, getLoader, getSearch])

  const openPairsList = useCallback(async () => {
    setTokensListIsShownState(true)
    try {
      const initialTokens = areUserTokensUsed ? getInitialTokensList() : []
      initialTokens.sort((item1, item2) => sortArrayByAlphabet({ item1, item2, prop: 'symbol' }))
      const data = await getTokensList({})
      const preFileteredTokens = prefilterTokensBasedOnInitialTokens(data.tokens, initialTokens)
      setTokens(initialTokens.concat(filterOurExistingTokens(preFileteredTokens)))
      setThereAreMoreTokensState(data.areThereMoreTokens)
      setTokensAreLoadingState(false)
    } catch (e) {
      console.log('e = ', e)
      setTokens([])
      setThereAreMoreTokensState(false)
      setTokensAreLoadingState(false)
    }
  }, [areUserTokensUsed, getInitialTokensList, filterOurExistingTokens, prefilterTokensBasedOnInitialTokens])

  return (
    <ClickOutside callback={closeTokensList}>
      <div className="select-token-container">
        <div className="select-token__body">
          <div
            className="select-token__header"
            onClick={() => {
              if (!isTokensListShown) {
                openPairsList()
              } else {
                closeTokensList()
              }
            }}
          >
            <div className="select-token__header-token-block">
              <img className="token-icon" src={selectedToken.icon} alt="selected-token-icon" />
              <BaseTextBody>{selectedToken.symbol}</BaseTextBody>
            </div>

            <div className="select-token__header-control">
              <Icon name={IconUnfold} id="icon--unfold" />
            </div>
          </div>

          {isTokensListShown && getTokensListTemplate}
        </div>
      </div>
    </ClickOutside>
  )
}

SelectToken.propTypes = {
  areUserTokensUsed: PropTypes.bool,
  selectedToken: PropTypes.object,
  onSelectToken: PropTypes.func,
}

export default SelectToken
