import React from 'react'
import PropTypes from 'prop-types'

import './asset-field.scss'
import { BaseTextBody } from '../BaseText/BaseText'

export const AssetField = ({
  disabled,
  name,
  placeholder,
  asset,
  value,
  onChange,
  onBlur,
  inputType = 'text',
  dataTestid,
  help,
}) => (
  <div
    className={`asset-field ${disabled ? 'asset-field--disabled' : ''}`}
    data-testid={dataTestid}
  >
    {name
      && (
      <div className="asset-field__name">
        <BaseTextBody secondary>{name}</BaseTextBody>
        {help}
      </div>
      )}

    <div className="asset-field__body">
      <input
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        type={inputType}
        className="asset-field__input"
        placeholder={placeholder}
        disabled={disabled}
      />

      <div className="asset-field__asset">
        <BaseTextBody secondary>{asset}</BaseTextBody>
      </div>
    </div>
  </div>
)

AssetField.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.node,
  placeholder: PropTypes.string,
  asset: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  inputType: PropTypes.oneOf([
    'email',
    'hidden',
    'number',
    'text',
  ]),
  dataTestid: PropTypes.string,
  help: PropTypes.element,
}
