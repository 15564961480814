import React from 'react'
import PropTypes from 'prop-types'
import './h1.scss'

export const H1 = ({
  className,
  text,
}) => (
  <h1 className={`h1 ${className || ''}`}>
    {text}
  </h1>
)

H1.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
}
