import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../BaseText/BaseText'
import { Icon } from '../Icon/Icon'
import DefaultTokenIcon from '../Icon/icon--16-default-token.svg'

import './img-pair-block.scss'

const ImgPairBlock = ({
  firstTokenIcon,
  secondTokenIcon,
  text,
  isLoadingFirstIcon,
  isLoadingSecondIcon,
  onClick,
}) => {
  const getImagesPair = useMemo(() => {
    if (isLoadingFirstIcon && isLoadingSecondIcon) {
      return (
        <>
          <span className="img-pair-block--first-img">
            <Icon name={DefaultTokenIcon} id="icon--16-default-token" />
          </span>
          <span className="img-pair-block--second-img">
            <Icon name={DefaultTokenIcon} id="icon--16-default-token" />
          </span>
        </>
      )
    }

    return (
      <>
        <img className="img-pair-block--first-img" src={firstTokenIcon} alt="first-token-icon" />
        <img className="img-pair-block--second-img" src={secondTokenIcon} alt="second-token-icon" />
      </>
    )
  }, [firstTokenIcon, secondTokenIcon, isLoadingFirstIcon, isLoadingSecondIcon])

  return (
    <div
      className="img-pair-block-container"
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <div className="img-pair-block-inner">
        {getImagesPair}
      </div>

      {text && (<BaseTextBody>{text}</BaseTextBody>)}
    </div>
  )
}

ImgPairBlock.propTypes = {
  firstTokenIcon: PropTypes.string,
  secondTokenIcon: PropTypes.string,
  text: PropTypes.string,
  isLoadingFirstIcon: PropTypes.bool,
  isLoadingSecondIcon: PropTypes.bool,
  onClick: PropTypes.func,
}

export default ImgPairBlock
