import React from 'react'

import PropTypes from 'prop-types'
import './social.scss'
import { Icon } from '../Icon/Icon'
import IconTwitter from '../Icon/icon--twitter.svg'
import IconTelegram from '../Icon/icon--telegram.svg'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { tgLink, twitterLink } from '../../const/socialLink'

export const Social = ({
  twitter,
  telegram,
}) => (
  <div className="social">
    {twitter
      && (
      <div className="social__item">
        <ExternalLink
          text="Twitter"
          link={twitterLink}
          icon={<Icon name={IconTwitter} id="icon--twitter" />}
        />
      </div>
      )}

    {telegram
      && (
      <div className="social__item">
        <ExternalLink
          text="Telegram"
          link={tgLink}
          icon={<Icon name={IconTelegram} id="icon--telegram" />}
        />
      </div>
      )}
  </div>
)

Social.propTypes = {
  twitter: PropTypes.bool,
  telegram: PropTypes.bool,
}
