import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import './base-text.scss'

export const BaseTextBody = ({
  size,
  isUnderLine,
  isUpperCase,
  children,
  secondary,
  tertiary,
  success,
  error,
  onClick,
  dataTestid,
}) => {
  const classModify = useMemo(() => {
    let cls = 'base-text--body'

    if (size === 'S') {
      cls = `${cls} base-text--body-s`
    }

    if (isUnderLine) {
      cls = `${cls} base-text--underline`
    }

    if (isUpperCase) {
      cls = `${cls} base-text--uppercase`
    }

    if (secondary) {
      cls = `${cls} base-text--color-secondary`
    }

    if (tertiary) {
      cls = `${cls} base-text--color-tertiary`
    }

    if (success) {
      cls = `${cls} base-text--color-success`
    }

    if (error) {
      cls = `${cls} base-text--color-error`
    }

    return cls
  }, [size, isUnderLine, isUpperCase, secondary, tertiary, success, error])

  return (
    <div
      data-testid={dataTestid}
      onClick={onClick}
      className={`base-text ${classModify || ''}`}
    >
      {children}
    </div>
  )
}

BaseTextBody.propTypes = {
  size: PropTypes.oneOf(['S']),
  isUnderLine: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  children: PropTypes.node,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  dataTestid: PropTypes.string,
}

export const BaseTextSubheading = ({
  demi,
  isUnderLine,
  isUpperCase,
  children,
  secondary,
}) => {
  const classModify = useMemo(() => {
    let cls = 'base-text--subheading'

    if (demi) {
      cls = `${cls} base-text--medium`
    }

    if (isUnderLine) {
      cls = `${cls} base-text--underline`
    }

    if (isUpperCase) {
      cls = `${cls} base-text--uppercase`
    }

    if (secondary) {
      cls = `${cls} base-text--color-secondary`
    }

    return cls
  }, [demi, isUnderLine, isUpperCase, secondary])

  return (
    <div className={`base-text ${classModify || ''}`}>
      {children}
    </div>
  )
}

BaseTextSubheading.propTypes = {
  demi: PropTypes.bool,
  isUnderLine: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  children: PropTypes.node,
  secondary: PropTypes.bool,
}

export const BaseTextHeading = ({
  size,
  isUnderLine,
  isUpperCase,
  children,
  isSecondary,
  dataTestid,
}) => {
  const classModify = useMemo(() => {
    let cls = 'base-text--heading'

    if (size === 'XXL') {
      cls = `${cls} base-text--heading-xxl`
    }

    if (size === 'XL') {
      cls = `${cls} base-text--heading-xl`
    }

    if (size === 'L') {
      cls = `${cls} base-text--heading-l`
    }

    if (size === 'S') {
      cls = `${cls} base-text--heading-s`
    }

    if (isUnderLine) {
      cls = `${cls} base-text--underline`
    }

    if (isUpperCase) {
      cls = `${cls} base-text--uppercase`
    }

    if (isSecondary) {
      cls += ' base-text--color-secondary'
    }

    return cls
  }, [size, isUnderLine, isUpperCase, isSecondary])

  return (
    <div data-testid={dataTestid} className={`base-text ${classModify || ''}`}>
      {children}
    </div>
  )
}

BaseTextHeading.propTypes = {
  size: PropTypes.oneOf(['XXL', 'XL', 'L', 'S']),
  isUnderLine: PropTypes.bool,
  isUpperCase: PropTypes.bool,
  children: PropTypes.node,
  isSecondary: PropTypes.bool,
  dataTestid: PropTypes.string,
}
