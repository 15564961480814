import React, { useMemo } from 'react'

import SettingsIcon from '@material-ui/icons/Settings'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import PropTypes from 'prop-types'
import { Text } from '../Text/Text'
import { Btn } from '../Btn/Btn'

import './exchange-create-order-header.scss'
import { getFeatureFlag } from '../../utils/feature-flags'

export const ExchangeCreateOrderHeader = ({
  onClick,
  isSettingsContainer,
  title,
}) => {
  const isNewDesign = useMemo(() => getFeatureFlag('isNewDesign'), [])

  return (
    <div className={`sidebar-right-dashboard__header_exchange ${isSettingsContainer ? 'sidebar-right-dashboard__header_exchange--open' : ''}`}>
      {!isSettingsContainer && (
        <>
          <Text
            text={title}
          />
          {isNewDesign && (
            <Btn
              mod="btn--icon"
              onClick={onClick}
              iconLeft={(
                <SettingsIcon />
              )}
            />
          )}
        </>
      )}
      {isSettingsContainer && isNewDesign && (
        <>
          <Btn
            mod="btn--icon"
            onClick={onClick}
            iconLeft={(
              <ArrowBackRoundedIcon />
            )}
          />
          <Text
            text="Settings"
          />
        </>
      )}

    </div>
  )
}

ExchangeCreateOrderHeader.defaultProps = {
  onClick: () => {},
  isSettingsContainer: false,
  title: 'Order Setup',
}

ExchangeCreateOrderHeader.propTypes = {
  onClick: PropTypes.func,
  isSettingsContainer: PropTypes.bool,
  title: PropTypes.node,
}
