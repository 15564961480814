import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { BaseTextBody } from '../../../../../components/BaseText/BaseText'

import './tab-vertical.scss'

export const TabVertical = ({
  icon,
  text,
  disabled,
  to,
}) => {
  const location = useLocation()
  return !disabled ? (
    <Link
      className={`
        tab-vertical
        ${location.pathname === to || (text === 'Dashboard' && location.pathname === '/') ? 'active' : ''}
      `}
      onKeyDown={() => {}}
      to={to}
    >
      {icon}
      <BaseTextBody>
        {text}
      </BaseTextBody>
    </Link>
  ) : (
    <div className="tab-vertical disabled">
      {icon}
      <BaseTextBody>{text}</BaseTextBody>
    </div>
  )
}

TabVertical.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string,
}
