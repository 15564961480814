import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const priceTokensAdapter = createEntityAdapter({
  selectId: (token) => token.address,
  // sortComparer: (a, b) => a.address.localeCompare(b.address),
})

export const priceToken = createSlice({
  name: 'tokens',
  initialState: priceTokensAdapter.getInitialState({
    isLoading: true,
  }),
  reducers: {
    processToken: priceTokensAdapter.upsertOne,
    setInitState() {
      return {
        ...priceTokensAdapter.getInitialState({
          isLoading: true,
        }),
      }
    },
    allTokensLoaded(state) {
      return {
        ...state,
        isLoading: false,
      }
    },
  },
})

export const { processToken } = priceToken.actions

/**
 * @param {string} address
 * @param {string} symbol
 * @param {string} name
 * @param {number|string} totalPrice
 * @param {number|string} balance
 * @param {number} decimals
 * @param {string} tradeSymbol
 * @param {function} dispatch
 */
export const addPriceToken = ({
  address,
  symbol,
  name,
  totalPrice,
  balance,
  decimals,
  dispatch,
  tradeSymbol,
  logoUrl,
  latestNativeTokenPrice,
}) => {
  dispatch(processToken({
    address,
    symbol,
    name,
    balance,
    decimals,
    totalPrice: parseFloat(totalPrice),
    tradeSymbol: (tradeSymbol || ''),
    logoUrl,
    latestNativeTokenPrice,
  }))
}

export const {
  setInitState,
  allTokensLoaded,
} = priceToken.actions

export const {
  selectById: selectPriceTokenById,
  selectIds: selectPriceTokenIds,
  selectEntities: selectPriceTokenEntities,
  selectAll: selectAllPriceTokens,
} = priceTokensAdapter.getSelectors((state) => state.priceToken)
