import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import IconClose from '../../../../../../components/Icon/icon--close.svg'
import { BaseModal } from '../../../../../../components/BaseModal/BaseModal'
import { Icon } from '../../../../../../components/Icon/Icon'
import { BaseButton } from '../../../../../../components/BaseButton/BaseButton'
import { Status } from '../../../../../../components/Status/Status'
import { BaseTextHeading, BaseTextBody, BaseTextSubheading } from '../../../../../../components/BaseText/BaseText'
import { Stepper } from '../../../../../../components/Stepper/Stepper'
import { ExternalLink } from '../../../../../../components/ExternalLink/ExternalLink'
import { AssetInfo } from '../../../../../../components/AssetInfo/AssetInfo'
import { AssetBlock } from '../../../../../../components/AssetBlock/AssetBlock'
import IconWallet from '../../../../../../components/Icon/icon--wallet.svg'
import IconCancel from '../../../../../../components/Icon/icon--cancel.svg'
import IconUrgently from '../../../../../../components/Icon/icon--urgently.svg'
import { List } from '../../../../../../components/List/List'
import { ERC20ToFloat, croppedString } from '../../../../../../utils/helperFunctions'
import { useWeb3Bn } from '../../../../../../hooks/web3'
import { getLinkBasedOnCurrentNetwork } from '../../../../../../parser/data'
import { urgentlyOrder, deleteOrder } from '../../../../../../service/api'
import { toastSuccess, toastCritical } from '../../../../../../components/Toast/Toast'

import './order-details-modal.scss'

const OrderDetailsModal = ({
  selectedOrder,
  onClose,
  onCancelOrder,
  onUrgentlyExecuteOrder,
}) => {
  const BN = useWeb3Bn()

  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const userState = useSelector((store) => store.user)

  const getModalHeader = useMemo(() => (
    <BaseButton
      onClick={onClose}
      onlyIcon
      size="s"
      variant="secondary"
      iconLeft={(<Icon name={IconClose} id="icon--close" />)}
    />
  ), [onClose])

  const getModalBody = useMemo(() => {
    const steps = selectedOrder.modal.steps.map((step) => {
      if (step.hash) {
        return {
          ...step,
          text: <ExternalLink text={step.text} link={step.link} />,
        }
      }
      return step
    })

    return (
      <>
        <Status type={selectedOrder.modal.type} />
        <BaseTextHeading size="S">{selectedOrder.modal.text}</BaseTextHeading>
        <BaseTextBody>{selectedOrder.modal.subText}</BaseTextBody>
        <div className={`order-details-modal__stepper-container ${steps.length === 1 ? 'small' : ''}`}>
          <Stepper steps={steps} />
        </div>
      </>
    )
  }, [selectedOrder])

  const getAssetsTemplate = useMemo(() => {
    const { token1, token2 } = selectedOrder.modal
    const token1Balance = ERC20ToFloat(new BN(token1.value.toString()), token1.decimals, BN)
    const token2Balance = token2.price !== 0 && token2.price ? ((token1Balance * (token1.price || 0)) / token2.price) : undefined

    const cyberWalletObj = userState.internalAddresses.filter((wallet) => wallet.address === internalSelectedAddress)[0]

    return (
      <AssetInfo
        swap={{
          block1: <AssetBlock
            title="You Pay"
            bodyName={(
              <>
                <img src={token1.icon} alt="first-token-icon" />
                <BaseTextSubheading>{token1.symbol}</BaseTextSubheading>
              </>
            )}
            bodyValue={token1Balance}
            footerName={token1.name}
            footerValue={token1Balance ? token1.displayPrice : undefined}
          />,
          block2: <AssetBlock
            title="You Recieve"
            bodyName={(
              <>
                <img src={token2.icon} alt="second-token-icon" />
                <BaseTextSubheading>{token2.symbol}</BaseTextSubheading>
              </>
            )}
            bodyValue={token2Balance}
            footerName={token2.name}
            footerValue={token2Balance ? token2.displayPrice : undefined}
          />,
        }}
        detail={(
          <List items={[
            {
              name: 'Type',
              value: selectedOrder.type,
            },
            {
              name: 'Trigger Conditions',
              value: selectedOrder.triggerConditions,
            },
            {
              name: 'Created',
              value: selectedOrder.creationTime,
              valueState: { secondary: selectedOrder.creationTime === '00.00.00, 00:00:00' },
            },
            {
              name: 'Triggered',
              value: selectedOrder.modal.triggered,
              valueState: { secondary: selectedOrder.modal.triggered === '00.00.00, 00:00:00' },
            },
            {
              name: 'Executed',
              value: selectedOrder.modal.executed,
              valueState: { secondary: selectedOrder.modal.executed === '00.00.00, 00:00:00' },
            },
            {
              name: 'Owner wallet',
              value: (
                <ExternalLink
                  text={croppedString(internalSelectedAddress || '', 0, 6, -5)}
                  link={`https://${getLinkBasedOnCurrentNetwork()}/address/${internalSelectedAddress}`}
                />
              ),
              valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
            },
            {
              name: 'Cyberwallet',
              value: cyberWalletObj ? cyberWalletObj.name : '',
              valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
            },
          ]}
          />
        )}
      />
    )
  }, [selectedOrder, BN, internalSelectedAddress, userState.internalAddresses])

  const getModalFooter = useMemo(() => (
    <>
      {getAssetsTemplate}

      <div className="controls-row">
        <BaseButton
          variant="secondary"
          iconRight={<Icon name={IconCancel} id="icon--cancel" />}
          onClick={async () => {
            try {
              await deleteOrder(selectedOrder.id)
              toastSuccess('Order has been canceled successfully')
              onCancelOrder()
            } catch (e) {
              toastCritical('Order cancellation failed')
            }
          }}
        >
          Cancel order
        </BaseButton>

        <BaseButton
          iconRight={<Icon name={IconUrgently} id="icon--urgently" />}
          onClick={async () => {
            try {
              await urgentlyOrder(selectedOrder.id)
              toastSuccess('The order will be urgently executed')
              onUrgentlyExecuteOrder()
            } catch (e) {
              toastCritical('Could not execute the order urgently')
            }
          }}
        >
          Urgently execute
        </BaseButton>
      </div>
    </>
  ), [getAssetsTemplate, selectedOrder, onCancelOrder, onUrgentlyExecuteOrder])

  return (
    <BaseModal
      title="Order Details"
      topAction={getModalHeader}
      footer={getModalFooter}
      dataTestId="OrderDetailsModal"
    >
      {getModalBody}
    </BaseModal>
  )
}

OrderDetailsModal.propTypes = {
  selectedOrder: PropTypes.object,
  onClose: PropTypes.func,
  onCancelOrder: PropTypes.func,
  onUrgentlyExecuteOrder: PropTypes.func,
}

export default OrderDetailsModal
