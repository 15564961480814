import React, {
  useMemo, useEffect, useCallback, useState, useRef,
} from 'react'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'

import { BaseTextHeading } from '../../../../../../components/BaseText/BaseText'
import { BaseTabs } from '../../../../../../components/BaseTabs/BaseTabs'
import MarketForm from './market-form/MarketForm'
import SettingsForm from './settings-form/SettingsForm'
import { getTokenData, getLpTokensPair } from '../../../../../../service/api'
import { getExactTokenBalance } from '../../../../../../service/etherscanApi'
import { swapSelectedTokens, updateSelectedTokensPair } from '../../../../../../store/slice/trade'
import IconSettings from '../../../../../../components/Icon/icon--settings.svg'
import { BaseButton } from '../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../components/Icon/Icon'
import IconArrowBack from '../../../../../../components/Icon/icon--arrow-back.svg'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../../../../../../const'
import { weiToEth } from '../../../../../../utils/helperFunctions'
import { parseNativeTokenData, getNativeTokenDataBasedOnNetworkWithZeroBalance } from '../../../../../../parser/data'
import { useWeb3Bn } from '../../../../../../hooks/web3'

import './side-panel-block.scss'
import { useWeb3 } from '../../../../../../context/web3Provider'
import GPForm from './gp-form/GPForm'
import { getFeatureFlag } from '../../../../../../utils/feature-flags'
import SlTpForm from './sl-tp-form/SlTpForm'

const SidePanelBlock = () => {
  const isShowTradeGP = getFeatureFlag('isShowTradeGP')
  const isShowLpTp = getFeatureFlag('isShowLpTp')
  const web3React = useWeb3()
  const Web3BN = useWeb3Bn()
  const dispatch = useDispatch()

  const tokensDataPoller = useRef()
  const isPollerRequestPending = useRef()

  const [cancelTokenSource, setCancelTokenSource] = useState()

  const selectedTokensPair = useSelector((store) => store.trade.selectedTokensPair)
  const { latestNativeTokenPrice, tokens } = useSelector((store) => store.user)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [tradeSettings] = useLocalStorage('tradeSettings')

  const [tokensPair, setTokensPair] = useState({})
  const [isSettingsFormOpened, setSettingsFormIsOpenedState] = useState(false)
  const [cachedSelectedTokensPair, setCachedSelectedTokensPairState] = useState({})
  const [cachedTokenValue, setCachedTokenValue] = useState()

  const [isUpdateTokensRequestPending, setUpdatedTokensRequestIsPendingState] = useState(false)

  const [selectedTab, setSelectedTab] = useState(0)

  const [wasLimitPriceUpdatedByUser, setLimitPriceWasUpdatedByUserState] = useState(false)
  const [cachedLimit, setCachedLimit] = useState()

  const loadTokenData = useCallback(async (token, cancelToken) => {
    if (![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(token.address)) {
      const { address } = token
      const checkedAddress = web3React.library.utils.toChecksumAddress(address)
      const data = await getTokenData(checkedAddress, cancelToken)
      const exactTokenBalance = await getExactTokenBalance({
        tokenAddress: checkedAddress,
        userAddress: internalSelectedAddress,
        decimals: data.decimals,
        Web3BN,
        cancelToken,
      })
      return { ...data, exactTokenBalance }
    }

    const foundToken = tokens.length > 0
      ? tokens.filter((userToken) => (
        userToken.address === token.address
        || userToken.contract_address === token.address
        || (token.address === ZERO_ADDRESS && userToken.contract_address === NEW_ETH_ADDRESS)
      ))[0]
      : getNativeTokenDataBasedOnNetworkWithZeroBalance(latestNativeTokenPrice)

    return {
      ...parseNativeTokenData(token, latestNativeTokenPrice),
      exactTokenBalance: weiToEth(web3React?.library.utils.fromWei, foundToken.balance.toString()),
    }
  }, [web3React, internalSelectedAddress, latestNativeTokenPrice, tokens, Web3BN])

  const loadAndSetTokensPair = useCallback(async (cancelToken) => {
    try {
      const token1 = await loadTokenData(selectedTokensPair.token1, cancelToken)
      const token2 = await loadTokenData(selectedTokensPair.token2, cancelToken)
      setTokensPair({ token1, token2 })
      isPollerRequestPending.current = false
    } catch (e) {
      console.log('Tokens pair setting error = ', e)
      isPollerRequestPending.current = false
    }
  }, [selectedTokensPair, loadTokenData])

  const stopPollingForTokensData = useCallback(() => {
    if (tokensDataPoller.current) {
      clearInterval(tokensDataPoller.current)
      tokensDataPoller.current = undefined
      isPollerRequestPending.current = false
    }
  }, [])

  const startPollingForTokensData = useCallback(async (cancelToken) => {
    if (!tokensDataPoller.current) {
      const intervalTime = 10000
      tokensDataPoller.current = setInterval(async () => {
        if (!isPollerRequestPending.current) {
          isPollerRequestPending.current = true
          loadAndSetTokensPair(cancelToken)
        }
      }, intervalTime)
    }
  }, [loadAndSetTokensPair])

  useEffect(() => {
    (async () => {
      if (web3React.library && JSON.stringify(cachedSelectedTokensPair) !== JSON.stringify(selectedTokensPair)) {
        if (cancelTokenSource) {
          cancelTokenSource.cancel()
          stopPollingForTokensData()
        }

        const newCancelTokenSource = axios.CancelToken.source()
        setCancelTokenSource(newCancelTokenSource)
        setTokensPair({})
        setCachedSelectedTokensPairState(selectedTokensPair)
        loadAndSetTokensPair(newCancelTokenSource.token)
        startPollingForTokensData(newCancelTokenSource.token)
      }
    })()
  }, [
    selectedTokensPair,
    cachedSelectedTokensPair,
    web3React,
    loadAndSetTokensPair,
    startPollingForTokensData,
    stopPollingForTokensData,
    cancelTokenSource,
  ])

  const onSwapTokens = useCallback(async () => {
    setUpdatedTokensRequestIsPendingState(true)
    try {
      const pair = await getLpTokensPair({
        address1: web3React.library.utils.toChecksumAddress(tokensPair.token1.address),
        address2: web3React.library.utils.toChecksumAddress(tokensPair.token2.address),
      })
      setUpdatedTokensRequestIsPendingState(false)
      const isPairExist = !!pair
      dispatch(swapSelectedTokens(isPairExist))
    } catch (e) {
      setUpdatedTokensRequestIsPendingState(false)
    }
  }, [web3React, tokensPair, dispatch])

  // TODO: it's better to move this to a different action
  const onUpdateTokensPair = useCallback(async ({ token1, token2 }) => {
    setUpdatedTokensRequestIsPendingState(true)
    try {
      const pair = await getLpTokensPair({
        address1: web3React.library.utils.toChecksumAddress(token1.address),
        address2: web3React.library.utils.toChecksumAddress(token2.address),
      })

      setUpdatedTokensRequestIsPendingState(false)
      if (pair) {
        if (pair.token1.address !== token1.address && pair.token2.address !== token2.address) {
          const cachedToken1 = { ...pair.token1 }
          pair.token1 = { ...pair.token2 }
          pair.token2 = cachedToken1
        }
        dispatch(updateSelectedTokensPair({
          pair: {
            token1: pair.token1,
            token2: pair.token2,
          },
          pairAddress: pair.address,
          isChartExist: true,
        }))
      } else {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1,
            token2,
          },
          isChartExist: false,
        }))
      }
    } catch (e) {
      setUpdatedTokensRequestIsPendingState(false)
    }
  }, [web3React, dispatch])

  // below forms getMarketFormTemplate & getLimitFormTemplate usememo calls are used sepparately for a better performance
  // and let a developer distinguish the functionality easily
  const getMarketFormTemplate = useMemo(() => (
    <MarketForm
      internalSelectedAddress={internalSelectedAddress}
      isUpdateTokensRequestPending={isUpdateTokensRequestPending}
      tokensPair={tokensPair}
      isLoading={!(tokensPair.token1 && tokensPair.token2)}
      onSwapTokens={onSwapTokens}
      onSelectFirstToken={(token) => onUpdateTokensPair({ token1: token, token2: tokensPair.token2 })}
      onSelectSecondToken={(token) => onUpdateTokensPair({ token1: tokensPair.token1, token2: token })}
      onUpdateTokenValue={(tokenValue) => setCachedTokenValue(tokenValue)}
      cachedTokenValue={cachedTokenValue}
      wasLimitPriceUpdatedByUser={wasLimitPriceUpdatedByUser}
      onUpdateLimitValue={() => setLimitPriceWasUpdatedByUserState(true)}
      onCacheLimitValue={(newLimitValue) => setCachedLimit(newLimitValue)}
      cachedLimit={cachedLimit}
    />
  ), [
    internalSelectedAddress,
    isUpdateTokensRequestPending,
    tokensPair,
    onSwapTokens,
    onUpdateTokensPair,
    cachedTokenValue,
    wasLimitPriceUpdatedByUser,
    cachedLimit,
  ])

  const getLimitFormTemplate = useMemo(() => (
    <MarketForm
      isLimitForm
      internalSelectedAddress={internalSelectedAddress}
      isUpdateTokensRequestPending={isUpdateTokensRequestPending}
      tokensPair={tokensPair}
      pairAddress={selectedTokensPair.address}
      isLoading={!(tokensPair.token1 && tokensPair.token2)}
      onSwapTokens={onSwapTokens}
      onSelectFirstToken={(token) => onUpdateTokensPair({ token1: token, token2: tokensPair.token2 })}
      onSelectSecondToken={(token) => onUpdateTokensPair({ token1: tokensPair.token1, token2: token })}
      onUpdateTokenValue={(tokenValue) => setCachedTokenValue(tokenValue)}
      cachedTokenValue={cachedTokenValue}
      wasLimitPriceUpdatedByUser={wasLimitPriceUpdatedByUser}
      onUpdateLimitValue={() => setLimitPriceWasUpdatedByUserState(true)}
      onCacheLimitValue={(newLimitValue) => setCachedLimit(newLimitValue)}
      cachedLimit={cachedLimit}
    />
  ), [
    internalSelectedAddress,
    isUpdateTokensRequestPending,
    tokensPair,
    onSwapTokens,
    onUpdateTokensPair,
    selectedTokensPair,
    cachedTokenValue,
    wasLimitPriceUpdatedByUser,
    cachedLimit,
  ])

  const getSettingsFormTemplate = useMemo(() => (
    <SettingsForm
      gas={tradeSettings ? tradeSettings.gas : 'fastest'}
      onChangeGas={(newGas) => {
        writeStorage('tradeSettings', {
          ...tradeSettings,
          gas: newGas,
        })
      }}
      slippage={tradeSettings ? tradeSettings.slippage : '0.1'}
      onChangeSlippage={(newSlippage) => {
        writeStorage('tradeSettings', {
          ...tradeSettings,
          slippage: newSlippage,
        })
      }}
    />
  ), [tradeSettings])

  const getGPForm = useMemo(() => (
    <GPForm />
  ), [])

  const tabsArray = [
    {
      text: 'Market',
      renderContent: () => (
        !isSettingsFormOpened
          ? getMarketFormTemplate
          : getSettingsFormTemplate
      ),
    },
    {
      text: 'Limit',
      disabled: isSettingsFormOpened,
      renderContent: () => getLimitFormTemplate,
    },
  ]

  if (isShowTradeGP) {
    tabsArray.push({
      text: 'GP',
      disabled: isSettingsFormOpened,
      renderContent: () => getGPForm,
    })
  }

  if (isShowLpTp) {
    tabsArray.push({
      text: 'SL & TP',
      disabled: isSettingsFormOpened,
      renderContent: () => <SlTpForm />,
    })
  }

  const getTabs = useMemo(() => tabsArray, [tabsArray])

  const getHeader = useMemo(() => (
    !isSettingsFormOpened
      ? (
        <>
          <BaseTextHeading size="S">Trade</BaseTextHeading>
          <BaseButton
            onlyIcon
            variant="secondary"
            onClick={() => setSettingsFormIsOpenedState(true)}
            iconLeft={(<Icon name={IconSettings} id="icon--settings" />)}
            dataTestid="TradeSettingsButton"
          />
        </>
      )
      : (
        <>
          <BaseButton
            onlyIcon
            variant="secondary"
            onClick={() => setSettingsFormIsOpenedState(false)}
            iconLeft={(<Icon name={IconArrowBack} id="icon--arrow-back" />)}
          />
          <BaseTextHeading size="S">Settings</BaseTextHeading>
        </>
      )
  ), [isSettingsFormOpened])

  return (
    <div data-testid="side-panel-block-container" className="side-panel-block-container">
      <div className="side-panel-block-container__header">
        {getHeader}
      </div>

      <div className="side-panel-block-container__body">
        <BaseTabs
          className="no-paddings"
          withAdditionalBorder
          tabs={getTabs}
          selectedTabIndex={isSettingsFormOpened ? 0 : selectedTab}
          onChange={(tab) => setSelectedTab(tab.toLowerCase() === 'market' ? 0 : 1)}
        />
      </div>
    </div>
  )
}

export default SidePanelBlock
