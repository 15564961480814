import React from 'react'

import PropTypes from 'prop-types'
import './history-item.scss'
import { Icon } from '../Icon/Icon'
import IconGas from '../Icon/icon--local-gas-station.svg'
import IconArrowRight from '../Icon/icon--arrow-right.svg'
import { BaseTextBody } from '../BaseText/BaseText'
import { TransactionTypeDetails } from './TransactionTypeDetails/TransactionTypeDetails'
import { ExchangeCurrency } from './ExchangeCurrency/ExchangeCurrency'
import { FromTo } from './FromTo/FromTo'

export const HistoryItem = ({
  transactionTypeDetails,
  currencyLeft,
  currencyRight,
  fromTo,
  gasValue,
  single,
  gasCostBackButton,
}) => (
  <div className="history-item">
    <div className="history-item__type">
      <TransactionTypeDetails {...transactionTypeDetails} />
    </div>

    <div className="history-item__center">
      {!single
        && (
        <>
          <div className="history-item__currency">
            {fromTo ? (
              <FromTo {...fromTo} />
            ) : (
              <ExchangeCurrency {...currencyLeft} flipRight />
            )}
          </div>

          <div className="history-item__arrow">
            <Icon name={IconArrowRight} id="icon--arrow-right" />
          </div>
        </>
        )}

      <div className={`history-item__currency ${single ? 'history-item__currency--single' : ''}`}>
        <ExchangeCurrency {...currencyRight} />
      </div>
    </div>

    <div className="history-item__right">
      <div className="history-item__gas">
        <Icon name={IconGas} id="icon--local-gas-station" />
        <BaseTextBody size="S" secondary>Gas fee</BaseTextBody>
      </div>

      <div className="history-item__gas-value">
        <BaseTextBody>{gasValue}</BaseTextBody>
      </div>
    </div>

    {gasCostBackButton && (
      <div className="history-item__gas-cost-back">
        {gasCostBackButton}
      </div>
    )}
  </div>
)

HistoryItem.propTypes = {
  transactionTypeDetails: PropTypes.shape({
    currency: PropTypes.element,
    icon: PropTypes.oneOf([
      'add',
      'down',
      'up',
      'open',
      'remove',
      'swap',
    ]),
    name: PropTypes.oneOf([
      'Send',
      'Receive',
      'Exchange',
      'Bridge Exchange',
      'Trade {type}',
      'Approval',
      'Add Liquidity',
      'Remove Liquidity',
    ]),
    time: PropTypes.string,
  }),
  gasValue: PropTypes.string,
  currencyLeft: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.element,
  }),
  currencyRight: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.element,
  }),
  fromTo: PropTypes.shape({
    to: PropTypes.bool,
    value: PropTypes.string,
  }),
  single: PropTypes.bool,
  gasCostBackButton: PropTypes.element,
}
