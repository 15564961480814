import React from 'react'
import { useSelector } from 'react-redux'
import TopPairControl from './top-pair-control/TopPairControl'
import Chart from './chart/Chart'
import BottomPairControl from './bottom-pair-control/BottomPairControl'

import './chart-block.scss'

const ChartBlock = () => {
  const { selectedTokensPair, isChartExist } = useSelector((store) => store.trade)

  return (
    <div className="chart-block-container">
      <TopPairControl />
      <Chart selectedTokensPair={selectedTokensPair} isChartExist={isChartExist} />
      <BottomPairControl />
    </div>
  )
}

export default ChartBlock
