import { useCallback, useEffect, useState } from 'react'
import { BREAKPOINT, BREAKPOINT_NAME } from '../const/breakpoint'

/**
 * Отдает true, если бреакпоинт больше экрана
 * @param {xs|md|lg|xl|fhd} size
 * @returns {(boolean|string)[]}
 */
export const useGetDownBreakpoint = (size) => {
  const [isNeedSize, setIsNeedSize] = useState(false)
  const [currentBreakpoint, setCurrentBreakpoint] = useState(BREAKPOINT_NAME.fhd)

  const getBreakPoint = useCallback(() => {
    if (window.innerWidth >= BREAKPOINT.fhd) {
      setCurrentBreakpoint(BREAKPOINT_NAME.fhd)
    }
    if (window.innerWidth >= BREAKPOINT.xl && window.innerWidth < BREAKPOINT.fhd) {
      setCurrentBreakpoint(BREAKPOINT_NAME.xl)
    }
    if (window.innerWidth >= BREAKPOINT.lg && window.innerWidth < BREAKPOINT.xl) {
      setCurrentBreakpoint(BREAKPOINT_NAME.lg)
    }
    if (window.innerWidth >= BREAKPOINT.md && window.innerWidth < BREAKPOINT.lg) {
      setCurrentBreakpoint(BREAKPOINT_NAME.md)
    }
    if (window.innerWidth < BREAKPOINT.md) {
      setCurrentBreakpoint(BREAKPOINT_NAME.xs)
    }
  }, [])

  useEffect(() => {
    setIsNeedSize(window.innerWidth < BREAKPOINT[size])
    getBreakPoint()

    window.addEventListener('resize', () => {
      getBreakPoint()
      setIsNeedSize(window.innerWidth < BREAKPOINT[size])
    })
    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [getBreakPoint, size])

  return [isNeedSize, currentBreakpoint]
}
