import { Route, Switch } from 'react-router-dom'
import React from 'react'
import { Dashboard } from './pages/main-container/pages/dashboard/Dashboard'
import { History } from './pages/History'
import { ExchangeFilled } from './pages/ExchangeFilled'
import Example from './pages/Example'
import ProtectedRoute from './utils/ProtectedRoute'
import { TestMagicLink } from './pages/TestMagicLink'
import { Exchange } from './pages/main-container/pages/exchange/Exchange'
import Page404 from './pages/main-container/pages/page-404/Page404'
import Trade from './pages/main-container/pages/trade/Trade'
import Bridge from './pages/main-container/pages/bridge/Bridge'
import Loyalty from './pages/main-container/pages/loyalty/Loyalty'
import LoyaltyUser from './pages/main-container/pages/loyalty-user/LoyaltyUser'

const Routes = () => (
  <Switch>
    <ProtectedRoute exact path="/dashboard" component={Dashboard} />
    <ProtectedRoute exact path="/automatic-orders" component={Example} />
    <ProtectedRoute exact path="/history" component={History} />
    {/* TODO: Remove this once we are sure that new trade is 100% enabled */}
    <ProtectedRoute exact path="/old-trade" component={ExchangeFilled} />
    <ProtectedRoute exact path="/trade" component={Trade} />
    <ProtectedRoute exact path="/test-magic-link" component={TestMagicLink} />
    <ProtectedRoute exact path="/exchange" component={Exchange} />
    <ProtectedRoute exact path="/bridge" component={Bridge} />
    <ProtectedRoute exact path="/loyalty" component={Loyalty} />
    <ProtectedRoute exact path="/loyalty-user" component={LoyaltyUser} />
    <Route path="/404" component={Page404} />
    <Route exact path="/" component={Dashboard} />
    <Route path="*" component={Page404} />
  </Switch>
)

export default Routes
