import React from 'react'
import PropTypes from 'prop-types'
import { H2 } from '../H2/H2'

import './block.scss'

export const Block = ({
  title,
  headerRight,
  children,
}) => (
  <div className="block">
    {(title || headerRight) && (
      <div className="block__header">
        <div className="block__header-left">
          <H2
            text={title}
          />
        </div>

        <div className="block__header-right">
          {headerRight}
        </div>
      </div>
    )}

    <div className="block__body">
      {children}
    </div>
  </div>
)

Block.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  headerRight: PropTypes.string,
}
Block.defaultProps = {
  title: '',
  headerRight: '',
}
