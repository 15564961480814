import React from 'react'
import '../../../styles/exchange.scss'
import { Social } from '../../../../components/Social/Social'
import ExchangeSwap from './exchange-swap/ExchangeSwap'

export const Exchange = () => (
  <div className="exchange__container">
    <div className="exchange__body">
      <ExchangeSwap />
    </div>

    <div className="exchange__footer">
      <Social
        twitter
        discord
        telegram
      />
    </div>
  </div>
)
