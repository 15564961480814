import { gql, ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
})

/**
 * @param {string} pairAddress
 * @param {number} hours
 * @returns {Promise<null|*>}
 */
export const getPairHourDatas = async (pairAddress, hours = 24) => {
  const query = gql`
    query GetPairHourDatas($pairAddress: String, $hours: Int) { 
      pairHourDatas(
        first: $hours,
        where: { pair: $pairAddress }, 
        orderBy: hourStartUnix, 
        orderDirection: desc
      ){
        reserve0
        reserve1
        hourStartUnix
        hourlyVolumeUSD
      }
    }`

  try {
    const response = await client.query({
      query,
      variables: { pairAddress, hours },
    })

    const formattedResponce = response.data.pairHourDatas.map((pairData) => ({
      price: pairData.reserve0 / pairData.reserve1,
      reserve0: pairData.reserve0,
      hourStartUnix: pairData.hourStartUnix,
      hourlyVolumeUSD: pairData.hourlyVolumeUSD,
    }))

    return formattedResponce
  } catch (err) {
    console.error(err)
    return null
  }
}

/**
 * @param {string} pairAddress
 * @param {number} days
 * @returns {Promise<null|*>}
 */
export const getDayDatas = async (pairAddress, days) => {
  const query = gql`
    query GetDayDatas($pairAddress: String, $days: Int) {
      tokenDayDatas(
        first: $days, 
          orderBy: date, 
          orderDirection: desc, 
          where: {token: $pairAddress}
        ) {
          id
          token {
            id
          }
          priceUSD
        }
    }`

  try {
    const response = await client.query({
      query,
      variables: { pairAddress, days },
    })

    const formattedResponce = response.data.tokenDayDatas.map((pairData) => ({
      id: pairData.id,
      priceUSD: pairData.priceUSD,
      token: {
        id: pairData.token.id,
      },
    }))

    return formattedResponce
  } catch (error) {
    console.error(error)
    return null
  }
}

export const availableTokensToTrade = async () => {
  const query = gql`
  query tokens {
     tokens(orderBy: tradeVolumeUSD, orderDirection: desc, first: 1000) {
       id
       name
       symbol
       tradeVolumeUSD
     }
   }
  `
  try {
    const response = await client.query({
      query,
    })

    let formattedResponse = response.data.tokens.map((tokenData) => ({
      address: tokenData.id,
      value: tokenData.symbol,
    }))
    formattedResponse = [{
      address: `0x${'0'.repeat(40)}`,
      value: 'ETH',
    }, ...formattedResponse]
    return formattedResponse
  } catch (error) {
    console.error(error)
    return null
  }
}

export const allLPTokens = async () => {
  const query = gql`{
  pairs(first: 20, orderBy: volumeUSD, orderDirection: desc) {
    id
    token1Price
    token0 {
      id
      name
      symbol
    }
    token1 {
      id
      name
      symbol
    }
  }
}
  `
  try {
    const response = await client.query({
      query,
    })

    const formattedResponse = response.data.pairs.map((pairsData) => ({
      address: pairsData.id,
      price: pairsData.token1Price,
      value: `${pairsData.token0.symbol} / ${pairsData.token1.symbol}`,
    }))

    return formattedResponse
  } catch (error) {
    console.error(error)
    return null
  }
}
