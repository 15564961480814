import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from '@rehooks/local-storage'
import { PairHistory } from '../../../../../../../../components/PairHistory/PairHistory'
import Loading from '../../../../../../../../components/Loading/Loading'
import { Chip } from '../../../../../../../../components/Chip/Chip'
import './total-exchange-rates.scss'
import { balanceHistory } from '../../../../../../../../service/eth1'
import { checkFloatNaN } from '../../../../../../../../utils/helperFunctions'

const CONST_FILTER = {
  '1Y': 'Y',
  '1M': 'M',
  '1W': 'W',
  '1D': 'D',
  '1H': 'H',
}

export const TotalExchangeRates = ({
  className,
}) => {
  const [selectedAddress] = useLocalStorage('selectedAddress')
  const [dataChart, setDataChart] = useState([])
  const [fromChart, setFromChart] = useState(1589426025)
  const [toChart, setToChart] = useState(1620982630)
  const [selectedFilter, setSelectedFilter] = useState(CONST_FILTER['1Y'])
  const [isLoading, setIsLoading] = useState(false)

  const renderChart = useMemo(() => {
    if (isLoading) {
      return (
        <>
          <PairHistory dataChart={dataChart} />

          <div className="dashboard--loading-chart">
            <Loading />
          </div>
        </>
      )
    }
    if (Array.isArray(dataChart) && dataChart.length < 1) {
      return (
        <div className="dashboard__example-content">
          <p className="dashboard__example-content-header">The selected wallet has no history.</p>
          <p className="dashboard__example-content-description">Portfolio performance for selected wallet will start to calculated after your first deposit.</p>
        </div>
      )
    }

    return <PairHistory dataChart={dataChart} />
  }, [isLoading, dataChart])

  useEffect(() => {
    let isMounted = true

    const asyncFunc = async () => {
      try {
        if (isMounted) {
          setIsLoading(true)
        }

        const { data } = await balanceHistory(selectedFilter, selectedAddress)
        const newDataChart = {}

        // eslint-disable-next-line array-callback-return
        const arrPromise = data.eth_history.map((item) => {
          // eslint-disable-next-line array-callback-return
          item.holdings.map((hold) => {
            const unixTimestamp = checkFloatNaN(new Date(hold.timestamp).getTime() / 1000, 0)

            newDataChart[unixTimestamp] = {
              time: unixTimestamp,
              value: checkFloatNaN(newDataChart[unixTimestamp]?.value, 0) + hold.close.quote,
            }
          })
        })

        await Promise.all(arrPromise)

        if (isMounted) {
          setDataChart(Object.values(newDataChart).map((item) => ({
            time: item.time,
            value: item.value,
          })))
        }
      } catch (e) {
        console.log('dataChart', e)
      }
      if (isMounted) {
        setIsLoading(false)
      }
    }

    asyncFunc()

    return () => { isMounted = false }
  }, [fromChart, selectedAddress, selectedFilter, toChart])

  return (
    <div className={`total-exchange-rates ${className || ''}`}>
      <div className="total-exchange-rates__right">
        <div className="total-exchange-rates-filter-chart">
          <div className="total-exchange-rates-filter-chart-chip">
            <Chip
              onClick={() => {
                const newTo = new Date()
                const newFrom = new Date()

                setFromChart(parseInt((newFrom.getTime() - (24 * 3600000)) / 1000, 10))
                setToChart(parseInt(newTo.getTime() / 1000, 10))
                setSelectedFilter(CONST_FILTER['1D'])
              }}
              isSelected={selectedFilter === CONST_FILTER['1D']}
            >
              1D
            </Chip>
          </div>
          <div className="total-exchange-rates-filter-chart-chip">
            <Chip
              onClick={() => {
                const newTo = new Date()
                const newFrom = new Date()

                setFromChart(parseInt((newFrom.getTime() - (7 * 24 * 3600000)) / 1000, 10))
                setToChart(parseInt(newTo.getTime() / 1000, 10))
                setSelectedFilter(CONST_FILTER['1W'])
              }}
              isSelected={selectedFilter === CONST_FILTER['1W']}
            >
              1W
            </Chip>
          </div>
          <div className="total-exchange-rates-filter-chart-chip">
            <Chip
              onClick={() => {
                const newTo = new Date()
                const newFrom = new Date()

                newFrom.setMonth(newFrom.getMonth() - 1)

                setFromChart(parseInt(newFrom.getTime() / 1000, 10))
                setToChart(parseInt(newTo.getTime() / 1000, 10))
                setSelectedFilter(CONST_FILTER['1M'])
              }}
              isSelected={selectedFilter === CONST_FILTER['1M']}
            >
              1M
            </Chip>
          </div>
          <div className="total-exchange-rates-filter-chart-chip">
            <Chip
              onClick={() => {
                const newTo = new Date()
                const newFrom = new Date()

                newFrom.setFullYear(newFrom.getFullYear() - 1)

                setFromChart(parseInt(newFrom.getTime() / 1000, 10))
                setToChart(parseInt(newTo.getTime() / 1000, 10))
                setSelectedFilter(CONST_FILTER['1Y'])
              }}
              isSelected={selectedFilter === CONST_FILTER['1Y']}
            >
              1Y
            </Chip>
          </div>
        </div>
        {renderChart}
      </div>
    </div>
  )
}

TotalExchangeRates.propTypes = {
  className: PropTypes.string,
}
