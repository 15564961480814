import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { ExchangeCreateOrderNew } from '../ExchangeCreateOrderNew/ExchangeCreateOrderNew'
import { ExchangeCreateOrderHeader } from '../ExchangeCreateOrderHeader/ExchangeCreateOrderHeader'
import './sidebar-right-dashboard.scss'

export const SidebarRightDashboard = ({
  className,
  title,
  isShowTabs,
}) => {
  const [settingsContainer, setSettingsContainer] = useState(false)

  const settingsClick = useCallback(() => {
    setSettingsContainer(!settingsContainer)
  }, [settingsContainer])
  return (
    <div className={`sidebar-right-dashboard ${className || ''}`}>
      <div className="sidebar-right-dashboard__header">
        <ExchangeCreateOrderHeader title={title} onClick={settingsClick} isSettingsContainer={settingsContainer} />
      </div>
      <div className="sidebar-right-dashboard__body">
        <ExchangeCreateOrderNew isShowTabs={isShowTabs} isSettingsContainer={settingsContainer} />
      </div>
    </div>
  )
}

SidebarRightDashboard.defaultProps = {
  title: 'Order Setup',
  isShowTabs: true,
}

SidebarRightDashboard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  isShowTabs: PropTypes.bool,
}
