export const ZERO_ADDRESS = `0x${'0'.repeat(40)}`
export const NEW_ETH_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export const USDT_ADDRESS = '0xdac17f958d2ee523a2206206994597c13d831ec7'
export const WRAPPED_ETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
export const WRAPPED_BNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
export const WRAPPED_MATIC_ADDRESS = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270'
export const TYPES_WALLET = {
  external: 'external',
  internal: 'internal',
}
export const CONST_NODE_ENV = {
  development: 'development',
  test: 'test',
  production: 'production',
}
export const BSC_ADDRESS = '0xe541504417670fb76b612b41b4392d967a1956c7'
export const MATIC_ADDRESS = '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0'

export const ETHEREUM_RPC_URL = 'https://speedy-nodes-nyc.moralis.io/517928ac357f728df57c3f15/eth/mainnet'
export const BINANCE_RPC_URL = 'https://bsc-dataseed.binance.org/'
export const POLYGON_RPC_URL = 'https://speedy-nodes-nyc.moralis.io/517928ac357f728df57c3f15/polygon/mainnet'

export const ETHEREUM_NETWORK_SETTINGS = {
  chainId: '0x1',
  chainName: 'Ethereum',
  nativeCurrency: {
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: [ETHEREUM_RPC_URL],
  blockExplorerUrls: ['https://etherscan.io'],
  iconUrls: [''],
}

export const BSC_NETWORK_SETTINGS = {
  chainId: '0x38',
  chainName: 'Binance Smart Chain',
  nativeCurrency: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: [BINANCE_RPC_URL],
  blockExplorerUrls: ['https://bscscan.com/'],
  iconUrls: [''],
}

export const POLYGON_NETWORK_SETTINGS = {
  chainId: '0x89',
  chainName: 'Polygon',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: [POLYGON_RPC_URL],
  blockExplorerUrls: ['https://polygonscan.com/'],
  iconUrls: [''],
}

export const SUPPORTED_CHAINS_IDS = [1, 56, 137]

export const ETHEREUM_NETWORK_SETTINGS_FOR_BRIDGE = {
  name: 'Ethereum',
  chainId: 1,
  rpcUrl: ETHEREUM_RPC_URL,
  blockTime: 15000,
  blockDelay: 8,
  symbol: 'ETH',
  blockExplorerUrl: 'https://etherscan.io',
  tokenSymbolList: [
    'USDC',
    'USDT',
    'WETH',
    'BUSD',
    'DODO',
    'LYRA',
    'DOMI',
    'MCB',
    'IMX',
    'oneDODO',
    'OLO',
    'BOBA',
    'PSP',
    'XTK',
    'Metis',
    'WXT',
    'PEOPLE',
    'SOS',
    'FRAX',
    'CEC',
    'STND',
    'WOO',
    'CELR',
    'KROM',
    'PERP',
    'SAFLE',
    'DAI',
  ],
}

export const BSC_NETWORK_SETTINGS_FOR_BRIDGE = {
  name: 'Binance Smart Chain',
  chainId: 56,
  rpcUrl: BINANCE_RPC_URL,
  blockTime: 4000,
  blockDelay: 8,
  symbol: 'BNB',
  blockExplorerUrl: 'https://bscscan.com',
  tokenSymbolList: [
    'USDC',
    'USDT',
    'WETH',
    'BUSD',
    'DODO',
    'DOMI',
    'MCB',
    'oneDODO',
    'OLO',
    'PSP',
    'ATL',
    'PEOPLE',
    'SOS',
    'FRAX',
    'CEC',
    'WOO',
    'PERP',
    'SAFLE',
    'WBNB',
    'BNB',
  ],
}

export const POLYGON_NETWORK_SETTINGS_FOR_BRIDGE = {
  name: 'Polygon',
  chainId: 137,
  rpcUrl: POLYGON_RPC_URL,
  blockTime: 4000,
  blockDelay: 80,
  symbol: 'MATIC',
  blockExplorerUrl: 'https://polygonscan.com',
}

export const BRIDGE_ORDERS_STATUS = {
  TRANSFER_UNKNOWN: 0,
  TRANSFER_SUBMITTING: 1,
  TRANSFER_FAILED: 2,
  TRANSFER_WAITING_FOR_SGN_CONFIRMATION: 3,
  TRANSFER_WAITING_FOR_FUND_RELEASE: 4,
  TRANSFER_COMPLETED: 5,
  TRANSFER_TO_BE_REFUNDED: 6,
  TRANSFER_REQUESTING_REFUND: 7,
  TRANSFER_REFUND_TO_BE_CONFIRMED: 8,
  TRANSFER_CONFIRMING_YOUR_REFUND: 9,
  TRANSFER_REFUNDED: 10,
  TRANSFER_DELAYED: 11,
}

export const DEFAULT_ETHEREUM_TOKEN = {
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  decimals: 18,
  name: 'Ethereum',
  symbol: 'ETH',
  usdtPrice: '0',
  icon: '/static/media/icon--ethereum.891e0eca.svg#icon--ethereum',
  link: 'https://etherscan.io//token/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  isLinkHidden: true,
  exactTokenBalance: '0',
}

export const DEFAULT_BNB_TOKEN = {
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  decimals: 18,
  name: 'Binance Coin',
  symbol: 'BNB',
  usdtPrice: '0',
  icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABNCAMAAADU1xmCAAAAxlBMVEUAAADvrxDvtQXvtgrvtwvvtwrxuQvxuAvwugvwuQrvtwjwuQvwugvwuQrvuAnvtwjxugvxuwrwuQvxugnvugvwuQvxuwvyuQvxuQrwuwrvtg3vtwzvtQvvuQrwuAvvtgvvuQ3vvxDvuAvwugvvuQrvtwrvuArwuArvuAzvuQzvuAvvtwzvtwvvtgvwuQv33IX////22Hb99uHywin0ykj102f55KP++/D43IXzxjj213bywSr99uDxvRr313bzxjn44JT65KPcDRBMAAAALnRSTlMAEDBQYICPn7/fQM/vr3Agn3/fbzDvj19/r1BAMIC/cFAQkM9QoLCvkIBwgKCQV/fbcAAAAgJJREFUeJyt2E1Lw0AQBuCAjaIUKYqnHJTiSXQTKkggvfT//6l2TZPux8w7M8vOSdPk6Tub5mO3aYS637qltq20M5ZcXrsi6YmQlrqraPnaGKyNYFm8V4Wl9pSWr6qYyJksyXs0ay8Vk6F0RRjHoSN6K4fGrB8QR4wd+vLBlymdhEHOgPVXDTVrTWZIB24aw6DinlXR+hA7qMLx0aJkvwAL7k8VsFs4NhrV5h+3cydEo5KBUzFjHzaM5ToUreewgWsWaGwyPh2vQYzhPLajPuDbBM3uNY0SGN8q/YErwIDm7NhFa3PmGAA8dszRfbNNN112n9a/eGwiMr5ljf6fzYXDWH5mU+16yJHYFrdJjmCirQdNEJuYExJrwY925SCWNBtpY3ggOptBjXy2UYNNLMaOmxKD47YefijCMm1utqhNr1HXQlmb/lrYp5tcaZvOtconlqZN1f1NnQxoJZjX8oFzyVWha9O5B80zS5tM9TxVY0hbmp3/4S/0VOuYD43J3Kf4jmTAlhc4Lpy7tqRq8/Zyye1gSXZ78YXzeCUWzMsBpmszmjC883vpksVLBiCcCtNPjU5ym5ZpmzWZ8CuRMGL2jObOJ9gmue6AvhxhBcsEZqzyekjltZrK60hWT7BsnIzp1hl9fWkwpVdzHbSTiai4GXWJNdc3If0USUu1wTq5KJ0B2ttNDqDvAWUAAAAASUVORK5CYII=',
  link: 'https://bscscan.com/token/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  isLinkHidden: true,
  exactTokenBalance: '0',
}

export const DEFAULT_POLYGON_TOKEN = {
  address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  decimals: 18,
  name: 'Polygon',
  symbol: 'MATIC',
  usdtPrice: '',
  icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAMAAACf4xmcAAAC/VBMVEUAAAApW+hGqv9/f/8Z//hDgP1QhvZJif1omPI4ePYtcfQwdv8wcvY+fPeRrfMAAP8Af/92mu07evczdfQudf8qbO4qbO4pbfE0dvZBffW+xveHqOQ4efctdP8rbe8rbe4qbvM8evVPhfUAAAAyc/MydPY9evVBfPY6evcwdP4qbPYqbO0rcPcxdPhNjvIscvxAffUqa+4sc/1Ef/MydPYrbe5HgvUvcvUyc/dPh/dEkvk0d/Y1d/RAsfhHl/Q1mfovmv8pdPArbO80dvk8e/Q/m/onePIra+8rau8rZ+4qgPErr/wsuPI4mfktnf8nkfgphPYqcvEra+4ydPQ9e/VCm/osmP4nk/kph/cqdfEraO8qd/ErofQrwPorwfkfv/U3mPonkPgolPookfkpfvQrbvA3ePY/d/ItmP8nkPgok/kokvkqcPArcO8rl/MruvcrwPcrvfcqvPciu/YokPkojfgqevNWie8ok/ookPgri/IrtPcrw/grvPYrvvkqvfcucPM7efIuoOwrq/Urv/crvPcrvfYvcfUzdvcpgfUrkvMrxPcscfk3ePUokfopovhCffUojvkpo/hOh/Y/v/8pifc3ePZIn/8nkfk1mfUqsvYruv8rvvcqdPIolPkoj/kqqfgqrvcplvoqq/gppPgpnfgqr/gqv/Ypnfopl/8uyv8sxP4qvfc3nfkulvwpmv8ok/wqvPYtxP8ov/gxqvcrlvsqvPYvl/wtlvwrnP8tyf8qw/snvvhKpP8qlf8qw/omvvcXqv8slvstxv8rwfs5m/wslvwtw/8uzf8pv/kmwPlV//82mvsrlf0ru/gowfkA//8loKAvqvczmPoqvfspwfs5nvkqufgtoP8nkPgnkfgrvfcswvwjufczlfkslvs2l/knkvsovPc1mfwrxPwov/kvmPwrw/sowPkrlvoumPwowfsqlv0qlf83m/oqwPsylPwumPwpqvcrwfwoofYqwPkpxfwzmfwst/80m/srrv8qxPkrr/0zlPolxvp7PJhOAAAA/3RSTlMAAwMCAQREliib/P/vgxYBAhFz9v///P/gWAYJ4v///v/OOQG30U8ruf///f//HP+f/v+C7vx549FhLM2yEiVt//f//5c5+v///f7/KGT//P///+1nNv79////////9hxm/P/////XR//9///////////7Hv///zL///////7/+vaRCv////7Zvv////+l//+K//8+BP9yMf7X/v////////////////z/////5FvX///8/61s/Npn8P//1VMfivBrBrj/omHQ//+6PgOH7/9fAgIYovSIQDP/9/79/yEx6RX/z43ZV/PqcqmRj8r/eMBX1vtk/Ot8x/+p//qgPDbJtGiTAAAA60lEQVR4nGNgGAX0AcwEBcBAWEySEcFjlBQTxlTDIqgtJydnxArjsxoBuWKCLGjKfAPkwEBWGmJVhBOIFxOPpiwhucIdrE6sAcxPzi6Ti6ppK8ZQllxSCjGwxw6kLDm5v7UQmzKgwqkQE404GIG8SYU4lAEB2Ely8joElCWXBMnJRbXOwaNsHlRhfmFhCx5li6tLwMrmEFBWWFiwEqJsFX5lbQz7Ekvm1B4QIKSMgWHlCUi4EVAGBpQps4coK4Lxb0GU3WVAB+uTF298jeAeSpxUuwtDETAd3vuBKvDr7mksyhgYmPByRwHtAADhcn/REaqhxgAAAABJRU5ErkJggg==',
  link: 'https://polygonscan.com/token/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  isLinkHidden: true,
  exactTokenBalance: '0',
}

export const TRANSACTION_METHOD = {
  exchange: 'exchange',
  send: 'send',
  receive: 'receive',
  approval: 'approval',
}

export const NATIVE_ETH_TOKEN = {
  contract_name: 'Ether',
  contract_ticker_symbol: 'ETH',
  logo_url: 'https://logos.covalenthq.com/tokens/1/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
  contract_address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  address: '0x0000000000000000000000000000000000000000',
  contract_decimals: '18',
  balance: '0',
  network: 'ETH',
  icon: 'https://logos.covalenthq.com/tokens/1/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
}

export const NATIVE_BSC_TOKEN = {
  contract_name: 'Binance Coin',
  contract_ticker_symbol: 'BNB',
  logo_url: 'https://logos.covalenthq.com/tokens/1/0xb8c77482e45f1f44de1745f52c74426c631bdd52.png',
  contract_address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  address: '0x0000000000000000000000000000000000000000',
  contract_decimals: '18',
  balance: '0',
  network: 'BSC',
  icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABNCAMAAADU1xmCAAAAxlBMVEUAAADvrxDvtQXvtgrvtwvvtwrxuQvxuAvwugvwuQrvtwjwuQvwugvwuQrvuAnvtwjxugvxuwrwuQvxugnvugvwuQvxuwvyuQvxuQrwuwrvtg3vtwzvtQvvuQrwuAvvtgvvuQ3vvxDvuAvwugvvuQrvtwrvuArwuArvuAzvuQzvuAvvtwzvtwvvtgvwuQv33IX////22Hb99uHywin0ykj102f55KP++/D43IXzxjj213bywSr99uDxvRr313bzxjn44JT65KPcDRBMAAAALnRSTlMAEDBQYICPn7/fQM/vr3Agn3/fbzDvj19/r1BAMIC/cFAQkM9QoLCvkIBwgKCQV/fbcAAAAgJJREFUeJyt2E1Lw0AQBuCAjaIUKYqnHJTiSXQTKkggvfT//6l2TZPux8w7M8vOSdPk6Tub5mO3aYS637qltq20M5ZcXrsi6YmQlrqraPnaGKyNYFm8V4Wl9pSWr6qYyJksyXs0ay8Vk6F0RRjHoSN6K4fGrB8QR4wd+vLBlymdhEHOgPVXDTVrTWZIB24aw6DinlXR+hA7qMLx0aJkvwAL7k8VsFs4NhrV5h+3cydEo5KBUzFjHzaM5ToUreewgWsWaGwyPh2vQYzhPLajPuDbBM3uNY0SGN8q/YErwIDm7NhFa3PmGAA8dszRfbNNN112n9a/eGwiMr5ljf6fzYXDWH5mU+16yJHYFrdJjmCirQdNEJuYExJrwY925SCWNBtpY3ggOptBjXy2UYNNLMaOmxKD47YefijCMm1utqhNr1HXQlmb/lrYp5tcaZvOtconlqZN1f1NnQxoJZjX8oFzyVWha9O5B80zS5tM9TxVY0hbmp3/4S/0VOuYD43J3Kf4jmTAlhc4Lpy7tqRq8/Zyye1gSXZ78YXzeCUWzMsBpmszmjC883vpksVLBiCcCtNPjU5ym5ZpmzWZ8CuRMGL2jObOJ9gmue6AvhxhBcsEZqzyekjltZrK60hWT7BsnIzp1hl9fWkwpVdzHbSTiai4GXWJNdc3If0USUu1wTq5KJ0B2ttNDqDvAWUAAAAASUVORK5CYII=',
}

export const NATIVE_POLYGON_TOKEN = {
  contract_name: 'Matic Token',
  contract_ticker_symbol: 'MATIC',
  logo_url: 'https://logos.covalenthq.com/tokens/1/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
  contract_address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  address: '0x0000000000000000000000000000000000000000',
  contract_decimals: '18',
  balance: '0',
  network: 'POLYGON',
  sort: 'prioritized',
  icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAMAAACf4xmcAAAC/VBMVEUAAAApW+hGqv9/f/8Z//hDgP1QhvZJif1omPI4ePYtcfQwdv8wcvY+fPeRrfMAAP8Af/92mu07evczdfQudf8qbO4qbO4pbfE0dvZBffW+xveHqOQ4efctdP8rbe8rbe4qbvM8evVPhfUAAAAyc/MydPY9evVBfPY6evcwdP4qbPYqbO0rcPcxdPhNjvIscvxAffUqa+4sc/1Ef/MydPYrbe5HgvUvcvUyc/dPh/dEkvk0d/Y1d/RAsfhHl/Q1mfovmv8pdPArbO80dvk8e/Q/m/onePIra+8rau8rZ+4qgPErr/wsuPI4mfktnf8nkfgphPYqcvEra+4ydPQ9e/VCm/osmP4nk/kph/cqdfEraO8qd/ErofQrwPorwfkfv/U3mPonkPgolPookfkpfvQrbvA3ePY/d/ItmP8nkPgok/kokvkqcPArcO8rl/MruvcrwPcrvfcqvPciu/YokPkojfgqevNWie8ok/ookPgri/IrtPcrw/grvPYrvvkqvfcucPM7efIuoOwrq/Urv/crvPcrvfYvcfUzdvcpgfUrkvMrxPcscfk3ePUokfopovhCffUojvkpo/hOh/Y/v/8pifc3ePZIn/8nkfk1mfUqsvYruv8rvvcqdPIolPkoj/kqqfgqrvcplvoqq/gppPgpnfgqr/gqv/Ypnfopl/8uyv8sxP4qvfc3nfkulvwpmv8ok/wqvPYtxP8ov/gxqvcrlvsqvPYvl/wtlvwrnP8tyf8qw/snvvhKpP8qlf8qw/omvvcXqv8slvstxv8rwfs5m/wslvwtw/8uzf8pv/kmwPlV//82mvsrlf0ru/gowfkA//8loKAvqvczmPoqvfspwfs5nvkqufgtoP8nkPgnkfgrvfcswvwjufczlfkslvs2l/knkvsovPc1mfwrxPwov/kvmPwrw/sowPkrlvoumPwowfsqlv0qlf83m/oqwPsylPwumPwpqvcrwfwoofYqwPkpxfwzmfwst/80m/srrv8qxPkrr/0zlPolxvp7PJhOAAAA/3RSTlMAAwMCAQREliib/P/vgxYBAhFz9v///P/gWAYJ4v///v/OOQG30U8ruf///f//HP+f/v+C7vx549FhLM2yEiVt//f//5c5+v///f7/KGT//P///+1nNv79////////9hxm/P/////XR//9///////////7Hv///zL///////7/+vaRCv////7Zvv////+l//+K//8+BP9yMf7X/v////////////////z/////5FvX///8/61s/Npn8P//1VMfivBrBrj/omHQ//+6PgOH7/9fAgIYovSIQDP/9/79/yEx6RX/z43ZV/PqcqmRj8r/eMBX1vtk/Ot8x/+p//qgPDbJtGiTAAAA60lEQVR4nGNgGAX0AcwEBcBAWEySEcFjlBQTxlTDIqgtJydnxArjsxoBuWKCLGjKfAPkwEBWGmJVhBOIFxOPpiwhucIdrE6sAcxPzi6Ti6ppK8ZQllxSCjGwxw6kLDm5v7UQmzKgwqkQE404GIG8SYU4lAEB2Ely8joElCWXBMnJRbXOwaNsHlRhfmFhCx5li6tLwMrmEFBWWFiwEqJsFX5lbQz7Ekvm1B4QIKSMgWHlCUi4EVAGBpQps4coK4Lxb0GU3WVAB+uTF298jeAeSpxUuwtDETAd3vuBKvDr7mksyhgYmPByRwHtAADhcn/REaqhxgAAAABJRU5ErkJggg==',
}
