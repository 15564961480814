import React from 'react'
import PropTypes from 'prop-types'
import './base-table.scss'
import { BaseTextSubheading } from '../BaseText/BaseText'

const BaseTable = ({
  className,
  tHead,
  tBody,
}) => (
  <table className={`base-table ${className || ''}`} align="center" border="0" cellPadding="0" cellSpacing="0" width="100%">
    <thead>
      <tr>
        {tHead.map((item, index) => (
          <th
            valign="top"
            key={index}
          >
            <BaseTextSubheading secondary>{item}</BaseTextSubheading>
          </th>
        ))}
      </tr>
    </thead>

    <tbody>
      {tBody.map((trItem, trIndex) => (
        <tr
          className={trItem.trClass || ''}
          key={trIndex}
        >
          {trItem.tdList.map((tdItem, tdIndex) => (
            <td
              valign="top"
              key={tdIndex}
            >
              <BaseTextSubheading>{tdItem}</BaseTextSubheading>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
)

BaseTable.propTypes = {
  className: PropTypes.string,
  tHead: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ])),
  tBody: PropTypes.arrayOf(PropTypes.shape({
    trClass: PropTypes.string,
    tdList: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.node,
    ])),
  })),
}

export default BaseTable
