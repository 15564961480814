import axios from 'axios'
import config from './config'

const instance = axios.create({
  baseURL: config.apiURL,
})

instance.interceptors.response.use((response) => response, (error) => {
  const accessToken = localStorage.getItem('accessToken')

  if (error?.response?.status === 401 && accessToken && error?.response?.config?.baseURL === config.apiURL) {
    localStorage.clear()
    window.location.reload()
  }

  return Promise.reject(error)
})

export default instance
