import React from 'react'
import PropTypes from 'prop-types'
import './asset-info.scss'
import iconArrowRight from '../Icon/icon--arrow-right.svg'
import { Icon } from '../Icon/Icon'

export const AssetInfo = ({
  approve,
  swap,
  transfer,
  detail,
}) => (
  <div className="asset-info">
    <div className="asset-info__top">
      {approve
        && approve}

      {swap
        && (
        <div className="asset-info__blocks asset-info__blocks--swap">
          {swap.block1}

          <div className="asset-info__swap-arrow">
            <Icon name={iconArrowRight} id="icon--arrow-right" />
          </div>

          {swap.block2}
        </div>
        )}

      {transfer
        && (
        <div className="asset-info__blocks">
          <div className="asset-info__transfer">
            {transfer.block1}
            {transfer.block2}
          </div>

          <div className="asset-info__swap-arrow">
            <Icon name={iconArrowRight} id="icon--arrow-right" />
          </div>

          {transfer.block3}
        </div>
        )}
    </div>

    {detail
      && (
      <div className="asset-info__detail">
        {detail}
      </div>
      )}
  </div>
)

AssetInfo.propTypes = {
  approve: PropTypes.node,
  swap: PropTypes.shape({
    block1: PropTypes.node,
    block2: PropTypes.node,
    block3: PropTypes.node,
  }),
  transfer: PropTypes.shape({
    block1: PropTypes.node,
    block2: PropTypes.node,
    block3: PropTypes.node,
  }),
  detail: PropTypes.node,
}
