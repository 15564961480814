import React from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import iconMoreHoriz from '../../../../../../../../components/Icon/icon--more-horiz.svg'
import IconRadioButtonChecked from '../../../../../../../../components/Icon/icon--radio-button-checked.svg'
import IconRadioButtonUnchecked from '../../../../../../../../components/Icon/icon--radio-button-unchecked.svg'
import IconListAlt from '../../../../../../../../components/Icon/icon--list-alt.svg'
import './rewards-item.scss'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'

const RewardsItem = ({
  title,
  icon,
  level,
  exp,
  quests,
  active,
}) => (
  <div className="rewards-item">
    <div className="rewards-item__top">
      <div className="rewards-item__user">
        {icon && (
          <div className="rewards-item__icon">
            {icon}
          </div>
        )}

        <BaseTextBody>
          {title}
        </BaseTextBody>
      </div>

      <div className="rewards-item__action">
        <BaseButton
          onClick={() => {}}
          variant="secondary"
          onlyIcon
          size="xs"
          iconLeft={(
            <Icon
              name={iconMoreHoriz}
              id="icon--more-horiz"
            />
          )}
        />

        {active ? (
          <Icon name={IconRadioButtonChecked} id="icon--radio-button-checked" mod="active" />
        ) : (
          <Icon name={IconRadioButtonUnchecked} id="icon--radio-button-unchecked" />
        )}
      </div>
    </div>

    <div className="rewards-item__bottom">
      <div className="rewards-item__exp">
        <BaseTextBody
          size="S"
          secondary
        >
          Lvl.&nbsp;
          {level}
        </BaseTextBody>

        <BaseTextBody
          size="S"
          secondary
        >
          {exp}
&nbsp;exp
        </BaseTextBody>
      </div>

      <div className="rewards-item__quests">
        <Icon name={IconListAlt} id="icon--list-alt" />

        <BaseTextBody
          size="S"
          secondary
        >
          {quests}
&nbsp;quests
        </BaseTextBody>
      </div>
    </div>
  </div>
)

RewardsItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  level: PropTypes.string,
  exp: PropTypes.string,
  quests: PropTypes.string,
  active: PropTypes.bool,
}

export default RewardsItem
