import React from 'react'
import PropTypes from 'prop-types'
import { Input } from '../../../../../../../components/Input/Input'

import './create-form.scss'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { Alert } from '../../../../../../../components/Alert/Alert'
import { Checkbox } from '../../../../../../../components/Checkbox/Checkbox'

const CreateForm = ({
  name,
  password,
  repeatPassword,
  errors,
  onChangeName,
  onChangePassword,
  onChangeRepeatPassword,
  onClickConsent,
  isConsentChecked,
}) => (
  <div className="create-form__container">
    <div className="create-form__item">
      <div className="create-form__item-title">
        <BaseTextBody>Enter a name for your wallet</BaseTextBody>
      </div>

      <Input
        dataTestid="create-new-wallet--input-name"
        value={name}
        onChange={(e) => onChangeName(e.target.value)}
        type="text"
        name="new-cyberwallet-name"
        min="4"
        max="12"
        placeholder="Cyber Wallet"
        done={!errors.name && name.length > 0}
      />

      <div className="create-form__item-bottom">
        {
          !errors.name
            ? (<BaseTextBody size="S" secondary>18 characters max</BaseTextBody>)
            : (<BaseTextBody size="S" error>{errors.name[0]}</BaseTextBody>)
        }
      </div>
    </div>

    <div className="create-form__password">
      <div className="create-form__item">
        <div className="create-form__item-title">
          <BaseTextBody>Create password</BaseTextBody>
        </div>

        <Input
          dataTestid="create-new-wallet--input-password"
          value={password}
          onChange={(e) => onChangePassword(e.target.value)}
          type="password"
          name="form_password"
          min="8"
          max="12"
          placeholder="Password"
        />

        <div className="create-form__item-bottom">
          {
            !errors.pin
              ? (<BaseTextBody size="S" secondary>Use 8 or more characters with a mix of letters, numbers & symbols.</BaseTextBody>)
              : (<BaseTextBody size="S" error>{errors.pin[0]}</BaseTextBody>)
          }
        </div>
      </div>

      <div className="create-form__item">
        <div className="create-form__item-title">
          <BaseTextBody>Repeat password</BaseTextBody>
        </div>

        <Input
          dataTestid="create-new-wallet--input-repeat-password"
          value={repeatPassword}
          onChange={(e) => onChangeRepeatPassword(e.target.value)}
          type="password"
          name="repeat_password"
          min="8"
          max="12"
          placeholder="Repeat password"
        />

        <div className="create-form__item-bottom">
          {errors.repeatPin && (
            <BaseTextBody size="S" error>{errors.repeatPin[0]}</BaseTextBody>
          )}
        </div>
      </div>
    </div>

    <Alert
      warning
    >
      Please keep your password in a safe place. Access to the cyber wallet is possible only with a password. If the password is lost, it
      &nbsp;is impossible to recover.
    </Alert>

    <div className="create-form__consent">
      <Checkbox
        dataTestid="create-new-wallet--checkbox"
        onChange={onClickConsent}
        checked={isConsentChecked}
        name="consent_checkbox"
        textRight="I understood and saved the password in a safe place."
      />
    </div>
  </div>
)

CreateForm.propTypes = {
  name: PropTypes.string,
  password: PropTypes.string,
  repeatPassword: PropTypes.string,
  errors: PropTypes.object,
  onChangeName: PropTypes.func,
  onChangePassword: PropTypes.func,
  onChangeRepeatPassword: PropTypes.func,
  onClickConsent: PropTypes.func,
  isConsentChecked: PropTypes.bool,
}

export default CreateForm
