import instance from '../axios-api'
import {
  getCurrentNetworkForRequest,
  parseZeroXData,
} from '../parser/api'

// TODO: remove once we have a new trade enabled
/**
 * @param {string} sell token address
 * @param {string} buy token address
 * @param {number} amount
 * @param {string} network
 * @returns {Promise<void>}
 */
export const zeroX = async ({
  sell,
  buy,
  amount,
  cyberWallet,
}) => {
  try {
    return instance.get('/0x', {
      params: {
        sell,
        buy,
        amount,
        network: getCurrentNetworkForRequest(),
        slippage: '0.1',
        takerAddress: cyberWallet,
      },
    })
  } catch (e) {
    console.error(e)

    return null
  }
}

export const getZeroXData = async ({
  cyberWallet,
  sellAddress,
  buyAddress,
  amount,
  slippage = '0.1',
  cancelToken,
}) => {
  const res = await instance.get('/0x', {
    cancelToken,
    params: {
      takerAddress: cyberWallet,
      sell: sellAddress,
      buy: buyAddress,
      amount,
      slippage,
      network: getCurrentNetworkForRequest(),
    },
  })

  return parseZeroXData(res.data)
}
