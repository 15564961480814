import React, { useMemo } from 'react'
import './alert.scss'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import IconError from '../Icon/icon--error-circle.svg'
import IconWarning from '../Icon/icon--warning.svg'
import IconInfo from '../Icon/icon--info.svg'
import IconCheck from '../Icon/icon--check-circle.svg'
import { AlertMessage } from './alert-message/AlertMessage'

export const Alert = ({
  size,
  children,
  error,
  warning,
  assistive,
  success,
}) => {
  const classNameAlert = useMemo(() => {
    let cls = 'alert'

    if (error) {
      cls = `${cls} alert--error`
    }
    if (warning) {
      cls = `${cls} alert--warning`
    }
    if (assistive) {
      cls = `${cls} alert--assistive`
    }
    if (success) {
      cls = `${cls} alert--success`
    }

    if (size) {
      cls = `${cls} alert--size-${size}`
    }

    return cls
  }, [error, warning, assistive, success, size])

  return (
    <div className={classNameAlert}>
      {error && (<Icon name={IconError} id="icon--error-circle" />)}
      {warning && (<Icon name={IconWarning} id="icon--warning" />)}
      {assistive && (<Icon name={IconInfo} id="icon--info" />)}
      {success && (<Icon name={IconCheck} id="icon--check-circle" />)}

      <AlertMessage
        size={size}
        error={error}
        warning={warning}
        assistive={assistive}
        success={success}
      >
        {children}
      </AlertMessage>
    </div>
  )
}
Alert.propTypes = {
  size: PropTypes.oneOf(['s', 'm']),
  children: PropTypes.node,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  assistive: PropTypes.bool,
  success: PropTypes.bool,
}
