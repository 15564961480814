import instance from '../axios-api'

/**
 * Получаем истрою
 * @param {string} range
 * @param {string} address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const balanceHistory = (range, address) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post('/api/v2/monitor/proxy/history/', {
    range,
    address,
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}
