import React from 'react'
import PropTypes from 'prop-types'
import './icon.scss'

export const Icon = ({
  name,
  id,
  mod,
}) => (
  <svg className={`icon ${id} ${mod || ''}`}>
    <use xlinkHref={`${name}#${id}`} />
  </svg>
)

Icon.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  mod: PropTypes.string,
}
