import React from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody, BaseTextHeading } from '../../BaseText/BaseText'
import { Status } from '../../Status/Status'

const ModalBodyLoading = ({ textHeading, textBody }) => (
  <>
    <Status type="spinner" />

    <BaseTextHeading size="S">
      {textHeading}
    </BaseTextHeading>

    <BaseTextBody size="S">
      {textBody}
    </BaseTextBody>
  </>
)

ModalBodyLoading.propTypes = {
  textHeading: PropTypes.node,
  textBody: PropTypes.node,
}

export default ModalBodyLoading
