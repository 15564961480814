import React from 'react'
import PropTypes from 'prop-types'
import './asset-block.scss'
import { BaseTextBody, BaseTextSubheading } from '../BaseText/BaseText'

export const AssetBlock = ({
  title,
  bodyName,
  bodyValue,
  footerName,
  footerValue,
  footerLink,
}) => (
  <div className="asset-block">
    <div className="asset-block__top">
      <BaseTextBody size="S">
        {title}
      </BaseTextBody>
    </div>

    <div className="asset-block__body">
      <div className="asset-block__body-name">
        {bodyName}
      </div>

      {bodyValue
        && (
        <div className="asset-block__body-value">
          <BaseTextSubheading>
            {bodyValue}
          </BaseTextSubheading>
        </div>
        )}
    </div>

    <div className="asset-block__footer">
      {footerName
        && (
        <BaseTextBody size="S">
          {footerName}
        </BaseTextBody>
        )}

      {footerValue
        && (
        <BaseTextBody size="S">
          {footerValue}
        </BaseTextBody>
        )}

      {footerLink
        && footerLink}
    </div>
  </div>
)

AssetBlock.propTypes = {
  title: PropTypes.node,
  bodyName: PropTypes.node,
  bodyValue: PropTypes.node,
  footerName: PropTypes.node,
  footerValue: PropTypes.node,
  footerLink: PropTypes.node,
}
