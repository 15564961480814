import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import './dashboard-body.scss'
import { BigNumber } from 'bignumber.js'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { Chart } from './chart/Chart'
import { Chip } from '../../../../../../components/Chip/Chip'
// eslint-disable-next-line import/no-cycle
import { AssetsTable } from './assets-table/AssetsTable'
import {
  checkFloatNaN, ERC20ToFloat, formatEth, weiToEth,
} from '../../../../../../utils/helperFunctions'
import {
  getDataSelectWallet, getLpTokensPair, getOrdersList, getPoolLiquidity,
} from '../../../../../../service/api'
import {
  getNativeTokenDataBasedOnNetwork,
  getUserTokensSum,
  parseToken,
  sortArrayByAlphabet,
} from '../../../../../../parser/data'
import { useWeb3 } from '../../../../../../context/web3Provider'
import { useWeb3Bn } from '../../../../../../hooks/web3'
import { NEW_ETH_ADDRESS, ZERO_ADDRESS } from '../../../../../../const'
import { IconCurrency } from '../../../../../../components/IconCurrency/IconCurrency'
import { getImage } from '../../../../../../utils/images'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import { updateSelectedTokensPair } from '../../../../../../store/slice/trade'
import { toastCritical } from '../../../../../../components/Toast/Toast'

export const IsOnChangeReturnValueContext = React.createContext(true)

export const DashboardBody = ({
  selectedAddress,
  chips,
  areTokensLoading,
  selectedWallet,
  ...props
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const web3React = useWeb3()
  const Web3BN = useWeb3Bn()
  const userState = useSelector((store) => store.user)
  const [isTotalBalanceReady, setIsTotalBalanceReadyState] = useState(false)
  const [listIdle, setListIdle] = useState([])
  const [listLp, setListLp] = useState([])
  const [sumIdle, setSumIdle] = useState(0)
  const [sumLp, setSumLp] = useState(0)
  const [difPercent, setDifPercent] = useState(0)
  const [difSum, setDifSum] = useState(0)
  const selectedTokensPair = useSelector((store) => store.trade.selectedTokensPair)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [averageProfitLoss, setAverageProfitLoss] = useState({
    lp: { percent: 0, currency: 0 },
    idle: { percent: 0, currency: 0 },
  })

  const onUpdateTokensPair = useCallback(async ({ token1, token2 }) => {
    try {
      const pair = await getLpTokensPair({
        address1: web3React.library.utils.toChecksumAddress(token1.address),
        address2: web3React.library.utils.toChecksumAddress(token2.address),
      })
      if (pair) {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1: pair.token1,
            token2: pair.token2,
          },
          pairAddress: pair.address,
          isChartExist: true,
        }))
      } else {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1,
            token2,
          },
          isChartExist: false,
        }))
      }
    } catch (e) {
      toastCritical('Tokens pair update failed')
      console.error('Update tokens pair on assets click failed = ', e)
    }
  }, [web3React, dispatch])

  const onClickToken = useCallback((item) => {
    const isNativeToken = [NEW_ETH_ADDRESS, ZERO_ADDRESS].includes(item.address)
    const chosenToken = userState.tokens.filter((token) => (
      (!isNativeToken && token.contract_address.toLowerCase() === item.address.toLowerCase())
      || (isNativeToken && token.contract_ticker_symbol === item.symbol)
    ))[0]

    onUpdateTokensPair({
      token1: parseToken(chosenToken),
      token2: selectedTokensPair.token2,
    })
    history.push('/trade')
  }, [userState.tokens, onUpdateTokensPair, selectedTokensPair, history])

  const templateRow = useCallback(({
    onClick, address, asset, value, price, balance, orders, symbol, lp, profitLoss,
  }) => ({
    address,
    asset,
    profitLoss,
    value: (
      <BaseTextBody onClick={onClick}>
        $
        {checkFloatNaN(value, 0, 2)}
      </BaseTextBody>
    ),
    price: (
      <BaseTextBody onClick={onClick}>
        $
        {checkFloatNaN(price, 0, 2)}
      </BaseTextBody>
    ),
    balance: (
      <BaseTextBody onClick={onClick}>
        {checkFloatNaN(balance, 0, 5)}
        {' '}
        {symbol}
      </BaseTextBody>
    ),
    orders: (
      <BaseTextBody onClick={onClick}>
        {orders}
        {' '}
        {symbol}
      </BaseTextBody>
    ),
    lp,
    symbol,
  }), [])

  const getDataToken = useCallback(async (token) => {
    const decimals = token.decimals || token.contract_decimals
    const price = token.price || userState.latestNativeTokenPrice
    const erc20 = ERC20ToFloat(new Web3BN(token.balance.toString()), decimals, Web3BN)
    const value = formatEth(checkFloatNaN(formatEth(erc20 * price), 0), 2).toString()
    const symbol = token.symbol || token.contract_ticker_symbol
    const address = token.address || token.contract_address

    const { iconSrc } = getNativeTokenDataBasedOnNetwork()
    const { src } = await getImage(address, iconSrc)

    return {
      price, erc20, value, symbol, address, src, decimals,
    }
  }, [Web3BN, userState.latestNativeTokenPrice])

  const getListTokens = useCallback(() => {
    const list = selectedWallet.owner ? userState.externalTokens : userState.tokens

    if (!list) {
      return []
    }

    return list
  }, [selectedWallet.owner, userState.externalTokens, userState.tokens])

  const columnsData = useMemo(() => (
    [
      {
        name: 'Asset',
        selector: 'asset',
      },
      {
        name: 'Value',
        selector: 'value',
      },
      {
        name: 'Profit / Loss',
        selector: 'profitLoss',
      },
      {
        name: 'Price',
        selector: 'price',
      },
      {
        name: 'Balance',
        selector: 'balance',
      },
      {
        name: 'On Orders',
        selector: 'orders',
      },
    ]
  ), [])

  const assets = useMemo(() => ([
    {
      name: 'Idle Assets',
      value: `$${sumIdle}`,
      percent: 0,
      currency: 0,
      onRowClicked: (item) => {
        onClickToken(item)
      },
      columns: columnsData,
      data: listIdle,
      openAssets: true,
      ...averageProfitLoss.idle,
      dataTestid: 'dashboard--table-idle',
    },
    {
      name: 'Liquidity Pools',
      value: `$${sumLp}`,
      percent: 0,
      currency: 0,
      onRowClicked: (item) => {
        onClickToken(item, true, item.lp?.token1?.address, item.lp?.token2?.address)
      },
      columns: columnsData,
      data: listLp,
      ...averageProfitLoss.lp,
      dataTestid: 'dashboard--table-lp',
    },
    {
      name: 'Deposits',
      value: '$0',
      percent: 0,
      currency: 0,
      columns: columnsData,
      data: [],
      isDeposits: true,
      dataTestid: 'dashboard--table-deposits',
    },
  ]), [columnsData, listIdle, listLp, onClickToken, sumIdle, sumLp, averageProfitLoss])

  useEffect(() => {
    let isMounted = true

    const asyncFunc = async () => {
      if (userState.tokens && Array.isArray([userState.tokens]) && BigNumber && web3React.library) {
        const nowDate = new Date()
        const yesterday = nowDate.setDate(nowDate.getDate() - 1)
        const tokens = getListTokens()

        const objTodaySum = getUserTokensSum({
          BN: Web3BN,
          tokens,
          web3React,
          latestNativeTokenPrice: userState.latestNativeTokenPrice,
        })
        const yesterdayDataBalanceTokens = await getDataSelectWallet(selectedAddress, undefined, yesterday)
        const objYesterdaySum = getUserTokensSum({
          BN: Web3BN,
          tokens: yesterdayDataBalanceTokens.tokens,
          web3React,
        })

        const yesterdaySum = objYesterdaySum.sumLp + objYesterdaySum.sumIdle + objYesterdaySum.sumEth
        const todaySum = objTodaySum.sumLp + objTodaySum.sumIdle + objTodaySum.sumEth

        const localSumDif = (todaySum * 100) / yesterdaySum
        const localPercentDif = checkFloatNaN(localSumDif - 100, 0, 3)
        const newDifSum = checkFloatNaN(todaySum - yesterdaySum, 0, 3)

        if (isMounted) {
          setSumIdle(formatEth(objTodaySum.sumIdle + objTodaySum.sumEth, 2))
          setSumLp(formatEth(objTodaySum.sumLp, 2))
          setDifSum(newDifSum)
          setDifPercent(localPercentDif)
        }
        setIsTotalBalanceReadyState(true)
      }
    }

    asyncFunc()

    return () => { isMounted = false }
  }, [
    selectedAddress,
    web3React,
    Web3BN,
    userState.tokens,
    userState.latestNativeTokenPrice,
    getListTokens,
  ])

  useEffect(() => {
    let isMounted = true

    const asyncFunc = async () => {
      const newListIdle = []
      const newListLp = []
      const totalProfitLoss = {
        lp: {
          percent: 0,
          currency: 0,
          count: 0,
        },
        idle: {
          percent: 0,
          currency: 0,
          count: 0,
        },
      }

      if (web3React.library && !areTokensLoading) {
        const getProfitLoss = (returnPercents, returnFloat) => (
          <BaseTextBody
            success={returnPercents >= 0 && returnFloat >= 0}
            error={returnPercents < 0 || returnFloat < 0}
          >
            {`${returnPercents}%`}

            <span>{` ($${returnFloat})`}</span>
          </BaseTextBody>
        )

        const tokens = getListTokens()

        try {
          const arrPromise = tokens.map(async (token) => {
            const {
              price, erc20, value, symbol, address, src, decimals,
            } = await getDataToken(token)

            const contractAddress = token.contract_address.toLowerCase()
            const tokenPnlInfo = await getPoolLiquidity({
              address,
              internalAddress: internalSelectedAddress,
              isShouldParse: true,
            })

            if (
              (contractAddress === ZERO_ADDRESS || contractAddress === NEW_ETH_ADDRESS)
              && token.balance !== '0'
            ) {
              let onOrders = 0

              totalProfitLoss.idle.count += 1
              totalProfitLoss.idle.percent += tokenPnlInfo.returnPercents
              totalProfitLoss.idle.currency += tokenPnlInfo.returnFloat

              if (selectedWallet.internal) {
                const getOrders = await getOrdersList({
                  cyberWallet: internalSelectedAddress,
                  address1: ZERO_ADDRESS,
                  limit: 0,
                  page: 0,
                  ordering: '',
                })
                getOrders.rows.forEach((row) => {
                  onOrders += +(weiToEth(web3React.library.utils.fromWei, row.modal.token1.value.toString()))
                })
              }
              const toEth = weiToEth(web3React.library.utils.fromWei, token.balance.toString())
              const totalPrice = formatEth(erc20 * userState.latestNativeTokenPrice)

              const { tokenSymbol, iconSrc } = getNativeTokenDataBasedOnNetwork()

              newListIdle.push(templateRow({
                onClick: () => onClickToken({ address: NEW_ETH_ADDRESS }),
                address: NEW_ETH_ADDRESS,
                profitLoss: getProfitLoss(tokenPnlInfo.returnPercents, tokenPnlInfo.returnFloat),
                asset: (
                  <div onClick={() => onClickToken({ address: NEW_ETH_ADDRESS })}>
                    <IconCurrency img={iconSrc} imgAlt={tokenSymbol} text={tokenSymbol} />
                  </div>
                ),
                value: formatEth(checkFloatNaN(totalPrice, 0), 2).toString(),
                price: userState.latestNativeTokenPrice,
                balance: toEth,
                orders: formatEth(onOrders),
                symbol: tokenSymbol,
              }))
            } else if (symbol === 'UNI-V2') {
              const name = `${token.token1.name}/${token.token2.name}`

              totalProfitLoss.lp.count += 1
              totalProfitLoss.lp.percent += tokenPnlInfo.returnPercents
              totalProfitLoss.lp.currency += tokenPnlInfo.returnFloat

              newListLp.push(templateRow({
                onClick: () => onClickToken({ address }, true, token.token1.address, token.token2.address),
                address,
                profitLoss: getProfitLoss(tokenPnlInfo.returnPercents, tokenPnlInfo.returnFloat),
                asset: (
                  <div onClick={() => onClickToken({ address }, true, token.token1.address, token.token2.address)}>
                    <IconCurrency
                      img={src}
                      imgAlt={name}
                      text={name}
                    />
                  </div>
                ),
                value,
                price,
                balance: erc20,
                orders: 0,
                symbol,
                lp: { token1: { ...token.token1 }, token2: { ...token.token2 } },
              }))
            } else {
              // eslint-disable-next-line
              if (address !== NEW_ETH_ADDRESS) {
                let onOrders = 0

                totalProfitLoss.idle.count += 1
                totalProfitLoss.idle.percent += tokenPnlInfo.returnPercents
                totalProfitLoss.idle.currency += tokenPnlInfo.returnFloat

                if (selectedWallet.internal) {
                  const getOrders = await getOrdersList({
                    cyberWallet: internalSelectedAddress,
                    address1: address,
                    limit: 0,
                    page: 0,
                    ordering: '',
                  })

                  getOrders.rows.forEach((row) => {
                    if (row.modal.token1.address.toLowerCase() === address.toLowerCase()) {
                      onOrders += +(ERC20ToFloat(new Web3BN(row.modal.token1.value.toString()), decimals, Web3BN))
                    }
                  })
                }
                newListIdle.push(templateRow({
                  onClick: () => onClickToken({ address }),
                  address,
                  profitLoss: getProfitLoss(tokenPnlInfo.returnPercents, tokenPnlInfo.returnFloat),
                  asset: (
                    <div onClick={() => onClickToken({ address })}>
                      <IconCurrency
                        img={src}
                        imgAlt={symbol}
                        text={symbol}
                      />
                    </div>
                  ),
                  value,
                  price,
                  balance: erc20,
                  orders: formatEth(onOrders),
                  symbol,
                }))
              }
            }
          })

          await Promise.all(arrPromise)

          if (isMounted) {
            newListIdle.sort((item1, item2) => sortArrayByAlphabet({ item1, item2, prop: 'symbol' }))
            setListIdle(newListIdle)
            newListLp.sort((item1, item2) => sortArrayByAlphabet({ item1, item2, prop: 'symbol' }))
            setListLp(newListLp)
            setAverageProfitLoss({
              idle: {
                percent: checkFloatNaN(totalProfitLoss.idle.percent / totalProfitLoss.idle.count, 0, 3),
                currency: checkFloatNaN(totalProfitLoss.idle.currency / totalProfitLoss.idle.count, 0, 3),
              },
              lp: {
                percent: checkFloatNaN(totalProfitLoss.lp.percent / totalProfitLoss.lp.count, 0, 3),
                currency: checkFloatNaN(totalProfitLoss.lp.currency / totalProfitLoss.lp.count, 0, 3),
              },
            })
          }
        } catch (e) {
          console.log('e = ', e.response)
        }
      }
    }

    asyncFunc()

    return () => { isMounted = false }
  }, [
    Web3BN,
    internalSelectedAddress,
    web3React,
    dispatch,
    history,
    userState,
    onClickToken,
    templateRow,
    getDataToken,
    selectedWallet,
    getListTokens,
    areTokensLoading,
  ])

  useEffect(() => {
    setIsTotalBalanceReadyState(false)
  }, [selectedAddress])

  return (
    <div className="dashboard-body" {...props}>
      <div className="dashboard-body__currency">
        {chips.map((item, key) => (
          <Chip
            onClick={item.onClick}
            icon={item.icon}
            iconPosition="left"
            size="L"
            outline
            key={key}
          >
            {item.text}
            <span>{item.value}</span>
          </Chip>
        ))}
      </div>

      <div className="dashboard-body__chart">
        <Chart
          isTotalBalanceReady={isTotalBalanceReady}
          title="Net Worth"
          value={`$${checkFloatNaN(formatEth(sumIdle + sumLp, 2), 0)}`}
          subvalue={difSum}
          subvaluePercent={difPercent}
          pieChartItems={[
            { number: 0, text: 'Deposits', color: '#81F17E' },
            { number: checkFloatNaN(sumLp, 0), text: 'Liquidity Pools', color: '#44B491' },
            { number: checkFloatNaN(sumIdle, 0), text: 'Purse', color: '#FFF200' },
          ]}
        />
      </div>

      <IsOnChangeReturnValueContext.Provider value>
        <div className="dashboard-body__assets">
          {assets.map((item, key) => (
            <div data-testid={item.dataTestid} className="dashboard-body__asset-item" key={key}>
              <AssetsTable
                isDeposits={item.isDeposits}
                name={item.name}
                value={item.value}
                pnl={{
                  percent: item.percent,
                  currency: item.currency,
                }}
                onRowClicked={item.onRowClicked}
                table={{
                  columns: item.columns,
                  data: item.data,
                }}
                openAssets={item.openAssets}
                areTokensLoading={areTokensLoading}
              />
            </div>
          ))}
        </div>
      </IsOnChangeReturnValueContext.Provider>
    </div>
  )
}

DashboardBody.defaultProps = {
  chips: [],
}

DashboardBody.propTypes = {
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      onRowClicked: PropTypes.func,
      icon: PropTypes.element,
      text: PropTypes.string,
    }),
  ),
  selectedWallet: PropTypes.shape({
    owner: PropTypes.bool,
    internal: PropTypes.bool,
  }),
  areTokensLoading: PropTypes.bool,
  selectedAddress: PropTypes.string,
}
