import React, {
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useLocalStorage } from '@rehooks/local-storage'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import { ClipboardTooltip } from '../../../../../../components/ClipboardTooltip/ClipboardTooltip'
import { Icon } from '../../../../../../components/Icon/Icon'
import IconAccountBalanceWallet from '../../../../../../components/Icon/icon--account-balance-wallet.svg'
import IconFileCopy from '../../../../../../components/Icon/icon--file-copy.svg'
import IconOpenInNew from '../../../../../../components/Icon/icon--open-in-new.svg'
import IconTwitter from '../../../../../../components/Icon/icon--twitter.svg'
import IconPictogram from '../../../../../../assets/images/pictogram.svg'
import DepositAssetsModal from '../../deposit-assets-modal/DepositAssetsModal'
import ProgressBar from './progress-bar/ProgressBar'
import AddressesList from './addresses-list/AddressesList'
import { getLinkBasedOnCurrentNetwork, getLinkTitleBasedOnCurrentNetwork } from '../../../../../../parser/data'

import ClickOutside from '../../../../../../components/ClickOutside/ClickOutside'

import './owner-wallet-account.scss'
import Quests from './quests/Quests'
import Rewards from './rewards/Rewards'
import { getIconSelectedProvider } from '../../../../../../utils/images'
import { getFeatureFlag } from '../../../../../../utils/feature-flags'

const OwnerWalletAccount = ({
  ownerAccounts,
  isOwnerAdressesListOpened,
  onToggleAddessesListOpenedState,
}) => {
  const [selectedOwnerWallet] = useLocalStorage('selectedOwnerWallet')
  const [isDepositModalShown, setDepositModalShownState] = useState(false)
  const [progressValue] = useState(19)
  const [level] = useState(94)
  const [curExperienceValue] = useState(1000)
  const [maxExperienceValue] = useState(2500)
  const [isFeatureHidden] = useState(true)
  const isQuestsAndRewards = getFeatureFlag('isQuestsAndRewards')

  const cutAddress = (address) => `${(address || '').substr(0, 6)}...${(address || '').substr(-5)}`

  return (
    <ClickOutside callback={() => {
      if (isOwnerAdressesListOpened) {
        onToggleAddessesListOpenedState()
      }
    }}
    >
      <div className="owner-wallet__account-container">
        <div
          className="main-block"
          onClick={onToggleAddessesListOpenedState}
        >
          <div className="header-controls-block">
            <div className="owner-wallet__address-block">
              <img className="owner-avatar" src={getIconSelectedProvider()} alt="metamask" />
              <BaseTextBody upperCase>
                {cutAddress(selectedOwnerWallet)}
              </BaseTextBody>
            </div>

            <div className="controls-block" onClick={(e) => e.stopPropagation()}>
              <ClipboardTooltip tooltipText="Deposit">
                <span onClick={() => setDepositModalShownState(true)}>
                  <Icon
                    mod="icon--14"
                    name={IconAccountBalanceWallet}
                    id="icon--account-balance-wallet"
                  />
                </span>
              </ClipboardTooltip>
              <ClipboardTooltip tooltipText="Copy address" tooltipTextFocus="Copied!">
                <CopyToClipboard text={selectedOwnerWallet}>
                  <span>
                    <Icon
                      mod="icon--14"
                      name={IconFileCopy}
                      id="icon--file-copy"
                    />
                  </span>
                </CopyToClipboard>
              </ClipboardTooltip>
              <ClipboardTooltip tooltipText={`View in ${getLinkTitleBasedOnCurrentNetwork()}`}>
                <span onClick={() => window.open(`https://${getLinkBasedOnCurrentNetwork()}/address/${selectedOwnerWallet}`, '_blank')}>
                  <Icon
                    mod="icon--14"
                    name={IconOpenInNew}
                    id="icon--open-in-new"
                  />
                </span>
              </ClipboardTooltip>
            </div>
          </div>

          {!isFeatureHidden && (
            <div className="body-block">
              <ProgressBar
                progressValue={progressValue}
                level={level}
                curExperienceValue={curExperienceValue}
                maxExperienceValue={maxExperienceValue}
              />
              <div className="selectors" />
            </div>
          )}

          {isQuestsAndRewards && (
            <div className="owner-wallet-account-bottom">
              <div className="owner-wallet-account-bottom__item">
                <Rewards
                  items={[
                    {
                      title: 'example1@gmail.com example1@gmail.com example1@gmail.com example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                      active: true,
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                    {
                      title: 'example1@gmail.com',
                      icon: <Icon name={IconTwitter} id="icon--twitter" />,
                      level: '999',
                      exp: '999,999 / 999,999',
                      quests: '999',
                    },
                  ]}
                />
              </div>

              <div className="owner-wallet-account-bottom__item">
                <Quests
                  data={[
                    {
                      title: 'Daily Quests',
                      items: [
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective Quest objective Quest objective Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                      ],
                    },
                    {
                      divider: true,
                    },
                    {
                      title: 'Weekly Quests',
                      items: [
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                        {
                          icon: <Icon name={IconPictogram} id="icon--pictogram" />,
                          title: 'Quest objective',
                          exp: '99999',
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          )}
        </div>

        {isOwnerAdressesListOpened && (
          <AddressesList
            addresses={ownerAccounts}
            onConnectNewOwnerWallet={() => console.log('connect')}
            onDeposit={() => {
              setDepositModalShownState(true)
            }}
          />
        )}

        {isDepositModalShown && (
          <DepositAssetsModal
            chosenWallet={selectedOwnerWallet}
            onCloseModal={() => setDepositModalShownState(false)}
          />
        )}
      </div>
    </ClickOutside>
  )
}

OwnerWalletAccount.propTypes = {
  ownerAccounts: PropTypes.array,
  isOwnerAdressesListOpened: PropTypes.bool,
  onToggleAddessesListOpenedState: PropTypes.func,
}

export default OwnerWalletAccount
