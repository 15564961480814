import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import './base-search.scss'
import { Icon } from '../Icon/Icon'
import iconBaseSearch from '../Icon/icon--base-search.svg'

export const BaseSearch = ({
  placeholder,
  onChange,
  clearInput,
  dataTestid,
  inputValue,
}) => {
  useEffect(() => {
    if (clearInput) {
      onChange('')
    }
  }, [clearInput, onChange])

  return (
    <div className="base-search">
      <Icon name={iconBaseSearch} id="icon--base-search" />

      <input
        data-testid={dataTestid}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={inputValue}
        type="text"
        placeholder={placeholder}
        className="base-search__input"
      />
    </div>
  )
}

BaseSearch.propTypes = {
  placeholder: PropTypes.string,
  dataTestid: PropTypes.string,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  clearInput: PropTypes.bool,
}
