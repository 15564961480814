import React, {
  useEffect, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import { writeStorage } from '@rehooks/local-storage'
import QRCode from 'react-qr-code'
import { LIST_WEB3_PROVIDER } from '../../../../const/web3'
import { useWeb3 } from '../../../../context/web3Provider'
import { LIST_CONNECTOR_PROVIDER } from '../../../../service/web3'
import { BaseModal } from '../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../components/BaseButton/BaseButton'
import { BaseTextHeading } from '../../../../components/BaseText/BaseText'
import IconFileCopy from '../../../../components/Icon/icon--file-copy.svg'
import { Icon } from '../../../../components/Icon/Icon'

const ConnectWalletConnectModal = ({
  onUserSuccessfullyConected,
}) => {
  const web3React = useWeb3()
  const [qrCode, setQrCode] = useState()

  const getModalBody = useMemo(() => (
    <>
      <div>
        {qrCode && (
        <QRCode value={qrCode} />
        )}
      </div>
      <BaseTextHeading size="S">
        Scan QR code with a WalletConnect-compatible wallet
      </BaseTextHeading>
      <div className="sign-in-modal__controls-block">
        <BaseButton
          variant="primary"
          onClick={() => navigator.clipboard.writeText(qrCode)}
        >
          <div className="sign-in-modal__controls-block__copy">
            <span>Copy to clipboard</span>

            <div className="sign-in-modal__controls-block__copy__icon-copy">
              <Icon
                mod="icon--14"
                name={IconFileCopy}
                id="icon--file-copy"
              />
            </div>
          </div>
        </BaseButton>
      </div>
    </>
  ), [qrCode])

  useEffect(() => {
    (async () => {
      writeStorage('selectedProvider', LIST_WEB3_PROVIDER.walletConnect)
      const providerWalletConnect = LIST_CONNECTOR_PROVIDER[LIST_WEB3_PROVIDER.walletConnect]
      providerWalletConnect.connector.on('display_uri', (err, payload) => {
        setQrCode(payload.params[0])
      })

      try {
        await web3React.activate({
          provider: providerWalletConnect,
          onConnect: providerWalletConnect.enable,
        })

        onUserSuccessfullyConected()
      } catch (e) {
        console.log('Wallet connect authentication failed: ', e)
      }
    })()
  }, [web3React, onUserSuccessfullyConected])

  return (
    <BaseModal
      title="Sign In With WalletConnect"
    >
      {getModalBody}
    </BaseModal>

  )
}

ConnectWalletConnectModal.propTypes = {
  onUserSuccessfullyConected: PropTypes.func,
}

export default ConnectWalletConnectModal
