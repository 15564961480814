import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import './trade-pair-info.scss'
import { AssetPnl } from '../AssetPnl/AssetPnl'
import { TradingViewChart } from '../TradingViewChart/TradingViewChart'
import { SelectCurrencyChart } from '../SelectCurrencyChart/SelectCurrencyChart'
import { SelectBasePropTypeItems } from '../SelectBase/SelectBase'
import Loading from '../Loading/Loading'
import { BaseTextBody } from '../BaseText/BaseText'

export const TradePairInfo = ({
  assetPnlChips,
  assetPnlBalance,
  assetPnlPrice,
  assetPnlBuyButton,
  assetPnlItems,
  tradingChartCurrencyItems,
  infinityScrollHasMore,
  funcInfinityScroll,
  currencyItems,
  callback = () => {},
  onChange = () => {},
  isLoadingDropDown,
  isLoadingComponent,
  selectedValue,
  fullNamePair,
  dropDownItems,
  funcLoadItems = () => {},
  firstTokenAddress,
  secondTokenAddress,
  onBlurInputSelectCurrencyChart,
}) => {
  const clsContainer = useMemo(() => {
    let cls = 'trade-pair-info'

    if (isLoadingComponent) {
      cls = `${cls} trade-pair-info__loading`
    }

    return cls
  }, [isLoadingComponent])

  return (
    <div className={clsContainer}>
      {isLoadingComponent ? (
        <Loading />
      )
        : (
          <>
            <div className="trade-pair-info__top">
              <SelectCurrencyChart
                onBlurInput={onBlurInputSelectCurrencyChart}
                firstTokenAddress={firstTokenAddress}
                secondTokenAddress={secondTokenAddress}
                infinityScrollHasMore={infinityScrollHasMore}
                funcInfinityScroll={funcInfinityScroll}
                selectedValue={selectedValue !== null ? selectedValue : currencyItems[0].value}
                isLoading={isLoadingDropDown}
                items={dropDownItems}
                onChange={onChange}
                callback={callback}
                funcLoadItems={funcLoadItems}
              />

              <div className="trade-pair-info__full-name-pair">
                <BaseTextBody secondary>{fullNamePair}</BaseTextBody>
              </div>
            </div>

            <div className="trade-pair-info__chart">
              <TradingViewChart currencyItems={tradingChartCurrencyItems} />
            </div>

            <div className="trade-pair-info__bottom">
              <AssetPnl
                chips={assetPnlChips}
                balance={assetPnlBalance}
                price={assetPnlPrice}
                buyButton={assetPnlBuyButton}
                items={assetPnlItems}
              />
            </div>
          </>
        )}
    </div>
  )
}

TradePairInfo.defaultProps = {
  assetPnlChips: [],
  assetPnlBalance: '',
  assetPnlPrice: '',
  assetPnlBuyButton: '',
  tradingChartCurrencyItems: [],
  assetPnlItems: [],
}

TradePairInfo.propTypes = {
  assetPnlChips: PropTypes.array,
  assetPnlBalance: PropTypes.node,
  assetPnlPrice: PropTypes.node,
  assetPnlBuyButton: PropTypes.node,
  fullNamePair: PropTypes.node,
  tradingChartCurrencyItems: PropTypes.array,
  assetPnlItems: PropTypes.array,
  funcInfinityScroll: PropTypes.func,
  infinityScrollHasMore: PropTypes.bool,
  dropDownItems: PropTypes.array,
  selectedValue: PropTypes.string,
  firstTokenAddress: PropTypes.string,
  secondTokenAddress: PropTypes.string,
  currencyItems: SelectBasePropTypeItems,
  callback: PropTypes.func,
  onChange: PropTypes.func,
  funcLoadItems: PropTypes.func,
  onBlurInputSelectCurrencyChart: PropTypes.func,
  isLoadingDropDown: PropTypes.bool,
  isLoadingComponent: PropTypes.bool,
}
