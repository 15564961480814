import { BigNumber } from 'bignumber.js'
import {
  NEW_ETH_ADDRESS,
  ZERO_ADDRESS,
  WRAPPED_ETH_ADDRESS,
  WRAPPED_BNB_ADDRESS,
  WRAPPED_MATIC_ADDRESS,
  NATIVE_ETH_TOKEN,
  NATIVE_BSC_TOKEN,
  NATIVE_POLYGON_TOKEN,
} from '../const'
// eslint-disable-next-line import/no-cycle
import {
  weiToEth,
  formatEth,
  ERC20ToFloat,
  checkFloatNaN,
  floatToERC20,
} from '../utils/helperFunctions'
import Img32Eth from '../components/Table/img-32-eth.png'
import IconBsc from '../components/Icon/icon--bsc.png'
import IconPolygon from '../components/Icon/icon--polygon.png'
import config from '../config'
import LevelsItemImg01 from '../pages/main-container/pages/loyalty/levels-functionality/levels-item/LevelsItemImg01.png'
import LevelsItemImg02 from '../pages/main-container/pages/loyalty/levels-functionality/levels-item/LevelsItemImg02.png'
import LevelsItemImg03 from '../pages/main-container/pages/loyalty/levels-functionality/levels-item/LevelsItemImg03.png'

export const parseOrderStatus = (statusId) => {
  const orderStatusesMap = {
    Failed: 'ORDER_FAILED',
    Executing: 'ORDER_EXECUTING',
    Executed: 'ORDER_EXECUTED',
    Active: 'ORDER_ACTIVE',
  }

  return orderStatusesMap[statusId]
}

export const parseTransactionStatus = (statusId) => {
  const transactionStatusesMap = [
    'TRX_NOT_SENT',
    'TRX_EXECUTED',
    'TRX_CONFIRMED',
    'TRX_FAILED',
    'TRX_CANCELLED',
  ]

  return transactionStatusesMap[statusId]
}

export const getUserTokensSum = ({
  tokens = [],
  BN,
  web3React,
  latestNativeTokenPrice,
}) => {
  let totalSum = 0
  let sumLp = 0
  let sumIdle = 0
  let sumEth = 0

  tokens.forEach((token) => {
    let totalPrice = 0
    const symbol = token.symbol ? token.symbol : token.contract_ticker_symbol
    const contractAddress = token.contract_address.toLowerCase()

    if (contractAddress !== NEW_ETH_ADDRESS && contractAddress !== ZERO_ADDRESS) {
      const decimals = token.contract_decimals ? token.contract_decimals : token.decimals
      const erc20 = ERC20ToFloat(new BN(token.balance.toString()), decimals, BN)
      totalPrice = formatEth(erc20 * token.price)

      if (Number.isNaN(totalPrice)) {
        totalPrice = 0
      }

      if (symbol === 'UNI-V2') {
        sumLp += totalPrice
      } else {
        sumIdle += totalPrice
      }
    } else {
      const toEth = weiToEth(web3React?.library?.utils.fromWei, token.balance.toString())
      const priceEth = latestNativeTokenPrice || token.price

      totalPrice = formatEth(parseFloat(toEth) * parseFloat(priceEth))
      if (Number.isNaN(totalPrice)) {
        totalPrice = 0
      }

      sumEth += totalPrice
    }

    totalSum += totalPrice
  })

  return {
    totalSum, sumLp, sumIdle, sumEth,
  }
}

export const getLinkBasedOnCurrentNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  const etherScannerLink = 'etherscan.io'

  switch (selectedChain) {
    case 'Ethereum':
      return etherScannerLink

    case 'BSC':
      return 'bscscan.com'

    case 'Polygon':
      return 'polygonscan.com'

    default:
      return etherScannerLink
  }
}

export const getLinkTitleBasedOnCurrentNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  const etherScannerLink = 'Etherscan'

  switch (selectedChain) {
    case 'Ethereum':
      return etherScannerLink

    case 'BSC':
      return 'BscScan'

    case 'Polygon':
      return 'Polygonscan'

    default:
      return etherScannerLink
  }
}

export const multiplyBigNumber = (value) => {
  const tokenBalance = new BigNumber(value)
  const pow = new BigNumber(10 ** 18)
  return tokenBalance.multipliedBy(pow).toString()
}

export const sortArrayByAlphabet = ({
  item1, item2, prop, additionalProp,
}) => {
  const value1 = (item1[prop] || item1[additionalProp]).toUpperCase()
  const value2 = (item2[prop] || item2[additionalProp]).toUpperCase()

  let comparison = 0
  if (value1 > value2) {
    comparison = 1
  } else if (value1 < value2) {
    comparison = -1
  }
  return comparison
}

// we need to display initial token and it's LP pair for the chart
// there is no API where we can get a list of all possible LP tokens
// it can be got by a token address only
export const getInitialTokenAndLpPairBasedOnNetwork = (chainName) => {
  const selectedChain = chainName || localStorage.getItem('selectedChain')
  let details = {
    currentAsset: '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4',
    chartToken: '0x36938d1419b717c97ebdb273702806ca73f89a4c',
  }

  if (selectedChain === 'BSC') {
    details = {
      currentAsset: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      chartToken: '0x804678fa97d91B974ec2af3c843270886528a9E6',
    }
  } else if (selectedChain === 'Polygon') {
    details = {
      currentAsset: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
      chartToken: '0xdC9232E2Df177d7a12FdFf6EcBAb114E2231198D',
    }
  }

  return details
}

export const getFormatDataLpToken = (data) => (
  {
    address: data.address,
    link: `https://${getLinkBasedOnCurrentNetwork()}/token/${data.address}`,
    token0: {
      address: data.token1.address,
      symbol: data.token1.symbol,
    },
    token1: {
      address: data.token2.address,
      symbol: data.token2.symbol,
    },
  }
)

// FOR NEW TRADE

// we need to display initial token and it's LP pair for the chart
// there is no API where we can get a list of all possible LP tokens
// it can be got by a token address only
export const getInitialTokensPairBasedOnNetwork = (chainName) => {
  const selectedChain = chainName || localStorage.getItem('selectedChain')
  let details = {
    selectedTokensPair: {
      token1: {
        address: '0x63b4f3e3fa4e438698CE330e365E831F7cCD1eF4',
        icon: `${config.apiURL}/token/images/0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4.png`,
        name: 'CyberFi Token',
        symbol: 'CFi',
      },
      token2: {
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        icon: `${config.apiURL}/token/images/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png`,
        name: 'Wrapped Ether',
        symbol: 'WETH',
      },
    },
    pairAddress: '0x36938d1419b717C97EBDB273702806CA73f89a4C',
    isChartExist: true,
  }

  if (selectedChain === 'BSC') {
    details = {
      selectedTokensPair: {
        token1: {
          address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
          icon: `${config.apiURL}/token/images/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.png`,
          name: 'PancakeSwap Token',
          symbol: 'Cake',
        },
        token2: {
          address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
          icon: `${config.apiURL}/token/images/0xe9e7cea3dedca5984780bafc599bd69add087d56.png`,
          name: 'BUSD Token',
          symbol: 'BUSD',
        },
      },
      isChartExist: true,
    }
  } else if (selectedChain === 'Polygon') {
    details = {
      selectedTokensPair: {
        token1: {
          address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
          // we don't have existing icons for any tokens from polygon on our server yet
          icon: `${config.apiURL}/static/media/not-found.svg`,
          name: '(PoS) Wrapped BTC',
          symbol: 'WBTC',
        },
        token2: {
          address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
          // we don't have existing icons for any tokens from polygon on our server yet
          icon: `${config.apiURL}/static/media/not-found.svg`,
          name: 'Wrapped Ether',
          symbol: 'WETH',
        },
      },
      isChartExist: true,
    }
  }

  return details
}

export const getImage = (src, address) => {
  const selectedChain = localStorage.getItem('selectedChain')
  let staticImg = '/static/media/not-found.svg'

  if ([ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(address)) {
    staticImg = '/static/media/icon--ethereum.891e0eca.svg'

    if (selectedChain === 'BSC') {
      return IconBsc
    }
    if (selectedChain === 'Polygon') {
      return IconPolygon
    }
  }

  if (src && src.indexOf('covalenthq') > -1) {
    return src
  }

  if (/^https?:\/\//.test(src)) {
    return src
  }

  return `${config.apiURL}${src && src.length > 0 ? `/${src}` : staticImg}`
}

export const parseToken = (token) => {
  const address = token.address || token.contract_address
  return {
    address,
    icon: getImage(token.image_url || token.logo_url, address),
    symbol: token.symbol || token.contract_ticker_symbol,
    name: token.name || token.contract_name,
  }
}

export const getParsedTokenBalance = (exactBalance) => {
  const decimalsPart = exactBalance.split('.')[1]
  const numberOfIntegersAfterDecimal = decimalsPart ? decimalsPart.length : 0
  return numberOfIntegersAfterDecimal < 6 ? exactBalance : checkFloatNaN(exactBalance, 0, 6)
}

export const getParsedTokenValue = (exactValue) => {
  const decimalsPart = exactValue.split('.')[1]
  const numberOfIntegersAfterDecimal = decimalsPart ? decimalsPart.length : 0
  if (exactValue <= 1) {
    return numberOfIntegersAfterDecimal > 5 ? `${checkFloatNaN(exactValue, 0, 5)}` : exactValue
  }
  return numberOfIntegersAfterDecimal > 4 ? `${checkFloatNaN(exactValue, 0, 4)}` : exactValue
}

export const getInitialTokenValue = (exactBalance) => {
  if (exactBalance > 1) {
    return '1'
  }

  if (exactBalance > 10000000) {
    return exactBalance
  }

  if (exactBalance > 0 && exactBalance <= 1) {
    return exactBalance
  }
  return exactBalance
}

export const convertGasToUsd = (gasFromStation, latestNativeTokenPrice) => {
  const gasInGwei = gasFromStation / 10
  const gasInEth = gasInGwei / 1000000000
  return latestNativeTokenPrice * gasInEth
}

export const prepareDataForOrderCreation = ({
  web3React,
  transactions,
  triggers,
  tokenValue,
  firstToken,
  secondToken,
}) => ({
  external_address: web3React.library.utils.toChecksumAddress(web3React.account),
  transactions,
  triggers,
  asset0: web3React.library.utils.toChecksumAddress(firstToken.address !== NEW_ETH_ADDRESS ? firstToken.address : ZERO_ADDRESS),
  asset0_value:
    firstToken.address === ZERO_ADDRESS || firstToken.address === NEW_ETH_ADDRESS
      ? multiplyBigNumber(tokenValue)
      : floatToERC20(firstToken.decimals, tokenValue),
  asset1: web3React.library.utils.toChecksumAddress(secondToken.address),
})

export const parseNativeTokenData = (data, latestNativeTokenPrice) => ({
  address: data.address,
  decimals: 18,
  name: data.name || data.contract_name,
  symbol: data.symbol || data.contract_ticker_symbol,
  usdtPrice: latestNativeTokenPrice,
  icon: data.icon,
  link: `https://${getLinkBasedOnCurrentNetwork()}/token/${data.address}`,
  isLinkHidden: true,
})

export const getPureAddress = (address) => (
  address !== ZERO_ADDRESS ? address : NEW_ETH_ADDRESS
)

export const getErrorMessageDetailsForOrderCreationBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  if (selectedChain === 'BSC') {
    return { title: 'Binance Smart Chain', symbol: 'BNB' }
  }

  if (selectedChain === 'Polygon') {
    return { title: 'Polygon', symbol: 'MATIC' }
  }

  return { title: 'Ethereum', symbol: 'ETH' }
}

export const getSymbolBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  if (selectedChain === 'BSC') {
    return 'BNB'
  }

  if (selectedChain === 'Polygon') {
    return 'MATIC'
  }

  return 'ETH'
}

export const getDefaultTokenBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  let name = 'Ether'
  if (selectedChain === 'BSC') {
    name = 'Binance Coin'
  } else if (selectedChain === 'Polygon') {
    name = 'Polygon'
  }

  return {
    address: NEW_ETH_ADDRESS,
    icon: getImage('', NEW_ETH_ADDRESS),
    symbol: getSymbolBasedOnNetwork(),
    name,
  }
}

export const getNativeTokenDataBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')

  let tokenSymbol = 'ETH'
  let iconSrc = Img32Eth
  if (selectedChain === 'BSC') {
    tokenSymbol = 'BNB'
    iconSrc = IconBsc
  } else if (selectedChain === 'Polygon') {
    tokenSymbol = 'MATIC'
    iconSrc = IconPolygon
  }

  return {
    tokenSymbol,
    iconSrc,
  }
}

export const getGasPriceBasedOnNetwork = (gas) => {
  const selectedChain = localStorage.getItem('selectedChain')
  let currentGas = gas

  if (selectedChain === 'BSC') {
    currentGas = 100
  }
  if (selectedChain === 'Polygon') {
    currentGas = 400
  }

  return (parseFloat(currentGas) / 10) * (10 ** 9) * 1.5
}

export const getChainNameBasedOnId = (chainId) => {
  if (chainId === '0x38' || chainId === '56' || chainId === 56) {
    return 'BSC'
  }

  if (chainId === '0x89' || chainId === '137' || chainId === 137) {
    return 'Polygon'
  }

  return 'Ethereum'
}

export const getWrappedNativeTokenAddressBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')

  if (selectedChain === 'BSC') {
    return WRAPPED_BNB_ADDRESS
  }
  if (selectedChain === 'Polygon') {
    return WRAPPED_MATIC_ADDRESS
  }

  return WRAPPED_ETH_ADDRESS
}

export const getUpdateIntervalTimeForOrdersBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  return selectedChain === 'BSC' || selectedChain === 'Polygon' ? 2000 : 10000
}

export const getNativeTokenDataBasedOnNetworkWithZeroBalance = (currentPrice) => {
  const selectedChain = localStorage.getItem('selectedChain')
  let nativeToken = NATIVE_ETH_TOKEN

  if (selectedChain === 'BSC') {
    nativeToken = NATIVE_BSC_TOKEN
  } else if (selectedChain === 'Polygon') {
    nativeToken = NATIVE_POLYGON_TOKEN
  }

  return {
    ...nativeToken,
    price: currentPrice,
  }
}

export const getLoyaltyUserLevels = () => ({
  title: 'User levels',
  description: 'Description -  сколько надо застейкать для того или иного уровня',
  table: {
    tHead: [
      'Subtype',
      ['CFI', 'Staked'],
      ['CFI/ETH LP', 'Staked'],
      ['SFI/BNB LP', 'Staked'],
      ['CFI/MATIC LP', 'Staked'],
      ['CFI/OTHER LP', 'Staked'],
    ],
    tBody: [
      {
        tdList: ['SAMURAI', '10,000', '10,000', '10,000', '10,000', '10,000'],
      },
      {
        tdList: ['SAMURAI', '10,000', '10,000', '10,000', '10,000', '10,000'],
      },
      {
        tdList: ['SAMURAI', '10,000', '10,000', '10,000', '10,000', '10,000'],
      },
      {
        tdList: ['SAMURAI', '10,000', '10,000', '10,000', '10,000', '10,000'],
      },
      {
        tdList: ['SAMURAI', '10,000', '10,000', '10,000', '10,000', '10,000'],
      },
    ],
  },
})

export const getLoyaltyLevelsFunctionality = () => [
  {
    title: 'SAMURAI',
    img: LevelsItemImg01,
    secondaryFeatures: [
      {
        desc: 'Free gas (or discount) for limit orders (BSC,Polygon)',
        value: 'No',
      },
      {
        desc: 'Free platform or discount on platform feels',
        value: 'No',
      },
    ],
    levelFeatures: {
      top: [
        { text: 'Market order (+swap)' },
        {
          text: 'Limit order (All subtypes)',
          orders: ['SL order', 'TP order', 'LP order', 'MC order', 'DCA order', 'GPL order'],
        },
        { text: 'Dashboard' },
      ],
      bottom: [
        { text: 'Smart invest', disabled: true },
        { text: 'Gamification', disabled: true },
        { text: 'Bridge', disabled: true },
      ],
    },
  },
  {
    title: 'SAMURAI',
    img: LevelsItemImg02,
    secondaryFeatures: [
      {
        desc: 'Free gas (or discount) for limit orders (BSC,Polygon)',
        value: 'No',
      },
      {
        desc: 'Free platform or discount on platform feels',
        value: 'No',
      },
    ],
    levelFeatures: {
      top: [
        { text: 'Market order (+swap)' },
        {
          text: 'Limit order (All subtypes)',
          orders: ['SL order', 'TP order', 'LP order', 'MC order', 'DCA order', 'GPL order'],
        },
        { text: 'Dashboard' },
      ],
      bottom: [
        { text: 'Smart invest' },
        { text: 'Gamification' },
        { text: 'Bridge' },
      ],
    },
  },
  {
    title: 'SAMURAI',
    img: LevelsItemImg03,
    secondaryFeatures: [
      {
        desc: 'Free gas (or discount) for limit orders (BSC,Polygon)',
        value: 'No',
      },
      {
        desc: 'Free platform or discount on platform feels',
        value: 'No',
      },
    ],
    levelFeatures: {
      top: [
        { text: 'Market order (+swap)' },
        {
          text: 'Limit order (All subtypes)',
          orders: ['SL order', 'TP order', 'LP order', 'MC order', 'DCA order', 'GPL order'],
        },
        { text: 'Dashboard' },
      ],
      bottom: [
        { text: 'Smart invest' },
        { text: 'Gamification' },
        { text: 'Bridge' },
      ],
    },
  },
]

export const getLoyaltyCompleteFunctionality = () => ({
  title: 'Сomplete functionality table',
  tHead: ['Subtype', 'Nomad', 'Solo', 'Ninja', 'Ronin', 'Samurai', 'Shogun', 'Emperor'],
  tBody: [
    {
      tdList: ['Dashboard', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      tdList: ['Market order (+ swap)', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'no-border',
      tdList: ['Limit order (all subtypes)', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'no-border small',
      tdList: ['Stop loss order', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'no-border small',
      tdList: ['Take profit order', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'no-border small',
      tdList: ['Liquidity pool order', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'no-border small',
      tdList: ['Market cap order', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'no-border small',
      tdList: ['DCA order', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      trClass: 'small small--last-child',
      tdList: ['Gas price limit order', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      tdList: ['Bridge', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      tdList: ['Smart invest', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      tdList: ['Gamification', 'No', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    },
    {
      tdList: [
        'Free gas (or discount) for limit orders (BSC, Polygon)',
        'No',
        'Free gas for 10 orders per day and',
        'Free gas for 10 orders per day and discount 20% other orders',
        'Free gas for 10 orders per day and discount 30% other orders',
        'Free gas for 10 orders per day and discount 40% other orders',
        'Free gas for 10 orders per day and discount 50% other orders',
        'Free gas for 10 orders per day and discount 60% other orders',
      ],
    },
    {
      tdList: [
        'Free platform or discount on platform feels',
        'No',
        'No',
        'Discount - 10%',
        'Discount - 30%',
        'Discount - 50%',
        'Free platform fee',
        'Free platform fee',
      ],
    },
  ],
})

export const getLoyaltyYourFunctionality = () => ({
  title: 'Your functionality1',
  topLeft: [
    { text: 'Market order (+swap)' },
    {
      text: 'Limit order (All subtypes)',
      orders: ['Stop loss order', 'Take profit order', 'Liquidity pool order', 'Market cap order', 'DCA order', 'Gas price limit order'],
    },
    { text: 'Dashboard' },
  ],
  topRigth: [
    'Free gas (or discount) for limit orders (BSC,Polygon)',
    'Free gas for 10 orders per day and discount 20% other orders',
  ],
  bottomLeft: [
    { text: 'Smart invest1' },
    { text: 'Gamification' },
    { text: 'Bridge' },
  ],
  bottomRight: [
    'Free platform or discount on platform feels',
    'Discount - 10%1',
  ],
})
