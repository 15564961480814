import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const useOutsideAlerter = (ref, callback, ignore) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !event.target.classList.contains(ignore)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [callback, ref, ignore])
}

export default function ClickOutside({ children, callback, ignore }) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, callback, ignore)

  return <div className="ClickOutside" ref={wrapperRef}>{children}</div>
}

ClickOutside.propTypes = {
  callback: PropTypes.func,
  ignore: PropTypes.string,
}
