import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
// import { Text } from '../Text/Text'
// import { Link } from '../Link/Link'
import { TitleDate } from '../TitleDate/TitleDate'
import { IconItemHistory } from '../IconItemHistory/IconItemHistory'
import { ItemFromCurrency } from '../ItemFromCurrency/ItemFromCurrency'
import Icon15Direction from '../Icon/icon--15-direction.svg'
// import Img32Dai from '../IconItemHistory/img-32-dai.png'
// import Img32Eth from '../IconItemHistory/img-32-eth.png'
// import Icon16Copy from '../Icon/icon--16-copy.svg'
// import Icon16Link from '../Icon/icon--16-link.svg'
// import IconLpSublabel from '../Icon/icon--lp-sublabel.svg'

import './item-currency-chart-orderbook.scss'

export const ItemCurrencyChartOrderbook = ({
  className,
  iconName,
  iconId,
  title,
  date,
  iconImgOne,
  iconImgOneAlt,
  iconImgOneTitle,
  iconImgOneDate,
  iconArrow,
  iconImgTwo,
  iconImgTwoAlt,
  iconImgTwoTitle,
  iconImgTwoDate,
  iconOneTooltip,
  iconTwoTooltip,
  titleFrom,
  iconFromName,
  iconFromId,
  keysFrom,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={`item-currency-chart-orderbook ${className || ''}  ${open ? 'item-currency-chart-orderbook--open' : ''}`} onClick={() => setOpen(!open)}>
      <div className="item-currency-chart-orderbook__body">
        <div className="item-currency-chart-orderbook__icon">
          <Icon
            mod="icon--16"
            name={iconName}
            id={iconId}
          />
        </div>

        <div className="item-currency-chart-orderbook__title">
          <TitleDate
            title={title}
            date={date}
          />
        </div>

        <div className="item-currency-chart-orderbook__currency">
          <div className="item-currency-chart-orderbook__currency-item">
            <IconItemHistory
              iconImg={iconImgOne}
              iconImgAlt={iconImgOneAlt}
              title={iconImgOneTitle}
              date={iconImgOneDate}
              tooltip={iconOneTooltip}
            />

            {iconArrow && (
              <Icon
                mod="icon--15-direction "
                name={Icon15Direction}
                id="icon--15-direction"
              />
            )}

          </div>

          {iconImgTwo && (
            <div className="item-currency-chart-orderbook__currency-item">
              <IconItemHistory
                iconImg={iconImgTwo}
                iconImgAlt={iconImgTwoAlt}
                title={iconImgTwoTitle}
                date={iconImgTwoDate}
                tooltip={iconTwoTooltip}
              />
            </div>
          )}
        </div>

        <div className="item-currency-chart-orderbook__from">
          <ItemFromCurrency
            title={titleFrom}
            iconName={iconFromName}
            iconId={iconFromId}
            keys={keysFrom}
          />
        </div>
      </div>

      {/* <div className="item-currency-chart-orderbook__desc"> */}
      {/* <div className="item-currency-chart-orderbook__desc-top"> */}
      {/* <div className="item-currency-chart-orderbook__desc-item"> */}
      {/* <Text */}
      {/* className="item-currency-chart-orderbook__desc-title" */}
      {/* text="Sent" */}
      {/* /> */}

      {/* <div className="item-currency-chart-orderbook__desc-body"> */}
      {/* <div className="item-currency-chart-orderbook__desc-body-item"> */}
      {/* <IconItemHistory */}
      {/* iconImg={Img32Eth} */}
      {/* iconImgAlt="ETH" */}
      {/* title="- 0.03 ETH" */}
      {/* date="$5.03" */}
      {/* /> */}
      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-body-item"> */}
      {/* <IconItemHistory */}
      {/* iconImg={Img32Dai} */}
      {/* iconImgAlt="DAI" */}
      {/* title="- 0.03 ETH" */}
      {/* date="$4.97" */}
      {/* /> */}
      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-body-arrows"> */}
      {/* <Icon */}
      {/* mod="icon--15-direction " */}
      {/* name={Icon15Direction} */}
      {/* id="icon--15-direction" */}
      {/* /> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-item"> */}
      {/* <Text */}
      {/* className="item-currency-chart-orderbook__desc-title" */}
      {/* text="Received" */}
      {/* /> */}

      {/* <div className="item-currency-chart-orderbook__desc-body"> */}
      {/* <div className="item-currency-chart-orderbook__desc-body-item"> */}
      {/* <div className="item-currency-chart-orderbook__desc-body-icon"> */}
      {/* <Icon */}
      {/* name={IconLpSublabel} */}
      {/* id="icon--lp-sublabel" */}
      {/* /> */}
      {/* </div> */}
      {/* <IconItemHistory */}
      {/* iconImg={Img32Dai} */}
      {/* iconImgAlt="DAI" */}
      {/* title="- 0.03 ETH" */}
      {/* date="$5.03" */}
      {/* /> */}
      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-body-item"> */}
      {/* <IconItemHistory */}
      {/* iconImg={Img32Eth} */}
      {/* iconImgAlt="ETH" */}
      {/* title="- 0.03 ETH" */}
      {/* date="$4.97" */}
      {/* /> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}

      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-bottom"> */}
      {/* <div className="item-currency-chart-orderbook__desc-bottom-item"> */}
      {/* <div className="item-currency-chart-orderbook__desc-bottom-left"> */}
      {/* <Text */}
      {/* className="item-currency-chart-orderbook__bottom-title" */}
      {/* text="Fee" */}
      {/* /> */}

      {/* <Text */}
      {/* text="0.00015 ETH ($0.03)" */}
      {/* /> */}
      {/* </div> */}
      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-bottom-item"> */}
      {/* <div className="item-currency-chart-orderbook__desc-bottom-left"> */}
      {/* <Text */}
      {/* className="item-currency-chart-orderbook__bottom-title" */}
      {/* text="Transaction hash" */}
      {/* /> */}

      {/* <Text */}
      {/* text="0xc92e...03f0" */}
      {/* /> */}
      {/* </div> */}

      {/* <div className="item-currency-chart-orderbook__desc-bottom-rigth"> */}
      {/* <Link */}
      {/* className="item-currency-chart-orderbook__desc-bottom-link" */}
      {/* href="/#" */}
      {/* > */}
      {/* <Icon */}
      {/* mod="icon--16" */}
      {/* name={Icon16Copy} */}
      {/* id="icon--16-copy" */}
      {/* /> */}
      {/* </Link> */}

      {/* <Link */}
      {/* className="item-currency-chart-orderbook__desc-bottom-link" */}
      {/* href="/#" */}
      {/* > */}
      {/* <Icon */}
      {/* mod="icon--16" */}
      {/* name={Icon16Link} */}
      {/* id="icon--16-link" */}
      {/* /> */}
      {/* </Link> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  )
}

ItemCurrencyChartOrderbook.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconId: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  iconImgOne: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  iconImgOneAlt: PropTypes.string,
  iconImgOneTitle: PropTypes.string,
  iconImgOneDate: PropTypes.string,
  iconArrow: PropTypes.bool,
  iconImgTwo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  iconImgTwoAlt: PropTypes.string,
  iconImgTwoTitle: PropTypes.string,
  iconImgTwoDate: PropTypes.string,
  iconOneTooltip: PropTypes.bool,
  iconTwoTooltip: PropTypes.bool,
  titleFrom: PropTypes.string,
  iconFromName: PropTypes.string,
  iconFromId: PropTypes.string,
  keysFrom: PropTypes.string,
}
