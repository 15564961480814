import React from 'react'
import { BaseTextBody, BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import ImgBg01 from './your-functionality__bg01.svg'
import ImgBg02 from './your-functionality__bg02.svg'
import ImgBg03 from './your-functionality__bg03.svg'
import './your-functionality.scss'
import FunctionalityRow from './functionality-row/FunctionalityRow'
import { getLoyaltyYourFunctionality } from '../../../../../parser/data'

const YourFunctionality = () => {
  const data = getLoyaltyYourFunctionality()

  return (
    <div className="your-functionality">
      <img src={ImgBg01} alt="" className="your-functionality__bg01" />
      <img src={ImgBg02} alt="" className="your-functionality__bg02" />
      <img src={ImgBg03} alt="" className="your-functionality__bg03" />

      <div className="your-functionality__header">
        <BaseTextHeading size="XL">
          {data.title}
        </BaseTextHeading>
      </div>

      <div className="your-functionality__body">
        <div className="your-functionality__top">
          <div className="your-functionality__left">
            <FunctionalityRow data={data.topLeft} />
          </div>

          <div className="your-functionality__right">
            <BaseTextBody secondary>{data.topRigth[0]}</BaseTextBody>
            <BaseTextBody>{data.topRigth[1]}</BaseTextBody>
          </div>
        </div>

        <div className="your-functionality__bottom">
          <div className="your-functionality__left">
            <FunctionalityRow data={data.bottomLeft} />
          </div>

          <div className="your-functionality__right">
            <BaseTextBody secondary>{data.bottomRight[0]}</BaseTextBody>
            <BaseTextBody>{data.bottomRight[1]}</BaseTextBody>
          </div>
        </div>
      </div>
    </div>
  )
}

export default YourFunctionality
