import React, {
  useEffect, useState,
} from 'react'
import { useSelector } from 'react-redux'
import { writeStorage, useLocalStorage } from '@rehooks/local-storage'
import PropTypes from 'prop-types'

import OwnerWallet from './owner-wallet/OwnerWallet'
import { Divider } from '../../../../components/Divider/Divider'
import CyberWallet from './cyber-wallet/CyberWallet'
import { useWeb3 } from '../../../../context/web3Provider'

const Wallets = ({ onSetIsOpenSubMenu }) => {
  const web3React = useWeb3()
  const [selectedChain] = useLocalStorage('selectedChain')

  const [ownerAccounts, setOwnerAccounts] = useState(null)
  const userState = useSelector((store) => store.user)
  const [isOwnerAdressesListOpened, setOwnerAddresesListOpenedState] = useState(false)
  const [isCyberWalletAdressesListOpened, setCyberWalletAddresesListOpenedState] = useState(false)

  const setOwner = (accounts) => {
    setOwnerAccounts(accounts)
    if (accounts) {
      writeStorage('selectedOwnerWallet', accounts[0])
    }
  }

  useEffect(() => {
    const additionalDataLoading = () => {
      if (web3React.account) {
        setOwner([web3React.account])
      }
    }

    const init = async (accounts) => {
      setOwner(accounts)

      if (accounts.length === 0 || selectedChain === 'ETH') {
        additionalDataLoading()
      }
    }

    const fetchAccountsData = async () => {
      if (!ownerAccounts) {
        const accounts = [web3React.account]

        if (await typeof accounts !== 'undefined') {
          await init(accounts)
          writeStorage('isLoginMetamask', true)
        }
      }
    }

    fetchAccountsData()
  }, [ownerAccounts, userState, web3React, selectedChain])

  useEffect(() => {
    setOwner([web3React.account])
  }, [web3React.account])

  return (
    <>
      <OwnerWallet
        ownerAccounts={ownerAccounts}
        isOwnerAdressesListOpened={isOwnerAdressesListOpened}
        onToggleAddessesListOpenedState={() => {
          setCyberWalletAddresesListOpenedState(false)
          setOwnerAddresesListOpenedState(!isOwnerAdressesListOpened)
          onSetIsOpenSubMenu(!isOwnerAdressesListOpened)
        }}
      />
      <Divider />
      <CyberWallet
        ownerAccounts={ownerAccounts}
        userState={userState}
        isCyberWalletAdressesListOpened={isCyberWalletAdressesListOpened}
        onToggleAddessesListOpenedState={() => {
          setOwnerAddresesListOpenedState(false)
          setCyberWalletAddresesListOpenedState(!isCyberWalletAdressesListOpened)
          onSetIsOpenSubMenu(!isCyberWalletAdressesListOpened)
        }}
      />
    </>
  )
}

Wallets.propTypes = {
  onSetIsOpenSubMenu: PropTypes.func,
}

export default Wallets
