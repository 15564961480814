import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import { checkFloatNaN, ERC20ToFloat } from '../utils/helperFunctions'
// eslint-disable-next-line import/no-cycle
import {
  getLinkBasedOnCurrentNetwork,
  parseTransactionStatus,
  getImage,
  parseToken,
  sortArrayByAlphabet,
} from './data'
import { ZERO_ADDRESS, BRIDGE_ORDERS_STATUS, TRANSACTION_METHOD } from '../const'

export const parseGetTopAsset = (ratingRes) => {
  const selectedChain = localStorage.getItem('selectedChain')
  let investIndexData = ratingRes.data.investindex_data.tokens || []

  if (selectedChain === 'BSC') {
    investIndexData = ratingRes.data.investindex_data.bscswap || []
  } else if (selectedChain === 'Polygon') {
    investIndexData = ratingRes.data.investindex_data.quickswap || []
  }

  return {
    investIndexData,
  }
}

export const parseGetDataSelectWallet = (balancesRes) => {
  const selectedChain = localStorage.getItem('selectedChain')
  const parsedResult = {
    balance: balancesRes.data.balance,
    tokens: balancesRes.data.tokens,
  }

  if (selectedChain === 'BSC') {
    parsedResult.tokens = balancesRes.data.bsc_tokens || []
  } else if (selectedChain === 'Polygon') {
    parsedResult.tokens = balancesRes.data.polygon_tokens || []
  }

  parsedResult.tokens = parsedResult.tokens.map((token) => ({
    ...token,
    icon: getImage(token.image_url || token.logo_url, token.address),
  }))
  parsedResult.tokens.sort((item1, item2) => (
    sortArrayByAlphabet({
      item1,
      item2,
      prop: 'symbol',
      additionalProp: 'contract_ticker_symbol',
    })
  ))

  return parsedResult
}

export const getCurrentNetworkForRequest = () => {
  const selectedChain = localStorage.getItem('selectedChain')

  switch (selectedChain) {
    case 'Ethereum':
      return 'ETH'

    case 'BSC':
      return 'BSC'

    case 'Polygon':
      return 'POLYGON'

    default:
      return 'ETH'
  }
}

export const parserGetDataUser = (dataUser) => {
  const clearInternalAddress = dataUser.data.user.internal_addresses.filter((item) => item)
  const clearExternalAddress = dataUser.data.user.external_addresses.filter((item) => item)

  return { ...dataUser.data, user: { internal_addresses: clearInternalAddress, external_addresses: clearExternalAddress } }
}

export const parsePair = (pair) => ({
  address: pair.address,
  link: `https://${getLinkBasedOnCurrentNetwork()}/token/${pair.address}`,
  token1: {
    address: pair.token1.address,
    symbol: pair.token1.symbol,
    icon: getImage(pair.token1.image_url || pair.token1.logo_url, pair.token1.address),
    name: pair.token1.name,
  },
  token2: {
    address: pair.token2.address,
    symbol: pair.token2.symbol,
    icon: getImage(pair.token2.image_url || pair.token2.logo_url, pair.token2.address),
    name: pair.token2.name,
  },
})

export const parsePairsList = (res) => res.data.results.map((pair) => parsePair(pair))

export const convertToExactBalance = (tokenBalance, decimals, Web3BN) => ERC20ToFloat(new Web3BN(`${tokenBalance}`), decimals, Web3BN)

export const parsePoolLiquidityData = (res) => {
  const cutValue = (val) => checkFloatNaN(val, 'N/A', 2)
  const totalPnl = cutValue(res.total_pnl)
  return {
    returnFloat: cutValue(res.twenty_four_hour_return_float),
    returnPercents: cutValue(res.twenty_four_hour_return_percents),
    averageCost: cutValue(res.average_cost),
    fees: cutValue(res.fees),
    totalPnl,
    parsedTotalPnl: totalPnl < 0 ? `-$${totalPnl * -1}` : `+$${totalPnl}`,
  }
}

const parseStatus = (status) => {
  switch (status) {
    case 'Executed':
      return 'loading'
    case 'Confirmed':
      return 'completed'
    case 'Canceled':
      return 'canceled'
    case 'Failed':
      return 'error'
    default:
      return 'pending'
  }
}

const parseDate = (date) => {
  const day = `${(`0${date.getDate()}`).slice(-2)}`
  const month = `${(`0${date.getMonth() + 1}`).slice(-2)}`
  const year = `${(`0${date.getYear()}`).slice(-2)}`
  const hours = `${(`0${date.getHours()}`).slice(-2)}`
  const minutes = `${(`0${date.getMinutes()}`).slice(-2)}`
  const seconds = `${(`0${date.getSeconds()}`).slice(-2)}`

  return `${day}.${month}.${year}, ${hours}:${minutes}:${seconds}`
}

const getStatusDetailsForOrderDetailsModal = (status) => {
  const details = {
    type: 'error',
    text: 'Something went wrong',
    subText: 'Try to re-create the order. If the error occurs frequently, please contact our support team.',
  }
  switch (status) {
    case 'Active':
      return {
        type: 'pending',
        text: 'Order is active',
        subText: 'Order is active and pending for trigger conditions.',
      }
    case 'Executed':
      return {
        type: 'spinner',
        text: 'Order is being executed, please wait',
        subText: 'Order is active and pending for trigger conditions.',
      }
    case 'Confirmed':
      return {
        type: 'completed',
        text: 'Order has been successfully executed',
        subText: 'All transactions in order has been successfully executed.',
      }
    case 'Canceled':
      return {
        type: 'canceled',
        text: 'Order was canceled',
        subText: 'Order was manually canceled by you.',
      }
    default:
      return details
  }
}

const getStepsDetails = (order) => {
  const steps = []

  order.transactions.forEach((transaction) => {
    const transactionStatus = parseTransactionStatus(transaction.status)

    let state = 'pending'
    if (transactionStatus === 'TRX_CONFIRMED') {
      state = 'completed'
    } else if (transactionStatus === 'TRX_FAILED' || transactionStatus === 'TRX_CANCELLED') {
      state = 'canceled'
    }

    steps.push({
      badge: { state },
      text: transaction.name,
      active: true,
      hash: transaction.hash,
      link: transaction.hash ? `https://${getLinkBasedOnCurrentNetwork()}/tx/${transaction.hash}` : '',
    })
  })
  return steps
}

export const parseOrdersList = (res) => {
  const result = res?.results ? res.results : res
  const count = res?.count ? res.count : res.length

  return {
    count,
    rows: result.map((item) => {
      const foundSwapTransaction = item.transactions.filter((transaction) => transaction.name === 'Swap')[0]
      const executed = foundSwapTransaction ? parseDate(new Date(foundSwapTransaction.modified_at)) : '00.00.00, 00:00:00'

      return {
        id: item.id,
        status: {
          value: parseStatus(item.status),
          text: item.status === 'Executed' ? 'Executing' : item.status,
        },
        pair: {
          title: item.pair,
          token1: {
            icon: getImage(item.asset0.image_url || item.asset0.logo_url, item.asset0.address),
          },
          token2: {
            icon: getImage(item.asset1.image_url || item.asset1.logo_url, item.asset1.address),
          },
        },
        transaction: item.action,
        type: item.type.charAt(0).toUpperCase() + item.type.slice(1),
        triggerConditions: item.triggers && item.triggers[0] ? item.triggers[0].trigger_condition : '',
        creationTime: parseDate(new Date(item.base_order.creation_time)),
        modal: {
          ...getStatusDetailsForOrderDetailsModal(item.status),
          ...{ steps: getStepsDetails(item) },
          token1: {
            value: item.asset0_value || 0,
            price: item.asset0_price,
            name: item.asset0.name,
            decimals: item.asset0.decimals,
            icon: getImage(item.asset0.image_url || item.asset0.logo_url, item.asset0.address),
            address: item.asset0.address,
            symbol: item.asset0.symbol,
            displayPrice: `$${checkFloatNaN(item.asset0_price, 0, 5)}`,
          },
          token2: {
            value: 0,
            price: item.asset1_price,
            name: item.asset1.name,
            decimals: item.asset1.decimals,
            icon: getImage(item.asset1.image_url || item.asset1.logo_url, item.asset1.address),
            address: item.asset1.address,
            symbol: item.asset1.symbol,
            displayPrice: `$${checkFloatNaN(item.asset1_price, 0, 5)}`,
          },
          triggered: item.base_order.activate_time ? parseDate(new Date(item.base_order.activate_time)) : '00.00.00, 00:00:00',
          executed,
        },
      }
    }),
  }
}

export const parseTokensPairsList = (res) => (
  res.map((pair) => ({
    token1: {
      address: pair.asset0__address,
      symbol: pair.asset0__symbol,
    },
    token2: {
      address: pair.asset1__address,
      symbol: pair.asset1__symbol,
    },
  }))
)

export const parseTokenData = (data) => ({
  address: data.address,
  decimals: data.decimals,
  name: data.name,
  symbol: data.symbol,
  usdtPrice: data.usdt_price,
  icon: getImage(data.image_url || data.logo_url, data.address),
  link: `https://${getLinkBasedOnCurrentNetwork()}/token/${data.address}`,
  isLinkHidden: ZERO_ADDRESS === data.address,
})

export const parseTokensList = (data) => ({
  tokens: data.results.map((token) => parseToken(token)),
  areThereMoreTokens: !!data.next,
})

export const parseZeroXData = (data) => ({
  data: data.data,
  gas: data.gas,
  sources: data.sources,
  to: data.to,
  allowanceTarget: data.allowanceTarget,
})

export const parseGasData = (data) => ({
  slow: data.safeLow,
  medium: data.average,
  fastest: data.fastest,
})

export const parseTokenSwapOrder = (data) => ({
  type: `${data.type.charAt(0).toUpperCase()}${data.type.slice(1)}`,
  triggerConditions: data.triggers && data.triggers[0] ? data.triggers[0].trigger_condition : '',
  transactions: data.transactions.map((transaction) => ({
    id: transaction.id,
    name: transaction.name,
    status: transaction.status,
    vaultRequestId: transaction.vault_request_id,
  })),
})

const parseBridgeStatus = (item) => {
  let obj = {
    status: 'pending',
    text: 'Active',
    type: 'orders',
  }
  // no information about the canceled status yet
  if (item.status === BRIDGE_ORDERS_STATUS.TRANSFER_FAILED || item.txIsFailed) {
    obj = {
      status: 'error',
      text: 'Failed',
      type: 'history',
    }
  } else if (item.status === BRIDGE_ORDERS_STATUS.TRANSFER_COMPLETED) {
    obj = {
      status: 'completed',
      text: 'Confirmed',
      type: 'history',
    }
  } else if (item.status === BRIDGE_ORDERS_STATUS.TRANSFER_UNKNOWN || item.status === BRIDGE_ORDERS_STATUS.TRANSFER_SUBMITTING) {
    obj = {
      status: 'loading',
      text: 'Executed',
      type: 'orders',
    }
  }
  return obj
}

export const parseBridgeHistory = (listType, data) => {
  const parsedOrders = data.history.map((item) => {
    const { status, type, text } = parseBridgeStatus(item)
    return {
      token: {
        sent: {
          amount: item.src_send_info.amount,
          symbol: item.src_send_info.token.symbol,
          decimals: item.src_send_info.token.decimal,
        },
        received: {
          amount: item.dst_received_info.amount,
          symbol: item.dst_received_info.token.symbol,
          decimals: item.dst_received_info.token.decimal,
        },
      },
      networks: {
        from: item.src_send_info.chain.name,
        to: item.dst_received_info.chain.name,
      },
      date: moment(Number(item.ts)).format('DD.MM.YY, hh:mm:ss'),
      status: {
        value: status,
        text,
      },
      type,
    }
  })

  return parsedOrders.filter((item) => item.type === listType)
}

export const parserZapperAllTransactions = (data) => data.map((item) => {
  const getTransactionAction = (transactionAction) => {
    switch (transactionAction.toLowerCase()) {
      case TRANSACTION_METHOD.exchange:
        return 'swap'
      case TRANSACTION_METHOD.approval:
        return 'open'
      case TRANSACTION_METHOD.receive:
        return 'down'
      case TRANSACTION_METHOD.send:
        return 'up'
      default:
        return ''
    }
  }
  const isTo = (method) => {
    switch (method.toLowerCase()) {
      case TRANSACTION_METHOD.exchange:
        return false
      case TRANSACTION_METHOD.receive:
        return false
      case TRANSACTION_METHOD.send:
        return true
      default:
        return false
    }
  }

  const dateTransaction = new Date((+item.timeStamp) * 1000)
  const formatDate = `${dateTransaction.toLocaleString('en-US', { month: 'long' })} ${dateTransaction.getDate()}, ${dateTransaction.getFullYear()}`
  const time = `${dateTransaction.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`

  return {
    ...item,
    dateTransaction,
    formatDate,
    time,
    action: getTransactionAction(item.name),
    isTo: isTo(item.name),
  }
})
