import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getTokenSwapOrderForToken } from '../../service/api'
import { getAllERC20Transfers } from '../../service/etherscanApi'
import { getTokenExactData } from '../../utils/helperFunctions'

const tokensAdapter = createEntityAdapter({
  selectId: (token) => token.address,
  // sortComparer: (a, b) => a.address.localeCompare(b.address),
})

export const tokens = createSlice({
  name: 'tokens',
  initialState: tokensAdapter.getInitialState(),
  reducers: {
    processToken: tokensAdapter.upsertOne,
  },
})

export const { processToken } = tokens.actions

export const populateTokenData = async ({
  address, user, web3React, dispatch, addToken = true,
}) => {
  // TODO: Move addToken to the better place
  // const listOfTokenData = ['name', 'symbol', 'decimals']
  const tokenData = {
    name: await getTokenExactData(address, 'name', web3React),
    symbol: await getTokenExactData(address, 'symbol', web3React),
    decimals: await getTokenExactData(address, 'decimals', web3React),
  }
  const token = { address, ...tokenData }
  const orders = await getTokenSwapOrderForToken(address)
  const transactions = await getAllERC20Transfers(address, user)
  addToken && dispatch(processToken({ ...token, transactions: transactions.data?.result, orders: orders.data }))
}
