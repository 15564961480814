import React from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import { BaseButton } from '../../../../../../components/BaseButton/BaseButton'
import { Input } from '../../../../../../components/Input/Input'

const SettingsForm = ({
  gas,
  onChangeGas,
  slippage,
  onChangeSlippage,
}) => (
  <div className="form-container">
    <BaseTextBody>Gas Price</BaseTextBody>
    <div className="settings-controls">
      <BaseButton
        onClick={() => onChangeGas('slow')}
        variant={gas === 'slow' ? '' : 'primary'}
      >
        Slow
      </BaseButton>
      <BaseButton
        onClick={() => onChangeGas('medium')}
        variant={gas === 'medium' ? '' : 'primary'}
      >
        Medium
      </BaseButton>
      <BaseButton
        onClick={() => onChangeGas('fastest')}
        variant={gas === 'fastest' ? '' : 'primary'}
      >
        Fast
      </BaseButton>
    </div>

    <BaseTextBody>Price Slippage</BaseTextBody>
    <div className="settings-controls">
      <BaseButton
        onClick={() => onChangeSlippage('0.1')}
        variant={slippage === '0.1' ? '' : 'primary'}
      >
        1%
      </BaseButton>
      <BaseButton
        onClick={() => onChangeSlippage('0.3')}
        variant={slippage === '0.3' ? '' : 'primary'}
      >
        3%
      </BaseButton>
      <Input
        type="number"
        placeholder="Custom"
        value={!['0.1', '0.3'].includes(`${slippage}`) ? `${slippage * 10}` : ''}
        onChange={(e) => onChangeSlippage(`${e.target.value / 10}`)}
      />
    </div>
  </div>
)

SettingsForm.propTypes = {
  gas: PropTypes.string,
  onChangeGas: PropTypes.func,
  slippage: PropTypes.string,
  onChangeSlippage: PropTypes.func,
}

export default SettingsForm
