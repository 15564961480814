import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TopMenu } from './top-menu/TopMenu'
import { BaseSearch } from '../../../components/BaseSearch/BaseSearch'
import ClickOutside from '../../../components/ClickOutside/ClickOutside'
import { BaseDropDown } from '../../../components/SelectCurrencyChart/BaseDropDown'
import { lpTokensFilter, getLpTokensPair, getTokenData } from '../../../service/api'
import { setAsset } from '../../../store/slice/tradingView'
import { BaseButton } from '../../../components/BaseButton/BaseButton'
import { Icon } from '../../../components/Icon/Icon'
import IconReorder from '../../../components/Icon/icon--reorder.svg'
import { getFormatDataLpToken } from '../../../parser/data'
import { updateSelectedTokensPair } from '../../../store/slice/trade'
import { toastCritical } from '../../../components/Toast/Toast'

import './header.scss'
import { useWeb3 } from '../../../context/web3Provider'
import { getPairForAndSearch } from '../../../utils/helperFunctions'

export const Header = ({
  placeholder,
  isSidebarToggled,
  onToggleSidebar,
}) => {
  const web3React = useWeb3()
  const dispatch = useDispatch()
  const history = useHistory()

  const [valueSearch, setValueSearch] = useState('')
  const [openDropDown, setOpenDropDown] = useState(false)
  const [infinityScrollHasMore, setInfinityScrollHasMore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [clearInput, setClearInput] = useState(false)
  const [pageLpTokens, setPageLpTokens] = useState(1)
  const [countFindLp, setCountFindLp] = useState(0)
  const [lpTokensList, setLpTokensList] = useState([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSetValueSearch = useCallback(debounce(async (newValue) => {
    setIsLoading(true)
    setPageLpTokens(1)
    setValueSearch(newValue)

    try {
      const { filterValue, pairFor } = getPairForAndSearch(newValue)
      const listOfLpTokens = await lpTokensFilter({
        filterValue,
        pairFor,
        page: 1,
        limit: 10,
      })

      setCountFindLp(listOfLpTokens.data.count)
      setLpTokensList(listOfLpTokens.data.results.map((serverData) => getFormatDataLpToken(serverData)))
    } catch (e) {
      setCountFindLp(0)
      setLpTokensList([])
    }

    setIsLoading(false)
  }, 1000), [])

  const funcLoadItems = useCallback(async () => {
    setIsLoading(true)
    setValueSearch('')
    setPageLpTokens(1)

    try {
      const listOfLpTokens = await lpTokensFilter({
        filterValue: '',
        page: 1,
        limit: 10,
      })

      setCountFindLp(listOfLpTokens.data.count)
      setLpTokensList(listOfLpTokens.data.results.map((serverData) => getFormatDataLpToken(serverData)))

      setInfinityScrollHasMore(!!listOfLpTokens.data.next)
    } catch (e) {
      setCountFindLp(0)
      setLpTokensList([])
      setInfinityScrollHasMore(false)
    }
    setIsLoading(false)
  }, [])

  const handleCloseDropDown = useCallback(() => {
    if (openDropDown) {
      setValueSearch('')
      setPageLpTokens(1)
      setOpenDropDown(false)
      setClearInput(true)
      setClearInput(false)
    }
  }, [openDropDown])

  const handleOpenDropDown = useCallback(async () => {
    !openDropDown && await funcLoadItems()

    setOpenDropDown(true)
  }, [funcLoadItems, openDropDown])

  // TODO: it's better to move this to a different action
  const onUpdateTokensPair = useCallback(async ({ token1, token2 }) => {
    try {
      const pair = await getLpTokensPair({
        address1: web3React.library.utils.toChecksumAddress(token1.address),
        address2: web3React.library.utils.toChecksumAddress(token2.address),
      })
      if (pair) {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1: pair.token1,
            token2: pair.token2,
          },
          pairAddress: pair.address,
          isChartExist: true,
        }))
      } else {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1,
            token2,
          },
          isChartExist: false,
        }))
      }
    } catch (e) {
      toastCritical('Tokens pair update failed')
      console.error('Update tokens pair on assets click failed = ', e)
    }
  }, [web3React, dispatch])

  const handleSelect = useCallback(async (item) => {
    handleCloseDropDown()
    dispatch(setAsset(item.token0.address))

    try {
      const token1 = await getTokenData(web3React.library.utils.toChecksumAddress(item.token0.address))
      const token2 = await getTokenData(web3React.library.utils.toChecksumAddress(item.token1.address))
      onUpdateTokensPair({ token1, token2 })
      history.push('/trade')
    } catch (e) {
      toastCritical('Could not load data for a chosen token')
      console.error('Update tokens pair on assets click failed = ', e)
    }
  }, [dispatch, handleCloseDropDown, web3React, onUpdateTokensPair, history])

  const funcInfinityScroll = useCallback(async () => {
    try {
      const { filterValue, pairFor } = getPairForAndSearch(valueSearch)
      const listOfLpTokens = await lpTokensFilter({
        filterValue,
        pairFor,
        page: pageLpTokens + 1,
        limit: 10,
      })

      setCountFindLp(listOfLpTokens.data.count)
      setLpTokensList(lpTokensList.concat(listOfLpTokens.data.results.map((serverData) => getFormatDataLpToken(serverData))))

      setPageLpTokens(pageLpTokens + 1)
      setInfinityScrollHasMore(!!listOfLpTokens.data.next)
    } catch (e) {
      console.error(e)
      setInfinityScrollHasMore(false)
    }
  }, [lpTokensList, pageLpTokens, valueSearch])

  return (
    <header className="base-header">
      {isSidebarToggled && (
        <div className="button-wrapper">
          <BaseButton
            variant="secondary"
            onlyIcon
            size="xs"
            iconLeft={<Icon name={IconReorder} id="icon--reorder" />}
            onClick={onToggleSidebar}
          />
        </div>
      )}

      <div className="base-header__left">
        <ClickOutside callback={handleCloseDropDown}>
          <div onClick={handleOpenDropDown}>
            <BaseSearch
              dataTestid="header--search-lp"
              placeholder={placeholder}
              value={valueSearch}
              onChange={handleSetValueSearch}
              clearInput={clearInput}
            />
          </div>

          <BaseDropDown
            isCountItems
            dataTestid="header--search-lp--drop-down"
            countItems={countFindLp}
            infinityScrollHasMore={infinityScrollHasMore}
            funcInfinityScroll={funcInfinityScroll}
            isLoading={isLoading}
            onClick={handleSelect}
            items={lpTokensList}
            isOpen={openDropDown}
          />
        </ClickOutside>
      </div>

      <div className="base-header__right">
        <TopMenu />
      </div>
    </header>
  )
}

Header.propTypes = {
  placeholder: PropTypes.string,
  isSidebarToggled: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
}
