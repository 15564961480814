import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getAllTokensTransfers, getAllAddressTransactions } from '../../service/etherscanApi'

const walletAdapter = createEntityAdapter({
  selectId: (wallet) => wallet.address,
  sortComparer: (a, b) => a.address.localeCompare(b.address),
})

export const wallets = createSlice({
  name: 'wallets',
  initialState: walletAdapter.getInitialState(),
  reducers: {
    processWallet: walletAdapter.upsertOne,
  },
})

export const { processWallet } = wallets.actions
/**
 * Метод ходит в API Etherscan и сначала забирает все транзакции с адреса, после забирает все операции с ERC20 токенами
 * После этого проходимся по обоим массивам, мерджим элементы в них в один
 * Далее сортируем по дате и диспатчим их в стор
 * @param {string} user
 * @param {function} dispatch
 * @param {Object} web3React
 * @returns {Promise<void>}
 */
export const populateTransactionHistory = async (user, dispatch, web3React) => {
  const transactionHistory = []
  const allTransactions = await getAllAddressTransactions(user)
  const allTokenTransfers = await getAllTokensTransfers(user)
  const etherTransfers = allTransactions.data.result.filter((el) => el.value > 0)
  const tokenTransfers = allTokenTransfers.data.result
  etherTransfers.forEach((item) => {
    const transaction = {
      timestamp: item.timeStamp,
      // eslint-disable-next-line radix
      value: parseInt(item.value) / 10 ** 18,
      destination: item.from === user ? item.to : item.from,
      send: item.from === user.toLowerCase(),
      name: 'ETH',
    }
    transactionHistory.push(transaction)
  })
  tokenTransfers.forEach((item) => {
    const transaction = {
      timestamp: item.timeStamp,
      // eslint-disable-next-line radix
      value: parseInt(item.value) / 10 ** parseInt(item.tokenDecimal),
      destination: item.from === user.toLowerCase() ? item.to : item.from,
      send: item.from === user.toLowerCase(),
      name: item.tokenSymbol,
      contract: web3React.library.utils.toChecksumAddress(item.contractAddress),
    }
    transactionHistory.push(transaction)
  })
  dispatch(processWallet({ address: user, transactions: transactionHistory.sort((a, b) => b.timestamp.localeCompare(a.timestamp)) }))
}
