import React from 'react'
import PropTypes from 'prop-types'
import './icon-text.scss'
import { Icon } from '../Icon/Icon'
import { BaseTextBody, BaseTextSubheading } from '../BaseText/BaseText'
import { BlockImgPair } from '../BlockImgPair/BlockImgPair'

export const IconText = ({
  isImg,
  imgSrc,
  className,
  icon,
  id,
  text,
  small,
}) => (
  <div className={`icon-text ${className || ''} ${small ? 'icon-text--small' : ''}`}>

    {isImg ? (
      <div className="icon-text--block-img-pair">
        <BlockImgPair
          firstImg={imgSrc}
        />
      </div>

    ) : (
      <Icon
        name={icon}
        id={id}
      />
    )}

    {small ? (
      <BaseTextBody size="S">
        {text}
      </BaseTextBody>
    ) : (
      <BaseTextSubheading>
        {text}
      </BaseTextSubheading>
    )}
  </div>
)

IconText.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  small: PropTypes.bool,
  isImg: PropTypes.bool,
  imgSrc: PropTypes.string,
}

export const IconTextPair = ({
  firstTokenImg,
  secondTokenImg,
  text,
  loadingIconFirst,
  loadingIconSecond,
}) => (
  <div className="icon-text--pair">
    <BlockImgPair
      loadingIconFirst={loadingIconFirst}
      loadingIconSecond={loadingIconSecond}
      firstImg={firstTokenImg}
      secondImg={secondTokenImg}
    />

    <BaseTextBody>
      {text}
    </BaseTextBody>
  </div>
)

IconTextPair.propTypes = {
  firstTokenImg: PropTypes.string,
  secondTokenImg: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  loadingIconFirst: PropTypes.bool,
  loadingIconSecond: PropTypes.bool,
}
