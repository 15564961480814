import instance from '../axios-api'
import {
  parseAllPossibleTokens,
  parseAllPossibleTokensForAllNetworksConbinations,
} from '../parser/bridge'

export const getAllPossibleTokensForSelectedNetworks = async ({
  networkFrom,
  networkTo,
}) => {
  const { data } = await instance.get(`https://bridgeapi.anyswap.exchange/v2/serverInfo/${networkTo}`)
  return parseAllPossibleTokens({ data, networkFrom, networkTo })
}

export const getAllPossibleTokensForAllNetworksConbinations = async () => {
  const { data } = await instance.get('https://bridgeapi.anyswap.exchange/v2/serverInfo/2')
  return parseAllPossibleTokensForAllNetworksConbinations(data)
}
