import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import './btn-form-big.scss'

export const BtnFormBig = ({
  className,
  iconLeft,
  iconRight,
  text,
  click,
  isDisabled,
  testId,
}) => {
  const classNameBtn = useMemo(() => {
    let cls = 'btn-form-big'

    if (className) {
      cls += ` ${className}`
    }
    if (isDisabled) {
      cls += ' btn-form-big--disabled'
    }

    return cls
  }, [className, isDisabled])

  const onClick = useCallback(() => {
    if (isDisabled) {
      return
    }

    click()
  }, [isDisabled, click])

  return (
    <div
      data-testid={testId}
      onClick={onClick}
      className={classNameBtn}
    >
      <div className="btn-form-big__btn">
        {iconLeft}
        {text && <span className="btn-form-big__text">{text}</span>}
        {iconRight}
      </div>
    </div>
  )
}

BtnFormBig.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  testId: PropTypes.string,
  isDisabled: PropTypes.bool,
  iconLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  iconRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  click: PropTypes.func,
}

BtnFormBig.defaultProps = {
  className: '',
  text: '',
  testId: 'cyber-wallet-button-create',
  iconLeft: '',
  isDisabled: false,
  iconRight: '',
  click: () => {},
}
