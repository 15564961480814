import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import IconClose from '../../../../../../../components/Icon/icon--close.svg'
import { BaseModal } from '../../../../../../../components/BaseModal/BaseModal'
import { BaseTextHeading, BaseTextSubheading, BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../../components/Icon/Icon'
import { Status } from '../../../../../../../components/Status/Status'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import { Stepper } from '../../../../../../../components/Stepper/Stepper'
import { AssetInfo } from '../../../../../../../components/AssetInfo/AssetInfo'
import { AssetBlock } from '../../../../../../../components/AssetBlock/AssetBlock'
import { List } from '../../../../../../../components/List/List'
import { ExternalLink } from '../../../../../../../components/ExternalLink/ExternalLink'
import IconEthereum from '../../../../../../../components/Icon/icon--ethereum.svg'
import IconBsc from '../../../../../../../components/Icon/icon--bsc.png'
import IconPolygon from '../../../../../../../components/Icon/icon--polygon.png'
import { checkFloatNaN, floatToERC20 } from '../../../../../../../utils/helperFunctions'
import { IconCurrency } from '../../../../../../../components/IconCurrency/IconCurrency'
import { useWeb3 } from '../../../../../../../context/web3Provider'
import { minABI } from '../../../../../../../utils/contracts'
import { getParsedTokenValue, parseOrderStatus } from '../../../../../../../parser/data'
import { apiTransferOrder, confirmOrder, checkTransferTransaction } from '../../../../../../../service/api'
import { NEW_ETH_ADDRESS, ZERO_ADDRESS } from '../../../../../../../const'
import { toastCritical, toastSuccess } from '../../../../../../../components/Toast/Toast'
import Loading from '../../../../../../../components/Loading/Loading'

import './transaction-modal.scss'

const TransactionModal = ({
  token,
  totalFee,
  networkFrom,
  networkTo,
  externalAddress,
  onClose,
  internalSelectedAddress,
  // slippage,
}) => {
  const web3React = useWeb3()
  const bridgeTransactionPoller = useRef()

  const [isTransactionSignPending, setTransactionSignIsPending] = useState(true)
  const [isTransactionSigned, setTransactionIsSigned] = useState(false)
  const [wasTransactionRequestSent, setTransactionRequestWasSent] = useState(false)
  const [isBridgeTransactionRequestPending, setBridgeTransactionRequestPendingState] = useState(false)
  const [isBridgeTransactionSuccessfull, setBridgeTransactionSuccessfullState] = useState(false)
  const [isBridgeTransactionPending, setBridgeTransactionIsPending] = useState(false)
  const [bridgeTransactionErrorMessage, setBridgeTransactionErrorMessageState] = useState()

  const getIconForNetwork = useCallback((name) => {
    switch (name) {
      case 'Binance Smart Chain':
        return <img src={IconBsc} alt="network-from" />

      case 'Polygon':
        return <img src={IconPolygon} alt="network-from" />

      default:
        return <Icon name={IconEthereum} id="icon--ethereum" />
    }
  }, [])

  const getModalHeader = useMemo(() => (
    <BaseButton
      onClick={onClose}
      variant="secondary"
      onlyIcon
      iconLeft={(<Icon name={IconClose} id="icon--close" />)}
    />
  ), [onClose])

  const getModalStepper = useMemo(() => {
    let stepObj = {
      state: 'loading',
      text: 'Waiting for sign...',
      active: true,
    }

    if (!isTransactionSignPending) {
      if (isTransactionSigned) {
        if (isBridgeTransactionPending) {
          stepObj.text = 'Loading transaction data...'
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isBridgeTransactionSuccessfull) {
            stepObj = {
              state: 'completed',
              text: 'Transaction completed',
            }
          } else {
            stepObj = {
              state: 'canceled',
              text: 'Transaction failed',
            }
          }
        }
      } else {
        stepObj = {
          state: 'canceled',
          text: 'Bridge canceled',
        }
      }
    }

    const steps = [
      {
        badge: {
          state: stepObj.state,
          text: '1',
        },
        text: stepObj.text,
        active: stepObj.active,
      },
    ]

    return <Stepper steps={steps} />
  }, [isTransactionSignPending, isBridgeTransactionPending, isTransactionSigned, isBridgeTransactionSuccessfull])

  const getModalBody = useMemo(() => {
    let bodyData = {
      type: 'metamask',
      title: 'Please, sign approval transaction',
      subTitle: 'Open your MetaMask and sign',
    }

    if (!isTransactionSignPending) {
      if (isTransactionSigned) {
        if (isBridgeTransactionSuccessfull) {
          bodyData = {
            type: 'completed',
            title: 'Transaction finished successfully',
          }
        } else {
          bodyData = {
            type: 'canceled',
            title: 'Transaction failed',
            subTitle: bridgeTransactionErrorMessage,
          }
        }
      } else {
        bodyData = {
          type: 'canceled',
          title: 'Authentication canceled by you',
        }
      }
    }

    if (isBridgeTransactionPending) {
      return (
        <>
          <div className="bridge-modal__icon-block">
            <Loading />
          </div>
          <BaseTextHeading dataTestid="popup--bridge-status" size="S">Transaction in progress...</BaseTextHeading>
        </>
      )
    }

    return (
      <>
        <Status type={bodyData.type} />
        <BaseTextHeading size="S">{bodyData.title}</BaseTextHeading>
        {
          bodyData.subTitle && (<BaseTextBody size="S">{bodyData.subTitle}</BaseTextBody>)
        }
      </>
    )
  }, [
    isTransactionSignPending,
    isTransactionSigned,
    isBridgeTransactionSuccessfull,
    isBridgeTransactionPending,
    bridgeTransactionErrorMessage,
  ])

  const getCancelButtonTemplate = useMemo(() => {
    if (isBridgeTransactionSuccessfull) {
      return (<BaseButton onClick={onClose}>Close</BaseButton>)
    }
    return (<BaseButton variant="secondary" onClick={onClose}>Cancel</BaseButton>)
  }, [onClose, isBridgeTransactionSuccessfull])

  const getRequestDataForBridge = useCallback(async () => {
    let details = {}
    if (token.address !== NEW_ETH_ADDRESS) {
      const assetValue = floatToERC20(token.decimals, token.value)
      const contract = web3React.library.eth && new web3React.library.eth.Contract(minABI)
      contract.options.address = token.address
      const gasAmount = await contract.methods.transfer(token.depositAddress, assetValue)
        .estimateGas({
          gas: '100000',
          from: internalSelectedAddress,
        })

      details = {
        to: web3React.library.utils.toChecksumAddress(token.address),
        value: '0x0',
        assetValue,
        data: contract
          && token.depositAddress
          && assetValue
          && contract.methods.transfer(token.depositAddress, assetValue).encodeABI(),
        asset: web3React.library.utils.toChecksumAddress(token.address),
        gas: web3React.library.utils.toHex(gasAmount),
      }
    } else {
      details = {
        to: web3React.library.utils.toChecksumAddress(token.depositAddress),
        gas: web3React.library.utils.toHex('23000'),
        value: web3React.library.utils.toHex(floatToERC20(18, token.value).toString()),
        data: '0x0',
        assetValue: floatToERC20(18, token.value),
        asset: web3React.library.utils.toChecksumAddress(ZERO_ADDRESS),
      }
    }

    return {
      external_address: externalAddress,
      transactions: [{
        to: details.to,
        from_address: internalSelectedAddress,
        gas: details.gas,
        data: details.data,
        value: details.value,
      }],
      asset: details.asset,
      asset_value: details.assetValue,
      address: token.depositAddress,
    }
  }, [token, web3React, externalAddress, internalSelectedAddress])

  const stopBridgeTransactionStatusPolling = useCallback(() => {
    if (bridgeTransactionPoller.current) {
      clearInterval(bridgeTransactionPoller.current)
      bridgeTransactionPoller.current = undefined
    }
  }, [])

  const startBridgeTransactionStatusPolling = useCallback((id) => {
    const intervalTimeout = 3000
    bridgeTransactionPoller.current = setInterval(async () => {
      if (!isBridgeTransactionRequestPending) {
        setBridgeTransactionRequestPendingState(true)

        try {
          const details = await checkTransferTransaction(id)
          setBridgeTransactionRequestPendingState(false)

          const transactionStatus = parseOrderStatus(details.data.base_order.status)
          if (transactionStatus === 'ORDER_EXECUTING' || transactionStatus === 'ORDER_EXECUTED') {
            toastSuccess('Transaction has finished successfully')
            setBridgeTransactionSuccessfullState(true)
            setBridgeTransactionIsPending(false)
            stopBridgeTransactionStatusPolling()
          } else if (transactionStatus === 'ORDER_FAILED') {
            const errorMessage = details.data.base_order.activate_log
            const parsedErrorMessage = `${errorMessage.charAt(0).toUpperCase()}${errorMessage.slice(1)}`
            toastCritical(parsedErrorMessage)
            setBridgeTransactionErrorMessageState(parsedErrorMessage)
            setBridgeTransactionSuccessfullState(false)
            setBridgeTransactionIsPending(false)
            stopBridgeTransactionStatusPolling()
          }
        } catch (e) {
          setBridgeTransactionRequestPendingState(false)
          setBridgeTransactionSuccessfullState(false)
          setBridgeTransactionIsPending(false)
          stopBridgeTransactionStatusPolling()
        }
      }
    }, intervalTimeout)
  }, [isBridgeTransactionRequestPending, stopBridgeTransactionStatusPolling])

  const requestUserSign = useCallback(async () => {
    setTransactionSignIsPending(true)
    try {
      const requestData = await getRequestDataForBridge()
      const orderResponse = await apiTransferOrder(requestData)

      const vaultRequestId = orderResponse.data.transactions[0].vault_request_id
      const web3Signature = await web3React.library.eth.personal.sign(
        web3React.library.utils.utf8ToHex(vaultRequestId),
        web3React.account,
      )

      setTransactionIsSigned(true)
      setTransactionSignIsPending(false)
      setBridgeTransactionIsPending(true)

      try {
        await confirmOrder({
          signed_request_id: web3Signature,
          request_id: vaultRequestId,
        })
        startBridgeTransactionStatusPolling(orderResponse.data.id)
      } catch (e) {
        setBridgeTransactionSuccessfullState(false)
        setBridgeTransactionIsPending(false)
      }
    } catch (e) {
      setTransactionIsSigned(false)
      setTransactionSignIsPending(false)
      console.log('Bridge contract request failed = ', e)
    }
  }, [getRequestDataForBridge, web3React, startBridgeTransactionStatusPolling])

  const getSetButtonTemplate = useMemo(() => {
    if (!isTransactionSignPending && !isBridgeTransactionPending) {
      return (
        <BaseButton onClick={() => requestUserSign()}>Request set bridge again</BaseButton>
      )
    }

    return (
      <BaseButton
        disabled={isTransactionSignPending || isBridgeTransactionPending}
        onClick={() => requestUserSign()}
      >
        Set bridge
      </BaseButton>
    )
  }, [isTransactionSignPending, isBridgeTransactionPending, requestUserSign])

  const getAssetsBlocks = useMemo(() => (
    <AssetInfo
      transfer={{
        block1: <AssetBlock
          title="From"
          bodyName={(
            <>
              {getIconForNetwork(networkFrom.name)}
              <BaseTextSubheading>{networkFrom.name}</BaseTextSubheading>
            </>
          )}
          footerLink={(
            <ExternalLink
              text={networkFrom.blockExplorerUrl}
              link={networkFrom.blockExplorerUrl}
            />
          )}
        />,
        block2: <AssetBlock
          title="Transfer"
          bodyName={(
            <IconCurrency
              img={token.icon}
              imgAlt={token.symbol}
              text={token.symbol}
              textClassName="no-bold"
            />
          )}
          bodyValue={getParsedTokenValue(token.value)}
          footerName={token.symbol}
          footerValue={`$${token.price}`}
        />,
        block3: <AssetBlock
          title="To"
          bodyName={(
            <>
              {getIconForNetwork(networkTo.name)}
              <BaseTextSubheading>{networkTo.name}</BaseTextSubheading>
            </>
          )}
          footerLink={(
            <ExternalLink
              text={networkTo.blockExplorerUrl}
              link={networkTo.blockExplorerUrl}
            />
          )}
        />,
      }}
      detail={(
        <List items={[
          {
            name: 'Network fee',
            value: `${totalFee} ${token.symbol} ~($${checkFloatNaN(token.price * totalFee, 0, 2)})`,
          },
        ]}
        />
      )}
    />
  ), [networkFrom, networkTo, getIconForNetwork, totalFee, token])

  const getModalFooter = useMemo(() => (
    <div className="transaction-modal__footer">
      {getAssetsBlocks}

      <div className="transaction-modal__footer__btn">
        {getCancelButtonTemplate}
        {!isBridgeTransactionSuccessfull && getSetButtonTemplate}
      </div>
    </div>
  ), [getAssetsBlocks, getCancelButtonTemplate, isBridgeTransactionSuccessfull, getSetButtonTemplate])

  useEffect(() => {
    (async () => {
      if (!wasTransactionRequestSent) {
        setTransactionRequestWasSent(true)
        await requestUserSign()
      }
    })()
  }, [wasTransactionRequestSent, requestUserSign])

  return (
    <BaseModal
      title="Bridge Setup"
      topAction={getModalHeader}
      headerBottom={getModalStepper}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

TransactionModal.propTypes = {
  token: PropTypes.object,
  totalFee: PropTypes.number,
  networkFrom: PropTypes.object,
  networkTo: PropTypes.object,
  externalAddress: PropTypes.string,
  onClose: PropTypes.func,
  internalSelectedAddress: PropTypes.string,
  // slippage: PropTypes.string,
}

export default TransactionModal
