export const getCustomStyles = () => {
  const tableMinHeight = '250px'

  return {
    table: {
      style: {
        minHeight: tableMinHeight,
        borderRadius: '0',
        border: 'none',
        background: '#282828',
      },
    },
    rows: {
      style: {
        minHeight: 'none',
        padding: '8px 12px',
        background: '#282828',
        border: 'none',
        borderTop: '1px solid rgba(255, 255, 255, 0.08)',
        borderBottom: 'none',
        '&:not(:last-of-type)': {
          borderBottom: 'none',
        },
        '&:hover': {
          background: '#262626',
          cursor: 'pointer',
        },
      },
    },
    headRow: {
      style: {
        padding: '8px 12px',
        minHeight: 'none',
        background: 'rgba(49, 49, 49, 0.8)',
        borderRadius: '0',
      },
    },
    headCells: {
      style: {
        minHeight: '22px',
        padding: '0 12px 0 0',
        background: 'none',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#8E8E8E',
        '& svg': {
          marginLeft: '8px',
          marginRight: '8px',
          opacity: '1 !important',
        },
        '&:first-child': {
          paddingLeft: '0',
        },
        '&:last-child': {
          paddingRight: '0',
        },
      },
      activeSortStyle: {
        color: '#F6F6F6',
        '&:focus': {
          color: '#F6F6F6',
        },
        '&:hover': {
          color: '#F6F6F6',
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: '#F6F6F6',
        },
        '&:hover': {
          color: '#F6F6F6',
        },
      },
    },
    cells: {
      style: {
        padding: '0 12px 0 0',
        '&:first-child': {
          paddingLeft: '0',
        },
        '&:last-child': {
          paddingRight: '0',
        },
        '&:nth-child(2)': {
          '& div:first-child': {
            whiteSpace: 'inherit',
            overflow: 'visible',
            textOverflow: 'inherit',
          },
        },
      },
    },
    noData: {
      style: {
        height: 'max-content',
        position: 'absolute',
        zIndex: '0',
        right: '0',
        top: 'calc(50% - 20px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        background: '#282828',
      },
    },
    progress: {
      style: {
        height: 'max-content',
        position: 'absolute',
        zIndex: '0',
        right: '0',
        top: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
      },
    },
  }
}
