import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { BigNumber } from 'bignumber.js'
import debounce from 'lodash/debounce'

import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import Loading from '../../../../../../../components/Loading/Loading'
import {
  checkFloatNaN,
  floatToERC20,
  checkTokenBalance,
  getAllowance,
  approveTokensData,
} from '../../../../../../../utils/helperFunctions'
import ExchangeItem from '../exchange-item/ExchangeItem'
import IconSwapVert from '../../../../../../../components/Icon/icon--swap-vert.svg'
import { Icon } from '../../../../../../../components/Icon/Icon'
import { AssetInfo } from '../../../../../../../components/AssetInfo/AssetInfo'
import { List } from '../../../../../../../components/List/List'
import { Text } from '../../../../../../../components/Text/Text'
import IconAllInclusive from '../../../../../../../components/Icon/icon--all-inclusive.svg'
import {
  getInitialTokenValue,
  getParsedTokenBalance,
  getParsedTokenValue,
  // convertGasToUsd,
  multiplyBigNumber,
  prepareDataForOrderCreation,
  getPureAddress,
  getErrorMessageDetailsForOrderCreationBasedOnNetwork,
  getSymbolBasedOnNetwork,
  getGasPriceBasedOnNetwork,
  getWrappedNativeTokenAddressBasedOnNetwork,
} from '../../../../../../../parser/data'
import { Alert } from '../../../../../../../components/Alert/Alert'
import { getZeroXData } from '../../../../../../../service/0x'
import IconWallet from '../../../../../../../components/Icon/icon--wallet.svg'
import { loadGasData } from '../../../../../../../service/gas'
import { useWeb3Bn } from '../../../../../../../hooks/web3'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../../../../../../../const'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import TransactionsModal from './transactions-modal/TransactionsModal'
import { sendCreateTokenSwapOrderRequest, getInfoLpPair, getLpTokensPair } from '../../../../../../../service/api'
import { toastCritical, toastSuccess } from '../../../../../../../components/Toast/Toast'
import { AssetField } from '../../../../../../../components/AssetField/AssetField'
import { setOrderCreationBooleanValue } from '../../../../../../../store/slice/trade'

import './market-form.scss'
import { useWeb3 } from '../../../../../../../context/web3Provider'

const MarketForm = ({
  internalSelectedAddress,
  isUpdateTokensRequestPending,
  isLimitForm,
  tokensPair,
  pairAddress,
  isLoading,
  onSwapTokens,
  onSelectFirstToken,
  onSelectSecondToken,
  onUpdateTokenValue,
  cachedTokenValue,
  wasLimitPriceUpdatedByUser,
  onUpdateLimitValue,
  onCacheLimitValue,
  cachedLimit,
}) => {
  const necessaryApproveGas = 60000
  const necessarySwapGas = 250000

  const web3React = useWeb3()
  const BN = useWeb3Bn()
  const dispatch = useDispatch()
  const [tradeSettings] = useLocalStorage('tradeSettings')
  const { isGodModeEnabled, latestNativeTokenPrice, tokens } = useSelector((store) => store.user)

  const [pairPrice, setPairPrice] = useState(0)
  const [isOrderCreationRequestPending, setOrderCreationRequestIsPendingState] = useState(false)
  const [tokenValue, setTokenValue] = useState('0')
  const [isTokenValueInputIsFocused, setTokenValueInputIsFocusedState] = useState(false)
  const [wasTokenValueUpdatedByUser, setTokenValueWasUpdatedByUserState] = useState(false)
  const [receiveTokenValue, setReceiveTokenValueState] = useState('0')
  const [zeroXData, setZeroXData] = useState({})
  const [gasData, setGasData] = useState()
  const [isTokenEnough, setTokenIsEnoughState] = useState(false)
  const [transactions, setTransactionsState] = useState([])
  const [isTransactionsModalShown, setTransactionsModalIsShown] = useState(false)
  const [transactionsModalDetails, setTransactionsModalDetails] = useState()
  const [wrappedRequiredTokenAmountBasedOnNetwork, setWrappedRequiredTokenAmountBasedOnNetworkState] = useState(0)
  const [cachedTokensPair, setCachedTokensPairState] = useState({})
  const [areCalculationsInProgress, setCalculationsAreInProgressState] = useState(false)

  const [nativeTokenBalanceError, setNativeTokenBalanceError] = useState()
  const [sufficientBalanceError, setSufficientBalanceError] = useState()
  const [otherError, setOtherError] = useState()
  const [lpPairError, setLpPairError] = useState()

  const [limitPrice, setLimitPrice] = useState()

  const [cancelTokenSource, setCancelTokenSource] = useState()

  useEffect(() => {
    if (!tradeSettings) {
      writeStorage('tradeSettings', {
        gas: 'fastest',
        slippage: '0.1',
      })
    }
  }, [tradeSettings])

  const getErrorMessageBasedOnNetwork = useCallback((requiredTokenAmountBasedOnNetworkInWrapper) => {
    const message = getErrorMessageDetailsForOrderCreationBasedOnNetwork()
    return (
      <>
        {`Insufficient ${message.title} balance.`}
        <br />
        {requiredTokenAmountBasedOnNetworkInWrapper && (
          `Required amount for gas: ${requiredTokenAmountBasedOnNetworkInWrapper.toString()} ${message.symbol}`
        )}
      </>
    )
  }, [])

  const getTokensTransactions = useCallback(async ({ loadedZeroXData, allowedValue, newTokenValue }) => {
    const newTransactions = []
    let necessaryGas = parseInt(parseFloat(loadedZeroXData.gas) * 1.2, 10) < necessarySwapGas
      ? await web3React.library.utils.toHex(necessarySwapGas)
      : await web3React.library.utils.toHex(parseInt(parseFloat(loadedZeroXData.gas) * 1.2, 10))

    if (![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(tokensPair.token1.address)) {
      if (allowedValue === 0 || allowedValue < newTokenValue) {
        const approveTransactionData = approveTokensData({
          amount: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          tokenAddress: tokensPair.token1.address,
          web3React,
          allowanceTarget: loadedZeroXData.allowanceTarget,
        })

        newTransactions.push({
          to: tokensPair.token1.address,
          from_address: internalSelectedAddress,
          value: '0x0',
          data: approveTransactionData,
          gas: await web3React.library.utils.toHex(necessaryApproveGas),
          name: 'Approve',
        })

        necessaryGas = await web3React.library.utils.toHex(necessarySwapGas)
      }
    } else {
      newTransactions.push({
        to: web3React.library.utils.toChecksumAddress(loadedZeroXData.to),
        from_address: internalSelectedAddress,
        value: await web3React.library.utils.toHex(multiplyBigNumber(newTokenValue)),
        data: loadedZeroXData.data,
        gas: necessaryGas,
        name: 'Swap',
      })

      return newTransactions
    }

    newTransactions.push({
      to: web3React.library.utils.toChecksumAddress(loadedZeroXData.to),
      from_address: internalSelectedAddress,
      value: '0x0',
      data: loadedZeroXData.data,
      gas: necessaryGas,
      name: 'Swap',
    })

    return newTransactions
  }, [tokensPair, web3React, internalSelectedAddress])

  const generateTransactions = useCallback(async ({
    isEnough, loadedZeroXData, allowedValue, newTokenValue,
  }) => {
    if (isEnough) {
      try {
        setTransactionsState(await getTokensTransactions({ loadedZeroXData, allowedValue, newTokenValue }))
      } catch (e) {
        setTransactionsState([])
      }
    }
  }, [getTokensTransactions])

  const additionalCheckThatChosenTokenHasSufficientBalance = useCallback((newTokenValue) => {
    const wrappedExactTokenBalance = new BigNumber(tokensPair.token1.exactTokenBalance)
    const wrappedTokenValue = new BigNumber(newTokenValue)

    if (tokensPair.token1.exactTokenBalance === '0' || newTokenValue === '0' || wrappedExactTokenBalance.lt(wrappedTokenValue)) {
      return false
    }
    return true
  }, [tokensPair])

  const checkWhetherIsTokenEnough = useCallback(async ({
    loadedZeroXData, loadedGasData, newTokenValue,
  }) => {
    const parsedGasPriceFromStation = getGasPriceBasedOnNetwork(loadedGasData[tradeSettings.gas])
    let { gas } = loadedZeroXData
    let allowedValue
    let isFirstTokenBalanceEnough = true

    if (![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(tokensPair.token1.address)) {
      allowedValue = await getAllowance({
        tokenAddress: tokensPair.token1.address,
        allowanceTarget: loadedZeroXData.allowanceTarget,
        userAddress: internalSelectedAddress,
        web3React,
      })

      if (allowedValue === 0 || allowedValue < newTokenValue) {
        gas = necessaryApproveGas + necessarySwapGas
      }
    }

    const checkObj = await checkTokenBalance({
      userTokens: tokens,
      BN,
      gas,
      gasPrice: parsedGasPriceFromStation,
      internalSelectedAddress,
      web3React,
      firstTokenAddress: tokensPair.token1.address,
      tokenValue: newTokenValue,
    })

    let { isEnough } = checkObj
    const { requiredTokenAmountBasedOnNetworkInWrapper } = checkObj
    setWrappedRequiredTokenAmountBasedOnNetworkState(requiredTokenAmountBasedOnNetworkInWrapper)

    if (isEnough) {
      isFirstTokenBalanceEnough = additionalCheckThatChosenTokenHasSufficientBalance(newTokenValue)
      isEnough = isFirstTokenBalanceEnough
    }

    generateTransactions({
      isEnough, loadedZeroXData, allowedValue, newTokenValue,
    })

    setTokenIsEnoughState(isEnough)

    let errorMessage
    if (!isFirstTokenBalanceEnough) {
      errorMessage = `Insufficient ${tokensPair.token1.symbol} balance.`
    } else if (!isEnough) {
      errorMessage = getErrorMessageBasedOnNetwork(requiredTokenAmountBasedOnNetworkInWrapper)
    }
    setNativeTokenBalanceError(errorMessage)
    setCalculationsAreInProgressState(false)
  }, [
    tradeSettings,
    web3React,
    tokens,
    BN,
    internalSelectedAddress,
    tokensPair,
    additionalCheckThatChosenTokenHasSufficientBalance,
    generateTransactions,
    getErrorMessageBasedOnNetwork,
  ])

  const handleZeroXErrors = useCallback((errorData) => {
    if (errorData && errorData.external_errors && errorData.external_errors.length > 0) {
      if (errorData.external_errors[0].reason === 'INSUFFICIENT_ASSET_LIQUIDITY') {
        toastCritical('The liquidity of the chosen token is insufficient')
        setOtherError('Insufficient token liquidity')
        setCalculationsAreInProgressState(false)
      }
    }
  }, [])

  const loadZeroXData = useCallback(async (newTokenValue, cancelToken) => {
    const amount = floatToERC20(
      tokensPair.token1.decimals,
      // eslint-disable-next-line no-nested-ternary
      newTokenValue ? (newTokenValue !== '0' ? newTokenValue : 1) : (tokenValue !== '0' ? tokenValue : 1),
    )
    try {
      const loadedZeroXData = await getZeroXData({
        cyberWallet: internalSelectedAddress,
        sellAddress: getPureAddress(tokensPair.token1.address),
        buyAddress: getPureAddress(tokensPair.token2.address),
        amount,
        slippage: tradeSettings.slippage,
        cancelToken,
      })
      setZeroXData(loadedZeroXData)
      return loadedZeroXData
    } catch (e) {
      const errorData = e.response.data
      console.log('Failed loading zerox data = ', errorData)
      handleZeroXErrors(errorData)
      setZeroXData({ gas: 0, sources: [{ name: '-' }] })
      return undefined
    }
  }, [tokenValue, internalSelectedAddress, tokensPair.token1, tokensPair.token2, tradeSettings, handleZeroXErrors])

  const getLoadedGasData = useCallback(async (cancelToken) => {
    try {
      const loadedGasData = await loadGasData(cancelToken)
      setGasData(loadedGasData)
      return loadedGasData
    } catch (e) {
      setGasData()
      setOtherError('Gas data is not available')
      return undefined
    }
  }, [])

  const loadNecessaryData = useCallback(async (newTokenValue) => {
    try {
      const newCancelTokenSource = axios.CancelToken.source()
      const cancelToken = newCancelTokenSource.token
      setCancelTokenSource(newCancelTokenSource)

      setCalculationsAreInProgressState(true)
      const loadedZeroXData = await loadZeroXData(newTokenValue, cancelToken)
      const loadedGasData = await getLoadedGasData(cancelToken)

      if (loadedZeroXData && loadedGasData) {
        await checkWhetherIsTokenEnough({
          loadedZeroXData, loadedGasData, newTokenValue: newTokenValue || tokenValue,
        })
      }
    } catch (e) {
      console.log('Necessary data loading error = ', e)
    }
  }, [loadZeroXData, getLoadedGasData, checkWhetherIsTokenEnough, tokenValue])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadNecessaryDataWithDebounce = useCallback(debounce(async (newValue) => loadNecessaryData(newValue), 1000), [loadNecessaryData])

  const updateReceiveTokenValue = useCallback(() => {
    const wrappedTokenValue = new BigNumber(tokenValue)
    const wrappedPrice = new BigNumber(pairPrice)
    setReceiveTokenValueState(wrappedTokenValue.multipliedBy(wrappedPrice).toString())
  }, [tokenValue, pairPrice])

  const checkThatBalanceIsSufficient = useCallback(() => {
    const exactTokenBalance = new BigNumber(tokensPair.token1.exactTokenBalance)
    const exactTokenValue = new BigNumber(tokenValue)
    setSufficientBalanceError(exactTokenBalance.lt(exactTokenValue) ? `Insufficient ${tokensPair.token1.symbol} balance` : undefined)
  }, [tokensPair.token1, tokenValue])

  useEffect(() => {
    if (!isLoading && (Object.keys(zeroXData).length === 0 || JSON.stringify(cachedTokensPair) !== JSON.stringify(tokensPair))) {
      if (!wasTokenValueUpdatedByUser) {
        const initialTokenValue = !cachedTokenValue ? getInitialTokenValue(tokensPair.token1.exactTokenBalance) : cachedTokenValue
        loadNecessaryData(initialTokenValue)
        setTokenValue(initialTokenValue)
      } else {
        loadNecessaryData(tokenValue)
      }
      checkThatBalanceIsSufficient()
    }
  }, [
    isLoading,
    zeroXData,
    cachedTokensPair,
    tokensPair,
    loadNecessaryData,
    wasTokenValueUpdatedByUser,
    checkThatBalanceIsSufficient,
    tokenValue,
    cachedTokenValue,
  ])

  useEffect(() => {
    if (JSON.stringify(cachedTokensPair) !== JSON.stringify(tokensPair)) {
      setCachedTokensPairState(tokensPair)
    }
  }, [cachedTokensPair, tokensPair])

  useEffect(() => {
    if (!isLoading) {
      const tmpPairPrice = tokensPair.token2.usdtPrice > 0
        ? checkFloatNaN(tokensPair.token1.usdtPrice / tokensPair.token2.usdtPrice, 0, 8)
        : 0
      setPairPrice(tmpPairPrice)
      const newLimitValue = !wasLimitPriceUpdatedByUser ? tmpPairPrice : cachedLimit
      setLimitPrice(newLimitValue)
      onCacheLimitValue(`${newLimitValue}`)
      updateReceiveTokenValue()
      checkThatBalanceIsSufficient()
    }
  }, [
    isLoading,
    tokensPair.token1,
    tokensPair.token2,
    updateReceiveTokenValue,
    checkThatBalanceIsSufficient,
    wasLimitPriceUpdatedByUser,
    limitPrice,
    onCacheLimitValue,
    cachedLimit,
  ])

  const getBalance = useMemo(() => (
    !tokensPair.token1
      ? <Loading size="S" />
      : (
        <>
          <BaseTextBody>{getParsedTokenBalance(tokensPair.token1.exactTokenBalance)}</BaseTextBody>
          <BaseTextBody>{tokensPair.token1.symbol}</BaseTextBody>
        </>
      )
  ), [tokensPair.token1])

  const getAdditionalDetailsList = useMemo(() => {
    const offeredBy = {
      name: 'Offered by',
      value: (
        zeroXData.sources && zeroXData.sources[0]
          ? zeroXData.sources[0].name
          : <Loading size="S" />
      ),
      valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
    }

    if (!isLimitForm) {
      setLpPairError()

      // TODO: remove this once we are completely sure that the network fee calculations are valid
      // const gasInUsd = gasData ? convertGasToUsd(gasData[tradeSettings.gas], latestNativeTokenPrice) : undefined
      const tokenValueInUsd = wrappedRequiredTokenAmountBasedOnNetwork
        ? wrappedRequiredTokenAmountBasedOnNetwork.toString() * latestNativeTokenPrice
        : undefined

      return [
        {
          name: 'Min. Received',
          value: `0 ${getSymbolBasedOnNetwork()}`,
        },
        {
          name: 'Rate',
          value: tokensPair.token1 && <Text text={`1 ${tokensPair.token1.symbol} = ${pairPrice} ${tokensPair.token2.symbol}`} />,
        },
        offeredBy,
        {
          name: 'Price Slippage',
          value: `${10 * (tradeSettings ? tradeSettings.slippage : 0)}%`,
        },
        {
          name: 'Network Fee',
          value: (
            <div className="network-fee-block">
              {wrappedRequiredTokenAmountBasedOnNetwork ? `$${checkFloatNaN(tokenValueInUsd, 0, 2)}` : <Loading size="S" />}
              <span className="values-divider">•</span>
              {tradeSettings ? `${tradeSettings.gas.charAt(0).toUpperCase()}${tradeSettings.gas.slice(1)}` : 'Fastest'}
            </div>
          ),
        },
      ]
    }

    return [offeredBy]
  }, [
    isLimitForm, latestNativeTokenPrice, tokensPair.token1, pairPrice,
    tokensPair.token2, tradeSettings, zeroXData, wrappedRequiredTokenAmountBasedOnNetwork,
  ])

  const getFirstTokenUsdtPrice = useCallback(() => (
    tokensPair.token1 ? checkFloatNaN(tokensPair.token1.usdtPrice * tokenValue, 0, 2) : 0
  ), [tokensPair, tokenValue])

  // console.log('tokensPair = ', tokensPair.token1)
  const getFirstTokenItem = useMemo(() => (
    <ExchangeItem
      dataTestid="trade--side-panel--first-token"
      selectedToken={tokensPair.token1}
      isMaxButtonEnabled
      areUserTokensUsed
      onMaxButtonClick={() => {
        const maxValue = tokensPair.token1.exactTokenBalance
        setTokenValue(maxValue)
        setTokenValueWasUpdatedByUserState(true)
        loadNecessaryData(tokensPair.token1.exactTokenBalance !== '0' ? tokensPair.token1.exactTokenBalance : 1)
        onUpdateTokenValue(maxValue)
      }}
      tokenValue={!isTokenValueInputIsFocused && !wasTokenValueUpdatedByUser ? getParsedTokenValue(tokenValue) : tokenValue}
      tokenPrice={getFirstTokenUsdtPrice()}
      onChangeTokenValue={((value) => {
        if (cancelTokenSource) {
          cancelTokenSource.cancel()
        }
        setTokenValue(value)
        updateReceiveTokenValue()
        loadNecessaryDataWithDebounce(value !== '0' ? value : 1)
        setTokenValueWasUpdatedByUserState(true)
        onUpdateTokenValue(value)
      })}
      onFocus={() => setTokenValueInputIsFocusedState(true)}
      onFocusOut={() => setTokenValueInputIsFocusedState(false)}
      onSelectToken={(newToken) => {
        setLpPairError()
        onSelectFirstToken(newToken)
      }}
    />
  ), [
    tokensPair.token1,
    loadNecessaryData,
    tokenValue,
    isTokenValueInputIsFocused,
    wasTokenValueUpdatedByUser,
    updateReceiveTokenValue,
    loadNecessaryDataWithDebounce,
    onSelectFirstToken,
    getFirstTokenUsdtPrice,
    cancelTokenSource,
    onUpdateTokenValue,
  ])

  const getAddressForLpPairRequest = useCallback((address) => (
    web3React.library.utils.toChecksumAddress(address !== NEW_ETH_ADDRESS
      ? address
      : getWrappedNativeTokenAddressBasedOnNetwork())
  ), [web3React])

  const getTriggers = useCallback(async () => {
    let triggerPair = pairAddress
    if (!pairAddress) {
      const pair = await getLpTokensPair({
        address1: getAddressForLpPairRequest(tokensPair.token1.address),
        address2: getAddressForLpPairRequest(tokensPair.token2.address),
      })

      if (pair) {
        triggerPair = pair.address
      }
    }

    if (triggerPair) {
      const { data: { price } } = await getInfoLpPair(triggerPair)
      return {
        trigger_price: limitPrice,
        trigger_pair: triggerPair,
        start_price: price,
      }
    }

    const errorMessage = 'LP pair does not exist for chosen tokens'
    setLpPairError(errorMessage)
    throw errorMessage
  }, [pairAddress, tokensPair, getAddressForLpPairRequest, limitPrice])

  const onCreateOrder = useCallback(async () => {
    setOrderCreationRequestIsPendingState(true)
    try {
      await loadNecessaryData()

      let triggers = []
      if (isLimitForm) {
        triggers = [await getTriggers()]
      }

      const dataForOrderCreation = prepareDataForOrderCreation({
        web3React,
        transactions,
        triggers,
        tokenValue,
        firstToken: tokensPair.token1,
        secondToken: tokensPair.token2,
      })

      const details = await sendCreateTokenSwapOrderRequest(dataForOrderCreation)
      if (!isGodModeEnabled) {
        setTransactionsModalDetails(details)
        setTransactionsModalIsShown(true)
      } else {
        toastSuccess('Order has been successfully created')
        dispatch(setOrderCreationBooleanValue(true))
      }
      setOrderCreationRequestIsPendingState(false)
    } catch (e) {
      console.log('Order creation error = ', e.response || e)
      toastCritical('Order creation failed')
      setOrderCreationRequestIsPendingState(false)
    }
  }, [isLimitForm, web3React, transactions, tokenValue, tokensPair, isGodModeEnabled, dispatch, getTriggers, loadNecessaryData])

  const doErrorsExist = useCallback(() => (
    nativeTokenBalanceError || sufficientBalanceError || otherError || lpPairError
  ), [nativeTokenBalanceError, sufficientBalanceError, otherError, lpPairError])

  const getFormControlsBlock = useMemo(() => (
    <div className="form-controls-block">
      <BaseButton
        isBlock
        disabled={
          !!doErrorsExist() || isOrderCreationRequestPending || !tradeSettings || !gasData
          || Object.keys(zeroXData).length === 0 || !isTokenEnough
          || transactions.length === 0 || isUpdateTokensRequestPending || areCalculationsInProgress
        }
        onClick={onCreateOrder}
        dataTestid="CreateOrder"
      >
        {
          !isOrderCreationRequestPending && tradeSettings && gasData
          && Object.keys(zeroXData).length > 0 && !isUpdateTokensRequestPending && !areCalculationsInProgress
            ? (
              <div className="create-order-button">
                Create Order
                {' '}
                {isGodModeEnabled && (<Icon mod="icon--14" name={IconAllInclusive} id="icon--all-inclusive" />)}
              </div>
            )
            : <Loading />
        }
      </BaseButton>
    </div>
  ), [
    isOrderCreationRequestPending,
    tradeSettings,
    gasData,
    zeroXData,
    isGodModeEnabled,
    isTokenEnough,
    transactions,
    onCreateOrder,
    isUpdateTokensRequestPending,
    areCalculationsInProgress,
    doErrorsExist,
  ])

  const getSecondTokenUsdtPrice = useCallback(() => (tokensPair.token2
    ? checkFloatNaN(tokensPair.token2.usdtPrice * receiveTokenValue, 0, 2)
    : 0),
  [tokensPair, receiveTokenValue])

  const getLimitInput = useMemo(() => isLimitForm && tokensPair.token2 && (
    <AssetField
      inputType="number"
      value={limitPrice}
      name="Limit Price"
      onChange={(event) => {
        const newLimitValue = event.target.value >= 0 ? event.target.value : (-1) * event.target.value
        setLimitPrice(newLimitValue)
        onCacheLimitValue(`${newLimitValue}`)
        onUpdateLimitValue()
      }}
      asset={tokensPair.token2.symbol}
      placeholder="0"
      dataTestid="LimitPrice"
    />
  ), [isLimitForm, limitPrice, tokensPair.token2, onCacheLimitValue, onUpdateLimitValue])

  const getFormBody = useMemo(() => (
    <div className="form-container__body">
      {getFirstTokenItem}
      {getLimitInput}

      <div data-testid="trade--side-panel--switch" className="swap-control-block">
        <BaseButton onClick={onSwapTokens} variant="secondary" onlyIcon iconLeft={(<Icon name={IconSwapVert} id="icon--swap-vert" mod="icon--24" />)} />
      </div>

      <BaseTextBody secondary>You Receive</BaseTextBody>
      <ExchangeItem
        dataTestid="trade--side-panel--second-token"
        selectedToken={tokensPair.token2}
        tokenValue={getParsedTokenValue(receiveTokenValue)}
        tokenPrice={getSecondTokenUsdtPrice()}
        onSelectToken={(newToken) => {
          setLpPairError()
          onSelectSecondToken(newToken)
        }}
        isInputDisabled
      />

      <div
        data-testid="trade--side-panel--info-transaction"
        className={`additional-details-block ${isLimitForm ? 'is-limit' : ''}`}
      >
        <AssetInfo detail={<List noBorder items={getAdditionalDetailsList} />} />
      </div>

      {getFormControlsBlock}

      {doErrorsExist() && (
        <div data-testid="trade--side-panel--error" className="form-errors-block">
          <Alert error size="s">{nativeTokenBalanceError || sufficientBalanceError || otherError || lpPairError}</Alert>
        </div>
      )}
    </div>
  ), [
    getFirstTokenItem,
    getLimitInput,
    tokensPair.token2,
    getAdditionalDetailsList,
    receiveTokenValue,
    onSwapTokens,
    onSelectSecondToken,
    getFormControlsBlock,
    getSecondTokenUsdtPrice,
    isLimitForm,
    doErrorsExist,
    nativeTokenBalanceError,
    sufficientBalanceError,
    otherError,
    lpPairError,
  ])

  const getForm = useMemo(() => (
    <>
      <div className="form-container__header">
        <div className="title">
          <BaseTextBody secondary>You Pay</BaseTextBody>
        </div>
        <div data-testid="trade--side-panel--first-token--balance" className="balance-block">
          <BaseTextBody>Balance:</BaseTextBody>
          {getBalance}
        </div>
      </div>

      {getFormBody}
    </>
  ), [getBalance, getFormBody])

  return (
    <div className={`form-container ${!isLoading ? '' : 'loading'}`}>
      {!isLoading ? getForm : <Loading />}
      {
        isTransactionsModalShown && (
          <TransactionsModal
            isGodModeEnabled={isGodModeEnabled}
            tokensPair={{
              token1: {
                ...tokensPair.token1,
                value: tokenValue,
                currentUsdtPriceBasedOnValue: getFirstTokenUsdtPrice(),
              },
              token2: {
                ...tokensPair.token2,
                value: receiveTokenValue,
                currentUsdtPriceBasedOnValue: getSecondTokenUsdtPrice(),
              },
            }}
            details={transactionsModalDetails}
            networkFeeInEth={wrappedRequiredTokenAmountBasedOnNetwork ? wrappedRequiredTokenAmountBasedOnNetwork.toString() : undefined}
            networkFeeInUsd={
              wrappedRequiredTokenAmountBasedOnNetwork
                ? `$${checkFloatNaN(wrappedRequiredTokenAmountBasedOnNetwork.toString() * latestNativeTokenPrice, 0, 2)}`
                : undefined
            }
            onClose={() => setTransactionsModalIsShown(false)}
          />
        )
      }
    </div>
  )
}

MarketForm.propTypes = {
  internalSelectedAddress: PropTypes.string,
  isUpdateTokensRequestPending: PropTypes.bool,
  isLimitForm: PropTypes.bool,
  tokensPair: PropTypes.object,
  pairAddress: PropTypes.string,
  isLoading: PropTypes.bool,
  onSwapTokens: PropTypes.func,
  onSelectFirstToken: PropTypes.func,
  onSelectSecondToken: PropTypes.func,
  onUpdateTokenValue: PropTypes.func,
  cachedTokenValue: PropTypes.string,
  wasLimitPriceUpdatedByUser: PropTypes.bool,
  onUpdateLimitValue: PropTypes.func,
  onCacheLimitValue: PropTypes.func,
  cachedLimit: PropTypes.string,
}

export default MarketForm
