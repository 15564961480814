import React, {
  useEffect,
} from 'react'
import { writeStorage } from '@rehooks/local-storage'
import { LIST_WEB3_PROVIDER } from '../../../../const/web3'
import { LIST_CONNECTOR_PROVIDER } from '../../../../service/web3'
import GlobalLoading from '../../../../components/GlobalLoading/GlobalLoading'

const ConnectGoogleModal = () => {
  useEffect(() => {
    (async () => {
      const providerMagicConnect = LIST_CONNECTOR_PROVIDER[LIST_WEB3_PROVIDER.magic]

      writeStorage('selectedProvider', LIST_WEB3_PROVIDER.magic)
      await providerMagicConnect.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI: `${window.location.origin}/oauth`,
      })
    })()
  }, [])

  return (
    <GlobalLoading />
  )
}

export default ConnectGoogleModal
