import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { toastCritical } from '../../../../../components/Toast/Toast'
import { accessMessage } from '../../../../../service/api'
import { Icon } from '../../../../../components/Icon/Icon'
import IconAdd from '../../../../../components/Icon/icon--add.svg'
import CreateCyberWalletModal from './create-cyber-wallet-modal/CreateCyberWalletModal'
import CyberWalletAccount from './cyber-wallet-account/CyberWalletAccount'
import { CommonButton } from '../../../../../components/CommonButton/CommonButton'
import { getMessage } from '../../../../../store/slice/user'
import Loading from '../../../../../components/Loading/Loading'
import IconLink from '../../../../../components/Icon/icon--link.svg'

import './cyber-wallet.scss'
import { useWeb3 } from '../../../../../context/web3Provider'

const CyberWallet = ({
  ownerAccounts,
  userState,
  isCyberWalletAdressesListOpened,
  onToggleAddessesListOpenedState,
}) => {
  const dispatch = useDispatch()
  const web3React = useWeb3()
  const [isCyberWalletCreationModalShown, setCyberWalletCreationModalShownState] = useState(false)
  const [isSignApprovePending, setSignApprovePending] = useState(true)
  const [isSignApproved, setSignApprove] = useState(false)
  const [signature, setSignature] = useState('')
  const [signMessage, setSignMessage] = useState('')

  const [isConnectSignApprovePending, setConnectSignApprovePendingState] = useState(false)

  const onCloseModal = () => {
    setCyberWalletCreationModalShownState(false)
    setSignApprovePending(true)
  }

  const onStartCyberWalletConnection = useCallback(() => {
    (async () => {
      if (web3React.library) {
        setConnectSignApprovePendingState(true)
        try {
          await dispatch(getMessage({ account: ownerAccounts, web3React }))
          setConnectSignApprovePendingState(false)
        } catch (e) {
          setConnectSignApprovePendingState(false)
        }
      } else {
        setConnectSignApprovePendingState(false)
        toastCritical('Your Metamask should be connected to Ethereum Mainnet, but it is connected to some other network')
      }
    })()
  }, [web3React, dispatch, ownerAccounts])

  const connectCyberWalletButton = useMemo(() => (
    <CommonButton
      disabled={isConnectSignApprovePending}
      className="glass"
      text="Connect Cyber Wallet"
      iconLeft={
        !isConnectSignApprovePending
          ? <Icon name={IconLink} id="icon--link" />
          : <Loading />
      }
      onClick={onStartCyberWalletConnection}
    />
  ), [isConnectSignApprovePending, onStartCyberWalletConnection])

  const onStartCyberWalletCreation = useCallback(() => {
    (async () => {
      if (web3React.library) {
        setCyberWalletCreationModalShownState(true)
        try {
          const { data: { message } } = await accessMessage()
          const web3Signature = await web3React.library.eth.personal.sign(message, web3React.account)
          setSignMessage(message)
          setSignature(web3Signature)
          setSignApprove(true)
          setSignApprovePending(false)
        } catch (e) {
          setSignApprove(false)
          setSignApprovePending(false)
        }
      } else {
        setSignApprovePending(false)
        toastCritical('Your Metamask should be connected to Ethereum Mainnet, but it is connected to some other network')
      }
    })()
  }, [web3React])

  const createCyberWalletButton = useMemo(() => (
    <CommonButton
      className="glass"
      text="Create Cyber Wallet"
      iconLeft={<Icon name={IconAdd} id="icon--add" />}
      onClick={onStartCyberWalletCreation}
    />
  ), [onStartCyberWalletCreation])

  const controlElement = useMemo(() => {
    if (userState.internalAddresses.length > 0) {
      return (
        <CyberWalletAccount
          userState={userState}
          onCreateNewCyberWallet={onStartCyberWalletCreation}
          isCyberWalletAdressesListOpened={isCyberWalletAdressesListOpened}
          onToggleAddessesListOpenedState={onToggleAddessesListOpenedState}
        />
      )
    }

    if (userState.accessToken) {
      return createCyberWalletButton
    }

    return connectCyberWalletButton
  }, [
    userState,
    connectCyberWalletButton,
    createCyberWalletButton,
    onStartCyberWalletCreation,
    isCyberWalletAdressesListOpened,
    onToggleAddessesListOpenedState,
  ])

  useEffect(() => {
    if (userState.accessToken && userState.internalAddresses.length === 0 && !isCyberWalletCreationModalShown) {
      onStartCyberWalletCreation()
    }
  }, [userState, onStartCyberWalletCreation, isCyberWalletCreationModalShown])

  return (
    <>
      <div className="cyber-wallet__container">
        {controlElement}
      </div>
      {isCyberWalletCreationModalShown && (
        <CreateCyberWalletModal
          isSignApprovePending={isSignApprovePending}
          isSignApproved={isSignApproved}
          signMessage={signMessage}
          signature={signature}
          onCreateCyberWallet={() => {
            setSignApprovePending(true)
            setSignApprove(false)
            onStartCyberWalletCreation()
          }}
          onCloseModal={onCloseModal}
          onCreateAnotherCyberWallet={() => {
            setSignApprovePending(true)
            setSignApprove(false)
            onStartCyberWalletCreation()
          }}
        />
      )}
    </>
  )
}

CyberWallet.propTypes = {
  ownerAccounts: PropTypes.array,
  userState: PropTypes.object,
  isCyberWalletAdressesListOpened: PropTypes.bool,
  onToggleAddessesListOpenedState: PropTypes.func,
}

export default CyberWallet
