import React from 'react'
import PropTypes from 'prop-types'
import { H2 } from '../H2/H2'
import './list-history.scss'
import { generateUniqueKey } from '../../utils/helperFunctions'

export const ListHistory = ({
  className,
  title,
  items,
}) => (
  <div className={`list-history ${className || ''}`}>
    <div className="list-history__header">
      <H2
        className="list-history__title"
        text={title}
      />
    </div>

    <ul className="list-history__body">
      {items.map((item) => (
        <li className="list-history__item" key={generateUniqueKey()}>
          {item}
        </li>
      ))}
    </ul>
  </div>
)

ListHistory.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.node])),
}
