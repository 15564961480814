import React, {
  useState, useMemo, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconAdd from '../../../../../../../components/Icon/icon--add.svg'
import IconArrowRight from '../../../../../../../components/Icon/icon--keyboard-arrow-right.svg'
import { TYPES_WALLET } from '../../../../../../../const'
import { Divider } from '../../../../../../../components/Divider/Divider'
import { formatEth } from '../../../../../../../utils/helperFunctions'
import AddressBlock from './address-block/AddressBlock'
import { getDataSelectWallet } from '../../../../../../../service/api'
import { getUserTokensSum } from '../../../../../../../parser/data'
import Loading from '../../../../../../../components/Loading/Loading'

import './addresses-list.scss'
import { useWeb3Bn } from '../../../../../../../hooks/web3'
import { useWeb3 } from '../../../../../../../context/web3Provider'

const AddressesList = ({
  addresses,
  onTransfer,
  onDeposit,
  onRename,
  onCreateNewCyberWallet,
  onDelete,
  onExtractPrivateKey,
  onOpenModalGodMode,
}) => {
  const Web3BN = useWeb3Bn()
  const { latestNativeTokenPrice, balance, isGodModeEnabled } = useSelector((store) => store.user)
  const web3React = useWeb3()
  const [activeCyberWallet, setActiveCyberWallet] = useState(useLocalStorage('internalSelectedAddress')[0])
  const [openedCyberWallet, setOpenedCyberWalletState] = useState({
    name: '',
    address: '',
    totalBalance: undefined,
  })
  const [addressesWithBalances, setAddressesWithBalancesState] = useState([])

  useEffect(() => {
    document.addEventListener('click', (evt) => {
      const flyoutElement = document.getElementById('addresses-list-block')
      let targetElement = evt.target
      do {
        if (targetElement === flyoutElement) {
          return
        }
        targetElement = targetElement.parentNode
      } while (targetElement)

      setOpenedCyberWalletState({})
    })
  }, [])

  useEffect(() => {
    (async () => {
      if (addresses.length > 0 && web3React.library?.utils?.fromWei) {
        const balances = []

        const promises = []
        addresses.forEach((item) => {
          balances.push({
            ...item,
            totalBalance: 0,
          })
          promises.push((async () => (getDataSelectWallet(item.address)))())
        })

        const details = await Promise.all(promises)
        details.forEach((res, index) => {
          const sum = getUserTokensSum({
            tokens: res.tokens,
            BN: Web3BN,
            web3React,
            latestNativeTokenPrice,
          }).totalSum
          balances[index].totalBalance = formatEth(sum, 2)
        })

        setAddressesWithBalancesState(balances)
      }
    })()
  }, [Web3BN, addresses, balance, web3React, latestNativeTokenPrice, isGodModeEnabled])

  const selectCyberAddress = (cyberWallet) => {
    writeStorage('selectedAddress', cyberWallet.address)
    writeStorage('internalSelectedAddress', cyberWallet.address)
    writeStorage('selectedWallet', TYPES_WALLET.internal)
    setActiveCyberWallet(cyberWallet.address)
    writeStorage('handleSelected', true)
  }

  const getAddresses = useMemo(
    () => addressesWithBalances.map((cyberWallet, key) => (
      <AddressBlock
        key={key}
        onOpenModalGodMode={onOpenModalGodMode}
        cyberWallet={cyberWallet}
        openedCyberWallet={openedCyberWallet}
        activeCyberWallet={activeCyberWallet}
        onOpenSubmenu={setOpenedCyberWalletState}
        onSelectAddress={selectCyberAddress}
        onTransfer={onTransfer}
        onDeposit={onDeposit}
        onRename={onRename}
        onDelete={onDelete}
        onExtractPrivateKey={onExtractPrivateKey}
      />
    )), [
      onExtractPrivateKey,
      onOpenModalGodMode,
      addressesWithBalances,
      openedCyberWallet,
      activeCyberWallet,
      onTransfer,
      onDeposit,
      onRename,
      onDelete,
    ],
  )

  const list = useMemo(() => (
    <div id="addresses-list-block" className={`cyber-wallet__addresses-list-container ${addresses && addresses.length !== addressesWithBalances.length ? 'loading' : ''}`}>
      {
        addresses && addresses.length === addressesWithBalances.length ? (
          <>
            <div data-testid="sidebar--cyber-wallets--open" className="addresses-list">
              {getAddresses}
            </div>
            <Divider />
            <div className="create-new-cyber-wallet-button" onClick={onCreateNewCyberWallet}>
              <Icon
                name={IconAdd}
                id="icon--add"
                mod="icon--12"
              />
              <div data-testid="create-new-wallet-button" className="title">
                <BaseTextBody>
                  Create New Cyber Wallet
                </BaseTextBody>
              </div>
              <div className="right-icon">
                <Icon
                  name={IconArrowRight}
                  id="icon--keyboard-arrow-right"
                />
              </div>
            </div>
          </>
        ) : <Loading />
      }
    </div>
  ), [addresses, addressesWithBalances, getAddresses, onCreateNewCyberWallet])

  return list
}

AddressesList.propTypes = {
  addresses: PropTypes.array,
  onTransfer: PropTypes.func,
  onDeposit: PropTypes.func,
  onRename: PropTypes.func,
  onOpenModalGodMode: PropTypes.func,
  onCreateNewCyberWallet: PropTypes.func,
  onExtractPrivateKey: PropTypes.func,
  onDelete: PropTypes.func,
}

export default AddressesList
