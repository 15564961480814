import React from 'react'
import PropTypes from 'prop-types'
import { MainHistory } from '../MainHistory/MainHistory'
import './body-history.scss'

export const BodyHistory = ({
  className,
}) => (
  <div className={`body-history ${className}`}>
    <MainHistory />
  </div>
)

BodyHistory.propTypes = {
  className: PropTypes.string,
}
BodyHistory.defaultProps = {
  className: '',
}
