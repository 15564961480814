import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import './base-history.scss'
import { Pagination } from '../Pagination/Pagination'
import { HistoryCategory } from '../HistoryCategory/HistoryCategory'
import { BaseSearch } from '../BaseSearch/BaseSearch'
import Loading from '../Loading/Loading'
import { DataTableEmptyContent } from '../DataTable/empty/DataTableEmpty'

export const BaseHistory = ({
  historyData,
  rowCount,
  currentPage,
  isLoading,
  onChangePage,
  onChangeSearch,
  searchValue,
}) => {
  const rowsHistory = useMemo(() => {
    let date = ''
    const formatData = []

    historyData.forEach((row) => {
      if (date !== row.title) {
        formatData.push({
          title: row.title,
          data: [row.data],
        })

        date = row.title
      } else {
        formatData[formatData.length - 1].data.push(row.data)
      }
    })

    return (
      <>
        <div className="base-history__list">
          {formatData.map((item, index) => (
            <div className="base-history__item" key={index}>
              <HistoryCategory
                title={item.title}
                items={item.data}
              />
            </div>
          ))}
        </div>
      </>
    )
  }, [historyData])

  const bodyTable = useMemo(() => {
    if (isLoading) {
      return <div className="base-history__loading"><Loading /></div>
    }
    if (!Array.isArray(historyData) || historyData.length < 1) {
      return <div className="base-history__loading"><DataTableEmptyContent /></div>
    }

    return rowsHistory
  }, [historyData, isLoading, rowsHistory])

  return (
    <div data-testid="dashboard-history" className="base-history">
      <div className="base-history__search">
        <div className="base-history__search-left">
          <BaseSearch
            inputValue={searchValue}
            onChange={onChangeSearch}
            placeholder="Filter by Address and Asset"
          />
        </div>

        <div data-testid="dashboard-history__pagination" className="base-history__search-right">
          <Pagination
            rowsPerPage={10}
            rowCount={rowCount}
            currentPage={currentPage}
            onChangePage={onChangePage}
          />
        </div>
      </div>

      {bodyTable}

      <div className="base-history__footer">
        <Pagination
          rowsPerPage={10}
          rowCount={rowCount}
          currentPage={currentPage}
          onChangePage={onChangePage}
        />
      </div>
    </div>
  )
}

BaseHistory.defaultProps = {
  historyData: [],
}

BaseHistory.propTypes = {
  historyData: PropTypes.array,
  rowCount: PropTypes.number,
  currentPage: PropTypes.number,
  isLoading: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeSearch: PropTypes.func,
  searchValue: PropTypes.string,
}
