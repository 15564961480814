import React, {
  useCallback, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import './input.scss'
import { Icon } from '../Icon/Icon'
import IconClear from '../Icon/icon--clear.svg'
import IconCheck from '../Icon/icon--check.svg'
import IconShowPass from '../Icon/icon--show-pass.svg'
import IconHidePass from '../Icon/icon--hide-pass.svg'
import { Alert } from '../Alert/Alert'

export const Input = ({
  className,
  type = 'text',
  name,
  value,
  placeholder,
  min,
  max,
  step,
  onChange,
  isInputDisabled = false,
  clear = false,
  done = false,
  readOnly,
  label,
  error,
  onClearInputValue,
  onFocus,
  onFocusOut,
  dataTestid,
}) => {
  const [inputType, setInputType] = useState(type)
  const [isPasswordShown, setPasswordShownState] = useState(false)

  const showHidePassword = useCallback((currentType) => {
    if (currentType === 'text') {
      setPasswordShownState(false)
      setInputType('password')
    } else {
      setPasswordShownState(true)
      setInputType('text')
    }
  }, [])

  const rightContent = useMemo(() => {
    if (done) {
      return (
        <div className="input__action" type="button">
          <Icon
            name={IconCheck}
            id="icon--check"
          />
        </div>
      )
    }

    if (clear) {
      return (
        <button className="input__action" type="button" onClick={onClearInputValue}>
          <Icon
            name={IconClear}
            id="icon--clear"
          />
        </button>
      )
    }

    if (type === 'password') {
      return (
        <button className="input__action" type="button" onClick={() => showHidePassword(inputType)}>
          {isPasswordShown ? (
            <Icon
              name={IconHidePass}
              id="icon--hide-pass"
            />
          ) : (
            <Icon
              name={IconShowPass}
              id="icon--show-pass"
            />
          )}

        </button>
      )
    }

    return ''
  }, [clear, done, inputType, showHidePassword, type, isPasswordShown, onClearInputValue])

  return (
    <>
      {label && (
        <label className="input__label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className={`input ${done ? 'input--done' : ''} ${className || ''}`}>
        <input
          data-testid={dataTestid}
          disabled={isInputDisabled}
          onChange={onChange}
          type={inputType}
          name={name}
          id={name}
          value={value}
          placeholder={placeholder}
          className="input__input"
          min={min}
          max={max}
          step={step}
          readOnly={readOnly}
          onFocus={() => {
            if (onFocus) {
              onFocus()
            }
          }}
          onBlur={() => {
            if (onFocusOut) {
              onFocusOut()
            }
          }}
        />

        {rightContent}
      </div>

      {error && (
        <div className="input--error">
          <Alert error size="s">{error}</Alert>
        </div>
      )}
    </>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'password',
    'submit',
    'number',
    'reset',
    'radio',
    'checkbox',
    'button',
    'file',
    'image',
  ]),
  name: PropTypes.string,
  dataTestid: PropTypes.string,
  isInputDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.symbol,
  ]),
  placeholder: PropTypes.string,
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  step: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  clear: PropTypes.bool,
  done: PropTypes.bool,
  readOnly: PropTypes.bool,
  label: PropTypes.node,
  error: PropTypes.node,
  onClearInputValue: PropTypes.func,
  onFocus: PropTypes.func,
  onFocusOut: PropTypes.func,
}
