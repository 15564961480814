import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { BaseModal } from '../../../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../components/Icon/icon--close.svg'
import { Input } from '../../../../../../../components/Input/Input'

import './rename-cyber-wallet-modal.scss'
import { renameCyberwallet } from '../../../../../../../service/api'
import Message from '../../../../../../../components/Message/Message'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'

const RenameCyberWalletModal = ({
  onAfterRename,
  chosenCyberWallet,
  onCloseModal,
}) => {
  const [newCyberWalletName, setNewCyberWalletName] = useState('')
  const [wasCyberWalletNameUpdated, setWasCyberWalletNameUpdatedState] = useState(false)
  const [isInputError, setIsInputError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const onRenameWallet = useCallback(async () => {
    if (!isInputError) {
      try {
        await renameCyberwallet({ address: chosenCyberWallet.address, name: newCyberWalletName })
        setIsSuccess(true)
        onAfterRename({ address: chosenCyberWallet.address, name: newCyberWalletName })
      } catch (e) {
        setIsError(true)
        console.log(e)
      }
    }
  }, [onAfterRename, chosenCyberWallet, newCyberWalletName, isInputError])

  const retryRename = () => {
    setIsSuccess(false)
    setIsError(false)
  }

  useEffect(() => {
    if (chosenCyberWallet) {
      setNewCyberWalletName(chosenCyberWallet.name)
    }
  }, [chosenCyberWallet])

  const isCyberWalletNameValid = useCallback(() => (
    newCyberWalletName.length >= 4 && newCyberWalletName.length <= 12 && chosenCyberWallet.name !== newCyberWalletName
  ), [newCyberWalletName, chosenCyberWallet])

  const getModalHeader = () => (
    <BaseButton
      onClick={onCloseModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getInputDetails = useMemo(() => {
    let details = {
      isError: false,
      text: '12 characters max',
    }
    if (newCyberWalletName.length < 4) {
      details = {
        isError: true,
        text: '4 characters min',
      }
    }
    if (newCyberWalletName.length > 12) {
      details = {
        isError: true,
        text: '12 characters max',
      }
    }
    if (wasCyberWalletNameUpdated && chosenCyberWallet.name === newCyberWalletName) {
      details = {
        isError: true,
        text: 'New name has to be different',
      }
    }

    setIsInputError(details.isError)

    return (
      <BaseTextBody
        size="S"
        error={details.isError}
      >
        {details.text}
      </BaseTextBody>
    )
  }, [newCyberWalletName, chosenCyberWallet, wasCyberWalletNameUpdated])

  const getModalBody = useMemo(() => {
    let body = (
      <>
        <Message
          status="edit"
          title="Write a new name for the Owner Account"
          description="You can change it again at any time. It's will only affect the Cyberfi interface."
          size="S"
        />

        <div className="rename-cyber-wallet-modal__input">
          <Input
            dataTestid="popup--rename-wallet-input-new-name"
            value={newCyberWalletName}
            onChange={(e) => {
              setWasCyberWalletNameUpdatedState(true)
              setNewCyberWalletName(e.target.value)
            }}
            type="text"
            name="cyber_wallet_name"
            min="4"
            max="12"
            placeholder="Cyber Wallet Name"
          />

          {getInputDetails}
        </div>
      </>
    )

    if (isSuccess) {
      body = (
        <Message
          dataTestid="popup--rename-wallet-completed"
          status="completed"
          title="Cyber Wallet name changed successfully"
          size="S"
        />
      )
    }

    if (isError) {
      body = (
        <Message
          status="error"
          title="Something went wrong"
          description={(
            <>
              Try changing the name again. If the error occurs frequently, please contact our
              {' '}
              <a href="link">support team</a>
              .
            </>
          )}
          size="S"
        />
      )
    }

    return (
      <div className="rename-cyber-wallet-modal__content">
        {body}
      </div>
    )
  }, [newCyberWalletName, getInputDetails, isSuccess, isError])

  const getModalFooter = useMemo(() => {
    let footerContent = (
      <>
        <BaseButton
          onClick={onCloseModal}
          variant="secondary"
        >
          Cancel
        </BaseButton>

        <BaseButton
          dataTestid="popup--rename-wallet-btn-save"
          disabled={!isCyberWalletNameValid()}
          onClick={onRenameWallet}
        >
          Save
        </BaseButton>
      </>
    )

    if (isSuccess) {
      footerContent = (
        <BaseButton
          onClick={onCloseModal}
        >
          Close
        </BaseButton>
      )
    }

    if (isError) {
      footerContent = (
        <>
          <BaseButton
            onClick={onCloseModal}
            variant="secondary"
          >
            Cancel
          </BaseButton>

          <BaseButton
            onClick={retryRename}
          >
            Retry
          </BaseButton>
        </>
      )
    }

    return footerContent
  }, [onCloseModal, isCyberWalletNameValid, onRenameWallet, isSuccess, isError])

  return (
    <BaseModal
      title="Rename Cyber Wallet"
      topAction={getModalHeader()}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

RenameCyberWalletModal.propTypes = {
  onAfterRename: PropTypes.func,
  chosenCyberWallet: PropTypes.object,
  onCloseModal: PropTypes.func,
}

export default RenameCyberWalletModal
