import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import find from 'lodash/find'
import { BigNumber } from 'bignumber.js'
import { BaseModal } from '../BaseModal/BaseModal'
import { BaseButton } from '../BaseButton/BaseButton'
import { Icon } from '../Icon/Icon'
import IconClose from '../Icon/icon--close.svg'
import { AssetBlock } from '../AssetBlock/AssetBlock'
import { IconText } from '../IconText/IconText'
import IconWallet from '../Icon/icon--wallet.svg'
import { List } from '../List/List'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { AssetInfo } from '../AssetInfo/AssetInfo'
import { Status } from '../Status/Status'
import { BaseTextBody, BaseTextHeading } from '../BaseText/BaseText'
import { Stepper } from '../Stepper/Stepper'
import { checkFloatNaN, croppedString, ERC20ToFloat } from '../../utils/helperFunctions'
import { parseOrderStatus, getLinkBasedOnCurrentNetwork } from '../../parser/data'

export const ModalRowOrderbook = ({
  closeModal,
  firstToken,
  secondToken,
  status,
  type,
  triggerConditions,
  created,
  triggered,
  executed,
  ownerWallet,
  transactions,
}) => {
  const userState = useSelector((store) => store.user)
  const [cyberwallet, setCuberWallet] = useState('')

  const renderTopAction = useMemo(() => (
    <BaseButton
      onClick={closeModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  ), [closeModal])

  const renderFooter = useMemo(() => {
    const croppedAddressWallet = croppedString(ownerWallet || '', 0, 6, -5)
    let firstTokenValue = 0
    let secondTokenValue = 0
    let footerValueFirst = firstToken.price || 0
    let footerValueSecond = secondToken.price || 0
    let footerNameFirst = firstToken.name
    let footerNameSecond = secondToken.name

    if (footerValueFirst === 0 || footerValueSecond === 0) {
      footerValueFirst = ''
      footerValueSecond = ''
      footerNameFirst = ''
      footerNameSecond = ''
    } else {
      footerValueFirst = `$${checkFloatNaN(footerValueFirst, 0, 5)}`
      footerValueSecond = `$${checkFloatNaN(footerValueSecond, 0, 5)}`
    }

    firstTokenValue = ERC20ToFloat(
      new BigNumber(firstToken.value.toString()),
      firstToken.decimals,
      BigNumber,
    )
    secondTokenValue = (firstTokenValue * (firstToken.price || 0)) / (secondToken.price || 0)

    return (
      <AssetInfo
        swap={{
          block1:
  <AssetBlock
    title="You Pay"
    bodyName={(
      <IconText
        icon={IconWallet}
        id="icon--wallet"
        text={firstToken.symbol}
      />
              )}
    bodyValue={checkFloatNaN(firstTokenValue, 0, 5)}
    footerName={footerNameFirst}
    footerValue={footerValueFirst}
  />,
          block2:
  <AssetBlock
    title="You Recieve"
    bodyName={(
      <IconText
        icon={IconWallet}
        id="icon--wallet"
        text={secondToken.symbol}
      />
              )}
    bodyValue={checkFloatNaN(secondTokenValue, '', 5)}
    footerName={footerNameSecond}
    footerValue={footerValueSecond}
  />,
        }}
        detail={(
          <List
            items={[
              {
                name: 'Type',
                value: type,
              },
              {
                name: 'Trigger Conditions',
                value: triggerConditions,
              },
              {
                name: 'Created',
                value: created,
                valueState: created === '00.00, 00:00:00'
                  ? { secondary: true }
                  : { secondary: false },
              },
              {
                name: 'Triggered',
                value: triggered,
                valueState: triggered === '00.00, 00:00:00'
                  ? { secondary: true }
                  : { secondary: false },
              },
              {
                name: 'Executed',
                value: executed,
                valueState: executed === '00.00, 00:00:00'
                  ? { secondary: true }
                  : { secondary: false },
              },
              {
                name: 'Owner wallet',
                value: <ExternalLink text={croppedAddressWallet} link={`https://${getLinkBasedOnCurrentNetwork()}/address/${ownerWallet}`} />,
                valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
              },
              {
                name: 'Cyberwallet',
                value: cyberwallet,
                valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
              },
            ]}
          />
        )}
      />
    )
  }, [created, cyberwallet, executed, firstToken, ownerWallet, secondToken, triggerConditions, triggered, type])

  const renderBodyModal = useMemo(() => {
    let statusType
    let statusText
    let statusSubText

    const stepper = []
    transactions.forEach((transaction) => {
      const transactionStatus = parseOrderStatus(transaction.status)

      let state = 'pending'
      if (transactionStatus === 'ORDER_EXECUTING' || transactionStatus === 'ORDER_EXECUTED') {
        state = 'completed'
      } else if (transactionStatus === 'ORDER_FAILED') {
        state = 'canceled'
      }

      stepper.push({
        badge: {
          state,
        },
        text: transaction.name,
        active: true,
      })
    })

    if (firstToken.hash) {
      stepper[0].text = (
        <ExternalLink
          text={stepper[0].text}
          link={`https://${getLinkBasedOnCurrentNetwork()}/tx/${firstToken.hash}`}
        />
      )
    }
    if (secondToken.hash) {
      stepper[1].text = (
        <ExternalLink
          text={stepper[1].text}
          link={`https://${getLinkBasedOnCurrentNetwork()}/tx/${secondToken.hash}`}
        />
      )
    }

    switch (status) {
      case 'Active':
        statusType = 'pending'
        statusText = 'Order is active'
        statusSubText = 'Order is active and pending for trigger conditions.'
        break
      case 'Executed':
        statusType = 'spinner'
        statusText = 'Order is executing, please wait'
        statusSubText = 'Order is active and pending for trigger conditions.'
        break
      case 'Confirmed':
        statusType = 'completed'
        statusText = 'Order has been successfully executed'
        statusSubText = 'All transactions in order has been successfully executed.'
        break
      case 'Canceled':
        statusType = 'canceled'
        statusText = 'Order was canceled'
        statusSubText = 'Order was manually canceled by you.'
        break
      default:
        statusType = 'error'
        statusText = 'Something went wrong'
        statusSubText = 'Try to re-create the order. If the error occurs frequently, please contact our support team.'
        break
    }

    return (
      <>
        <Status
          type={statusType}
        />

        <BaseTextHeading
          size="S"
        >
          {statusText}
        </BaseTextHeading>

        <BaseTextBody>
          {statusSubText}
        </BaseTextBody>

        <Stepper
          steps={stepper}
        />
      </>
    )
  }, [firstToken.hash, secondToken.hash, status, transactions])

  useEffect(() => {
    if (userState.internalAddresses) {
      const findName = find(userState.internalAddresses, { address: ownerWallet })

      setCuberWallet(findName?.name || '')
    }
  }, [ownerWallet, userState])

  return (
    <BaseModal
      title="Order Details"
      topAction={renderTopAction}
      footer={renderFooter}
    >
      {renderBodyModal}
    </BaseModal>
  )
}

ModalRowOrderbook.propTypes = {
  closeModal: PropTypes.func.isRequired,
  firstToken: PropTypes.shape({
    address: PropTypes.string,
    decimals: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    symbol: PropTypes.string,
    value: PropTypes.number,
    hash: PropTypes.string,
  }),
  secondToken: PropTypes.shape({
    address: PropTypes.string,
    decimals: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    symbol: PropTypes.string,
    hash: PropTypes.string,
  }),
  status: PropTypes.oneOf([
    'Active',
    'Executed',
    'Confirmed',
  ]),
  type: PropTypes.oneOf([
    'limit',
    'market',
  ]),
  triggerConditions: PropTypes.string,
  created: PropTypes.string,
  triggered: PropTypes.string,
  executed: PropTypes.string,
  ownerWallet: PropTypes.string,
  transactions: PropTypes.array,
}
