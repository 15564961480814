import React from 'react'
import PropTypes from 'prop-types'
import './menu-block.scss'
import { Divider } from '../Divider/Divider'
import { BaseTextBody } from '../BaseText/BaseText'

export const MenuBlock = ({
  divider,
  top,
  title,
  items,
}) => (
  <div className="menu-block">
    <div className="menu-block__header">
      {top
          && (
          <div className="menu-block__top">
            {top}
          </div>
          )}

      {title
          && (
          <div className="menu-block__title">
            <BaseTextBody
              size="S"
              secondary
            >
              { title }
            </BaseTextBody>
          </div>
          )}
    </div>

    <ul className="menu-block__list">
      {items.map((item, index) => (
        <li className="menu-block__item" key={index}>
          {item}
        </li>
      ))}
    </ul>

    {divider
        && (
        <div className="menu-block__divider">
          <Divider />
        </div>
        )}
  </div>
)

MenuBlock.propTypes = {
  divider: PropTypes.bool,
  top: PropTypes.element,
  title: PropTypes.string,
  items: PropTypes.array,
}
