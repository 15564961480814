import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '../Text/Text'
import { Icon } from '../Icon/Icon'
import { Tooltip } from '../Tooltip/Tooltip'

import './icon-item-history.scss'

export const IconItemHistory = ({
  className,
  iconName,
  iconId,
  iconImg,
  iconImgAlt,
  title,
  date,
  tooltip,
}) => (
  <div className={`icon-item-history ${className || ''}`}>
    <div className="icon-item-history__icon">
      {iconName && (
        <Icon
          name={iconName}
          id={iconId}
        />
      )}
      {iconImg && <img src={iconImg} alt={iconImgAlt} className="icon-item-history__img" />}
    </div>

    <div className="icon-item-history__body">
      <Text
        className="icon-item-history__title"
        text={title}
      />

      <div className="icon-item-history__bottom">
        <Text
          className="icon-item-history__date"
          text={date}
        />
        {tooltip && <Tooltip />}
      </div>

    </div>
  </div>
)

IconItemHistory.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconId: PropTypes.string,
  iconImg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  iconImgAlt: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  tooltip: PropTypes.bool,
}
