import React, {
  useState,
  useMemo,
  useCallback,
} from 'react'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import IconUnfold from '../../../../../../../../components/Icon/icon--unfold.svg'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import { ExternalLink } from '../../../../../../../../components/ExternalLink/ExternalLink'
import { getNativeTokenDataBasedOnNetwork } from '../../../../../../../../parser/data'
import ClickOutside from '../../../../../../../../components/ClickOutside/ClickOutside'
import iconBaseSearch from '../../../../../../../../components/Icon/icon--base-search.svg'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'

import './select-token.scss'

const SelectToken = ({
  allPossibleTokens,
  selectedToken,
  onSelectToken,
}) => {
  const [isTokensListShown, setTokensListIsShownState] = useState(false)
  const [tokens, setTokens] = useState(allPossibleTokens)
  const [searchTokenValue, setSearchTokenValue] = useState('')

  const closeTokensList = useCallback(() => {
    setTokensListIsShownState(false)
    setSearchTokenValue('')
    setTokens(allPossibleTokens)
  }, [allPossibleTokens])

  const getTokenItemTemplate = useCallback((item) => (
    <div
      className="token-item-container"
      onClick={() => {
        onSelectToken(item)
        closeTokensList()
      }}
    >
      <div className="token-item-left-block">
        <img className="token-icon" src={item.icon} alt="token-icon" />
        <BaseTextBody>{item.symbol}</BaseTextBody>
      </div>

      {
        getNativeTokenDataBasedOnNetwork().tokenSymbol !== item.symbol && (
          <div className="token-item-right-block">
            <ExternalLink
              link={item.link}
              text={item.address}
              onClick={(e) => {
                window.open(item.link, '_blank')
                e.stopPropagation()
                e.nativeEvent.stopImmediatePropagation()
              }}
            />
          </div>
        )
      }
    </div>
  ), [onSelectToken, closeTokensList])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoadNewTokensBasedOnSearchValue = useCallback(debounce(async (searchValue) => {
    try {
      const filteredTokens = tokens.filter((token) => (
        token.name.toLowerCase().includes(searchValue)
        || token.address.toLowerCase().includes(searchValue)
        || token.symbol.toLowerCase().includes(searchValue)
      ))
      setTokens(filteredTokens)
    } catch (e) {
      setTokens(allPossibleTokens)
    }
  }), [allPossibleTokens])

  const getSearch = useMemo(() => (
    <div className="tokens-search-block">
      <Icon name={iconBaseSearch} id="icon--base-search" />
      <input
        onChange={(e) => {
          setSearchTokenValue(e.target.value)
          onLoadNewTokensBasedOnSearchValue(e.target.value.toLowerCase())
        }}
        value={searchTokenValue}
        type="text"
        placeholder="Search for asset..."
      />
    </div>
  ), [searchTokenValue, onLoadNewTokensBasedOnSearchValue])

  const getTokensListTemplate = useMemo(() => (
    <div className="tokens-list-container">
      {getSearch}

      {tokens.length > 0 && (
        <InfiniteScroll
          dataLength={tokens.length}
          hasMore={false}
          height={tokens.length >= 10 ? 280 : tokens.length * 42}
        >
          {tokens.map((item, key) => (
            <div key={key} className="item-block">
              {getTokenItemTemplate(item)}
            </div>
          ))}
        </InfiniteScroll>
      )}

      {tokens.length === 0 && (<div className="no-result-block">Tokens list is empty</div>)}
    </div>
  ), [tokens, getTokenItemTemplate, getSearch])

  return (
    <ClickOutside callback={closeTokensList}>
      <div className="select-token-container">
        <div className="select-token__body">
          <div
            className="select-token__header"
            onClick={() => {
              if (!isTokensListShown) {
                setTokensListIsShownState(true)
              } else {
                closeTokensList()
              }
            }}
          >
            <div className="select-token__header-token-block">
              <img className="token-icon" src={selectedToken.icon} alt="selected-token-icon" />
              <BaseTextBody>{selectedToken.symbol}</BaseTextBody>
            </div>

            <div className="select-token__header-control">
              <Icon name={IconUnfold} id="icon--unfold" />
            </div>
          </div>

          {isTokensListShown && getTokensListTemplate}
        </div>
      </div>
    </ClickOutside>
  )
}

SelectToken.propTypes = {
  allPossibleTokens: PropTypes.array,
  selectedToken: PropTypes.object,
  onSelectToken: PropTypes.func,
}

export default SelectToken
