import React from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'

import { getCustomStyles } from './data-table-styles'
// eslint-disable-next-line import/no-cycle
import { DataTableFooter } from './footer/DataTableFooter'
import { DataTableEmptyContent } from './empty/DataTableEmpty'
import { DataTableLoading } from './loading/DataTableLoading'
import { Icon } from '../Icon/Icon'
import IconArrowSort from '../Icon/icon--arrow-sort.svg'

import './data-table.scss'

const IconSort = (
  <Icon
    name={IconArrowSort}
    id="icon--arrow-sort"
  />
)

export const CustomDataTable = ({
  defaultSortField,
  defaultSortAsc,
  columns,
  data,
  onSort,
  isDataLoading,
  onChangeRowsPerPage,
  onChangePage,
  paginationTotalRows,
  onRowClicked,
  sortServer = true,
  paginationServer = true,
  paginationPerPage,
  emptyContentDescription,
  minHeight,
}) => {
  const defaultCustomStyles = getCustomStyles()
  return (
    <>
      <DataTable
        className="custom-data-table-container"
        customStyles={{
          ...defaultCustomStyles,
          ...{
            table: {
              style: {
                ...defaultCustomStyles.table.style,
                minHeight: minHeight || defaultCustomStyles.table.style.minHeight,
              },
            },
          },
        }}
        progressPending={isDataLoading}
        noHeader
        persistTableHead
        columns={columns}
        progressComponent={<DataTableLoading />}
        noDataComponent={
          <DataTableEmptyContent desctription={emptyContentDescription} />
        }
        defaultSortField={defaultSortField}
        defaultSortAsc={defaultSortAsc}
        onRowClicked={onRowClicked}
        data={data}
        pagination
        paginationPerPage={paginationPerPage || undefined}
        paginationServer={paginationServer}
        paginationTotalRows={paginationTotalRows}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        onSort={onSort}
        sortServer={sortServer}
        sortIcon={IconSort}
        paginationComponent={DataTableFooter}
      />
    </>
  )
}

export const CustomDataTableProps = {
  columns: PropTypes.array,
  paginationTotalRows: PropTypes.number,
  paginationPerPage: PropTypes.number,
  data: PropTypes.array,
  onSort: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onRowClicked: PropTypes.func,
  isDataLoading: PropTypes.bool,
  defaultSortAsc: PropTypes.bool,
  sortServer: PropTypes.bool,
  paginationServer: PropTypes.bool,
  defaultSortField: PropTypes.string,
  emptyContentDescription: PropTypes.string,
  minHeight: PropTypes.string,
}

CustomDataTable.propTypes = CustomDataTableProps
