import React from 'react'

import PropTypes from 'prop-types'
import './swap-info.scss'
import { generateUniqueKey } from '../../utils/helperFunctions'

const SwapInfo = ({
  items,
}) => (
  <div className="swap-info">
    {items.map((item) => (
      <div className="swap-info__item" key={generateUniqueKey()}>
        <div className="swap-info__name">
          {item.name}
        </div>

        <div className="swap-info__value">
          {item.value}
        </div>
      </div>
    ))}
  </div>
)

SwapInfo.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.any,
    value: PropTypes.any,
  })),
}

export default SwapInfo
