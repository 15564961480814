import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './rewards.scss'
import { Chip } from '../../../../../../../components/Chip/Chip'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconCardGiftcard from '../../../../../../../components/Icon/icon--card-giftcard.svg'
import IconAddSm from '../../../../../../../components/Icon/icon--add-sm.svg'
import IconKeyboardArrowRight from '../../../../../../../components/Icon/icon--keyboard-arrow-right.svg'
import ClickOutside from '../../../../../../../components/ClickOutside/ClickOutside'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { generateUniqueKey } from '../../../../../../../utils/helperFunctions'
import RewardsItem from './rewards-item/RewardsItem'

const Rewards = ({
  items,
}) => {
  const [open, setOpen] = useState(false)

  const openPopover = () => {
    setOpen(!open)
  }

  const propagation = (e) => {
    e.stopPropagation()
  }

  return (
    <ClickOutside callback={() => { setOpen(false) }}>
      <div className="rewards" onClick={(e) => { propagation(e) }}>
        <Chip
          onClick={() => { openPopover() }}
          icon={<Icon name={IconCardGiftcard} id="icon--card-giftcard" />}
          iconPosition="left"
        >
          Rewards
        </Chip>

        <div
          className={
            `rewards__popover 
            ${open ? 'rewards__popover--open' : ''}`
          }
        >
          <div className="rewards__list">
            {items.map((item) => (
              <div
                className="rewards__item"
                key={generateUniqueKey()}
              >
                <RewardsItem
                  title={item.title}
                  icon={item.icon}
                  level={item.level}
                  exp={item.exp}
                  quests={item.quests}
                  active={item.active}
                />
              </div>
            ))}
          </div>

          <div className="rewards__divider" />

          <div className="rewards__footer">
            <button className="rewards__connect-new-account" type="button">
              <div>
                <Icon name={IconAddSm} id="icon--add-sm" />

                <BaseTextBody>
                  Connect New Owner Account
                </BaseTextBody>
              </div>

              <Icon name={IconKeyboardArrowRight} id="icon--keyboard-arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </ClickOutside>
  )
}

Rewards.propTypes = {
  items: PropTypes.array,
}

export default Rewards
