import React, {
  useState, useEffect, useCallback,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IconCurrency } from '../../../../components/IconCurrency/IconCurrency'
import { BaseTabs } from '../../../../components/BaseTabs/BaseTabs'
import AssetList from './asset-list/AssetList'
import {
  weiToEth,
  formatEth,
  ERC20ToFloat,
  getTokenExactData,
  checkFloatNaN,
} from '../../../../utils/helperFunctions'
import {
  ZERO_ADDRESS,
  NEW_ETH_ADDRESS,
} from '../../../../const'
import { selectAllPriceTokens } from '../../../../store/slice/priceToken'
import { addLPToken } from '../../../../store/slice/lpTokens'
import {
  sortArrayByAlphabet, parseToken, getNativeTokenDataBasedOnNetwork, getImage,
} from '../../../../parser/data'
import { updateSelectedTokensPair } from '../../../../store/slice/trade'
import { getLpTokensPair } from '../../../../service/api'
import { toastCritical } from '../../../../components/Toast/Toast'

import './assets-block.scss'
import { useWeb3Bn } from '../../../../hooks/web3'
import { useWeb3 } from '../../../../context/web3Provider'

const AssetsBlock = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const Web3BN = useWeb3Bn()
  const userState = useSelector((store) => store.user)
  const selectedTokensPair = useSelector((store) => store.trade.selectedTokensPair)
  const web3React = useWeb3()
  const priceTokensState = useSelector(selectAllPriceTokens)

  const [isIdleTokensLoading, setIdleTokensLoadingState] = useState(true)
  const [idleTokens, setIdleTokens] = useState([])
  const [isLpTokensLoading, setLpTokensLoadingState] = useState(true)
  const [lpTokens, setLpTokens] = useState([])
  const [deposits] = useState([])
  const [cachedUserTokens, setCachedUserTokens] = useState([])

  useEffect(() => {
    (async () => {
      if (
        userState.tokens
        && Array.isArray([userState.tokens])
        && web3React.library
        && Web3BN
        && (
          (userState.tokens.length > 0 && JSON.stringify(userState.tokens) !== JSON.stringify(cachedUserTokens))
          || userState.tokens.length === 0
        )
      ) {
        const idle = []
        const lp = []

        const arrPromise = userState.tokens.map(async (token) => {
          const tokenBalance = ERC20ToFloat(new Web3BN(`${token.balance}`), token.contract_decimals, Web3BN)
          const contractAddress = token.contract_address.toLowerCase()

          if (token.contract_ticker_symbol === 'UNI-V2' && ZERO_ADDRESS !== contractAddress && NEW_ETH_ADDRESS !== contractAddress) {
            const decimals = token.contract_decimals ? token.contract_decimals : token.decimals
            const erc20 = ERC20ToFloat(new Web3BN(token.balance.toString()), decimals, Web3BN)
            const totalPrice = formatEth(erc20 * token.price)
            const token0 = await getTokenExactData(contractAddress, 'token0', web3React)
            const token1 = await getTokenExactData(contractAddress, 'token1', web3React)

            const resultToken = {
              title: `${token.contract_ticker_symbol}`,
              currency: `$${totalPrice}`,
              token0,
              token1,
              total: tokenBalance,
              address: contractAddress,
              pairPrice: totalPrice,
              name: '',
              assetName: token.contract_ticker_symbol,
              totalInUsd: formatEth(checkFloatNaN(totalPrice, 0), 2).toString(),
            }
            lp.push(resultToken)
            dispatch(addLPToken(resultToken))
          } else if (ZERO_ADDRESS !== contractAddress && NEW_ETH_ADDRESS !== contractAddress) {
            const decimals = token.contract_decimals ? token.contract_decimals : token.decimals
            const erc20 = ERC20ToFloat(new Web3BN(token.balance.toString()), decimals, Web3BN)
            const symbol = token.symbol ? token.symbol : token.contract_ticker_symbol
            const address = contractAddress

            idle.push({
              assetIcon: (
                <IconCurrency
                  img={token.logo_url || getImage(token.image_url, token.address)}
                  imgAlt={symbol}
                  text={symbol}
                  textClassName="no-bold"
                />
              ),
              assetName: symbol,
              total: tokenBalance,
              totalInUsd: formatEth(checkFloatNaN(formatEth(erc20 * token.price), 0), 2).toString(),
              address,
            })
          } else if (
            (ZERO_ADDRESS === contractAddress || NEW_ETH_ADDRESS === contractAddress)
            && token.balance !== '0' && web3React.library && web3React.library.utils
          ) {
            const toEth = weiToEth(web3React.library.utils.fromWei, token.balance.toString())
            const totalPrice = formatEth(parseFloat(toEth) * parseFloat(userState.latestNativeTokenPrice))

            const { tokenSymbol, iconSrc } = getNativeTokenDataBasedOnNetwork()

            idle.push({
              assetIcon: (
                <IconCurrency
                  img={iconSrc}
                  imgAlt={tokenSymbol}
                  text={tokenSymbol}
                  textClassName="no-bold"
                />
              ),
              assetName: tokenSymbol,
              total: toEth.toString(),
              totalInUsd: formatEth(checkFloatNaN(totalPrice, 0), 2).toString(),
              address: NEW_ETH_ADDRESS,
            })
          }
        })
        await Promise.all(arrPromise)

        if (!userState.areInternalTokensLoading) {
          idle.sort((item1, item2) => sortArrayByAlphabet({ item1, item2, prop: 'assetName' }))
          setIdleTokens(idle)
          setIdleTokensLoadingState(false)
          lp.sort((item1, item2) => sortArrayByAlphabet({ item1, item2, prop: 'assetName' }))
          setLpTokens(lp)
          setLpTokensLoadingState(false)
          setCachedUserTokens(userState.tokens)
        }
      }
    })()
  }, [
    Web3BN,
    userState.internalAddresses,
    userState.tokens,
    userState.balance,
    web3React,
    dispatch,
    priceTokensState,
    userState.areInternalTokensLoading,
    userState,
    cachedUserTokens,
  ])

  // TODO: it's better to move this to a different action
  const onUpdateTokensPair = useCallback(async ({ token1, token2 }) => {
    try {
      const pair = await getLpTokensPair({
        address1: web3React.library.utils.toChecksumAddress(token1.address),
        address2: web3React.library.utils.toChecksumAddress(token2.address),
      })
      if (pair) {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1: pair.token1,
            token2: pair.token2,
          },
          pairAddress: pair.address,
          isChartExist: true,
        }))
      } else {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1,
            token2,
          },
          isChartExist: false,
        }))
      }
    } catch (e) {
      toastCritical('Tokens pair update failed')
      console.error('Update tokens pair on assets click failed = ', e)
    }
  }, [web3React, dispatch])

  return (
    <div className="sidebar__assets-block">
      <BaseTabs
        tabs={[
          {
            text: 'Idle Assets',
            isLoading: isIdleTokensLoading,
            renderContent: () => idleTokens.length > 0
              && (
                <AssetList
                  items={idleTokens}
                  onItemClick={async ({ address, assetName }) => {
                    const isNativeToken = [NEW_ETH_ADDRESS, ZERO_ADDRESS].includes(address)
                    const chosenToken = userState.tokens.filter((token) => (
                      (!isNativeToken && token.contract_address.toLowerCase() === address.toLowerCase())
                      || (isNativeToken && token.contract_ticker_symbol === assetName)
                    ))[0]

                    onUpdateTokensPair({
                      token1: parseToken(chosenToken),
                      token2: selectedTokensPair.token2,
                    })
                    history.push('/trade')
                  }}
                />
              ),
          },
          {
            text: 'LP Tokens',
            isLoading: isLpTokensLoading,
            renderContent: () => lpTokens.length > 0 && <AssetList items={lpTokens} />,
          },
          {
            text: 'Deposits',
            renderContent: () => deposits.length > 0 && <AssetList items={deposits} />,
            disabled: true,
          },
        ]}
      />
    </div>
  )
}

export default AssetsBlock
