import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import './chip.scss'
import { Input } from '../Input/Input'

export const Chip = ({
  className,
  isDisabled,
  isSelected,
  children,
  onClick,
  icon,
  iconPosition,
  size,
  outline,
  inputValue,
  isInput,
  isClearInput,
  value,
  valueType,
  dataTestid,
}) => {
  const [currentValue, setCurrentValue] = useState(inputValue)

  const clearInputValue = () => {
    setCurrentValue('')
  }

  const clsChip = useMemo(() => {
    let cls = 'chip'

    if (className) {
      cls += ` ${className}`
    }
    if (isDisabled) {
      cls += ' chip--disabled'
    }
    if (isSelected) {
      cls += ' chip--selected'
    }
    if (outline) {
      cls += ' chip--outline'
    }
    if (isInput) {
      cls += ' chip--input'
    }
    if (isSelected && isInput && isClearInput) {
      cls += ' chip--selected-focus'
    }

    switch (size) {
      case 'L':
        cls += ' chip--size-l'
        break

      case 'XXL':
        cls += ' chip--size-xxl'
        break

      default:
    }

    return cls
  }, [className, isDisabled, isSelected, outline, size, isInput, isClearInput])

  const clsChipValue = useMemo(() => {
    let cls = 'chip__value'

    switch (valueType) {
      case 'up':
        cls = `${cls} chip__value--up`
        break

      case 'down':
        cls = `${cls} chip__value--down`
        break

      case 'flat':
        cls = `${cls} chip__value--flat`
        break

      default:
    }

    return cls
  }, [valueType])

  return (
    <div
      data-testid={dataTestid}
      onClick={() => {
        if (!isDisabled) {
          onClick()
        }
      }}
      className={clsChip}
    >
      <div className="chip__container">
        <div className="chip__inner">
          {isInput ? (
            <Input
              type="text"
              name="chip-input"
              value={currentValue}
              clear={isClearInput}
              onChange={() => {}}
              onClearInputValue={clearInputValue}
            />
          ) : (
            <>
              {iconPosition === 'left' ? icon : ''}

              <div className="chip__text">
                {children}
              </div>

              {value && (
                <div className={clsChipValue}>
                  {value}
                </div>
              )}

              {!iconPosition || iconPosition === 'right' ? icon : ''}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

Chip.propTypes = {
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf([
    'L',
    'XXL',
  ]),
  outline: PropTypes.bool,
  inputValue: PropTypes.string,
  isInput: PropTypes.bool,
  isClearInput: PropTypes.bool,
  value: PropTypes.string,
  valueType: PropTypes.oneOf(['up', 'down', 'flat']),
}
