// Todo: получить свои, эти украл
import instance from '../axios-api'
// eslint-disable-next-line import/no-cycle
import { convertToExactBalance } from '../parser/api'

const ETHERSCAN_API_KEYS = ['BBDQ1WWK1MYHTHTKDS8HGFW8CJPHKU6U26', 'Q8ZXAU8MQGGDDDVAYZV351I9RYHK8VXNIK', 'RHGF4ZTBRQ8BKND1WZ8RIYZY7W21T5B33I']
const BSCSCAN_API_KEYS = ['3GWUWY4F2JKHN44WQ2A6NAPB5UN8HWM8Y9']
const POLYGONSCAN_API_KEYS = ['SP9D2SQRP61835NZPCPHKBDM74Z4KJC5V4']

const getUrlDetalsBasedOnNetwork = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  let details = {
    domain: 'etherscan.io',
    keys: ETHERSCAN_API_KEYS,
  }

  if (selectedChain === 'BSC') {
    details = {
      domain: 'bscscan.com',
      keys: BSCSCAN_API_KEYS,
    }
  }

  if (selectedChain === 'Polygon') {
    details = {
      domain: 'polygonscan.com',
      keys: POLYGONSCAN_API_KEYS,
    }
  }

  return details
}

/**
 * Забираем все события Transfer для конкретного ERC20 токена с указанного адреса
 * @param {string} tokenAddress
 * @param {string} userAddress
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllERC20Transfers = async (tokenAddress, userAddress) => {
  const details = getUrlDetalsBasedOnNetwork()

  // eslint-disable-next-line no-bitwise
  const tokensUrl = `https://api.${details.domain}/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${userAddress}&page=1&offset=50&sort=desc&apikey=${details.keys[details.keys.length * Math.random() | 0]}`
  const response = await instance.get(tokensUrl)
  return response
}

/**
 * Забираем все события Transfer для всех ERC20 токенов с указанного адреса
 * @param {string} userAddress
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllTokensTransfers = (userAddress) => {
  // eslint-disable-next-line no-bitwise
  const transactionsUrl = `https://api.etherscan.io/api?module=account&action=tokentx&address=${userAddress}&startblock=0&endblock=999999999&sort=asc&apikey=${ETHERSCAN_API_KEYS[ETHERSCAN_API_KEYS.length * Math.random() | 0]}`
  return instance.get(transactionsUrl)
}

/**
 * Забираем все транзации с указанного адреса
 * @param {string} userAddress
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getAllAddressTransactions = (userAddress) => {
  // eslint-disable-next-line no-bitwise
  const transactionsUrl = `https://api.etherscan.io/api?module=account&action=txlist&address=${userAddress}&startblock=0&endblock=99999999&sort=asc&apikey=${ETHERSCAN_API_KEYS[ETHERSCAN_API_KEYS.length * Math.random() | 0]}`
  return instance.get(transactionsUrl)
}

export const getTokenBalance = async (tokenAddress, userAddress) => {
  const details = getUrlDetalsBasedOnNetwork()

  // eslint-disable-next-line no-bitwise
  const tokenBalanceUrl = `https://api.${details.domain}/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${userAddress}&tag=latest&apikey=${details.keys[details.keys.length * Math.random() | 0]}`
  const response = await instance.get(tokenBalanceUrl)

  return response.data?.result
}

export const getTokenData = (tokenAddress) => {
  // eslint-disable-next-line no-bitwise
  const tokenDataUrl = `https://api.etherscan.io/api?module=token&action=tokeninfo&contractaddress=${tokenAddress}&apikey=${ETHERSCAN_API_KEYS[ETHERSCAN_API_KEYS.length * Math.random() | 0]}`

  return instance.get(tokenDataUrl)
}

export const getExactTokenBalance = async ({
  tokenAddress, userAddress, decimals, Web3BN, cancelToken,
}) => {
  const details = getUrlDetalsBasedOnNetwork()

  // eslint-disable-next-line no-bitwise
  const tokenBalanceUrl = `https://api.${details.domain}/api?module=account&action=tokenbalance&contractaddress=${tokenAddress}&address=${userAddress}&tag=latest&apikey=${details.keys[details.keys.length * Math.random() | 0]}`
  const response = await instance.get(tokenBalanceUrl, { cancelToken })

  return convertToExactBalance(response.data?.result, decimals, Web3BN)
}
