import React, {
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import IconClose from '../../../../../../../../components/Icon/icon--close.svg'
import { BaseModal } from '../../../../../../../../components/BaseModal/BaseModal'
import { BaseTextHeading, BaseTextSubheading } from '../../../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import { Status } from '../../../../../../../../components/Status/Status'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'
import { Stepper } from '../../../../../../../../components/Stepper/Stepper'
import { AssetInfo } from '../../../../../../../../components/AssetInfo/AssetInfo'
import { AssetBlock } from '../../../../../../../../components/AssetBlock/AssetBlock'
import { checkFloatNaN } from '../../../../../../../../utils/helperFunctions'
import { List } from '../../../../../../../../components/List/List'
import { confirmOrder } from '../../../../../../../../service/api'
import { setOrderCreationBooleanValue } from '../../../../../../../../store/slice/trade'
import { getSymbolBasedOnNetwork } from '../../../../../../../../parser/data'
import { useWeb3 } from '../../../../../../../../context/web3Provider'

import './transactions-modal.scss'

const TransactionsModal = ({
  isGodModeEnabled,
  tokensPair,
  networkFeeInEth,
  networkFeeInUsd,
  details,
  onClose,
}) => {
  const web3React = useWeb3()
  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(1)
  const [isFirstStepSigned, setFirstStepIsSigned] = useState(false)
  const [isFirstStepRequestPending, setFirstStepRequestIsPendingState] = useState(true)
  const [isSecondStepSigned, setSecondStepIsSigned] = useState(false)
  const [isSecondStepRequestPending, setSecondStepRequestIsPendingState] = useState(true)

  const getModalHeader = useMemo(() => (
    <BaseButton
      onClick={onClose}
      variant="secondary"
      onlyIcon
      iconLeft={(<Icon name={IconClose} id="icon--close" />)}
    />
  ), [onClose])

  const getModalStepper = useMemo(() => {
    const initialStepObj = {
      state: 'loading',
      text: 'Waiting for sign...',
      active: 'active',
    }

    let firstStep = initialStepObj
    if (isFirstStepSigned && !isFirstStepRequestPending) {
      firstStep = {
        state: 'completed',
        text: `${details.transactions[0].name} signed`,
      }
    } else if (!isFirstStepSigned && !isFirstStepRequestPending) {
      firstStep = {
        state: 'canceled',
        text: `${details.transactions[0].name} canceled`,
      }
    }

    const steps = [
      {
        badge: {
          state: firstStep.state,
          text: '1',
        },
        text: firstStep.text,
        active: firstStep.active,
      },
    ]

    if (details.transactions.length > 1) {
      const transactionName = details.transactions[1].name
      let secondStep = {
        state: 'active',
        text: transactionName,
      }

      if (currentStep === 2) {
        secondStep = initialStepObj
        if (isSecondStepSigned && !isSecondStepRequestPending) {
          secondStep = {
            state: 'completed',
            text: `${transactionName} signed`,
          }
        } else if (!isSecondStepSigned && !isSecondStepRequestPending) {
          secondStep = {
            state: 'canceled',
            text: `${transactionName} canceled`,
          }
        }
      }

      steps.push({
        badge: {
          state: secondStep.state,
          text: '2',
        },
        text: secondStep.text,
        active: secondStep.active,
      })
    }

    return <Stepper steps={steps} />
  }, [isFirstStepSigned, isFirstStepRequestPending, currentStep, details, isSecondStepSigned, isSecondStepRequestPending])

  const getModalBody = useMemo(() => {
    let bodyData = {
      type: 'metamask',
      title: 'Please, sign approval transaction',
    }

    if (!isFirstStepRequestPending) {
      if (isFirstStepSigned) {
        bodyData = {
          type: 'completed',
          title: 'Approval transaction signed',
        }
      } else {
        bodyData = {
          type: 'canceled',
          title: 'Approval transaction canceled by you',
        }
      }
    }

    return (
      <>
        <Status type={bodyData.type} />
        <BaseTextHeading size="S">{bodyData.title}</BaseTextHeading>
      </>
    )
  }, [isFirstStepRequestPending, isFirstStepSigned])

  const getAssetsBlocks = useMemo(() => (
    <AssetInfo
      swap={{
        block1: (
          <AssetBlock
            title="You Pay"
            bodyName={(
              <>
                <img src={tokensPair.token1.icon} alt="first-token-icon" />
                <BaseTextSubheading>{tokensPair.token1.symbol}</BaseTextSubheading>
              </>
            )}
            bodyValue={checkFloatNaN(tokensPair.token1.value, 0, 9)}
            footerName={tokensPair.token1.name}
            footerValue={`$${tokensPair.token1.currentUsdtPriceBasedOnValue}`}
          />
        ),
        block2: (
          <AssetBlock
            title="You Recieve"
            bodyName={(
              <>
                <img src={tokensPair.token2.icon} alt="first-token-icon" />
                <BaseTextSubheading>{tokensPair.token2.symbol}</BaseTextSubheading>
              </>
            )}
            bodyValue={checkFloatNaN(tokensPair.token2.value, 0, 9)}
            footerName={tokensPair.token2.name}
            footerValue={`$${tokensPair.token2.currentUsdtPriceBasedOnValue}`}
          />
        ),
      }}
      detail={(
        <List items={[
          {
            name: 'Type',
            value: details.type,
          },
          {
            name: 'Trigger Conditions',
            value: details.triggerConditions,
          },
          {
            name: 'Network fee',
            value: `${networkFeeInEth} ${getSymbolBasedOnNetwork()} ~(${networkFeeInUsd})`,
          },
        ]}
        />
      )}
    />
  ), [tokensPair, details, networkFeeInEth, networkFeeInUsd])

  const haveAllTransactionsBeenSigned = useCallback(() => (
    (details.transactions.length === 1 && isFirstStepSigned && !isFirstStepRequestPending)
    || (
      details.transactions.length === 2 && isFirstStepSigned && !isFirstStepRequestPending
      && isSecondStepSigned && !isSecondStepRequestPending
    )
  ), [details, isFirstStepSigned, isFirstStepRequestPending, isSecondStepSigned, isSecondStepRequestPending])

  const requestUserSign = useCallback(async () => {
    try {
      const { vaultRequestId } = details.transactions[currentStep - 1]
      const confirmationDetails = {
        request_id: vaultRequestId,
        signed_request_id: '',
      }

      if (!isGodModeEnabled) {
        confirmationDetails.signed_request_id = (
          await web3React.library.eth.personal.sign(web3React.library.utils.utf8ToHex(vaultRequestId), web3React.account)
        )
      }

      await confirmOrder(confirmationDetails)

      if (currentStep === 1) {
        setFirstStepIsSigned(true)
        setFirstStepRequestIsPendingState(false)
      }

      if (currentStep === 2) {
        setSecondStepIsSigned(true)
        setSecondStepRequestIsPendingState(false)
      }

      if (haveAllTransactionsBeenSigned()) {
        dispatch(setOrderCreationBooleanValue(true))
      }
    } catch (e) {
      console.log('e = ', e)
      if (currentStep === 1) {
        setFirstStepIsSigned(false)
        setFirstStepRequestIsPendingState(false)
      }

      if (currentStep === 2) {
        setSecondStepIsSigned(false)
        setSecondStepRequestIsPendingState(false)
      }
    }
  }, [details, currentStep, web3React, haveAllTransactionsBeenSigned, dispatch, isGodModeEnabled])

  const getCancelButtonTemplate = useMemo(() => {
    if (haveAllTransactionsBeenSigned()) {
      return (<BaseButton onClick={onClose}>Close</BaseButton>)
    }
    return (<BaseButton variant="secondary" onClick={onClose}>Cancel</BaseButton>)
  }, [onClose, haveAllTransactionsBeenSigned])

  const getNextButtonTemplate = useMemo(() => {
    if (
      (currentStep === 1 && !isFirstStepSigned && !isFirstStepRequestPending)
      || (currentStep === 2 && !isSecondStepSigned && !isSecondStepRequestPending)
    ) {
      return (
        <BaseButton
          onClick={() => {
            if (currentStep === 1) {
              setFirstStepRequestIsPendingState(true)
            }
            if (currentStep === 2) {
              setSecondStepRequestIsPendingState(true)
            }
            requestUserSign()
          }}
        >
          Request sign again
        </BaseButton>
      )
    }

    return (
      <BaseButton
        disabled={
          (currentStep === 1 && (isFirstStepRequestPending || !isFirstStepSigned))
          || (currentStep === 2 && (isSecondStepRequestPending || !isSecondStepSigned))
        }
        onClick={() => {
          if (details.transactions.length > 1) {
            setCurrentStep(2)
          }
        }}
      >
        Next Step
      </BaseButton>
    )
  }, [currentStep, isFirstStepRequestPending, isFirstStepSigned, requestUserSign, isSecondStepRequestPending, isSecondStepSigned, details])

  const getModalFooter = useMemo(() => (
    <div className="transactions-modal__footer">
      {getAssetsBlocks}

      <div className="transactions-modal__footer__btn">
        {getCancelButtonTemplate}
        {
          ((details.transactions.length === 1 && !isFirstStepSigned)
          || (details.transactions.length === 2 && (!isFirstStepSigned || !isSecondStepSigned)))
          && getNextButtonTemplate
        }
      </div>
    </div>
  ), [getAssetsBlocks, getCancelButtonTemplate, details, isFirstStepSigned, isSecondStepSigned, getNextButtonTemplate])

  useEffect(() => {
    if (
      (currentStep === 1 && !isFirstStepSigned && isFirstStepRequestPending)
      || (currentStep === 2 && !isSecondStepSigned && isSecondStepRequestPending)
    ) {
      requestUserSign()
    }
  }, [currentStep, isFirstStepSigned, isSecondStepSigned, isFirstStepRequestPending, isSecondStepRequestPending, requestUserSign])

  return (
    <BaseModal
      dataTestId="modal-order-setup"
      title="Order Setup"
      topAction={getModalHeader}
      headerBottom={getModalStepper}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

TransactionsModal.propTypes = {
  isGodModeEnabled: PropTypes.bool,
  tokensPair: PropTypes.object,
  networkFeeInEth: PropTypes.string,
  networkFeeInUsd: PropTypes.string,
  details: PropTypes.object,
  onClose: PropTypes.func,
}

export default TransactionsModal
