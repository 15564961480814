import React, {
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import { writeStorage } from '@rehooks/local-storage'
import { BaseModal } from '../../../../components/BaseModal/BaseModal'
import { Status } from '../../../../components/Status/Status'
import { BaseTextHeading, BaseTextBody } from '../../../../components/BaseText/BaseText'
import { Icon } from '../../../../components/Icon/Icon'
import { BaseButton } from '../../../../components/BaseButton/BaseButton'
import { getBrowserName } from '../../../../utils/helperFunctions'
import IconOpenInNew from '../../../../components/Icon/icon--open-in-new.svg'
import { Stepper } from '../../../../components/Stepper/Stepper'
import { LIST_WEB3_PROVIDER } from '../../../../const/web3'

const ConnectMetamaskModal = ({
  onUserSuccessfullyConected,
}) => {
  const [currentStep, setCurrentStepState] = useState(window.ethereum !== undefined ? 2 : 1)
  const [isMetamaskInstalled] = useState(window.ethereum !== undefined)
  const [isSignApprovePending, setSignApprovePendingState] = useState(window.ethereum !== undefined)
  const [isSignApproved, setSignApprovedState] = useState(false)

  const requestConnectionSign = useCallback(() => {
    (async () => {
      if (currentStep === 2) {
        try {
          await window.ethereum?.send('eth_requestAccounts')
          setSignApprovePendingState(false)
          setSignApprovedState(true)
        } catch (e) {
          setSignApprovePendingState(false)
          setSignApprovedState(false)
        }
      }
    })()
  }, [currentStep])

  const getModalStepper = useMemo(() => {
    const firstStep = {
      state: isMetamaskInstalled ? 'completed' : 'active',
      text: isMetamaskInstalled ? 'Metamask Installed' : 'Install MetaMask',
      active: !isMetamaskInstalled,
    }
    const secondStep = {
      state: isSignApprovePending ? 'loading' : 'active',
      text: isSignApprovePending ? 'Waiting for connection...' : 'Connect MetaMask',
    }

    const steps = [
      {
        badge: {
          state: firstStep.state,
          text: '1',
        },
        text: firstStep.text,
        active: firstStep.active,
      },
      {
        badge: {
          state: secondStep.state,
          text: !isSignApprovePending ? '2' : undefined,
        },
        text: secondStep.text,
        active: secondStep.active,
      },
    ]

    return <Stepper steps={steps} />
  }, [isMetamaskInstalled, isSignApprovePending])

  const getModalBody = useMemo(() => {
    const browserName = getBrowserName()

    const getUrlForInstallation = () => {
      // TODO: remove this eslint disabling
      // eslint-disable-next-line default-case
      switch (browserName) {
        case 'Firefox':
          window.open(
            'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
            '_blank',
          )
          break
        case 'Chrome':
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
            '_blank',
          )
          break
        case 'Opera':
          window.open(
            'https://addons.opera.com/en/extensions/details/metamask/',
            '_blank',
          )
          break
      }
    }

    const getFirstStep = () => {
      const bodyData = {
        type: 'metamask',
        title: 'Please, install MetaMask crypto wallet',
        subTitle: 'We do not support other crypto wallets at the moment.',
      }

      return (
        <>
          <Status type={bodyData.type} />
          <BaseTextHeading size="S">
            {bodyData.title}
          </BaseTextHeading>
          {
            bodyData.subTitle && (
              <BaseTextBody size="S">
                {bodyData.subTitle}
              </BaseTextBody>
            )
          }
          <div className="sign-in-modal__controls-block">
            <BaseButton
              onClick={() => getUrlForInstallation()}
            >
              {`Install MetaMask for ${browserName}`}
            </BaseButton>
            <a href="https://metamask.zendesk.com/hc/en-us/articles/360015489471-How-to-Install-MetaMask-Manually" target="_blank" rel="noreferrer">
              <BaseTextBody> How to setup MetaMask? </BaseTextBody>
              <Icon
                name={IconOpenInNew}
                id="icon--open-in-new"
              />
            </a>
          </div>
        </>
      )
    }

    const getSecondStep = () => {
      let bodyData = {
        type: 'metamask',
        title: 'Please, confirm connection to Cyberfi',
        subTitle: 'Open your MetaMask and sign confirmation..',
      }

      if (!isSignApprovePending) {
        if (isSignApproved) {
          bodyData = {
            type: 'completed',
            title: 'MetaMask successfully connected to Cyberfi',
          }
        } else {
          bodyData = {
            type: 'canceled',
            title: 'MetaMask connection canceled by you',
          }
        }
      }

      return (
        <>
          <Status type={bodyData.type} />
          <BaseTextHeading size="S">
            {bodyData.title}
          </BaseTextHeading>
          {
            bodyData.subTitle && (
              <BaseTextBody size="S">
                {bodyData.subTitle}
              </BaseTextBody>
            )
          }
        </>
      )
    }

    const getNextStepBody = () => {
      switch (currentStep) {
        case 1:
          return getFirstStep()

        case 2:
          return getSecondStep()

        default:
          return getFirstStep()
      }
    }

    return getNextStepBody()
  }, [currentStep, isSignApproved, isSignApprovePending])

  const getModalFooter = useMemo(() => {
    const getNextButton = () => {
      const buttonProps = {
        text: 'Next Step',
        isDisabled: (currentStep === 1 && !isMetamaskInstalled) || (currentStep === 2 && isSignApprovePending),
        callback: () => {
          let step = 1
          if (isMetamaskInstalled) {
            step = currentStep ? currentStep + 1 : 2
          }
          setCurrentStepState(step)

          if (step === 2) {
            setSignApprovePendingState(true)
          }
        },
      }

      if (!isSignApprovePending) {
        buttonProps.isDisabled = false
        if (isSignApproved) {
          buttonProps.text = 'Continue'
          buttonProps.callback = onUserSuccessfullyConected
        } else {
          buttonProps.text = 'Request sign again'
          buttonProps.callback = () => {
            if (window.ethereum !== undefined) {
              setSignApprovePendingState(true)
              requestConnectionSign()
            }
          }
        }
      }

      return (
        <BaseButton
          disabled={buttonProps.isDisabled}
          onClick={buttonProps.callback}
        >
          {buttonProps.text}
        </BaseButton>
      )
    }

    return (
      <div className="sign-in-modal__footer">
        <div className="sign-in-modal__footer__btn">
          {getNextButton()}
        </div>
      </div>
    )
  }, [
    currentStep,
    isMetamaskInstalled,
    isSignApprovePending,
    isSignApproved,
    requestConnectionSign,
    onUserSuccessfullyConected,
  ])

  useEffect(() => {
    writeStorage('selectedProvider', LIST_WEB3_PROVIDER.metamask)
  }, [])
  useEffect(() => {
    requestConnectionSign()
  }, [requestConnectionSign])

  return (
    <BaseModal
      title={currentStep ? 'Sign In With MetaMask' : 'Sign in'}
      headerBottom={currentStep && getModalStepper}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

ConnectMetamaskModal.propTypes = {
  onUserSuccessfullyConected: PropTypes.func,
}

export default ConnectMetamaskModal
