import React from 'react'
import PropTypes from 'prop-types'
import './text.scss'

export const Text = ({
  className,
  text,
}) => (
  <p className={`text ${className || ''}`}>
    {text}
  </p>
)

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
}
