import React, {
  useState, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
// import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
// import { Icon } from '../../../../../../../components/Icon/Icon'
// import IconAdd from '../../../../../../../components/Icon/icon--add.svg'
// import IconArrowRight from '../../../../../../../components/Icon/icon--keyboard-arrow-right.svg'
// import { Divider } from '../../../../../../../components/Divider/Divider'
import AddressBlock from './address-block/AddressBlock'

import './addresses-list.scss'

const AddressesList = ({
  addresses,
  onDeposit,
  // onConnectNewOwnerWallet,
}) => {
  const [activeWallet, setActiveCyberWallet] = useState(useLocalStorage('selectedOwnerWallet')[0])
  const [openedWallet, setOpenedWalletState] = useState()
  const [walletDetails] = useState({
    level: 9,
    currentExperienceValue: 1400,
    maxExperienceValue: 2000,
    questsCount: 19,
  })

  const selectCyberAddress = (wallet) => {
    setActiveCyberWallet(wallet)
    writeStorage('selectedOwnerWallet', wallet)
  }

  const getAddresses = useMemo(
    () => addresses.map((address, key) => (
      <AddressBlock
        key={key}
        address={address}
        walletDetails={walletDetails}
        openedWallet={openedWallet}
        activeWallet={activeWallet}
        onOpenSubmenu={setOpenedWalletState}
        onSelectAddress={selectCyberAddress}
        onDeposit={onDeposit}
      />
    )), [addresses, walletDetails, openedWallet, activeWallet, onDeposit],
  )

  return (
    <>
      <div className="owner-wallet__addresses-list-container">
        <div className="addresses-list">
          {getAddresses}
        </div>
        {/* TODO: uncomment when the magic link functionality is ready */}
        {/* <Divider /> */}
        {/* <div className="create-new-owner-wallet-button" onClick={onConnectNewOwnerWallet}>
          <Icon
            name={IconAdd}
            id="icon--add"
            mod="icon--12"
          />
          <div className="title">
            <BaseTextBody>
              Connect New Owner Account
            </BaseTextBody>
          </div>
          <div className="right-icon">
            <Icon
              name={IconArrowRight}
              id="icon--keyboard-arrow-right"
            />
          </div>
        </div> */}
      </div>
    </>
  )
}

AddressesList.propTypes = {
  addresses: PropTypes.array,
  onDeposit: PropTypes.func,
  // onConnectNewOwnerWallet: PropTypes.func,
}

export default AddressesList
