import React from 'react'
import PropTypes from 'prop-types'
import { Btn } from '../Btn/Btn'
import { Icon } from '../Icon/Icon'
import Icon16Search from '../Icon/icon--16-search.svg'

import './search.scss'

export const Search = ({
  className,
  type,
  name,
  id,
  value,
  placeholder,
}) => (
  <form className={`search ${className || ''}`} action="" method="post">
    <div className="search__wrapper">
      <div className="search__btn">
        <Btn
          mod="btn--icon"
          iconLeft={(
            <Icon
              name={Icon16Search}
              id="icon--16-search"
            />
            )}
        />
      </div>

      <input type={type} name={name} id={id} value={value} placeholder={placeholder} className="search__input" />
    </div>
  </form>
)

Search.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
  ]),
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}
