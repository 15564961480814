import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from '@rehooks/local-storage'
import { StatusBadge } from '../../../../../../components/StatusBadge/StatusBadge'
import { CustomDataTable } from '../../../../../../components/DataTable/DataTable'
import { getHistoryList } from '../../../../../../service/api'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import ImgPairBlock from '../../../../../../components/ImgPairBlock/ImgPairBlock'
import { getUpdateIntervalTimeForOrdersBasedOnNetwork } from '../../../../../../parser/data'

const HistoryTable = ({
  columns,
  selectedPair,
  onOrderClick,
}) => {
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [isDataRequestPending, setDataRequestIsPendingState] = useState(false)
  const [isDataReceived, setDataIsReceivedState] = useState(false)

  const [historyList, setHistoryListState] = useState([])
  const [historyDataList, setHistoryDataListState] = useState([])
  const [historyRowsCount, setHistoryRowsCountState] = useState(0)
  const [rowsPerPage, setRowsPerPageState] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentOrdering, setCurrentOrderding] = useState('-creationTime')

  const dataPoller = useRef()
  const isPollerRequestPending = useRef(false)

  const getOrderElement = useCallback((order) => ({
    id: order.id,
    status: (
      <StatusBadge
        state={order.status.value}
        text={order.status.text}
        label
        onClick={() => onOrderClick(order)}
      />
    ),
    pairs: (
      <ImgPairBlock
        firstTokenIcon={order.pair.token1.icon}
        secondTokenIcon={order.pair.token2.icon}
        text={order.pair.title}
        onClick={() => onOrderClick(order)}
      />
    ),
    transaction: <BaseTextBody onClick={() => onOrderClick(order)}>{order.transaction}</BaseTextBody>,
    type: <BaseTextBody onClick={() => onOrderClick(order)}>{order.type}</BaseTextBody>,
    triggerConditions: <BaseTextBody onClick={() => onOrderClick(order)}>{order.triggerConditions}</BaseTextBody>,
    creationTime: <BaseTextBody secondary onClick={() => onOrderClick(order)}>{order.creationTime}</BaseTextBody>,
  }), [onOrderClick])

  const loadHistoryList = useCallback(async ({
    limit,
    page,
    ordering,
    address1,
    address2,
  }) => {
    try {
      const historyData = await getHistoryList({
        cyberWallet: internalSelectedAddress,
        limit: limit || rowsPerPage,
        currentPage: page || currentPage,
        ordering: ordering || currentOrdering,
        address1: address1 || (selectedPair !== 'all-pairs' ? selectedPair.token1.address : ''),
        address2: address2 || (selectedPair !== 'all-pairs' ? selectedPair.token2.address : ''),
      })

      setHistoryDataListState(historyData.rows)
      setHistoryListState(historyData.rows.map((order) => getOrderElement(order)))
      setHistoryRowsCountState(historyData.count)
      setDataRequestIsPendingState(false)
      setDataIsReceivedState(true)
      isPollerRequestPending.current = false
    } catch (e) {
      setHistoryListState([])
      setDataRequestIsPendingState(false)
      setDataIsReceivedState(true)
      isPollerRequestPending.current = false
    }
  }, [internalSelectedAddress, rowsPerPage, currentPage, currentOrdering, selectedPair, getOrderElement])

  const onChangeRowsPerPage = useCallback((rowsPerPageNewValue) => {
    setRowsPerPageState(rowsPerPageNewValue)
    setDataRequestIsPendingState(true)
    loadHistoryList({
      limit: rowsPerPageNewValue,
    })
  }, [loadHistoryList])

  const onChangePage = useCallback((newPage) => {
    setCurrentPage(newPage)
    setDataRequestIsPendingState(true)
    loadHistoryList({
      page: newPage,
    })
  }, [loadHistoryList])

  const onSort = useCallback((column, sortDirection) => {
    const newOrdering = `${sortDirection === 'desc' ? '-' : ''}${column.selector}`
    setCurrentOrderding(newOrdering)
    setDataRequestIsPendingState(true)
    loadHistoryList({
      ordering: newOrdering,
    })
  }, [loadHistoryList])

  useEffect(() => {
    setDataRequestIsPendingState(true)
    loadHistoryList({
      address1: selectedPair !== 'all-pairs' ? selectedPair.token1.address : '',
      address2: selectedPair !== 'all-pairs' ? selectedPair.token2.address : '',
    })
  }, [loadHistoryList, selectedPair])

  useEffect(() => {
    if (!isDataReceived && !isDataRequestPending) {
      setDataRequestIsPendingState(true)
      loadHistoryList({})
    }
  }, [isDataReceived, isDataRequestPending, loadHistoryList])

  const stopPollingForHistory = useCallback(() => {
    if (dataPoller.current) {
      clearInterval(dataPoller.current)
      dataPoller.current = undefined
      isPollerRequestPending.current = false
    }
  }, [])

  const startPollingForHistory = useCallback(() => {
    if (!dataPoller.current) {
      const intervalTime = getUpdateIntervalTimeForOrdersBasedOnNetwork()

      dataPoller.current = setInterval(async () => {
        if (!isPollerRequestPending.current && !isDataRequestPending) {
          isPollerRequestPending.current = true
          loadHistoryList({})
        }
      }, intervalTime)
    }
  }, [isDataRequestPending, loadHistoryList])

  useEffect(() => {
    startPollingForHistory()
    return () => stopPollingForHistory()
  }, [startPollingForHistory, stopPollingForHistory])

  return (
    <CustomDataTable
      columns={columns}
      data={historyList}
      isDataLoading={isDataRequestPending}
      emptyContentDescription="Orders history is empty"
      paginationTotalRows={historyRowsCount}
      defaultSortField="creationTime"
      defaultSortAsc={false}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      onSort={onSort}
      onRowClicked={(selectedRow) => onOrderClick(historyDataList.filter((order) => order.id === selectedRow.id)[0])}
    />
  )
}

HistoryTable.propTypes = {
  columns: PropTypes.array,
  selectedPair: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onOrderClick: PropTypes.func,
}

export default HistoryTable
