import React from 'react'
import PropTypes from 'prop-types'
import './tab.scss'

export const Tab = ({
  text,
  onClick,
  active,
}) => {
  function handleClick() {
    onClick()
  }

  return (
    <div className={`tab ${active ? 'tab--active' : ''}`} onClick={handleClick}>
      {text}
    </div>
  )
}

Tab.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}
