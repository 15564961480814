import React, {
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import DeleteCyberWalletModalMsgStep from './delete-cyber-wallet-modal-msg-step-modal/DeleteCyberWalletModalMsgStep'
import DeleteCyberWalletModalEnterPinStep from './delete-cyber-wallet-modal-enter-pin-step-modal/DeleteCyberWalletModalEnterPinStep'

const CONST_MODAL_STEPS = {
  msg: 'msg',
  enterPin: 'enterPin',
}

const DeleteCyberWalletStepsModal = ({
  chosenCyberWallet,
  onCloseModal,
  onAfterRemove,
}) => {
  const [currentStep, setCurrentStep] = useState(CONST_MODAL_STEPS.msg)

  const getDefaultStep = useMemo(() => (
    <DeleteCyberWalletModalMsgStep
      chosenCyberWallet={chosenCyberWallet}
      onCloseModal={onCloseModal}
      onNextStep={() => setCurrentStep(CONST_MODAL_STEPS.enterPin)}
    />
  ), [chosenCyberWallet, onCloseModal])

  const getStep = useMemo(() => {
    switch (currentStep) {
      case CONST_MODAL_STEPS.msg:
        return getDefaultStep
      case CONST_MODAL_STEPS.enterPin:
        return (
          <DeleteCyberWalletModalEnterPinStep
            onAfterRemove={onAfterRemove}
            chosenCyberWallet={chosenCyberWallet}
            onCloseModal={onCloseModal}
          />
        )
      default:
        return getDefaultStep
    }
  }, [chosenCyberWallet, onCloseModal, currentStep, getDefaultStep, onAfterRemove])

  return (
    <>
      {getStep}
    </>
  )
}

DeleteCyberWalletStepsModal.propTypes = {
  chosenCyberWallet: PropTypes.object,
  onCloseModal: PropTypes.func,
  onAfterRemove: PropTypes.func,
}

export default DeleteCyberWalletStepsModal
