import React from 'react'
import PropTypes from 'prop-types'
import './menu.scss'

export const Menu = ({
  children,
  menuType,
}) => (
  <div className={`menu ${menuType || ''}`}>
    {children}
  </div>
)

Menu.propTypes = {
  children: PropTypes.node,
  menuType: PropTypes.oneOf([
    'menu--wallet-submenu',
  ]),
}
