import React from 'react'
import BridgeBody from './bridge-body/BridgeBody'
import { Social } from '../../../../components/Social/Social'

import './bridge.scss'

const Bridge = () => (
  <div className="bridge">
    <div className="bridge__body">
      <BridgeBody data-testid="bridgeBody" />
    </div>
    <div className="bridge__footer">
      <Social twitter discord telegram />
    </div>
  </div>
)

export default Bridge
