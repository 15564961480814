import React from 'react'
import PropTypes from 'prop-types'
import './functionality-row.scss'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'

const FunctionalityRow = ({
  data,
}) => (
  <div className="functionality-row">
    {data.map((item, index) => (
      <div
        className={`functionality-row__col ${item.disabled ? 'disabled' : ''}`}
        key={index}
      >
        <BaseTextBody>{item.text}</BaseTextBody>

        {item.orders && (
        <div className="functionality-row__orders">
          {item.orders.map((order, orderIndex) => (
            <div className="functionality-row__order" key={orderIndex}>
              <BaseTextBody
                size="S"
              >
                {order}
              </BaseTextBody>
            </div>
          ))}
        </div>
        )}
      </div>
    ))}
  </div>
)

FunctionalityRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    orders: PropTypes.arrayOf(PropTypes.string),
  })),
}

export default FunctionalityRow
