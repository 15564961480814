import React from 'react'
import PropTypes from 'prop-types'
import { H1 } from '../H1/H1'
import { Search } from '../Search/Search'
import { BtnForm } from '../BtnForm/BtnForm'
import { ListHistory } from '../ListHistory/ListHistory'
import { ItemCurrencyChartOrderbook } from '../ItemCurrencyChartOrderbook/ItemCurrencyChartOrderbook'
import IconArrowsGorixontal from '../Icon/icon--arrows-gorizontal.svg'
import Img32Dai from '../IconItemHistory/img-32-dai.png'
import Img32Eth from '../IconItemHistory/img-32-eth.png'
import Img32Maker from '../IconItemHistory/img-32-maker.png'
import Img32Unicron from '../IconItemHistory/img-32-unicron.png'
import Img32Assets from '../IconItemHistory/img-32-assets.png'
import Icon12LabelYelow from '../Icon/icon--12-label-yelow.svg'
import Icon12LabelRedWhite from '../Icon/icon--12-label-red-white.svg'
import Icon12LabelRed from '../Icon/icon--12-label-red.svg'
import IconArrowBootom from '../Icon/icon--arrow-bootom.svg'
import Icon16ContractLabel from '../Icon/icon--16-contract-label.svg'
import Icon16ApprovaLabel from '../Icon/icon--16-approval-label.svg'
import Icon16AddLiquidityLabel from '../Icon/icon--16-add-liquidity-label.svg'

import './main-history.scss'

const ListHistoryData = [
  <ItemCurrencyChartOrderbook
    className="item-currency-chart-orderbook--history item-currency-chart-orderbook--history-blue-icon"
    iconName={IconArrowBootom}
    iconId="icon--arrow-bootom"
    title="Receive"
    date="04:13 PM"
    iconImgOne={Img32Unicron}
    iconImgOneAlt="Eth"
    iconImgOneTitle="+ 405.562 UNI"
    iconImgOneDate="N/A"
    iconOneTooltip
    titleFrom="From"
    iconFromName={Icon12LabelYelow}
    iconFromId="icon--12-label-yelow"
    keysFrom="0xc92e...03f0"
  />,
  <ItemCurrencyChartOrderbook
    className="item-currency-chart-orderbook--history"
    iconName={IconArrowsGorixontal}
    iconId="icon--arrows-gorizontal"
    title="Trade"
    date="Sept 17, 2020 08:07 PM"
    iconImgOne={Img32Eth}
    iconImgOneAlt="Eth"
    iconImgOneTitle="- 0.029 ETH"
    iconImgOneDate="$4.99"
    iconArrow
    iconImgTwo={Img32Dai}
    iconImgTwoAlt="Dai"
    iconImgTwoTitle="+ 5 DAI"
    iconImgTwoDate="$5.03"
    titleFrom="Application"
    iconFromName={Icon12LabelRed}
    iconFromId="icon--12-label-red"
    keysFrom="Uniswap"
  />,
]
const ListHistoryApprovalLiquidityData = [
  <ItemCurrencyChartOrderbook
    className="item-currency-chart-orderbook--history"
    iconName={Icon16AddLiquidityLabel}
    iconId="icon--16-add-liquidity-label"
    title="Add Liquidity"
    date="12:14 AM"
    iconImgOne={Img32Assets}
    iconImgOneAlt="Assets"
    iconImgOneTitle="2 Assets"
    iconImgOneDate="$10"
    iconArrow
    iconImgTwo={Img32Dai}
    iconImgTwoAlt="Dai"
    iconImgTwoTitle="+ 0.028 UNI-V1"
    iconImgTwoDate="$10.53"
    titleFrom="Application"
    iconFromName={Icon12LabelRed}
    iconFromId="icon--12-label-red"
    keysFrom="Uniswap"
  />,
  <ItemCurrencyChartOrderbook
    className="item-currency-chart-orderbook--history"
    iconName={Icon16ApprovaLabel}
    iconId="icon--16-approval-label"
    title="Approval"
    date="12:14 AM"
    iconImgOne={Img32Maker}
    iconImgOneAlt="Maker"
    iconImgOneTitle="Maker"
    titleFrom="From"
    iconFromName={Icon12LabelRedWhite}
    iconFromId="icon--12-label-red-white"
    keysFrom="0xc92e...03f0"
  />,
]

export const MainHistory = ({
  className,
}) => (
  <div className={`main-history ${className || ''}`}>
    <div className="main-history__header">
      <H1
        className="h1--medium h1--primary main-history__title"
        text="HISTORY"
      />
    </div>

    <div className="main-history__control">
      <Search
        type="text"
        name="search"
        placeholder="Filter by Address, Protocol, Asset, Type"
      />

      <BtnForm
        text="DOWNLOAD CSV"
      />
    </div>

    <div className="main-history__item">
      <ListHistory
        title="September 17, 2020"
        items={ListHistoryData}
      />
    </div>

    <div className="main-history__item">
      <ListHistory
        title="August 29, 2020"
        items={[
          <ItemCurrencyChartOrderbook
            className="item-currency-chart-orderbook--history"
            iconName={Icon16ContractLabel}
            iconId="icon--16-contract-label"
            title="Contract Execution"
            date="08:15 PM"
            titleFrom="From"
            iconFromName={Icon12LabelYelow}
            iconFromId="icon--12-label-yelow"
            keysFrom="0xc92e...03f0"
          />,
        ]}
      />
    </div>

    <div className="main-history__item">
      <ListHistory
        title="August 21, 2020"
        items={[
          <ItemCurrencyChartOrderbook
            className="item-currency-chart-orderbook--history"
            iconName={Icon16ApprovaLabel}
            iconId="icon--16-approval-label"
            title="Approval"
            date="12:14 AM"
            iconImgOne={Img32Maker}
            iconImgOneAlt="Maker"
            iconImgOneTitle="Maker"
            titleFrom="From"
            iconFromName={Icon12LabelRedWhite}
            iconFromId="icon--12-label-red-white"
            keysFrom="0xc92e...03f0"
          />,
        ]}
      />
    </div>

    <div className="main-history__item">
      <ListHistory
        title="August 21, 2020"
        items={ListHistoryApprovalLiquidityData}
      />
    </div>
  </div>
)

MainHistory.propTypes = {
  className: PropTypes.string,
}
