import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import './pie-chart.scss'
import { checkFloatNaN, generateUniqueKey } from '../../utils/helperFunctions'
import { BaseTextBody } from '../BaseText/BaseText'

export const PieChart = ({
  items,
  dataTestid,
  listShow,
}) => {
  const [maxPercent, setMaxPercent] = useState(0)

  const itemsPercent = useMemo(() => {
    let totalCount = 0
    let percent = 0
    let totalWidthChart = 0
    const newItemsArray = []
    let maxNumber = 0
    let strokeWidth = 3

    // eslint-disable-next-line array-callback-return,no-lone-blocks
    { items.map((item) => {
      if (item.number >= maxNumber) { checkFloatNaN(maxNumber = item.number, 0) }

      totalCount += checkFloatNaN(item.number, 0)
    }) }

    percent = checkFloatNaN(totalCount / 100, 0)
    setMaxPercent(maxNumber / percent)

    // eslint-disable-next-line array-callback-return,no-lone-blocks
    { items.map((item) => {
      if (item.number === maxNumber) {
        strokeWidth = 6
      } else {
        strokeWidth = 4
      }

      newItemsArray.push({
        number: checkFloatNaN(item.number / percent, 0),
        text: item.text,
        color: item.color,
        strokeWidth,
        offset: totalWidthChart,
      })

      totalWidthChart += checkFloatNaN(item.number / percent, 0)
    }) }

    return newItemsArray
  }, [items, setMaxPercent])

  return (
    <div className="pie-chart">
      <div className="pie-chart__chart">
        <svg viewBox="0 0 38 38" className="pie-chart__svg">
          <circle className="pie-chart__ring" cx="19" cy="19" r="15.91549430918954" fill="none" stroke="#d2d3d4" strokeWidth="4" />

          {itemsPercent.map((item) => (
            <circle
              className="pie-chart__ring"
              cx="19"
              cy="19"
              r="15.91549430918954"
              fill="none"
              stroke={item.color}
              strokeWidth={item.strokeWidth}
              strokeDasharray={`${item.number} ${100 - item.number}`}
              strokeDashoffset={checkFloatNaN(100 - item.offset, 0)}
              key={generateUniqueKey()}
            />
          ))}

          <g>
            <text
              data-testid={dataTestid}
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              className="pie-chart__percent"
            >
              {maxPercent ? Math.trunc(maxPercent) : 0}
              %
            </text>
          </g>
        </svg>
      </div>

      {listShow && (
      <div className="pie-chart__list">
        {itemsPercent.map((item) => (
          <div className="pie-chart__item" key={generateUniqueKey()}>
            <div className="pie-chart__item-body">
              <div className="pie-chart__item-color-block" style={{ backgroundColor: item.color }} />
              <div className="pie-chart__item-text">
                <BaseTextBody size="S">{item.text}</BaseTextBody>
              </div>
            </div>

            <div className="pie-chart__item-value">
              <BaseTextBody size="S" secondary>
                {item.number.toFixed(2)}
                %
              </BaseTextBody>
            </div>
          </div>
        ))}
      </div>
      )}
    </div>
  )
}

PieChart.propTypes = {
  items: PropTypes.array,
  dataTestid: PropTypes.string,
  listShow: PropTypes.bool,
}
