import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../../Icon/Icon'
import IconSelectCircleActive from '../../Icon/icon--select-circle-active.svg'
import IconSelectCircle from '../../Icon/icon--select-circle.svg'
import { BaseTextBody } from '../../BaseText/BaseText'
// eslint-disable-next-line import/no-cycle
import { SelectBase } from '../../SelectBase/SelectBase'
import IconSelectBase from '../../Icon/icon--select-base.svg'
import { Pagination } from '../../Pagination/Pagination'

export const DataTableFooter = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  const items = useMemo(() => {
    const newArr = [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }]

    return newArr.map((item) => {
      // eslint-disable-next-line eqeqeq
      if (item.value == rowsPerPage?.value || item.value == rowsPerPage) {
        return {
          name: item.value,
          value: item.value,
          icon: <Icon name={IconSelectCircleActive} id="icon--select-circle-active" />,
        }
      }

      return {
        name: item.value,
        value: item.value,
        icon: <Icon name={IconSelectCircle} id="icon--select-circle" />,
      }
    })
  }, [rowsPerPage])

  return (
    <div className="data-table__footer">
      <div className="data-table__rows-per-page">
        <BaseTextBody secondary>Rows per page</BaseTextBody>
        <SelectBase
          selected={rowsPerPage?.value ? rowsPerPage.value : rowsPerPage}
          onChange={onChangeRowsPerPage}
          className="select-base--new"
          classNameDropDown="select-base__list-container--up"
          headerIcon={IconSelectBase}
          headerIconID="icon--select-base"
          items={items}
        />
      </div>

      <Pagination
        rowsPerPage={rowsPerPage}
        rowCount={rowCount}
        currentPage={currentPage}
        onChangePage={onChangePage}
      />
    </div>
  )
}

DataTableFooter.propTypes = {
  rowsPerPage: PropTypes.number,
  rowCount: PropTypes.number,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
}
