import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import IconInfoToolTip from '../Icon/icon--info-tool-tip.svg'
import './tooltip.scss'

export const Tooltip = ({
  className,
}) => (
  <div className={`tooltip ${className || ''}`}>
    <Icon
      name={IconInfoToolTip}
      id="icon--info-tool-tip"
      mod="icon--12"
    />
  </div>
)

Tooltip.propTypes = {
  className: PropTypes.string,
}
