import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import debounce from 'lodash/debounce'
import { BigNumber } from 'bignumber.js'
import { SelectBase } from '../../../../../../components/SelectBase/SelectBase'
import { Icon } from '../../../../../../components/Icon/Icon'
import IconUnfold from '../../../../../../components/Icon/icon--unfold.svg'
import Asset from '../../../../../../components/Asset/Asset'
import IconHorizontalSwitcher from '../../../../../../components/Icon/icon--horizontal-switcher.svg'
import IconEthereum from '../../../../../../components/Icon/icon--ethereum.svg'
import IconBsc from '../../../../../../components/Icon/icon--bsc.png'
import IconPolygon from '../../../../../../components/Icon/icon--polygon.png'
import ExchangeItem from './exchange-item/ExchangeItem'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import SwapInfo from '../../../../../../components/SwapInfo/SwapInfo'
import { BaseButton } from '../../../../../../components/BaseButton/BaseButton'
import { LIST_WEB3_PROVIDER } from '../../../../../../const/web3'
import {
  ETHEREUM_NETWORK_SETTINGS_FOR_BRIDGE,
  BSC_NETWORK_SETTINGS_FOR_BRIDGE,
  POLYGON_NETWORK_SETTINGS_FOR_BRIDGE,
  ETHEREUM_NETWORK_SETTINGS,
  BSC_NETWORK_SETTINGS,
  POLYGON_NETWORK_SETTINGS,
} from '../../../../../../const'
import { useWeb3 } from '../../../../../../context/web3Provider'
import { useWeb3Bn } from '../../../../../../hooks/web3'
import {
  loadTokenData, checkFloatNaN, formatDecimal,
} from '../../../../../../utils/helperFunctions'
import {
  getInitialTokenValue,
  getParsedTokenValue,
  getChainNameBasedOnId,
} from '../../../../../../parser/data'
import Loading from '../../../../../../components/Loading/Loading'
import IconHelpOutline from '../../../../../../components/Icon/icon--help-outline.svg'
import { Alert } from '../../../../../../components/Alert/Alert'
import TransactionModal from './transaction-modal/TransactionModal'
import { getAllPossibleTokensForSelectedNetworks } from '../../../../../../service/bridge'
import IconInbox from '../../../../../../components/Icon/icon--inbox.svg'

import './bridge-setup.scss'

const BridgeSetup = ({
  cachedTokenValue,
  onSetCachedTokenValue,
}) => {
  const web3React = useWeb3()
  const Web3BN = useWeb3Bn()

  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [selectedOwnerWallet] = useLocalStorage('selectedOwnerWallet')
  const [selectedChain] = useLocalStorage('selectedChain')
  const [bridgeSettings] = useLocalStorage('bridgeSettings')
  const [selectedProvider] = useLocalStorage('selectedProvider')
  const {
    latestNativeTokenPrice,
    tokens,
    areInternalTokensLoading,
    externalAddresses,
  } = useSelector((store) => store.user)

  const getIconForNetwork = useCallback((name) => {
    switch (name) {
      case 'Binance Smart Chain':
        return <img src={IconBsc} alt="img" />

      case 'Polygon':
        return <img src={IconPolygon} alt="img" />

      default:
        return <Icon name={IconEthereum} id="icon--ethereum" />
    }
  }, [])

  const networksList = [
    {
      name: (
        <Asset
          icon={getIconForNetwork()}
          assetName={ETHEREUM_NETWORK_SETTINGS_FOR_BRIDGE.name}
        />
      ),
      value: ETHEREUM_NETWORK_SETTINGS_FOR_BRIDGE,
    },
    {
      name: (
        <Asset
          icon={getIconForNetwork('Binance Smart Chain')}
          assetName={BSC_NETWORK_SETTINGS_FOR_BRIDGE.name}
        />
      ),
      value: BSC_NETWORK_SETTINGS_FOR_BRIDGE,
    },
    {
      name: (
        <Asset
          icon={getIconForNetwork('Polygon')}
          assetName={POLYGON_NETWORK_SETTINGS_FOR_BRIDGE.name}
        />
      ),
      value: POLYGON_NETWORK_SETTINGS_FOR_BRIDGE,
    },
  ]

  const [selectedNetworkFrom, setSelectedNetworkFrom] = useState(networksList.find(
    (network) => network.value.name === selectedChain || (selectedChain === 'BSC' && network.value.chainId === 56),
  ))
  const [selectedNetworkTo, setSelectedNetworkTo] = useState(networksList.filter(
    (network) => network.value.chainId !== selectedNetworkFrom.value.chainId,
  )[0])
  const [selectedToken, setSelectedToken] = useState({})
  const [cachedSelectedToken, setCachedSelectedToken] = useState()
  const [isTokenDataLoading, setTokenDataIsLoading] = useState(false)

  const [tokenValue, setTokenValue] = useState('0')
  const [wasTokenValueUpdatedByUser, setTokenValueWasUpdatedByUserState] = useState(false)
  const [isTokenValueInputIsFocused, setTokenValueInputIsFocusedState] = useState(false)

  const [sufficientBalanceError, setSufficientBalanceError] = useState()
  const [valueLimitError, setValueLimitError] = useState()

  const [isTransactionModalShown, setTransactionModalIsShown] = useState(false)

  const [allPossibleTokens, setAllPossibleTokens] = useState([])
  const [areAllPossibleTokensLoading, setAllPossibleTokensAreLoading] = useState(true)
  const [areAllPossibleTokensSuccessfullyLoaded, setAllPossibleTokensAreSuccessfullyLoaded] = useState(false)

  const [isFeeLoading, setFeeIsLoading] = useState(true)

  const [doesUserHaveTokensToSwap] = useState(true)

  const getTokenValueIsEnoughForBridgeMessage = useCallback(({ currentToken, exactTokenValue }) => {
    const minSwap = new BigNumber(currentToken.swap.min)
    const maxSwap = new BigNumber(currentToken.swap.max)

    if (exactTokenValue.lt(minSwap)) {
      return `Minimum amount to swap is ${currentToken.swap.min} ${currentToken.symbol}`
    }
    if (exactTokenValue.gt(maxSwap)) {
      return `Maximum amount to swap is ${currentToken.swap.max} ${currentToken.symbol}`
    }
    return undefined
  }, [])

  const checkThatBalanceIsSufficient = useCallback(({ exactBalance, value, token }) => {
    const currentToken = token || selectedToken
    const exactTokenBalance = new BigNumber(exactBalance || currentToken.exactTokenBalance)
    const exactTokenValue = new BigNumber(value || tokenValue)

    const limitErrorMessage = getTokenValueIsEnoughForBridgeMessage({ currentToken, exactTokenValue })
    setValueLimitError(limitErrorMessage)

    if (!limitErrorMessage) {
      const isNotSufficient = exactTokenBalance.toString() === '0' || exactTokenBalance.lt(exactTokenValue)
      setSufficientBalanceError(isNotSufficient ? `Insufficient ${currentToken.symbol} balance` : undefined)
    } else {
      setSufficientBalanceError()
    }
  }, [selectedToken, tokenValue, getTokenValueIsEnoughForBridgeMessage])

  const loadAndSetToken = useCallback(async ({
    necessaryToken,
    value,
    isUpdatedByUser,
    possibleTokens,
    isNewTokenOrNetworkSelected,
  }) => {
    try {
      const token = await loadTokenData({
        token: necessaryToken && Object.keys(necessaryToken).length > 0 ? necessaryToken : possibleTokens[0],
        web3React,
        internalSelectedAddress,
        Web3BN,
        tokens,
        latestNativeTokenPrice,
      })

      setSelectedToken(token)
      setCachedSelectedToken(token)
      setTokenDataIsLoading(false)
      setFeeIsLoading(false)

      if (!isNewTokenOrNetworkSelected && cachedTokenValue && !wasTokenValueUpdatedByUser) {
        setTokenValue(cachedTokenValue)
        checkThatBalanceIsSufficient({ exactBalance: token.exactTokenBalance, value: cachedTokenValue })
      } else if ((!isUpdatedByUser && !wasTokenValueUpdatedByUser) || isNewTokenOrNetworkSelected) {
        const initValue = getInitialTokenValue(token.exactTokenBalance)
        // here we gotta set 1, because otherwise the RPC requests will faill down with an error
        const parsedTokenValue = initValue !== '0' ? initValue : '1'
        setTokenValue(parsedTokenValue)
        checkThatBalanceIsSufficient({ exactBalance: token.exactTokenBalance, value: parsedTokenValue, token })
      } else {
        checkThatBalanceIsSufficient({ exactBalance: token.exactTokenBalance, value })
      }
    } catch (e) {
      setTokenDataIsLoading(false)
      console.log('Token data load error = ', e)
    }
  }, [
    web3React,
    internalSelectedAddress,
    Web3BN,
    latestNativeTokenPrice,
    cachedTokenValue,
    wasTokenValueUpdatedByUser,
    checkThatBalanceIsSufficient,
    tokens,
  ])

  const loadAllPossibleTokensBasedOnNetworks = useCallback(async (networkTo) => {
    setAllPossibleTokensAreLoading(true)
    setAllPossibleTokensAreSuccessfullyLoaded(false)

    try {
      const possibleTokens = await getAllPossibleTokensForSelectedNetworks({
        networkFrom: selectedNetworkFrom.value.chainId,
        networkTo: networkTo || selectedNetworkTo.value.chainId,
      })

      setAllPossibleTokens(possibleTokens)
      setAllPossibleTokensAreLoading(false)
      setAllPossibleTokensAreSuccessfullyLoaded(true)
      return possibleTokens
    } catch (e) {
      console.log('Could not load all possible tokens for chosen networks... = ', e)
      setAllPossibleTokens([])
      setAllPossibleTokensAreLoading(false)
      setAllPossibleTokensAreSuccessfullyLoaded(false)
      return []
    }
  }, [selectedNetworkFrom, selectedNetworkTo])

  const loadTokensData = useCallback(async ({ networkTo, isNewTokenOrNetworkSelected }) => {
    let possibleTokens = allPossibleTokens
    if (networkTo || !areAllPossibleTokensSuccessfullyLoaded) {
      possibleTokens = await loadAllPossibleTokensBasedOnNetworks(networkTo)
    }
    const necessaryToken = !isNewTokenOrNetworkSelected ? selectedToken : undefined
    await loadAndSetToken({ necessaryToken, possibleTokens, isNewTokenOrNetworkSelected })
  }, [allPossibleTokens, areAllPossibleTokensSuccessfullyLoaded, selectedToken, loadAllPossibleTokensBasedOnNetworks, loadAndSetToken])

  useEffect(() => {
    (async () => {
      if (
        web3React.library
        && !areInternalTokensLoading
        && !isTokenDataLoading
        && JSON.stringify(selectedToken) !== JSON.stringify(cachedSelectedToken)
        && !areAllPossibleTokensSuccessfullyLoaded
      ) {
        await loadTokensData({})
      }
    })()
  }, [
    web3React,
    areInternalTokensLoading,
    isTokenDataLoading,
    selectedToken,
    cachedSelectedToken,
    areAllPossibleTokensSuccessfullyLoaded,
    loadTokensData,
  ])

  const onFormUpdateCompletely = useCallback(() => {
    onSetCachedTokenValue()
    setFeeIsLoading(true)
    setSufficientBalanceError()
    setValueLimitError()
    setTokenDataIsLoading(true)
    setTokenValueWasUpdatedByUserState(false)
  }, [onSetCachedTokenValue])

  const onSelectNetwork = useCallback(async ({ newSelectedObj, isFrom }) => {
    const chosenChainId = newSelectedObj.value.chainId
    let networkSettings = ETHEREUM_NETWORK_SETTINGS
    if (chosenChainId === 56) {
      networkSettings = BSC_NETWORK_SETTINGS
    } else if (chosenChainId === 137) {
      networkSettings = POLYGON_NETWORK_SETTINGS
    }

    try {
      if (isFrom) {
        if (selectedProvider === LIST_WEB3_PROVIDER.metamask) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: networkSettings.chainId }],
          })
        } else {
          writeStorage('selectedChain', getChainNameBasedOnId(networkSettings.chainId))
          // there is no callback for the network change event for other providers yet
          window.location.reload()
        }

        setSelectedNetworkFrom(newSelectedObj)
        if (selectedNetworkTo.value.chainId === newSelectedObj.value.chainId) {
          setSelectedNetworkTo(networksList.filter((network) => network.value.chainId !== newSelectedObj.value.chainId)[0])
        }
      } else {
        setSelectedNetworkTo(newSelectedObj)
        if (selectedNetworkFrom.value.chainId === newSelectedObj.value.chainId) {
          const newNetworkFrom = networksList.filter((network) => network.value.chainId !== newSelectedObj.value.chainId)[0]
          setSelectedNetworkFrom(newNetworkFrom)

          let newChainIdFrom = '0x1'
          if (newNetworkFrom.value.chainId === 56) {
            newChainIdFrom = '0x38'
          } else if (newNetworkFrom.value.chainId === 137) {
            newChainIdFrom = '0x89'
          }
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: newChainIdFrom }],
          })
        }

        setCachedSelectedToken()
        onFormUpdateCompletely()
        await loadTokensData({ networkTo: newSelectedObj.value.chainId, isNewTokenOrNetworkSelected: true })
      }
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkSettings],
          })
        } catch (e) {
          console.log('Polygon network adding failed = ', e)
        }
      }
    }
  }, [selectedProvider, networksList, selectedNetworkFrom, selectedNetworkTo, loadTokensData, onFormUpdateCompletely])

  const getNetworksListFrom = useCallback((isFrom) => (
    <div className="bridge-setup__networks-select" data-testid={`network${isFrom ? 'One' : 'Two'}`}>
      <SelectBase
        size="M"
        className="select-base--new"
        headerIcon={IconUnfold}
        headerIconID="icon--unfold"
        items={networksList}
        selected={isFrom ? selectedNetworkFrom.name : selectedNetworkTo.name}
        onChange={(newSelected) => {
          const newSelectedObj = {
            name: (
              <Asset
                icon={getIconForNetwork(newSelected.name)}
                assetName={newSelected.name}
              />
            ),
            value: newSelected,
          }

          onSelectNetwork({ newSelectedObj, isFrom })
        }}
      />
    </div>
  ), [networksList, selectedNetworkFrom, selectedNetworkTo, getIconForNetwork, onSelectNetwork])

  const loadAndSetTokenWithDebounce = useCallback((newValue) => {
    (debounce(async () => loadAndSetToken({
      necessaryToken: selectedToken,
      value: newValue,
      isUpdatedByUser: true,
      possibleTokens: allPossibleTokens,
    }), 1000))(newValue)
  }, [loadAndSetToken, selectedToken, allPossibleTokens])

  const getExchangeItem = useMemo(() => (
    <ExchangeItem
      allPossibleTokens={allPossibleTokens}
      selectedToken={selectedToken}
      isMaxButtonEnabled={false}
      tokenValue={!isTokenValueInputIsFocused && !wasTokenValueUpdatedByUser ? getParsedTokenValue(tokenValue) : tokenValue}
      tokenPrice={Object.keys(selectedToken).length > 0 ? checkFloatNaN(selectedToken.usdtPrice * tokenValue, 0, 2) : 0}
      onChangeTokenValue={((value) => {
        setFeeIsLoading(true)
        setTokenValue(value)
        setTokenValueWasUpdatedByUserState(true)
        onSetCachedTokenValue(value)
        loadAndSetTokenWithDebounce(value)
      })}
      onSelectToken={(token) => {
        onFormUpdateCompletely()
        loadAndSetToken({ necessaryToken: token, isNewTokenOrNetworkSelected: true })
      }}
      onFocus={() => setTokenValueInputIsFocusedState(true)}
      onFocusOut={() => setTokenValueInputIsFocusedState(false)}
    />
  ), [
    allPossibleTokens,
    selectedToken,
    isTokenValueInputIsFocused,
    wasTokenValueUpdatedByUser,
    tokenValue,
    loadAndSetTokenWithDebounce,
    loadAndSetToken,
    onSetCachedTokenValue,
    onFormUpdateCompletely,
  ])

  const getSwapInfo = useMemo(() => (
    <div className="bridge-setup__swap-info">
      <SwapInfo items={[
        {
          name: (
            <BaseTextBody secondary>
              <Icon name={IconHelpOutline} id="icon--help-outline" />
              <div>Network Fee</div>
            </BaseTextBody>
          ),
          value: !isFeeLoading
            ? (
              <BaseTextBody>{`${selectedToken.fee.value} ${selectedToken.symbol}`}</BaseTextBody>
            )
            : <Loading size="S" />
          ,
        },
      ]}
      />
    </div>
  ), [isFeeLoading, selectedToken])

  const getBridgeContent = useMemo(() => (
    doesUserHaveTokensToSwap
      ? (
        <>
          <div className="bridge-setup__content">
            <div className="bridge-setup__networks">
              <div className="bridge-setup__networks-header">
                <BaseTextBody secondary>Networks</BaseTextBody>
              </div>

              <div className="bridge-setup__networks-body">
                {getNetworksListFrom(true)}
                <Icon name={IconHorizontalSwitcher} id="icon--horizontal-switcher" />
                {getNetworksListFrom()}
              </div>
            </div>

            <BaseTextBody secondary>Asset</BaseTextBody>
            {
              !isTokenDataLoading
                ? getExchangeItem
                : (
                  <div className="exchange-loading-container">
                    <Loading size="S" />
                  </div>
                )
            }
          </div>

          {getSwapInfo}

          <div className="bridge-setup__footer">
            <BaseButton
              disabled={isTokenDataLoading || !!sufficientBalanceError || !!valueLimitError}
              onClick={() => setTransactionModalIsShown(true)}
            >
              Swap
            </BaseButton>

            {
              (sufficientBalanceError || valueLimitError) && (
                <div className="bridge-setup__notification">
                  <Alert error size="s">{sufficientBalanceError || valueLimitError}</Alert>
                </div>
              )
            }
          </div>

          {isTransactionModalShown && (
            <TransactionModal
              token={{
                ...selectedToken,
                value: tokenValue,
                price: Object.keys(selectedToken).length > 0 ? checkFloatNaN(selectedToken.usdtPrice * tokenValue, 0, 2) : 0,
                formatedValue: Number(formatDecimal(tokenValue, selectedToken.decimals).split(',').join('')),
              }}
              totalFee={selectedToken.fee.value}
              networkFrom={selectedNetworkFrom.value}
              networkTo={selectedNetworkTo.value}
              externalAddress={externalAddresses[0]}
              onClose={() => setTransactionModalIsShown(false)}
              selectedOwnerWallet={selectedOwnerWallet}
              slippage={bridgeSettings ? bridgeSettings.slippage : '0.1'}
              internalSelectedAddress={internalSelectedAddress}
            />
          )}
        </>
      )
      : (
        <div className="bridge-setup__content--no-data">
          <Icon name={IconInbox} id="icon--inbox" />
          <BaseTextBody size="S">You do not have tokens to be swapped</BaseTextBody>
        </div>
      )
  ), [
    doesUserHaveTokensToSwap,
    getNetworksListFrom,
    isTokenDataLoading,
    getSwapInfo,
    bridgeSettings,
    externalAddresses,
    selectedOwnerWallet,
    selectedNetworkTo,
    selectedNetworkFrom,
    selectedToken,
    getExchangeItem,
    sufficientBalanceError,
    tokenValue,
    isTransactionModalShown,
    internalSelectedAddress,
    valueLimitError,
  ])

  return (
    <div className="bridge-setup">
      {
        !areInternalTokensLoading && Object.keys(selectedToken).length > 0 && !areAllPossibleTokensLoading
          ? getBridgeContent
          : (
            <div className="loading-container">
              <Loading />
            </div>
          )
      }
    </div>
  )
}

BridgeSetup.propTypes = {
  cachedTokenValue: PropTypes.string,
  onSetCachedTokenValue: PropTypes.func,
}

export default BridgeSetup
