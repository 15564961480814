/*eslint-disable*/
import Web3 from 'web3'
import { push } from 'connected-react-router'

import instance from '../../axios-api'

export const SIGN_METAMASK_SUCCESS = 'SIGN_METAMASK_SUCCESS'
export const SIGN_SIGNATURE_SUCCESS = 'SIGN_SIGNATURE_SUCCESS'

export const CREATE_CYBER_WALLET_SUCCESS = 'CREATE_CYBER_WALLET_SUCCESS'
export const CREATE_CYBER_WALLET_FAILURE = 'CREATE_CYBER_WALLET_FAILURE'

export const signMetaMaskSuccess = (signMessage) => ({ type: SIGN_METAMASK_SUCCESS, signMessage })
export const signSignatureSuccess = (signature) => ({ type: SIGN_SIGNATURE_SUCCESS, signature })

export const createCyberWalletSuccess = (cyberWallet) => ({ type: CREATE_CYBER_WALLET_SUCCESS, cyberWallet })
export const createCYberWalletFailure = (errors) => ({ type: CREATE_CYBER_WALLET_FAILURE, errors })

export const getMessageSignMetaMask = () => (dispatch) => instance.get('/api/v2/storage/get_access_message/').then(
  async (response) => {
    dispatch(signMetaMaskSuccess(response.data.message))
    const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545')
    const account = web3.currentProvider.selectedAddress
    await web3.eth.personal.sign(response.data.message, account).then((res) => {
      dispatch(signSignatureSuccess(res))
      if (res) {
        dispatch(push('/manager-wallet'))
      }
    })
  },
)

export const createCyberWallet = (data, userToken) => async (dispatch) => axios.post('/api/v2/monitor/profile/sw/add/', data, {
    headers: {
      'Authorization': `Token ${userToken}`,
    },
  }).then(
    async (response) => {
      if (response.data.errors) {
        if(response.data.errors.pin) {
          dispatch(createCYberWalletFailure(response.data.errors.pin + " min - 8 length, max - 12 length"))
        } else {
          dispatch(createCYberWalletFailure(response.data.errors.name + " min - 4 length, max - 12 length"))
        }
      } else {
        dispatch(createCyberWalletSuccess(response.data.address))
        dispatch(push('/manager-wallet'))
      }
    },
  )
