import React from 'react'

import PropTypes from 'prop-types'
import './toggle.scss'

const Toggle = ({
  name,
  value,
  checked,
  disabled,
  onChange,
  dataTestid,
}) => (
  <label className="toggle" htmlFor={name}>
    <input
      data-testid={dataTestid}
      type="checkbox"
      name={name}
      id={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={() => {
        onChange(!checked)
      }}
    />

    <span />
  </label>
)

Toggle.defaultProps = {
  onChange: () => {},
}

Toggle.propTypes = {
  name: PropTypes.string,
  dataTestid: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Toggle
