import React from 'react'
import PropTypes from 'prop-types'
import './page-dashboard.scss'

export const PageDashboard = ({
  children,
}) => (
  <div className="page-dashboard">
    {children}
  </div>
)

PageDashboard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
}
