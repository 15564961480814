import React, {
  useMemo,
  useState,
  useCallback, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import { BaseModal } from '../../../../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../../components/Icon/icon--close.svg'
import { BaseTextHeading, BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { Status } from '../../../../../../../../components/Status/Status'
import './delete-cyber-wallet-modal-step.scss'
import { Divider } from '../../../../../../../../components/Divider/Divider'
import { Input } from '../../../../../../../../components/Input/Input'
import { Alert } from '../../../../../../../../components/Alert/Alert'
import { Checkbox } from '../../../../../../../../components/Checkbox/Checkbox'
import { extractPrivateKeyApi } from '../../../../../../../../service/api'
import ModalBodyLoading from '../../../../../../../../components/Modal/modal-body-loading/ModalBodyLoading'
import ModalBodySomethingError from '../../../../../../../../components/Modal/modal-body-something-error/ModalBodySomethingError'

const DeleteCyberWalletModalEnterPinStep = ({
  chosenCyberWallet,
  onCloseModal,
  onAfterRemove,
}) => {
  const [valuePin, setValuePin] = useState('')
  const [valueCheckbox, setValueCheckbox] = useState(false)
  const [isErrorPin, setIsErrorPin] = useState(false)
  const [isLoadingRequest, setIsLoadingRequest] = useState(false)
  const [isErrorRequest, setIsErrorRequest] = useState(false)
  const [isSuccessRequest, setIsSuccessRequest] = useState(false)
  const [isDisabledSecondBtn, setIsDisabledSecondBtn] = useState(false)

  const onSendRemoveAccount = useCallback(() => {
    ((async () => {
      setIsLoadingRequest(true)
      try {
        await extractPrivateKeyApi({ pin: valuePin, address: chosenCyberWallet.address })
        setIsLoadingRequest(false)
        setIsSuccessRequest(true)
      } catch (e) {
        setIsLoadingRequest(false)
        if (e.response.status === 401) {
          setIsErrorPin(true)
        } else {
          setIsErrorRequest(true)
        }
      }
    }))()
  }, [valuePin, chosenCyberWallet.address])

  const onSelectCallbackSecondBtn = useCallback(() => {
    if (isSuccessRequest) {
      return () => {
        onAfterRemove(chosenCyberWallet.address)
        onCloseModal()
      }
    }
    if (isErrorRequest) {
      return () => {
        setIsErrorRequest(false)
        setValuePin('')
      }
    }

    return onSendRemoveAccount
  }, [isSuccessRequest, isErrorRequest, onSendRemoveAccount, onAfterRemove, chosenCyberWallet.address, onCloseModal])

  const getModalHeader = () => (
    <BaseButton
      onClick={() => {
        if (isSuccessRequest) {
          onAfterRemove(chosenCyberWallet.address)
        }
        onCloseModal()
      }}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getModalBody = useMemo(() => {
    if (isLoadingRequest) {
      return (
        <ModalBodyLoading
          textHeading="Cyber wallet is being deleted, please wait"
          textBody="You cannot interrupt this operation. When you close the application window, the operation
           will still be performed."
        />
      )
    }
    if (isErrorRequest) {
      return <ModalBodySomethingError />
    }
    if (isSuccessRequest) {
      return (
        <>
          <div className="delete-cyber-wallet-modal__icon-block__default">
            <Status type="completed" />
          </div>

          <BaseTextHeading size="S">
            Cyber Wallet successfully deleted.
          </BaseTextHeading>
        </>
      )
    }

    return (
      <>
        <div className="delete-cyber-wallet-modal__icon-block__default">
          <Status type="delete" />
        </div>

        <BaseTextHeading size="S">
          Enter PIN to delete Cyber Wallet.
          <br />
          {chosenCyberWallet.name}
          {' '}
          balance: $
          {chosenCyberWallet.totalBalance}
          .
        </BaseTextHeading>

        <Divider />

        <Input
          dataTestid="popup--delete-wallet--input-password"
          label={<BaseTextBody size="S">Cyber Wallet PIN</BaseTextBody>}
          value={valuePin}
          onChange={(e) => setValuePin(e.target.value)}
          error={isErrorPin ? 'Wrong Cyber Wallet PIN' : undefined}
          type="password"
          name="delete-cyber-wallet-pin"
        />

        <div className="delete-cyber-wallet-modal-enter-pin--alert">
          <Alert warning>
            We will delete the wallet data from the platform, so the funds on it will no longer be available.
            Please make sure that you have transferred all the funds from it.
          </Alert>
        </div>

        <div className="delete-cyber-wallet-modal-enter-pin--checkbox">
          <Checkbox
            dataTestid="popup--delete-wallet--checkbox"
            name="delete-cyber-wallet-modal-enter-pin--checkbox"
            checked={valueCheckbox}
            value={valueCheckbox}
            onChange={() => setValueCheckbox(!valueCheckbox)}
            textRight={(
              <BaseTextBody>
                I understood everything and I want to delete Cyber Wallet:
                {' '}
                {chosenCyberWallet.name}
                .
              </BaseTextBody>
            )}
          />
        </div>

      </>
    )
  }, [
    isLoadingRequest,
    isErrorRequest,
    isSuccessRequest,
    chosenCyberWallet.name,
    chosenCyberWallet.totalBalance,
    valuePin,
    isErrorPin,
    valueCheckbox,
  ])

  const getModalFooter = useMemo(() => {
    let textSecondBtn = 'Delete forever'

    if (isErrorRequest) {
      textSecondBtn = 'Retry'
    }
    if (isSuccessRequest) {
      textSecondBtn = 'Close'
    }

    return (
      <div className="delete-cyber-wallet__base-modal__footer">
        <div className="delete-cyber-wallet__base-modal__footer__btn">
          {!isSuccessRequest && (
            <div className="delete-cyber-wallet__base-modal__footer__btn__box">
              <BaseButton
                onClick={onCloseModal}
                variant="secondary"
              >
                Cancel
              </BaseButton>
            </div>
          )}

          <div className="delete-cyber-wallet__base-modal__footer__btn__box">
            <BaseButton
              dataTestid="popup--delete-wallet--next-step-btn"
              onClick={onSelectCallbackSecondBtn()}
              disabled={isDisabledSecondBtn}
            >
              {textSecondBtn}
            </BaseButton>
          </div>
        </div>
      </div>
    )
  }, [isErrorRequest, isSuccessRequest, onCloseModal, onSelectCallbackSecondBtn, isDisabledSecondBtn])

  useEffect(() => {
    setIsDisabledSecondBtn(!valuePin || !valueCheckbox || isLoadingRequest)
  }, [isLoadingRequest, valueCheckbox, valuePin])

  return (
    <BaseModal
      title="Delete Cyber Wallet"
      topAction={getModalHeader()}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

DeleteCyberWalletModalEnterPinStep.propTypes = {
  chosenCyberWallet: PropTypes.object,
  onCloseModal: PropTypes.func,
  onAfterRemove: PropTypes.func,
}

export default DeleteCyberWalletModalEnterPinStep
