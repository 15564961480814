import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../components/BaseText/BaseText'

import './asset-list.scss'
import { getParsedTokenBalance } from '../../../../../parser/data'

const AssetList = ({
  items,
  onItemClick,
}) => {
  const getAssetItem = useCallback((item, key) => (
    <div
      className="asset-item"
      key={key}
      onClick={() => {
        onItemClick({ address: item.address, assetName: item.assetName })
      }}
    >
      {item.assetIcon}
      {
        !item.assetIcon && (
          <div className="asset-item-name">
            <BaseTextBody>{item.assetName}</BaseTextBody>
          </div>
        )
      }

      <div className="asset-item-value">
        {item.total
          && <BaseTextBody>{getParsedTokenBalance(item.total)}</BaseTextBody>}

        {item.totalInUsd >= 0
          && <BaseTextBody secondary>{`(~$${item.totalInUsd})`}</BaseTextBody>}
      </div>
    </div>
  ), [onItemClick])

  return (
    <div className="asset-list">
      {items.map((item, key) => getAssetItem(item, key))}
    </div>
  )
}

AssetList.propTypes = {
  items: PropTypes.array,
  onItemClick: PropTypes.func,
}

export default AssetList
