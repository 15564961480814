import React, { useMemo, useState, useEffect } from 'react'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { BaseButton } from '../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../components/Icon/Icon'
import IconSettings from '../../../../../components/Icon/icon--settings.svg'
import IconArrowBack from '../../../../../components/Icon/icon--arrow-back.svg'
import { BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import BridgeSettings from './bridge-settings/BridgeSettings'
import { BaseTabs } from '../../../../../components/BaseTabs/BaseTabs'
import { OrderList } from './order-list/OrderList'
import BridgeSetup from './bridge-setup/BridgeSetup'

import './bridge-body.scss'

const BridgeBody = () => {
  const [bridgeSettings] = useLocalStorage('bridgeSettings')
  const [isSettingsShow, setSettingsShow] = useState(false)
  const [cachedTokenValue, setCachedTokenValue] = useState()

  const tabs = [
    {
      text: 'Setup',
      renderContent: () => (
        <BridgeSetup
          cachedTokenValue={cachedTokenValue}
          onSetCachedTokenValue={(newValue) => setCachedTokenValue(newValue)}
        />
      ),
    },
    {
      text: 'Orders',
      renderContent: () => <OrderList type="orders" data-testid="OrderListOrders" />,
      disabled: true,
    },
    {
      text: 'History',
      renderContent: () => <OrderList type="history" data-testid="OrderListHistory" />,
      disabled: true,
    },
  ]

  useEffect(() => {
    if (!bridgeSettings) {
      writeStorage('bridgeSettings', { slippage: '0.1' })
    }
  }, [bridgeSettings])

  const getBridgeTemplate = useMemo(() => (
    <>
      <div className="bridge-body__header">
        <BaseTextHeading size="S">Bridge</BaseTextHeading>

        <BaseButton
          disabled
          variant="secondary"
          onClick={() => { setSettingsShow(!isSettingsShow) }}
          onlyIcon
          size="s"
          iconLeft={<Icon name={IconSettings} id="icon--settings" />}
          data-testid="BridgeSettingButton"
        />
      </div>

      <BaseTabs tabs={tabs} />
    </>
  ), [isSettingsShow, tabs])

  const getSettingsTemplate = useMemo(() => (
    <>
      <div className="bridge-body__header bridge-body__header--settings">
        <BaseButton
          variant="secondary"
          onClick={() => { setSettingsShow(!isSettingsShow) }}
          onlyIcon
          size="s"
          iconLeft={<Icon name={IconArrowBack} id="icon--arrow-back" />}
        />

        <BaseTextHeading size="S">Settings</BaseTextHeading>
      </div>

      <BridgeSettings />
    </>
  ), [isSettingsShow])

  return (
    <div className="bridge-body">
      {!isSettingsShow ? getBridgeTemplate : getSettingsTemplate}
    </div>
  )
}

export default BridgeBody
