import React, { useCallback, useEffect, useState } from 'react'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector } from 'react-redux'
import chunk from 'lodash/chunk'
import PropTypes from 'prop-types'
import { Icon } from '../../../../../../components/Icon/Icon'
import { BaseHistory } from '../../../../../../components/BaseHistory/BaseHistory'
import IconEthereum from '../../../../../../components/Icon/icon--ethereum.svg'
import { NEW_ETH_ADDRESS, TRANSACTION_METHOD, ZERO_ADDRESS } from '../../../../../../const'
import { croppedString, formatEth } from '../../../../../../utils/helperFunctions'
import { getAllTransactions } from '../../../../../../service/zapperApi'
import { IconCurrency } from '../../../../../../components/IconCurrency/IconCurrency'
import { getImage } from '../../../../../../utils/images'
import { SUPPORTED_CHAINS } from '../../../../../../const/web3'
import IconBsc from '../../../../../../components/Icon/icon--bsc.png'
import IconPolygon from '../../../../../../components/Icon/icon--polygon.png'
import './dashboard-history.scss'
import { useWeb3 } from '../../../../../../context/web3Provider'
import { getTokenData } from '../../../../../../service/api'
import { getSymbolBasedOnNetwork } from '../../../../../../parser/data'

export const DashboardHistory = ({ selectedAddress }) => {
  const web3React = useWeb3()
  const { latestNativeTokenPrice } = useSelector((store) => store.user)
  const [selectedChain] = useLocalStorage('selectedChain')
  const [historyData, setHistoryData] = useState([])
  const [allHistoryData, setAllHistoryData] = useState([])
  const [rowCount, setRowCount] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingTransaction, setLoadingTransaction] = useState(true)
  const [filter, setFilter] = useState('')

  const onChangeSearch = useCallback((newFilter) => {
    setFilter(newFilter)
    setLoadingTransaction(true)
    setCurrentPage(1)

    if (!Array.isArray(allHistoryData) || allHistoryData.length === 0) {
      setRowCount(1)
      setLoadingTransaction(false)
      return
    }
    if (!newFilter) {
      setRowCount(allHistoryData.length)
      setHistoryData(chunk(allHistoryData, 10))
      setLoadingTransaction(false)

      return
    }

    const filterLowerCase = newFilter.toLowerCase()
    const filterRows = allHistoryData.filter((row) => row.filter.address.toLowerCase().includes(filterLowerCase)
        || row.filter.account.toLowerCase().includes(filterLowerCase)
        || row.filter.contract.toLowerCase().includes(filterLowerCase)
        || row.filter.from.toLowerCase().includes(filterLowerCase)
        || row.filter.subTransactions[0].address.toLowerCase().includes(filterLowerCase)
        || row.filter.subTransactions[0].symbol.toLowerCase().includes(filterLowerCase)
        || row.filter.subTransactions[0].address.toLowerCase().includes(filterLowerCase)
        || row.filter.subTransactions[1]?.address?.toLowerCase()?.includes(filterLowerCase) // может не быть
        || row.filter.subTransactions[1]?.symbol?.toLowerCase()?.includes(filterLowerCase) // может не быть
        || row.filter.symbol.toLowerCase().includes(filterLowerCase)
        || row.filter.destination.toLowerCase().includes(filterLowerCase)
        || row.filter.name.toLowerCase().includes(filterLowerCase))

    setRowCount(filterRows.length)
    setHistoryData(chunk(filterRows, 10))
    setLoadingTransaction(false)
  }, [allHistoryData])

  const formatDataTransaction = useCallback(async (transaction) => {
    const getFromTo = ({ from, contract, method }) => {
      let address = '...'

      switch (method.toLowerCase()) {
        case TRANSACTION_METHOD.exchange:
          address = '...'
          break
        case TRANSACTION_METHOD.receive:
          address = from
          break
        case TRANSACTION_METHOD.send:
          address = contract
          break
        default:
          break
      }

      return croppedString(address, 0, 6, -5)
    }
    const getIconNetwork = (network) => {
      switch (network) {
        case SUPPORTED_CHAINS.ethereum:
          return <Icon name={IconEthereum} id="icon--ethereum" />
        case SUPPORTED_CHAINS.bsc:
          return <img className="dashboard-history__icon-network" src={IconBsc} alt="img" />
        case SUPPORTED_CHAINS.polygon:
          return <img className="dashboard-history__icon-network" src={IconPolygon} alt="img" />
        default:
          return <Icon name={IconEthereum} id="icon--ethereum" />
      }
    }

    const iconRight = await getImage(transaction.subTransactions[0].address)
    const checkSumAddressRightToken = web3React.library.utils.toChecksumAddress(transaction.subTransactions[0].address)
    const priceRightToken = [ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(transaction.subTransactions[0].address)
      ? latestNativeTokenPrice
      : (await getTokenData(checkSumAddressRightToken)).usdtPrice

    const result = {
      title: transaction.formatDate,
      data: {
        transactionTypeDetails: {
          currency: getIconNetwork(selectedChain),
          icon: transaction.action,
          name: transaction.name,
          time: transaction.time,
        },
        gasValue: `${formatEth(transaction.gas, 6)} ${getSymbolBasedOnNetwork()}`,
        currencyRight: {
          title: `${formatEth(transaction.amount, 3)} ${transaction.subTransactions[0].symbol}`,
          value: `$${formatEth(priceRightToken * transaction.amount, 3)}`,
          icon: <IconCurrency img={iconRight.src} />,
        },
      },
    }

    if (transaction.name.toLowerCase() !== TRANSACTION_METHOD.exchange) {
      result.data.fromTo = {
        to: transaction.isTo,
        value: getFromTo({ from: transaction.from, contract: transaction.contract, method: transaction.name }),
      }
    } else {
      const checkSumAddressLeftToken = web3React.library.utils.toChecksumAddress(transaction.subTransactions[0].address)
      const priceLeftToken = [ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(transaction.subTransactions[0].address)
        ? latestNativeTokenPrice
        : (await getTokenData(checkSumAddressLeftToken)).usdtPrice
      const iconLeft = await getImage(transaction.subTransactions[1].address)
      result.data.currencyLeft = {
        title: `${formatEth(transaction.subTransactions[1].amount, 3)} ${transaction.subTransactions[1].symbol}`,
        value: `$${formatEth(priceLeftToken * transaction.amount, 3)}`,
        icon: <IconCurrency img={iconLeft.src} />,
      }
    }

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChain, web3React])

  useEffect(() => {
    (async () => {
      try {
        setLoadingTransaction(true)

        if (!web3React.library.utils) {
          return
        }

        const newHistoryData = []
        const allTransactions = (await getAllTransactions(selectedAddress, selectedChain.toLowerCase()))
          .sort((item1, item2) => item2.timeStamp - item1.timeStamp)

        if (Array.isArray(allTransactions) && allTransactions.length > 0) {
          const arrPromise = allTransactions.map(async (transaction) => {
            const resultFormat = await formatDataTransaction(transaction)

            return {
              title: resultFormat.title,
              data: resultFormat.data,
              filter: { ...transaction },
            }
          })

          const result = await Promise.all(arrPromise)
          result.forEach((promise) => newHistoryData.push(promise))
        }

        setHistoryData(chunk(newHistoryData, 10))
        setAllHistoryData(newHistoryData)
        setRowCount(allTransactions.length)
        setLoadingTransaction(false)
      } catch (e) {
        console.error(`Init get transactions error: ${e}`)
        setLoadingTransaction(false)
      }
    })()
  }, [selectedChain, selectedAddress, formatDataTransaction, web3React])

  useEffect(() => {
    setCurrentPage(1)
    setRowCount(1)
    setFilter('')
  }, [selectedAddress])

  return (
    <div className="dashboard-history">
      <BaseHistory
        onChangeSearch={onChangeSearch}
        historyData={historyData[currentPage - 1]}
        rowCount={rowCount}
        isLoading={isLoadingTransaction}
        currentPage={currentPage}
        onChangePage={(newPage) => setCurrentPage(newPage)}
        searchValue={filter}
      />
    </div>
  )
}

DashboardHistory.propTypes = {
  selectedAddress: PropTypes.string,
}
