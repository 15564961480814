import React from 'react'

import PropTypes from 'prop-types'
import './from-to.scss'
import { Icon } from '../../Icon/Icon'
import IconWallet from '../../Icon/icon--wallet.svg'
import { BaseTextBody } from '../../BaseText/BaseText'

export const FromTo = ({
  value,
  to,
}) => (
  <div className="from-to">
    <div className="from-to__body">
      <BaseTextBody
        size="S"
        secondary
      >
        {to ? 'To' : 'From'}
      </BaseTextBody>

      <BaseTextBody>{value}</BaseTextBody>
    </div>

    <div className="from-to__icon">
      <Icon name={IconWallet} id="icon--wallet" />
    </div>
  </div>
)

FromTo.propTypes = {
  value: PropTypes.string,
  to: PropTypes.bool,
}
