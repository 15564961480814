import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import './btn-form.scss'

export const BtnForm = ({
  isDisabled,
  className,
  classNameText,
  iconLeft,
  iconRight,
  text,
  signed,
  onClick,
  testId,
}) => {
  const classNameBtn = useMemo(() => {
    let cls = 'btn-form'

    if (className) {
      cls += ` ${className}`
    }
    if (isDisabled) {
      cls += ' btn-form--disabled'
    }

    return cls
  }, [className, isDisabled])

  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onClick()
    }
  }, [isDisabled, onClick])

  return (
    <div onClick={handleClick} className={classNameBtn}>
      <span className="btn-form__signed">{signed}</span>
      <div className="btn-form__wrapper">
        <div className="btn-form__btn" data-testid={testId}>
          {iconLeft}
          {text && <span className={`btn-form__text ${classNameText || ''}`}>{text}</span>}
          {iconRight}
        </div>
      </div>
    </div>
  )
}

BtnForm.propTypes = {
  className: PropTypes.string,
  classNameText: PropTypes.string,
  text: PropTypes.string,
  signed: PropTypes.string,
  isDisabled: PropTypes.bool,
  iconLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  iconRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  testId: PropTypes.string,
}
BtnForm.defaultProps = {
  isDisabled: false,
  className: '',
  classNameText: '',
  text: '',
  signed: '',
  iconLeft: '',
  iconRight: '',
  onClick: () => {},
  testId: '',
}
