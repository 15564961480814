import { sortArrayByAlphabet } from './data'
import config from '../config'
import { NEW_ETH_ADDRESS } from '../const'

export const parseAllPossibleTokens = ({ data, networkFrom, networkTo }) => {
  const result = []

  Object.keys(data).forEach((key) => {
    const pairObj = data[key]

    if (parseInt(pairObj.srcChainID, 10) === networkFrom && parseInt(pairObj.destChainID, 10) === networkTo) {
      result.push({
        address: pairObj.SrcToken.ContractAddress || NEW_ETH_ADDRESS,
        symbol: pairObj.symbol,
        name: pairObj.name,
        icon: pairObj.logoUrl || `${config.apiURL}/static/media/not-found.svg`,
        depositAddress: pairObj.SrcToken.DepositAddress,
        fee: {
          min: pairObj.SrcToken.MinimumSwapFee,
          max: pairObj.SrcToken.MaximumSwapFee,
          value: pairObj.SrcToken.SwapFeeRate,
        },
        swap: {
          min: pairObj.SrcToken.MinimumSwap,
          max: pairObj.SrcToken.MaximumSwap,
        },
      })
    }
  })

  result.sort((item1, item2) => sortArrayByAlphabet({ item1, item2, prop: 'symbol' }))

  return result
}

export const parseBridgeTransactions = ({
  transactions,
  allPossibleTokens,
  type,
}) => {
  console.log('transactions = ', transactions)
  transactions.sort((item1, item2) => item2.timeStamp - item1.timeStamp)
  const allPossibleSymbols = allPossibleTokens.map((symbol) => symbol.toLowerCase())
  console.log('allPossibleSymbols = ', allPossibleSymbols)
  const filteredOrdersBySymbol = transactions.filter((transaction) => (
    allPossibleSymbols.includes(transaction.subTransactions[0].symbol.toLowerCase())
    && ((!transaction.txSuccessful && type === 'orders') || (transaction.txSuccessful && type === 'history'))
  ))
  console.log('filteredOrdersBySymbol = ', filteredOrdersBySymbol)

  const parseChainName = (chainName) => {
    let chain = 'Ethereum'
    if (chainName === 'binance-smart-chain') {
      chain = 'Binance Smart Chain'
    } else if (chainName === 'polygon-pos') {
      chain = 'Polygon'
    }
    return chain
  }

  return filteredOrdersBySymbol.map((transaction) => {
    const date = new Date(transaction.timeStamp * 1000)
    return {
      token: {
        sent: {
          amount: transaction.subTransactions[0].amount,
          symbol: transaction.subTransactions[0].symbol,
          // decimals: item.src_send_info.token.decimal,
        },
        // received: {
        //   amount: item.dst_received_info.amount,
        //   symbol: item.dst_received_info.token.symbol,
        //   decimals: item.dst_received_info.token.decimal,
        // },
      },
      networks: {
        from: parseChainName(transaction.network),
        // to: item.dst_received_info.chain.name,
      },
      date: `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`,
      // status: {
      //   value: status,
      //   text,
      // },
      type,
    }
  })
}

export const parseAllPossibleTokensForAllNetworksConbinations = (data) => {
  const networks = [1, 56, 137]
  let result = []

  networks.forEach((networkTo) => {
    const tokensForNetworkTo = data[networkTo]
    const possibleNetworksFrom = networks.filter((net) => net !== networkTo)

    possibleNetworksFrom.forEach((networkFrom) => {
      const filteredAllPossibleTokensBasedOnNetworkFrom = Object.keys(tokensForNetworkTo)
        .filter((tokenName) => (
          parseInt(tokensForNetworkTo[tokenName].srcChainID, 10) === networkFrom
        ))

      const mappedData = filteredAllPossibleTokensBasedOnNetworkFrom.map((tokenName) => tokensForNetworkTo[tokenName].symbol)
      result = [...result, ...mappedData]
    })
  })

  return result
}
