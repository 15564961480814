import React from 'react'

import PropTypes from 'prop-types'
import './history-category.scss'
import { HistoryItem } from '../HistoryItem/HistoryItem'
import { BaseTextSubheading } from '../BaseText/BaseText'

export const HistoryCategory = ({
  title,
  items,
}) => (
  <div className="history-category">
    <div className="history-category__header">
      <BaseTextSubheading>{title}</BaseTextSubheading>
    </div>

    <div className="history-category__list">
      {items.map((item, index) => (
        <div key={index} className="history-category__item">
          <HistoryItem {...item} />
        </div>
      ))}
    </div>
  </div>
)

HistoryCategory.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
}
