import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLocalStorage } from '@rehooks/local-storage'
import { BigNumber } from 'bignumber.js'
import { BodyExchangeFilled } from '../components/BodyExchangeFilled/BodyExchangeFilled'
import { PageDashboard } from '../components/PageDashboard/PageDashboard'
import { SidebarRightDashboard } from '../components/SidebarRightDashboard/SidebarRightDashboard'
import { BaseButton } from '../components/BaseButton/BaseButton'
import { Stepper } from '../components/Stepper/Stepper'
import { BaseModal } from '../components/BaseModal/BaseModal'
import { Icon } from '../components/Icon/Icon'
import { IconText } from '../components/IconText/IconText'
import IconClose from '../components/Icon/icon--close.svg'
import { Status } from '../components/Status/Status'
import { BaseTextHeading } from '../components/BaseText/BaseText'
import { AssetBlock } from '../components/AssetBlock/AssetBlock'
import { AssetInfo } from '../components/AssetInfo/AssetInfo'
import { postConfirmOrder, user } from '../store/slice/user'
import { checkFloatNaN, ERC20ToFloat } from '../utils/helperFunctions'
import './styles/exchange-filled.scss'
import { getToken } from '../service/api'
import { ZERO_ADDRESS } from '../const/index'
import { toastSuccess } from '../components/Toast/Toast'
import { getImage } from '../utils/images'
import { Social } from '../components/Social/Social'
import { useWeb3 } from '../context/web3Provider'

export const ExchangeFilled = () => {
  const userInterface = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const web3React = useWeb3()
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')

  const [confirmOrderValue, setConfirmOrderValue] = useState(0)
  const [confirmOrderValueRecieve, setConfirmOrderValueRecieve] = useState(0)
  const [asset0Price, setAsset0Price] = useState(0)
  const [asset1Price, setAsset1Price] = useState(0)
  const [isSignFirst, setIsSignFirst] = useState(false)
  const [isNextStep, setIsNextStep] = useState(false)
  const [firstStepCanceled, setFirstStepCanceled] = useState(false)
  const [secondStepCanceled, setSecondStepCanceled] = useState(false)
  const [isSignSecond, setIsSignSecond] = useState(false)
  const [firstTokenAddress, setFirstTokenAddress] = useState(null)
  const [secondTokenAddress, setSecondTokenAddress] = useState(null)
  const [firstTokenImg, setFirstTokenImg] = useState(null)
  const [secondTokenImg, setSecondTokenImg] = useState(null)
  const [needToaster, setNeedToaster] = useState(true)
  const [isFirstTransactionApprove, setIsFirstTransactionApproveState] = useState(false)

  const closeModal = useCallback(async () => {
    dispatch(user.actions.allTransactionsConfirmed())
  }, [dispatch])
  // TODO: доделать
  // const handleExistImg = useCallback(async (srcImg, setImg) => {
  //   imageExist(srcImg, (exist) => {
  //     if (exist) {
  //       setImg(srcImg)
  //     }
  //     if (!exist) {
  //       setImg(defaultIcon)
  //     }
  //   })
  // }, [])

  useEffect(() => {
    (async () => {
      const srcFirstToken = await getImage(firstTokenAddress)
      const srcSecondToken = await getImage(secondTokenAddress)

      setFirstTokenImg(srcFirstToken.src)
      setSecondTokenImg(srcSecondToken.src)
    })()
  }, [firstTokenAddress, secondTokenAddress])

  useEffect(() => {
    if (userInterface.transactionsToConfirm?.[0]) {
      if (userInterface.transactionsToConfirm?.[0].name === 'Approve') {
        setIsFirstTransactionApproveState(true)
      }
    }
  }, [userInterface.transactionsToConfirm, setIsFirstTransactionApproveState])

  const renderTopAction = useMemo(() => (
    <BaseButton
      onClick={closeModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  ), [closeModal])
  const renderHeaderBottom = useMemo(() => {
    const asset0 = userInterface?.transactionsToConfirm?.[0]?.asset0 || {}
    let firstStepState = 'loading'
    let firstStepText = 'Waiting for sign...'
    let secondStepState = 'active'
    let secondStepText = 'Sign Swap'

    if (isSignFirst) {
      firstStepState = 'completed'
      firstStepText = 'Approve signed'
    }
    if (firstStepCanceled) {
      firstStepState = 'canceled'
      firstStepText = 'Approve canceled'
    }
    if (isSignFirst && isNextStep) {
      secondStepState = 'loading'
    }
    if (isSignSecond) {
      secondStepState = 'completed'
      secondStepText = 'Approve signed'
    }
    if (secondStepCanceled) {
      secondStepState = 'canceled'
      secondStepText = 'Approve canceled'
    }

    const steps = [
      {
        badge: {
          state: firstStepState,
        },
        text: firstStepText,
        active: !isNextStep,
      },
    ]

    if (ZERO_ADDRESS !== asset0.address && isFirstTransactionApprove) {
      steps.push({
        badge: {
          state: secondStepState,
          text: '2',
        },
        text: secondStepText,
        active: isNextStep,
      })
    }

    return (
      <Stepper
        steps={steps}
      />
    )
  }, [
    firstStepCanceled,
    isNextStep,
    isSignFirst,
    isSignSecond,
    secondStepCanceled,
    userInterface.transactionsToConfirm,
    isFirstTransactionApprove,
  ])

  const renderFooter = useMemo(() => {
    const asset0 = userInterface?.transactionsToConfirm?.[0]?.asset0 || {}
    const asset1 = userInterface?.transactionsToConfirm?.[0]?.asset1 || {}
    setFirstTokenAddress(asset0.address)
    setSecondTokenAddress(asset1.address)
    const triggerPrice = userInterface?.transactionsToConfirm?.[0]?.triggers[0]?.trigger_price || 0
    let youRecive = confirmOrderValueRecieve

    if (triggerPrice > 0) {
      youRecive = confirmOrderValue * triggerPrice
    }

    return (
      <div className="exchange-filled__base-modal__footer">
        <div>
          <AssetInfo
            swap={{
              block1: (
                <AssetBlock
                  title="You Pay"
                  bodyName={(
                    <IconText
                      isImg
                      imgSrc={firstTokenImg}
                      text={asset0.symbol}
                    />
                  )}
                  bodyValue={checkFloatNaN(confirmOrderValue, 0, 5)}
                  footerName={asset0.name}
                  footerValue={`$${asset0Price}`}
                />
              ),
              block2: (
                <AssetBlock
                  title="You Recieve"
                  bodyName={(
                    <IconText
                      isImg
                      imgSrc={secondTokenImg}
                      text={asset1.symbol}
                    />
                  )}
                  bodyValue={checkFloatNaN(youRecive, 0, 5)}
                  footerName={asset1.name}
                  footerValue={`$${asset1Price}`}
                />
              ),
            }}
          />
        </div>

        <div className="exchange-filled__base-modal__footer__btn">
          <BaseButton
            onClick={closeModal}
            variant="secondary"
          >
            Cancel
          </BaseButton>
          {ZERO_ADDRESS !== asset0.address && isFirstTransactionApprove && !isNextStep && (
            <BaseButton
              disabled={!isSignFirst}
              onClick={() => setIsNextStep(true)}
            >
              Next step
            </BaseButton>
          )}
        </div>
      </div>
    )
  }, [
    isNextStep,
    isSignFirst,
    asset0Price,
    asset1Price,
    closeModal,
    confirmOrderValue,
    confirmOrderValueRecieve,
    userInterface.transactionsToConfirm,
    firstTokenImg,
    secondTokenImg,
    isFirstTransactionApprove,
  ])
  const renderBodyModal = useMemo(() => {
    let type = 'metamask'
    let text = 'Please, sign approval transaction in Metamask'

    if (isSignFirst && !isNextStep) {
      type = 'completed'
      text = 'Swapping transaction signed'
    }
    if (firstStepCanceled && !isNextStep) {
      type = 'canceled'
      text = 'Approval transaction canceled by you'
    }
    if (isNextStep && !isSignSecond) {
      text = 'Please, sign swapping transaction in Metamask'
    }
    if (isSignSecond && isNextStep) {
      type = 'completed'
      text = 'Swapping transaction signed'
    }
    if (secondStepCanceled && isNextStep) {
      type = 'canceled'
      text = 'Swapping transaction canceled by you'
    }

    return (
      <>
        <Status
          type={type}
        />

        <BaseTextHeading
          size="S"
        >
          {text}
        </BaseTextHeading>
      </>
    )
  }, [firstStepCanceled, isNextStep, isSignFirst, isSignSecond, secondStepCanceled])

  const setInitStateModal = useCallback(() => {
    setIsSignFirst(false)
    setIsSignSecond(false)
    setIsNextStep(false)
    setFirstStepCanceled(false)
    setSecondStepCanceled(false)
    setNeedToaster(true)
  }, [])

  // Вызываем подпись через метамаск
  useEffect(() => {
    (async () => {
      if (userInterface?.needConfirm) {
        try {
          // (Если это первый шаг и нет подписи на нем или второй шаг и подписан первый) и не подписан второй
          if (((!isSignFirst && !isNextStep) || (isNextStep && isSignFirst)) && !isSignSecond) {
            const vaultRequestId = userInterface?.transactionsToConfirm?.[0]?.vault_request_id
            const web3Signature = web3React.library.utils
              && await web3React.library.eth.personal.sign(web3React.library.utils.utf8ToHex(vaultRequestId), web3React.account)

            const message = {
              signed_request_id: web3Signature,
              request_id: vaultRequestId,
            }
            dispatch(postConfirmOrder(message))

            if (!isSignFirst) {
              setIsSignFirst(true)
            } else {
              setIsSignSecond(true)
            }
          }
        } catch (e) {
          if (!isSignFirst && !isNextStep && userInterface?.needConfirm) {
            setFirstStepCanceled(true)
          }
          if (isSignFirst && isNextStep && userInterface?.needConfirm) {
            setSecondStepCanceled(true)
          }

          console.error('sendConfirmOrder', e)
        }
      }
    })()
  }, [
    isNextStep,
    dispatch,
    userInterface.needConfirm,
    userInterface.transactionsToConfirm,
    web3React.account,
    web3React.library,
    isSignFirst,
    isSignSecond,
  ])

  useEffect(() => {
    const asset0 = userInterface?.transactionsToConfirm?.[0]?.asset0 || {}

    if (isSignFirst && asset0.address === ZERO_ADDRESS && needToaster) {
      toastSuccess('Order placed successfully')
      setNeedToaster(false)
    }
    if (isSignSecond && asset0.address !== ZERO_ADDRESS && isNextStep && needToaster) {
      toastSuccess('Order placed successfully')
      setNeedToaster(false)
    }
  }, [isSignFirst, isSignSecond, userInterface, isNextStep, needToaster])

  useEffect(() => {
    (async () => {
      if (userInterface?.transactionsToConfirm?.[0]?.tokenValue) {
        const newValue = ERC20ToFloat(
          new BigNumber(userInterface?.transactionsToConfirm?.[0]?.tokenValue.toLocaleString(
            'fullwide',
            { useGrouping: false },
          )),
          userInterface?.transactionsToConfirm?.[0]?.decimals,
          BigNumber,
        )

        let newAsset0Price = 0
        let newAsset1Price = 0
        try {
          newAsset0Price = (await getToken(userInterface?.transactionsToConfirm?.[0]?.asset0.address)).data.usdt_price
          newAsset1Price = (await getToken(userInterface?.transactionsToConfirm?.[0]?.asset1.address)).data.usdt_price
        } catch (e) {
          console.error(e)
        }
        const newValueRecieve = (newValue * newAsset0Price) / newAsset1Price

        setAsset0Price(checkFloatNaN(newAsset0Price.toFixed(2), 0))
        setAsset1Price(checkFloatNaN(newAsset1Price.toFixed(2), 0))
        setConfirmOrderValueRecieve(newValueRecieve)
        setConfirmOrderValue(newValue)
      }
    })()
  }, [dispatch, userInterface, web3React.account, web3React.library])
  // Возвращаем состояния модалки в исходное состояние
  useEffect(() => {
    setInitStateModal()
  }, [setInitStateModal, userInterface.needConfirm])

  return (
    <div className="dashboard">
      {userInterface?.needConfirm && (
        <BaseModal
          title="Asset Swap"
          topAction={renderTopAction}
          headerBottom={renderHeaderBottom}
          footer={renderFooter}
        >
          {renderBodyModal}
        </BaseModal>
      )}
      <PageDashboard>
        <BodyExchangeFilled />
        {internalSelectedAddress && <SidebarRightDashboard />}
      </PageDashboard>

      <div className="exchange-filled__footer">
        <div className="exchange-filled__footer-body">
          <Social
            twitter
            discord
            telegram
          />
        </div>
      </div>
    </div>
  )
}
