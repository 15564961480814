import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import { Text } from '../Text/Text'
import './icon-currency.scss'

export const IconCurrency = ({
  className,
  icon,
  iconId,
  img,
  imgAlt,
  text,
  textClassName,
}) => (
  <div className={`icon-currency ${className || ''}`}>
    {icon && (
      <div className="icon-currency__img">
        <Icon
          name={icon}
          id={iconId}
        />
      </div>
    )}
    {img && (
      <div className="icon-currency__img">
        <img src={img} alt={imgAlt} />
      </div>
    )}
    <Text
      className={`icon-currency__text ${textClassName || ''}`}
      text={text}
    />
  </div>
)

IconCurrency.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  iconId: PropTypes.string,
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imgAlt: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
}
