import React from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody, BaseTextSubheading } from '../../../../../../../../components/BaseText/BaseText'

import './detail-item.scss'

const DetailItem = ({
  title,
  value,
  subValue,
  isError,
}) => (
  <div className={`detail-item ${isError ? 'error' : ''}`}>
    <div className="detail-item__title">
      <BaseTextBody secondary>{title}</BaseTextBody>
    </div>

    <div className="detail-item__value">
      <BaseTextSubheading>
        {value}
        &nbsp;
        {subValue && (`(${subValue})`)}
      </BaseTextSubheading>
    </div>
  </div>
)

DetailItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  subValue: PropTypes.string,
  isError: PropTypes.bool,
}

export default DetailItem
