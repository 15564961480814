import { createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { getTokenExactData } from '../../utils/helperFunctions'
import { ZERO_ADDRESS } from '../../const'
import { getInitialTokenAndLpPairBasedOnNetwork } from '../../parser/data'

export const tradingView = createSlice({
  name: 'tradingView',
  initialState: {
    reversed: false,
    currentToTrade: ZERO_ADDRESS,
    lpTokenToTrade: undefined,
    availableTokens: [{
      value: 'ETH',
      address: `0x${'0'.repeat(40)}`,
    }],
    ...getInitialTokenAndLpPairBasedOnNetwork(),
    isRedirectedByTopAsset: false,
  },
  reducers: {
    reverseTokens(state) {
      return {
        ...state,
        reversed: !state.reversed,
      }
    },
    setAsset(state, action) {
      // TO DO: remove this temprorary fix for the swap issue
      let newData = {}
      if (typeof action.payload === 'object') {
        newData = {
          currentAsset: action.payload.asset,
          isTopAsset: true,
          isRedirectedByTopAsset: true,
        }
      } else {
        newData = {
          currentAsset: action.payload,
          isTopAsset: false,
        }
      }

      return {
        ...state,
        ...newData,
      }
    },
    setToTrade(state, action) {
      // TO DO: remove this temprorary fix for the swap issue
      let newData = {}
      if (typeof action.payload === 'object') {
        newData = {
          currentToTrade: action.payload.tokenAddress,
          isTopAddress: true,
        }
      } else {
        newData = {
          currentToTrade: action.payload,
          isTopAddress: false,
        }
      }

      return {
        ...state,
        ...newData,
      }
    },
    setInitAvailableToken(state) {
      return {
        ...state,
        availableTokens: [{
          value: 'ETH',
          address: `0x${'0'.repeat(40)}`,
        }],
      }
    },
    addAvailableToken(state, action) {
      let { availableTokens } = state
      availableTokens = JSON.parse(JSON.stringify(availableTokens))

      const isExistToken = find(availableTokens, { address: action.payload.address })
      if (isExistToken) {
        return {
          ...state,
        }
      }

      return {
        ...state,
        availableTokens: [...state.availableTokens, action.payload],
      }
    },
    setLpToken(state, action) {
      return {
        ...state,
        currentAsset: action.payload.token0,
        currentToTrade: action.payload.token1,
        lpTokenToTrade: action.payload.lpToken,
        chartToken: action.payload.lpToken,
      }
    },
    setChartToken(state, action) {
      return {
        ...state,
        chartToken: action.payload,
      }
    },
    toggleTopAsset(state, action) {
      return {
        ...state,
        isTopAsset: action.payload,
      }
    },
    toggleTopAddress(state, action) {
      return {
        ...state,
        isTopAddress: action.payload,
      }
    },
    updateInitialTokenAndLpPair(state, action) {
      return {
        ...state,
        chartToken: action.payload.chartToken,
        currentAsset: action.payload.currentAsset,
      }
    },
  },
})

export const {
  reverseTokens,
  setAsset,
  addAvailableToken,
  setToTrade,
  setLpToken,
  setChartToken,
  setInitAvailableToken,
  toggleTopAsset,
  toggleTopAddress,
  updateInitialTokenAndLpPair,
} = tradingView.actions

/**
 * @param {string} address
 * @param {object} web3React
 * @param {function} dispatch
 * @returns {Promise<null>}
 */
export const addLpTokenToStore = async (address, web3React, dispatch) => {
  // TODO: Move addToken to the better place
  const token0 = await getTokenExactData(address, 'token0', web3React)
  const token1 = await getTokenExactData(address, 'token1', web3React)
  dispatch(setLpToken({ token0, token1, lpToken: address }))
}
