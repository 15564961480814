import React, {
  useEffect, useState, useRef, useMemo, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { unwrapResult } from '@reduxjs/toolkit'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from './sidebar/Sidebar'
import GlobalLoading from '../../components/GlobalLoading/GlobalLoading'
import {
  getDataWallet,
  getInitialDataUser,
  updateConnectCyberWallet,
  getDataExternalWallet,
} from '../../store/slice/user'
import { setInitAvailableToken, updateInitialTokenAndLpPair } from '../../store/slice/tradingView'
import { logout } from '../../utils/helperFunctions'
import useYourPriceTokens from '../../hooks/useYourPriceTokens'
import { TYPES_WALLET, SUPPORTED_CHAINS_IDS } from '../../const'
import SignInModal from './sign-in-modal/SignInModal'
import ConnectModal from './connect-modal/ConnectModal'
import { Header } from './header/Header'
import './main-container.scss'
import SetDefaultNetworkMetamaskModal from './set-default-network-metamask-modal/SetDefaultNetworkMetamaskModal'
import { LIST_WEB3_PROVIDER } from '../../const/web3'
import { enableEthereum, LIST_CONNECTOR_PROVIDER } from '../../service/web3'
import { useWeb3 } from '../../context/web3Provider'
import browserHistory from '../../history'
import { getChainNameBasedOnId, getInitialTokensPairBasedOnNetwork, getInitialTokenAndLpPairBasedOnNetwork } from '../../parser/data'
import { updateSelectedTokensPair } from '../../store/slice/trade'

const MainContainer = ({ children }) => {
  const web3React = useWeb3()
  const dispatch = useDispatch()
  const userState = useSelector((store) => store.user)
  const selectedTokensPairFromState = useSelector((store) => store.trade.selectedTokensPair)

  const [selectedChain] = useLocalStorage('selectedChain')
  const [cancelTokenSource, setCancelTokenSource] = useState()
  const [isFirstGetData, setIsFirstGetData] = useState(true)
  const lastSelectAddress = useRef()
  const [selectedProviderFromLocalStorage] = useLocalStorage('selectedProvider')
  const [walletconnectFromLocalStorage] = useLocalStorage('walletconnect')
  const [isMagicConnectFromLocalStorage] = useLocalStorage('isMagicConnect')
  const [selectedAddress] = useLocalStorage('selectedAddress')
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [accessToken] = useLocalStorage('accessToken')
  const [selectedWallet] = useLocalStorage('selectedWallet')
  const [web3Account] = useLocalStorage('web3Account')
  const [handleSelected] = useLocalStorage('handleSelected')
  const [isLoginMetamask] = useLocalStorage('isLoginMetamask')
  const [isSidebarToggled, setSidebarToggledState] = useState(document.documentElement.clientWidth <= 1024)
  const [isSidebarShownViaMouseOver, setSidebarShownViaMouseOverState] = useState(false)
  const [isSignInModalShown, setSignInModalShownState] = useState(false)
  const [isConnectModalShown, setConnectModalShownState] = useState(false)
  const [wasConnectActionSkipped, setConnectActionSkipState] = useState(false)
  const [isOpenChangeNetworkMetamask, setIsOpenChangeNetworkMetamask] = useState(false)
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false)
  const actualDataPoller = useRef()
  const isPollerRequestPending = useRef()
  const [isProviderSet, setProviderIsSetState] = useState(false)
  const [isPendingProviderSet, setPendingProviderIsSetState] = useState(false)

  useYourPriceTokens()

  useEffect(() => {
    document.getElementById('main').onmousemove = (e) => {
      if (isSidebarToggled) {
        if (!isSidebarShownViaMouseOver) {
          setSidebarShownViaMouseOverState(e.pageX < 25)
        } else {
          setSidebarShownViaMouseOverState(e.pageX < 335 || isOpenSubMenu)
        }
      }
    }
  }, [isSidebarToggled, isSidebarShownViaMouseOver, isOpenSubMenu])

  useEffect(() => {
    if (!web3React.account) {
      setSignInModalShownState(true)
    } else {
      setSignInModalShownState(false)

      if (!userState.loading && !wasConnectActionSkipped) {
        if (userState.internalAddresses.length === 0 && !userState.accessToken) {
          setConnectModalShownState(true)
        } else if (userState.internalAddresses.length > 0 || userState.accessToken) {
          setConnectModalShownState(false)
        }
      }
    }
  }, [
    wasConnectActionSkipped,
    userState,
    web3React,
  ])

  useEffect(() => {
    (async () => {
      if (!isProviderSet) {
        switch (selectedProviderFromLocalStorage) {
          case LIST_WEB3_PROVIDER.walletConnect:
            try {
              if (typeof walletconnectFromLocalStorage === 'object') {
                setPendingProviderIsSetState(true)
                const providerWalletConnect = LIST_CONNECTOR_PROVIDER[LIST_WEB3_PROVIDER.walletConnect]
                await web3React.activate({ provider: providerWalletConnect, onConnect: providerWalletConnect.enable })
                setProviderIsSetState(true)
              }
              setPendingProviderIsSetState(false)
            } catch (e) {
              setPendingProviderIsSetState(false)
              setProviderIsSetState(false)
              console.error(`Connect walletConnect ${e}`)
            }
            break
          case LIST_WEB3_PROVIDER.magic:
            try {
              const providerMagicConnect = LIST_CONNECTOR_PROVIDER[LIST_WEB3_PROVIDER.magic]

              if (isMagicConnectFromLocalStorage) {
                setPendingProviderIsSetState(true)
                await web3React.activate({
                  provider: providerMagicConnect.rpcProvider,
                  onConnect: providerMagicConnect.enable,
                })
                setProviderIsSetState(true)
              } else if (window.location.pathname === '/oauth') {
                setPendingProviderIsSetState(true)
                await web3React.activate({
                  provider: providerMagicConnect.rpcProvider,
                  onConnect: providerMagicConnect.enable,
                })
                setProviderIsSetState(true)
                writeStorage('isMagicConnect', true)
                browserHistory.push('/')
              }
              setPendingProviderIsSetState(false)
            } catch (e) {
              setPendingProviderIsSetState(false)
              setProviderIsSetState(false)
              console.error(`Connect magic ${e}`)
            }
            break
          case LIST_WEB3_PROVIDER.metamask:
            // eslint-disable-next-line no-underscore-dangle,no-case-declarations
            const metamaskIsUnlocked = await window.ethereum?._metamask?.isUnlocked()

            if (metamaskIsUnlocked) {
              try {
                setPendingProviderIsSetState(true)
                await web3React.activate({
                  provider: LIST_CONNECTOR_PROVIDER[LIST_WEB3_PROVIDER.metamask],
                  onConnect: enableEthereum,
                })
                setProviderIsSetState(true)
                setPendingProviderIsSetState(false)
              } catch (e) {
                console.error(`Connect metamask ${e}`)
                setPendingProviderIsSetState(false)
                setProviderIsSetState(false)
              }
            } else {
              localStorage.clear()
              setPendingProviderIsSetState(false)
              setProviderIsSetState(false)
            }
            break
          default:
            setSignInModalShownState(true)
            break
        }
      }
    })()
  }, [
    isProviderSet,
    web3React,
    selectedProviderFromLocalStorage,
    walletconnectFromLocalStorage,
    isMagicConnectFromLocalStorage,
  ])

  // if the account has changed logout
  useEffect(() => {
    if (typeof window !== 'undefined' && window.ethereum) {
      window.ethereum.on('accountsChanged', () => {
        if (typeof isSignInModalShown === 'boolean' && !isSignInModalShown) {
          logout()
          document.location.reload()
        }
      })
    }
  }, [isSignInModalShown])

  const stopPollingForActualData = useCallback(() => {
    if (actualDataPoller.current) {
      clearInterval(actualDataPoller.current)
      actualDataPoller.current = undefined
      isPollerRequestPending.current = false
    }
  }, [])

  const startPollingForActualUserData = useCallback(async (newCancelTokenSource) => {
    if (!actualDataPoller.current) {
      const intervalTime = 10000
      actualDataPoller.current = setInterval(async () => {
        if (!isPollerRequestPending.current) {
          isPollerRequestPending.current = true
          const res = unwrapResult(await dispatch(getDataWallet({ address: selectedAddress, cancelTokenSource: newCancelTokenSource })))
          if (res && res.data) {
            isPollerRequestPending.current = false
          }
        }
      }, intervalTime)
    }
  }, [dispatch, selectedAddress])

  useEffect(() => {
    const connectMetamask = async () => {
      const newCancelTokenSource = axios.CancelToken.source()

      // Если сменился адрес, то отменяем старый запрос
      if (!!(cancelTokenSource) && lastSelectAddress.current !== selectedAddress) {
        cancelTokenSource.cancel()
        stopPollingForActualData()
      }

      if (selectedAddress && lastSelectAddress.current !== selectedAddress) {
        dispatch(getDataWallet({
          address: selectedAddress,
          withLoader: true,
          cancelTokenSource: newCancelTokenSource,
        }))
        dispatch(getInitialDataUser(selectedAddress))
      } else if (isFirstGetData) {
        dispatch(getDataWallet({
          address: web3React.account,
          withLoader: true,
          cancelTokenSource: newCancelTokenSource,
        }))
        dispatch(getInitialDataUser(web3React.account))
        setIsFirstGetData(false)
      }

      lastSelectAddress.current = selectedAddress
      if (lastSelectAddress.current !== selectedAddress || isFirstGetData) {
        setCancelTokenSource(newCancelTokenSource)
      }

      await startPollingForActualUserData(newCancelTokenSource)
    }

    connectMetamask()
  }, [
    web3React,
    dispatch,
    userState,
    cancelTokenSource,
    setCancelTokenSource,
    isFirstGetData,
    selectedAddress,
    accessToken,
    isSignInModalShown,
    startPollingForActualUserData,
    stopPollingForActualData,
    selectedProviderFromLocalStorage,
  ])

  useEffect(() => stopPollingForActualData(), [stopPollingForActualData])

  // Если не выбран кошелек, то по умолчанию ставим кошелек из web3
  useEffect(() => {
    if (!handleSelected && userState.connectCyberWallet && userState.internalAddresses[0]?.address) {
      writeStorage('selectedAddress', userState.internalAddresses[0].address)
      writeStorage('internalSelectedAddress', userState.internalAddresses[0].address)
      writeStorage('selectedWallet', TYPES_WALLET.internal)
      writeStorage('selectedChain', 'Ethereum')
    }
    if (!selectedWallet && web3React.account && isLoginMetamask && !userState.connectCyberWallet) {
      writeStorage('selectedWallet', TYPES_WALLET.external)
      writeStorage('selectedAddress', web3React.account)
      writeStorage('selectedChain', 'Ethereum')
    }
    if (!selectedAddress && isLoginMetamask && !userState.connectCyberWallet) {
      writeStorage('selectedAddress', web3React.account)
      writeStorage('selectedChain', 'Ethereum')
    }
  }, [
    selectedWallet,
    handleSelected,
    web3React.account,
    selectedAddress,
    isLoginMetamask,
    userState.connectCyberWallet,
    userState.internalAddresses,
  ])

  // Задаем состояние коннекта к внутреннему кошельку
  useEffect(() => {
    if (userState.accessToken) {
      dispatch(updateConnectCyberWallet(true))
    }
    if (!userState.accessToken) {
      dispatch(updateConnectCyberWallet(false))
    }
  }, [dispatch, userState.accessToken])

  // Если пользователь закрыл сайт и сменил аккаунт, то сетим новый
  useEffect(() => {
    if (web3React.account && web3Account && web3Account !== web3React.account) {
      writeStorage('web3Account', web3React.account)
      logout()
      document.location.reload()
    }

    if (!web3Account && web3React.account) {
      writeStorage('web3Account', web3React.account)
    }
  }, [web3React.account, web3Account])

  useEffect(() => {
    if (!internalSelectedAddress && userState.internalAddresses.length > 0) {
      writeStorage('internalSelectedAddress', userState.internalAddresses[0].address)
    }
  }, [userState.internalAddresses, internalSelectedAddress])

  useEffect(() => {
    dispatch(setInitAvailableToken())
  }, [selectedAddress, dispatch])

  // Получаем токены external wallet
  useEffect(() => {
    if (web3React.account) {
      dispatch(getDataExternalWallet({ address: web3React.account }))
    }
  }, [dispatch, web3React.account])

  const getBody = useMemo(() => (
    !isSignInModalShown ? (
      <>
        {(!isSidebarToggled || isSidebarShownViaMouseOver)
          && (
            <Sidebar
              onSetIsOpenSubMenu={(value) => setIsOpenSubMenu(value)}
              isSidebarShownViaMouseOver={isSidebarShownViaMouseOver}
              onToggleSidebar={() => {
                const toggleState = !isSidebarToggled
                setSidebarToggledState(toggleState)
                if (!toggleState) {
                  setSidebarShownViaMouseOverState(false)
                }
              }}
            />
          )}
        <div className="Main">
          <div className="header--container" style={{ width: `calc(100% - ${!isSidebarToggled ? '320px' : '0px'})` }}>
            <Header
              placeholder="Search for assets and pairs..."
              isSidebarToggled={isSidebarToggled}
              onToggleSidebar={setSidebarToggledState}
            />
          </div>
          {children}
        </div>

        {isConnectModalShown && (
          <ConnectModal onClose={() => {
            setConnectActionSkipState(true)
            setConnectModalShownState(false)
          }}
          />
        )}

        {isOpenChangeNetworkMetamask && (
          <SetDefaultNetworkMetamaskModal onCloseModal={() => setIsOpenChangeNetworkMetamask(false)} />
        )}
      </>
    ) : (
      <SignInModal onUserSuccessfullyConected={() => setSignInModalShownState(false)} />
    )
  ), [
    isOpenChangeNetworkMetamask,
    isSignInModalShown,
    isSidebarToggled,
    children,
    isSidebarShownViaMouseOver,
    isConnectModalShown,
  ])

  useEffect(() => {
    if (selectedProviderFromLocalStorage === LIST_WEB3_PROVIDER.metamask && web3React.library?.eth) {
      const { chainId } = web3React
      setIsOpenChangeNetworkMetamask(!SUPPORTED_CHAINS_IDS.includes(chainId))

      const currentChainInLibrary = getChainNameBasedOnId(chainId)
      if (currentChainInLibrary !== selectedChain) {
        writeStorage('selectedChain', currentChainInLibrary)

        const { selectedTokensPair } = getInitialTokensPairBasedOnNetwork(currentChainInLibrary)
        if (JSON.stringify(selectedTokensPairFromState) !== JSON.stringify(selectedTokensPair)) {
          dispatch(updateSelectedTokensPair({ pair: selectedTokensPair, isChartExist: true }))
          dispatch(updateInitialTokenAndLpPair(getInitialTokenAndLpPairBasedOnNetwork(currentChainInLibrary)))
        }
      }
    }
  }, [selectedProviderFromLocalStorage, web3React, selectedChain, selectedTokensPairFromState, dispatch])

  return (
    <>
      <div id="main">
        {(userState.loading || (!web3React.active && isPendingProviderSet))
          ? <GlobalLoading />
          : getBody}
      </div>
    </>
  )
}

MainContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
  ]),
}

export default MainContainer
