import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon/Icon'
import DefaultTokenIcon from '../Icon/icon--16-default-token.svg'

import './block-img-pair.scss'

export const BlockImgPair = ({
  firstImg,
  secondImg,
  loadingIconFirst,
  loadingIconSecond,
  isNotFoundFirstIcon = false,
  isNotFoundSecondIcon = false,
}) => {
  const renderImg = useMemo(() => {
    if (loadingIconFirst && loadingIconSecond) {
      return (
        <>
          <span className="BlockImgPair-FirstImg"><Icon name={DefaultTokenIcon} id="icon--16-default-token" /></span>
          <span className="BlockImgPair-SecondImg"><Icon name={DefaultTokenIcon} id="icon--16-default-token" /></span>
        </>
      )
    }

    if (loadingIconFirst && !loadingIconSecond) {
      return (
        <>
          <span className="BlockImgPair-Img"><Icon name={DefaultTokenIcon} id="icon--16-default-token" /></span>
        </>
      )
    }

    if (firstImg && !secondImg) {
      return <img className="BlockImgPair-Img" src={firstImg} alt="img" />
    }
    if (!firstImg && secondImg) {
      return <img className="BlockImgPair-Img" src={secondImg} alt="img" />
    }

    return (
      <>
        <img className="BlockImgPair-FirstImg" src={isNotFoundFirstIcon ? DefaultTokenIcon : firstImg} alt="firstImg" />
        <img className="BlockImgPair-SecondImg" src={isNotFoundSecondIcon ? DefaultTokenIcon : secondImg} alt="secondImg" />
      </>
    )
  }, [isNotFoundFirstIcon, isNotFoundSecondIcon, firstImg, loadingIconFirst, loadingIconSecond, secondImg])

  return (
    <div className="BlockImgPair-Container">
      <div className="BlockImgPair-Inner">
        {renderImg}
      </div>
    </div>
  )
}

BlockImgPair.propTypes = {
  firstImg: PropTypes.string,
  secondImg: PropTypes.string,
  loadingIconFirst: PropTypes.bool,
  loadingIconSecond: PropTypes.bool,
  isNotFoundFirstIcon: PropTypes.bool,
  isNotFoundSecondIcon: PropTypes.bool,
}
