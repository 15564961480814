import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector, useDispatch } from 'react-redux'
import find from 'lodash/find'
import { minABI } from '../../../../../../../utils/contracts'
import { BaseModal } from '../../../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../components/Icon/icon--close.svg'
import { Stepper } from '../../../../../../../components/Stepper/Stepper'
import TransactionCreationStep from './transaction-creation-step/TransactionCreationStep'
import { Status } from '../../../../../../../components/Status/Status'
import { BaseTextHeading, BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { toastCritical, toastSuccess } from '../../../../../../../components/Toast/Toast'
import { accessMessage, apiTransferOrder, checkTransferTransaction } from '../../../../../../../service/api'
import { AssetBlock } from '../../../../../../../components/AssetBlock/AssetBlock'
import { AssetInfo } from '../../../../../../../components/AssetInfo/AssetInfo'
import { IconText } from '../../../../../../../components/IconText/IconText'
import { ExternalLink } from '../../../../../../../components/ExternalLink/ExternalLink'
import IconAccountBalanceWallet from '../../../../../../../components/Icon/icon--account-balance-wallet.svg'
import { IconCurrency } from '../../../../../../../components/IconCurrency/IconCurrency'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../../../../../../../const'
import { floatToERC20 } from '../../../../../../../utils/helperFunctions'
import { postConfirmOrder } from '../../../../../../../store/slice/user'
import Loading from '../../../../../../../components/Loading/Loading'
import { parseOrderStatus, getLinkBasedOnCurrentNetwork } from '../../../../../../../parser/data'

import './transfer-modal.scss'
import { useWeb3 } from '../../../../../../../context/web3Provider'

let transferTransactionPoller

const TransferModal = ({
  chosenCyberWallet,
  onCloseModal,
}) => {
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const dispatch = useDispatch()
  const web3React = useWeb3()
  const userState = useSelector((state) => state.user)
  const [address] = useState(find(userState.internalAddresses, { address: internalSelectedAddress }) || userState.internalAddresses[0])
  const [currentStep, setCurrentStepState] = useState(1)
  const [isFirstStepValid, setFirstStepValidationState] = useState(false)

  const [isSecondStepSignApprovePending, setSecondStepSignApprovePending] = useState(true)
  const [isSecondStepSignApproved, setSecondStepSignApproveState] = useState(false)

  const [isThirdStepSignApprovePending, setThirdStepSignApprovePending] = useState(true)
  const [isThirdStepSignApproved, setThirdStepSignApproveState] = useState(false)

  const [isAdditionalRequestStep, setAdditionalRequestStep] = useState(false)
  const [isTransferTransactionPending, setTransferTransactionPendingState] = useState(false)
  const [isTransferTransactionSuccessfull, setTransferTransactionSuccessfullState] = useState(false)
  const [isTransferTransactionRequestPending, setTransferTransactionRequestPendingState] = useState(false)
  const [transferTransactionErrorMessage, setTransferTransactionErrorMessageState] = useState()

  const [transactionDetails, setTransactionDetails] = useState({
    selectedToken: {
      name: '',
      logoUrl: '',
      symbol: '',
      contractAddress: '',
      contractDecimals: '',
    },
    sumInDollars: 0,
    recipientAddress: '',
    tokenValue: 0,
  })

  // second step sign request
  const requestSecondStepSign = useCallback(async () => {
    if (currentStep === 2) {
      if (web3React.library) {
        try {
          const { data: { message } } = await accessMessage()
          await web3React.library.eth.personal.sign(message, web3React.account)
          setSecondStepSignApproveState(true)
          setSecondStepSignApprovePending(false)
        } catch (e) {
          console.error('error = ', e)
          setSecondStepSignApproveState(false)
          setSecondStepSignApprovePending(false)
        }
      } else {
        toastCritical('Your Metamask should be connected to Ethereum Mainnet')
      }
    }
  }, [currentStep, web3React])

  useEffect(() => requestSecondStepSign(), [requestSecondStepSign])

  // third step transaction sign request
  // eslint-disable-next-line consistent-return
  const getRequestDataForThirdStepSign = useCallback(() => {
    try {
      if (web3React.library?.utils?.isAddress(transactionDetails.recipientAddress)) {
        let requestData = {}
        const fromAddress = chosenCyberWallet || address.address

        if (transactionDetails.selectedToken.contractAddress === ZERO_ADDRESS
          || transactionDetails.selectedToken.contractAddress === NEW_ETH_ADDRESS) {
          requestData = {
            to: web3React.library.utils.toChecksumAddress(transactionDetails.recipientAddress),
            gas: web3React.library.utils.toHex('23000'),
            value: web3React.library.utils.toHex(floatToERC20(18, transactionDetails.tokenValue).toString()),
            data: '0x0',
            assetValue: floatToERC20(18, transactionDetails.tokenValue),
            asset: web3React.library.utils.toChecksumAddress(ZERO_ADDRESS),
          }
        } else {
          const assetValue = floatToERC20(transactionDetails.selectedToken.contractDecimals, transactionDetails.tokenValue)
          const contract = web3React.library.eth && new web3React.library.eth.Contract(minABI, transactionDetails.selectedToken.contract)
          contract.options.address = transactionDetails.selectedToken.contractAddress

          requestData = {
            to: web3React.library.utils.toChecksumAddress(transactionDetails.selectedToken.contractAddress),
            value: '0x0',
            assetValue,
            data: contract
              && transactionDetails.recipientAddress
              && assetValue
              && contract.methods.transfer(transactionDetails.recipientAddress, assetValue).encodeABI(),
            asset: web3React.library.utils.toChecksumAddress(transactionDetails.selectedToken.contractAddress),
            gas: web3React.library.utils.toHex(transactionDetails.requiredGasInGwei),
          }
        }

        return requestData.gas ? {
          external_address: userState.externalAddresses[0],
          transactions: [{
            to: requestData.to,
            from_address: fromAddress,
            gas: requestData.gas,
            data: requestData.data,
            value: requestData.value,
          }],
          asset: requestData.asset,
          asset_value: requestData.assetValue,
          address: transactionDetails.recipientAddress,
        } : undefined
      }
    } catch (e) {
      console.error(e)
    }
  }, [address.address, transactionDetails, web3React, userState.externalAddresses, chosenCyberWallet])

  const stopTransferTransactionStatusPolling = useCallback(() => {
    if (transferTransactionPoller) {
      clearInterval(transferTransactionPoller)
      transferTransactionPoller = undefined
    }
  }, [])

  const startTransferTransactionStatusPolling = useCallback((id) => {
    setTransferTransactionPendingState(true)

    const intervalTimeout = 3000
    transferTransactionPoller = setInterval(async () => {
      if (!isTransferTransactionRequestPending) {
        setTransferTransactionRequestPendingState(true)

        try {
          const details = await checkTransferTransaction(id)
          setTransferTransactionRequestPendingState(false)

          const transactionStatus = parseOrderStatus(details.data.base_order.status)
          if (transactionStatus === 'ORDER_EXECUTING' || transactionStatus === 'ORDER_EXECUTED') {
            toastSuccess('Transaction has finished successfully')
            setTransferTransactionSuccessfullState(true)
            setTransferTransactionPendingState(false)
            stopTransferTransactionStatusPolling()
          } else if (transactionStatus === 'ORDER_FAILED') {
            const errorMessage = details.data.base_order.activate_log
            const parsedErrorMessage = `${errorMessage.charAt(0).toUpperCase()}${errorMessage.slice(1)}`
            toastCritical(parsedErrorMessage)
            setTransferTransactionErrorMessageState(parsedErrorMessage)
            setTransferTransactionSuccessfullState(false)
            setTransferTransactionPendingState(false)
            stopTransferTransactionStatusPolling()
          }
        } catch (e) {
          setTransferTransactionRequestPendingState(false)
          setTransferTransactionPendingState(false)
          stopTransferTransactionStatusPolling()
        }
      }
    }, intervalTimeout)
  }, [isTransferTransactionRequestPending, stopTransferTransactionStatusPolling])

  const requestThirdStepSign = useCallback(() => {
    (async () => {
      const requestData = getRequestDataForThirdStepSign()
      if (requestData) {
        const orderResponse = await apiTransferOrder(requestData)
        try {
          const vaultRequestId = orderResponse.data.transactions[0].vault_request_id
          const web3Signature = await web3React.library.eth.personal.sign(
            web3React.library.utils.utf8ToHex(vaultRequestId),
            web3React.account,
          )
          const message = {
            signed_request_id: web3Signature,
            request_id: vaultRequestId,
          }
          setThirdStepSignApproveState(true)
          setThirdStepSignApprovePending(false)
          dispatch(postConfirmOrder(message))

          setAdditionalRequestStep(true)
          startTransferTransactionStatusPolling(orderResponse.data.id)
        } catch (e) {
          setThirdStepSignApproveState(false)
          setThirdStepSignApprovePending(false)
        }
      }
    })()
  }, [getRequestDataForThirdStepSign, dispatch, web3React, startTransferTransactionStatusPolling])

  useEffect(() => {
    if (currentStep === 3 && !isAdditionalRequestStep) {
      requestThirdStepSign()
    }
  }, [currentStep, isAdditionalRequestStep, requestThirdStepSign])

  const getModalHeader = () => (
    <BaseButton
      onClick={onCloseModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getModalStepper = useMemo(() => {
    const getStepState = (step) => (currentStep > step ? 'completed' : 'active')

    const firstStep = {
      state: getStepState(1),
      text: 'Setup transfer',
      active: currentStep === 1,
    }
    let secondStep = {
      state: getStepState(2),
      text: 'Sign approve',
      active: currentStep === 2,
    }
    let thirdStep = {
      state: getStepState(3),
      text: 'Sign transfer',
      active: currentStep === 3,
    }

    if (currentStep === 2) {
      if (!isSecondStepSignApprovePending) {
        if (isSecondStepSignApproved) {
          secondStep = {
            state: 'completed',
            text: 'Authentication signed',
          }
        } else {
          secondStep = {
            state: 'canceled',
            text: 'Authentication canceled',
          }
        }
      } else {
        secondStep.state = 'loading'
        secondStep.text = 'Waiting for sign...'
      }
    }

    if (currentStep === 3) {
      if (!isAdditionalRequestStep) {
        if (!isThirdStepSignApprovePending) {
          if (isThirdStepSignApproved) {
            thirdStep = {
              state: 'completed',
              text: 'Transfer signed',
            }
          } else {
            thirdStep = {
              state: 'canceled',
              text: 'Transfer canceled',
            }
          }
        } else {
          thirdStep = {
            state: 'loading',
            text: 'Waiting for sign...',
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!isTransferTransactionPending) {
          if (isTransferTransactionSuccessfull) {
            thirdStep = {
              state: 'completed',
              text: 'Success',
            }
          } else {
            thirdStep = {
              state: 'canceled',
              text: 'Failed',
            }
          }
        } else {
          thirdStep = {
            state: 'loading',
            text: 'In progress...',
          }
        }
      }
    }

    const stepsData = [firstStep, secondStep, thirdStep]

    return (
      <Stepper steps={stepsData.map((step, index) => ({
        badge: {
          state: step.state,
          text: `${index + 1}`,
        },
        text: step.text,
        active: step.active,
      }))}
      />
    )
  }, [
    currentStep,
    isSecondStepSignApprovePending,
    isSecondStepSignApproved,
    isThirdStepSignApprovePending,
    isThirdStepSignApproved,
    isAdditionalRequestStep,
    isTransferTransactionPending,
    isTransferTransactionSuccessfull,
  ])

  const getModalBody = useMemo(() => {
    const getFirstStep = () => (
      <TransactionCreationStep
        chosenCyberWallet={chosenCyberWallet}
        onSetTransactionDetails={setTransactionDetails}
        onSetValidation={(isValid) => setFirstStepValidationState(isValid)}
      />
    )

    const getStepTemplate = (bodyData) => (
      <>
        <Status type={bodyData.type} />
        <BaseTextHeading size="S">{bodyData.title}</BaseTextHeading>
        {bodyData.subTitle && (<BaseTextBody size="S">{bodyData.subTitle}</BaseTextBody>)}
      </>
    )

    const getSecondStep = () => {
      let bodyData = {
        type: 'metamask',
        title: 'Please, sign authentication',
        subTitle: 'Open your MetaMask and authentication.',
      }
      if (!isSecondStepSignApprovePending) {
        if (isSecondStepSignApproved) {
          bodyData = {
            type: 'completed',
            title: 'Authentication signed',
          }
        } else {
          bodyData = {
            type: 'canceled',
            title: 'Authentication canceled by you',
          }
        }
      }

      return getStepTemplate(bodyData)
    }

    const getLoadingTemplate = () => (
      <>
        <div className="transfer-modal__icon-block">
          <Loading />
        </div>
        <BaseTextHeading dataTestid="popup--transfer-status" size="S">Transaction in progress...</BaseTextHeading>
      </>
    )

    const getAdditionalRequestStepTemplate = () => {
      let details = {}
      if (isTransferTransactionSuccessfull) {
        details = {
          type: 'completed',
          title: 'Transaction finished successfully',
        }
      } else {
        details = {
          type: 'canceled',
          title: 'Transaction failed',
          subTitle: transferTransactionErrorMessage,
        }
      }

      return (
        <>
          <Status type={details.type} />
          <BaseTextHeading size="S">{details.title}</BaseTextHeading>
          {
            details.subTitle && (
              <BaseTextBody size="S">{details.subTitle}</BaseTextBody>
            )
          }
        </>
      )
    }

    const getThirdStep = () => {
      let bodyData = {}
      let template

      if (!isAdditionalRequestStep) {
        bodyData = {
          type: 'metamask',
          title: 'Please, sign transfering transaction',
          subTitle: 'Open your MetaMask and sign transfering transaction.',
        }
        if (!isThirdStepSignApprovePending) {
          if (isThirdStepSignApproved) {
            bodyData = {
              type: 'completed',
              title: 'Transfering transaction signed',
            }
          } else {
            bodyData = {
              type: 'canceled',
              title: 'Transfering transaction canceled by you',
            }
          }
        }
        template = getStepTemplate(bodyData)
      } else {
        template = getLoadingTemplate()
        if (!isTransferTransactionPending) {
          template = getAdditionalRequestStepTemplate()
        }
      }

      return template
    }

    switch (currentStep) {
      case 1:
        return getFirstStep()
      case 2:
        return getSecondStep()
      case 3:
        return getThirdStep()
      default:
        return getFirstStep()
    }
  }, [
    currentStep,
    isSecondStepSignApprovePending,
    isSecondStepSignApproved,
    isThirdStepSignApprovePending,
    isThirdStepSignApproved,
    chosenCyberWallet,
    isAdditionalRequestStep,
    isTransferTransactionPending,
    isTransferTransactionSuccessfull,
    transferTransactionErrorMessage,
  ])

  const getModalFooter = useMemo(() => {
    const getCloseButton = () => {
      let text = 'Cancel'
      let variant = 'secondary'
      if (
        currentStep === 3
        && !isThirdStepSignApprovePending
        && isThirdStepSignApproved
        && !isTransferTransactionPending
        && isTransferTransactionSuccessfull
      ) {
        text = 'Close'
        variant = ''
      }

      return (
        <BaseButton
          onClick={onCloseModal}
          variant={variant}
        >
          {text}
        </BaseButton>
      )
    }

    const getNextButton = () => {
      const buttonProps = {
        text: 'Next step',
        isDisabled: (currentStep === 1 && !isFirstStepValid) || (currentStep === 2 && !isSecondStepSignApproved),
        callback: () => setCurrentStepState(currentStep + 1),
      }

      if (currentStep === 2 && !isSecondStepSignApprovePending) {
        buttonProps.isDisabled = false
        if (isSecondStepSignApproved) {
          buttonProps.callback = () => setCurrentStepState(3)
        } else {
          buttonProps.text = 'Request sign again'
          buttonProps.callback = () => {
            setSecondStepSignApprovePending(true)
            requestSecondStepSign()
          }
        }
      }

      if (currentStep === 3 && !isThirdStepSignApprovePending && !isTransferTransactionPending) {
        buttonProps.isDisabled = false
        if (isThirdStepSignApproved && isTransferTransactionSuccessfull) {
          buttonProps.callback = () => setCurrentStepState(3)
        } else {
          buttonProps.text = !isThirdStepSignApproved ? 'Request sign again' : 'Send transaction again'
          buttonProps.callback = () => {
            setThirdStepSignApproveState(false)
            setThirdStepSignApprovePending(true)
            setAdditionalRequestStep(false)
            setTransferTransactionPendingState(false)
            setTransferTransactionSuccessfullState(false)
            setTransferTransactionRequestPendingState(false)
            setTransferTransactionErrorMessageState()
            requestThirdStepSign()
          }
        }
      }

      return (
        currentStep !== 3
        || (currentStep === 3 && !isThirdStepSignApprovePending && !isThirdStepSignApproved)
        || (currentStep === 3 && isThirdStepSignApproved && !isTransferTransactionPending && !isTransferTransactionSuccessfull)
      ) && (
        <BaseButton
          dataTestid="popup--transfer-next-step"
          disabled={buttonProps.isDisabled}
          onClick={buttonProps.callback}
        >
          {buttonProps.text}
        </BaseButton>
      )
    }

    const getTransactionDetails = () => {
      const cutAddress = (addr) => `${(addr || '').substr(0, 6)}...${(addr || '').substr(-5)}`

      return currentStep !== 1 && (
        <AssetInfo
          transfer={{
            block1: <AssetBlock
              title="From"
              bodyName={(
                <IconText
                  icon={IconAccountBalanceWallet}
                  id="icon--account-balance-wallet"
                  text="You"
                  className="icon-text--sm"
                />
              )}
              footerLink={(
                <ExternalLink
                  text={cutAddress(chosenCyberWallet || internalSelectedAddress)}
                  link={`https://${getLinkBasedOnCurrentNetwork()}/address/${chosenCyberWallet || internalSelectedAddress}`}
                />
              )}
            />,
            block2: <AssetBlock
              title="For Approval"
              bodyName={(
                <IconCurrency
                  img={transactionDetails.selectedToken.logoUrl}
                  imgAlt={transactionDetails.selectedToken.symbol}
                  text={transactionDetails.selectedToken.symbol}
                  textClassName="no-bold"
                />
              )}
              bodyValue={transactionDetails.tokenValue}
              footerName={transactionDetails.selectedToken.name}
              footerValue={transactionDetails.sumInDollars}
            />,
            block3: <AssetBlock
              title="To"
              bodyName={(
                <IconText
                  icon={IconAccountBalanceWallet}
                  id="icon--account-balance-wallet"
                  text="Recepient"
                  className="icon-text--sm"
                />
              )}
              footerLink={(
                <ExternalLink
                  text={cutAddress(transactionDetails.recipientAddress)}
                  link={`https://${getLinkBasedOnCurrentNetwork()}/address/${transactionDetails.recipientAddress}`}
                />
              )}
            />,
          }}
        />
      )
    }

    return (
      <div className="create-cyber-wallet__base-modal__footer">
        {getTransactionDetails()}
        <div className="create-cyber-wallet__base-modal__footer__btn">
          {getCloseButton()}
          {getNextButton()}
        </div>
      </div>
    )
  }, [
    currentStep,
    onCloseModal,
    isFirstStepValid,
    isSecondStepSignApproved,
    isSecondStepSignApprovePending,
    requestSecondStepSign,
    isThirdStepSignApprovePending,
    isThirdStepSignApproved,
    internalSelectedAddress,
    transactionDetails,
    requestThirdStepSign,
    chosenCyberWallet,
    isTransferTransactionPending,
    isTransferTransactionSuccessfull,
  ])

  return (
    <BaseModal
      title="Transfer"
      topAction={getModalHeader()}
      headerBottom={getModalStepper}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

TransferModal.propTypes = {
  chosenCyberWallet: PropTypes.string,
  onCloseModal: PropTypes.func,
}

export default TransferModal
