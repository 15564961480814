import React from 'react'
import PropTypes from 'prop-types'
import './sidebar-menu.scss'

const SidebarMenu = ({
  items,
}) => (
  <nav className="sidebar-menu">
    <ul className="sidebar-menu-list">
      {items.map((item, key) => (
        <li className="sidebar-menu-item" key={key}>
          {item}
        </li>
      ))}
    </ul>
  </nav>
)

SidebarMenu.propTypes = {
  items: PropTypes.array,
}

export default SidebarMenu
