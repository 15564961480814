import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import ImgLogo from './logo.svg'
import ImgLogoBase from './logo--base.svg'
import ImgLogoBeta from './logo--beta.svg'

import './logo.scss'

export const Logo = ({
  className,
}) => {
  const history = useHistory()
  return (
    <span className={`logo ${className || ''}`} onClick={() => history.push('/')}>
      <img src={ImgLogo} alt="CYBERFI/BETA" />
    </span>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
}

export const LogoBase = ({
  className,
}) => {
  const history = useHistory()
  return (
    <span className={`logo ${className || ''}`} onClick={() => history.push('/')}>
      <img src={ImgLogoBase} alt="CYBERFI" />
    </span>
  )
}

LogoBase.propTypes = {
  className: PropTypes.string,
}

export const LogoBeta = () => {
  const history = useHistory()
  return (
    <span className="logo logo--beta" onClick={() => history.push('/')}>
      <img src={ImgLogoBeta} alt="CYBERFI Beta" />
    </span>
  )
}
