import React from 'react'

import PropTypes, { string } from 'prop-types'
import './asset.scss'
import { BaseTextBody } from '../BaseText/BaseText'

const Asset = ({
  icon,
  assetName,
}) => (
  <div className="asset">
    {icon}

    <div className="asset-name">
      <BaseTextBody>{assetName}</BaseTextBody>
    </div>
  </div>
)

Asset.propTypes = {
  icon: PropTypes.element,
  assetName: string,
}

export default Asset
