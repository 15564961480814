import { createSlice } from '@reduxjs/toolkit'
import { getInitialTokensPairBasedOnNetwork } from '../../parser/data'

export const trade = createSlice({
  name: 'trade',
  initialState: {
    ...getInitialTokensPairBasedOnNetwork(),
    hasOrderJustBeenCreated: false,
  },
  reducers: {
    updateSelectedTokensPair(state, action) {
      return {
        ...state,
        selectedTokensPair: action.payload.pair,
        pairAddress: action.payload.pairAddress,
        isChartExist: action.payload.isChartExist,
      }
    },

    swapSelectedTokens(state, action) {
      const cachedFirstToken = { ...state.selectedTokensPair.token1 }
      return {
        ...state,
        selectedTokensPair: {
          token1: { ...state.selectedTokensPair.token2 },
          token2: cachedFirstToken,
        },
        isChartExist: action.payload,
      }
    },

    setOrderCreationBooleanValue(state, action) {
      return {
        ...state,
        hasOrderJustBeenCreated: action.payload,
      }
    },
  },
})

export const {
  updateSelectedTokensPair,
  swapSelectedTokens,
  setOrderCreationBooleanValue,
} = trade.actions
