import React from 'react'
import PropTypes from 'prop-types'
import './checkbox.scss'

export const Checkbox = ({
  className,
  textRight,
  name,
  value,
  checked,
  disabled,
  onChange,
  dataTestid,
}) => (
  <label htmlFor={name} className={`checkbox ${className || ''}`}>
    <input
      data-testid={dataTestid}
      className="checkbox__checkbox"
      type="checkbox"
      name={name}
      id={name}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />

    <span className="checkbox--checked" />

    {textRight && (
    <div className="text text--12 checkbox__text-right">
      {textRight}
    </div>
    )}
  </label>
)

Checkbox.propTypes = {
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  textRight: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
Checkbox.defaultProps = {
  className: '',
  textRight: '',
  name: '',
  value: false,
  checked: false,
  disabled: false,
  onChange: () => {},
}
