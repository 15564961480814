import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Icon } from '../Icon/Icon'
import './menu-item.scss'
import { BaseTextBody } from '../BaseText/BaseText'
import Toggle from '../Toggle/Toggle'
import Loading from '../Loading/Loading'

export const MenuItem = ({
  iconLeft,
  text,
  action,
  iconRight,
  onClick,
  isCopyToClipBoard,
  copyToClipBoardText,
  disabled,
  isToggle,
  isUnderLine,
  isToggleChecked,
  onChangeToggle,
  isStopPropagation,
  isLoading,
  dataTestid,
}) => {
  const item = useMemo(() => (
    <div
      data-testid={!isToggle ? dataTestid : ''}
      className={`menu-item ${disabled ? 'disabled' : ''}`}
      onClick={(event) => {
        if (!disabled && onClick) {
          isStopPropagation && event.stopPropagation()
          onClick()
        }
      }}
    >
      <div className="menu-item__body">
        {iconLeft
            && (
            <div className="menu-item__icon-left">
              <Icon
                name={iconLeft.file}
                id={iconLeft.name}
                mod={iconLeft.mode}
              />
            </div>
            )}

        <div className="menu-item__text">
          <BaseTextBody isUnderLine={isUnderLine}>
            {text}
          </BaseTextBody>
        </div>

        <div className="menu-item__right">
          {action
              && (
              <div className="menu-item__action">
                {action}
              </div>
              )}

          {iconRight
              && (
              <div className="menu-item__icon-right">
                <Icon
                  name={iconRight.file}
                  id={iconRight.name}
                  mod={iconRight.mode}
                />
              </div>
              )}

          {isToggle && !isLoading && <Toggle dataTestid={dataTestid} checked={isToggleChecked} onChange={onChangeToggle} />}

          {isLoading && <div className="menu-item__loader-container"><Loading size="S" /></div> }
        </div>
      </div>
    </div>
  ), [
    onChangeToggle,
    isToggleChecked,
    action,
    iconLeft,
    iconRight,
    text,
    onClick,
    disabled,
    isToggle,
    isUnderLine,
    isStopPropagation,
    isLoading,
    dataTestid,
  ])

  return !isCopyToClipBoard ? item : (
    // TODO: оставляем этот компонент, потому что понадобится добавить тултип
    <CopyToClipboard text={copyToClipBoardText}>
      {item}
    </CopyToClipboard>
  )
}

MenuItem.defaultProps = {
  onClick: () => {},
  onChangeToggle: () => {},
}

MenuItem.propTypes = {
  iconLeft: PropTypes.object,
  text: PropTypes.string,
  dataTestid: PropTypes.string,
  action: PropTypes.element,
  iconRight: PropTypes.object,
  onClick: PropTypes.func,
  onChangeToggle: PropTypes.func,
  isCopyToClipBoard: PropTypes.bool,
  copyToClipBoardText: PropTypes.string,
  disabled: PropTypes.bool,
  isToggle: PropTypes.bool,
  isUnderLine: PropTypes.bool,
  isToggleChecked: PropTypes.bool,
  isStopPropagation: PropTypes.bool,
  isLoading: PropTypes.bool,
}
