import React from 'react'
import { BaseButton } from '../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../components/Icon/Icon'
import IconEthereum from '../../../../../components/Icon/icon--ethereum.svg'
import IocnCurrencyLogo from '../../../../../components/Icon/icon--img-logo.svg'

export const historyData = [
  {
    title: 'August 31, 2021',
    data: {
      transactionTypeDetails: {
        currency: <Icon name={IconEthereum} id="icon--ethereum" />,
        icon: 'up',
        name: 'Send',
        time: '00:00 DD',
      },
      gasValue: '0.999999 ETH',
      currencyLeft: {
        title: '~999,999.999 ASSET...',
        value: '$999,999.999',
        icon: <Icon name={IocnCurrencyLogo} id="icon--img-logo" />,
      },
      currencyRight: {
        title: '~999,999.999 ASSET...',
        value: '$999,999.999',
        icon: <Icon name={IocnCurrencyLogo} id="icon--img-logo" />,
      },
      gasCostBackButton: <BaseButton onClick={() => { }}>Get your gas costs back</BaseButton>,
    },
  },
  {
    title: 'August 27, 2021',
    data: {
      transactionTypeDetails: {
        currency: <Icon name={IconEthereum} id="icon--ethereum" />,
        icon: 'up',
        name: 'Send',
        time: '00:00 DD',
      },
      gasValue: '0.999999 ETH',
      currencyLeft: {
        title: '~999,999.999 ASSET...',
        value: '$999,999.999',
        icon: <Icon name={IocnCurrencyLogo} id="icon--img-logo" />,
      },
      currencyRight: {
        title: '~999,999.999 ASSET...',
        value: '$999,999.999',
        icon: <Icon name={IocnCurrencyLogo} id="icon--img-logo" />,
      },
      gasCostBackButton: <BaseButton onClick={() => { }}>Get your gas costs back</BaseButton>,
    },
  },
]
