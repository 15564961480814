import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ERC20ToFloat, weiToEth, formatEth } from '../utils/helperFunctions'
import { addPriceToken, setInitState, allTokensLoaded } from '../store/slice/priceToken'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../const/index'
import { useWeb3Bn } from './web3'
import { getNativeTokenDataBasedOnNetwork } from '../parser/data'
import { useWeb3 } from '../context/web3Provider'

const useYourPriceTokens = () => {
  const Web3BN = useWeb3Bn()
  const userState = useSelector((store) => store.user)
  const web3React = useWeb3()
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      dispatch(setInitState())

      if (userState.tokens && Array.isArray([userState.tokens]) && Web3BN) {
        userState.tokens.forEach((token) => {
          if (token.contract_address.toLocaleLowerCase() !== NEW_ETH_ADDRESS) {
            const decimals = token.contract_decimals ? token.contract_decimals : token.decimals
            const erc20 = ERC20ToFloat(new Web3BN(token.balance.toString()), decimals, Web3BN)
            const symbol = token.symbol ? token.symbol : token.contract_ticker_symbol

            addPriceToken({
              address: token.address,
              symbol,
              name: token.name,
              totalPrice: formatEth(erc20 * token.price),
              balance: token.balance,
              decimals,
              dispatch,
              tradeSymbol: token.trade_symbol,
              logoUrl: token.logo_url,
            })
          } else if (web3React && web3React.library && token.balance !== '0') {
            const toEth = weiToEth(web3React.library.utils.fromWei, token.balance.toString())
            const { tokenSymbol } = getNativeTokenDataBasedOnNetwork()

            addPriceToken({
              address: ZERO_ADDRESS,
              symbol: tokenSymbol,
              name: tokenSymbol,
              totalPrice: formatEth(parseFloat(toEth) * parseFloat(userState.latestNativeTokenPrice)),
              balance: token.balance.toString(),
              decimals: 18,
              dispatch,
              tradeSymbol: tokenSymbol,
            })
          }
        })
      }

      if (!userState.areInternalTokensLoading) {
        dispatch(allTokensLoaded())
      }
    })()
  }, [
    Web3BN,
    dispatch,
    userState.balance,
    userState.tokens,
    web3React,
    userState.areInternalTokensLoading,
    userState.latestNativeTokenPrice,
  ])
}

export default useYourPriceTokens
