import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { croppedString } from '../../utils/helperFunctions'

import './external-link.scss'

export const ExternalLink = ({
  text,
  link,
  hideLink,
  icon,
}) => {
  const renderLink = useMemo(() => {
    if (hideLink) {
      return (
        <div className="ExternalLink-Link">
          <span className="ExternalLink-Text">
            {text && text.length > 15 ? croppedString(text, 0, 6, -5) : text}
          </span>
        </div>
      )
    }

    return (
      <a className="ExternalLink-Link" href={link} target="_blank" rel="noreferrer">
        <span className="ExternalLink-Text">
          {text && text.length > 15 ? croppedString(text, 0, 6, -5) : text}
        </span>

        {icon || (
          <OpenInNewIcon style={{ fontSize: '13.5px', color: '#FFFFFF7A' }} fontSize="small" />
        )}
      </a>
    )
  }, [hideLink, link, text, icon])

  return (
    <div className="ExternalLink-Container">
      <div className="ExternalLink-Inner">
        {renderLink}
      </div>
    </div>
  )
}
ExternalLink.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  hideLink: PropTypes.bool,
  icon: PropTypes.element,
}
