import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TabsHeader } from '../TabsHeader/TabsHeader'

import './tabs.scss'

export function Tabs({
  className,
  tabs,
  isShowTabs,
}) {
  const [activeIndex, setActiveIndex] = useState(0)

  const handleTabClick = (index) => {
    setActiveIndex(index)
  }

  return (
    <div className={`tabs ${className || ''}`}>
      {isShowTabs && (
        <div className="tabs__header">
          <TabsHeader
            activeIndex={activeIndex}
            tabs={tabs}
            onTabClick={handleTabClick}
          />
        </div>
      )}

      <div className="tabs__content" data-testid="wip-balance-block-tabs-content">
        {tabs[activeIndex].renderContent()}
      </div>
    </div>
  )
}

Tabs.defaultProps = {
  isShowTabs: true,
}

Tabs.propTypes = {
  className: PropTypes.string,
  isShowTabs: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    renderContent: PropTypes.func,
    text: PropTypes.string,
  })),
}
