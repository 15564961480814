import React, { useMemo } from 'react'

import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocalStorage } from '@rehooks/local-storage'
import { getDataWallet } from '../../store/slice/user'
import { Tabs } from '../Tabs/Tabs'
import { Btn } from '../Btn/Btn'
import { Icon } from '../Icon/Icon'
import { ExchangeCreateOrderFormNew } from '../ExchangeCreateOrderFormNew/ExchangeCreateOrderFormNew'
import IconRefresh from '../Icon/icon--refresh.svg'

import './exchange-create-order-new.scss'
import { getFeatureFlag } from '../../utils/feature-flags'
import { useWeb3 } from '../../context/web3Provider'

export const ExchangeCreateOrderNew = ({
  className,
  isSettingsContainer,
  isShowTabs,
}) => {
  const web3React = useWeb3()
  const dispatch = useDispatch()
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress') || ''

  const isNewDesign = useMemo(() => getFeatureFlag('isNewDesign'), [])

  const TabsArgs = useMemo(() => {
    const tabs = {
      tabs: [
        {
          text: 'market',
          testId: 'tarde-page-market-btn',
          renderContent: () => <div><ExchangeCreateOrderFormNew settingsContainer={isSettingsContainer} market /></div>,
        },
        {
          text: 'limit',
          testId: 'tarde-page-limit-btn',
          renderContent: () => <div><ExchangeCreateOrderFormNew settingsContainer={isSettingsContainer} trigger /></div>,
        },
      ],
    }

    if (isNewDesign) {
      tabs.tabs.push({
        text: 'LP',
        testId: 'tarde-page-lp-btn',
        renderContent: () => <div><ExchangeCreateOrderFormNew settingsContainer={isSettingsContainer} LP /></div>,
      },
      {
        text: 'MC',
        testId: 'tarde-page-mc-btn',
        renderContent: () => <div><ExchangeCreateOrderFormNew settingsContainer={isSettingsContainer} MC /></div>,
      },
      {
        text: 'DCA',
        testId: 'tarde-page-oca-btn',
        renderContent: () => <div><ExchangeCreateOrderFormNew settingsContainer={isSettingsContainer} DCA /></div>,
      })
    }

    return tabs
  }, [isNewDesign, isSettingsContainer])

  const refreshTokens = () => {
    // TODO: Selected internal account
    // eslint-disable-next-line no-unused-expressions
    web3React.account && dispatch(getDataWallet({
      address: internalSelectedAddress,
      withLoader: true,
    }))
  }

  return (
    <div className={`exchange-create-order${className || ''}`}>
      <Tabs
        className="tabs--big tabs--exchange"
        isShowTabs={isShowTabs}
        {...TabsArgs}
      />
      <div className="exchange-create-order__actions">
        <div className="exchange-create-order__action">
          <Btn
            mod="btn--icon"
            iconLeft={(
              <Icon
                name={IconRefresh}
                id="icon--refresh"
              />
            )}
            onClick={refreshTokens}
          />
        </div>
      </div>
    </div>
  )
}

ExchangeCreateOrderNew.defaultProps = {
  isShowTabs: true,
}

ExchangeCreateOrderNew.propTypes = {
  className: PropTypes.string,
  isSettingsContainer: PropTypes.bool,
  isShowTabs: PropTypes.bool,
}
