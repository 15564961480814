import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../Loading/Loading'
import { ITEM_DROP_DOWN_TOKEN, ItemDropDown } from '../ItemDropDown/ItemDropDown'
import { BaseTextBody } from '../BaseText/BaseText'

import './base-drop-down.scss'

export const BaseDropDown = ({
  infinityScrollHasMore,
  funcInfinityScroll,
  items,
  isOpen,
  isLoading,
  isCountItems,
  countItems,
  dataTestid,
  onClick = () => {},
}) => {
  const classNameListContainer = useMemo(() => {
    let cls = 'BaseDropDown-Container'

    if (isOpen) {
      cls += ' BaseDropDown-Container__open'
    }
    if (isOpen && (isLoading || (!Array.isArray(items) || items.length === 0))) {
      cls += ' BaseDropDown-Container-Loading'
    }

    return cls
  }, [isOpen, isLoading, items])

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Loading />
    }
    if (!isLoading && (!Array.isArray(items) || items.length === 0)) {
      return (
        <div className="BaseDropDown-Container__noResult">
          <span>No result.</span>
        </div>
      )
    }

    return (
      <div className="BaseDropDown-Container-List">
        {isCountItems && (
          <div className="BaseDropDown-Container-CountItems">
            <BaseTextBody
              size="S"
              secondary
            >
              #
              {countItems}
              {' '}
              results found
            </BaseTextBody>
          </div>
        )}

        <InfiniteScroll
          dataLength={items.length}
          next={funcInfinityScroll}
          hasMore={infinityScrollHasMore}
          height={280}
          loader={<div className="BaseDropDown-Container-List__loading"><Loading /></div>}
        >
          {items.map((item, key) => (
            <div
              onClick={() => onClick(item)}
              key={key}
              className="BaseDropDown-Container-List__item"
            >
              <ItemDropDown
                link={item.link}
                address={item.address}
                token0={item.token0}
                token1={item.token1}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    )
  }, [isLoading, items, isCountItems, countItems, funcInfinityScroll, infinityScrollHasMore, onClick])

  return (
    <div data-testid={dataTestid} className={classNameListContainer}>
      {isOpen && renderContent()}
    </div>
  )
}

export const ITEMS_PROPTYPES = PropTypes.shape({
  address: PropTypes.string, // Address пары
  link: PropTypes.string,
  token0: ITEM_DROP_DOWN_TOKEN,
  token1: ITEM_DROP_DOWN_TOKEN,
})

BaseDropDown.propTypes = {
  dataTestid: PropTypes.string,
  infinityScrollHasMore: PropTypes.bool,
  funcInfinityScroll: PropTypes.func,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCountItems: PropTypes.bool,
  onClick: PropTypes.func,
  countItems: PropTypes.node,
  items: PropTypes.arrayOf(ITEMS_PROPTYPES),
}

BaseDropDown.defaultProps = {
  isOpen: false,
  isLoading: false,
  items: [],
}
