import React, {
  useEffect, useMemo, useState,
} from 'react'
import './dashboard.scss'
import { useSelector } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import { BaseTabs } from '../../../../../components/BaseTabs/BaseTabs'
import { Social } from '../../../../../components/Social/Social'
import { BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import { Chip } from '../../../../../components/Chip/Chip'
import { croppedString } from '../../../../../utils/helperFunctions'
import { DashboardBody } from './dashboard-body/DashboardBody'
import { getIconSelectedProvider } from '../../../../../utils/images'
import IocnCurrencyLogo from '../../../../../components/Icon/icon--img-logo.svg'
import { Icon } from '../../../../../components/Icon/Icon'
import { useWeb3 } from '../../../../../context/web3Provider'
import { DashboardHistory } from './dashboard-history/DashboardHistory'

export const Dashboard = () => {
  const web3React = useWeb3()
  const [selectedWallet, setSelectedWallet] = useState({ owner: false, internal: true })
  const userState = useSelector((store) => store.user)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [selectedWalletStorage] = useLocalStorage('selectedWallet')

  const tabsArgs = useMemo(() => ({
    tabs: [
      {
        text: 'Wallet overview',
        renderContent: () => (
          <DashboardBody
            selectedWallet={selectedWallet}
            selectedAddress={selectedWallet.owner ? web3React.account : internalSelectedAddress}
            areTokensLoading={
              selectedWallet.owner
                ? userState.areExternalTokensLoading
                : userState.areInternalTokensLoading
            }
          />
        ),
      },
      {
        text: 'History',
        renderContent: () => (
          <DashboardHistory selectedAddress={selectedWallet.owner ? web3React.account : internalSelectedAddress} />
        ),
      },
    ],
  }), [
    userState.areInternalTokensLoading,
    userState.areExternalTokensLoading,
    web3React.account,
    internalSelectedAddress,
    selectedWallet,
  ])

  useEffect(() => {
    if (selectedWalletStorage === 'internal') {
      setSelectedWallet({ owner: false, internal: true })
    } else {
      setSelectedWallet({ owner: true, internal: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <BaseTextHeading
          size="L"
        >
          Dashboard
        </BaseTextHeading>

        {web3React.account && (
          <Chip
            dataTestid="dashboard-chip-web3"
            onClick={() => setSelectedWallet({ owner: true, internal: false })}
            icon={<img className="dashboard-wallet-icon" src={getIconSelectedProvider()} alt="Metamask" />}
            iconPosition="left"
            size="L"
            outline
            isSelected={selectedWallet.owner}
          >
            {croppedString(web3React.account, 0, 7, -7)}
          </Chip>
        )}
        {internalSelectedAddress && userState.internalAddresses.length > 0 && (
          <Chip
            dataTestid="dashboard-chip-cyberwallet"
            onClick={() => setSelectedWallet({ owner: false, internal: true })}
            icon={<Icon name={IocnCurrencyLogo} id="icon--img-logo" />}
            iconPosition="left"
            size="L"
            outline
            isSelected={selectedWallet.internal}
          >
            {croppedString(internalSelectedAddress, 0, 7, -7)}
          </Chip>
        )}
      </div>

      <div data-testid="dashboard-tabs" className="dashboard__body">
        <BaseTabs
          {...tabsArgs}
        />
      </div>

      <div className="dashboard__footer">
        <Social
          twitter
          discord
          telegram
        />
      </div>
    </div>
  )
}
