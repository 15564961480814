import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Wallets from './wallets/Wallets'
import SidebarMenu from './sidebar-menu/SidebarMenu'
import { Icon } from '../../../components/Icon/Icon'
import { TabVertical } from './sidebar-menu/tab-vertical/TabVertical'
import IconShowChart from '../../../components/Icon/icon--show-chart.svg'
import IconBarChart from '../../../components/Icon/icon--bar-chart.svg'
import IconSwapHoriz2 from '../../../components/Icon/icon--swap-horiz-2.svg'
import IconStorage from '../../../components/Icon/icon--storage.svg'
import IconPieChart from '../../../components/Icon/icon--pie-chart.svg'
import IconHistory from '../../../components/Icon/icon--history.svg'
import { Divider } from '../../../components/Divider/Divider'
import AssetsBlock from './assets-block/AssetsBlock'
import SidebarHeader from './sidebar-header/SidebarHeader'
import MultiChainSelector from './multi-chain-selector/MultiChainSelector'

import './sidebar.scss'
import { getFeatureFlag } from '../../../utils/feature-flags'

const Sidebar = ({
  isSidebarShownViaMouseOver,
  onToggleSidebar,
  onSetIsOpenSubMenu,
}) => {
  const isShowLoyalty = getFeatureFlag('isShowLoyalty')
  const userState = useSelector((store) => store.user)
  const pagesUnvailableWithoutSign = ['trade', 'exchange', 'bridge']

  const [tabs] = useState([
    {
      icon: {
        name: IconShowChart,
        id: 'icon--show-chart',
      },
      text: 'Dashboard',
      to: '/dashboard',
    },
    {
      icon: {
        name: IconBarChart,
        id: 'icon--bar-chart',
      },
      text: 'Trade',
      to: '/trade',
    },
    {
      icon: {
        name: IconSwapHoriz2,
        id: 'icon--swap-horiz-2',
      },
      text: 'Exchange',
      to: '/exchange',
    },
    {
      icon: {
        name: IconStorage,
        id: 'icon--storage',
      },
      text: 'Bridge',
      to: '/bridge',
    },
    {
      icon: {
        name: IconPieChart,
        id: 'icon--pie-chart',
      },
      text: 'Smart Invest',
      to: '/smart-invest',
      disabled: true,
    },
    {
      icon: {
        name: IconPieChart,
        id: 'icon--pie-chart',
      },
      text: 'Loyalty',
      to: '/loyalty',
      disabled: !isShowLoyalty,
    },
    {
      icon: {
        name: IconHistory,
        id: 'icon--history',
      },
      text: 'History',
      to: '/history',
      disabled: true,
    },
  ])

  return (
    <>
      {isSidebarShownViaMouseOver && <div className="background-cover-for-sidebar" />}
      <aside className={`left-sidebar-container ${isSidebarShownViaMouseOver ? 'shown-above' : ''}`}>
        <SidebarHeader onToggleSidebar={onToggleSidebar} />

        <div className="body">
          <Wallets onSetIsOpenSubMenu={onSetIsOpenSubMenu} />
          <AssetsBlock />
          <div className="bottom-controls">
            <Divider />
            <SidebarMenu
              items={tabs.map((item) => (
                <TabVertical
                  icon={<Icon name={item.icon.name} id={item.icon.id} />}
                  text={item.text}
                  to={item.to}
                  disabled={
                    item.disabled
                    || !userState
                    || (
                      pagesUnvailableWithoutSign.includes(item.text.toLowerCase())
                      && userState
                      && userState.internalAddresses.length === 0
                    )
                  }
                />
              ))}
            />
            <Divider />
            <MultiChainSelector />
          </div>
        </div>
      </aside>
    </>
  )
}

Sidebar.propTypes = {
  isSidebarShownViaMouseOver: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  onSetIsOpenSubMenu: PropTypes.func,
}

export default Sidebar
