import React from 'react'
import FirstScreenBanner from '../../../../components/first-screen-banner/FirstScreenBanner'
import BackgroundImg02 from '../../../../components/first-screen-banner/backgroundImg--02.png'
import BodyImgEmperor from '../../../../components/first-screen-banner/bodyImg--emperor.png'
import { Social } from '../../../../components/Social/Social'
import './loyalty-user.scss'
import { BaseTextBody, BaseTextHeading } from '../../../../components/BaseText/BaseText'
import { BaseButton } from '../../../../components/BaseButton/BaseButton'
import YourFunctionality from './your-functionality/YourFunctionality'
import GasCompensation from './gas-compensation/GasCompensation'

const LoyaltyUser = () => (
  <div className="loyalty-user">
    <FirstScreenBanner
      backgroundImg={BackgroundImg02}
      headerLeft="Warlock loyalty program"
      bodyImg={BodyImgEmperor}
    >
      <div>
        <BaseTextHeading size="XXL">
          Your level -
          <span>emperor</span>
        </BaseTextHeading>
        <BaseTextBody>Your CFI staked - 999,999.999 CFi</BaseTextBody>
      </div>

      <div className="first-screen-banner__actions">
        <BaseButton>Buy CFI</BaseButton>
        <BaseButton>Stake more CFI</BaseButton>
        <BaseButton>Unstake</BaseButton>
      </div>
    </FirstScreenBanner>

    <div className="base-container">
      <YourFunctionality />

      <GasCompensation />
    </div>

    <div className="loyalty-user__footer">
      <Social
        twitter
        discord
        telegram
      />
    </div>
  </div>
)

export default LoyaltyUser
