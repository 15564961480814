import React from 'react'
import PropTypes from 'prop-types'
import './base-tab.scss'
import { BaseTextBody } from '../BaseText/BaseText'

export const BaseTab = ({
  text,
  onClick,
  active,
  disabled,
  withAdditionalBorder,
  dataTestid,
}) => {
  function handleClick() {
    onClick()
  }

  return (
    <div
      className={`base-tab 
        ${active ? 'base-tab--active' : ''}
        ${disabled ? 'base-tab--disabled' : ''}
        ${withAdditionalBorder ? 'base-tab--without-borders' : ''}
      `}
      onClick={handleClick}
      data-testid={dataTestid}
    >
      <BaseTextBody>
        {text}
      </BaseTextBody>
    </div>
  )
}

BaseTab.propTypes = {
  dataTestid: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  withAdditionalBorder: PropTypes.bool,
}
