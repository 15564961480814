import React, {
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { BaseModal } from '../../../../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../../components/Icon/icon--close.svg'
import IconWarning from '../../../../../../../../components/Icon/icon--warning.svg'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../../components/BaseText/BaseText'

import './delete-cyber-wallet-modal.scss'

const DeleteCyberWalletModalMsgStep = ({
  chosenCyberWallet,
  onCloseModal,
  onNextStep,
}) => {
  const getModalHeader = () => (
    <BaseButton
      onClick={onCloseModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getModalBody = useMemo(() => (
    <>
      <div className="delete-cyber-wallet-modal__icon-block">
        <Icon
          name={IconWarning}
          id="icon--warning"
        />
      </div>
      <BaseTextHeading size="S">
        {chosenCyberWallet.name}
        &nbsp;
        balance:
        $
        {chosenCyberWallet.totalBalance}
        <br />
        Are you sure you want to delete the wallet?
      </BaseTextHeading>
      <BaseTextBody size="S">
        We will delete the wallet data from the platform, so the funds on it will no longer be available.
        Please make sure that you have transferred all the funds from it.
      </BaseTextBody>
    </>
  ), [chosenCyberWallet])

  const getModalFooter = useMemo(() => (
    <div className="delete-cyber-wallet__base-modal__footer">
      <div className="delete-cyber-wallet__base-modal__footer__btn">
        <BaseButton
          onClick={onCloseModal}
          variant="secondary"
        >
          Cancel
        </BaseButton>
        <BaseButton
          dataTestid="popup--delete-wallet--next-step"
          onClick={onNextStep}
        >
          Continue
        </BaseButton>
      </div>
    </div>
  ), [onCloseModal, onNextStep])

  return (
    <BaseModal
      title="Delete Cyber Wallet"
      topAction={getModalHeader()}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

DeleteCyberWalletModalMsgStep.propTypes = {
  chosenCyberWallet: PropTypes.object,
  onCloseModal: PropTypes.func,
  onNextStep: PropTypes.func,
}

export default DeleteCyberWalletModalMsgStep
