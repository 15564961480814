import instance from '../axios-api'
import { parserZapperAllTransactions } from '../parser/api'

const ZAPPER_API_KEY = '96e0cc51-a62e-42ca-acee-910ea7d2a241'

export const ZAPPER_SUPPORT_CHAIN = {
  ethereum: 'ethereum',
  polygon: 'polygon',
  optimism: 'optimism',
  xdai: 'xdai',
  bsc: 'binance-smart-chain',
  fantom: 'fantom',
  avalanche: 'avalanche',
  arbitrum: 'arbitrum',
  celo: 'celo',
  harmony: 'harmony',
}

export const getAllTransactions = async (address, network) => {
  const url = `https://api.zapper.fi/v1/transactions?api_key=${ZAPPER_API_KEY}&network=${ZAPPER_SUPPORT_CHAIN[network.toLowerCase()]}&addresses[]=${address}`
  const { data: { data } } = await instance.get(url)

  return parserZapperAllTransactions(data)
}
