import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Logo } from '../../../../components/Logo/Logo'
import { BaseButton } from '../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../components/Icon/Icon'
import IconNotifications from '../../../../components/Icon/icon--notifications.svg'
import IconSettings from '../../../../components/Icon/icon--settings.svg'
import IconMenuOpen from '../../../../components/Icon/icon--menu-open.svg'

import './sidebar-header.scss'

const SidebarHeader = ({
  onToggleSidebar,
}) => {
  const [isFeatureHidden] = useState(true)

  return (
    <div className="sidebar-header">
      <Logo />
      <div className="sidebar-header__action">
        {
          !isFeatureHidden && (
            <BaseButton
              variant="secondary"
              onlyIcon
              size="xs"
              iconLeft={<Icon name={IconNotifications} id="icon--notifications" />}
            />
          )
        }
        {
          !isFeatureHidden && (
            <BaseButton
              variant="secondary"
              onlyIcon
              size="xs"
              iconLeft={<Icon name={IconSettings} id="icon--settings" />}
            />
          )
        }
        <BaseButton
          variant="secondary"
          onlyIcon
          size="xs"
          iconLeft={<Icon name={IconMenuOpen} id="icon--menu-open" />}
          onClick={onToggleSidebar}
        />
      </div>
    </div>
  )
}

SidebarHeader.propTypes = {
  onToggleSidebar: PropTypes.func,
}

export default SidebarHeader
