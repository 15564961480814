import React, {
  useMemo, useState, useEffect,
} from 'react'
import PropTypes from 'prop-types'
import EthereumQrCodeGenerator from 'ethereum-qr-code'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { BaseModal } from '../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../components/Icon/Icon'
import IconClose from '../../../../../components/Icon/icon--close.svg'
import { Stepper } from '../../../../../components/Stepper/Stepper'
import { BaseTextHeading, BaseTextSubheading } from '../../../../../components/BaseText/BaseText'
import IconAccountBalanceWallet from '../../../../../components/Icon/icon--account-balance-wallet.svg'
import IconSwapHoriz from '../../../../../components/Icon/icon--swap-horiz.svg'
import IconCreditCard from '../../../../../components/Icon/icon--credit-card.svg'
import { Alert } from '../../../../../components/Alert/Alert'
import { Input } from '../../../../../components/Input/Input'
import { ClipboardTooltip } from '../../../../../components/ClipboardTooltip/ClipboardTooltip'
import IconFileCopy from '../../../../../components/Icon/icon--file-copy.svg'

import './deposit-assets-modal.scss'

const DepositAssetsModal = ({
  chosenWallet,
  onCloseModal,
}) => {
  const [currentStep, setCurrentStepState] = useState(1)
  const [chosenOption, setChosenOptionState] = useState('crypto')

  const getModalHeader = () => (
    <BaseButton
      onClick={() => {
        onCloseModal()
      }}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getModalStepper = useMemo(() => {
    const getStepState = (step) => (currentStep > step ? 'completed' : 'active')

    const stepsData = [
      {
        state: getStepState(1),
        text: 'Deposit method',
        active: currentStep === 1,
      },
      {
        state: 'active',
        text: 'Payment process',
        active: currentStep === 2,
      },
    ]

    return (
      <Stepper steps={stepsData.map((step, index) => ({
        badge: {
          state: step.state,
          text: `${index + 1}`,
        },
        text: step.text,
        active: step.active,
      }))}
      />
    )
  }, [currentStep])

  useEffect(() => {
    if (currentStep === 2) {
      const qr = new EthereumQrCodeGenerator()
      qr.toCanvas({
        to: chosenWallet,
      }, {
        size: 248,
        selector: '#deposit-modal__qr-code',
        options: {
          margin: 1,
        },
      })
    }
  }, [currentStep, chosenWallet])

  const getModalBody = useMemo(() => {
    const getWalletStatus = ({ title, withIcon }) => (
      <>
        {withIcon && (
          <div className="deposit-assets-modal__icon-block">
            <Icon
              name={IconAccountBalanceWallet}
              id="icon--account-balance-wallet"
            />
          </div>
        )}
        <BaseTextHeading size="S">{title}</BaseTextHeading>
      </>
    )

    const getFirstStep = () => (
      <>
        {getWalletStatus({ title: 'Select one of the options to deposit to your wallet', withIcon: true })}
        <div className="deposit-assets-modal__container">
          <div className="deposit-options-block">
            <div
              className={`checkbox ${chosenOption === 'crypto' ? 'active' : ''}`}
              onClick={() => setChosenOptionState('crypto')}
            >
              <Icon
                name={IconSwapHoriz}
                id="icon--swap-horiz"
              />
              <BaseTextSubheading>Crypto exchange</BaseTextSubheading>
            </div>
            <div className="checkbox disabled">
              <Icon
                name={IconCreditCard}
                id="icon--credit-card"
              />
              <BaseTextSubheading>Credit card</BaseTextSubheading>
            </div>
          </div>
        </div>
      </>
    )

    const getSecondStep = () => (
      <>
        <div className="deposit-assets-modal__qr-code-block" id="deposit-modal__qr-code" />
        {getWalletStatus({ title: 'Deposit from your exchange to the following address' })}
        <div className="deposit-assets-modal__payment-details">
          <div className="deposit-assets-modal__input-block">
            <Input dataTestid="popup--deposit-assets-input-address" type="text" name="chosen-wallet" value={chosenWallet} readOnly />
            <ClipboardTooltip tooltipText="Copy address" tooltipTextFocus="Copied!">
              <CopyToClipboard text={chosenWallet}>
                <span>
                  <Icon
                    mod="icon--14"
                    name={IconFileCopy}
                    id="icon--file-copy"
                  />
                </span>
              </CopyToClipboard>
            </ClipboardTooltip>
          </div>
          <Alert warning>Only send ETH or any ERC-20 token to this address!</Alert>
        </div>
      </>
    )

    switch (currentStep) {
      case 1:
        return getFirstStep()
      case 2:
        return getSecondStep()
      default:
        return getFirstStep()
    }
  }, [chosenOption, currentStep, setChosenOptionState, chosenWallet])

  const getModalFooter = useMemo(() => {
    const getCloseButton = () => {
      let text = 'Cancel'
      let variant = 'secondary'
      if (currentStep === 2) {
        text = 'Close'
        variant = ''
      }

      return (
        <BaseButton
          onClick={onCloseModal}
          variant={variant}
        >
          {text}
        </BaseButton>
      )
    }

    const getNextButton = () => {
      const buttonProps = {
        text: 'Next step',
        isDisabled: currentStep === 1 && !chosenOption,
      }

      return (
        <BaseButton
          dataTestid="popup--deposit-assets-btn-next-step"
          disabled={buttonProps.isDisabled}
          onClick={() => setCurrentStepState(currentStep + 1)}
        >
          {buttonProps.text}
        </BaseButton>
      )
    }

    return (
      <div className="create-cyber-wallet__base-modal__footer">
        <div className="create-cyber-wallet__base-modal__footer__btn">
          {getCloseButton()}
          {currentStep !== 2 && getNextButton()}
        </div>
      </div>
    )
  }, [currentStep, onCloseModal, chosenOption])

  return (
    <BaseModal
      title="Deposit Assets"
      topAction={getModalHeader()}
      headerBottom={getModalStepper}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

DepositAssetsModal.propTypes = {
  chosenWallet: PropTypes.string,
  onCloseModal: PropTypes.func,
}

export default DepositAssetsModal
