import React from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import './gwei-input.scss'
import { Input } from '../../../../../../../../components/Input/Input'

const GweiInput = ({
  title,
  gweiValue,
}) => (
  <div className="gwei-input">
    <BaseTextBody
      size="S"
      secondary
    >
      {title}
    </BaseTextBody>

    <Input
      className="gwei-input__input"
      type="number"
      placeholder="0"
      value={gweiValue}
      onChange={() => {}}
      onFocus={() => {}}
      onFocusOut={() => {}}
    />

    <div className="gwei-input__desc">
      <BaseTextBody
        size="S"
        secondary
      >
        gwei
      </BaseTextBody>
    </div>
  </div>
)

GweiInput.propTypes = {
  title: PropTypes.string,
  gweiValue: PropTypes.string,
}

export default GweiInput
