import React from 'react'
import { toast } from 'react-toastify'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'

import 'react-toastify/dist/ReactToastify.css'
import './toast.scss'

/**
 * Используется для варнингов
 * @param {string} text
 */
export const toastNeutral = (text) => toast(
  <span className="spanToast">
    <InfoOutlinedIcon className="iconToast" />
    {text}
  </span>, {
    className: 'custom-toast custom-toast-neutral',
  },
)
/**
 * Используется если все хорошо
 * @param {string} text
 */
export const toastSuccess = (text) => toast(
  <span className="spanToast">
    <CheckCircleOutlineOutlinedIcon className="iconToast" />
    {text}
  </span>, {
    className: 'custom-toast custom-toast-success',
  },
)
/**
 * Используется при ошибках
 * @param {string} text
 */
export const toastCritical = (text) => toast(
  <span className="spanToast">
    <CancelOutlinedIcon className="iconToast" />
    {text}
  </span>, {
    className: 'custom-toast custom-toast-critical',
  },
)
