import instance from '../axios-api'
// eslint-disable-next-line import/no-cycle
import {
  parseGetDataSelectWallet,
  getCurrentNetworkForRequest,
  parseGetTopAsset,
  parserGetDataUser,
  parsePoolLiquidityData,
  parseOrdersList,
  parseTokensPairsList,
  parseTokenData,
  parseTokensList,
  parseTokenSwapOrder,
  parsePair,
} from '../parser/api'

/**
 * @returns {Promise<void>}
 */
export const refreshAccessToken = async () => {
  const accessToken = localStorage.getItem('accessToken')

  try {
    const { data } = await instance.post('/api/v2/monitor/connect/refresh/', {}, {
      headers: {
        Authorization: `Token ${accessToken}`,
      },
    })

    localStorage.setItem('accessToken', data.accessToken)
  } catch (e) {
    console.error(e)
  }
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userLogin = () => instance.get('/api/v2/monitor/connect/request/')

/**
 * @param {string} signature
 * @param {string} message
 * @returns {Promise<AxiosResponse<any>>}
 */
export const userConnectCreate = ({ signature, message }) => instance.post('/api/v2/monitor/connect/create/', {
  signature,
  message,
})

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const accessMessage = () => instance.get('/api/v2/storage/get_access_message/')

/**
 * @param {object} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addSw = (data) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post('/api/v2/monitor/profile/sw/add/', data, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * @param {string} address
 * @param {object} cancelToken
 * @param {number} timestamp
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getDataSelectWallet = async (address, cancelToken, timestamp) => {
  const balancesRes = await instance.get('/api/v2/monitor/proxy/balances/', {
    cancelToken,
    params: {
      address,
      network: getCurrentNetworkForRequest(),
      timestamp,
    },
  })
  return parseGetDataSelectWallet(balancesRes)
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apiGetDataUser = async () => {
  const accessToken = localStorage.getItem('accessToken')

  const dataUser = await instance.get('/api/v2/monitor/profile/', {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return parserGetDataUser(dataUser)
}

export const getTokenSwapOrderForToken = (token) => {
  const accessToken = localStorage.getItem('accessToken')
  return instance.get(`/api/v2/monitor/orders/token-swap/?asset0__address=${token}`, {
    params: {
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

// TODO: remove once new trade is enabled
export const createTokenSwapOrder = ({
// eslint-disable-next-line camelcase
  external_address, transactions, triggers = [], asset0, asset0_value, asset1,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  const data = {
    external_address,
    transactions,
    triggers,
    asset0,
    asset0_value,
    asset1,
  }
  return instance.post('/api/v2/monitor/orders/token-swap/', data, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

// TODO: rename this method to 'createTokenSwapOrder' once new trade is enabled
export const sendCreateTokenSwapOrderRequest = async (data) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.post('/api/v2/monitor/orders/token-swap/', {
    ...data,
    network: getCurrentNetworkForRequest(),
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
  return parseTokenSwapOrder(res.data)
}

export const getEthPrice = () => instance.get('https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT')

export const getBnbPrice = () => instance.get('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')

export const getMaticPrice = () => instance.get('https://api.binance.com/api/v3/ticker/price?symbol=MATICUSDT')

/**
 * @param {object} data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const confirmOrder = (data) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post('/api/v2/monitor/orders/confirm/', { transactions: [data] }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

export const apiTransferOrder = (data) => {
  const accessToken = localStorage.getItem('accessToken')
  return instance.post('/api/v2/monitor/orders/transfer-order/', {
    ...data,
    network: getCurrentNetworkForRequest(),
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * @param {string} filterValue
 * @param {string} pairFor
 * @param {number|string} page
 * @param {number|string} limit
 * @returns {Promise<AxiosResponse<any>>}
 */
export const lpTokensFilter = ({
  filterValue, pairFor, page, limit = 10,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  return instance.get('/api/v2/monitor/lp-tokens/', {
    params: {
      search: filterValue,
      pairFor,
      page,
      limit,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

// TODO: remove once new trade is enabled
/**
 * Поиск пары по двум токенам
 * @param {string} address1 - Первый токен в паре. Нужно прогонять через toCheckSumAddress
 * @param {string} address2 - Второй токен в паре. Нужно прогонять через toCheckSumAddress
 * @param {number|string} page
 * @param {number|string} limit
 * @returns {Promise<AxiosResponse<any>>}
 */
export const findLpTokens = ({
  address1, address2, page, limit,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  return instance.get('/api/v2/monitor/lp-tokens/', {
    params: {
      search: address1,
      pairFor: address2,
      page,
      limit,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * Поиск пары по двум токенам
 * @param {string} address1 - Первый токен в паре. Нужно прогонять через toCheckSumAddress
 * @param {string} address2 - Второй токен в паре. Нужно прогонять через toCheckSumAddress
 * @param {number|string} page
 * @param {number|string} limit
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLpTokensPair = async ({
  address1, address2, page, limit,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.get('/api/v2/monitor/lp-tokens/', {
    params: {
      search: address1,
      pairFor: address2,
      page,
      limit,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return res.data[0] ? parsePair(res.data[0]) : undefined
}

/**
 * Получаем пару по первому токену
 * @param {string} address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getLpPair = (address) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.get('/api/v2/monitor/lp-tokens/', {
    params: {
      pairFor: address,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}
/**
 * Получаем информацию по конкретной паре
 * @param {string} address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getInfoLpPair = (address) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.get(`/api/v2/monitor/lp-tokens/${address}/`, {
    params: {
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

// TODO: remove once we have a new trade enabled
/**
 * @param {string} filterValue
 * @param {number} limit
 * @param {number} page
 * @returns {Promise<AxiosResponse<any>>}
 */
export const tokensFilter = (filterValue, limit, page) => {
  const accessToken = localStorage.getItem('accessToken')
  return instance.get('/api/v2/monitor/tokens/', {
    params: {
      search: filterValue,
      limit,
      page,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * @param {string} filterValue
 * @param {number} limit
 * @param {number} page
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTokensList = async ({
  filterValue, limit = 10, page,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.get('/api/v2/monitor/tokens/', {
    params: {
      search: filterValue,
      limit,
      page,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return parseTokensList(res.data)
}

// TODO: remove once we have a new trade enabled
/**
 * Получение информации о токене
 * @param {string} address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getToken = (address) => {
  const accessToken = localStorage.getItem('accessToken')
  return instance.get(`/api/v2/monitor/tokens/${address}/`, {
    params: {
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * Получение информации о токене
 * @param {string} address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTokenData = async (address, cancelToken) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.get(`/api/v2/monitor/tokens/${address}/`, {
    cancelToken,
    params: {
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return parseTokenData(res.data)
}

/**
 * Получение информации pool liquidity
 * @param {string} address
 * @param {string} cyberwallet
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getPoolLiquidity = async ({
  address, internalAddress, cancelToken, isShouldParse,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.get(`/api/v2/monitor/tokens/${address}/pnl/?address=${internalAddress}`, {
    cancelToken,
    params: {
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
  // TODO: remove this isShouldParse once we get rid of old trade
  return isShouldParse ? parsePoolLiquidityData(res.data) : res
}

// TODO: remove once we have a new trade enabled
/**
 * @param {number} limit
 * @param {number} page
 * @param {string} ordering
 * @param {string} cyberwallet
 * @param {string} asset0address
 * @param {string} asset1address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTokenSwapOrder = (
  cyberwallet,
  limit = 10,
  page = 1,
  ordering,
  asset0address,
  asset1address,
) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.get('/api/v2/monitor/orders/token-swap/', {
    params: {
      limit,
      page,
      ordering,
      cyberwallet,
      asset0__address: asset0address,
      asset1__address: asset1address,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * @param {string} cyberwallet
 * @param {number} limit
 * @param {number} currentPage
 * @param {string} ordering
 * @param {string} address1
 * @param {string} address2
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrdersList = async ({
  cyberWallet,
  limit = 10,
  currentPage = 1,
  ordering,
  address1,
  address2,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.get('/api/v2/monitor/orders/token-swap/', {
    params: {
      cyberwallet: cyberWallet,
      limit,
      page: currentPage,
      ordering: ordering.replace('creationTime', 'base_order__creation_time'),
      asset0__address: address1,
      asset1__address: address2,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return parseOrdersList(res.data)
}

// TODO: remove once we have a new trade enabled
/**
 * @param {number} limit
 * @param {number} page
 * @param {string} ordering
 * @param {string} cyberwallet
 * @param {string} asset0address
 * @param {string} asset1address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTokenSwapOrderHistory = (
  cyberwallet,
  limit,
  page,
  ordering,
  asset0address,
  asset1address,
) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.get('/api/v2/monitor/orders/token-swap/history/', {
    params: {
      limit,
      page,
      ordering,
      cyberwallet,
      asset0__address: asset0address,
      asset1__address: asset1address,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 * @param {number} limit
 * @param {number} page
 * @param {string} ordering
 * @param {string} cyberwallet
 * @param {string} asset0address
 * @param {string} asset1address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getHistoryList = async ({
  cyberWallet,
  limit = 10,
  currentPage = 1,
  ordering,
  address1,
  address2,
}) => {
  const accessToken = localStorage.getItem('accessToken')
  const res = await instance.get('/api/v2/monitor/orders/token-swap/history/', {
    params: {
      cyberwallet: cyberWallet,
      limit,
      page: currentPage,
      ordering: ordering.replace('creationTime', 'base_order__creation_time'),
      asset0__address: address1,
      asset1__address: address2,
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return parseOrdersList(res.data)
}

// TODO: remove once we have a new trade enabled
/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTokenPairs = () => {
  const accessToken = localStorage.getItem('accessToken')
  const selectedAddress = localStorage.getItem('selectedAddress')

  return instance.get('/api/v2/monitor/orders/token-swap/token-pairs/', {
    params: {
      cyberwallet: selectedAddress,
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

export const getTokensPairsList = async () => {
  const accessToken = localStorage.getItem('accessToken')
  const selectedAddress = localStorage.getItem('selectedAddress')

  const res = await instance.get('/api/v2/monitor/orders/token-swap/token-pairs/', {
    params: {
      cyberwallet: selectedAddress,
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })

  return parseTokensPairsList(res.data)
}

/**
 * Запрос на удаление транзакции
 * @param {string|number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteOrder = (id) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.delete(`/api/v2/monitor/orders/token-swap/${id}/`, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}
/**
 * Запрос на ускорение транзакции
 * @param {string|number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const urgentlyOrder = (id) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post(`/api/v2/monitor/orders/token-swap/${id}/urgent-exec/`, {}, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}
/**
 * Запрос на ускорение транзакции
 * @param {string|number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getTopAsset = async () => {
  const ratingRes = await instance.get('/api/v2/monitor/tokens/rating/')
  return parseGetTopAsset(ratingRes)
}

export const checkTransferTransaction = (id) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.get(`/api/v2/monitor/orders/transfer-order/${id}/`, {
    params: {
      network: getCurrentNetworkForRequest(),
    },
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 *
 * @param {string} address
 * @param {string} pin
 * @param {string|number} hours
 * @returns {Promise<AxiosResponse<any>>}
 */
export const enableGodMode = ({ address, pin, hours }) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post('/api/v2/monitor/profile/sw/advanced/', {
    address,
    pin,
    hours,
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 *
 * @param {string} address
 * @returns {Promise<AxiosResponse<any>>}
 */
export const disableGodMode = (address) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post('/api/v2/monitor/profile/sw/advanced/', {
    address,
    hours: '0',
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 *
 * @param {string} address
 * @param {string} pin
 * @returns {Promise<AxiosResponse<any>>}
 */
export const extractPrivateKeyApi = ({ address, pin }) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.post('/api/v2/monitor/profile/sw/remove/', {
    address,
    pin,
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}

/**
 *
 * @param {string} address
 * @param {string} newName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const renameCyberwallet = ({ address, name }) => {
  const accessToken = localStorage.getItem('accessToken')

  return instance.patch(`/api/v2/monitor/profile/cyberwallet/${address}/`, {
    address,
    name,
  }, {
    headers: {
      Authorization: `Token ${accessToken}`,
    },
  })
}
