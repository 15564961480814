import React, { useState, useEffect, useCallback } from 'react'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import IconEthereum from '../../../../components/Icon/icon--ethereum.svg'
import { SelectWithControl } from '../../../../components/SelectWithControl/SelectWithControl'
import IconBsc from '../../../../components/Icon/icon--bsc.png'
import IconPolygon from '../../../../components/Icon/icon--polygon.png'
import { ETHEREUM_NETWORK_SETTINGS, BSC_NETWORK_SETTINGS, POLYGON_NETWORK_SETTINGS } from '../../../../const'
import { LIST_WEB3_PROVIDER, SUPPORTED_CHAINS } from '../../../../const/web3'
import { getChainNameBasedOnId } from '../../../../parser/data'

import './multi-chain-selector.scss'

const MultiChainSelector = () => {
  const [selectedChainFromLocalStorage] = useLocalStorage('selectedChain')
  const [selectedProvider] = useLocalStorage('selectedProvider')

  const [chains] = useState([
    {
      icon: {
        id: 'icon--ethereum',
        name: IconEthereum,
      },
      name: SUPPORTED_CHAINS.ethereum,
      title: 'Ethereum',
    },
    {
      image: IconBsc,
      name: SUPPORTED_CHAINS.bsc,
      title: 'Binance Smart Chain',
    },
    {
      image: IconPolygon,
      name: SUPPORTED_CHAINS.polygon,
      title: 'Polygon',
    },
  ])

  const [selectedChain, setSelectedChain] = useState(chains[0])

  useEffect(() => {
    if (selectedChainFromLocalStorage && selectedChain?.name !== selectedChainFromLocalStorage) {
      setSelectedChain(chains.filter((chain) => chain.name === selectedChainFromLocalStorage)[0])
    }
  }, [selectedChainFromLocalStorage, chains, selectedChain])

  const onSelectNetwork = useCallback(async (chosenIndex) => {
    let networkSettings = ETHEREUM_NETWORK_SETTINGS
    if (chosenIndex === 1) {
      networkSettings = BSC_NETWORK_SETTINGS
    } else if (chosenIndex === 2) {
      networkSettings = POLYGON_NETWORK_SETTINGS
    }

    try {
      if (selectedProvider === LIST_WEB3_PROVIDER.metamask) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networkSettings.chainId }],
        })
      } else {
        writeStorage('selectedChain', getChainNameBasedOnId(networkSettings.chainId))
        // there is no callback for the network change event for other providers yet
        window.location.reload()
      }
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkSettings],
          })
        } catch (e) {
          console.log('Polygon network adding failed = ', e)
        }
      }
    }
  }, [selectedProvider])

  return (
    <SelectWithControl
      items={chains}
      selectedItem={selectedChain}
      onSelectItem={onSelectNetwork}
      dataTestid="SwitchNetwork"
    />
  )
}

export default MultiChainSelector
