import React, {
  useState, useEffect, useCallback, useRef, useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from '@rehooks/local-storage'
import PropTypes from 'prop-types'
import { BigNumber } from 'bignumber.js'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import ErrorIcon from '@material-ui/icons/Error'
import IconSelectCircleActive from '../Icon/icon--select-circle-active.svg'
import { getTokenPrice } from '../../service/coinGekoApi'
import { getTokenBalance } from '../../service/etherscanApi'
import { allLPTokens } from '../../service/uniswapApi'
import {
  addLpTokenToStore,
  reverseTokens,
  setAsset,
  setToTrade,
  toggleTopAsset,
  toggleTopAddress,
} from '../../store/slice/tradingView'
import { postTokenSwapOrder } from '../../store/slice/user'
import { Btn } from '../Btn/Btn'
import { BtnFormBig } from '../BtnFormBig/BtnFormBig'
import { Icon } from '../Icon/Icon'
import Loading from '../Loading/Loading'
import { Text } from '../Text/Text'
import {
  approveTokensData,
  ERC20ToFloat,
  safeFloatToERC20,
  getEtherBalance,
  formatEth,
  checkFloatNaN,
  balanceToERC20,
  convertETHToWETH,
  getAllowance, getTokenExactData,
} from '../../utils/helperFunctions'
import './exchange-create-order-form-new.scss'
import {
  USDT_ADDRESS,
  WRAPPED_ETH_ADDRESS,
  ZERO_ADDRESS,
  NEW_ETH_ADDRESS,
} from '../../const'
import { ExchangeItem } from '../ExchangeItem/ExchangeItem'
import {
  tokensFilter, getToken, getLpPair, getInfoLpPair, findLpTokens,
} from '../../service/api'
import { AssetField } from '../AssetField/AssetField'
import { AssetInfo } from '../AssetInfo/AssetInfo'
import IconWallet from '../Icon/icon--wallet.svg'
import { List } from '../List/List'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { Alert } from '../Alert/Alert'
import { SelectBase } from '../SelectBase/SelectBase'
import IconSelectCircle from '../Icon/icon--select-circle.svg'
import IconCheckBtn2 from '../Icon/icon--check-btn-2.svg'
import { zeroX } from '../../service/0x'
import { getFeatureFlag } from '../../utils/feature-flags'
import { getGas } from '../../service/gas'
import { getLinkBasedOnCurrentNetwork, multiplyBigNumber } from '../../parser/data'
import IconAllInclusive from '../Icon/icon--all-inclusive.svg'
import { useWeb3Bn } from '../../hooks/web3'
import { useWeb3 } from '../../context/web3Provider'

const tokenInitState = {
  address: 'Loading...',
  symbol: '...',
  name: 'Loading...',
  balance: '...',
  price: 0,
}

export const ExchangeCreateOrderFormNew = ({
  className, market, trigger, LP, MC, DCA, settingsContainer,
}) => {
  const Web3BN = useWeb3Bn()
  const dispatch = useDispatch()
  const web3React = useWeb3()
  const tokensReversed = useSelector((store) => store.tradingView.reversed)
  const tradingView = useSelector((store) => store.tradingView)
  const userState = useSelector((store) => store.user)
  const [pairPrice, setPairPrice] = useState()
  const [firstToken, setFirstToken] = useState(tokenInitState)
  const [secondToken, setSecondToken] = useState(tokenInitState)
  const [internalAddress, setInternalAddress] = useState('')
  const [triggerPrice, setTriggerPrice] = useState(null)
  const [isFirstSetOrder, setIsFirstSetOrder] = useState(true)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const oldStateTrigger = useRef()
  const oldStateFirstTokenAddress = useRef()
  const oldStateSecondTokenAddress = useRef()
  const oldChartToken = useRef()

  const [upperTokensList, setUpperTokensList] = useState([])
  const [loadingContainer, setLoadingContainer] = useState(true)
  const [lowerTokensList, setLowerTokensList] = useState([])
  const [triggerListTokens, setTriggerListTokens] = useState([])
  const [triggerLpPair, setTriggerLpPair] = useState({})
  const [lpPairs, setLPPairs] = useState([])
  const [upperInputValue, setUpperInputValue] = useState('')
  const [downInputValue, setDownInputValue] = useState('')
  const [downInputSearch, setDownInputSearch] = useState('')
  const [upperInputSearch, setUpperInputSearch] = useState('')
  const [downLimitPage] = useState(10)
  const [downTokensListInfinityScrollHasMore, setDownTokensListInfinityScrollHasMore] = useState(true)
  const [upperTokensListInfinityScrollHasMore, setUpperTokensListInfinityScrollHasMore] = useState(true)
  const [downCurrentPage, setDownCurrentPage] = useState(1)
  const [upperCurrentPage, setUpperCurrentPage] = useState(1)
  const [upperPriceValue, setUpperPriceValue] = useState(1)
  const [downPriceValue, setDownPriceValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false)
  const [isFirstGet0x, setIsFirstGet0x] = useState(true)
  const [dataZeroX, setDataZeroX] = useState({})
  const [priceOpen, setPriceOpen] = useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const [isHandleChangeToken, setIsHandleChangeToken] = useState(false)
  const [objError, setObjError] = useState({
    msg: '',
    error: false,
    warning: false,
    assistive: false,
  })

  const tokensDataPoller = useRef()
  const isFirstTokenDataRequestPending = useRef()
  const isSecondTokenDataRequestPending = useRef()

  const convertToExactBalance = useCallback(async (address, decimals) => {
    const tokenBalance = new BigNumber(await getTokenBalance(address, internalAddress))
    const rate = decimals ? parseInt(decimals, 10) : 18
    const pow = new BigNumber(10 ** rate)
    const balance = tokenBalance.dividedBy(pow).toString()

    return balance
  }, [internalAddress])

  const isNewDesign = useMemo(() => getFeatureFlag('isNewDesign'), [])
  const marketItems = useMemo(() => {
    let items = [
      {
        name: 'Rate',
        value: <Text
          text={(
            <>
              1
              {' '}
              {firstToken.symbol}
              {' '}
              =
              {' '}
              <span>{checkFloatNaN(pairPrice, 0)}</span>
              {' '}
              {secondToken.symbol}
            </>
          )}
        />,
      },
      {
        name: 'Inverse rate',
        value: <Text
          text={(
            <>
              1
              {' '}
              {secondToken.symbol}
              {' '}
              =
              {' '}
              <span>{checkFloatNaN(formatEth(secondToken.price / firstToken.price), 0)}</span>
              {' '}
              {firstToken.symbol}
            </>
          )}
        />,
      },
    ]

    if (dataZeroX.sources && dataZeroX.sources.length > 0) {
      items.push({
        name: 'Offered by',
        value: <Text text={dataZeroX.sources[0].name} />,
      })
    }

    if (isNewDesign) {
      items = [
        {
          name: (
            <div>
              <HelpOutlineIcon style={{
                color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'relative', marginRight: '10px', bottom: '-3px',
              }}
              />
              Min. Received
            </div>
          ),
          value: '0 ETH',
        },
        {
          name: 'Rate',
          value: <Text
            text={(
              <>
                1
                {' '}
                {firstToken.symbol}
                {' '}
                =
                {' '}
                <span>{checkFloatNaN(pairPrice, 0)}</span>
                {' '}
                {secondToken.symbol}
              </>
            )}
          />,
        },
        {
          name: 'Offered by',
          value: <ExternalLink
            text="Uniswap V2"
            link={`https://${getLinkBasedOnCurrentNetwork()}/address/`}
          />,
          valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
        },
        {
          name: (
            <div>
              <HelpOutlineIcon style={{
                color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'relative', marginRight: '10px', bottom: '-3px',
              }}
              />
              Price Slippage
            </div>
          ),
          value: '1%',
        },
        {
          name: (
            <div>
              <HelpOutlineIcon style={{
                color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'relative', marginRight: '10px', bottom: '-3px',
              }}
              />
              Network Fee
            </div>
          ),
          value: '$8.26 • Fast',
        },
        {
          name: 'Cyberfi Fee',
          value: '$0',
        },
      ]
    }

    return items
  }, [dataZeroX.sources, firstToken.price, firstToken.symbol, isNewDesign, pairPrice, secondToken.price, secondToken.symbol])

  const checkEtherBalance = useCallback(async ({ gas, gasPrice }) => {
    let isEnough = false
    let wrappedRequiredEtherAmount

    if (userState.tokens) {
      const etherToken = userState.tokens.filter((token) => (
        token.contract_address === NEW_ETH_ADDRESS || token.contract_address === ZERO_ADDRESS
      ))[0]

      if (etherToken) {
        const requiredEtherAmount = ERC20ToFloat(new Web3BN(`${parseFloat(gas) * parseFloat(gasPrice)}`), etherToken.contract_decimals, Web3BN)
        const currentEtherBalance = ERC20ToFloat(await getEtherBalance(internalAddress, web3React), etherToken.contract_decimals, Web3BN)

        wrappedRequiredEtherAmount = new BigNumber(requiredEtherAmount)
        const wrappedCurrentEtherBalance = new BigNumber(currentEtherBalance)
        const koef = new BigNumber([ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(firstToken.address) ? upperPriceValue : 0)

        isEnough = wrappedCurrentEtherBalance.isGreaterThanOrEqualTo(wrappedRequiredEtherAmount.plus(koef))
      }
    }

    setIsBtnDisabled(!isEnough)
    setObjError({
      msg: !isEnough
        ? (
          <>
            Insufficient Ethereum balance.
            <br />
            {
              wrappedRequiredEtherAmount && (
                <>
                  Required amount for gas:&nbsp;
                  {wrappedRequiredEtherAmount.toString()}
                  &nbsp;ETH
                </>
              )
            }
          </>
        ) : '',
      error: !isEnough,
    })

    return isEnough
  }, [userState.tokens, Web3BN, internalAddress, web3React, firstToken.address, upperPriceValue])

  // eslint-disable-next-line consistent-return
  const generateTokensTransaction = async () => {
    try {
      // eslint-disable-next-line no-use-before-define
      await handleGetData0x(upperPriceValue)
      const { data: { fastest } } = await getGas()
      const parsedFastestGasPriceFromStation = (parseFloat(fastest) / 10) * (10 ** 9) * 1.5
      const { allowanceTarget } = dataZeroX
      let necessaryGas = await web3React.library.utils.toHex(parseInt(parseFloat(dataZeroX.gas) * 1.2, 10))
      let isEtherEnough = await checkEtherBalance({
        gas: dataZeroX.gas,
        gasPrice: parsedFastestGasPriceFromStation,
      })
      let firstTransaction

      if (firstToken.address !== ZERO_ADDRESS) {
        const allowedValue = await getAllowance({
          tokenAddress: firstToken.address,
          allowanceTarget,
          userAddress: internalSelectedAddress,
          web3React,
        })

        if (allowedValue === 0 || allowedValue < upperPriceValue) {
          const necessaryApproveGas = 60000
          const necessarySwapGas = 250000

          const firstTransactionData = approveTokensData({
            amount: '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
            tokenAddress: firstToken.address,
            web3React,
            allowanceTarget,
          })

          firstTransaction = {
            to: firstToken.address,
            from_address: internalAddress,
            value: '0x0', // todo: if swap tokens
            data: firstTransactionData,
            gas: await web3React.library.utils.toHex(necessaryApproveGas),
            name: 'Approve',
          }

          necessaryGas = await web3React.library.utils.toHex(necessarySwapGas)
          isEtherEnough = await checkEtherBalance({
            gas: necessaryApproveGas + necessarySwapGas,
            gasPrice: parsedFastestGasPriceFromStation,
          })
        }
      }

      if (isEtherEnough) {
        const necessarySwapGas = 250000
        if (parseInt(parseFloat(dataZeroX.gas) * 1.2, 10) < necessarySwapGas) {
          necessaryGas = await web3React.library.utils.toHex(necessarySwapGas)
        }

        if (firstToken.address === ZERO_ADDRESS) {
          const value = multiplyBigNumber(upperPriceValue)

          const transaction = {
            to: web3React.library.utils.toChecksumAddress(dataZeroX?.to),
            from_address: internalAddress,
            value: await web3React.library.utils.toHex(value),
            data: dataZeroX?.data,
            gas: necessaryGas,
            name: 'Swap',
          }
          return [transaction]
        }
        if (secondToken.address === ZERO_ADDRESS) {
          const transaction = {
            to: web3React.library.utils.toChecksumAddress(dataZeroX?.to),
            from_address: internalAddress,
            value: '0x0',
            data: dataZeroX?.data,
            gas: necessaryGas,
            name: 'Swap',
          }
          return firstTransaction ? [firstTransaction, transaction] : [transaction]
        }

        const secondTransaction = {
          to: web3React.library.utils.toChecksumAddress(dataZeroX?.to),
          from_address: internalAddress,
          value: '0x0', // todo: if swap tokens
          data: dataZeroX?.data,
          gas: necessaryGas,
          name: 'Swap',
        }
        return firstTransaction ? [firstTransaction, secondTransaction] : [secondTransaction]
      }

      return []
    } catch (e) {
      console.log('e = ', e)
      return []
    }
  }

  const sendTokenSwapOrder = async () => {
    if (!internalAddress) {
      console.error('Internal address should be provided')
      return
    }

    const chosenTokenBalance = firstToken.address === ZERO_ADDRESS
      ? ERC20ToFloat(await getEtherBalance(internalAddress, web3React), 18, Web3BN)
      : await convertToExactBalance(firstToken.address, firstToken.decimals)

    const wrappedBalance = new BigNumber(chosenTokenBalance)
    const wrappedUpperPriceValue = new BigNumber(upperPriceValue)

    if (wrappedBalance.isGreaterThanOrEqualTo(wrappedUpperPriceValue)) {
      setIsBtnDisabled(false)
      setObjError({
        error: false,
      })

      const triggers = []
      if (trigger) {
        triggers.push({
          trigger_pair: web3React.library.utils.toChecksumAddress(triggerLpPair.address),
          trigger_price: triggerPrice || triggerLpPair.price,
          start_price: triggerLpPair.price,
        })
      }

      const tokenWithDecimals = firstToken.address === ZERO_ADDRESS
        ? multiplyBigNumber(upperPriceValue)
        : await safeFloatToERC20(firstToken.address, upperPriceValue, web3React)
      const transactions = await generateTokensTransaction()
      if (transactions.length > 0) {
        const data = {
          external_address: web3React.library.utils.toChecksumAddress(web3React.library.currentProvider.selectedAddress),
          transactions,
          triggers,
          asset0: web3React.library.utils.toChecksumAddress(firstToken.address),
          asset0_value: tokenWithDecimals,
          asset1: web3React.library.utils.toChecksumAddress(secondToken.address),
        }
        dispatch(postTokenSwapOrder(data))
      }
    } else {
      setIsBtnDisabled(true)
      setObjError({
        msg: `Insufficient ${firstToken.symbol} balance`,
        error: true,
      })
    }
  }

  const reverseTokensOnTrading = useCallback(() => {
    dispatch(reverseTokens())
    setIsHandleChangeToken(true)
    setIsFirstGet0x(true)
  }, [dispatch])

  const setUpperTokenByAddress = useCallback(async (token) => {
    const address = web3React.library.utils.toChecksumAddress(token.address)

    if (address === ZERO_ADDRESS || address === NEW_ETH_ADDRESS) {
      setFirstToken({
        ...firstToken,
        symbol: 'ETH',
        name: 'Ethereum',
        address,
        balance: ERC20ToFloat(await getEtherBalance(internalAddress, web3React), 18, Web3BN),
        decimals: 18,
        price: (await getToken(WRAPPED_ETH_ADDRESS)).data.usdt_price,
      })
    } else {
      const { data } = await getToken(address)

      setFirstToken({
        ...firstToken,
        symbol: data?.symbol,
        name: data?.name,
        address,
        balance: await convertToExactBalance(address, data?.decimals),
        decimals: data?.decimals,
        price: parseFloat(data?.usdt_price),
      })
    }
  }, [web3React, firstToken, internalAddress, Web3BN, convertToExactBalance])

  const setDownTokenByAddress = useCallback(async (token) => {
    let getAddress = ''

    if (typeof token === 'string') {
      getAddress = token
    }
    if (token?.address) {
      getAddress = token.address
    }

    const address = web3React.library.utils.toChecksumAddress(getAddress)

    if (address === ZERO_ADDRESS || address === NEW_ETH_ADDRESS) {
      setSecondToken({
        ...secondToken,
        symbol: 'ETH',
        name: 'Ethereum',
        address,
        balance: ERC20ToFloat(await getEtherBalance(internalAddress, web3React), 18, Web3BN),
        decimals: 18,
        price: (await getTokenPrice(WRAPPED_ETH_ADDRESS)).price,
      })
    } else {
      const { data } = await getToken(address)
      setSecondToken({
        ...secondToken,
        symbol: data?.symbol,
        name: data?.name,
        address,
        balance: await convertToExactBalance(token.address, data?.decimals),
        decimals: data?.decimals,
        price: parseFloat(data?.usdt_price),
      })
    }
  }, [web3React, secondToken, internalAddress, Web3BN, convertToExactBalance])

  const handleGetData0x = useCallback(async (newValue) => {
    if (
      !web3React.library?.utils?.isAddress(firstToken.address)
      || !web3React.library?.utils?.isAddress(secondToken.address)) {
      return
    }

    setIsLoadingCreateOrder(true)

    try {
      // 0x requirement
      const getPureEthAddress = (address) => {
        if (address === '0x0000000000000000000000000000000000000000') return NEW_ETH_ADDRESS
        return convertETHToWETH(address)
      }
      const firstAddress = getPureEthAddress(firstToken.address)
      const secondAddress = getPureEthAddress(secondToken.address)
      const amount = await balanceToERC20(firstToken.address, newValue, web3React)
      const { data } = await zeroX({
        sell: firstAddress,
        buy: secondAddress,
        amount,
        cyberWallet: internalSelectedAddress,
      })

      setDataZeroX(data)
      setIsLoadingCreateOrder(false)
    } catch (e) {
      console.error('0x', e)
    }
  }, [firstToken, secondToken, web3React, internalSelectedAddress])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGetData0xWithDebounce = useCallback(debounce(async (newValue) => handleGetData0x(newValue), 1000), [handleGetData0x])
  // Получаем все токены, которые можем выбрать и преобразовываем
  const getAllTokens = useCallback(async (search, limit, page, isNotInclude = undefined) => {
    const listOfTokens = await tokensFilter(search, limit, page)

    let formatData = listOfTokens.data.results.map((serverData) => (
      serverData && {
        address: serverData.address,
        link: `https://${getLinkBasedOnCurrentNetwork()}/token/${serverData.address}`,
        value: serverData.symbol,
        symbol: serverData.symbol,
      }
    ))

    formatData = formatData.filter((el) => el.address !== isNotInclude)

    return { ...listOfTokens.data, data: formatData }
  }, [])
  // Поиск токенов по значению
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeUpperValue = useCallback(debounce(async (newValue) => {
    setIsLoading(true)
    setUpperInputSearch(newValue)
    let newList = []
    const { availableTokens } = tradingView

    let resultAllTokens = {}

    newList = newList.concat(availableTokens.map((item) => ({
      value: item.value,
      symbol: item.value,
      link: `https://${getLinkBasedOnCurrentNetwork()}/token/${item.address}`,
      address: item.address,
    })))
    try {
      resultAllTokens = await getAllTokens(newValue, 10, 1)
    } catch (e) {
      console.error('loadingUpperTokensList getAllTokens', e)
    }

    resultAllTokens.data.forEach((item) => {
      const isFindToken = find(availableTokens, { address: item.address })

      if (!isFindToken) {
        newList.push(item)
      }
    })
    newList = newList.filter((el) => el.address !== firstToken.address)
    setUpperTokensList(newList)
    setIsLoading(false)
  }, 1000), [tradingView.availableTokens, firstToken])
  // Поиск токенов по значению
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDownValue = useCallback(debounce(async (newValue) => {
    if (!trigger) {
      setIsLoading(true)
      setDownInputSearch(newValue)

      try {
        const { data } = await getAllTokens(newValue, 10, 1, secondToken.address)
        setLowerTokensList(data)
      } catch (e) {
        console.error('handleChangeDownValue', e)
      }

      setIsLoading(false)
    }
  }, 1000), [downCurrentPage, trigger])

  // Получаем все имеющиеся токены
  const loadingUpperTokensList = useCallback(async () => {
    setIsLoading(true)

    let newList = [{
      value: firstToken.symbol,
      symbol: firstToken.symbol,
      link: `https://${getLinkBasedOnCurrentNetwork()}/token/${firstToken.address}`,
      address: firstToken.address,
    }]
    const { availableTokens } = tradingView
    let resultAllTokens = { data: [] }

    newList = newList.concat(availableTokens.map((item) => ({
      value: item.value,
      symbol: item.value,
      link: `https://${getLinkBasedOnCurrentNetwork()}/token/${item.address}`,
      address: item.address,
    })))
    try {
      resultAllTokens = await getAllTokens('', 10, 1)
    } catch (e) {
      console.error('loadingUpperTokensList getAllTokens', e)
    }

    resultAllTokens.data.forEach((item) => {
      const isFindToken = find(availableTokens, { address: item.address })

      if (!isFindToken) {
        newList.push(item)
      }
    })
    newList = newList.filter((el) => el.address !== firstToken.address)
    setUpperTokensList(newList)
    setIsLoading(false)
  }, [firstToken.address, firstToken.symbol, getAllTokens, tradingView])

  // Получаем все имеющиеся токены
  const loadingDownTokensList = useCallback(async () => {
    setDownCurrentPage(1)
    setDownInputSearch('')
    setDownTokensListInfinityScrollHasMore(true)

    if (trigger) {
      setLowerTokensList(triggerListTokens.map((serverData) => (
        serverData && {
          address: serverData.token2.address,
          link: `https://${getLinkBasedOnCurrentNetwork()}/token/${serverData.token2.address}`,
          value: serverData.token2.symbol,
          symbol: serverData.token2.symbol,
        }
      )).filter((el) => el.address !== secondToken.address))
    }

    if (!trigger) {
      setIsLoading(true)

      const listOfTokens = await getAllTokens('', 10, 1, secondToken.address)

      setLowerTokensList(listOfTokens.data)

      setDownTokensListInfinityScrollHasMore(!!listOfTokens.next)
      setIsLoading(false)
    }
  }, [getAllTokens, secondToken.address, trigger, triggerListTokens])

  const infinityScrollUpperTokensList = useCallback(async () => {
    const listOTokens = await getAllTokens(upperInputSearch, 10, upperCurrentPage + 1)
    const { availableTokens } = tradingView
    const newList = []

    listOTokens.data.forEach((item) => {
      const isFindToken = find(availableTokens, { address: item.address })

      if (!isFindToken) {
        newList.push(item)
      }
    })

    setUpperTokensList(upperTokensList.concat(newList).filter((el) => el.address !== firstToken.address))

    setUpperTokensListInfinityScrollHasMore(!!listOTokens.next)
    setUpperCurrentPage(upperCurrentPage + 1)
  }, [getAllTokens, upperInputSearch, upperCurrentPage, tradingView, upperTokensList, firstToken.address])
  const infinityScrollDownTokensList = useCallback(async () => {
    if (!trigger) {
      const listOTokens = await tokensFilter(downInputSearch, downLimitPage, downCurrentPage + 1)

      setLowerTokensList(lowerTokensList.concat(listOTokens.data.results.map((serverData) => (
        serverData && {
          address: serverData.address,
          link: `https://${getLinkBasedOnCurrentNetwork()}/token/${serverData.address}`,
          value: serverData.symbol,
          symbol: serverData.symbol,
        }
      )).filter((el) => el.address !== secondToken.address)))

      setDownTokensListInfinityScrollHasMore(!!listOTokens.data.next)
      setDownCurrentPage(downCurrentPage + 1)
    }
  }, [trigger, downInputSearch, downLimitPage, downCurrentPage, lowerTokensList, secondToken.address])

  const handleCloseOpen = useCallback(() => {
    setPriceOpen(!priceOpen)
  }, [priceOpen])

  const handleSetMaxBalance = useCallback(() => {
    setUpperPriceValue(firstToken.balance)
    handleGetData0xWithDebounce(firstToken.balance)
  }, [firstToken.balance, handleGetData0xWithDebounce])

  const getInfoToken = useCallback(async (address) => {
    const { data } = await getToken(address)
    return data
  }, [])

  useEffect(() => {
    const value = new BigNumber(upperPriceValue)
    const price = new BigNumber(triggerPrice && trigger ? triggerPrice : pairPrice)
    setDownPriceValue(value.multipliedBy(price).toString())
  }, [upperPriceValue, triggerPrice, trigger, pairPrice, tokensReversed])

  useEffect(() => {
    if (secondToken.price === 0) {
      setPairPrice(0)
    }
    if (secondToken.price !== 0) {
      const newValue = parseFloat(formatEth(firstToken.price / secondToken.price))

      setPairPrice(newValue)
    }
  }, [firstToken.price, secondToken.price])

  // Token swapping
  useEffect(() => {
    if (tokensReversed) {
      const firstTokenCache = firstToken

      setFirstToken(secondToken)
      setSecondToken(firstTokenCache)
      setPairPrice(1 / pairPrice)

      setTriggerPrice(0)
      dispatch(reverseTokens())
    }
  }, [dispatch, firstToken, pairPrice, secondToken, tokensReversed])

  useEffect(() => {
    if (isFirstSetOrder && Array.isArray(lpPairs) && lpPairs.length > 0) {
      setTriggerLpPair(lpPairs[0])
      setIsFirstSetOrder(false)
    }
  }, [lpPairs, isFirstSetOrder])

  useEffect(() => {
    let isMounted = true

    const asyncSetLpPairs = async () => {
      const pairs = await allLPTokens()

      if (isMounted) {
        setLPPairs(pairs)
      }
    }

    asyncSetLpPairs()
    loadingUpperTokensList()
    return () => { isMounted = false }
  }, [loadingUpperTokensList])

  useEffect(() => {
    setInternalAddress(internalSelectedAddress)
  }, [internalSelectedAddress])

  useEffect(() => {
    if (
      web3React.library?.utils
      && tokenInitState.address !== firstToken.address
      && tokenInitState.address !== secondToken.address) {
      setLoadingContainer(false)
    }
    if (!web3React.library?.utils
      || tokenInitState.address === firstToken.address
      || tokenInitState.address === secondToken.address) {
      setLoadingContainer(true)
    }
  }, [firstToken.address, secondToken.address, web3React])

  useEffect(() => {
    (async () => {
      if (trigger && !oldStateTrigger.current) {
        setLoadingContainer(true)

        try {
          const { data } = await getLpPair(firstToken.address === ZERO_ADDRESS ? WRAPPED_ETH_ADDRESS : firstToken.address)
          const findSecondToken = find(data, { token2: { address: secondToken.address } })
          setTriggerListTokens(data)

          if (Array.isArray(data) && !findSecondToken && data[0].token2.address) {
            const dataGetInfoLpPair = await getInfoLpPair(data[0].address)

            setTriggerLpPair({
              price: dataGetInfoLpPair.data.price,
              address: dataGetInfoLpPair.data.address,
            })
            dispatch(setToTrade(data[0].token2.address))
            setDownTokenByAddress(data[0].token2.address)
          } else if (findSecondToken) {
            const dataGetInfoLpPair = await getInfoLpPair(findSecondToken.address)

            setTriggerLpPair({
              price: dataGetInfoLpPair.data.price,
              address: dataGetInfoLpPair.data.address,
            })
          }
        } catch (e) {
          console.error('getLpPair', e)
        }

        setLoadingContainer(false)
        oldStateTrigger.current = true
      }
    })()
  }, [trigger, firstToken.address, setDownTokenByAddress, dispatch, pairPrice, secondToken.address])

  // Обновляем цену triggerPrice, если выбрали другую пару
  useEffect(() => {
    if (trigger) {
      setTriggerPrice(pairPrice)
    }
    if (!trigger) {
      setTriggerPrice(0)
    }
  }, [pairPrice, firstToken.address, secondToken.address, trigger])

  // Сбрасываем состаяние у oldStateTrigger
  useEffect(() => {
    if (!trigger && oldStateTrigger.current) {
      oldStateTrigger.current = false
    }
  }, [trigger])

  useEffect(() => {
    (async () => {
      const selectedPair = find(
        triggerListTokens,
        {
          token1: {
            address: firstToken.address === ZERO_ADDRESS ? WRAPPED_ETH_ADDRESS : firstToken.address,
          },
          token2: {
            address: secondToken.address,
          },
        },
      )

      if (selectedPair && selectedPair.address) {
        const { data } = await getInfoLpPair(selectedPair.address)

        dispatch(setToTrade(secondToken.address))
        setDownTokenByAddress(secondToken.address)
        setTriggerLpPair({
          price: data.price,
          address: selectedPair.address,
        })
      }
    })()
  }, [dispatch, firstToken.address, secondToken.address, setDownTokenByAddress, triggerListTokens])

  useEffect(() => {
    if (!triggerPrice) {
      setTriggerPrice(checkFloatNaN(pairPrice, 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pairPrice])

  // Отрабатывает при загрузке стр и при смене токена
  useEffect(() => {
    (async () => {
      const checkFirstToken = firstToken.address !== tokenInitState.address
      const checkSecondToken = secondToken.address !== tokenInitState.address
      const checkOldFirstToken = (oldStateFirstTokenAddress.current !== firstToken.address || !oldStateFirstTokenAddress.current)
      const checkOldSecondToken = (oldStateSecondTokenAddress.current !== secondToken.address || !oldStateSecondTokenAddress.current)

      if (
        isFirstGet0x
        && checkFirstToken
        && checkSecondToken
        && (checkOldFirstToken || checkOldSecondToken)
      ) {
        await handleGetData0xWithDebounce(upperPriceValue)

        oldStateFirstTokenAddress.current = firstToken.address
        oldStateSecondTokenAddress.current = secondToken.address
        setIsFirstGet0x(false)
      }
    })()
  }, [firstToken, handleGetData0xWithDebounce, isFirstGet0x, secondToken, upperPriceValue, dataZeroX])

  useEffect(() => {
    if (
      (oldStateFirstTokenAddress.current !== firstToken.address || !oldStateFirstTokenAddress.current)
      || (oldStateSecondTokenAddress.current !== secondToken.address || !oldStateSecondTokenAddress.current)
    ) {
      setIsFirstGet0x(true)
    }
  }, [firstToken.address, secondToken.address])

  // Обработка ошибки и состояние disabled у Create Order
  useEffect(() => {
    (async () => {
      const tokenBalance = new BigNumber(firstToken.balance)
      const bnUpperPrice = new BigNumber(upperPriceValue)

      if (tokenBalance.lt(bnUpperPrice)) {
        setIsBtnDisabled(true)
        setObjError({
          msg: `Insufficient ${firstToken.symbol} balance`,
          error: true,
        })
      } else {
        setIsBtnDisabled(false)
        setObjError({
          msg: '',
        })
      }
    })()
  }, [firstToken, upperPriceValue])

  // Поиск пары по токенам
  useEffect(() => {
    (async () => {
      if (isHandleChangeToken) {
        const isFirstAddress = web3React.library?.utils?.isAddress(firstToken.address)
        const isSecondAddress = web3React.library?.utils?.isAddress(secondToken.address)

        if (web3React.library?.utils?.toChecksumAddress && isFirstAddress && isSecondAddress && dispatch) {
          if (firstToken.address !== secondToken.address) {
            try {
              const { data } = await findLpTokens({
                address1: web3React.library.utils.toChecksumAddress(firstToken.address),
                address2: web3React.library.utils.toChecksumAddress(secondToken.address),
              })
              const newChartToken = web3React.library.utils.toChecksumAddress(data[0]?.address)
              const toChecksumAddressChartToken = web3React.library.utils.toChecksumAddress(tradingView.chartToken)

              if (newChartToken && newChartToken !== toChecksumAddressChartToken) {
                await addLpTokenToStore(newChartToken, web3React, dispatch)
              }
            } catch (e) {
              console.error('findLp', e)
            }
          }
        }
      }
    }
    )()
  }, [
    tradingView.chartToken,
    firstToken.address,
    secondToken.address,
    dispatch,
    isHandleChangeToken,
    web3React,
  ])

  useEffect(() => {
    (async () => {
      if (web3React.library?.utils) {
        setIsHandleChangeToken(false)
        const token0Address = await getTokenExactData(tradingView.chartToken, 'token0', web3React)
        const token1Address = await getTokenExactData(tradingView.chartToken, 'token1', web3React)

        if (
          oldChartToken.current === tradingView.chartToken
          || firstToken.address === token1Address
          || secondToken.address === token0Address
        ) {
          return
        }

        try {
          const dataToken0 = await getInfoToken(token0Address)

          setFirstToken({
            symbol: dataToken0?.symbol,
            name: dataToken0?.name,
            address: token0Address,
            balance: await convertToExactBalance(token0Address, dataToken0?.decimals),
            decimals: dataToken0.decimals,
            price: parseFloat(dataToken0?.usdt_price),
          })
        } catch (e) {
          console.error(e)
        }

        try {
          if (!tradingView.isRedirectedByTopAsset) {
            const dataToken1 = await getInfoToken(token1Address)
            setSecondToken({
              symbol: dataToken1?.symbol,
              name: dataToken1?.name,
              address: token1Address,
              balance: await convertToExactBalance(token1Address, dataToken1?.decimals),
              decimals: dataToken1.decimals,
              price: parseFloat(dataToken1?.usdt_price),
            })
          }
        } catch (e) {
          console.error(e)
        }

        oldChartToken.current = tradingView.chartToken
      }
    })()
  }, [
    web3React,
    tradingView,
    internalAddress,
    getInfoToken,
    convertToExactBalance,
    firstToken.address,
    secondToken.address,
  ])

  const stopPollingForChosenTokensData = useCallback(() => {
    if (tokensDataPoller.current) {
      clearInterval(tokensDataPoller.current)
      tokensDataPoller.current = undefined
      isFirstTokenDataRequestPending.current = false
      isSecondTokenDataRequestPending.current = false
    }
  }, [])

  const getActualBalance = useCallback(async (address, decimals) => (
    ![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(address)
      ? convertToExactBalance(address, decimals)
      : ERC20ToFloat(await getEtherBalance(internalAddress, web3React), 18, Web3BN)
  ), [Web3BN, convertToExactBalance, internalAddress, web3React])

  const getActualPrice = useCallback(async (address) => {
    if ([ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(address)) {
      return (await getTokenPrice(WRAPPED_ETH_ADDRESS)).price
    }

    const { data } = await getToken(address)
    if (data) {
      return data.usdt_price
    }

    return false
  }, [])

  const getUpdatedTokenData = useCallback(async (address, decimals) => {
    const actualBalance = await getActualBalance(address, decimals)
    const actualPrice = await getActualPrice(address)
    if (actualBalance && (actualPrice === 0 || actualPrice)) {
      return {
        balance: actualBalance,
        price: actualPrice,
      }
    }
    return false
  }, [getActualBalance, getActualPrice])

  const updateFirstToken = useCallback(async () => {
    if (!isFirstTokenDataRequestPending.current) {
      isFirstTokenDataRequestPending.current = true
      const updatedData = await getUpdatedTokenData(firstToken.address, firstToken.decimals)
      if (updatedData) {
        setFirstToken({
          ...firstToken,
          ...updatedData,
        })
        isFirstTokenDataRequestPending.current = false
      }
    }
  }, [getUpdatedTokenData, firstToken])

  const updateSecondToken = useCallback(async () => {
    if (!isSecondTokenDataRequestPending.current) {
      isSecondTokenDataRequestPending.current = true
      const updatedData = await getUpdatedTokenData(secondToken.address, secondToken.decimals)
      if (updatedData) {
        setSecondToken({
          ...secondToken,
          ...updatedData,
        })
        isSecondTokenDataRequestPending.current = false
      }
    }
  }, [getUpdatedTokenData, secondToken])

  useEffect(() => {
    (async () => {
      if (internalAddress && tradingView.isTopAsset) {
        dispatch(toggleTopAsset(false))
        setUpperTokenByAddress(tradingView.currentAsset
          ? { address: tradingView.currentAsset }
          : { address: ZERO_ADDRESS })
      }
    })()
  }, [tradingView.currentAsset, internalAddress, setUpperTokenByAddress, tradingView.isTopAsset, dispatch])

  useEffect(() => {
    (async () => {
      if (internalAddress && tradingView.isTopAddress) {
        dispatch(toggleTopAddress(false))
        setDownTokenByAddress(tradingView.currentToTrade
          ? { address: tradingView.currentToTrade }
          : { address: '0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4' })
      }
    })()
  }, [tradingView.currentToTrade, internalAddress, setDownTokenByAddress, tradingView.isTopAddress, dispatch])

  const startPollingForChosenTokensData = useCallback(() => {
    if (!tokensDataPoller.current) {
      const intervalTime = 10000
      tokensDataPoller.current = setInterval(async () => {
        await updateFirstToken()
        await updateSecondToken()
      }, intervalTime)
    }
  }, [updateFirstToken, updateSecondToken])

  useEffect(() => {
    startPollingForChosenTokensData()
    return () => stopPollingForChosenTokensData()
  }, [startPollingForChosenTokensData, stopPollingForChosenTokensData])

  useEffect(() => {
    if (userState.errors.trade) {
      setObjError({
        error: true,
        msg: userState.errors.trade,
      })
    } else {
      setObjError({})
    }
  }, [userState.errors.trade])

  return (
    <>
      {loadingContainer && (
        <div className="exchange-create-order-form__loading">
          <Loading />
        </div>
      )}
      {settingsContainer && isNewDesign && (
      <div className="exchange-create-order-form__settings">
        <Text
          text={(
            <div>
              Gas Price
              <HelpOutlineIcon
                style={{
                  color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                }}
              />
            </div>
          )}
        />
        <div className="exchange-create-order-form__settings_item">
          <Btn
            mod="btn--gas"
            text="42 GWEI ($12.69)"
          />
          <Btn
            mod="btn--gas"
            text="36 GWEI ($10.88)"
          />
        </div>
        <Text
          text={(
            <div>
              Price Slippage
              <HelpOutlineIcon
                style={{
                  color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                }}
              />
            </div>
          )}
        />
        <div className="exchange-create-order-form__settings_item">
          <Btn
            mod="btn--slippage"
            text="1%"
          />
          <Btn
            mod="btn--slippage"
            text="3%"
          />
          <input
            // onBlur={onBlur}
            // onChange={onChange}
            // value={value}
            // type={inputType}
            className="input--slippage"
            placeholder="0"
            // disabled={disabled}
          />
        </div>
      </div>
      )}
      <form className={`exchange-create-order-form ${className || ''}`} action="" method="post">
        <div className="exchange-create-order-form__item">
          <div className="exchange-create-order-block__header">
            <div className="exchange-create-order-block__header-left">
              <Text
                text="You Pay"
              />
            </div>
            <div className="exchange-create-order-block__header-right">
              <Text
                text={`Balance: ${firstToken.balance}`}
              />
              {isNewDesign && (
                <>
                  <ErrorIcon className="header-right_icon-error" />
                  <WarningRoundedIcon className="header-right_icon-warning" />
                </>
              )}
            </div>
          </div>
          <ExchangeItem
            isMax
            funcInfinityScroll={infinityScrollUpperTokensList}
            infinityScrollHasMore={upperTokensListInfinityScrollHasMore}
            chipOnClick={handleSetMaxBalance}
            price={checkFloatNaN((firstToken.price * upperPriceValue).toFixed(2), 0)}
            priceValue={upperPriceValue}
            priceOnChange={(e) => {
              let newValue = e.target.value

              if (Number.isNaN(newValue)) {
                newValue = 0
              }
              setUpperPriceValue(newValue)

              handleGetData0xWithDebounce(newValue)
            }}
            isLoading={isLoading}
            funcLoadItems={loadingUpperTokensList}
            inputValue={upperInputValue}
            firstTokenAddress={firstToken.address}
            selectedValue={firstToken.symbol}
            dropDownItems={upperTokensList}
            textLink={firstToken.name}
            link={`https://${getLinkBasedOnCurrentNetwork()}/token/${firstToken.address}`}
            callback={(data) => {
              setIsHandleChangeToken(true)
              setUpperTokenByAddress(data)
              dispatch(setAsset(data.address))

              if (data.address === secondToken.address) {
                if (secondToken.address !== USDT_ADDRESS) {
                  dispatch(setToTrade(USDT_ADDRESS))
                }
                if (secondToken.address === USDT_ADDRESS) {
                  dispatch(setToTrade(ZERO_ADDRESS))
                }
              }
              setIsFirstGet0x(true)
            }}
            onChange={(e) => {
              if (e?.target?.value) {
                handleChangeUpperValue(e?.target?.value)
                setUpperInputValue(e?.target?.value)
              } else {
                setUpperInputValue('')
                handleChangeUpperValue('')
              }
            }}
          />
        </div>

        {trigger && (
        <div className="exchange-create-order-form__item">
          <AssetField
            inputType="number"
            value={triggerPrice}
            name={(
              <div>
                Limit Price
                <HelpOutlineIcon
                  style={{
                    color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                  }}
                />
              </div>
            )}
            onBlur={() => {
              if (triggerPrice <= 0) {
                setTriggerPrice(checkFloatNaN(pairPrice, 0))
              }
            }}
            onChange={(event) => {
              setTriggerPrice(event.target.value)
            }}
            asset={secondToken.symbol}
            placeholder="0"
          />
        </div>
        )}
        {LP && isNewDesign && (
        <div className="exchange-create-order-form__item">
          <AssetField
            inputType="number"
            value={triggerPrice}
            name={(
              <div>
                Total Liquidity
                <HelpOutlineIcon style={{
                  color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                }}
                />
              </div>
            )}
            onBlur={() => {
              if (triggerPrice <= 0) {
                setTriggerPrice(checkFloatNaN(pairPrice, 0))
              }
            }}
            onChange={(event) => {
              setTriggerPrice(event.target.value)
            }}
            asset="$"
            placeholder="0"
          />

          <div className="exchange-create-order-form__current-liquidity">
            <Text
              text="Current Liquidity: $0"
            />
          </div>
        </div>
        )}
        {MC && isNewDesign && (
        <div className="exchange-create-order-form__item">
          <AssetField
            inputType="number"
            value={triggerPrice}
            name={(
              <div>
                Total Market Cap
                <HelpOutlineIcon style={{
                  color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                }}
                />
              </div>
            )}
            onBlur={() => {
              if (triggerPrice <= 0) {
                setTriggerPrice(checkFloatNaN(pairPrice, 0))
              }
            }}
            onChange={(event) => {
              setTriggerPrice(event.target.value)
            }}
            asset="$"
            placeholder="0"
          />

          <div className="exchange-create-order-form__current-mc">
            <Text
              text="Current Market Cap: $0"
            />
          </div>
        </div>
        )}
        {DCA && isNewDesign && (
        <div className="exchange-create-order-form__item">
          <AssetField
            inputType="number"
            value={triggerPrice}
            name={(
              <div>
                DCA Total Budget
                <HelpOutlineIcon style={{
                  color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                }}
                />
              </div>
            )}
            onBlur={() => {
              if (triggerPrice <= 0) {
                setTriggerPrice(checkFloatNaN(pairPrice, 0))
              }
            }}
            onChange={(event) => {
              setTriggerPrice(event.target.value)
            }}
            asset={firstToken.symbol}
            placeholder="0"
          />
          <div className="exchange-create-order-form__dca-budget">
            <AssetField
              inputType="number"
              value={triggerPrice}
              name={(
                <div>
                  Budget Per Order
                  <HelpOutlineIcon style={{
                    color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                  }}
                  />
                </div>
              )}
              onBlur={() => {
                if (triggerPrice <= 0) {
                  setTriggerPrice(checkFloatNaN(pairPrice, 0))
                }
              }}
              onChange={(event) => {
                setTriggerPrice(event.target.value)
              }}
              asset={firstToken.symbol}
              placeholder="0"
            />
            <div className="exchange-create-order-form__dca-execute">
              <Text
                text="Execute Every"
              />
              <SelectBase
                  // selected={{ name: rowsPerPage?.value ? rowsPerPage.value : rowsPerPage }}
                  // onChange={onChangeRowsPerPage}
                className="select-base--new"
                headerIconMaterial
                items={[{ name: '1 hour', value: 1, icon: <Icon name={IconSelectCircleActive} id="icon--select-circle-active" /> },
                  { name: '6 hours', value: 6, icon: <Icon name={IconSelectCircle} id="icon--select-circle" /> },
                  { name: '12 hours', value: '12 hours', icon: <Icon name={IconSelectCircle} id="icon--select-circle" /> },
                  { name: '1 day', value: '1 day', icon: <Icon name={IconSelectCircle} id="icon--select-circle" /> },
                  { name: '1 week', value: '1 week', icon: <Icon name={IconSelectCircle} id="icon--select-circle" /> },
                ]}
              />
            </div>
          </div>
          <div className={`exchange-create-order-form__dca-price ${className || ''} ${priceOpen ? 'exchange-create-order-form__dca-price--open' : ''}`}>
            <div className="dca-price_limit">
              <AssetField
                inputType="number"
                value={triggerPrice}
                name={(
                  <div>
                    Limit Price
                    <HelpOutlineIcon style={{
                      color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                    }}
                    />
                  </div>
                )}
                onBlur={() => {
                  if (triggerPrice <= 0) {
                    setTriggerPrice(checkFloatNaN(pairPrice, 0))
                  }
                }}
                onChange={(event) => {
                  setTriggerPrice(event.target.value)
                }}
                asset={firstToken.symbol}
                placeholder="0"
              />
            </div>
            <div className="dca-price_gas">
              <AssetField
                inputType="number"
                value={triggerPrice}
                name={(
                  <div>
                    Gas Price
                    <HelpOutlineIcon style={{
                      color: 'rgba(255, 255, 255, 0.24)', fontSize: '18px', position: 'absolute', marginLeft: '10px',
                    }}
                    />
                  </div>
                )}
                onBlur={() => {
                  if (triggerPrice <= 0) {
                    setTriggerPrice(checkFloatNaN(pairPrice, 0))
                  }
                }}
                onChange={(event) => {
                  setTriggerPrice(event.target.value)
                }}
                asset="$"
                placeholder="0"
              />
            </div>
          </div>
          <div className={`exchange-create-order-form__dca-price-btn ${className || ''} ${priceOpen ? 'exchange-create-order-form__dca-price-btn--open' : ''}`} onClick={handleCloseOpen}>
            <Btn
              onClick={handleCloseOpen}
              mod="btn--icon wip-balance__btn"
              iconLeft={(
                <Icon
                  name={IconCheckBtn2}
                  id="icon--check-btn-2"
                />
                )}
            />
            { priceOpen ? <Text text="Close" /> : <Text text="Limit & Gas Price" /> }
          </div>
        </div>
        )}

        <div className="exchange-create-order-form__switsh">
          <Btn
            mod="btn--icon"
            onClick={reverseTokensOnTrading}
            iconLeft={(
              <SwapVertRoundedIcon />
                        )}
          />
        </div>

        <div className="exchange-create-order-form__item">
          <div className="exchange-create-order-block__header">
            <div className="exchange-create-order-block__header-left">
              <Text
                text="You Receive"
              />
            </div>
          </div>

          <ExchangeItem
            funcInfinityScroll={infinityScrollDownTokensList}
            infinityScrollHasMore={downTokensListInfinityScrollHasMore}
            isInputDisabled
            price={checkFloatNaN((secondToken.price * downPriceValue).toFixed(2), 0)}
            priceOnChange={(e) => {
              let newValue = parseFloat(e.target.value)

              if (Number.isNaN(newValue)) {
                newValue = 0
              }
              if (!Number.isNaN(newValue)) {
                parseFloat(formatEth(newValue))
              }

              setDownPriceValue(newValue)
            }}
            priceValue={downPriceValue}
            isLoading={isLoading}
            funcLoadItems={loadingDownTokensList}
            inputValue={downInputValue}
            callback={(data) => {
              setIsHandleChangeToken(true)
              setDownTokenByAddress(data.address)
              setIsFirstGet0x(true)
            }}
            firstTokenAddress={secondToken.address}
            selectedValue={secondToken.symbol}
            dropDownItems={lowerTokensList}
            textLink={secondToken.name}
            link={`https://${getLinkBasedOnCurrentNetwork()}/token/${secondToken.address}`}
            onChange={(e) => {
              if (e?.target?.value) {
                handleChangeDownValue(e.target.value)
                setDownInputValue(e.target.value)
              } else {
                handleChangeDownValue('')
                setDownInputValue('')
              }
            }}
          />
        </div>

        {market && (
        <AssetInfo detail={<List noBorder items={marketItems} />} />
        )}
        {!market && dataZeroX.sources && dataZeroX.sources.length > 0 && (
        <div className="exchange-create-order-form__item">
          <div className="exchange-create-order-block__footer">
            <div className="exchange-create-order-block__footer-info">
              <span>Offered by</span>
              <div className="exchange-create-order-block__footer-info-link">
                <Text text={dataZeroX.sources[0].name} />
              </div>
            </div>
          </div>
        </div>
        )}
        <div className="exchange-create-order-form__item">
          <BtnFormBig
            isDisabled={isLoadingCreateOrder || isBtnDisabled}
            testId="btn-create-order-trade"
            className="btn-form-big--pr-sm"
            click={sendTokenSwapOrder}
            text={isLoadingCreateOrder ? <Loading /> : (
              <div className="exchange-create-order-form__item__text-btn">
                CREATE ORDER
                {' '}
                {userState.isGodModeEnabled && (
                  <Icon
                    mod="icon--14"
                    name={IconAllInclusive}
                    id="icon--all-inclusive"
                  />
                )}
              </div>
            )}
          />
        </div>

        {(objError.warning || objError.error || objError.assistive) && (
          <div className="exchange-create-order-form__item">
            <Alert
              warning={objError.warning}
              error={objError.error}
              assistive={objError.assistive}
              size="s"
            >
              {objError.msg}
            </Alert>
          </div>
        )}
      </form>
    </>
  )
}

ExchangeCreateOrderFormNew.propTypes = {
  className: PropTypes.string,
  market: PropTypes.bool,
  trigger: PropTypes.bool,
  LP: PropTypes.bool,
  MC: PropTypes.bool,
  DCA: PropTypes.bool,
  settingsContainer: PropTypes.bool,
}
