import React from 'react'
import PropTypes from 'prop-types'
import './btn.scss'

export const Btn = ({
  text,
  mod,
  type,
  iconLeft,
  iconRight,
  onClick,
  disabled,
  dataTestid,
}) => {
  const handleOnClick = (e) => {
    e.stopPropagation()
    if (onClick) {
      onClick()
    }
  }

  return (
    <button
      data-testid={dataTestid}
      className={`btn ${mod || ''}`}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={handleOnClick}
      disabled={disabled}
    >
      {iconLeft}
      {text && <span className="btn__text">{text}</span>}
      {iconRight}
    </button>
  )
}

Btn.propTypes = {
  text: PropTypes.string,
  dataTestid: PropTypes.string,
  mod: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  iconLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  iconRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}
Btn.defaultProps = {
  text: '',
  mod: '',
  type: 'button',
  iconLeft: '',
  iconRight: '',
  onClick: () => {},
  disabled: false,
}
