import { createSlice } from '@reduxjs/toolkit'

export const lpTokens = createSlice({
  name: 'lp_tokens',
  initialState: {
    tokens: [],
  },
  reducers: {
    addLPToken(state, action) {
      return (state.tokens.len && state.tokens.filter((token) => token.address === action.payload.address).length) ? {
        ...state,
        tokens: state.tokens.map((token) => (token.address === action.payload.address ? action.payload : token)),
      } : {
        ...state,
        tokens: [...state.tokens, action.payload],
      }
    },
  },
})

export const { addLPToken } = lpTokens.actions
