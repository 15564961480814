import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import find from 'lodash/find'
import { useLocalStorage } from '@rehooks/local-storage'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded'
import ErrorIcon from '@material-ui/icons/Error'
import { BigNumber } from 'bignumber.js'
import { minABI } from '../../../../../../../../utils/contracts'
import { getDataSelectWallet } from '../../../../../../../../service/api'
import { Text } from '../../../../../../../../components/Text/Text'
import { Input } from '../../../../../../../../components/Input/Input'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../../components/BaseText/BaseText'
import ClickOutside from '../../../../../../../../components/ClickOutside/ClickOutside'
import { DropDown } from './drop-down/DropDown'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../../../../../../../../const'
import Loading from '../../../../../../../../components/Loading/Loading'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconSend from '../../../../../../../../components/Icon/icon--send.svg'
import { Divider } from '../../../../../../../../components/Divider/Divider'
import { Chip } from '../../../../../../../../components/Chip/Chip'
import { ExternalLink } from '../../../../../../../../components/ExternalLink/ExternalLink'
import {
  ERC20ToFloat,
  formatEth,
  checkFloatNaN,
  floatToERC20,
  weiToEth,
} from '../../../../../../../../utils/helperFunctions'
import { Alert } from '../../../../../../../../components/Alert/Alert'
import { getLinkBasedOnCurrentNetwork, getNativeTokenDataBasedOnNetwork } from '../../../../../../../../parser/data'
import { getExactTokenBalance } from '../../../../../../../../service/etherscanApi'
import { useWeb3 } from '../../../../../../../../context/web3Provider'
import { useWeb3Bn } from '../../../../../../../../hooks/web3'

import './transaction-creation-step.scss'

const TransactionCreationStep = ({
  chosenCyberWallet,
  onSetTransactionDetails,
  onSetValidation,
}) => {
  const web3React = useWeb3()
  const Web3BN = useWeb3Bn()

  const userState = useSelector((state) => state.user)
  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [address] = useState(find(userState.internalAddresses, { address: internalSelectedAddress }) || userState.internalAddresses[0])
  const [areTokensLoading, setTokensLoadingState] = useState(true)
  const [tokens, setTokens] = useState([])
  const [isFocused, setFocusState] = useState(false)
  const [selectedToken, setSelectedToken] = useState({})
  const [balance, setBalance] = useState(0)
  const [recipientAddress, setRecipientAddress] = useState('')
  const [upperPriceValue, setUpperPriceValue] = useState(0)
  const [isRecipientAddressChanged, setRecipientAddressChangedState] = useState(false)
  const [isInsufficientBalance, setInsufficientBalanceState] = useState(false)
  const [transactionError, setTransactionErrorState] = useState()
  const [isTotalBalanceLoading, setTotalBalanceIsLoading] = useState(true)

  useEffect(() => {
    let isMounted = true

    const getTokens = async () => {
      const currentAddress = chosenCyberWallet || address.address
      if (currentAddress) {
        try {
          const data = await getDataSelectWallet(currentAddress)
          setTokensLoadingState(false)
          if (isMounted) {
            setTokens(data.tokens)
            setSelectedToken(data.tokens[0])
          }
        } catch (e) {
          setTokensLoadingState(false)
          console.warn(e)
        }
      }
    }

    getTokens()

    return () => { isMounted = false }
  }, [address.address, chosenCyberWallet])

  const getTotalBalanceOfChosenToken = useCallback(async () => {
    if (![ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(selectedToken.address)) {
      const checkedAddress = web3React.library.utils.toChecksumAddress(selectedToken.address)
      return getExactTokenBalance({
        tokenAddress: checkedAddress,
        userAddress: internalSelectedAddress,
        decimals: selectedToken.contract_decimals || selectedToken.decimals,
        Web3BN,
      })
    }
    return weiToEth(web3React?.library.utils.fromWei, selectedToken.balance.toString())
  }, [selectedToken, Web3BN, internalSelectedAddress, web3React])

  useEffect(() => {
    (async () => {
      if (Object.keys(selectedToken).length > 0) {
        try {
          const totalBalance = await getTotalBalanceOfChosenToken()
          setBalance(totalBalance)
          setTotalBalanceIsLoading(false)
        } catch (e) {
          setTotalBalanceIsLoading(false)
        }
      }
    })()
  }, [getTotalBalanceOfChosenToken, selectedToken])

  const rightIcon = useMemo(() => (
    isFocused
      ? <ArrowDropUpRoundedIcon style={{ color: '#FFFFFFF5', fontSize: '20px' }} fontSize="small" />
      : <ArrowDropDownRoundedIcon style={{ color: '#FFFFFF7A', fontSize: '20px' }} fontSize="small" />
  ), [isFocused])

  const getSumInDollars = useCallback(() => {
    let sumInDollars = 0
    if (Object.keys(selectedToken).length > 0) {
      if (selectedToken.contract_address === ZERO_ADDRESS || selectedToken.contract_address === NEW_ETH_ADDRESS) {
        sumInDollars = checkFloatNaN(formatEth(upperPriceValue * userState.latestNativeTokenPrice), 0)
      } else {
        sumInDollars = formatEth(checkFloatNaN(formatEth(upperPriceValue * (selectedToken.price || 0)), 0), 2)
      }
    }
    return `$${sumInDollars}`
  }, [selectedToken, upperPriceValue, userState.latestNativeTokenPrice])

  const handleSelected = useCallback((select) => {
    setTotalBalanceIsLoading(true)
    setSelectedToken(select)
    setFocusState(false)
    setUpperPriceValue(0)
  }, [])

  const handleSetMaxBalance = useCallback(async () => {
    setUpperPriceValue(checkFloatNaN(await getTotalBalanceOfChosenToken(), 0))
  }, [getTotalBalanceOfChosenToken])

  const isNativeTokenBalanceEnough = useCallback(async () => {
    let isEnough = false
    let requiredGasInGwei = 0

    try {
      const assetValue = floatToERC20(selectedToken.contract_decimals, upperPriceValue)

      const contract = web3React.library.eth && new web3React.library.eth.Contract(minABI, selectedToken.contract)
      contract.options.address = selectedToken.contract_address
      const gasAmount = await contract.methods.transfer(recipientAddress, assetValue)
        .estimateGas({
          gas: '100000',
          from: chosenCyberWallet || address.address,
        })

      if (gasAmount < 5000000) {
        const requiredNativeTokenAmount = new BigNumber(gasAmount * 0.000000001)
        const nativeTokenErrorMessage = `Insufficient ${getNativeTokenDataBasedOnNetwork().tokenSymbol} balance`

        if (userState.tokens) {
          const nativeToken = userState.tokens.filter((token) => token.contract_address.toLocaleLowerCase() === NEW_ETH_ADDRESS)[0]

          if (nativeToken) {
            const currentNativeTokenBalance = (
              new BigNumber(ERC20ToFloat(new Web3BN(nativeToken.balance), nativeToken.contract_decimals, Web3BN))
            )
            const koef = selectedToken.contract_address.toLocaleLowerCase() !== NEW_ETH_ADDRESS ? 0 : new BigNumber(upperPriceValue)

            if (currentNativeTokenBalance.isGreaterThanOrEqualTo(requiredNativeTokenAmount.plus(koef))) {
              isEnough = true
              requiredGasInGwei = gasAmount
              setTransactionErrorState()
            } else {
              setTransactionErrorState(nativeTokenErrorMessage)
            }
          } else {
            setTransactionErrorState(nativeTokenErrorMessage)
          }
        } else {
          setTransactionErrorState(nativeTokenErrorMessage)
        }
      } else {
        setTransactionErrorState('Ran out of gas')
      }
    } catch (e) {
      console.error('error = ', e)
      isEnough = false
    }

    return {
      isEnough,
      requiredGasInGwei,
    }
  }, [
    selectedToken,
    recipientAddress,
    upperPriceValue,
    web3React,
    chosenCyberWallet,
    address.address,
    userState.tokens,
    Web3BN,
  ])

  useEffect(() => {
    (async () => {
      let requiredGasInGwei = 0
      let isValid = (
        selectedToken
        && upperPriceValue !== '0'
        && upperPriceValue > 0
        && selectedToken.balance !== 0
        && parseFloat(balance) >= checkFloatNaN(upperPriceValue, 0)
        && web3React.library?.utils?.isAddress(recipientAddress)
      )

      if (isValid && selectedToken.contract_address.toLocaleLowerCase() !== ZERO_ADDRESS) {
        const details = await isNativeTokenBalanceEnough()
        isValid = details.isEnough
        requiredGasInGwei = details.requiredGasInGwei
      }

      onSetValidation(isValid)
      if (isValid) {
        onSetTransactionDetails({
          selectedToken: {
            name: selectedToken.name || selectedToken.contract_name,
            logoUrl: selectedToken.logo_url || '',
            symbol: selectedToken.symbol || selectedToken.contract_ticker_symbol,
            contractAddress: selectedToken.contract_address,
            contractDecimals: selectedToken.contract_decimals,
            contract: selectedToken.contract,
          },
          sumInDollars: getSumInDollars(),
          recipientAddress,
          tokenValue: upperPriceValue,
          requiredGasInGwei,
        })
      }
    })()
  }, [
    recipientAddress,
    selectedToken,
    balance,
    upperPriceValue,
    onSetValidation,
    web3React,
    onSetTransactionDetails,
    getSumInDollars,
    isNativeTokenBalanceEnough,
  ])

  const getBody = useMemo(() => {
    const isAddressValid = web3React.library?.utils?.isAddress(recipientAddress)
    setInsufficientBalanceState(parseFloat(balance) < checkFloatNaN(upperPriceValue, 0) || selectedToken.balance === 0 || upperPriceValue === '0')

    return (
      (
        <div className="transfer-modal__transaction-details-block">
          <div className="details-item">
            <Text text="Enter Recipient Address" className="text--white" />
            <Input
              dataTestid="popup--transfer-input-recipient-address"
              value={recipientAddress}
              onChange={(e) => {
                setRecipientAddressChangedState(true)
                setRecipientAddress(e.target.value)
              }}
              type="text"
              name="form_name"
              min="4"
              max="12"
              placeholder="0x00000000000000000000..."
              done={isRecipientAddressChanged && isAddressValid}
            />
            {
              isRecipientAddressChanged && !isAddressValid && (
                <Text className="text--12 text--error" text="Please, input a valid recipient address" />
              )
            }
          </div>

          <div className="details-item">
            <div className="transfer-modal__transaction-details-block__top-block">
              <Text text="Choose Asset and Amount to Withdraw" className="text--white" />
              <div className="right-block">
                {
                  !isTotalBalanceLoading
                    ? (
                      <>
                        <Text
                          text={`Balance: ${balance} ${selectedToken ? (selectedToken.symbol || selectedToken.contract_ticker_symbol) : ''}`}
                          className="text--white"
                        />
                        {
                          isInsufficientBalance && (
                            <ErrorIcon className="insufficient-balance-icon" />
                          )
                        }
                      </>
                    )
                    : <Loading size="S" />
                }
              </div>
            </div>
            <div className="controls-block">
              <div className="top">
                {
                  selectedToken && (
                    <ExternalLink
                      text={selectedToken.name || selectedToken.contract_name}
                      link={`https://${getLinkBasedOnCurrentNetwork()}/token/${selectedToken.address}`}
                      hideLink={selectedToken.address === ZERO_ADDRESS}
                    />
                  )
                }
                <Text text={getSumInDollars()} />
              </div>
              <div className="bottom">
                <ClickOutside callback={() => setFocusState(false)}>
                  <div className="select-currency">
                    <div className="select-currency__body">
                      <div className="select-currency__header" onClick={() => setFocusState(!isFocused)}>
                        <div className="select-currency__header-img">
                          <img className="token-icon" src={selectedToken.icon} alt="selected-token-icon" />
                          <Text text={selectedToken.symbol || selectedToken.contract_ticker_symbol} />
                        </div>
                        <div className="select-currency__header-icon">
                          {rightIcon}
                        </div>
                      </div>
                      <DropDown
                        onClick={handleSelected}
                        items={tokens}
                        isOpen={isFocused}
                      />
                    </div>
                  </div>
                </ClickOutside>
                <div className="exchange-item__body-chip">
                  <Chip onClick={() => handleSetMaxBalance()}>MAX</Chip>
                </div>
                <Input
                  dataTestid="popup--transfer-input-amount-value"
                  type="number"
                  placeholder="0.00"
                  value={upperPriceValue <= 0 ? undefined : upperPriceValue}
                  onChange={(e) => {
                    let newValue = e.target.value
                    if (Number.isNaN(newValue)) {
                      newValue = 0
                    }
                    setUpperPriceValue(checkFloatNaN(parseFloat(newValue), 0))
                  }}
                />
              </div>
            </div>

            {isInsufficientBalance && (
              <Alert error>
                {parseFloat(upperPriceValue) === 0 ? 'Insufficient sum to withdraw' : 'Insufficient balance.'}
              </Alert>
            )}

            {!isInsufficientBalance && transactionError && (
              <Alert error>
                {transactionError}
              </Alert>
            )}
          </div>
        </div>
      )
    )
  }, [
    recipientAddress,
    selectedToken,
    tokens,
    isFocused,
    handleSelected,
    rightIcon,
    balance,
    handleSetMaxBalance,
    upperPriceValue,
    getSumInDollars,
    web3React,
    isRecipientAddressChanged,
    isInsufficientBalance,
    transactionError,
    isTotalBalanceLoading,
  ])

  return !areTokensLoading ? (
    <>
      <div className="transfer-modal__icon-block">
        <Icon
          name={IconSend}
          id="icon--send"
        />
      </div>
      <BaseTextHeading size="S">Setup Transfer</BaseTextHeading>
      <BaseTextBody size="S">The transaction cannot be canceled or refunded. Please carefully check the address to which you are sending funds.</BaseTextBody>
      <Divider />
      {getBody}
    </>
  ) : (
    <>
      <div className="transfer-modal__icon-block">
        <Loading />
      </div>

      <BaseTextHeading size="S"> Loading tokens </BaseTextHeading>
    </>
  )
}

TransactionCreationStep.propTypes = {
  chosenCyberWallet: PropTypes.string,
  onSetTransactionDetails: PropTypes.func,
  onSetValidation: PropTypes.func,
}

export default TransactionCreationStep
