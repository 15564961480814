import React from 'react'
import PropTypes from 'prop-types'
import OwnerWalletAccount from './owner-wallet-account/OwnerWalletAccount'

import './owner-wallet.scss'

const OwnerWallet = ({
  ownerAccounts,
  isOwnerAdressesListOpened,
  onToggleAddessesListOpenedState,
}) => (
  <div className="owner-wallet__container">
    <OwnerWalletAccount
      ownerAccounts={ownerAccounts}
      isOwnerAdressesListOpened={isOwnerAdressesListOpened}
      onToggleAddessesListOpenedState={onToggleAddessesListOpenedState}
    />
  </div>
)

OwnerWallet.propTypes = {
  ownerAccounts: PropTypes.array,
  isOwnerAdressesListOpened: PropTypes.bool,
  onToggleAddessesListOpenedState: PropTypes.func,
}

export default OwnerWallet
