import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import './status-badge.scss'
import Loading from '../Loading/Loading'
import IconPending from '../Icon/icon--pending.svg'
import IconCompleted from '../Icon/icon--completed.svg'
import IconCanceled from '../Icon/icon--canceled.svg'
import IconError from '../Icon/icon--error.svg'
import { Icon } from '../Icon/Icon'
import { BaseTextBody } from '../BaseText/BaseText'

export const StatusBadge = ({
  state,
  text,
  label,
  onClick,
}) => {
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick()
    }
  }, [onClick])

  const Content = useMemo(() => {
    let con = {
      status: text,
      label: false,
    }

    if (state === 'active') {
      con = {
        status: <BaseTextBody>{ text }</BaseTextBody>,
        label: false,
      }
    }

    if (state === 'pending') {
      con = {
        status: <Icon name={IconPending} id="icon--pending" />,
        label: text || 'Pending',
      }
    }

    if (state === 'loading') {
      con = {
        status: <Loading />,
        label: text || 'Loading',
      }
    }

    if (state === 'completed') {
      con = {
        status: <Icon name={IconCompleted} id="icon--completed" />,
        label: text || 'Completed',
      }
    }

    if (state === 'canceled') {
      con = {
        status: <Icon name={IconCanceled} id="icon--canceled" />,
        label: text || 'Canceled',
      }
    }

    if (state === 'error') {
      con = {
        status: <Icon name={IconError} id="icon--error" />,
        label: text || 'Error',
      }
    }

    return con
  }, [state, text])

  return (
    <div onClick={handleOnClick} className={`status-badge status-badge--${state || ''}`}>
      <div className="status-badge__body">
        {Content.status}
      </div>

      {label
        && (
        <div className="status-badge__label">
          <BaseTextBody>{Content.label}</BaseTextBody>
        </div>
        )}
    </div>
  )
}

StatusBadge.propTypes = {
  state: PropTypes.oneOf([
    'active',
    'pending',
    'loading',
    'completed',
    'canceled',
    'error',
  ]).isRequired,
  text: PropTypes.string,
  label: PropTypes.bool,
  onClick: PropTypes.func,
}
