import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Loading from '../Loading/Loading'

import IconDefault from './status__default.svg'
import IconMetamask from './status__metamask.svg'
import IconGoogle from './status__google.svg'
import IconWalletConnect from './status__walletConnect.svg'
import IconPending from './status__pending.svg'
import IconEdit from './status__edit.svg'
import IconCompleted from './status__completed.svg'
import IconCanceled from './status__canceled.svg'
import IconError from './status__error.svg'
import IconWarning from './status__warning.svg'
import IconInclusive from './status__inclusive.svg'
import IconDelete from './status__delete.svg'
import IconVpnKey from './status__vpnKey.svg'
import IconVisibilityOff from './status__visibilityOff.svg'
import IconSwitcher from './status_switcher.svg'

import './status.scss'

export const Status = ({
  type,
}) => {
  const Content = useMemo(() => {
    let con = <img src={IconDefault} alt="icon status" className="status__icon status__icon--sm" />

    if (type === 'default') {
      con = <img src={IconDefault} alt="icon status" className="status__icon status__icon--sm" />
    }

    if (type === 'metamask') {
      con = <img src={IconMetamask} alt="icon status" className="status__icon" />
    }

    if (type === 'google') {
      con = <img src={IconGoogle} alt="icon status" className="status__icon" />
    }

    if (type === 'walletConnect') {
      con = <img src={IconWalletConnect} alt="icon status" className="status__icon" />
    }

    if (type === 'pending') {
      con = <img src={IconPending} alt="icon status" className="status__icon" />
    }

    if (type === 'spinner') {
      con = <Loading />
    }

    if (type === 'edit') {
      con = <img src={IconEdit} alt="icon status" className="status__icon" />
    }

    if (type === 'completed') {
      con = <img src={IconCompleted} alt="icon status" className="status__icon" />
    }

    if (type === 'canceled') {
      con = <img src={IconCanceled} alt="icon status" className="status__icon" />
    }

    if (type === 'error') {
      con = <img src={IconError} alt="icon status" className="status__icon" />
    }

    if (type === 'warning') {
      con = <img src={IconWarning} alt="icon status" className="status__icon" />
    }

    if (type === 'inclusive') {
      con = <img src={IconInclusive} alt="icon status" className="status__icon" />
    }

    if (type === 'delete') {
      con = <img src={IconDelete} alt="icon status" className="status__icon" />
    }

    if (type === 'vpn-key') {
      con = <img src={IconVpnKey} alt="icon status" className="status__icon" />
    }

    if (type === 'visibility-off') {
      con = <img src={IconVisibilityOff} alt="icon status" className="status__icon" />
    }

    if (type === 'switcher') {
      con = <img src={IconSwitcher} alt="icon status" className="status__icon" />
    }

    return con
  }, [type])

  return (
    <div className={`status status--${type || ''}`}>
      { Content }
    </div>
  )
}

Status.propTypes = {
  type: PropTypes.oneOf([
    'default',
    'metamask',
    'google',
    'walletConnect',
    'pending',
    'spinner',
    'completed',
    'canceled',
    'error',
    'warning',
    'inclusive',
    'delete',
    'vpn-key',
    'visibility-off',
    'switcher',
  ]).isRequired,
}
