import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './quests.scss'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../components/BaseText/BaseText'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconListAlt from '../../../../../../../components/Icon/icon--list-alt.svg'
import IconUnfold from '../../../../../../../components/Icon/icon--unfold.svg'
import IconBack from '../../../../../../../components/Icon/icon--arrow-back.svg'
import ClickOutside from '../../../../../../../components/ClickOutside/ClickOutside'
import { generateUniqueKey } from '../../../../../../../utils/helperFunctions'
import QuestsItem from './quests-item/QuestsItem'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'

const Quests = ({
  data,
}) => {
  const [open, setOpen] = useState(false)
  const [detail, setDetail] = useState(false)

  const openPopover = () => {
    setOpen(!open)
  }

  const propagation = (e) => {
    e.stopPropagation()
  }

  return (
    <ClickOutside callback={() => { setOpen(false) }}>
      <div className="quests" onClick={(e) => { propagation(e) }}>
        <div
          className="quests__header"
          onClick={() => { openPopover() }}
        >
          <Icon
            name={IconListAlt}
            id="icon--list-alt"
          />

          <BaseTextBody>
            Quests
          </BaseTextBody>

          <Icon
            name={IconUnfold}
            id="icon--unfold"
          />
        </div>

        <div
          className={
            `quests__popover 
            ${open ? 'quests__popover--open' : ''} 
            ${detail ? 'quests__popover--detail' : ''}`
          }
        >
          {detail ? (
            <div className="quests__detail">
              <div className="quests__detail-header">
                <BaseButton
                  onClick={() => { setDetail(!detail) }}
                  size="s"
                  variant="secondary"
                  onlyIcon
                  iconLeft={(
                    <Icon
                      name={IconBack}
                      id="icon--arrow-back"
                    />
                  )}
                />

                <BaseTextHeading
                  size="S"
                >
                  Quest Objective Placeholder Placeholder Placeholder Quest Objective Placeholder Placeholder Placeholder
                </BaseTextHeading>
              </div>

              <div className="quests__detail-body">
                <BaseTextBody>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                </BaseTextBody>
              </div>

              <div className="quests__detail-footer">
                <div className="quests__detail-exp">
                  <BaseTextBody size="M" secondary>
                    Reward:
                  </BaseTextBody>

                  <BaseTextBody size="M">
                    99999 exp.
                  </BaseTextBody>
                </div>

                <BaseButton>Claim</BaseButton>
              </div>
            </div>
          ) : (
            <>
              {data.map((item) => (
                <div
                  className="quests__popover-block"
                  key={generateUniqueKey()}
                >
                  {item.divider && (
                    <div className="quests__list-divider" />
                  )}

                  {item.title && (
                    <div className="quests__list-title">
                      <BaseTextBody
                        size="S"
                        secondary
                      >
                        {item.title}
                      </BaseTextBody>
                    </div>
                  )}

                  {item.items && (
                    <div className="quests__list">
                      {item.items.map((listItem) => (
                        <div
                          className="quests__item"
                          key={generateUniqueKey()}
                        >
                          <QuestsItem
                            icon={listItem.icon}
                            title={listItem.title}
                            exp={listItem.exp}
                            onClick={() => { setDetail(!detail) }}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </ClickOutside>
  )
}

Quests.propTypes = {
  data: PropTypes.string,
}

export default Quests
