import {
  CREATE_CYBER_WALLET_FAILURE,
  CREATE_CYBER_WALLET_SUCCESS,
  SIGN_METAMASK_SUCCESS,
  SIGN_SIGNATURE_SUCCESS,
} from '../action/signAction'

const initialState = {
  signMessage: null,
  cyberWallet: null,
  signature: null,
  errors: null,
}

const signReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_METAMASK_SUCCESS:
      return { ...state, signMessage: action.signMessage }
    case SIGN_SIGNATURE_SUCCESS:
      return { ...state, signature: action.signature }
    case CREATE_CYBER_WALLET_SUCCESS:
      return { ...state, cyberWallet: action.cyberWallet }
    case CREATE_CYBER_WALLET_FAILURE:
      return { ...state, errors: action.errors }
    default:
      return state
  }
}

export default signReducer
