import React, { useEffect } from 'react'
import { createChart } from 'lightweight-charts'
import PropTypes from 'prop-types'
import './pair-history.scss'

const init = async (dataChart) => {
  document.getElementById('pairHistoryChart').innerHTML = ''
  const chart = createChart(document.getElementById('pairHistoryChart'), {
    width: 600,
    height: 300,
    rightPriceScale: {
      borderVisible: false,
    },
    timeScale: {
      borderVisible: false,
    },
    localization: {
      locale: 'en-US',
    },
  })
  chart.resize(document.getElementById('pairHistoryChart').clientWidth, 300)
  window?.addEventListener('resize', () => {
    if (document.getElementById('pairHistoryChart') !== null) {
      chart.resize(document.getElementById('pairHistoryChart').clientWidth, 300)
    }
  })

  const chartTheme = {
    chart: {
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
      },
      handleScale: {
        axisPressedMouseMove: false,
        mouseWheel: false,
        pinch: false,
      },
      layout: {
        backgroundColor: '#0d0d0d',
        lineColor: '#eadf14',
        textColor: '#D9D9D9',
      },
      watermark: {
        color: 'rgba(0, 0, 0, 0)',
      },
      crosshair: {
        color: '#758696',
      },
      grid: {
        vertLines: {
          color: '#2B2B43',
          visible: false,
        },
        horzLines: {
          color: '#000000',
        },
      },
    },
    series: {
      topColor: 'rgba(234, 223, 20, 0.56)',
      bottomColor: 'rgba(234, 223, 20, 0.04)',
      lineColor: 'rgba(234, 223, 20, 1)',
    },
  }

  const areaSeries = chart.addAreaSeries({
    topColor: 'rgba(33, 150, 243, 0.56)',
    bottomColor: 'rgba(33, 150, 243, 0.04)',
    lineColor: 'rgba(33, 150, 243, 1)',
    lineWidth: 2,
  })

  chart.applyOptions(chartTheme.chart)
  areaSeries.applyOptions(chartTheme.series)
  areaSeries.setData(dataChart)

  chart.timeScale().fitContent()
}

export const PairHistory = ({
  dataChart,
}) => {
  useEffect(() => init(dataChart), [dataChart])

  return (<div className={`${Array.isArray(dataChart) && dataChart.length < 1 ? 'pairHistoryChart__blur' : ''}`} id="pairHistoryChart" />)
}

PairHistory.propTypes = {
  dataChart: PropTypes.array,
}
