import React, { useCallback } from 'react'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import { BaseButton } from '../../../../../../components/BaseButton/BaseButton'
import { Input } from '../../../../../../components/Input/Input'

import './bridge-settings.scss'

const BridgeSettings = () => {
  const [bridgeSettings] = useLocalStorage('bridgeSettings')

  const onChangeSlippage = useCallback((newSlippage) => {
    writeStorage('bridgeSettings', {
      ...bridgeSettings,
      slippage: newSlippage,
    })
  }, [bridgeSettings])

  return (
    <div className="form-container">
      <BaseTextBody>Price Slippage</BaseTextBody>

      <div className="settings-controls">
        <BaseButton
          onClick={() => onChangeSlippage('0.3')}
          variant={bridgeSettings.slippage === '0.3' ? 'accent' : 'primary'}
        >
          0.3%
        </BaseButton>
        <BaseButton
          onClick={() => onChangeSlippage('0.5')}
          variant={bridgeSettings.slippage === '0.5' ? 'accent' : 'primary'}
        >
          0.5%
        </BaseButton>
        <Input
          type="number"
          placeholder="Custom"
          value={!['0.3', '0.5'].includes(`${bridgeSettings.slippage}`) ? `${bridgeSettings.slippage}` : ''}
          onChange={(e) => onChangeSlippage(e.target.value)}
        />
      </div>
    </div>
  )
}

export default BridgeSettings
