import React, {
  useMemo, useState, useCallback,
} from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useLocalStorage, writeStorage } from '@rehooks/local-storage'
import { BaseModal } from '../../../../../../components/BaseModal/BaseModal'
import { Stepper } from '../../../../../../components/Stepper/Stepper'
import { Icon } from '../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../components/Icon/icon--close.svg'
import IconAdd from '../../../../../../components/Icon/icon--add.svg'
import { BaseButton } from '../../../../../../components/BaseButton/BaseButton'
import { Status } from '../../../../../../components/Status/Status'
import { BaseTextHeading, BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import Loading from '../../../../../../components/Loading/Loading'
import IconAccountBalanceWallet from '../../../../../../components/Icon/icon--account-balance-wallet.svg'
import CreateForm from './create-form/CreateForm'
import { toastSuccess, toastCritical } from '../../../../../../components/Toast/Toast'
import { Btn } from '../../../../../../components/Btn/Btn'
import { TYPES_WALLET } from '../../../../../../const'

import { addSw } from '../../../../../../service/api'
import { getDataUser } from '../../../../../../store/slice/user'

import './create-cyber-wallet-modal.scss'
import { LIST_WEB3_PROVIDER } from '../../../../../../const/web3'

const CreateCyberWalletModal = ({
  isSignApprovePending,
  isSignApproved,
  signature,
  signMessage,
  onCloseModal,
  onCreateCyberWallet,
  onCreateAnotherCyberWallet,
}) => {
  const [selectedProviderFromLocalStorage] = useLocalStorage('selectedProvider')
  const [isCreateWalletStep, setCreateWalletStep] = useState(false)
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [isCreateWalletRequestPending, setCreateWalletRequestPendingState] = useState(false)
  const [isCyberWalletCreated, setCyberWalletCreatedState] = useState(false)
  const [errors, setErros] = useState({})
  const dispatch = useDispatch()
  const [isConsentCheckbox, setConsentCheckbox] = useState(false)

  const setInitialState = () => {
    setCreateWalletStep(false)
    setName('')
    setPassword('')
    setCreateWalletRequestPendingState(false)
    setCyberWalletCreatedState(false)
    setErros({})
  }

  const getModalHeader = () => (
    <BaseButton
      onClick={() => {
        setCreateWalletStep(false)
        onCloseModal()
      }}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const getModalStepper = useMemo(() => {
    let firstStep = {
      state: 'loading',
      text: 'Waiting for sign...',
    }
    let secondStep = {
      state: 'active',
      text: 'Create cyber wallet',
    }

    if (!isSignApprovePending) {
      if (isSignApproved) {
        firstStep = {
          state: 'completed',
          text: 'Authentication signed',
        }
      } else {
        firstStep = {
          state: 'canceled',
          text: 'Authentication canceled',
        }
      }
    }

    if (isCreateWalletRequestPending) {
      secondStep = {
        state: 'loading',
        text: 'Creating cyber wallet',
      }
    } else if (isCyberWalletCreated) {
      secondStep = {
        state: 'completed',
        text: 'Cyber wallet created',
      }
    }

    const steps = [
      {
        badge: {
          state: firstStep.state,
        },
        text: firstStep.text,
        active: true,
      },
      {
        badge: {
          state: secondStep.state,
          text: '2',
        },
        text: secondStep.text,
        active: false,
      },
    ]

    return (
      <Stepper
        steps={steps}
      />
    )
  }, [isSignApprovePending, isSignApproved, isCreateWalletRequestPending, isCyberWalletCreated])

  const sendCreateCyberWalletRequest = useCallback(async () => {
    setCreateWalletRequestPendingState(true)

    try {
      const newCyberWalletDetails = await addSw({
        signature,
        message: signMessage,
        name,
        pin: password,
        extra_entropy: '',
      })

      setCreateWalletRequestPendingState(false)
      toastSuccess('Cyber wallet has been created successfully')
      setCyberWalletCreatedState(true)
      unwrapResult(dispatch(getDataUser()))

      const newAddress = newCyberWalletDetails.data.address
      writeStorage('selectedAddress', newAddress)
      writeStorage('internalSelectedAddress', newAddress)
      writeStorage('selectedWallet', TYPES_WALLET.internal)
    } catch (e) {
      // TODO:
      // move all data parsing from the controller to the special parser which has to be used by the http service only
      setCreateWalletRequestPendingState(false)
      const errorsData = e.response.data
      if (errorsData.name || errorsData.pin) {
        setErros(errorsData)
        toastCritical(errorsData.name ? errorsData.name[0] : errorsData.pin[0])
      } else if (errorsData.errors) {
        toastCritical(errorsData.errors[0])
      } else {
        toastCritical(errorsData[Object.keys(errorsData)[0]])
      }
    }
  }, [signature, signMessage, name, password, dispatch])

  const getModalBody = useMemo(() => {
    const getFirstStep = () => {
      let bodyData = {
        type: 'metamask',
        title: 'Please, sign authentication',
        subTitle: 'Open your MetaMask and authentication.',
      }

      switch (selectedProviderFromLocalStorage) {
        case LIST_WEB3_PROVIDER.walletConnect:
          bodyData.subTitle = 'Open your app and authentication.'
          bodyData.type = 'walletConnect'
          break
        case LIST_WEB3_PROVIDER.magic:
          bodyData = {
            type: 'google',
            title: 'Signing...',
            subTitle: 'Signing is done automatically.',
          }
          break
        default:
          break
      }

      if (!isSignApprovePending) {
        if (isSignApproved) {
          bodyData = {
            type: 'completed',
            title: 'Authentication signed',
          }
        } else {
          bodyData = {
            type: 'canceled',
            title: 'Authentication canceled by you',
          }
        }
      }

      return (
        <>
          <Status type={bodyData.type} />
          <BaseTextHeading size="S">
            {bodyData.title}
          </BaseTextHeading>
          {
            bodyData.subTitle && (
              <BaseTextBody size="S">
                {bodyData.subTitle}
              </BaseTextBody>
            )
          }
        </>
      )
    }

    const getSecondStep = () => {
      const bodyData = {
        title: 'Create name and set privacy',
        subTitle: 'You will be able to fund and manage your wallet as well as start using all the advanced features of the platform.',
      }

      const getBody = () => (
        !isCyberWalletCreated ? (
          <>
            <div className="create-cyber-wallet__icon-block">
              <Icon
                name={IconAccountBalanceWallet}
                id="icon--account-balance-wallet"
              />
            </div>

            <BaseTextHeading size="S">
              {bodyData.title}
            </BaseTextHeading>
            {
              bodyData.subTitle && (
                <BaseTextBody>
                  {bodyData.subTitle}
                </BaseTextBody>
              )
            }
            <CreateForm
              name={name}
              password={password}
              repeatPassword={repeatPassword}
              errors={errors}
              onChangeName={(newName) => {
                setName(newName)
                const errorObj = {}
                if (newName.length < 4) {
                  errorObj.name = ['4 characters min']
                }
                if (newName.length > 12) {
                  errorObj.name = ['12 characters max']
                }
                setErros({
                  ...errorObj,
                  pin: errors.pin,
                  repeatPin: errors.repeatPin,
                })
              }}
              onChangePassword={(newPassword) => {
                setPassword(newPassword)
                const errorObj = {}
                if (newPassword.length < 8) {
                  errorObj.pin = ['8 characters min']
                }
                if (newPassword.length > 12) {
                  errorObj.pin = ['12 characters max']
                }
                setErros({
                  ...errorObj,
                  name: errors.name,
                  repeatPin: errors.repeatPin,
                })
              }}
              onChangeRepeatPassword={(newPassword) => {
                setRepeatPassword(newPassword)
                const errorObj = {}
                if (newPassword !== password) {
                  errorObj.repeatPin = ['Passwords mismatch']
                }
                setErros({
                  ...errorObj,
                  name: errors.name,
                  pin: errors.pin,
                })
              }}
              onClickConsent={() => { setConsentCheckbox(!isConsentCheckbox) }}
              isConsentChecked={isConsentCheckbox}
            />
          </>
        ) : (
          <>
            <Status type="completed" />
            <BaseTextHeading size="S">
              Cyber wallet successfully created
            </BaseTextHeading>
            <Btn
              text="Create another cyber wallet"
              onClick={() => {
                setInitialState()
                onCreateAnotherCyberWallet()
              }}
              iconRight={(
                <Icon
                  name={IconAdd}
                  id="icon--add"
                  mod="icon--24"
                />
              )}
            />
          </>
        )
      )

      return !isCreateWalletRequestPending ? getBody() : (
        <>
          <div className="create-cyber-wallet__icon-block">
            <Loading />
          </div>

          <BaseTextHeading size="S">
            Creating Cyber Wallet
          </BaseTextHeading>
        </>
      )
    }

    return !isCreateWalletStep ? getFirstStep() : getSecondStep()
  }, [
    selectedProviderFromLocalStorage,
    isSignApprovePending,
    isSignApproved,
    isCreateWalletStep,
    name,
    password,
    repeatPassword,
    isCreateWalletRequestPending,
    errors,
    isCyberWalletCreated,
    onCreateAnotherCyberWallet,
    isConsentCheckbox,
  ])

  const getModalFooter = useMemo(() => {
    const getCloseButton = () => {
      let text = 'Cancel'
      let variant = 'secondary'
      if (isCyberWalletCreated) {
        text = 'Close'
        variant = ''
      }

      return (
        <BaseButton
          onClick={onCloseModal}
          variant={variant}
        >
          {text}
        </BaseButton>
      )
    }

    const getNextButton = () => {
      const buttonProps = {
        text: 'Next step',
        isDisabled: true,
      }

      if (!isSignApprovePending) {
        buttonProps.isDisabled = false
        if (isSignApproved) {
          buttonProps.callback = () => setCreateWalletStep(true)
        } else {
          buttonProps.text = 'Request sign again'
          buttonProps.callback = onCreateCyberWallet
        }
      }

      return (
        <BaseButton
          dataTestid="create-new-wallet--next-step"
          disabled={buttonProps.isDisabled}
          onClick={buttonProps.callback}
        >
          {buttonProps.text}
        </BaseButton>
      )
    }

    const getCreateButton = () => {
      const isEnabled = !isCreateWalletRequestPending
        && (name.length >= 4 && name.length <= 12)
        && (password.length >= 8 && password.length <= 12)
        && (repeatPassword === password)
        && (!errors.name && !errors.pin)
        && isConsentCheckbox
      return (
        <BaseButton
          dataTestid="create-new-wallet--create-btn"
          disabled={!isEnabled}
          onClick={sendCreateCyberWalletRequest}
        >
          Create
        </BaseButton>
      )
    }

    const getControls = () => {
      if (!isCreateWalletStep) {
        return getNextButton()
      }
      if (isCreateWalletStep && !isCyberWalletCreated) {
        return getCreateButton()
      }
      return undefined
    }

    return (
      <div className="create-cyber-wallet__base-modal__footer">
        <div className="create-cyber-wallet__base-modal__footer__btn">
          {getCloseButton()}
          {getControls()}
        </div>
      </div>
    )
  }, [
    isCreateWalletStep,
    name,
    password,
    repeatPassword,
    isCreateWalletRequestPending,
    sendCreateCyberWalletRequest,
    isSignApprovePending,
    isSignApproved,
    onCloseModal,
    onCreateCyberWallet,
    errors,
    isCyberWalletCreated,
    isConsentCheckbox,
  ])

  return (
    <BaseModal
      title="Create Cyber Wallet"
      topAction={getModalHeader()}
      headerBottom={getModalStepper}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

CreateCyberWalletModal.propTypes = {
  isSignApprovePending: PropTypes.bool,
  isSignApproved: PropTypes.bool,
  signature: PropTypes.string,
  signMessage: PropTypes.string,
  onCloseModal: PropTypes.func,
  onCreateCyberWallet: PropTypes.func,
  onCreateAnotherCyberWallet: PropTypes.func,
}

export default CreateCyberWalletModal
