import React, {
  useCallback,
  useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import { BaseModal } from '../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../components/BaseButton/BaseButton'
import { Icon } from '../../../components/Icon/Icon'
import IconClose from '../../../components/Icon/icon--close.svg'
import { BaseTextBody, BaseTextHeading } from '../../../components/BaseText/BaseText'
import { Status } from '../../../components/Status/Status'
import ModalBodySomethingError from '../../../components/Modal/modal-body-something-error/ModalBodySomethingError'

const SetDefaultNetworkMetamaskModal = ({
  onCloseModal,
}) => {
  const [isErrorRequest, setIsErrorRequest] = useState(false)
  const [isSuccessRequest, setIsSuccessRequest] = useState(false)
  const [isSetDefaultNetwork, setIsSetDefaultNetwork] = useState(false)

  const getModalHeader = () => (
    <BaseButton
      onClick={onCloseModal}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const onChangeNetworkMetamask = useCallback(() => {
    (async () => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }],
        })

        setIsSuccessRequest(true)
      } catch (switchError) {
        console.error('switchError', switchError)
        setIsErrorRequest(true)
      }
    })()
  }, [])

  const onSelectCallbackSecondBtn = useCallback(() => {
    if (!isSetDefaultNetwork) {
      return () => setIsSetDefaultNetwork(true)
    }

    if (isErrorRequest) {
      return () => setIsErrorRequest(false)
    }
    if (isSuccessRequest) {
      return onCloseModal
    }

    return onChangeNetworkMetamask
  }, [isErrorRequest, isSetDefaultNetwork, isSuccessRequest, onChangeNetworkMetamask, onCloseModal])

  const getModalBody = useMemo(() => {
    if (isSetDefaultNetwork) {
      return (
        <>
          <Status type="metamask" />

          <BaseTextHeading size="S">
            Please, confirm network change
          </BaseTextHeading>
        </>
      )
    }
    if (isErrorRequest) {
      return <ModalBodySomethingError />
    }
    if (isSuccessRequest) {
      return (
        <>
          <Status type="completed" />

          <BaseTextHeading size="S">
            Network changed successfully
          </BaseTextHeading>
        </>
      )
    }

    return (
      <>
        <Status type="switcher" />

        <BaseTextHeading size="S">
          Your wallet network is incorrect. Please make sure it’s to set main network
        </BaseTextHeading>

        <BaseTextBody size="S">
          You will not be able to use the platform until you connect the required network.
        </BaseTextBody>
      </>
    )
  }, [isErrorRequest, isSetDefaultNetwork, isSuccessRequest])

  const getModalFooter = useMemo(() => {
    let textSecondBtn = 'Set to main network'

    if (isSetDefaultNetwork) {
      textSecondBtn = 'Nex step'
    }

    if (isErrorRequest) {
      textSecondBtn = 'Retry'
    }
    if (isSuccessRequest) {
      textSecondBtn = 'Close'
    }

    return (
      <div className="extract-private-key__base-modal__footer">
        <div className="extract-private-key__base-modal__footer__btn">
          {!isSuccessRequest && (
            <div className="extract-private-key__base-modal__footer__btn__box">
              <BaseButton
                onClick={onCloseModal}
                variant="secondary"
              >
                Cancel
              </BaseButton>
            </div>
          )}

          <div className="extract-private-key__base-modal__footer__btn__box">
            <BaseButton
              dataTestid="set-to-default-network"
              onClick={onSelectCallbackSecondBtn()}
            >
              {textSecondBtn}
            </BaseButton>
          </div>
        </div>
      </div>
    )
  }, [isErrorRequest, isSetDefaultNetwork, isSuccessRequest, onCloseModal, onSelectCallbackSecondBtn])

  return (
    <BaseModal
      dataTestId="incorrectNetwork"
      title={isSetDefaultNetwork ? 'Set to main network' : 'Incorrect Network'}
      topAction={getModalHeader()}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

SetDefaultNetworkMetamaskModal.propTypes = {
  onCloseModal: PropTypes.func,
}

export default SetDefaultNetworkMetamaskModal
