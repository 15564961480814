import React from 'react'
import PropTypes from 'prop-types'
import './base-modal.scss'
import { Divider } from '../Divider/Divider'
import { BaseTextSubheading } from '../BaseText/BaseText'
import ClickOutside from '../ClickOutside/ClickOutside'

export const BaseModal = ({
  children,
  title,
  topAction,
  headerBottom,
  footer,
  dataTestId,
  onClickOutside = () => {},
}) => (
  <div data-testid={dataTestId} className="base-modal__substrate">
    <div className="base-modal">
      <ClickOutside callback={onClickOutside}>
        <div className="base-modal__header">
          <div className="base-modal__header-top">
            <BaseTextSubheading demi>
              {title}
            </BaseTextSubheading>

            <div className="base-modal__top-action">
              {topAction}
            </div>
          </div>

          {headerBottom
          && (
            <div className="base-modal__header-bottom">
              {headerBottom}
            </div>
          )}
        </div>

        <Divider />

        <div className="base-modal__content">
          {children}
        </div>

        <Divider />

        <div className="base-modal__footer">
          {footer}
        </div>
      </ClickOutside>
    </div>
  </div>
)

BaseModal.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  dataTestId: PropTypes.string,
  topAction: PropTypes.node,
  headerBottom: PropTypes.node,
  footer: PropTypes.node,
  onClickOutside: PropTypes.func,
}
