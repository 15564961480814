import React from 'react'
import BaseTable from '../../../../../components/BaseTable/BaseTable'
import { BaseTextBody, BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import UserLevelsImg01 from '../../loyalty-user/your-functionality/your-functionality__bg01.svg'
import UserLevelsImg02 from '../../loyalty-user/your-functionality/your-functionality__bg02.svg'
import UserLevelsImg03 from './user-levels__img-01.svg'
import './user-levels.scss'
import { getLoyaltyUserLevels } from '../../../../../parser/data'

const UserLevels = () => {
  const data = getLoyaltyUserLevels()

  const tHead = []

  data.table.tHead.forEach((element) => {
    if (Array.isArray(element)) {
      tHead.push(
        <>
          {element[0]}
          <br />
          {element[1]}
        </>,
      )
    } else {
      tHead.push(element)
    }
  })

  return (
    <div className="user-levels">
      <div className="base-container">
        <div className="user-levels__header">
          <img src={UserLevelsImg01} alt="" className="user-levels__img-01" />
          <img src={UserLevelsImg02} alt="" className="user-levels__img-02" />

          <BaseTextHeading size="XL">{data.title}</BaseTextHeading>

          <BaseTextBody secondary>{data.description}</BaseTextBody>
        </div>

        <div className="user-levels__body">
          <img src={UserLevelsImg03} alt="" className="user-levels__img-03" />

          <BaseTable
            tHead={tHead}
            tBody={data.table.tBody}
          />
        </div>
      </div>
    </div>
  )
}

export default UserLevels
