import { Magic } from 'magic-sdk'
import { OAuthExtension } from '@magic-ext/oauth'
import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { LIST_WEB3_PROVIDER } from '../const/web3'
import { ETHEREUM_RPC_URL, BINANCE_RPC_URL, POLYGON_RPC_URL } from '../const'

const getNetworkSettingsForMagicLink = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  if (selectedChain === 'BSC') {
    return {
      rpcUrl: BINANCE_RPC_URL,
      chainId: 56,
    }
  }
  if (selectedChain === 'Polygon') {
    return {
      rpcUrl: POLYGON_RPC_URL,
      chainId: 137,
    }
  }

  return {
    rpcUrl: ETHEREUM_RPC_URL,
    chainId: 1,
  }
}

const getNetworkSettingsForWalletConnect = () => {
  const selectedChain = localStorage.getItem('selectedChain')
  if (selectedChain === 'BSC') {
    return {
      rpc: {
        56: BINANCE_RPC_URL,
      },
      network: 'binance',
      chainId: 56,
      infuraId: 'c7b70fc4ec0e4ca599e99b360894b699',
    }
  }
  if (selectedChain === 'Polygon') {
    return {
      rpc: {
        137: POLYGON_RPC_URL,
      },
      network: 'matic',
      chainId: 137,
      infuraId: 'c7b70fc4ec0e4ca599e99b360894b699',
    }
  }

  return {
    rpc: {
      1: ETHEREUM_RPC_URL,
    },
    network: 'ethereum',
    chainId: 1,
  }
}

export const LIST_CONNECTOR_PROVIDER = {
  [LIST_WEB3_PROVIDER.metamask]: Web3.givenProvider,
  [LIST_WEB3_PROVIDER.walletConnect]: new WalletConnectProvider({
    ...getNetworkSettingsForWalletConnect(),
    qrcode: false,
  }),
  [LIST_WEB3_PROVIDER.magic]: new Magic('pk_live_4E206E904F958CB2', {
    network: getNetworkSettingsForMagicLink(),
    extensions: [new OAuthExtension()],
  }),
}

/**
 * @returns {Promise<null|string[]>}
 */
export const enableEthereum = async () => {
  if (typeof window.ethereum !== 'undefined') {
    return window.ethereum.enable()
  }
  return null
}
