import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '../../../../../../../components/Text/Text'
import './progress-bar.scss'

const ProgressBar = ({
  progressValue,
  level,
  curExperienceValue,
  maxExperienceValue,
}) => (
  <div className="owner-wallet__progressbar">
    <div className="progressbar-container">
      <div className="filler" style={{ width: `${progressValue}%` }} />
    </div>
    <div className="details">
      <Text className="text--12" text={`Lvl. ${level}`} />
      <Text className="text--12" text={`${curExperienceValue} / ${maxExperienceValue} exp.`} />
    </div>
  </div>
)

ProgressBar.propTypes = {
  progressValue: PropTypes.number,
  level: PropTypes.number,
  curExperienceValue: PropTypes.number,
  maxExperienceValue: PropTypes.number,
}

export default ProgressBar
