import React from 'react'
import PropTypes from 'prop-types'
import { Tab } from '../Tab/Tab'

import './tabs-header.scss'
import { generateUniqueKey } from '../../utils/helperFunctions'

export const TabsHeader = ({
  tabs,
  activeIndex,
  onTabClick,
}) => {
  function handleClick(index) {
    onTabClick(index)
  }

  return (
    <div className="tabs-header">
      {tabs.map((tab, index) => (
        <div className="tabs-header__nav" key={generateUniqueKey()} data-testid={tab.testId}>
          <Tab
            text={tab.text}
            onClick={() => handleClick(index)}
            active={index === activeIndex}
          />
        </div>
      ))}
    </div>
  )
}

TabsHeader.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    testId: PropTypes.string,
  })),
  activeIndex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onTabClick: PropTypes.func,
}
