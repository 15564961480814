import { checkImageExists, isTokenAddress } from './helperFunctions'
import { tokenLogo } from '../service/imagesApi'
import { ZERO_ADDRESS, NEW_ETH_ADDRESS } from '../const'
import Img32Eth from '../components/Table/img-32-eth.png'
import IconBsc from '../components/Icon/icon--bsc.png'
import IconPolygon from '../components/Icon/icon--polygon.png'
import {useMemo} from "react";
import {LIST_WEB3_PROVIDER} from "../const/web3";
import IconWalletConnect from "../components/Icon/icon--wallet-connect.png";
import IconGoogle from "../components/Icon/icon--google.png";
import IconMetamask from "../components/Icon/icon--metamask.png";
import config from '../config'

/**
 *
 * @param {string} address
 * @param {string} iconIfNotExist
 * @returns {Promise<{src, isExist: boolean}>}
 */
export const getImage = async (address, iconIfNotExist) => {
  const emojiThink = `${config.apiURL}/static/media/not-found.svg`
  const selectedChain = localStorage.getItem('selectedChain')
  let imgSrc = Img32Eth
  if (selectedChain === 'BSC') {
    imgSrc = IconBsc
  } else if (selectedChain === 'Polygon') {
    imgSrc = IconPolygon
  }

  let defaultIcon = iconIfNotExist ? iconIfNotExist : emojiThink

  if ([ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(address)  && !iconIfNotExist) {
    defaultIcon = imgSrc
  }

  if (!address || !isTokenAddress(address)) {
    return {
      src: defaultIcon,
      isExist: false
    }
  }
  if ([ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(address)) {
    return {
      src: imgSrc,
      isExist: true,
    }
  }

  try {
    let srcImg = await tokenLogo((address || ''))
    srcImg = srcImg.config.baseURL + srcImg.config.url
    let isExist = false

    if ([ZERO_ADDRESS, NEW_ETH_ADDRESS].includes(address)) {
      srcImg = imgSrc
      isExist = true
    }
    if (ZERO_ADDRESS !== address) {
      isExist = await checkImageExists(srcImg)
    }

    return {
      src: srcImg,
      isExist,
    }
  } catch (e) {
    return {
      src: emojiThink,
      isExist: false
    }
  }
}

export const getIconSelectedProvider = () => {
  const selectedProviderFromLocalStorage = localStorage.getItem('selectedProvider')

  switch (selectedProviderFromLocalStorage) {
    case LIST_WEB3_PROVIDER.walletConnect:
      return IconWalletConnect
    case LIST_WEB3_PROVIDER.magic:
      return IconGoogle
    case LIST_WEB3_PROVIDER.metamask:
      return IconMetamask
    default:
      return IconMetamask
  }
}
