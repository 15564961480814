import React from 'react'
import { Alert } from '../../../../../../../components/Alert/Alert'
import { AssetInfo } from '../../../../../../../components/AssetInfo/AssetInfo'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { ExternalLink } from '../../../../../../../components/ExternalLink/ExternalLink'
import { Grid } from '../../../../../../../components/Grid/Grid'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconSwapVert from '../../../../../../../components/Icon/icon--swap-vert.svg'
import IconWallet from '../../../../../../../components/Icon/icon--wallet.svg'
import IconHelp from '../../../../../../../components/Icon/icon--help-outline.svg'
import { List } from '../../../../../../../components/List/List'
import ExchangeItem from '../exchange-item/ExchangeItem'
import './gp-form.scss'
import GweiInput from './gwei-input/GweiInput'
import Slider from '../../../../../../../components/Slider/Slider'

const GPForm = () => (
  <div className="gp-form">
    <div className="form-container">
      <div className="form-container__header">
        <div className="title">
          <BaseTextBody secondary>You Pay</BaseTextBody>
        </div>

        <div className="balance-block">
          <BaseTextBody>Balance:</BaseTextBody>
          <BaseTextBody>1089.21 CFi</BaseTextBody>
        </div>
      </div>

      <div className="form-container__body">
        <ExchangeItem
          selectedToken={{
            name: 'CyberFi Token',
            link: 'link',
          }}
          areUserTokensUsed
          onMaxButtonClick={() => {}}
          tokenValue="1,089.21"
          tokenPrice="7,171.81"
          onChangeTokenValue={() => {}}
          onFocus={() => {}}
          onFocusOut={() => {}}
          onSelectToken={() => {}}
        />

        <div className="gas-price">
          <Grid align="center" gap="8px">
            <BaseTextBody secondary>Gas price</BaseTextBody>

            <Icon name={IconHelp} id="icon--help-outline" />
          </Grid>

          <Slider
            min={0}
            max={300}
            onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
          />
        </div>

        <div className="gas-price-limit-order">
          <Grid align="center" gap="4px">
            <GweiInput title="min" gweiValue={0} />
            <GweiInput title="max" gweiValue={300} />
          </Grid>

          <Grid align="center" gap="8px">
            <BaseTextBody secondary>Gas price limit order</BaseTextBody>

            <Icon name={IconHelp} id="icon--help-outline" />
          </Grid>
        </div>

        <div className="swap-control-block">
          <BaseButton onClick={() => {}} variant="secondary" onlyIcon iconLeft={(<Icon name={IconSwapVert} id="icon--swap-vert" mod="icon--24" />)} />
        </div>

        <BaseTextBody secondary>You Receive</BaseTextBody>

        <ExchangeItem
          selectedToken={{
            name: 'Ethereum',
            link: 'link',
          }}
          areUserTokensUsed
          onMaxButtonClick={() => {}}
          tokenValue="TBA"
          tokenPrice="TBA"
          onChangeTokenValue={() => {}}
          onFocus={() => {}}
          onFocusOut={() => {}}
          onSelectToken={() => {}}
        />

        <div className="additional-details-block is-limit">
          <AssetInfo
            detail={(
              <List
                noBorder
                items={[
                  {
                    name: 'Owner wallet',
                    value: <ExternalLink text="Uniswap V2" link="#" />,
                    valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
                  },
                ]}
              />
            )}
          />
        </div>

        <div className="form-controls-block">
          <BaseButton
            isBlock
            onClick={() => {}}
          >
            Create Order
          </BaseButton>
        </div>

        <div className="form-errors-block">
          <Alert error size="s">This pair is not available for trading on our platform. Please, select another pair.</Alert>
        </div>
      </div>
    </div>
  </div>
)

export default GPForm
