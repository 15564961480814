import React from 'react'
import ChartAndSidePanel from './chart-and-side-panel/ChartAndSidePanel'
import Orderbook from './orderbook/Orderbook'
import { Social } from '../../../../components/Social/Social'

import './trade.scss'

const Trade = () => (
  <div className="trade-container">
    <ChartAndSidePanel />
    <Orderbook />
    <div className="trade-footer">
      <Social twitter discord telegram />
    </div>
  </div>
)

export default Trade
