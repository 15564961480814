import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { AssetTrandItem } from './asset-trand-item/AssetTrandItem'
import { Icon } from '../../../../../components/Icon/Icon'
import IconMore from '../../../../../components/Icon/icon--more.svg'
import { BaseButton } from '../../../../../components/BaseButton/BaseButton'
import { ClipboardTooltip } from '../../../../../components/ClipboardTooltip/ClipboardTooltip'
import ClickOutside from '../../../../../components/ClickOutside/ClickOutside'
import { getLpTokensPair, getTokenData } from '../../../../../service/api'
import { updateSelectedTokensPair } from '../../../../../store/slice/trade'
import { setAsset } from '../../../../../store/slice/tradingView'
import { USDT_ADDRESS, ZERO_ADDRESS } from '../../../../../const'
import { toastCritical } from '../../../../../components/Toast/Toast'

import './asset-trand-list.scss'
import { useWeb3 } from '../../../../../context/web3Provider'

export const AssetTrandList = ({
  displayStyle,
  list,
  listMore,
}) => {
  const web3React = useWeb3()
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedTokensPair = useSelector((store) => store.trade.selectedTokensPair)

  const [openMenu, setOpenMenu] = useState(false)

  const clsMenu = useMemo(() => {
    let cls = 'asset-top-tranding__list__menu'

    if (openMenu) {
      cls += ' asset-top-tranding__list__menu--open'
    }

    return cls
  }, [openMenu])

  const handleClickMenu = useCallback(() => {
    setOpenMenu(!openMenu)
  }, [openMenu])

  // TODO: it's better to move this to a different action
  const onUpdateTokensPair = useCallback(async ({ token1, token2 }) => {
    try {
      const pair = await getLpTokensPair({
        address1: web3React.library.utils.toChecksumAddress(token1.address),
        address2: web3React.library.utils.toChecksumAddress(token2.address),
      })
      if (pair) {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1: pair.token1,
            token2: pair.token2,
          },
          pairAddress: pair.address,
          isChartExist: true,
        }))
      } else {
        dispatch(updateSelectedTokensPair({
          pair: {
            token1,
            token2,
          },
          isChartExist: false,
        }))
      }
    } catch (e) {
      toastCritical('Tokens pair update failed')
      console.error('Update tokens pair on assets click failed = ', e)
    }
  }, [web3React, dispatch])

  const onAssetClick = useCallback(async (address) => {
    if (address) {
      dispatch(setAsset({
        asset: address === ZERO_ADDRESS ? USDT_ADDRESS : ZERO_ADDRESS,
        isTopAsset: true,
      }))

      try {
        const token2 = await getTokenData(web3React.library.utils.toChecksumAddress(address))
        onUpdateTokensPair({
          token1: selectedTokensPair.token1,
          token2,
        })
        history.push('/trade')
      } catch (e) {
        toastCritical('Could not load data for a chosen token')
        console.error('Update tokens pair on assets click failed = ', e)
      }
    }
  }, [dispatch, web3React, onUpdateTokensPair, selectedTokensPair.token1, history])

  return (
    <div data-testid="header--top-trend-tokens" className={`asset-trand-list ${displayStyle || ''}`}>
      {list.map((item, key) => (
        <div className="asset-trand-list__item" key={key}>
          <AssetTrandItem
            icon={item.icon}
            number={item.number}
            text={item.text}
            tokenPrice={item.tokenPrice}
            tokenAddress={item.tokenAddress}
            onClick={() => onAssetClick(item.tokenAddress)}
            value={item.value}
            valueType={item.valueType}
          />
          {displayStyle === 'table-style'
          && <div className="asset-trand-list__divider" />}
        </div>
      ))}

      {listMore.length > 0 && (
        <div data-testid="header--top-trend-tokens--btn-more" className="asset-trand-list__show-more">
          <BaseButton
            onClick={handleClickMenu}
            variant="secondary"
            onlyIcon
            size="s"
            iconLeft={(
              <Icon
                name={IconMore}
                id="icon--more"
              />
            )}
          />

          {openMenu && (
            <ClickOutside callback={() => setOpenMenu(false)} ignore="icon--more">
              <div data-testid="header--top-trend-tokens--list-more" className={clsMenu}>
                {listMore.map((item, key) => (
                  <ClipboardTooltip
                    key={key}
                    tooltipText={`$${item.tokenPrice}`}
                    tooltipTextFocus={`$${item.tokenPrice}`}
                    noFocus
                  >
                    <div className="asset-top-tranding__menu-item" onClick={handleClickMenu}>
                      <AssetTrandItem
                        icon={item.icon}
                        number={item.number}
                        text={item.text}
                        tokenAddress={item.tokenAddress}
                        tokenPrice={item.tokenPrice}
                        onClick={() => onAssetClick(item.tokenAddress)}
                        value={item.value}
                        valueType={item.valueType}
                      />
                    </div>
                  </ClipboardTooltip>
                ))}
              </div>
            </ClickOutside>
          )}
        </div>
      )}
    </div>
  )
}

AssetTrandList.defaultProps = {
  list: [],
  listMore: [],
}

AssetTrandList.propTypes = {
  displayStyle: PropTypes.oneOf([
    'table-style',
  ]),
  list: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    number: PropTypes.node,
    text: PropTypes.node,
    tokenPrice: PropTypes.node,
    tokenAddress: PropTypes.node,
    value: PropTypes.string,
    valueType: PropTypes.oneOf(['up', 'down', 'flat']),
  })),
  listMore: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    number: PropTypes.node,
    text: PropTypes.node,
    tokenPrice: PropTypes.node,
    tokenAddress: PropTypes.node,
    value: PropTypes.string,
    valueType: PropTypes.oneOf(['up', 'down', 'flat']),
  })),
}
