import React from 'react'
import PropTypes from 'prop-types'

import './exchange-item.scss'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { SelectCurrencyChart } from '../SelectCurrencyChart/SelectCurrencyChart'
import { Input } from '../Input/Input'
import { BaseTextBody } from '../BaseText/BaseText'
import { ITEMS_PROPTYPES } from '../SelectCurrencyChart/BaseDropDown'
import { ZERO_ADDRESS } from '../../const'
import { Chip } from '../Chip/Chip'
import { getLinkBasedOnCurrentNetwork } from '../../parser/data'

export const ExchangeItem = ({
  isMax,
  chipOnClick,
  infinityScrollHasMore,
  funcInfinityScroll,
  className,
  dropDownItems,
  textLink,
  link,
  price,
  onChange,
  callback,
  selectedValue,
  firstTokenAddress,
  inputValue,
  priceValue,
  priceOnChange,
  isLoading,
  isInputDisabled,
  funcLoadItems,
  errorIcon,
  warningIcon,
}) => (
  <div className={`exchange-item ${className || ''}`}>
    <div className="exchange-item__header">
      <ExternalLink
        text={textLink}
        link={link}
        hideLink={link === `https://${getLinkBasedOnCurrentNetwork()}/token/${ZERO_ADDRESS}`}
      />

      <BaseTextBody>
        $
        {price}
      </BaseTextBody>
    </div>

    <div className="exchange-item__body">
      <SelectCurrencyChart
        infinityScrollHasMore={infinityScrollHasMore}
        funcInfinityScroll={funcInfinityScroll}
        isInputDisabled={isInputDisabled}
        isLoading={isLoading}
        oneImg
        externalValue={inputValue}
        firstTokenAddress={firstTokenAddress}
        selectedValue={selectedValue}
        items={dropDownItems}
        isExchangeItem
        funcLoadItems={funcLoadItems}
        onChange={onChange}
        callback={callback}
        errorIcon={errorIcon}
        warningIcon={warningIcon}
      />

      {isMax && (
        <div className="exchange-item__body-chip">
          <Chip
            onClick={chipOnClick}
          >
            MAX
          </Chip>
        </div>
      )}

      <Input
        type="number"
        isInputDisabled={isInputDisabled}
        placeholder="0.00"
        value={!Number.isNaN(priceValue) ? priceValue : 0}
        onChange={priceOnChange}
      />
    </div>
  </div>
)

ExchangeItem.propTypes = {
  className: PropTypes.string,
  textLink: PropTypes.string,
  inputValue: PropTypes.string,
  priceValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  link: PropTypes.string,
  isLoading: PropTypes.bool,
  isMax: PropTypes.bool,
  infinityScrollHasMore: PropTypes.bool,
  isInputDisabled: PropTypes.bool,
  firstTokenAddress: PropTypes.string,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  funcLoadItems: PropTypes.func,
  chipOnClick: PropTypes.func,
  funcInfinityScroll: PropTypes.func,
  callback: PropTypes.func,
  priceOnChange: PropTypes.func,
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  dropDownItems: PropTypes.arrayOf(ITEMS_PROPTYPES),
  errorIcon: PropTypes.bool,
  warningIcon: PropTypes.bool,
}
