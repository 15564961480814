import React from 'react'

import PropTypes from 'prop-types'
import './asset-pnl.scss'
import { StatsItem } from '../StatsItem/StatsItem'
import { BaseTextHeading } from '../BaseText/BaseText'
import { BaseButton } from '../BaseButton/BaseButton'
import { Icon } from '../Icon/Icon'
import IconAddSm from '../Icon/icon--add-sm.svg'
import { Chip } from '../Chip/Chip'

export const AssetPnl = ({
  chips,
  balance,
  price,
  buyButton,
  items,
}) => (
  <div className="asset-pnl">
    <div className="asset-pnl__top">
      <div className="asset-pnl__actions">
        {chips.map((item, index) => (
          <Chip
            onClick={item.onClick}
            icon={item.icon}
            iconPosition="left"
            size="L"
            outline
            isSelected={item.isSelected}
            key={index}
          >
            {item.text}
          </Chip>
        ))}
      </div>

      <div className="asset-pnl__right">
        <BaseTextHeading
          size="S"
        >
          {balance}
        </BaseTextHeading>

        <BaseTextHeading
          size="S"
          isSecondary
        >
          (
          {price}
          )
        </BaseTextHeading>

        {buyButton
          && (
          <BaseButton
            iconRight={<Icon name={IconAddSm} id="icon--add-sm" />}
          >
            {`Buy ${buyButton}`}
          </BaseButton>
          )}
      </div>
    </div>

    <div className="asset-pnl__body">
      {items.map((item, index) => (
        <StatsItem
          name={item.name}
          value={item.value}
          subValue={item.subValue}
          isError={item.isError}
          key={index}
        />
      ))}
    </div>
  </div>
)

AssetPnl.propTypes = {
  balance: PropTypes.string,
  price: PropTypes.string,
  buyButton: PropTypes.string,
  items: PropTypes.array,
  chips: PropTypes.array,
}
