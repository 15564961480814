import React, {
  useCallback, useEffect,
  useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { BaseModal } from '../../../../../../../../components/BaseModal/BaseModal'
import { BaseButton } from '../../../../../../../../components/BaseButton/BaseButton'
import { Icon } from '../../../../../../../../components/Icon/Icon'
import IconClose from '../../../../../../../../components/Icon/icon--close.svg'
import { BaseTextBody, BaseTextHeading } from '../../../../../../../../components/BaseText/BaseText'
import { Stepper } from '../../../../../../../../components/Stepper/Stepper'
import { Status } from '../../../../../../../../components/Status/Status'
import { Divider } from '../../../../../../../../components/Divider/Divider'
import { Input } from '../../../../../../../../components/Input/Input'
import { Alert } from '../../../../../../../../components/Alert/Alert'
import './extract-private-key-get-key-step.scss'
import { Checkbox } from '../../../../../../../../components/Checkbox/Checkbox'
import { extractPrivateKeyApi } from '../../../../../../../../service/api'
import { ClipboardTooltip } from '../../../../../../../../components/ClipboardTooltip/ClipboardTooltip'
import IconFileCopy from '../../../../../../../../components/Icon/icon--file-copy.svg'
import ModalBodySomethingError from '../../../../../../../../components/Modal/modal-body-something-error/ModalBodySomethingError'
import ModalBodyLoading from '../../../../../../../../components/Modal/modal-body-loading/ModalBodyLoading'

const ExtractPrivateKeyGetKeyStep = ({
  onCloseModal,
  chosenAddress,
  onAfterRemove,
}) => {
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false)
  const [isErrorPin, setIsErrorPin] = useState(false)
  const [valuePin, setValuePin] = useState('')
  const [valuePrivateKey, setValuePrivateKey] = useState('')
  const [valueCheckbox, setValueCheckbox] = useState(false)
  const [isDisabledBtnNext, setIsDisabledBtnNext] = useState(true)
  const [isPendingRequest, setIsPendingRequest] = useState(false)
  const [isErrorRequest, setIsErrorRequest] = useState(false)

  const getModalHeader = () => (
    <BaseButton
      onClick={() => {
        if (isFirstStepCompleted) {
          onAfterRemove(chosenAddress)
        }
        onCloseModal()
      }}
      variant="secondary"
      onlyIcon
      iconLeft={(
        <Icon
          name={IconClose}
          id="icon--close"
        />
      )}
    />
  )

  const onSendExtractPrivateKey = useCallback(() => {
    (async () => {
      setIsPendingRequest(true)

      try {
        const { data } = await extractPrivateKeyApi({ address: chosenAddress, pin: valuePin })

        setValuePrivateKey(data.private_key)
        setIsFirstStepCompleted(true)
        setIsPendingRequest(false)
      } catch (e) {
        if (e.response.status === 401) {
          setIsErrorPin(true)
        } else {
          setIsErrorRequest(true)
        }
        setIsPendingRequest(false)
      }
    })()
  }, [chosenAddress, valuePin])

  const onSelectCallbackSecondBtn = useCallback(() => {
    if (isFirstStepCompleted) {
      return () => {
        navigator.clipboard.writeText(valuePrivateKey)
        onAfterRemove(chosenAddress)
        onCloseModal()
      }
    }
    if (isErrorRequest) {
      return () => setIsErrorRequest(false)
    }

    return onSendExtractPrivateKey
  }, [chosenAddress, isErrorRequest, isFirstStepCompleted, onAfterRemove, onCloseModal, onSendExtractPrivateKey, valuePrivateKey])

  const getHeaderBottom = useMemo(() => {
    let firstStepState = 'active'
    let firstStepText = 'Enter Cyber Wallet PIN'

    if (isFirstStepCompleted) {
      firstStepState = 'completed'
      firstStepText = 'Cyber Wallet PIN Entered'
    }
    if (isPendingRequest) {
      firstStepText = 'Extracting private key...'
      firstStepState = 'loading'
    }
    if (isErrorRequest) {
      firstStepText = 'Extracting canceled'
      firstStepState = 'error'
    }

    const steps = [
      {
        badge: {
          state: firstStepState,
          text: '1',
        },
        text: firstStepText,
        active: !isFirstStepCompleted,
      },
      {
        badge: {
          state: 'active',
          text: '2',
        },
        text: 'Get private key',
        active: isFirstStepCompleted,
      },
    ]

    return (
      <Stepper
        steps={steps}
      />
    )
  }, [isErrorRequest, isFirstStepCompleted, isPendingRequest])

  const getFirstStepBody = useMemo(() => (
    <>
      <Status
        type="vpn-key"
      />

      <BaseTextHeading size="S">
        Enter Cyber Wallet PIN to extract private key
      </BaseTextHeading>

      <BaseTextBody size="S">
        You will have full control over the wallet, but you will no longer be able to connect it back to
        the platform. We will delete all wallet data from our platform.
      </BaseTextBody>

      <Divider />

      <Input
        dataTestid="popup--extract-private-key--input-password"
        value={valuePin}
        onChange={(e) => setValuePin(e.target.value)}
        label={<BaseTextBody>Cyber Wallet PIN</BaseTextBody>}
        error={isErrorPin ? 'Wrong Cyber Wallet PIN' : undefined}
        name="ExtractPrivateKey"
        type="password"
      />

      <div className="extract-private-key-pin-step--alert">
        <Alert warning>
          Moving on to the next step, you cannot undo the extracting of the private key. Please, be careful.
        </Alert>
      </div>

      <div className="extract-private-key-pin-step--checkbox">
        <Checkbox
          dataTestid="popup--extract-private-key--checkbox"
          name="extract-private-key-pin-step--checkbox"
          checked={valueCheckbox}
          value={valueCheckbox}
          onChange={() => setValueCheckbox(!valueCheckbox)}
          textRight={<BaseTextBody>I understood everything and I want to extract private key.</BaseTextBody>}
        />
      </div>
    </>
  ), [valuePin, valueCheckbox, isErrorPin])
  const getSecondStepBody = useMemo(() => (
    <>
      <Status
        type="visibility-off"
      />

      <BaseTextHeading size="S">
        Copy and save the private key
      </BaseTextHeading>

      <BaseTextBody size="S">
        Do not give this key to anyone. It gives full access to the funds on the wallet and cannot be changed.
      </BaseTextBody>

      <Divider />

      <div className="extract-private-key-pin-step--key-and-copy">
        <Input
          dataTestid="popup--extract-private-key--extract-private-key"
          value={valuePrivateKey}
          readOnly
          name="ExtractPrivateKey"
          type="password"
        />

        <ClipboardTooltip tooltipText="Copy key" tooltipTextFocus="Copied!">
          <CopyToClipboard text={valuePrivateKey}>
            <span>
              <Icon
                mod="icon--14"
                name={IconFileCopy}
                id="icon--file-copy"
              />
            </span>
          </CopyToClipboard>
        </ClipboardTooltip>
      </div>

      <div className="extract-private-key-pin-step--alert">
        <Alert warning>
          After closing this window, you will not be able to receive the key if you do not save it.
        </Alert>
      </div>
    </>
  ), [valuePrivateKey])

  const getModalBody = useMemo(() => {
    let step = getFirstStepBody

    if (isFirstStepCompleted) {
      step = getSecondStepBody
    }
    if (isPendingRequest) {
      step = (
        <ModalBodyLoading
          textHeading="Private key is extracting, please wait"
          textBody="You cannot interrupt this operation. When you close this window, you will lose access to your wallet."
        />
      )
    }
    if (isErrorRequest) {
      step = <ModalBodySomethingError />
    }

    return (
      <>
        {step}
      </>
    )
  }, [getFirstStepBody, isFirstStepCompleted, isErrorRequest, getSecondStepBody, isPendingRequest])

  const getModalFooter = useMemo(() => {
    let textSecondBtn = 'Next Step'

    if (isFirstStepCompleted) {
      textSecondBtn = 'Copy & close'
    }
    if (isErrorRequest) {
      textSecondBtn = 'Retry'
    }

    return (
      <div className="delete-cyber-wallet__base-modal__footer">
        <div className="delete-cyber-wallet__base-modal__footer__btn">
          {!isFirstStepCompleted && (
            <div className="extract-private-key__base-modal__footer__btn__box">
              <BaseButton
                onClick={onCloseModal}
                variant="secondary"
              >
                Cancel
              </BaseButton>
            </div>
          )}
          <div className="extract-private-key__base-modal__footer__btn__box">
            <BaseButton
              dataTestid="popup--extract-private-key--next-step-btn"
              onClick={onSelectCallbackSecondBtn()}
              disabled={isDisabledBtnNext}
            >
              {textSecondBtn}
            </BaseButton>
          </div>
        </div>
      </div>
    )
  }, [isFirstStepCompleted, isErrorRequest, onCloseModal, onSelectCallbackSecondBtn, isDisabledBtnNext])

  useEffect(() => {
    setIsDisabledBtnNext((!valuePin || !valueCheckbox || isPendingRequest) && !isErrorRequest)
  }, [valuePin, valueCheckbox, isPendingRequest, isErrorRequest])

  return (
    <BaseModal
      title="Extract Private Key"
      topAction={getModalHeader()}
      headerBottom={getHeaderBottom}
      footer={getModalFooter}
    >
      {getModalBody}
    </BaseModal>
  )
}

ExtractPrivateKeyGetKeyStep.propTypes = {
  onCloseModal: PropTypes.func,
  onAfterRemove: PropTypes.func,
  chosenAddress: PropTypes.string,
}

export default ExtractPrivateKeyGetKeyStep
