import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import './slider.scss'

const Slider = ({
  min,
  max,
  onChange,
}) => {
  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const minValRef = useRef()
  const maxValRef = useRef()
  const range = useRef()

  const thumbRadius = 8
  const fract1 = (Number(minVal) - min) / (max - min)
  const fract2 = (Number(maxVal) - min) / (max - min)
  const percentLeft1 = fract1 * 100
  const percentLeft2 = fract2 * 100
  const fractFromCentre1 = (fract1 - 0.5) * 2
  const fractFromCentre2 = (fract2 - 0.5) * 2
  const adjustment1 = fractFromCentre1 * -thumbRadius
  const adjustment2 = fractFromCentre2 * -thumbRadius

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max],
  )

  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal)
      const maxPercent = getPercent(+maxValRef.current.value)

      if (range.current) {
        range.current.style.left = `${minPercent}%`
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [minVal, getPercent])

  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value)
      const maxPercent = getPercent(maxVal)

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`
      }
    }
  }, [maxVal, getPercent])

  useEffect(() => {
    onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal, onChange])

  return (
    <div className="slider">
      <div
        className="slider__tooltip"
        style={{ left: `calc(${percentLeft1}% + ${adjustment1}px)` }}
      >
        {minVal}
      </div>

      <div
        className="slider__tooltip"
        style={{ left: `calc(${percentLeft2}% + ${adjustment2}px)` }}
      >
        {maxVal}
      </div>

      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(+event.target.value, maxVal - 1)
          setMinVal(value)
        }}
        className="slider__input slider__input--1"
      />

      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(+event.target.value, minVal)
          setMaxVal(value)
        }}
        className="slider__input slider__input--2"
      />

      <div className="slider__wrapper">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
      </div>
    </div>
  )
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
}

export default Slider
