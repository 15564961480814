import React from 'react'
import BaseTable from '../../../../../components/BaseTable/BaseTable'
import { BaseTextHeading } from '../../../../../components/BaseText/BaseText'
import { getLoyaltyCompleteFunctionality } from '../../../../../parser/data'
import './complete-functionality.scss'

const CompleteFunctionality = () => {
  const data = getLoyaltyCompleteFunctionality()

  return (
    <div className="complete-functionality">
      <div className="complete-functionality__header">
        <BaseTextHeading size="L">
          {data.title}
        </BaseTextHeading>
      </div>

      <div className="complete-functionality__body">
        <BaseTable
          tHead={data.tHead}
          tBody={data.tBody}
        />
      </div>
    </div>
  )
}

export default CompleteFunctionality
