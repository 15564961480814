import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector, useDispatch } from 'react-redux'
import { CustomDataTable } from '../../../../../../components/DataTable/DataTable'
import { getOrdersList, urgentlyOrder, deleteOrder } from '../../../../../../service/api'
import { StatusBadge } from '../../../../../../components/StatusBadge/StatusBadge'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import ImgPairBlock from '../../../../../../components/ImgPairBlock/ImgPairBlock'
import { SelectBase } from '../../../../../../components/SelectBase/SelectBase'
import iconOpenMenu from '../../../../../../components/Icon/icon--open-menu.svg'
import iconUrgently from '../../../../../../components/Icon/icon--urgently.svg'
import iconCanceledFilled from '../../../../../../components/Icon/icon--canceled-filled.svg'
import { Icon } from '../../../../../../components/Icon/Icon'
import { toastSuccess, toastCritical } from '../../../../../../components/Toast/Toast'
import { setOrderCreationBooleanValue } from '../../../../../../store/slice/trade'
import { getUpdateIntervalTimeForOrdersBasedOnNetwork } from '../../../../../../parser/data'

const OrdersTable = ({
  columns,
  selectedPair,
  onOrderClick,
  shouldBeTableReloaded,
}) => {
  const dispatch = useDispatch()
  const hasOrderJustBeenCreated = useSelector((store) => store.trade.hasOrderJustBeenCreated)

  const [internalSelectedAddress] = useLocalStorage('internalSelectedAddress')
  const [isDataRequestPending, setDataRequestIsPendingState] = useState(false)
  const [isDataReceived, setDataIsReceivedState] = useState(false)

  const [ordersList, setOrdersListState] = useState([])
  const [ordersDataList, setOrdersDataListState] = useState([])
  const [ordersRowsCount, setOrderRowsCountState] = useState(0)
  const [rowsCountPerPage, setRowsCountPerPageState] = useState()
  const [currentPage, setCurrentPageState] = useState(1)
  const [currentOrdering, setCurrentOrderding] = useState('-creationTime')

  const [cachedSelectedPair, setCachedSelectedPairState] = useState(selectedPair)

  const dataPoller = useRef()
  const isPollerRequestPending = useRef(false)

  const getOrderMainColumns = useCallback((order) => ({
    id: order.id,
    status: (
      <StatusBadge
        state={order.status.value}
        text={order.status.text}
        label
        onClick={() => onOrderClick(order)}
      />
    ),
    pairs: (
      <ImgPairBlock
        firstTokenIcon={order.pair.token1.icon}
        secondTokenIcon={order.pair.token2.icon}
        text={order.pair.title}
        onClick={() => onOrderClick(order)}
      />
    ),
    transaction: <BaseTextBody onClick={() => onOrderClick(order)}>{order.transaction}</BaseTextBody>,
    type: <BaseTextBody onClick={() => onOrderClick(order)}>{order.type}</BaseTextBody>,
    triggerConditions: <BaseTextBody onClick={() => onOrderClick(order)}>{order.triggerConditions}</BaseTextBody>,
    creationTime: <BaseTextBody onClick={() => onOrderClick(order)} secondary>{order.creationTime}</BaseTextBody>,
  }), [onOrderClick])

  // there was a "before initialization" issue so that's why this method is so huge
  const loadOrdersList = useCallback(async ({
    limit,
    page,
    ordering,
    address1,
    address2,
  }) => {
    try {
      const ordersData = await getOrdersList({
        cyberWallet: internalSelectedAddress,
        limit: limit || rowsCountPerPage,
        currentPage: page || currentPage,
        ordering: ordering || currentOrdering,
        address1: address1 || (selectedPair !== 'all-pairs' ? selectedPair.token1.address : ''),
        address2: address2 || (selectedPair !== 'all-pairs' ? selectedPair.token2.address : ''),
      })

      setOrdersDataListState(ordersData.rows)
      setOrderRowsCountState(ordersData.count)
      setDataRequestIsPendingState(false)
      setDataIsReceivedState(true)
      isPollerRequestPending.current = false

      setOrdersListState(ordersData.rows.map((order) => ({
        ...getOrderMainColumns(order),
        more: (
          <SelectBase
            selected={<Icon name={iconOpenMenu} id="icon--open-menu" />}
            onChange={() => {}}
            className="select-base--new"
            items={[
              {
                value: 'Urgently execute',
                hide: order.type === 'Market',
                icon: <Icon name={iconUrgently} id="icon--urgently" />,
                name: (
                  <BaseTextBody
                    secondary
                    onClick={async () => {
                      try {
                        await urgentlyOrder(order.id)
                        toastSuccess('The order will be urgently executed')
                        setDataRequestIsPendingState(true)
                        loadOrdersList({})
                      } catch (e) {
                        toastCritical('Could not execute the order urgently')
                      }
                    }}
                  >
                    Urgently execute
                  </BaseTextBody>
                ),
              },
              {
                value: 'Cancel order',
                icon: <Icon name={iconCanceledFilled} id="icon--canceled-filled" />,
                name: (
                  <BaseTextBody
                    secondary
                    onClick={async () => {
                      try {
                        await deleteOrder(order.id)
                        toastSuccess('Order has been canceled successfully')
                        setDataRequestIsPendingState(true)
                        loadOrdersList({})
                      } catch (e) {
                        toastCritical('Order cancellation failed')
                      }
                    }}
                  >
                    Cancel order
                  </BaseTextBody>
                ),
              },
            ]}
          />
        ),
      })))
    } catch (e) {
      setOrdersListState([])
      setDataRequestIsPendingState(false)
      setDataIsReceivedState(true)
      isPollerRequestPending.current = false
    }
  }, [internalSelectedAddress, rowsCountPerPage, currentPage, currentOrdering, selectedPair, getOrderMainColumns])

  const onChangeRowsCountPerPage = useCallback((newRowsCountPerPage) => {
    setRowsCountPerPageState(newRowsCountPerPage)
    setDataRequestIsPendingState(true)
    loadOrdersList({
      limit: newRowsCountPerPage,
    })
  }, [loadOrdersList])

  const onChangePage = useCallback((newPage) => {
    setCurrentPageState(newPage)
    setDataRequestIsPendingState(true)
    loadOrdersList({
      page: newPage,
    })
  }, [loadOrdersList])

  const onSort = useCallback((column, sortDirection) => {
    const newOrdering = `${sortDirection === 'desc' ? '-' : ''}${column.selector}`
    setCurrentOrderding(newOrdering)
    setDataRequestIsPendingState(true)
    loadOrdersList({
      ordering: newOrdering,
    })
  }, [loadOrdersList])

  useEffect(() => {
    if (JSON.stringify(cachedSelectedPair) !== JSON.stringify(selectedPair)) {
      setDataRequestIsPendingState(true)
      loadOrdersList({
        address1: selectedPair !== 'all-pairs' ? selectedPair.token1.address : '',
        address2: selectedPair !== 'all-pairs' ? selectedPair.token2.address : '',
      })
      setCachedSelectedPairState(selectedPair)
    }
  }, [cachedSelectedPair, selectedPair, loadOrdersList])

  useEffect(() => {
    if (hasOrderJustBeenCreated) {
      setDataRequestIsPendingState(true)
      dispatch(setOrderCreationBooleanValue(false))
      loadOrdersList({})
    }
  }, [hasOrderJustBeenCreated, loadOrdersList, dispatch])

  useEffect(() => {
    if (!isDataReceived && !isDataRequestPending) {
      setDataRequestIsPendingState(true)
      loadOrdersList({})
    }
  }, [isDataReceived, isDataRequestPending, loadOrdersList])

  const stopPollingForOrders = useCallback(() => {
    if (dataPoller.current) {
      clearInterval(dataPoller.current)
      dataPoller.current = undefined
      isPollerRequestPending.current = false
    }
  }, [])

  const startPollingForOrders = useCallback(() => {
    if (!dataPoller.current) {
      const intervalTime = getUpdateIntervalTimeForOrdersBasedOnNetwork()

      dataPoller.current = setInterval(async () => {
        if (!isPollerRequestPending.current && !isDataRequestPending) {
          isPollerRequestPending.current = true
          loadOrdersList({})
        }
      }, intervalTime)
    }
  }, [isDataRequestPending, loadOrdersList])

  useEffect(() => {
    startPollingForOrders()
    return () => stopPollingForOrders()
  }, [startPollingForOrders, stopPollingForOrders])

  useEffect(() => {
    if (shouldBeTableReloaded) {
      setDataRequestIsPendingState(true)
      loadOrdersList({})
    }
  }, [shouldBeTableReloaded, loadOrdersList])

  return (
    <CustomDataTable
      columns={columns}
      data={ordersList}
      isDataLoading={isDataRequestPending}
      emptyContentDescription="You don't have any created orders yet"
      paginationTotalRows={ordersRowsCount}
      defaultSortField="creationTime"
      defaultSortAsc={false}
      onChangeRowsPerPage={onChangeRowsCountPerPage}
      onChangePage={onChangePage}
      onSort={onSort}
      onRowClicked={(selectedRow) => onOrderClick(ordersDataList.filter((order) => order.id === selectedRow.id)[0])}
      minHeight="250px"
    />
  )
}

OrdersTable.propTypes = {
  columns: PropTypes.array,
  selectedPair: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onOrderClick: PropTypes.func,
  shouldBeTableReloaded: PropTypes.bool,
}

export default OrdersTable
