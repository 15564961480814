import React from 'react'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import ExchangeItem from '../exchange-item/ExchangeItem'
import { Icon } from '../../../../../../../components/Icon/Icon'
import IconSwapVert from '../../../../../../../components/Icon/icon--swap-vert.svg'
import IconHelpOutline from '../../../../../../../components/Icon/icon--help-outline.svg'
import IconWallet from '../../../../../../../components/Icon/icon--wallet.svg'
import { Alert } from '../../../../../../../components/Alert/Alert'
import { AssetInfo } from '../../../../../../../components/AssetInfo/AssetInfo'
import { BaseButton } from '../../../../../../../components/BaseButton/BaseButton'
import { List } from '../../../../../../../components/List/List'
import { ExternalLink } from '../../../../../../../components/ExternalLink/ExternalLink'
import './sl-tp-form.scss'
import { AssetField } from '../../../../../../../components/AssetField/AssetField'
import { Grid } from '../../../../../../../components/Grid/Grid'
import StopLoss from './stop-loss/StopLoss'

const SlTpForm = () => (
  <div className="sl-tp-form">
    <div className="form-container">
      <div className="form-container__header">
        <div className="title">
          <BaseTextBody secondary>You Pay</BaseTextBody>
        </div>

        <div className="balance-block">
          <BaseTextBody>Balance:</BaseTextBody>
          <BaseTextBody>1089.21 CFi</BaseTextBody>
        </div>
      </div>

      <div className="form-container__body">
        <Grid gap="16px" direction="column">
          <ExchangeItem
            selectedToken={{
              name: 'CyberFi Token',
              link: 'link',
            }}
            areUserTokensUsed
            onMaxButtonClick={() => {}}
            tokenValue="1,089.21"
            tokenPrice="7,171.81"
            onChangeTokenValue={() => {}}
            onFocus={() => {}}
            onFocusOut={() => {}}
            onSelectToken={() => {}}
          />

          <AssetField
            inputType="number"
            value="0"
            name="Limit Price"
            onChange={() => {}}
            asset="CFi"
            placeholder="0"
            help={<Icon name={IconHelpOutline} id="icon--help-outline" />}
          />

          <Grid gap="16px">
            <AssetField
              inputType="number"
              value="99"
              name="Stop loss per cent"
              onChange={() => {}}
              asset="%"
              placeholder="0"
            />

            <StopLoss />
          </Grid>

          <Grid gap="4px">
            <BaseTextBody secondary>Loss price:</BaseTextBody>
            <BaseTextBody>$999,999.999</BaseTextBody>
            <BaseTextBody secondary>ETH</BaseTextBody>
          </Grid>

          <AssetField
            inputType="number"
            value="0"
            name="Budget"
            onChange={() => {}}
            asset="CFi"
            placeholder="0"
            help={<Icon name={IconHelpOutline} id="icon--help-outline" />}
          />
        </Grid>

        <div className="swap-control-block">
          <BaseButton onClick={() => {}} variant="secondary" onlyIcon iconLeft={(<Icon name={IconSwapVert} id="icon--swap-vert" mod="icon--24" />)} />
        </div>

        <BaseTextBody secondary>You Receive</BaseTextBody>

        <ExchangeItem
          selectedToken={{
            name: 'Ethereum',
            link: 'link',
          }}
          areUserTokensUsed
          onMaxButtonClick={() => {}}
          tokenValue="TBA"
          tokenPrice="TBA"
          onChangeTokenValue={() => {}}
          onFocus={() => {}}
          onFocusOut={() => {}}
          onSelectToken={() => {}}
        />

        <div className="additional-details-block is-limit">
          <AssetInfo
            detail={(
              <List
                noBorder
                items={[
                  {
                    name: 'Offered by',
                    value: <ExternalLink text="Uniswap V2" link="#" />,
                    valueIcon: <Icon name={IconWallet} id="icon--wallet" />,
                  },
                ]}
              />
            )}
          />
        </div>

        <div className="form-controls-block">
          <BaseButton
            isBlock
            onClick={() => {}}
          >
            Create Order
          </BaseButton>
        </div>

        <div className="form-errors-block">
          <Alert error size="s">This pair is not available for trading on our platform. Please, select another pair.</Alert>
        </div>
      </div>
    </div>
  </div>
)

export default SlTpForm
