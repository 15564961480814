import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SelectPair from './select-pair/SelectPair'
import { BaseTextBody } from '../../../../../../../components/BaseText/BaseText'
import { updateSelectedTokensPair } from '../../../../../../../store/slice/trade'

import './top-pair-control.scss'

const TopPairControl = () => {
  const dispatch = useDispatch()
  const selectedTokensPair = useSelector((store) => store.trade.selectedTokensPair)

  return (
    <div className="top-pair-control-container">
      <div className="left-block">
        <SelectPair
          tokensPair={selectedTokensPair}
          onSelectPair={(pair) => dispatch(updateSelectedTokensPair({
            pair,
            isChartExist: true,
          }))}
        />

        <div className="pair-name-title">
          <BaseTextBody dataTestid="trade--chart-select-pair--full-name" secondary>
            {`${selectedTokensPair.token1.name} / ${selectedTokensPair.token2.name}`}
          </BaseTextBody>
        </div>
      </div>
    </div>
  )
}

export default TopPairControl
