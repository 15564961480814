import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../../Icon/Icon'
import IconNoData from '../../Icon/icon--no-data.svg'
import { BaseTextBody } from '../../BaseText/BaseText'

export const DataTableEmptyContent = ({
  desctription,
}) => (
  <div className="data-table__empty-content">
    <Icon
      name={IconNoData}
      id="icon--no-data"
    />

    <BaseTextBody secondary>{desctription || 'No data'}</BaseTextBody>
  </div>
)

DataTableEmptyContent.propTypes = {
  desctription: PropTypes.string,
}
