import React, {
  createContext, useContext, useState,
} from 'react'
import Web3 from 'web3'
import { LIST_WEB3_PROVIDER } from '../const/web3'
import { getChainNameBasedOnId } from '../parser/data'

const Web3Context = createContext()

const useWeb3 = () => {
  const context = useContext(Web3Context)
  if (!context) {
    throw new Error('useWeb3 must be used within a Web3Provider')
  }
  return context
}

const Web3Provider = (props) => {
  const initWeb3Context = {
    account: undefined,
    active: false,
    chainId: undefined,
    error: undefined,
    library: undefined,
  }
  const [web3Context, setWeb3Context] = useState(initWeb3Context)

  const setMetamaskEvents = () => {
    if (localStorage.getItem('selectedProvider') === LIST_WEB3_PROVIDER.metamask) {
      window.ethereum.on('chainChanged', (newChainId) => {
        localStorage.setItem('selectedChain', getChainNameBasedOnId(newChainId))
        window.location.reload()
      })
      window.ethereum.on('accountsChanged', () => {
        localStorage.clear()
        window.location.reload()
      })
      window.ethereum?.on('networkChanged', () => {})
    }
  }

  const setWalletConnectEvents = (provider) => {
    if (localStorage.getItem('selectedProvider') === LIST_WEB3_PROVIDER.walletConnect) {
      provider.on('chainChanged', () => window.location.reload())
      provider.on('accountsChanged', (data) => {
        setWeb3Context({
          ...web3Context,
          account: data[0],
        })
      })
      provider.on('disconnect', () => {
        localStorage.clear()
        window.location.reload()
      })
    }
  }

  const value = {
    ...web3Context,
    activate: async ({
      provider,
      onConnect = () => {},
    }) => {
      try {
        await onConnect()

        const newLibrary = new Web3(provider)
        const chainId = await newLibrary.eth.getChainId()
        const account = (await newLibrary.eth.getAccounts())[0]

        setMetamaskEvents()
        setWalletConnectEvents(provider)

        setWeb3Context({
          ...initWeb3Context,
          account,
          chainId,
          library: newLibrary,
          active: true,
        })
      } catch (e) {
        setWeb3Context({
          ...initWeb3Context,
          error: `Activation error: ${e}`,
        })
        console.log(`Activation error: ${e.response}`)
      }
    },
  }

  return <Web3Context.Provider value={value} {...props} />
}

export { Web3Provider, useWeb3 }
