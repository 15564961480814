import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '../Text/Text'

import './title-date.scss'

export const TitleDate = ({
  className,
  title,
  date,
}) => (
  <div className={`title-date ${className || ''}`}>
    <Text
      className="title-date__title"
      text={title}
    />

    <div className="title-date__dates">
      <Text
        text={date}
      />
    </div>
  </div>
)

TitleDate.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
}
