import React from 'react'
import PropTypes from 'prop-types'
import './base-button.scss'
import { BaseTextBody } from '../BaseText/BaseText'

export const BaseButton = ({
  children,
  variant,
  size,
  type,
  iconLeft,
  iconRight,
  onClick,
  disabled,
  onlyIcon,
  href,
  targetBlank,
  isBlock,
  dataTestid,
}) => {
  const handleOnClick = (e) => {
    e.stopPropagation()
    if (onClick) {
      onClick()
    }
  }

  const attributes = {
    className: `
      base-button
      base-button--${variant}
      base-button--size-${size}
      ${onlyIcon ? 'base-button--only-icon' : ''}
      ${isBlock ? 'base-button--block' : ''}
    `,
    onClick: handleOnClick,
  }

  const content = (
    <>
      {iconLeft}
      {children && <BaseTextBody>{children}</BaseTextBody>}
      {iconRight}
    </>
  )

  return (
    <>
      {href ? (
        <a
          {...attributes}
          href={href}
          {...(targetBlank ? { target: '_blank' } : '')}
        >
          {content}
        </a>
      ) : (
        <button
          {...attributes}
          type={type === 'submit' ? 'submit' : 'button'}
          disabled={disabled}
          data-testid={dataTestid}
        >
          {content}
        </button>
      )}
    </>
  )
}

BaseButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'accent',
    'primary',
    'secondary',
    'tertiary',
  ]),
  size: PropTypes.oneOf([
    'xs',
    's',
    'm',
  ]),
  type: PropTypes.oneOf([
    'submit',
    'button',
    'reset',
  ]),
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  href: PropTypes.string,
  targetBlank: PropTypes.bool,
  isBlock: PropTypes.bool,
  dataTestid: PropTypes.string,
}
BaseButton.defaultProps = {
  variant: 'accent',
  size: 'm',
  type: 'button',
  onClick: () => {},
  disabled: false,
  onlyIcon: false,
  targetBlank: false,
  isBlock: false,
}
