import React from 'react'
import { BaseTextBody } from '../../../../../../../../components/BaseText/BaseText'
import { SelectBase } from '../../../../../../../../components/SelectBase/SelectBase'
import IconSwitcher from '../../../../../../../../components/Icon/icon--switcher.svg'
import './stop-loss.scss'

const StopLoss = () => (
  <div className="stop-loss">
    <BaseTextBody secondary>Choose profit</BaseTextBody>

    <SelectBase
      headerIcon={IconSwitcher}
      headerIconID="icon--switcher"
      size="M"
      className="select-base--new"
      items={[{ name: 'Stop loss' }, { name: 'item 2' }, { name: 'item 3' }]}
      selected="Stop loss"
    />
  </div>
)

export default StopLoss
