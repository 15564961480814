import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseTextBody } from '../../../../../../components/BaseText/BaseText'
import { Chip } from '../../../../../../components/Chip/Chip'
import { ClipboardTooltip } from '../../../../../../components/ClipboardTooltip/ClipboardTooltip'

import './asset-trand-item.scss'

export const AssetTrandItem = ({
  icon,
  number,
  text,
  tokenPrice,
  tokenAddress,
  onClick,
  value,
  valueType,
}) => {
  const renderBody = useMemo(() => (
    <div className="asset-trand-item">
      <BaseTextBody secondary>{number}</BaseTextBody>

      <Chip
        className="chip--secondary pointer"
        icon={icon}
        onClick={onClick}
        value={value}
        valueType={valueType}
      >
        {text}
      </Chip>
    </div>
  ), [onClick, icon, number, text, value, valueType])

  return (
    <>
      {tokenPrice
        ? (
          <ClipboardTooltip
            tooltipTextFocus={`$${tokenAddress}`}
            tooltipText={`$${tokenPrice}`}
            noFocus
          >
            {renderBody}
          </ClipboardTooltip>
        )
        : renderBody}
    </>
  )
}

AssetTrandItem.propTypes = {
  icon: PropTypes.element,
  number: PropTypes.string,
  text: PropTypes.string,
  tokenPrice: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  tokenAddress: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.string,
  valueType: PropTypes.oneOf(['up', 'down', 'flat']),
}
