import React, {
  useState, useCallback, useMemo, useRef, useContext,
} from 'react'
import PropTypes from 'prop-types'
import UnfoldMoreRoundedIcon from '@material-ui/icons/UnfoldMoreRounded'
import { Icon } from '../Icon/Icon'
import ClickOutside from '../ClickOutside/ClickOutside'
import './select-base.scss'
// eslint-disable-next-line import/no-cycle
import { IsOnChangeReturnValueContext } from '../../pages/main-container/pages/dashboard/dashboard-container/dashboard-body/DashboardBody'

export const SelectBase = ({
  className,
  classNameDropDown,
  headerIcon,
  headerIconID,
  headerIconMaterial,
  items,
  selected,
  onChange = () => {},
  isOnChangeReturnValue = false,
  isDisabled,
  size,
  dataTestid,
}) => {
  const [open, setOpen] = useState(false)
  const [openClass, setOpenClass] = useState('select-base--open')
  const selectBlock = useRef(null)
  const isOnChangeReturnValueContext = useContext(IsOnChangeReturnValueContext)

  const openSelect = useCallback(() => {
    setOpen(!open)

    if (selectBlock.current.offsetTop + 300 > document.documentElement.clientHeight) {
      setOpenClass('select-base--open-top')
    } else {
      setOpenClass('select-base--open')
    }
  }, [open])

  const setAddressSuccess = useCallback((data, e) => {
    if (isDisabled) {
      setOpen(false)
    }
    if (!isDisabled) {
      setOpen(!open)
    }

    onChange(data, e)
  }, [isDisabled, open, onChange])

  const renderDropDown = useMemo(() => {
    if (Array.isArray(items) && items.length > 0) {
      return (
        <div className="select-base__list">
          {items.map((item, key) => (
            !item.hide && (
            <div
              className="select-base__item"
              key={key}
              onClick={(e) => {
                if (isOnChangeReturnValue || isOnChangeReturnValueContext) {
                  setAddressSuccess(item.value, e)
                } else {
                  setAddressSuccess(item, e)
                }
              }}
            >
              {item.name}

              {item.icon
                && (
                <div className="select-base__item-icon">
                  {item.icon}
                </div>
                )}
            </div>
            )
          ))}
        </div>
      )
    }

    return (
      <div className="select-base__list_empty">
        List empty
      </div>
    )
  }, [isOnChangeReturnValue, isOnChangeReturnValueContext, items, setAddressSuccess])

  let sizeClass = ''

  switch (size) {
    case 'M':
      sizeClass += ' select-base--size-m'
      break

    default:
      sizeClass += ''
  }

  return (
    <ClickOutside callback={() => setOpen(false)}>
      <div
        onClick={() => openSelect()}
        className={`
          select-base ${className || ''}
          ${open ? openClass : ''}
          ${sizeClass}
        `}
        ref={selectBlock}
        data-testid={dataTestid}
      >
        <div className="select-base__body">
          <div className="select-base__header">
            {selected}

            <div className="select-base__header-icon">
              {headerIconMaterial ? <UnfoldMoreRoundedIcon /> : (
                <Icon
                  name={headerIcon}
                  id={headerIconID}
                />
              )}

            </div>
          </div>

          <div className={`select-base__list-container ${classNameDropDown || ''}`}>
            {renderDropDown}
          </div>
        </div>
      </div>
    </ClickOutside>
  )
}

export const SelectBasePropTypeItems = PropTypes.arrayOf(PropTypes.shape({
  value: PropTypes.any,
  name: PropTypes.node,
}))

SelectBase.propTypes = {
  className: PropTypes.string,
  classNameDropDown: PropTypes.string,
  headerIcon: PropTypes.string,
  headerIconID: PropTypes.string,
  headerIconMaterial: PropTypes.bool,
  items: SelectBasePropTypeItems,
  selected: PropTypes.node,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isOnChangeReturnValue: PropTypes.bool,
  size: PropTypes.string,
  dataTestid: PropTypes.string,
}
